import { useContext } from 'react';
import { PersonContext } from '../contexts/PersonContext';

/**
 * Checks local storage to see if the user is an admin
 * @returns {boolean} - Returns true if the user is an admin, false if not
 */
function isAdminUser() {
  if (Number(localStorage.getItem('adminLogin')) === 1) {
    return true;
  }
  return false;
}

/**
 * Sets up tracking events for Segment
 * @param {*} event - The event to be sent to Segment
 * @param {*} properties - An object containing the properties to be sent to Segment
 */
export function segmentTracking(event, properties) {
  if (!window.analytics) {
    return;
  }
  if (!isAdminUser()) {
    window.analytics.track(event, properties);
  }
}

/**
 * Sets up tracking events hook for Segment
 * @param {*} event - The event to be sent to Segment
 * @param {*} properties - An object containing the properties to be sent to Segment
 * @param {*} additionalContext - An object containing additional context when tracking events are
 * kicking off when the user's context is not yet set (e.g. Register screen)
 */
export function useSegmentTrack() {
  const {
    organizationId,
    showWelcome,
    partnerName,
    adminLogin,
  } = useContext(PersonContext);

  if (!window.analytics) {
    return () => {};
  }

  // exclude info from all partners other than TurboTenant
  if (partnerName && partnerName !== 'TurboTenant') {
    return () => {};
  }

  // exclude admin activity
  if (adminLogin) {
    return () => {};
  }

  let defaultProperties = {
    groupId: organizationId,
    showWelcome,
    partnerName,
  };

  return (event, properties, additionalContext) => {
    if (additionalContext) {
      defaultProperties = { ...defaultProperties, ...additionalContext };
    }
    window.analytics.track(event, { ...defaultProperties, ...properties });
  };
}
