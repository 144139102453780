import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';

const styles = {
  root: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
};

class FormGridItem extends React.PureComponent {
  render() {
    const { classes, children, ...rest } = this.props;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Grid item className={classes.root} {...rest}>
        {children}
      </Grid>
    );
  }
}

FormGridItem.defaultProps = {
  children: null,
};

FormGridItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(FormGridItem);
