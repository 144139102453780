import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import {
  ResponsiveContainer, ComposedChart,
  Bar, Cell, XAxis, YAxis,
} from 'recharts';

import { PersonContext } from '../../contexts/PersonContext';

import { create, find } from '../../feathersWrapper';

import LinkBase from '../../components/LinkBase';
import ErrorCard from '../../components/ErrorCard';
import CardBase from '../../components/CardBase';

class ValueCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { organizationId } = this.context;
    const { view, id } = this.props;

    const idParam = {};
    if (view === 'property') {
      idParam.propertyId = id;
    } else if (view === 'subportfolio') {
      /* const properties = await find(this, 'properties', {
        query: {
          organizationId,
          entityId: id,
          inactive: false,
          $limit: 200,
        },
      });
      idParam.propertyId = properties.data.map((property) => property.id); */
      idParam.entityId = id;
    }

    const sumMarketValuePromise = create(this, 'reports', {
      organizationId,
      ...idParam,
      reportName: 'sumMarketValue',
    });

    const loanTransactionsSinceBalanceSummaryPromise = create(this, 'reports', {
      organizationId,
      ...idParam,
      reportName: 'loanTransactionsSinceBalanceSummary',
    });

    const loanAccountsPromise = find(this, 'accounts', {
      query: {
        organizationId,
        ...idParam,
        inactive: { $or: [null, false] },
        type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
        $limit: 200,
      },
    });

    const sumMarketValue = await sumMarketValuePromise;
    const loanTransactionsSinceBalanceSummary = await loanTransactionsSinceBalanceSummaryPromise;
    const loanAccounts = await loanAccountsPromise;

    const includedLoansById = {};
    let totalDebt = 0;

    loanAccounts.data.forEach((loan) => {
      if (loan.lastCurrentBalance) {
        totalDebt += loan.lastCurrentBalance;
      }
      includedLoansById[loan.id] = loan;
    });

    loanTransactionsSinceBalanceSummary.forEach((loan) => {
      if (includedLoansById[loan.id]) {
        totalDebt += loan.netCredits;
      }
    });

    const propertyGraphData = [
      { name: 'Value', amount: sumMarketValue.marketValue },
      { name: 'Equity', amount: sumMarketValue.marketValue - totalDebt },
      { name: 'Debt', amount: totalDebt },
    ];

    this.setState({
      sumMarketValue,
      propertyGraphData,
      loanCount: loanAccounts.total,
      loading: false,
    });
  };

  getLink = () => {
    const { view, id } = this.props;
    const { loanCount } = this.state;

    if (view === 'portfolio') {
      return (
        <LinkBase to="/reports/portfolio-value-by-property">
          <Typography variant="body2">
            View Report
          </Typography>
        </LinkBase>
      );
    }
    if (view === 'subportfolio') {
      return (
        <LinkBase to={`/reports/portfolio-value-by-property?entityId=${id}`}>
          <Typography variant="body2">
            View Report
          </Typography>
        </LinkBase>
      );
    }
    return (
      <LinkBase to="/accounts/loans">
        <Typography variant="body2">{`View Loans (${loanCount})`}</Typography>
      </LinkBase>
    );
  };

  render() {
    const propertyColors = ['#4F7CAC', '#CCC', '#8884D8'];
    const {
      loading, error, propertyGraphData, sumMarketValue,
    } = this.state;
    const {
      view,
    } = this.props;

    const CustomPropertyLabel = ({
      // eslint-disable-next-line react/prop-types
      index, x, y,
    }) => (
      <text x={x} y={y} dy={-4} fill="#000" className="custom-label">
        <tspan>{propertyGraphData[index].name}</tspan>
        <NumberFormat
          displayType="text"
          value={propertyGraphData[index].amount}
          thousandSeparator
          prefix="($"
          suffix=")"
          decimalScale={0}
          fixedDecimalScale
          renderText={(formattedValue) => <tspan dx={4} fill="#666">{formattedValue}</tspan>}
        />
      </text>
    );

    if (loading) {
      return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                {view === 'portfolio' && 'Portfolio Value'}
                {view === 'property' && 'Market Value'}
                {view === 'subportfolio' && 'Sub-Portfolio Value'}
              </Typography>
            </Box>
          </CardBase>
        </Grid>
      );
    }
    if (error) {
      return <ErrorCard error={error.message} />;
    }
    return (
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <CardBase>
          <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
            <Typography variant="h6" gutterBottom>
              {view === 'portfolio' ? 'Portfolio Value' : 'Market Value'}
            </Typography>
            <Typography variant="h4">
              <NumberFormat
                displayType="text"
                value={sumMarketValue.marketValue}
                thousandSeparator
                prefix="$"
                decimalScale={0}
                fixedDecimalScale
              />
            </Typography>
            {sumMarketValue.count > 0 && (
              <ResponsiveContainer width="100%" height={225}>
                <ComposedChart
                  layout="vertical"
                  data={propertyGraphData}
                  margin={{
                    top: 30, right: 0, bottom: 0, left: 0,
                  }}
                  barCategoryGap={15}
                >
                  <XAxis hide type="number" />
                  <YAxis hide dataKey="name" type="category" />
                  <Bar label={<CustomPropertyLabel />} minPointSize={5} dataKey="amount" fill="#413ea0" isAnimationActive={false}>
                    {
                      // eslint-disable-next-line react/no-array-index-key
                      propertyGraphData.map((entry, index) => <Cell key={`cell-${index}`} fill={propertyColors[index % propertyColors.length]} />)
                    }
                  </Bar>
                </ComposedChart>
              </ResponsiveContainer>
            )}
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
              {this.getLink()}
            </Box>
          </Box>
        </CardBase>
      </Grid>
    );
  }
}

ValueCard.contextType = PersonContext;

ValueCard.defaultProps = {
  id: null,
};

ValueCard.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.string).isRequired,
  // stale: PropTypes.bool,
  // removeStale: PropTypes.func,
  view: PropTypes.string.isRequired,
  id: PropTypes.node,
  // theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default ValueCard;
