import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';

import PageGrid from '~/components/PageGrid';
import { get, patch, patchMultiple } from '../feathersWrapper';
import history from '../history';
import { PersonContext } from '../contexts/PersonContext';

import {
  nameLabel,
  getAccountOptions,
} from '../components/Autocomplete/Library';

import {
  handleTextFieldChange,
  handleKeyboardDatePickerChange,
  handleToggleButtonChange,
  handleAutocompleteChange,
} from '../functions/InputHandlers';

import CardBase from '../components/CardBase';
import PageHeader from '../components/PageHeader';

const styles = {
  grid: {
    width: '100%',
    margin: 0,
  },
};

const actionButtons = [
  { text: 'Cancel', link: '/', class: 'cancel' },
];

class Settings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async componentDidMount() {
    const { organizationId } = this.context;
    const oldOrganization = await get(this, 'organizations', organizationId);
    const organization = {};
    organization.name = oldOrganization.name;
    organization.bookkeepingStartDate = oldOrganization.bookkeepingStartDate;
    organization.bookkeepingCloseDate = oldOrganization.bookkeepingCloseDate;
    organization.basis = oldOrganization.basis;
    organization.multiEntity = oldOrganization.multiEntity ? 'yes' : 'no';
    organization.recurringTransactions = oldOrganization.recurringTransactions ? 'yes' : 'no';
    organization.vendorTracking = oldOrganization.vendorTracking ? 'yes' : 'no';
    organization.partnerImportStartDate = oldOrganization.partnerImportStartDate;
    organization.partnerSubscribed = oldOrganization.partnerSubscribed;
    organization.partnerName = oldOrganization.partnerName;

    if (oldOrganization.retainedEarningsAccountId) {
      organization.retainedEarningsAccountIdSelect = await get(this, 'accounts', oldOrganization.retainedEarningsAccountId);
    }
    const equityAccountOptions = await getAccountOptions(this, { type: 'Equity' });
    this.setState({
      organization,
      equityAccountOptions,
      loading: false,
    });
  }

  save = async (event) => {
    event.preventDefault();
    const {
      organizationId, basis, setOrganizationId, demo,
    } = this.context;
    const {
      organization, submitting,
    } = this.state;

    if (submitting) {
      return;
    }

    if (demo) {
      this.setState({ error: { message: 'You cannot save changes in the demo portfolio' } });
      return;
    }

    this.setState({ submitting: true });

    const patchData = { ...organization };
    patchData.multiEntity = patchData.multiEntity === 'yes';
    patchData.vendorTracking = patchData.vendorTracking === 'yes';
    patchData.recurringTransactions = patchData.recurringTransactions === 'yes';
    patchData.retainedEarningsAccountId = patchData.retainedEarningsAccountIdSelect
      ? patchData.retainedEarningsAccountIdSelect.id : null;

    if (patchData.basis !== basis) {
      if (patchData.basis === 'cash') {
        await patchMultiple(this, 'accounts', {
          query: {
            organizationId,
            type2: ['Accounts Receivable', 'Deferred Revenue'],
          },
        }, { type: 'Revenue' });
        await patchMultiple(this, 'accounts', {
          query: {
            organizationId,
            type2: ['Accounts Payable', 'Prepaid Expense'],
          },
        }, { type: 'Expense' });
      } else if (patchData.basis === 'accrual') {
        await patchMultiple(this, 'accounts', {
          query: {
            organizationId,
            type2: ['Accounts Receivable', 'Prepaid Expense'],
          },
        }, { type: 'Asset' });
        await patchMultiple(this, 'accounts', {
          query: {
            organizationId,
            type2: ['Accounts Payable', 'Deferred Revenue'],
          },
        }, { type: 'Liability' });
      }
    }

    await patch(this, 'organizations', organizationId, patchData);
    await setOrganizationId(organizationId);

    history.push('/');
  };

  render() {
    const { match } = this.props;
    const {
      loading, submitting, error, organization, equityAccountOptions,
    } = this.state;

    return (
      <>
        {!loading && (
          <PageGrid>
            <PageHeader match={match} actionButtons={actionButtons} title="Settings" />
            <Grid item xs={12}>
              <CardBase>
                <CardContent>
                  <form onSubmit={this.save}>
                    <TextField
                      label="Portfolio Name"
                      fullWidth
                      required
                      margin="dense"
                      InputProps={{
                        value: organization.name,
                        name: 'nested_organization_name',
                        onChange: handleTextFieldChange(this),
                      }}
                    />
                    <KeyboardDatePicker
                      label="Bookkeeping Start Date"
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      value={organization.bookkeepingStartDate}
                      onChange={handleKeyboardDatePickerChange('nested_organization_bookkeepingStartDate', this)}
                      margin="dense"
                      fullWidth
                      clearable
                    />
                    <FormControl margin="dense" fullWidth>
                      <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                        Accounting Basis
                      </FormLabel>
                      <input
                        tabIndex={-1}
                        onChange={() => {}}
                        autoComplete="off"
                        style={{ position: 'absolute', opacity: 0, height: 0 }}
                        value={organization.basis !== null ? organization.basis : ''}
                        required
                      />
                      <ToggleButtonGroup
                        value={organization.basis}
                        onChange={handleToggleButtonChange('nested_organization_basis', this)}
                        exclusive
                      >
                        <ToggleButton value="cash">
                          Cash
                        </ToggleButton>
                        <ToggleButton value="accrual">
                          Accrual
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                      <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                        Enable Vendor Tracking
                      </FormLabel>
                      <input
                        tabIndex={-1}
                        onChange={() => {}}
                        autoComplete="off"
                        style={{ position: 'absolute', opacity: 0, height: 0 }}
                        value={organization.vendorTracking !== null ? organization.vendorTracking : ''}
                        required
                      />
                      <ToggleButtonGroup
                        value={organization.vendorTracking}
                        onChange={handleToggleButtonChange('nested_organization_vendorTracking', this)}
                        exclusive
                      >
                        <ToggleButton value="yes">
                          Yes
                        </ToggleButton>
                        <ToggleButton value="no">
                          No
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                      <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                        Enable Sub-Portfolios
                      </FormLabel>
                      <input
                        tabIndex={-1}
                        onChange={() => {}}
                        autoComplete="off"
                        style={{ position: 'absolute', opacity: 0, height: 0 }}
                        value={organization.multiEntity !== null ? organization.multiEntity : ''}
                        required
                      />
                      <ToggleButtonGroup
                        value={organization.multiEntity}
                        onChange={handleToggleButtonChange('nested_organization_multiEntity', this)}
                        exclusive
                      >
                        <ToggleButton value="yes">
                          Yes
                        </ToggleButton>
                        <ToggleButton value="no">
                          No
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                      <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                        Enable Recurring Transactions
                      </FormLabel>
                      <input
                        tabIndex={-1}
                        onChange={() => {}}
                        autoComplete="off"
                        style={{ position: 'absolute', opacity: 0, height: 0 }}
                        value={
                          organization.recurringTransactions !== null
                            ? organization.recurringTransactions
                            : ''
                          }
                        required
                      />
                      <ToggleButtonGroup
                        value={organization.recurringTransactions}
                        onChange={handleToggleButtonChange('nested_organization_recurringTransactions', this)}
                        exclusive
                      >
                        <ToggleButton value="yes">
                          Yes
                        </ToggleButton>
                        <ToggleButton value="no">
                          No
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                    <Autocomplete
                      options={equityAccountOptions}
                      getOptionLabel={nameLabel}
                      value={organization.retainedEarningsAccountIdSelect}
                      onChange={handleAutocompleteChange(
                        'nested_organization_retainedEarningsAccountIdSelect',
                        this,
                      )}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                          margin="dense"
                          label="Retained Earnings Account"
                          placeholder="Type to Search"
                          fullWidth
                        />
                      )}
                    />
                    <KeyboardDatePicker
                      label="Bookkeeping Close Date (optional)"
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      value={organization.bookkeepingCloseDate}
                      onChange={handleKeyboardDatePickerChange('nested_organization_bookkeepingCloseDate', this)}
                      margin="dense"
                      disableFuture
                      maxDateMessage="The bookkeeping close date cannot be in the future"
                      fullWidth
                      clearable
                    />
                    {organization.bookkeepingCloseDate && (
                      <Box
                        border={1}
                        borderColor="grey.500"
                        borderRadius="borderRadius"
                        padding={2}
                        marginY={2}
                      >
                        <Typography variant="body2">
                          {`Setting a bookkeeping close date will block edits to your transactions on or before the selected date.
                            Use it if you would like to prevent changes to your financials from prior closed accounting periods.
                            Otherwise leave this field blank.`}
                        </Typography>
                      </Box>
                    )}
                    {(organization.partnerSubscribed || organization.partnerName === 'Innago') && (
                      <KeyboardDatePicker
                        label={`${organization.partnerName} Import Start Date (optional)`}
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY"
                        value={organization.partnerImportStartDate}
                        onChange={handleKeyboardDatePickerChange('nested_organization_partnerImportStartDate', this)}
                        margin="dense"
                        disableFuture
                        maxDateMessage="The import start date date cannot be in the future"
                        fullWidth
                        clearable
                      />
                    )}
                    <Box
                      marginY={1}
                    >
                      <Typography color="error" gutterBottom>{error && error.message}</Typography>
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        disabled={submitting}
                      >
                        Update Settings
                      </Button>
                    </Box>
                  </form>

                </CardContent>
              </CardBase>
            </Grid>
          </PageGrid>
        )}
      </>
    );
  }
}

Settings.contextType = PersonContext;

Settings.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Settings);
