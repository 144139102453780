import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import { PersonContext } from '../contexts/PersonContext';
import { create, find, patch } from '../feathersWrapper';
import CardBase from '../components/CardBase';
import { asyncHandleChange } from '../functions/InputHandlers';
import PlaidLinkUpdate from '../components/Plaid/PlaidLinkUpdate';

const styles = () => ({
  connectionErrorButton: {
    color: 'red',
    borderColor: 'red',
  },
});

class Connections extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      editItemNameDialog: false,
      editItem: {},
      items: [],
      plaidUpdateItem: null,
      editError: null,
    };
  }

  async componentDidMount() {
    await this.getItems();
  }

  getItems = async () => {
    const { organizationId } = this.context;

    const items = await find(this, 'plaid-items', {
      query: {
        organizationId,
        includeAccounts: true,
        deleted: false,
        auth: { $or: [null, false] },
        $sort: { institutionName: 1 },
      },
    });

    this.setState({
      items: items.data,
      loading: false,
    });
  };

  patchItemName = async () => {
    const { editItem } = this.state;

    if (!editItem.institutionName) {
      this.setState({ editError: 'Please enter a name' });
      return;
    }

    await patch(this, 'plaid-items', editItem.plaidItemId, {
      institutionName: editItem.institutionName,
    });

    this.setState({ editItemNameDialog: false });

    await this.getItems();
  };

  editItemNameDialog = () => {
    const { editItemNameDialog, editItem, editError } = this.state;
    return (
      <Dialog
        open={editItemNameDialog}
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6">
            Edit Item Name
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Edit Item Name"
            value={editItem.institutionName}
            onChange={(event) => {
              const { value } = event.target;
              this.setState((previousState) => ({
                editItem: {
                  ...previousState.editItem,
                  institutionName: value,
                },
              }));
            }}
            fullWidth
          />
          {editError && (
            <Typography color="error">
              {editError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => {
              this.patchItemName();
            }}
          >
            Save
          </Button>
          <Button
            color="primary"
            onClick={() => {
              this.setState({ editItemNameDialog: false });
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  updatePlaidItem = async (plaidItemId) => {
    await asyncHandleChange('plaidUpdateItem', plaidItemId, this);
  };

  onPlaidClose = () => {
    this.setState({
      plaidUpdateItem: null,
    });
  };

  onPlaidUpdateSuccess = async () => {
    const { organizationId } = this.context;
    this.onPlaidClose();
    setTimeout(async () => {
      await create(this, 'plaid-item-status', { organizationId });
      await this.getItems();
    }, 4000);

    setTimeout(async () => {
      await this.getItems();
    }, 30000);
  };

  onUpdatePlaidItem = async (plaidItemId) => {
    await this.updatePlaidItem(plaidItemId);
  }

  render() {
    const { match, classes } = this.props;
    const { demo } = this.context;
    const {
      loading, items, editItemNameDialog, plaidUpdateItem,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        <PageGrid>
          <PageHeader
            match={match}
            title="Plaid Connections"
          />
          {!demo && plaidUpdateItem && (
            <PlaidLinkUpdate
              onSuccess={this.onPlaidUpdateSuccess}
              closeDialog={this.onPlaidClose}
              plaidItemId={plaidUpdateItem}
            />
          )}
          {editItemNameDialog && this.editItemNameDialog()}
          <Grid item xs={12}>
            <CardBase>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2">
                          Item
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          Accounts
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" align="right">
                          Action
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item) => (
                      <TableRow key={item.plaidItemId}>
                        <TableCell>
                          <Typography variant="body2">
                            {item.institutionName}
                            <EditIcon
                              fontSize="small"
                              color="primary"
                              style={{ marginLeft: '5px', cursor: 'pointer' }}
                              aria-label="edit"
                              onClick={() => {
                                this.setState({ editItemNameDialog: true, editItem: item });
                              }}
                            />
                          </Typography>
                          <Box paddingLeft={2}>
                            <Typography variant="caption">
                              {
                              item.lastSuccessfulTransactionUpdate
                                ? `Last verified: ${moment(item.lastSuccessfulTransactionUpdate).format('M/DD/YY')}`
                                : 'Awaiting data'
                              }
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {item.plaid_accounts.map((plaidAccount) => (
                            <Typography variant="body2" key={plaidAccount.plaidAccountId}>
                              {`${plaidAccount.account.name} - xxxx${plaidAccount.mask}`}
                            </Typography>
                          ))}
                        </TableCell>
                        <TableCell align="right">
                          {item.requiresUpdate ? (
                            <Button
                              className={classes.connectionErrorButton}
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                this.updatePlaidItem(item.plaidItemId);
                              }}
                            >
                              Refresh Credentials
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => {
                                this.updatePlaidItem(item.plaidItemId);
                              }}
                            >
                              Refresh Credentials
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </CardBase>
          </Grid>
        </PageGrid>
      </>
    );
  }
}

Connections.contextType = PersonContext;

Connections.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Connections);
