import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  find, get, patch, patchMultiple, remove,
} from '../feathersWrapper';

import history from '../history';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
  handleCheckboxChange,
  handleTransactionScopeChange,
} from '../functions/InputHandlers';

import {
  setJournalScope,
} from '../functions/JournalFunctions';

import CardBase from '../components/CardBase';
import PageHeader from '../components/PageHeader';
import TransactionScope from '../components/TransactionScope';

const styles = {
  grid: {
    width: '100%',
    margin: 0,
  },
  deleteConfirmationButton: {
    color: 'red',
  },
};

class EditUnit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true, confirmDelete: false };
  }

  async componentDidMount() {
    const { match } = this.props;
    const { organizationId } = this.context;
    const { unitId } = match.params;
    const unit = {};

    try {
      const oldUnit = await get(this, 'units', unitId);

      unit.id = oldUnit.id;
      unit.propertyId = oldUnit.propertyId;
      unit.name = oldUnit.name;
      unit.inactive = oldUnit.inactive;

      const query = {
        organizationId,
        unitId: unit.id,
        $limit: 0,
      };
      const journalsResponse = await find(this, 'journals', { query });

      this.setState({
        unit,
        journal: {
          journalScopeSelect: null,
          entityIdSelect: null,
          propertyIdSelect: null,
          unitIdSelect: null,
        },
        totalTransactions: journalsResponse.total,
        loading: false,
        submitting: false,
      });
    } catch (error) {
      this.setState({ error });
    }
  }

  editUnit = async (event) => {
    event.preventDefault();
    const { submitting, unit } = this.state;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    await patch(this, 'units', unit.id, unit, true)
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });

    history.push(`/properties/property/${unit.propertyId}/units/unit/${unit.id}`);
  };

  openDelete = () => {
    this.setState({ confirmDelete: true });
  };

  deleteUnit = async () => {
    const {
      unit, submitting, totalTransactions, journal,
    } = this.state;
    const { id } = unit;

    if (submitting) {
      return;
    }

    if (totalTransactions) {
      setJournalScope(journal);

      if (journal.unitId === unit.id) {
        this.setState({ error: { message: 'You cannot reassign transactions to the unit being deleted.' } });
        return;
      }
    }

    this.setState({ submitting: true });

    if (totalTransactions) {
      await patchMultiple(
        this,
        'journals',
        {
          query: {
            unitId: unit.id,
          },
        },
        {
          ...journal,
        },
      );
    }

    remove(this, 'units', id, true)
      .then(() => {
        history.push(`/properties/property/${unit.propertyId}`);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  actionButtons = () => {
    const { unit } = this.state;
    const returnArray = [
      { text: 'Delete', action: this.openDelete, class: 'delete' },
      { text: 'Cancel', link: `/properties/property/${unit.propertyId}/units/unit/${unit.id}`, class: 'cancel' },
    ];
    return returnArray;
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading, error, submitting, unit,
      confirmDelete, totalTransactions, journal,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Grid spacing={3} alignItems="center" justify="center" container className={classes.grid}>
        <PageHeader match={match} actionButtons={this.actionButtons()} title="Edit Unit" />
        <Dialog
          open={confirmDelete}
          maxWidth="sm"
          fullWidth
          onClose={() => this.setState({ confirmDelete: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Really Delete Unit?</DialogTitle>
          <DialogContent>
            {totalTransactions > 0 && (
              <>
                <DialogContentText id="alert-dialog-description">
                  {`Deleting this unit will modify ${totalTransactions} 
                    transactions currently booked to the unit. 
                    Click cancel and mark the unit inactive instead to preserve its financial records. 
                    To proceed, please select a scope. 
                    Existing booked transactions assigned to the deleted unit will be assigned to the selected scope.`}
                </DialogContentText>
                <TransactionScope
                  journal={journal}
                  transactionScopeChange={(newScopeValues) => {
                    handleTransactionScopeChange(journal, newScopeValues, this);
                  }}
                />
              </>
            )}
            {totalTransactions === 0 && (
              <DialogContentText id="alert-dialog-description">
                This unit will be removed.
              </DialogContentText>
            )}
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ confirmDelete: false })} color="primary">
              Cancel
            </Button>
            <Button onClick={this.deleteUnit} className={classes.deleteConfirmationButton} color="primary">
              Delete Unit
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <form onSubmit={this.editUnit} autoComplete="off">
                <TextField
                  label="Unit Name"
                  fullWidth
                  required
                  margin="dense"
                  InputProps={{
                    value: unit.name,
                    name: 'nested_unit_name',
                    onChange: handleTextFieldChange(this),
                  }}
                />
                <FormControl margin="none" fullWidth>
                  <FormControlLabel
                    control={<Checkbox checked={unit.inactive} onChange={handleCheckboxChange(this)} name="nested_unit_inactive" />}
                    label="This unit is inactive"
                  />
                </FormControl>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                >
                  Save
                </Button>
                <Typography color="error">{error && error.message}</Typography>
              </form>
            </CardContent>
          </CardBase>
        </Grid>
      </Grid>
    );
  }
}

EditUnit.contextType = PersonContext;

EditUnit.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(EditUnit);
