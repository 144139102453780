import makeStyles from '@material-ui/core/styles/makeStyles';

export const SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

export const COLOR = {
  ERROR: 'error',
  INFO: 'info',
  INHERIT_TEXT: 'inheritText',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const VARIANT = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
};

const useStyles = makeStyles((theme) => {
  const themeColorsMap = {
    [VARIANT.CONTAINED]: {
      [COLOR.ERROR]: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        onFocus: {
          backgroundColor: theme.palette.error._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.error.dark,
        },
      },
      [COLOR.INFO]: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        onFocus: {
          backgroundColor: theme.palette.info._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.info.dark,
        },
      },
      [COLOR.INHERIT_TEXT]: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.text.primary,
        onFocus: {
          backgroundColor: theme.palette.grey[300],
        },
        onHover: {
          backgroundColor: theme.palette.grey[100],
        },
      },
      [COLOR.PRIMARY]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        onFocus: {
          backgroundColor: theme.palette.primary._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      [COLOR.SECONDARY]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        onFocus: {
          backgroundColor: theme.palette.secondary._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
      [COLOR.SUCCESS]: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        onFocus: {
          backgroundColor: theme.palette.success._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.success.dark,
        },
      },
      [COLOR.WARNING]: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        onFocus: {
          backgroundColor: theme.palette.warning._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.warning.dark,
        },
      },
    },
    [VARIANT.OUTLINED]: {
      [COLOR.ERROR]: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.error._states.outlinedBorder}`,
        color: theme.palette.error.main,
        onFocus: {
          backgroundColor: theme.palette.error._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.error._states.hover,
        },
      },
      [COLOR.INFO]: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.info._states.outlinedBorder}`,
        color: theme.palette.info.main,
        onFocus: {
          backgroundColor: theme.palette.info._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.info._states.hover,
        },
      },
      [COLOR.INHERIT_TEXT]: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary,
        onFocus: {
          backgroundColor: 'transparent',
        },
        onHover: {
          backgroundColor: theme.palette.action.hover,
        },
      },
      [COLOR.PRIMARY]: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.primary._states.outlinedBorder}`,
        color: theme.palette.primary.main,
        onFocus: {
          backgroundColor: theme.palette.primary._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.primary._states.hover,
        },
      },
      [COLOR.SECONDARY]: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.secondary._states.outlinedBorder}`,
        color: theme.palette.secondary.main,
        onFocus: {
          backgroundColor: theme.palette.secondary._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.secondary._states.hover,
        },
      },
      [COLOR.SUCCESS]: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.success._states.outlinedBorder}`,
        color: theme.palette.success.main,
        onFocus: {
          backgroundColor: theme.palette.success._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.success._states.hover,
        },
      },
      [COLOR.WARNING]: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.warning._states.outlinedBorder}`,
        color: theme.palette.warning.main,
        onFocus: {
          backgroundColor: theme.palette.warning._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.warning._states.hover,
        },
      },
    },
    [VARIANT.TEXT]: {
      [COLOR.ERROR]: {
        backgroundColor: 'transparent',
        color: theme.palette.error.main,
        onFocus: {
          backgroundColor: theme.palette.error._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.error._states.hover,
        },
      },
      [COLOR.INFO]: {
        backgroundColor: 'transparent',
        color: theme.palette.info.main,
        onFocus: {
          backgroundColor: theme.palette.info._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.info._states.hover,
        },
      },
      [COLOR.INHERIT_TEXT]: {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        onFocus: {
          backgroundColor: 'transparent',
        },
        onHover: {
          backgroundColor: theme.palette.action.hover,
        },
      },
      [COLOR.PRIMARY]: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        onFocus: {
          backgroundColor: theme.palette.primary._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.primary._states.hover,
        },
      },
      [COLOR.SECONDARY]: {
        backgroundColor: 'transparent',
        color: theme.palette.secondary.main,
        onFocus: {
          backgroundColor: theme.palette.secondary._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.secondary._states.hover,
        },
      },
      [COLOR.SUCCESS]: {
        backgroundColor: 'transparent',
        color: theme.palette.success.main,
        onFocus: {
          backgroundColor: theme.palette.success._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.success._states.hover,
        },
      },
      [COLOR.WARNING]: {
        backgroundColor: 'transparent',
        color: theme.palette.warning.main,
        onFocus: {
          backgroundColor: theme.palette.warning._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.warning._states.hover,
        },
      },
    },
  };

  return {
    button: (props) => ({
      'backgroundColor': themeColorsMap[props.variant][props.color].backgroundColor,
      'color': themeColorsMap[props.variant][props.color].color,
      'fontWeight': theme.typography.fontWeightMedium,
      'textTransform': 'none',

      '&:hover': themeColorsMap[props.variant][props.color].onHover,
    }),
  };
});

export default useStyles;
