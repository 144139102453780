import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Box, ButtonBase } from '@material-ui/core';
import { patch, get } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';
import {
  handleAutocompleteChange,
  handleTextFieldChange,
  handleNumberFormatChange,
  asyncHandleChange,
  handleKeyboardDatePickerChange,
  handleUploaderChange,
  handleTransactionScopeChange,
} from '../../functions/InputHandlers';

import {
  nameLabel,
  getAccountOptions,
  getPaymentAccountOptions,
  getVendorOptions,
} from '../Autocomplete/Library';

import {
  setJournalScope,
  setInitialJournalScope,
} from '../../functions/JournalFunctions';

import AddVendorDialog from '../AddVendorDialog';
import TransactionScope from '../TransactionScope';

const styles = (theme) => ({
  hideWidget: {
    display: 'none',
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class EditExpenseDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true, journal: null };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { journal } = this.props;

    const stateJournal = { ...journal };
    const initialState = {
      loading: false,
      submitting: false,
      addVendorDialog: false,
      error: null,
      displayNotesSection: !!journal.notes,
    };

    await setInitialJournalScope(stateJournal, this);

    if (journal.vendor) {
      stateJournal.vendorIdSelect = await get(this, 'vendors', journal.vendorId);
    }

    stateJournal.creditLines[0].accountIdSelect = await get(this, 'accounts', journal.creditLines[0].accountId);
    stateJournal.debitLines[0].accountIdSelect = await get(this, 'accounts', journal.debitLines[0].accountId);

    initialState.expenseAccountOptions = await getAccountOptions(this, { $or: [{ type: 'Expense' }, { type2: 'Sales and Use Tax' }] });
    initialState.paymentAccountOptions = await getPaymentAccountOptions(this);
    initialState.vendorOptions = await getVendorOptions(this, true);

    initialState.journal = stateJournal;

    this.setState(initialState);
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  editTransaction = async (event) => {
    event.preventDefault();
    const {
      submitting, journal,
    } = this.state;

    const { onEditTransaction } = this.props;

    if (submitting) {
      return;
    }

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    if (!journal.amount) {
      this.setState({ error: { message: 'Please enter a non-zero amount for this transaction' } });
      return;
    }

    this.setState({ submitting: true });

    setJournalScope(journal);

    journal.debitLines[0].debit = journal.amount;
    journal.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;
    journal.creditLines[0].credit = journal.amount;
    journal.creditLines[0].accountId = journal.creditLines[0].accountIdSelect.id;
    journal.vendorId = journal.vendorIdSelect ? journal.vendorIdSelect.id : null;

    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    patch(this, 'journals', journal.id, journal, true)
      .then((result) => {
        this.closeDialog();
        onEditTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes } = this.props;
    const { vendorTracking } = this.context;
    const {
      loading,
      error,
      addVendorDialog,
      journal,
      expenseAccountOptions,
      paymentAccountOptions,
      vendorOptions,
      displayNotesSection,
    } = this.state;

    if (loading || journal === null) {
      return null;
    }

    if (addVendorDialog) {
      return (
        <AddVendorDialog
          isOpen={addVendorDialog}
          closeDialog={() => this.setState({ addVendorDialog: false })}
          onAddVendor={async (newValue) => {
            await asyncHandleChange('vendorOptions', await getVendorOptions(this, true), this);
            await asyncHandleChange('nested_journal_vendorIdSelect', await get(this, 'vendors', newValue.id), this);
          }}
        />
      );
    }

    return (
      <form onSubmit={this.editTransaction}>
        <DialogTitle id="alert-dialog-title">
          Edit Expense
        </DialogTitle>
        <DialogContent>
          <KeyboardDatePicker
            label="Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={journal.date}
            onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
            margin="dense"
            fullWidth
            clearable
            required
          />
          <FormControl margin="dense" fullWidth>
            <InputLabel required>
              Amount
            </InputLabel>
            <NumberFormat
              value={journal.amount}
              required
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              onValueChange={handleNumberFormatChange('nested_journal_amount', this)}
              customInput={Input}
            />
          </FormControl>
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValues) => {
              handleTransactionScopeChange(journal, newScopeValues, this);
            }}
          />
          <Autocomplete
            options={expenseAccountOptions}
            getOptionLabel={nameLabel}
            value={journal.debitLines[0].accountIdSelect}
            onChange={handleAutocompleteChange(
              'nested_journal_debitLines_0_accountIdSelect',
              this,
            )}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                margin="dense"
                label="Expense Account"
                placeholder="Type to Search"
                fullWidth
                required
              />
            )}
          />
          <Autocomplete
            options={paymentAccountOptions}
            getOptionLabel={nameLabel}
            disabled={journal.creditLines[0].yodleeTransactionId}
            value={journal.creditLines[0].accountIdSelect}
            onChange={handleAutocompleteChange(
              'nested_journal_creditLines_0_accountIdSelect',
              this,
            )}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                margin="dense"
                label="Payment Account"
                placeholder="Type to Search"
                fullWidth
                required
              />
            )}
          />
          {vendorTracking && (
            <Autocomplete
              options={vendorOptions}
              getOptionLabel={nameLabel}
              value={journal.vendorIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_vendorIdSelect',
                this,
                () => this.setState({ addVendorDialog: true }),
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Vendor (optional)"
                  placeholder="Type to Search"
                  fullWidth
                />
              )}
            />
          )}
          <TextField
            label="Description (optional)"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple={journal.attachmentURL && journal.attachmentURL.includes('~')}
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Expense
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

EditExpenseDialogContent.contextType = PersonContext;

EditExpenseDialogContent.defaultProps = {
};

EditExpenseDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditTransaction: PropTypes.func.isRequired,
  journal: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(EditExpenseDialogContent);
