import React from 'react';
import { Box, Button as MuiButton, DialogContent, Typography } from '@material-ui/core';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';

const SEGMENT_LOCATION = 'Onboarding - Add Sub-Portfolio: Success';

export default function SubportfolioAdded({ subportfolioCount, setAddSubportfolioDialog }) {
  const tracking = useSegmentTrack();

  return (
    <DialogContent>
      <Box mx="auto" textAlign="center" mb={2}>
        <Typography color="secondary">
          <HomeWorkOutlinedIcon />
        </Typography>
        <Typography variant="caption" gutterBottom>
          ADD YOUR SUB-PORTFOLIOS
        </Typography>
        <Typography variant="h6" gutterBottom>
          {`You've created ${pluralize('sub-portfolio', subportfolioCount, true)}.`}
        </Typography>
      </Box>
      <Box mb={1} textAlign="center">
        <Typography variant="body1">
          {`Sub-portfolios provide an additional layer of reporting between the overall
                portfolio and individual properties.`}
        </Typography>
      </Box>
      <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
        <Button
          hasFullWidth
          variant="contained"
          onClick={() => {
            tracking('finished clicked', { location: SEGMENT_LOCATION });
            history.push('/accounts/banking');
          }}
        >
          Finished
        </Button>
        <Box mt={1}>
          <MuiButton
            color="secondary"
            variant="outlined"
            size="large"
            fullWidth
            onClick={() => {
              tracking('add_another_sub-portfolio clicked', {
                location: SEGMENT_LOCATION,
              });
              setAddSubportfolioDialog(true);
            }}
          >
            Add Another Sub-Portfolio
          </MuiButton>
        </Box>
      </Box>
    </DialogContent>
  );
}

SubportfolioAdded.propTypes = {
  subportfolioCount: PropTypes.number.isRequired,
  setAddSubportfolioDialog: PropTypes.func.isRequired,
};
