import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinkBase from './LinkBase';

const styles = (theme) => ({
  root: {
    width: 'fit-content',
    minWidth: '100%',
  },
  left: {
    minWidth: '175px',
    flexGrow: 1,
    position: 'sticky',
    left: 0,
    background: 'white',
  },
  right: {
    textAlign: 'right',
    width: '175px',
    minWidth: '175px',
    overflow: 'hidden',
    paddingLeft: '5px',
  },
  underline: { borderBottom: `1px solid ${theme.palette.text.disabled}` },
  overline: { borderTop: `1px solid ${theme.palette.text.disabled}` },
  bold: { fontWeight: 600 },
  marginBottom: { marginBottom: theme.spacing(4) },
  indent: { paddingLeft: theme.spacing(2) },
  indent2: { paddingLeft: theme.spacing(4) },
  indent3: { paddingLeft: theme.spacing(6) },
  indent4: { paddingLeft: theme.spacing(8) },
  spacing: { flexGrow: 9999 },
});

class FinancialAccountLine extends React.PureComponent {
  accountAddressString = () => {
    const {
      accountId, entityId, propertyId, unitId, startDate, endDate,
    } = this.props;
    let linkAddressString = `/reports/account-register?accountId=${accountId}`;
    if (entityId) {
      linkAddressString += `&entityId=${entityId}`;
    }
    if (propertyId) {
      linkAddressString += `&propertyId=${propertyId}`;
    }
    if (unitId) {
      linkAddressString += `&unitId=${unitId}`;
    }
    if (startDate) {
      linkAddressString += `&startDate=${startDate}`;
    }
    if (endDate) {
      linkAddressString += `&endDate=${endDate}`;
    }
    return linkAddressString;
  };

  vendorAddressString = () => {
    const {
      vendorId, startDate, endDate, entityId, propertyId, unitId,
    } = this.props;
    let linkAddressString = `/transactions/booked?vendorId=${vendorId}`;
    if (startDate) {
      linkAddressString += `&startDate=${startDate}`;
    }
    if (endDate) {
      linkAddressString += `&endDate=${endDate}`;
    }
    if (entityId) {
      linkAddressString += `&entityId=${entityId}`;
    }
    if (propertyId) {
      linkAddressString += `&propertyId=${propertyId}`;
    }
    if (unitId) {
      linkAddressString += `&unitId=${unitId}`;
    }
    return linkAddressString;
  };

  displayTotal = () => {
    const { subaccounts } = this.props;
    let result = false;
    subaccounts.forEach((account) => {
      if (account.display === true) {
        result = true;
      }
    });
    return result;
  };

  getTotalValues = () => {
    const { values, subaccounts } = this.props;
    const sumArray = [];
    values.forEach((value) => {
      sumArray.push(value);
    });
    subaccounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] += value;
      });
    });
    return sumArray;
  };

  noValueCell = () => {
    const {
      values, value, textValue, classes, sumline,
    } = this.props;
    const rightClasses = classNames({
      [classes.right]: true,
      [classes.overline]: sumline,
      [classes.spacing]: true,
    });
    if (values.length > 0 || value !== undefined || textValue) {
      return null;
    }
    return <Grid item className={rightClasses} />;
  };

  displayValue = (value) => {
    const {
      displayType, prefix, suffix, decimalScale, invertSign,
    } = this.props;
    if (Number.isNaN(Number(value)) || displayType === 'text') {
      return value;
    }
    let displayValue = value;
    if (invertSign) {
      displayValue = -1 * value;
    }
    if (displayValue >= 0) {
      return (
        <NumberFormat
          displayType="text"
          value={displayValue}
          thousandSeparator
          prefix={prefix}
          suffix={suffix}
          decimalScale={decimalScale}
          fixedDecimalScale
        />
      );
    }
    return (
      <NumberFormat
        displayType="text"
        value={-displayValue}
        thousandSeparator
        prefix={`(${prefix}`}
        suffix={`${suffix})`}
        decimalScale={decimalScale}
        fixedDecimalScale
      />
    );
  }

  render() {
    const {
      classes, display, label, values, value, textValue, subaccounts,
      underline, overline, bold, marginBottom, indent, sumline,
      accountId, vendorId,
      prefix, suffix, decimalScale, invertSign,
    } = this.props;

    const rootClasses = classNames({
      [classes.root]: true,
      [classes.underline]: underline,
      [classes.overline]: overline,
      [classes.marginBottom]: marginBottom,
    });
    const leftClasses = classNames({
      [classes.left]: true,
      [classes.indent]: indent === 1,
      [classes.indent2]: indent === 2,
      [classes.indent3]: indent === 3,
      [classes.indent4]: indent === 4,
    });
    const rightClasses = classNames({
      [classes.right]: true,
      [classes.overline]: sumline,
    });
    const textClasses = classNames({
      [classes.text]: true,
      [classes.bold]: bold,
    });

    if (!display) {
      return null;
    }

    return (
      <>
        <Grid container wrap="nowrap" className={rootClasses}>
          <Grid item className={leftClasses}>
            {accountId !== null && (
              <LinkBase to={this.accountAddressString()}>
                <Typography variant="body1" className={textClasses}>
                  {label}
                </Typography>
              </LinkBase>
            )}
            {vendorId !== null && (
              <LinkBase to={this.vendorAddressString()}>
                <Typography variant="body1" className={textClasses}>
                  {label}
                </Typography>
              </LinkBase>
            )}
            {accountId === null && vendorId === null && (
              <Typography variant="body1" className={textClasses}>
                {label}
              </Typography>
            )}
          </Grid>
          {(value !== undefined || textValue) && (
            <Grid item className={rightClasses}>
              <Typography variant="body1" className={textClasses}>
                {textValue}
                {value >= 0 && (
                  <NumberFormat
                    displayType="text"
                    value={value}
                    thousandSeparator
                    prefix={prefix}
                    suffix={suffix}
                    decimalScale={decimalScale}
                    fixedDecimalScale
                  />
                )}
                {value < 0 && (
                  <NumberFormat
                    displayType="text"
                    value={-value}
                    thousandSeparator
                    prefix={`(${prefix}`}
                    suffix={`${suffix})`}
                    decimalScale={decimalScale}
                    fixedDecimalScale
                  />
                )}
              </Typography>
            </Grid>
          )}
          {values.map((arrayValue, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item className={rightClasses} key={index}>
              <Typography variant="body1" className={textClasses}>
                {this.displayValue(arrayValue)}
              </Typography>
            </Grid>
          ))}
          {this.noValueCell()}
        </Grid>
        {subaccounts.map((subaccount) => (
          <FinancialAccountLine
            {...this.props} //eslint-disable-line
            key={subaccount.id}
            accountId={subaccount.id}
            label={subaccount.name}
            values={subaccount.values}
            display={subaccount.display}
            subaccounts={subaccounts.subaccounts}
            indent={indent + 1}
          />
        ))}
        {this.displayTotal() && (
          <FinancialAccountLine
            classes={classes}
            label={`Total ${label}`}
            indent={indent}
            values={this.getTotalValues()}
            invertSign={invertSign}
          />
        )}
      </>
    );
  }
}

FinancialAccountLine.defaultProps = {
  label: '',
  underline: false,
  overline: false,
  bold: false,
  marginBottom: false,
  value: undefined,
  textValue: null,
  values: [],
  displayType: null,
  display: true,
  subaccounts: [],
  accountId: null,
  vendorId: null,
  indent: false,
  sumline: false,
  entityId: null,
  propertyId: null,
  unitId: null,
  startDate: null,
  endDate: null,
  prefix: '$',
  suffix: '',
  decimalScale: 2,
  invertSign: false,
};

FinancialAccountLine.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  value: PropTypes.node,
  textValue: PropTypes.node,
  values: PropTypes.node,
  displayType: PropTypes.node,
  display: PropTypes.bool,
  subaccounts: PropTypes.arrayOf(PropTypes.object),
  bold: PropTypes.bool,
  underline: PropTypes.bool,
  overline: PropTypes.bool,
  marginBottom: PropTypes.bool,
  accountId: PropTypes.node,
  vendorId: PropTypes.node,
  entityId: PropTypes.node,
  propertyId: PropTypes.node,
  unitId: PropTypes.node,
  startDate: PropTypes.node,
  endDate: PropTypes.node,
  indent: PropTypes.node,
  sumline: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  decimalScale: PropTypes.number,
  invertSign: PropTypes.bool,
};

export default withStyles(styles)(FinancialAccountLine);
