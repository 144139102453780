const discountPercent = 50;

export const planOptions = [
  {
    name: 'Essentials',
    price: 15.0,
    discountPrice: 15.0 * (1.0 - discountPercent / 100.0),
    units: 'Up to 3',
    unitsIncluded: '3',
    maxUnits: 3,
    billing: 'Billed monthly',
    id: 'essential',
  },
  {
    name: 'Growth',
    price: 25.0,
    discountPrice: 25.0 * (1.0 - discountPercent / 100.0),
    units: 'Up to 10',
    unitsIncluded: '10',
    maxUnits: 10,
    billing: 'Billed monthly',
    id: 'growth',
  },
  {
    name: 'Investor',
    price: 45.0,
    discountPrice: 45.0 * (1.0 - discountPercent / 100.0),
    units: 'Up to 20',
    unitsIncluded: '20',
    maxUnits: 20,
    billing: 'Billed monthly',
    id: 'investor',
  },
  {
    name: 'Professional',
    price: 80.0,
    discountPrice: 80.0 * (1.0 - discountPercent / 100.0),
    units: 'Unlimited',
    unitsIncluded: 'unlimited',
    billing: 'Billed monthly',
    id: 'professional',
  },
];

export const annualPlanOptions = [
  {
    name: 'Essentials',
    price: 180.0,
    units: 'Up to 3',
    unitsIncluded: 3,
    maxUnits: 3,
    billing: 'Billed annually',
    id: 'essential_annual',
  },
  {
    name: 'Growth',
    price: 300.0,
    units: 'Up to 10',
    unitsIncluded: 10,
    maxUnits: 10,
    billing: 'Billed annually',
    id: 'growth_annual',
  },
  {
    name: 'Investor',
    price: 540.0,
    units: 'Up to 20',
    unitsIncluded: 20,
    maxUnits: 20,
    billing: 'Billed annually',
    id: 'investor_annual',
  },
  {
    name: 'Professional',
    price: 960.0,
    units: 'Unlimited',
    unitsIncluded: 'unlimited',
    billing: 'Billed annually',
    id: 'professional_annual',
  },
];
