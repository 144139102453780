import React, { useEffect, useMemo, useState } from 'react';
import { TextField, debounce } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { find } from '../feathersWrapper';

export default function AsyncAutocomplete({ type, onAddValue, label }) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const loaded = React.useRef(false);

  const search = useMemo(
    () => debounce(async (input, callback) => {
      const query = {
        $limit: 50,
        search: input,
      };

      let tableName;

      if (type === 'user') {
        tableName = 'users';
      } else if (type === 'portfolio') {
        tableName = 'organizations';
      }

      await find(this, tableName, {
        query,
      }).then((results) => {
        callback(results);
      });
    }, 1000),
    [type],
  );

  useEffect(() => {
    let active = true;

    if (!loaded.current) {
      loaded.current = true;
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
    }

    if (!inputValue) {
      setValue(null);
    } else {
      search(inputValue, (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results.data.length > 0) {
            let formatted;
            if (type === 'user') {
              formatted = results.data.map((user) => (
                {
                  label: `${user.firstName} ${user.lastName} (${user.email}) (${user.phone})`,
                  id: user.id,
                }
              ));
            } else if (type === 'portfolio') {
              formatted = results.data.map((portfolio) => (
                {
                  label: `${portfolio.name} (${portfolio.id})`,
                  id: portfolio.id,
                }
              ));
            }

            newOptions = [...newOptions, ...formatted];
          }

          setOptions(newOptions);
        }
      });
    }
    return () => {
      active = false;
    };
  }, [
    value,
    inputValue,
    search,
    type,
  ]);

  return (
    <>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.label}
        value={value}
        // disableClearable
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          onAddValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params /* eslint-disable-line react/jsx-props-no-spreading */}
            margin="dense"
            label={label}
            placeholder="Type to search"
            fullWidth
          />
        )}
      />
    </>
  );
}

AsyncAutocomplete.defaultProps = {
  onAddValue: () => {},
  onChange: () => {},
};

AsyncAutocomplete.propTypes = {
  type: PropTypes.string.isRequired,
  onAddValue: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
};
