import React from 'react';
import { Box, CircularProgress, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    position: 'fixed',
    color: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: theme.zIndex.modal + 1,
  },
}));

export default function LoadingOverlay({ visible }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (!visible) {
    return null;
  }

  return (
    <Box className={classes.loadingOverlay}>
      <CircularProgress color="inherit" />
    </Box>
  );
}

LoadingOverlay.propTypes = {
  visible: PropTypes.bool.isRequired,
};
