import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, IconButton } from '@material-ui/core';
import { ArrowRight, ArrowDropDown } from '@material-ui/icons';
import LinkBase from './LinkBase';

const styles = (theme) => ({
  root: {
    width: 'fit-content',
    minWidth: '100%',
  },
  arrowContainer: {
    width: '30px',
    justifySelf: 'flex-start',
  },
  left: {
    flexGrow: 1,
    minWidth: '365px',
    position: 'sticky',
    left: 0,
    background: 'white',
  },
  right: {
    textAlign: 'right',
    width: '175px',
    minWidth: '175px',
    overflow: 'hidden',
    paddingLeft: '5px',
  },
  underline: { borderBottom: `1px solid ${theme.palette.text.disabled}` },
  overline: { borderTop: `1px solid ${theme.palette.text.disabled}` },
  bold: { fontWeight: 600 },
  marginBottom: { marginBottom: theme.spacing(4) },
  indent: { paddingLeft: theme.spacing(2) },
  indent2: { paddingLeft: theme.spacing(4) },
  indent3: { paddingLeft: theme.spacing(6) },
  indent4: { paddingLeft: theme.spacing(8) },
  spacing: { flexGrow: 9999 },
});

class FinancialAccountLine2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsible: false,
      collapsed: false,
    };
  }

  componentDidMount() {
    this.checkForCollapsible();
  }

  componentDidUpdate() {
    const { collapsible } = this.state;
    if (collapsible) {
      this.setState({ collapsible: false });
    }
    this.checkForCollapsible();
  }

  checkForCollapsible = () => {
    const { subaccounts } = this.props;

    let displaySubs = false;
    if (subaccounts.length) {
      displaySubs = subaccounts.reduce(
        (acc, account) => acc + account.display, 0,
      );

      if (displaySubs > 0) {
        this.setState({ collapsible: true });
      }
    }
  };

  accountAddressString = (index) => {
    const {
      accountId, filter, additionalCriteria, mileage,
    } = this.props;

    const newFilter = {
      ...filter,
      ...additionalCriteria[index],
    };

    let linkAddressString = `/reports/account-register?accountId=${accountId}`;

    if (mileage) {
      linkAddressString = '/organization/mileage?';
    }

    if (newFilter.startDate) {
      linkAddressString += `&startDate=${newFilter.startDate}`;
    }
    if (newFilter.endDate) {
      linkAddressString += `&endDate=${newFilter.endDate}`;
    }
    if (newFilter.propertyId) {
      linkAddressString += `&propertyId=${newFilter.propertyId}`;
    }
    if (newFilter.unitId) {
      linkAddressString += `&unitId=${newFilter.unitId}`;
    }
    if (newFilter.entityId) {
      linkAddressString += `&entityId=${newFilter.entityId}`;
    }

    // remove ? if there is one at end of string
    if (linkAddressString.charAt(linkAddressString.length - 1) === '?') {
      linkAddressString = linkAddressString.substring(0, linkAddressString.length - 1);
    }
    // parameter formatting
    linkAddressString = linkAddressString.replace('?&', '?');

    return linkAddressString;
  };

  displayTotal = () => {
    const { subaccounts } = this.props;
    let result = false;
    subaccounts.forEach((account) => {
      if (account.display === true) {
        result = true;
      }
    });
    return result;
  };

  getTotalValues = () => {
    const { values, subaccounts } = this.props;
    const sumArray = [];
    values.forEach((value) => {
      sumArray.push(value);
    });
    subaccounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] += value;
      });
    });
    return sumArray;
  };

  noValueCell = () => {
    const {
      values, classes, sumline,
    } = this.props;
    const rightClasses = classNames({
      [classes.right]: true,
      [classes.overline]: sumline,
      [classes.spacing]: true,
    });
    if (values.length > 0) {
      return null;
    }
    return <Grid item className={rightClasses} />;
  };

  displayValue = (value) => {
    const {
      displayType, prefix, suffix, decimalScale, invertSign,
    } = this.props;
    if (Number.isNaN(Number(value)) || displayType === 'text') {
      return value;
    }
    let displayValue = value;
    if (invertSign) {
      displayValue = -1 * value;
    }
    if (displayValue >= 0) {
      return (
        <NumberFormat
          displayType="text"
          value={displayValue}
          thousandSeparator
          prefix={prefix}
          suffix={suffix}
          decimalScale={decimalScale}
          fixedDecimalScale
        />
      );
    }
    return (
      <NumberFormat
        displayType="text"
        value={-displayValue}
        thousandSeparator
        prefix={`(${prefix}`}
        suffix={`${suffix})`}
        decimalScale={decimalScale}
        fixedDecimalScale
      />
    );
  };

  render() {
    const {
      classes,
      display,
      label,
      values,
      subaccounts,
      underline,
      overline,
      bold,
      marginBottom,
      indent,
      sumline,
      accountId,
      invertSign,
      mileage,
      noSpacing,
    } = this.props;

    const { collapsible, collapsed } = this.state;

    const rootClasses = classNames({
      [classes.root]: true,
      [classes.underline]: underline,
      [classes.overline]: overline,
      [classes.marginBottom]: marginBottom,
    });
    const leftClasses = classNames({
      [classes.left]: true,
      [classes.indent]: indent === 1,
      [classes.indent2]: indent === 2,
      [classes.indent3]: indent === 3,
      [classes.indent4]: indent === 4,
    });
    const rightClasses = classNames({
      [classes.right]: true,
      [classes.overline]: sumline,
    });
    const textClasses = classNames({
      [classes.text]: true,
      [classes.bold]: bold,
    });

    if (!display) {
      return null;
    }

    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box className={!noSpacing ? classes.arrowContainer : null}>
          {collapsible && (
            <IconButton size="small" color="inherit" onClick={() => this.setState({ collapsed: !collapsed })}>
              {collapsed ? (<ArrowRight fontSize="small" />) : (<ArrowDropDown fontSize="small" />)}
            </IconButton>
          )}
        </Box>
        <Box style={{ width: '100%' }}>
          {!collapsed && (
            <Grid container wrap="nowrap" className={rootClasses}>
              <Grid item className={leftClasses}>
                <Typography variant="body1" className={textClasses}>
                  {label}
                </Typography>
              </Grid>
              {values.map((arrayValue, index) => (
              // eslint-disable-next-line react/no-array-index-key
                <Grid item className={rightClasses} key={index}>
                  {accountId !== null || mileage ? (
                    <LinkBase to={this.accountAddressString(index)}>
                      <Typography variant="body1" className={textClasses}>
                        {this.displayValue(arrayValue)}
                      </Typography>
                    </LinkBase>
                  ) : (
                    <Typography variant="body1" className={textClasses}>
                      {this.displayValue(arrayValue)}
                    </Typography>
                  )}
                </Grid>
              ))}
              {this.noValueCell()}
            </Grid>
          )}
          {!collapsed && (
            <>
              {subaccounts.map((subaccount) => (
                <FinancialAccountLine2
                  {...this.props} //eslint-disable-line
                  key={subaccount.id}
                  accountId={subaccount.id}
                  label={subaccount.name}
                  values={subaccount.values}
                  display={subaccount.display}
                  subaccounts={subaccounts.subaccounts}
                  indent={indent + 1}
                  noSpacing
                />
              ))}
            </>
          )}
          {this.displayTotal() && (
          <FinancialAccountLine2
            classes={classes}
            label={`Total ${label}`}
            indent={indent}
            values={this.getTotalValues()}
            invertSign={invertSign}
            noSpacing
          />
          )}
        </Box>
      </Box>
    );
  }
}

FinancialAccountLine2.defaultProps = {
  label: '',
  underline: false,
  overline: false,
  bold: false,
  marginBottom: false,
  value: undefined,
  values: [],
  displayType: null,
  display: true,
  subaccounts: [],
  accountId: null,
  indent: false,
  sumline: false,
  prefix: '$',
  suffix: '',
  decimalScale: 2,
  invertSign: false,
  filter: {},
  additionalCriteria: [],
  mileage: false,
  noSpacing: false,
};

FinancialAccountLine2.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  value: PropTypes.node,
  values: PropTypes.node,
  displayType: PropTypes.node,
  display: PropTypes.bool,
  subaccounts: PropTypes.arrayOf(PropTypes.object),
  bold: PropTypes.bool,
  underline: PropTypes.bool,
  overline: PropTypes.bool,
  marginBottom: PropTypes.bool,
  accountId: PropTypes.node,
  indent: PropTypes.node,
  sumline: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  decimalScale: PropTypes.number,
  invertSign: PropTypes.bool,
  filter: PropTypes.object,
  additionalCriteria: PropTypes.array,
  mileage: PropTypes.bool,
  noSpacing: PropTypes.bool,
};

export default withStyles(styles)(FinancialAccountLine2);
