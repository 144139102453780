import React, { useEffect, useState } from 'react';
import {
  Box,
  Button as MuiButton,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useOnboardingContext } from '~/contexts/OnboardingProvider';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';
import { BEST_DESCRIBES_YOU } from '~/views/onboarding/constants';

const SEGMENT_LOCATION = 'Onboarding - Add Sub-Portfolio: Intro';
const PROPERTY_MANAGER_SEGMENT_LOCATION = `${SEGMENT_LOCATION} (Property Manager)`;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  descriptionContainer: {
    marginTop: theme.spacing(2),
  },
  caption: {
    color: '#138EE6',
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  button: {
    marginInline: 'auto',
    maxWidth: '400px',
    width: '100%',
  },
}));

export default function SubportfoliosOverview({ openAddSubportfolioDialog }) {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const { userOnboarding } = useOnboardingContext();

  const isPropertyManager =
    userOnboarding?.bestDescribesYou === BEST_DESCRIBES_YOU.PROPERTY_MANAGER ||
    userOnboarding?.bestDescribesYou === BEST_DESCRIBES_YOU.MIX_OF_BOTH;
  const hasEnoughMultipleProperties = !isPropertyManager && userOnboarding?.totalUnits >= 4;

  useEffect(() => {
    // Since we render content based on one of these conditions, we need to redirect to the accounts link page if neither is true
    if (isPropertyManager === false && hasEnoughMultipleProperties === false) {
      history.push('/accounts/banking');
    }
  }, [isPropertyManager, hasEnoughMultipleProperties]);

  const tracking = useSegmentTrack();
  const selectedSegmentLocation = isPropertyManager ? PROPERTY_MANAGER_SEGMENT_LOCATION : SEGMENT_LOCATION;

  const handleNext = () => {
    tracking(`${isPropertyManager || selectedOption === 'yes' ? 'skip_for_now' : 'next'} clicked`, {
      location: selectedSegmentLocation,
    });
    history.push('/accounts/banking');
  };

  const handleAddSubportfolio = () => {
    tracking('add_sub-portfolio clicked', { location: selectedSegmentLocation });
    openAddSubportfolioDialog();
  };

  const classes = useStyles();

  return (
    <DialogContent className={classes.container}>
      {isPropertyManager && (
        <>
          <Typography className={classes.caption}>Recommended for Property Managers</Typography>
          <Typography variant="h6">Create sub-portfolios to easily group and track properties by owner</Typography>
          <SubportfoliosDescription selectedSegmentLocation={selectedSegmentLocation} />
        </>
      )}

      {hasEnoughMultipleProperties && (
        <>
          <Typography id="multiple-llcs-for-rental-business-question" variant="h6">
            Do you have multiple LLCs for your rental business?
          </Typography>
          <RadioGroup
            aria-labelledby="multiple-llcs-for-rental-business-question"
            name="multiple-llcs-for-rental-business"
            value={selectedOption}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>

          {selectedOption === 'yes' && (
            <Box className={classes.descriptionContainer}>
              <Typography className={classes.caption}>Recommended</Typography>
              <Typography variant="h6">Add sub-portfolios to group properties by entity</Typography>
              <SubportfoliosDescription selectedSegmentLocation={selectedSegmentLocation} />
            </Box>
          )}
          {selectedOption === 'no' && (
            <Box className={classes.buttonsContainer}>
              <Button hasFullWidth variant="contained" onClick={handleNext} className={classes.button}>
                Next
              </Button>
            </Box>
          )}
        </>
      )}

      {(hasEnoughMultipleProperties && selectedOption === 'yes') || isPropertyManager ? (
        <Box className={classes.buttonsContainer}>
          <Button hasFullWidth variant="contained" onClick={handleAddSubportfolio} className={classes.button}>
            Add Sub-Portfolio
          </Button>
          <MuiButton color="primary" variant="outlined" onClick={handleNext} className={classes.button}>
            Skip For Now
          </MuiButton>
        </Box>
      ) : null}
    </DialogContent>
  );
}

SubportfoliosOverview.propTypes = {
  openAddSubportfolioDialog: PropTypes.func.isRequired,
};

function SubportfoliosDescription({ selectedSegmentLocation }) {
  const tracking = useSegmentTrack();

  const handleLearnMore = () => {
    tracking('learn_about_subportfolios_link clicked', { location: selectedSegmentLocation });
  };

  return (
    <Typography variant="body2">
      Sub-portfolios add a reporting layer between your overall portfolio and individual properties.{' '}
      <a
        href="https://support.reihub.net/en/articles/9718906-about-sub-portfolios"
        rel="noreferrer"
        target="_blank"
        onClick={handleLearnMore}
      >
        Learn about sub-portfolios.
      </a>
    </Typography>
  );
}

SubportfoliosDescription.propTypes = {
  selectedSegmentLocation: PropTypes.string.isRequired,
};
