import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import NumberFormat from 'react-number-format';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Input,
  InputLabel,
  FormControl,
  DialogContentText,
  IconButton,
  Typography,
} from '@material-ui/core/';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { get, patch, remove } from '../feathersWrapper';
import {
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleSearchSelectChange,
  handleTextFieldChange,
} from '../functions/InputHandlers';

import SearchSelect from './SearchSelect/SearchSelect';
import { searchProperties } from './SearchSelect/SearchFunctions';
import { PersonContext } from '../contexts/PersonContext';

const styles = (theme) => ({
  datetime: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '4px',
  },
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: 'red',
  },
  deleteConfirmationButton: {
    color: 'red',
  },
});

class EditHoursDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  getHoursData = async () => {
    const { hoursId } = this.props;
    const hours = await get(this, 'hours', hoursId);

    hours.dateSelect = moment(hours.date);
    hours.propertyIdSelect = hours.property
      ? {
        value: hours.propertyId,
        label: hours.property.address1,
      }
      : null;

    this.setState({
      hours,
      error: null,
      loading: false,
      submitting: false,
      confirmDelete: false,
    });
  };

  editHours = async (event) => {
    event.preventDefault();
    const { submitting, hours } = this.state;

    const { onEdit, hoursId } = this.props;

    if (submitting) {
      return;
    }

    if (!hours.date) {
      this.setState({ error: { message: 'Please enter a valid date for these hours' } });
      return;
    }

    if (!hours.hours) {
      this.setState({ error: { message: 'Please enter the hours you would like to track' } });
      return;
    }

    this.setState({ submitting: true });

    patch(this, 'hours', hoursId, hours, true)
      .then((result) => {
        onEdit(result);
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  deleteHours = () => {
    const { submitting } = this.state;
    const { onDelete, hoursId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    remove(this, 'hours', hoursId, true)
      .then(() => {
        onDelete();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const {
      loading, error, hours, confirmDelete,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.getHoursData}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && !confirmDelete && (
          <form onSubmit={this.editHours}>
            <DialogTitle id="alert-dialog-title">
              Edit Hours
              <IconButton
                className={classes.deleteIconButton}
                aria-label="delete"
                onClick={() => this.setState({ confirmDelete: true })}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <KeyboardDatePicker
                className={classes.datetime}
                label="Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={hours.date}
                onChange={handleKeyboardDatePickerChange(
                  'nested_hours_date',
                  this,
                )}
                margin="dense"
                fullWidth
                clearable
                required
              />
              <FormControl margin="dense" fullWidth>
                <InputLabel required>Hours</InputLabel>
                <NumberFormat
                  value={hours.hours}
                  required
                  thousandSeparator
                  decimalScale={1}
                  onValueChange={handleNumberFormatChange(
                    'nested_hours_hours',
                    this,
                  )}
                  customInput={Input}
                />
              </FormControl>
              <TextField
                label="Description (Optional)"
                fullWidth
                multiline
                maxRows="5"
                margin="dense"
                InputProps={{
                  value: hours.description,
                  name: 'nested_hours_description',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <SearchSelect
                searchFunction={searchProperties(this, {})}
                changeFunction={handleSearchSelectChange(this)}
                label="Property"
                name="nested_hours_propertyId"
                isMulti={false}
                value={hours.propertyIdSelect}
                margin="dense"
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disableElevation
              >
                Save Changes
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <>
            <DialogTitle id="alert-dialog-title">
              Really Delete Hours?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                These hours will be removed.
              </DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button
                onClick={this.deleteHours}
                className={classes.deleteConfirmationButton}
              >
                Delete Hours
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

EditHoursDialog.contextType = PersonContext;

EditHoursDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  hoursId: PropTypes.node.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default withStyles(styles)(EditHoursDialog);
