import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { PersonContext } from '../../contexts/PersonContext';
import { create } from '../../feathersWrapper';
import { asyncSetState } from '../../functions/InputHandlers';
import history from '../../history';
import Button from '../Button';
import SubscribeDialog from '../SubscribeDialog';

import BuyNowPromoModal from './BuyNowPromoModal';

const styles = (theme) => ({
  tooltip: {
    position: 'relative',
    width: '100%',
    zIndex: theme.zIndex.drawer + 2,
    marginTop: '-50px',
  },
  arrow: {
    zIndex: theme.zIndex.drawer + 3,
    position: 'relative',
    textAlign: 'center',
    top: '-1px',
    marginBottom: '-16px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  checks: {
    marginRight: '16px',
    color: '#431C5D',
  },
  price: {
    color: '#491661',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  regularPrice: {
    color: theme.palette.action.active,
    textDecoration: 'line-through',
  },
});

class WelcomeTour extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      runTour: true,
      stepIndex: 0,
      subscribeDialog: false,
    };
  }

  async componentDidMount() {
    const { organizationId } = this.context;
    const unitCount = await create(this, 'reports', {
      organizationId,
      reportName: 'unitCount',
    });

    const discount = 60;
    let planSelect;

    const planOptions = [
      {
        name: 'Essentials',
        price: 15.0,
        discountPrice: 15.0 * (1.0 - discount / 100.0),
        units: 'Up to 3 units',
        maxUnits: 3,
        billing: 'Billed monthly',
        id: 'essential',
      },
      {
        name: 'Growth',
        price: 25.0,
        discountPrice: 25.0 * (1.0 - discount / 100.0),
        units: 'Up to 10 units',
        maxUnits: 10,
        billing: 'Billed monthly',
        id: 'growth',
      },
      {
        name: 'Investor',
        price: 45.0,
        discountPrice: 45.0 * (1.0 - discount / 100.0),
        units: 'Up to 20 units',
        maxUnits: 20,
        billing: 'Billed monthly',
        id: 'investor',
      },
      {
        name: 'Professional',
        price: 80.0,
        discountPrice: 80.0 * (1.0 - discount / 100.0),
        units: 'Unlimited units',
        billing: 'Billed monthly',
        id: 'professional',
      },
    ];

    if (unitCount > 20) {
      planSelect = planOptions[3];
    } else if (unitCount > 10) {
      planSelect = planOptions[2];
    } else if (unitCount > 3) {
      planSelect = planOptions[1];
    } else {
      planSelect = planOptions[0];
    }

    await asyncSetState(
      {
        run: true,
        unitCount,
        discount,
        planSelect,
      },
      this,
    );
    setTimeout(() => {
      document.activeElement.blur();
    }, 100);
  }

  onWelcomeSubscribed = () => {
    const { organizationId } = this.context;
    this.setState({ planSelect: null });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'onboardingSubscribed',
      organizationId,
    });
  };

  getStep = (index) => {
    const { partnerName, promptPayment } = this.context;
    const { planSelect } = this.state;

    let stepArray = [
      {
        url: '/properties',
        content: () => (
          <>
            <DialogTitle id="alert-dialog-title">Properties</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`REI Hub is built for property-based bookkeeping. As you enter your transactions, you'll select the desired
                property to segment your financials. View a property's dashboard, select it in reports, or use the
                filter buttons throughout the site to view information on a per property basis.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.nextStep}>
                Continue
              </Button>
              <MuiButton onClick={this.skipTour} color="primary">
                Skip
              </MuiButton>
            </DialogActions>
          </>
        ),
      },
    ];

    if (partnerName === 'Innago') {
      stepArray.push({
        url: '/accounts/partner',
        content: () => (
          <>
            <DialogTitle id="alert-dialog-title">Innago</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Your Innago payment data has been queued for transfer - Innago will export historic payment information to REI Hub overnight.
                  After that, we'll continue to automatically import your collected payments from ${partnerName}.
                Our systems work together to give you access to up-to-date financials with all transactions booked to the appropriate property or unit.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.nextStep}>
                Continue
              </Button>
              <MuiButton onClick={this.skipTour} color="primary">
                Skip
              </MuiButton>
            </DialogActions>
          </>
        ),
      });
    } else if (partnerName) {
      stepArray.push({
        url: '/accounts/partner',
        content: () => (
          <>
            <DialogTitle id="alert-dialog-title">{partnerName}</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Your ${partnerName} payment data has been successfully transferred.
                We'll continue to automatically import your collected payments from ${partnerName}.
                Our systems work together to give you instant access to up-to-date financials with all transactions booked to the appropriate property or unit.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.nextStep}>
                Continue
              </Button>
              <MuiButton onClick={this.skipTour} color="primary">
                Skip
              </MuiButton>
            </DialogActions>
          </>
        ),
      });
    }

    stepArray = stepArray.concat([
      {
        url: '/accounts/banking',
        content: () => (
          <>
            <DialogTitle id="alert-dialog-title">Bank Accounts</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`The banking page shows bank and credit card accounts that you've added to REI Hub.
                View an account's register to see all booked transactions recorded in your financial records,
                or select the import feed to view recently imported transactions and book them to the register.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.nextStep}>
                Continue
              </Button>
              <MuiButton onClick={this.skipTour} color="primary">
                Skip
              </MuiButton>
            </DialogActions>
          </>
        ),
      },
      {
        url: '/accounts/banking/import-feed',
        content: () => (
          <>
            <DialogTitle id="alert-dialog-title">Transaction Import Feed</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Recent transactions in linked accounts are automatically downloaded to your import feed.
                  Click the 'Book' button to use our built in real estate transaction templates to categorize
                  items in just a few clicks - or create matching rules to start doing the work for you.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.nextStep}>
                Continue
              </Button>
              <MuiButton onClick={this.skipTour} color="primary">
                Skip
              </MuiButton>
            </DialogActions>
          </>
        ),
      },
      {
        url: '/accounts/loans',
        content: () => (
          <>
            <DialogTitle id="alert-dialog-title">Loans</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Mortgages and other loans complete your financial picture.
                  REI Hub even recreates your amortization schedule to split each month's payment into principal, interest, and escrow components
                  for accurate views of profitability and cashflow.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.nextStep}>
                Continue
              </Button>
              <MuiButton onClick={this.skipTour} color="primary">
                Skip
              </MuiButton>
            </DialogActions>
          </>
        ),
      },
      {
        url: '/reports',
        content: () => (
          <>
            <DialogTitle id="alert-dialog-title">Reports</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Understand your portfolio's performance year-round with 30+ built-in reports.
                At tax time, automatically check your financials for accuracy and commonly overlooked real estate deductions
                with the Tax Review and then download everything that you or your CPA needs to complete your return in the Tax Packet.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.nextStep}>
                Continue
              </Button>
              <MuiButton onClick={this.skipTour} color="primary">
                Skip
              </MuiButton>
            </DialogActions>
          </>
        ),
      },
      {
        url: '/chart-of-accounts',
        content: () => (
          <>
            <DialogTitle id="alert-dialog-title">Chart of Accounts</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`For easy bookkeeping, REI Hub's default expense categories align with the IRS Schedule E filed by most rental property owners.
                To track additional categories, accounts or sub-accounts can be created by editing the chart of accounts.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.nextStep}>
                Continue
              </Button>
              <MuiButton onClick={this.skipTour} color="primary">
                Skip
              </MuiButton>
            </DialogActions>
          </>
        ),
      },
    ]);
    if (promptPayment && planSelect) {
      stepArray.push({
        isDialog: true,
        skip: true,
        url: '/dashboard',
        content: () => (
          <BuyNowPromoModal
            onBuyNow={() => this.setState({ subscribeDialog: true })}
            onTryForFree={() => this.nextStep()}
          />
        ),
      });
    }
    stepArray.push({
      isDialog: true,
      skip: true,
      url: '/dashboard',
      content: () => (
        <DialogContent>
          <Box mx="auto" textAlign="center" mb={2}>
            <Typography variant="h5" gutterBottom>
              Welcome aboard
            </Typography>
          </Box>
          <Box mb={1} textAlign="center">
            <Typography variant="body1">
              {`Keep exploring or visit the transaction import feed to begin booking transactions now.
                If you need help, our US-based customer service is here to answer any questions.
                Thanks for choosing REI Hub to keep your books!`}
            </Typography>
          </Box>
          <Box pb={1}>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} px={3}>
              <Box marginBottom={1}>
                <Button hasFullWidth size="large" variant="contained" onClick={this.closeTour}>
                  Keep Exploring
                </Button>
              </Box>
              <Button hasFullWidth size="large" variant="contained" onClick={this.closeTourFeed}>
                Go To Import Feed
              </Button>
            </Box>
          </Box>
        </DialogContent>
      ),
    });

    if (index === 'skip') {
      const skipStepIndex = stepArray.findIndex((step) => step.skip === true);
      const nextStep = stepArray[skipStepIndex];
      history.push(nextStep.url);
      this.setState({ stepIndex: skipStepIndex });
      return;
    }
    return stepArray[index];
  };

  nextStep = async () => {
    const { stepIndex } = this.state;
    const nextStep = this.getStep(stepIndex + 1);

    await history.push(nextStep.url);
    this.setState({ stepIndex: stepIndex + 1 });
  };

  skipTour = () => {
    this.getStep('skip');
  };

  closeTour = async () => {
    const { setIsTourVisible } = this.context;
    setIsTourVisible(false);
    this.setState({ runTour: false });
  };

  closeTourFeed = async () => {
    const { setIsTourVisible } = this.context;
    setIsTourVisible(false);
    this.setState({ runTour: false });
    history.push('/accounts/banking/import-feed');
  };

  render() {
    const { runTour, stepIndex, subscribeDialog } = this.state;
    const { classes } = this.props;
    const step = this.getStep(stepIndex);

    if (!runTour) {
      return null;
    }

    if (subscribeDialog) {
      return (
        <SubscribeDialog
          isOpen
          welcomeOffer
          closeDialog={() => {
            this.setState({ subscribeDialog: false });
          }}
          onWelcomeSubscribed={this.onWelcomeSubscribed}
        />
      );
    }

    if (step.isDialog) {
      return (
        <Dialog open scroll="body" maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
          {step.content()}
        </Dialog>
      );
    }

    return (
      <Box>
        <Backdrop className={classes.backdrop} open />
        <Paper className={classes.tooltip}>{step.content()}</Paper>
        <div className={classes.arrow}>
          <svg width="32" height="16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polygon points="0,0,16 16,32 0" fill="#fff" />
          </svg>
        </div>
      </Box>
    );
  }
}

WelcomeTour.contextType = PersonContext;

WelcomeTour.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(WelcomeTour);
