import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import client from '../../feathers';

import { PersonContext } from '../../contexts/PersonContext';
import {
  handleTextFieldChange,
} from '../../functions/InputHandlers';

const styles = {
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  loginCard: {
    maxWidth: '500px',
    width: '100%',
  },
};

class AccountantLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginFormEmail: '',
      loginFormPassword: '',
      submitting: false,
    };
  }

  loginSubmit = (event) => {
    event.preventDefault();

    const { submitting } = this.state;
    if (submitting) {
      return;
    }

    const { setPerson, setContextState } = this.context;

    const { loginFormEmail, loginFormPassword } = this.state;
    this.setState({ submitting: true });
    client
      .authenticate({
        strategy: 'local',
        email: loginFormEmail.trim().toLowerCase(),
        password: loginFormPassword,
      })
      .then((response) => {
        if (response.user.isAccountant) {
          localStorage.setItem('accountantLogin', 1);
          setContextState({
            sessionSource: 'accountantLogin',
            accountantLogin: true,
          });
          setPerson(response);
        } else {
          client.logout();
          this.setState({ error: { message: 'You must log in with an accounting profile. Please contact us for assistance.' } });
          this.setState({ submitting: false });
        }
      })
      .catch((error) => {
        const errorState = { ...error };
        errorState.message = `${errorState.message}. Please try again or use the buttons below to reset your password or create a new account.`;
        this.setState({ error: errorState, submitting: false });
      });
  };

  render() {
    const { classes } = this.props;
    const {
      submitting, error,
      loginFormEmail, loginFormPassword,
    } = this.state;
    return (
      <Grid container justify="center" alignContent="center" className={classes.root}>
        <Grid item xs={12} className={classes.centerContent}>
          <Card className={classes.loginCard}>
            <Box display="flex" flexDirection="column" width={1} px={2} py={2}>
              <Typography variant="h5" align="center" gutterBottom>Accountant Sign In</Typography>
              <form onSubmit={this.loginSubmit}>
                <TextField
                  label="Email"
                  id="email"
                  fullWidth
                  required
                  margin="dense"
                  InputProps={{
                    value: loginFormEmail,
                    name: 'loginFormEmail',
                    onChange: handleTextFieldChange(this),
                    autoComplete: 'email',
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Password"
                  id="password"
                  fullWidth
                  required
                  margin="dense"
                  InputProps={{
                    value: loginFormPassword,
                    name: 'loginFormPassword',
                    onChange: handleTextFieldChange(this),
                    type: 'password',
                    autoComplete: 'current-password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                {error && (
                  <Typography color="error">
                    {error.message}
                  </Typography>
                )}
                <Box marginTop={2}>
                  <Button
                    color="secondary"
                    type="submit"
                    size="large"
                    fullWidth
                    variant="outlined"
                    disabled={submitting}
                  >
                    Sign In
                  </Button>
                </Box>
              </form>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.centerContent}>
          <Box marginY={2} maxWidth="500px" width={1}>
            <Card className={classes.loginCard}>
              <Box display="flex" flexDirection="column" width={1} px={2} py={2}>
                {error && (
                  <Box marginBottom={1}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      disabled={submitting}
                      size="large"
                      fullWidth
                      component={RouterLink}
                      to={
                        loginFormEmail
                          ? `/resetpassword?email=${loginFormEmail}`
                          : '/resetpassword'
                      }
                    >
                      Reset My Password
                    </Button>
                  </Box>
                )}
                <Button
                  color="secondary"
                  variant="outlined"
                  disabled={submitting}
                  size="large"
                  fullWidth
                  component={RouterLink}
                  to="/accountant/register"
                >
                  Create an Account Instead
                </Button>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

AccountantLogin.contextType = PersonContext;

AccountantLogin.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(AccountantLogin);
