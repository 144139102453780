import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import EmailIcon from '@material-ui/icons/EmailOutlined';
// import LockIcon from '@material-ui/icons/LockOutlined';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';

import { create, get } from '../feathersWrapper';

import { PersonContext } from '../contexts/PersonContext';

const styles = {
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  registerCard: {
    width: '100%',
    textAlign: 'center',
  },
};

class AccessInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const { id } = this.context;
    const parsed = queryString.parse(location.search);
    try {
      const invite = await get(this, 'administrator-invites', parsed.id, true);
      const accept = {
        ...invite,
        personId: id,
      };
      create(
        this,
        'register-invite',
        accept,
        true,
      ).then(() => {
        this.setState({ loading: false });
      });
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error.message);
    }
  }

  render() {
    const { classes } = this.props;
    const {
      loading,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} lg={4}>
          <Card className={classes.registerCard}>
            <CardContent>
              <Typography>
                {`The portfolio was added to your account. 
                  Access it by selecting 'Portfolios' from the top right menu.`}
              </Typography>
              <Box marginTop={1}>
                <Button
                  component={Link}
                  to="/dashboard"
                  color="secondary"
                  size="large"
                  variant="outlined"
                >
                  Home
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

AccessInvite.contextType = PersonContext;

AccessInvite.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(AccessInvite);
