import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { PersonContext } from '../contexts/PersonContext';

import {
  addressLabel,
  getFilteredUnitOptions,
  getPropertyOptions,
  getSubportfolioOptions,
  getTransactionScopeOptions,
  getUnitOptions,
  nameLabel,
} from './Autocomplete/Library';

class TransactionScope extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { journal } = this.props;
    const initialState = {};

    [
      initialState.propertyOptions,
      initialState.unitOptions,
      initialState.subportfolioOptions,
    ] = await Promise.all([
      getPropertyOptions(this),
      getUnitOptions(this),
      getSubportfolioOptions(this),
    ]);

    // default property if not set and possible
    if (journal.journalScopeSelect
      && journal.journalScopeSelect.id === 'Property/Unit'
      && !journal.propertyIdSelect
      && initialState.propertyOptions.length === 1) {
      await this.handlePropertyChange(null, initialState.propertyOptions[0]);
    }

    initialState.loading = false;
    this.setState(initialState);
  };

  handleScopeChange = (event, value) => {
    const { propertyOptions } = this.state;
    const newScopeValues = { journalScopeSelect: value };
    const { journal, transactionScopeChange } = this.props;

    // return if scope not changed
    if (journal.journalScopeSelect
      && journal.journalScopeSelect.id === value.id) {
      return;
    }

    newScopeValues.entityIdSelect = null;
    newScopeValues.propertyIdSelect = null;
    newScopeValues.unitIdSelect = null;

    // default property if possible
    if (value.id === 'Property/Unit'
      && propertyOptions.length === 1) {
      [newScopeValues.propertyIdSelect] = propertyOptions;
    }

    transactionScopeChange(newScopeValues);
  };

  handleSubPortfolioChange = (event, value) => {
    const { transactionScopeChange } = this.props;

    const newScopeValues = { entityIdSelect: value };
    transactionScopeChange(newScopeValues);
  };

  handlePropertyChange = async (event, value) => {
    const { journal, transactionScopeChange } = this.props;

    // return if property not changed
    if (journal.propertyIdSelect
      && journal.propertyIdSelect.id === value.id) {
      return;
    }

    const newScopeValues = {
      propertyIdSelect: value,
      unitIdSelect: null,
    };
    transactionScopeChange(newScopeValues);
  };

  handleUnitChange = (event, value) => {
    const { transactionScopeChange } = this.props;

    const newScopeValues = { unitIdSelect: value };
    transactionScopeChange(newScopeValues);
  };

  getScopeOptions = () => {
    const { label } = this.props;

    switch (label) {
      default:
        return getTransactionScopeOptions(this);
    }
  }

  render() {
    const { journal, label } = this.props;
    const {
      subportfolioOptions,
      propertyOptions,
      unitOptions,
      loading,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <div>
        <Autocomplete
          options={this.getScopeOptions()}
          getOptionLabel={nameLabel}
          value={journal.journalScopeSelect}
          onChange={(event, value) => {
            this.handleScopeChange(event, value);
          }}
          disableClearable
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params /* eslint-disable-line react/jsx-props-no-spreading */}
              margin="dense"
              label={!label ? 'Transaction Scope' : `${label} Scope`}
              placeholder="Type to Search"
              fullWidth
              required
            />
          )}
        />
        {journal.journalScopeSelect
          && journal.journalScopeSelect.id === 'Sub-Portfolio' && (
            <Autocomplete
              options={subportfolioOptions}
              getOptionLabel={nameLabel}
              value={journal.entityIdSelect}
              onChange={(event, value) => {
                this.handleSubPortfolioChange(event, value);
              }}
              disableClearable
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Sub-Portfolio"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
        )}
        {journal.journalScopeSelect
          && journal.journalScopeSelect.id === 'Property/Unit' && (
            <>
              <Autocomplete
                options={propertyOptions}
                getOptionLabel={addressLabel}
                value={journal.propertyIdSelect}
                onChange={(event, value) => {
                  this.handlePropertyChange(event, value);
                }}
                disableClearable
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Property"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              {journal.propertyIdSelect
                && journal.propertyIdSelect.unitSelection === 'multi' && (
                  <Autocomplete
                    options={getFilteredUnitOptions(
                      unitOptions,
                      journal.propertyIdSelect.id,
                      { addAll: true },
                    )}
                    getOptionLabel={nameLabel}
                    value={journal.unitIdSelect}
                    onChange={(event, value) => {
                      this.handleUnitChange(event, value);
                    }}
                    disableClearable
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                        margin="dense"
                        label="Unit"
                        placeholder="Type to Search"
                        fullWidth
                        required
                      />
                    )}
                  />
              )}
            </>
        )}
      </div>
    );
  }
}

TransactionScope.contextType = PersonContext;

TransactionScope.defaultProps = {
  label: null,
};

TransactionScope.propTypes = {
  journal: PropTypes.objectOf(PropTypes.any).isRequired,
  transactionScopeChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};
export default TransactionScope;
