import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
} from '../functions/InputHandlers';

const styles = {};

class FilterVendorsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  getInitialState = async () => {
    const { filter } = this.props;
    if (!filter) {
      return { loading: true };
    }

    return { filter, loading: false };
  };

  updateFilter = async (event) => {
    event.preventDefault();
    const { filter } = this.state;
    const { updateFilter, closeDialog } = this.props;

    filter.page = 0;
    updateFilter(filter);

    closeDialog();
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, filter,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={closeDialog}
        onEnter={async () => { this.setState(await this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="filterVendorsDialog"
      >
        {!loading && (
          <form onSubmit={this.updateFilter}>
            <DialogTitle id="alert-dialog-title">Filter Vendors</DialogTitle>
            <DialogContent>
              <TextField
                label="Name"
                fullWidth
                margin="dense"
                InputProps={{
                  value: filter.name,
                  name: 'nested_filter_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Update Filter
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

FilterVendorsDialog.contextType = PersonContext;

FilterVendorsDialog.defaultProps = {
};

FilterVendorsDialog.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterVendorsDialog);
