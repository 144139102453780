import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import client from '../../feathers';

import { PersonContext } from '../../contexts/PersonContext';

const styles = {
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  loginCard: {
    maxWidth: '440px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  resetLink: {
    color: '#FFF',
    textDecoration: 'none',
    display: 'block',
  },
};

class AdminLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginFormEmail: '',
      loginFormPassword: '',
      submitting: false,
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  loginSubmit = (context) => (event) => {
    event.preventDefault();

    const { submitting } = this.state;
    if (submitting) {
      return;
    }

    const { loginFormEmail, loginFormPassword } = this.state;
    this.setState({ submitting: true });
    client
      .authenticate({
        strategy: 'local',
        email: loginFormEmail.toLowerCase(),
        password: loginFormPassword,
      })
      .then((response) => {
        if (response.user.isAdmin === 1) {
          localStorage.setItem('adminLogin', 1);
          context.setAdmin(true);
          context.setDismissPayment(true);
          context.setPerson(response);
        } else {
          client.logout();
          this.setState({ error: { message: 'You must log in as an admin user' } });
          this.setState({ submitting: false });
        }
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes } = this.props;
    const {
      submitting, error,
      loginFormEmail, loginFormPassword,
    } = this.state;
    return (
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} className={classes.centerContent}>
          <PersonContext.Consumer>
            {(context) => (
              <Card className={classes.loginCard}>
                <Box display="flex" flexDirection="column" width={1} px="50px" py="30px">
                  <form onSubmit={this.loginSubmit(context)}>
                    <Box mb="30px">
                      <Typography variant="h5" align="center" component="div">
                        Admin Sign In
                      </Typography>
                    </Box>
                    <Box mb="12px">
                      <TextField
                        label="Email"
                        id="email"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          value: loginFormEmail,
                          name: 'loginFormEmail',
                          onChange: this.handleInputChange,
                          required: true,
                          autoComplete: 'email',
                          endAdornment: (
                            <InputAdornment position="end">
                              <EmailIcon color="primary" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <TextField
                      label="Password"
                      id="password"
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        value: loginFormPassword,
                        name: 'loginFormPassword',
                        onChange: this.handleInputChange,
                        type: 'password',
                        required: true,
                        autoComplete: 'current-password',
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box marginTop="30px">
                      <Typography color="error">{error && error.message}</Typography>
                      <Button
                        color="primary"
                        type="submit"
                        size="large"
                        variant="contained"
                        disableElevation
                        fullWidth
                        disabled={submitting}
                      >
                        Sign In
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Card>
            )}
          </PersonContext.Consumer>
        </Grid>
      </Grid>
    );
  }
}

AdminLogin.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(AdminLogin);
