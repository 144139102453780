import React from 'react';
import { Box, Button as MuiButton, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useOnboardingContext } from '~/contexts/OnboardingProvider';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';

import { BEST_DESCRIBES_YOU } from '../constants';

const SEGMENT_LOCATION = 'Onboarding - Add Properties: Success';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  icon: {
    marginBottom: theme.spacing(1),
    color: theme.palette.success.main,
    fontSize: '64px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: '100%',
  },
  button: {
    maxWidth: '400px',
    width: '100%',
  },
}));

export default function PropertyAdded({ propertyCount, changeToSubportfoliosOverview, openAddPropertyDialog }) {
  const { userOnboarding } = useOnboardingContext();
  const tracking = useSegmentTrack();

  const handleFinished = () => {
    tracking('finished clicked', { location: SEGMENT_LOCATION });
    if (
      userOnboarding?.bestDescribesYou !== BEST_DESCRIBES_YOU.PROPERTY_MANAGER &&
      userOnboarding?.bestDescribesYou !== BEST_DESCRIBES_YOU.MIX_OF_BOTH &&
      userOnboarding?.totalUnits < 4
    ) {
      history.push('/accounts/banking');
    } else {
      changeToSubportfoliosOverview();
    }
  };

  const classes = useStyles();

  return (
    <DialogContent className={classes.container}>
      <CheckCircleIcon className={classes.icon} color="secondary" />

      <Typography align="center" variant="h6">
        {`You've added ${pluralize('property', propertyCount, true)} to your portfolio.`}
      </Typography>
      <Typography align="center" variant="body1">
        Now you can categorize transactions by property and view property-specific reports.
      </Typography>

      <Box className={classes.buttonsContainer}>
        <Button hasFullWidth variant="contained" onClick={() => handleFinished()} className={classes.button}>
          Finished
        </Button>
        <MuiButton
          className={classes.button}
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => {
            tracking('add_another_property clicked', { location: SEGMENT_LOCATION });
            openAddPropertyDialog();
          }}
        >
          Add Another Property
        </MuiButton>
      </Box>
    </DialogContent>
  );
}

PropertyAdded.propTypes = {
  propertyCount: PropTypes.number.isRequired,
  changeToSubportfoliosOverview: PropTypes.func.isRequired,
  openAddPropertyDialog: PropTypes.func.isRequired,
};
