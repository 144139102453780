import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';

import { PersonContext } from '~/contexts/PersonContext';
import { find } from '~/feathersWrapper';

const OnboardingContext = createContext(undefined);

export default function OnboardingProvider({ children }) {
  const [userOnboarding, setUserOnboarding] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const { triggerSnackbar, user } = useContext(PersonContext);

  async function loadUserOnboarding() {
    try {
      const userOnboardingsResponse = await find(this, 'user-onboardings', {
        userId: user.id,
      });
      setUserOnboarding(userOnboardingsResponse.data?.[0]);
      setIsLoading(false);
    } catch (error) {
      triggerSnackbar({
        message: 'Something went wrong. Please reload the page.',
        type: 'error',
      });
    }
  }

  useEffect(() => {
    loadUserOnboarding();
  }, []);

  const contextValue = {
    setUserOnboarding,
    userOnboarding,
  };

  if (isLoading) {
    return null;
  }

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
}

OnboardingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useOnboardingContext() {
  const context = useContext(OnboardingContext);

  if (context === undefined) {
    throw new Error('useOnboardingContext must be used within a OnboardingProvider');
  }

  return context;
}
