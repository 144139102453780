import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import history from '../history';

class ViewEnableSubportfoliosDialog extends React.PureComponent {
  render() {
    const { isOpen, closeDialog } = this.props;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Sub-Portfolios</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            {`Sub-Portfolios are currently disabled. When enabled, each property can be assigned 
              to a sub-portfolio for an additional dimension of reporting. 
              Please visit the settings page if you would like to enable this feature.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => history.push('/settings')}
          >
            Go To Settings
          </Button>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ViewEnableSubportfoliosDialog.defaultProps = {
};

ViewEnableSubportfoliosDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default ViewEnableSubportfoliosDialog;
