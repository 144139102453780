import React from 'react';
import PropTypes from 'prop-types';
import generateHash from 'random-hash';

import Dialog from '@material-ui/core/Dialog';

import { PersonContext } from '../contexts/PersonContext';
import { get } from '../feathersWrapper';

import EditExpenseDialogContent from './TransactionTemplates/EditExpenseDialogContent';
import EditRevenueDialogContent from './TransactionTemplates/EditRevenueDialogContent';
import EditTransactionDialogContent from './TransactionTemplates/EditTransactionDialogContent';

class EditBookedTransactionDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = async () => {
    const { journalId } = this.props;
    const journal = await get(this, 'journals', journalId);
    journal.journalLines.forEach((line) => {
      /* eslint-disable no-param-reassign */
      line.accountIdSelect = { label: line.account.name, value: line.accountId };
      line.key = generateHash();
      /* eslint-enable no-param-reassign */
    });
    journal.creditLines = journal.journalLines.filter((line) => line.type === 'credit');
    journal.debitLines = journal.journalLines.filter((line) => line.type === 'debit');

    const initialState = {
      loading: false,
      journal,
    };

    this.setState(initialState);
  };

  getDialogContent = () => {
    const {
      journal,
    } = this.state;

    switch (journal.type) {
      case 'Expense':
        return (
          <EditExpenseDialogContent
            journal={journal}
            closeDialog={this.closeDialog}
            onEditTransaction={this.onEditTransaction}
          />
        );
      case 'Revenue':
        return (
          <EditRevenueDialogContent
            journal={journal}
            closeDialog={this.closeDialog}
            onEditTransaction={this.onEditTransaction}
          />
        );
      default:
        return (
          <EditTransactionDialogContent
            journal={journal}
            closeDialog={this.closeDialog}
            onEditTransaction={this.onEditTransaction}
          />
        );
    }
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  onEditTransaction = () => {
    const { onEditTransaction } = this.props;
    this.closeDialog();
    onEditTransaction();
  };

  render() {
    const { isOpen } = this.props;
    const {
      loading,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEnforceFocus
        onClose={this.closeDialog}
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="transactionDialog"
      >
        {!loading && this.getDialogContent()}
      </Dialog>
    );
  }
}

EditBookedTransactionDialog.contextType = PersonContext;

EditBookedTransactionDialog.defaultProps = {
  journalId: null,
};

EditBookedTransactionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditTransaction: PropTypes.func.isRequired,
  journalId: PropTypes.node,
};

export default EditBookedTransactionDialog;
