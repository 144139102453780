import React, { useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CustomStepper from '~/components/CustomStepper';
import { PersonContext } from '~/contexts/PersonContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.palette.background.secondary,
  },
  appBar: ({ isTurboTenantPartner }) => ({
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: isTurboTenantPartner ? '78px' : '50px',
    width: '100%',
  }),
  logo: ({ isTurboTenantPartner }) => ({
    height: isTurboTenantPartner ? '62px' : '50px',
    width: 'auto',
  }),
  content: {
    backgroundColor: theme.palette.background.secondary,
    width: '100%',
    padding: 0,
  },
  stepperBackground: {
    backgroundColor: theme.palette.background.secondary,
  },
  stepperContainer: {
    maxWidth: '960px',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

function OnboardingLayout({ children, className }) {
  const { partnerName } = useContext(PersonContext);

  const isTurboTenantPartner = partnerName === 'TurboTenant';

  const classes = useStyles({ isTurboTenantPartner });

  return (
    <div className={classes.container}>
      <div className={classes.appBar}>
        <img
          alt="REI Hub logo"
          src={isTurboTenantPartner ? '/reihub-turbotenant-logo.svg' : '/rei_hub_logo_purple.svg'}
          className={classes.logo}
        />
      </div>
      <div className={classes.stepperBackground}>
        <div className={classes.stepperContainer}>
          <CustomStepper
            orientation="horizontal"
            stepProps={[
              {
                label: 'Profile Info',
                url: '/dashboard',
              },
              {
                label: 'Add Properties',
                url: '/properties',
              },
              {
                label: 'Link Accounts',
                url: '/accounts/banking',
              },
            ]}
            allCompletedUrl="/onboarding-complete"
          />
        </div>
      </div>
      <div className={classNames(classes.content, className)}>{children}</div>
    </div>
  );
}

OnboardingLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

OnboardingLayout.defaultProps = {
  className: undefined,
};

export default OnboardingLayout;
