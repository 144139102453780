import React from 'react';
import PropTypes from 'prop-types';
import { ElementsConsumer } from '@stripe/react-stripe-js';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';

import AddPaymentCardDialog from './AddPaymentCardDialog';
import PlaidLinkAuth from './Plaid/PlaidLinkAuth';

import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import {
  handleAutocompleteChange,
} from '../functions/InputHandlers';

import {
  nameLabel,
  getPaymentMethodOptions,
} from './Autocomplete/Library';

// const styles = {};

class UpdateSubscriptionPaymentDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      step: 'select',
      paymentMethodSelect: null,
    };
  }

  continueToPayment = (event) => {
    event.preventDefault();

    const { paymentMethodSelect } = this.state;

    if (paymentMethodSelect.id === 'Card') {
      this.setState({
        step: 'card',
      });
    }

    if (paymentMethodSelect.id === 'ACH') {
      this.setState({
        step: 'ACH',
      });
    }
  }

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({
      step: 'select',
      paymentMethodSelect: null,
    });
    closeDialog();
  };

  addACHPaymentMethod = async (stripeBankAccountToken) => {
    const { organizationId } = this.context;

    await create(
      this,
      'stripe-sources',
      {
        organizationId,
        stripeSource: stripeBankAccountToken,
      },
    );
    this.goToThanks();
  };

  goToThanks = () => {
    const { organizationId, setOrganizationId } = this.context;

    setOrganizationId(organizationId);
    this.setState({ step: 'thanks' });
  };

  getDialogContent = () => {
    const {
      step,
      paymentMethodSelect, error,
    } = this.state;

    if (step === 'select') {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Update Payment Method</DialogTitle>
          <DialogContent>
            <form onSubmit={this.continueToPayment}>
              <Autocomplete
                options={getPaymentMethodOptions}
                getOptionLabel={nameLabel}
                value={paymentMethodSelect}
                onChange={handleAutocompleteChange(
                  'paymentMethodSelect',
                  this,
                )}
                getOptionSelected={(option, value) => option.id === value.id}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Payment Method"
                    fullWidth
                    required
                  />
                )}
              />
              {paymentMethodSelect && paymentMethodSelect.id === 'ACH' && (
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body1">
                    {`Please click continue and sign in with your bank 
                      to securely select and authorize a payment account. Account authorization services are provided by Plaid.`}
                  </Typography>
                </Box>
              )}
              <Box
                marginTop={2}
              >
                {error && <Typography color="error">{error.message}</Typography>}
                <Button
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  size="large"
                  fullWidth
                  disableElevation
                >
                  Continue
                </Button>
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </>
      );
    }

    if (step === 'thanks') {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Update Successful</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              Thank you. We have successfully updated your payment method.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </>
      );
    }

    return null;
  };

  render() {
    const { isOpen } = this.props;
    const { step } = this.state;

    if (step === 'ACH') {
      return (
        <PlaidLinkAuth
          closeDialog={() => this.setState({ step: 'select' })}
          onSuccess={this.addACHPaymentMethod}
        />
      );
    }

    if (step === 'card') {
      return (
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <AddPaymentCardDialog
              elements={elements}
              stripe={stripe}
              onSuccess={() => this.goToThanks()}
              onCancel={() => this.setState({ step: 'select' })}
              cancelLabel="Go Back"
            />
          )}
        </ElementsConsumer>
      );
    }

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {this.getDialogContent()}
      </Dialog>
    );
  }
}

UpdateSubscriptionPaymentDialog.contextType = PersonContext;

UpdateSubscriptionPaymentDialog.defaultProps = {
};

UpdateSubscriptionPaymentDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default UpdateSubscriptionPaymentDialog;
