import React from 'react';
import { Box, Dialog, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import LoadingOverlay from './LoadingOverlay';

const useStyles = makeStyles(() => ({
  modal: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    position: 'absolute',
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '25px',
    height: 0,
  },
  videoIFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  },
  closeIcon: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    color: 'white',
  },
}));

export default function VideoPlayerDialog({
  open,
  onClose,
  source,
  allowFullScreen,
}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);

  if (!open) {
    return null;
  }

  return (
    <>
      <LoadingOverlay visible={open && loading} />
      <Dialog
        open={open}
        scroll="body"
        maxWidth="md"
        fullWidth
        onClose={onClose}
        disableBackdropClick
        disableEscapeKeyDown
        id="video-player-modal"
        aria-describedby="video-player-modal"
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {!loading && (
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ClearIcon
              className={classes.closeIcon}
              onClick={onClose}
            />
          </Box>
        )}
        <DialogContent>
          <Box mb={1} mx="auto" textAlign="center">
            <div className={classes.videoWrapper}>
              <iframe
                onLoad={() => {
                  setLoading(false);
                }}
                hidden={loading}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={allowFullScreen}
                src={source}
                className={classes.videoIFrame}
                data-embed="true"
                title="REI Hub Overview Video"
              />
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

VideoPlayerDialog.propTypes = {
  source: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  allowFullScreen: PropTypes.bool.isRequired,
};
