import React from 'react';
import PropTypes from 'prop-types';
import generateHash from 'random-hash';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';

import PageGrid from '~/components/PageGrid';
import { get, create } from '../feathersWrapper';
import history from '../history';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleChange,
  asyncHandleChange,
  handleSearchSelectChange,
  handleKeyboardDatePickerChange,
  handleTextFieldChange,
  handleNumberFormatChange,
  handleUploaderChange,
} from '../functions/InputHandlers';

import {
  sumProperty,
} from '../functions/SumFunctions';

import AddContactDialog from '../components/AddContactDialog';

import {
  searchProperties, searchUnits, searchContacts,
} from '../components/SearchSelect/SearchFunctions';
import SearchSelect from '../components/SearchSelect/SearchSelect';

import FormGridContainer from '../components/FormGridContainer';
import FormGridItem from '../components/FormGridItem';
import CardBase from '../components/CardBase';
import PageHeader from '../components/PageHeader';

const styles = {
  amountCell: {
    width: '30%',
    maxWidth: '120px',
  },
  hideWidget: {
    display: 'none',
  },
};

class AddLease extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      submitting: false,
      addPersonDialog: false,
      lease: {
        startDate: null,
        startDateSelect: null,
        endDate: null,
        endDateSelect: null,
        propertyId: null,
        propertyIdSelect: null,
        unitId: null,
        unitIdSelect: null,
        tenants: null,
        tenantsSelect: null,
        securityDepositAmount: null,
        recurringRents: [{
          key: generateHash(),
          description: 'Base Rent',
          amount: '',
        }],
      },
      monthToMonth: false,
      selectedProperty: null,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  handlePropertyChange = async (name, value, isMulti) => {
    const { lease } = this.state;
    if (value !== null) {
      const property = await get(this, 'properties', value.value);
      handleChange('selectedProperty', property, this);
    } else {
      handleChange('selectedProperty', null, this);
    }
    lease.unitId = null;
    lease.unitIdSelect = null;
    await asyncHandleChange('lease', { ...lease }, this);

    handleSearchSelectChange(this)(name, value, isMulti);
  };

  clickMonthToMonth = () => {
    const { monthToMonth, lease } = this.state;
    lease.endDate = null;
    lease.endDateSelect = null;
    this.setState({
      monthToMonth: !monthToMonth,
      lease: { ...lease },
    });
  };

  addLease = async (event) => {
    event.preventDefault();
    const { submitting, lease } = this.state;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    await create(this, 'leases', {
      organizationId, ...lease,
    }, true)
      .then(() => {
        history.push('/organization/leases');
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  addRecurringRent = () => {
    const newRecurringRent = {
      key: generateHash(),
      description: '',
      amount: '',
    };

    const { lease } = this.state;
    lease.recurringRents.push({ ...newRecurringRent });

    handleChange('nested_lease_recurringRents', [...lease.recurringRents], this);
  };

  removeRecurringRent = (index) => {
    const { lease } = this.state;
    lease.recurringRents.splice(index, 1);
    handleChange('nested_lease_recurringRents', [...lease.recurringRents], this);
  };

  closeAddPersonDialog = () => {
    this.setState({
      addPersonDialog: false,
    });
  };

  onAddPersonDialog = (contact) => {
    const { lease } = this.state;
    if (!Array.isArray(lease.tenantsSelect)) {
      lease.tenantsSelect = [];
    }
    lease.tenantsSelect.push({
      label: `${contact.person.firstName} ${contact.person.lastName}`, value: contact.personId,
    });
    handleSearchSelectChange(this)('nested_lease_tenants', [...lease.tenantsSelect], true);
    this.closeAddPersonDialog();
  };

  actionButtons = () => [
    { text: 'Cancel', link: '/organization/leases', class: 'cancel' },
  ];

  render() {
    const { classes, match } = this.props;
    const {
      loading, submitting, error,
      lease, selectedProperty, monthToMonth, addPersonDialog,
    } = this.state;

    return (
      <>
        {!loading && (
          <PageGrid>
            <PageHeader match={match} actionButtons={this.actionButtons()} title="Add Lease" />
            <AddContactDialog
              isOpen={addPersonDialog}
              closeDialog={this.closeAddPersonDialog}
              onAddContact={this.onAddPersonDialog}
            />
            <Grid item xs={12}>
              <CardBase>
                <CardContent>
                  <form onSubmit={this.addLease}>
                    <SearchSelect
                      searchFunction={searchProperties(this)}
                      changeFunction={this.handlePropertyChange}
                      label="Property"
                      name="nested_lease_propertyId"
                      isMulti={false}
                      value={lease.propertyIdSelect}
                      margin="dense"
                      required
                    />
                    {selectedProperty && selectedProperty.unitSelection === 'multi' && (
                      <SearchSelect
                        searchFunction={searchUnits(this, { propertyId: selectedProperty.id })}
                        changeFunction={handleSearchSelectChange(this)}
                        label="Unit"
                        name="nested_lease_unitId"
                        isMulti={false}
                        value={lease.unitIdSelect}
                        margin="dense"
                        required
                      />
                    )}
                    <FormGridContainer>
                      <FormGridItem xs={12} sm={6}>
                        <KeyboardDatePicker
                          className={classes.datetime}
                          fullWidth
                          margin="dense"
                          label="Start Date"
                          format="MM/DD/YYYY"
                          placeholder="MM/DD/YYYY"
                          value={lease.startDateSelect}
                          onChange={handleKeyboardDatePickerChange('nested_lease_startDate', this)}
                          clearable
                          required
                        />
                      </FormGridItem>
                      <FormGridItem xs={12} sm={6}>
                        <KeyboardDatePicker
                          className={classes.datetime}
                          fullWidth
                          margin="dense"
                          label={monthToMonth ? 'No End Date' : 'End Date'}
                          format="MM/DD/YYYY"
                          placeholder="MM/DD/YYYY"
                          value={lease.endDateSelect}
                          onChange={handleKeyboardDatePickerChange('nested_lease_endDate', this)}
                          clearable
                          disabled={monthToMonth}
                          required={!monthToMonth}
                        />
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={monthToMonth}
                              onChange={this.clickMonthToMonth}
                            />
                          )}
                          label="Month to Month (no end date)"
                        />
                      </FormGridItem>
                    </FormGridContainer>
                    <SearchSelect
                      searchFunction={searchContacts(this)}
                      changeFunction={handleSearchSelectChange(this)}
                      label="Tenants (Optional)"
                      name="nested_lease_tenants"
                      isMulti
                      value={lease.tenantsSelect}
                      noOptionsMessage={() => (
                        <Button
                          onClick={() => this.setState({ addPersonDialog: true })}
                        >
                          No Contacts Found - Click here to create a new one
                        </Button>
                      )}
                    />
                    <div>
                      <Button
                        onClick={() => this.setState({ addPersonDialog: true })}
                        color="primary"
                      >
                        Add Contact
                      </Button>
                    </div>
                    <Box
                      border={1}
                      borderColor="grey.500"
                      borderRadius="borderRadius"
                      padding={2}
                      marginY={2}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell><Typography variant="subtitle2">Rent Items</Typography></TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {lease.recurringRents.map((recurringRent, index) => (
                            <TableRow key={recurringRent.key}>
                              <TableCell>
                                <TextField
                                  label="Description"
                                  fullWidth
                                  required
                                  margin="dense"
                                  InputProps={{
                                    value: recurringRent.description,
                                    name: `nested_lease_recurringRents_${index}_description`,
                                    onChange: handleTextFieldChange(this),
                                  }}
                                />
                              </TableCell>
                              <TableCell className={classes.amountCell}>
                                <FormControl margin="dense" fullWidth>
                                  <InputLabel required>
                                    Amount
                                  </InputLabel>
                                  <NumberFormat
                                    value={recurringRent.amount}
                                    required
                                    prefix="$"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    onValueChange={handleNumberFormatChange(`nested_lease_recurringRents_${index}_amount`, this)}
                                    customInput={Input}
                                    endAdornment={index > 0 && (
                                      <InputAdornment position="end">
                                        <Button
                                          color="primary"
                                          size="small"
                                          onClick={() => {
                                            this.removeRecurringRent(index);
                                          }}
                                        >
                                          X
                                        </Button>
                                      </InputAdornment>
                                    )}
                                  />
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell>
                              <Button onClick={this.addRecurringRent} color="primary">
                                Add Rent Item
                              </Button>
                            </TableCell>
                            <TableCell>
                              <NumberFormat
                                displayType="text"
                                value={sumProperty(lease.recurringRents, 'amount')}
                                thousandSeparator
                                prefix="Total: $"
                                decimalScale={2}
                                fixedDecimalScale
                              />
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                    <Box
                      border={1}
                      borderColor="grey.500"
                      borderRadius="borderRadius"
                      padding={2}
                      marginY={2}
                    >
                      <FormControl margin="dense" fullWidth>
                        <InputLabel>
                          Security Deposit (Optional)
                        </InputLabel>
                        <NumberFormat
                          value={lease.securityDepositAmount}
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale
                          onValueChange={handleNumberFormatChange('nested_lease_securityDepositAmount', this)}
                          customInput={Input}
                        />
                      </FormControl>
                    </Box>
                    <Box
                      marginBottom={2}
                    >
                      <FormControl margin="dense">
                        <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                          Attachments
                        </FormLabel>
                        <div className={lease.attachmentURL ? null : classes.hideWidget}>
                          <Widget
                            ref={this.uploadcareWidget}
                            multiple
                            tabs="file url camera"
                            value={lease.attachmentURL}
                            onChange={handleUploaderChange('nested_lease_attachmentURL', this)}
                          />
                        </div>
                        {!lease.attachmentURL && (
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              this.uploadcareWidget.current.openDialog();
                            }}
                          >
                            Click to Add Pictures or Files
                          </Button>
                        )}
                        {lease.attachmentURL && (
                          <Button
                            color="primary"
                            onClick={async () => {
                              await asyncHandleChange('nested_lease_attachmentURL', '', this);
                              this.uploadcareWidget.current.reloadInfo();
                            }}
                          >
                            Remove All Attachments
                          </Button>
                        )}
                      </FormControl>
                    </Box>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={submitting}
                    >
                      Save Lease
                    </Button>
                    <Typography color="error">{error && error.message}</Typography>
                  </form>
                </CardContent>
              </CardBase>
            </Grid>
          </PageGrid>
        )}
      </>
    );
  }
}

AddLease.contextType = PersonContext;

AddLease.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(AddLease);
