import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { Box, withStyles } from '@material-ui/core';
import {
  handleChange,
  asyncHandleChange,
} from '../functions/InputHandlers';

import client from '../feathers';
import { PersonContext } from '../contexts/PersonContext';

import GraphsCard from './cards/GraphsCard';
import QuicklinksCard from './cards/QuicklinksCard';
import ExpensesCard from './cards/ExpensesCard';
import LeasesCard from './cards/LeasesCard';
import NotesCard from './cards/NotesCard';

import FilterDialog from '../components/FilterDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';

const styles = (theme) => ({
  headerContainer: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
});

class Unit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const unitsService = client.service('units');

    const { unitId } = match.params;

    // launch async calls
    const unitPromise = unitsService.get(unitId);

    // resolve async results
    const unit = await unitPromise;

    this.setState({
      unit,
      filterDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
      stale: {
        notes: false,
        expenses: false,
        cashflow: false,
      },
      loading: false,
    });
  }

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.setState({ stale: { cashflow: true, expenses: true } });
  };

  markStale = (area) => {
    handleChange(`nested_stale_${area}`, true, this);
  };

  removeStale = (area) => {
    handleChange(`nested_stale_${area}`, false, this);
  };

  actionButtons = () => {
    const { unit } = this.state;
    return (
      [
        { text: 'Edit', link: `/properties/property/${unit.propertyId}/units/unit/${unit.id}/edit`, class: 'edit' },
      ]
    );
  };

  render() {
    const { match, classes } = this.props;
    const {
      unit, filter, stale, filterDialog, loading,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        <Box className={classes.headerContainer}>
          <PageHeader
            match={match}
            actionButtons={this.actionButtons()}
          >
            <Typography variant="h5">
              {unit.name}
            </Typography>
          </PageHeader>
        </Box>
        <PageGrid isMultiCard>
          <FilterDialog
            filter={filter}
            isOpen={filterDialog}
            closeDialog={() => this.setState({ filterDialog: false })}
            updateFilter={this.updateFilter}
            dateRange
          />
          <GraphsCard
            view="unit"
            id={unit.id}
          />
          <QuicklinksCard
            markStale={this.markStale}
            view="unit"
            id={unit.id}
          />
          <LeasesCard
            view="unit"
            id={unit.id}
          />
          <ExpensesCard
            stale={stale.expenses}
            removeStale={this.removeStale}
            view="unit"
            id={unit.id}
            startDate={filter.startDate ? filter.startDate : undefined}
            endDate={filter.endDate ? filter.endDate : undefined}
          />
          <NotesCard
            stale={stale.notes}
            removeStale={this.removeStale}
            view="unit"
            id={unit.id}
          />
        </PageGrid>
      </>
    );
  }
}

Unit.contextType = PersonContext;

Unit.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles, { theme: true })(Unit);
