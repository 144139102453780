import React, { useContext, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, Link, makeStyles, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { CardElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import { useSegmentTrack } from '~/functions/SegmentFunctions';

import { PersonContext } from '../contexts/PersonContext';
import { create } from '../feathersWrapper';

import Button from './Button';

const useStyles = makeStyles(() => ({
  border: {
    border: '1px solid #C4C4C4',
    borderRadius: '4px',
    padding: '10px',
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 32px 16px 32px',
    gap: '20px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: 0,
  },
  cardElementAndCheckboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
}));

export default function AddSubscribePaymentCardDialog({
  onSuccess,
  onCancel,
  stripe,
  elements,
  caption,
  amount,
  cancelLabel,
  isInSubscribeFlow,
  isAnnualPlan,
}) {
  const tracking = useSegmentTrack();
  const context = useContext(PersonContext);
  const { organizationId } = context;
  const classes = useStyles();
  const theme = useTheme();

  const [elementError, setElementError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);

  const addPaymentMethod = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (submitting) {
      return;
    }
    setSubmitting(true);

    const cardElement = elements.getElement(CardElement);
    // Use your card Element with other Stripe.js APIs
    const { error, source } = await stripe.createSource(cardElement, {
      type: 'card',
    });

    if (error) {
      setElementError(error);
      setSubmitting(false);
      return;
    }

    try {
      await create(
        this,
        'stripe-sources',
        {
          organizationId,
          stripeSource: source.id,
        },
        true, // throw error
      );
    } catch (sourcesErr) {
      setElementError(sourcesErr);
      setSubmitting(false);
      return;
    }
    onSuccess();
  };

  const getButtonLabel = () => {
    let label = 'Confirm';
    if (amount) {
      label = `Confirm & Pay $${amount.toFixed(2)}`;
    }
    if (submitting) {
      label = 'Saving...';
    }
    return label;
  };

  if (!stripe) {
    return null;
  }

  return (
    <Dialog
      open
      scroll="body"
      maxWidth="sm"
      fullWidth
      onClose={(_event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return null;
        }
        return onCancel();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.dialogContentContainer}>
        <Typography variant="h6">Enter Payment Card</Typography>
        <Box className={classes.cardElementAndCheckboxContainer}>
          <CardElement
            className={classes.border}
            options={{
              style: {
                base: {
                  fontSize: '20px',
                  fontFamily: 'system-ui',
                  color: '#000000de',
                },
                invalid: {
                  color: theme.palette.error.main,
                },
              },
            }}
          />
          {caption && <Typography variant="caption">{caption}</Typography>}
          <FormControl margin="dense" fullWidth>
            <FormControlLabel
              control={<Checkbox checked={hasAgreed} onChange={() => setHasAgreed(!hasAgreed)} />}
              label={
                <Typography variant="body1">
                  {`I understand this will auto-renew ${isAnnualPlan ? 'yearly' : 'monthly'} and I can cancel anytime. `}
                  <Link href="https://www.reihub.net/terms" color="secondary" underline="always" target="_blank">
                    See terms
                  </Link>
                  .
                </Typography>
              }
            />
          </FormControl>
        </Box>
        {elementError && <Typography color="error">{elementError.message}</Typography>}
        <Box className={classes.buttonsContainer}>
          <Button
            disabled={submitting || !hasAgreed}
            hasFullWidth
            variant="contained"
            onClick={() => {
              if (isInSubscribeFlow) {
                tracking('confirm_and_pay clicked', { location: 'Subscribe Flow' });
              }
              addPaymentMethod();
            }}
          >
            {getButtonLabel()}
          </Button>
          <Button
            disabled={submitting}
            hasFullWidth
            variant="text"
            onClick={() => {
              if (isInSubscribeFlow) {
                tracking('go_back clicked', { location: 'Subscribe Flow' });
              }
              onCancel();
            }}
          >
            {cancelLabel}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

AddSubscribePaymentCardDialog.defaultProps = {
  caption: null,
  amount: null,
  cancelLabel: 'Cancel',
  isInSubscribeFlow: false,
};

AddSubscribePaymentCardDialog.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  caption: PropTypes.string,
  amount: PropTypes.number,
  cancelLabel: PropTypes.string,
  isInSubscribeFlow: PropTypes.bool,
  isAnnualPlan: PropTypes.bool.isRequired,
};
