import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link as RouterLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import client from '../../feathers';

import { PersonContext } from '../../contexts/PersonContext';

const styles = {
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loginCard: {
    maxWidth: '440px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

class Sso extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const { setContextState, setPerson } = this.context;
    const parsed = queryString.parse(location.search);

    client.authentication.setAccessToken(parsed.token);
    client
      .reAuthenticate()
      .then(async (response) => {
        await setPerson(response);
        setContextState({ sessionSource: 'sso' });
      })
      .catch((error) => {
        localStorage.clear();
        this.setState({ loading: false, error: { message: `An error occurred during sign-in. Click below to sign in using your email and password instead. Error: ${error.message}` } });
      });
  }

  render() {
    const { classes } = this.props;
    const {
      loading, error,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} className={classes.centerContent}>
          <Card className={classes.loginCard}>
            <Box display="flex" flexDirection="column" width={1} px="50px" py="30px">
              <Typography color="error">
                {error.message}
              </Typography>
              <Box marginTop="30px">
                <Button
                  color="primary"
                  type="submit"
                  size="large"
                  fullWidth
                  disableElevation
                  variant="contained"
                  component={RouterLink}
                  to="/sign-in"
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

Sso.contextType = PersonContext;

Sso.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Sso);
