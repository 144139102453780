import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import {
  get, find, patch, remove,
} from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
} from '../functions/InputHandlers';

const styles = (theme) => ({
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: 'red',
  },
  deleteConfirmationButton: {
    color: 'red',
  },
});

class EditContactDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  getContact = async () => {
    let { contactId } = this.props;
    const { personId } = this.props;
    const contact = {};
    try {
      if (!contactId) {
        const { organizationId } = this.context;
        const contactsResult = await find(this, 'contacts', { query: { personId, organizationId } });
        contactId = contactsResult.data[0].id;
      }
      const oldContact = await get(this, 'contacts', contactId);

      contact.id = contactId;
      contact.person = {
        firstName: oldContact.person.firstName,
        lastName: oldContact.person.lastName,
        phone: oldContact.person.phone,
        email: oldContact.person.email,
      };

      this.setState({
        contact, confirmDelete: false, loading: false, submitting: false,
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  editContact = async (event) => {
    event.preventDefault();
    const { submitting, contact } = this.state;
    const { onEditContact } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    patch(this, 'contacts', contact.id, contact)
      .then(() => {
        onEditContact();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  deleteContact = async () => {
    const { submitting, contact } = this.state;
    const { onEditContact } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    await remove(this, 'contacts', contact.id)
      .then(() => {
        onEditContact();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const {
      error, loading, contact, confirmDelete,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={closeDialog}
        onEnter={this.getContact}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="editContactDialog"
      >
        {!loading && !confirmDelete && (
          <form onSubmit={this.editContact}>
            <DialogTitle id="alert-dialog-title">
              Edit Contact
              <IconButton
                className={classes.deleteIconButton}
                aria-label="delete"
                onClick={() => this.setState({ confirmDelete: true })}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TextField
                label="First Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: contact.person.firstName,
                  name: 'nested_contact_person_firstName',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Last Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: contact.person.lastName,
                  name: 'nested_contact_person_lastName',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Email"
                fullWidth
                margin="dense"
                InputProps={{
                  value: contact.person.email,
                  type: 'email',
                  name: 'nested_contact_person_email',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Phone Number"
                fullWidth
                margin="dense"
                InputProps={{
                  value: contact.person.phone,
                  type: 'tel',
                  name: 'nested_contact_person_phone',
                  onChange: handleTextFieldChange(this),
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Contact
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <>
            <DialogTitle id="alert-dialog-title">Really Delete Contact?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This contact will be removed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={this.deleteContact} className={classes.deleteConfirmationButton}>
                Delete Contact
              </Button>
            </DialogActions>
          </>
        )}
        <Typography color="error">{error && error.message}</Typography>
      </Dialog>
    );
  }
}

EditContactDialog.contextType = PersonContext;

EditContactDialog.defaultProps = {
  contactId: null,
  personId: null,
};

EditContactDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditContact: PropTypes.func.isRequired,
  contactId: PropTypes.node,
  personId: PropTypes.node,
};

export default withStyles(styles)(EditContactDialog);
