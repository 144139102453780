import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
} from '../functions/InputHandlers';

const styles = (theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  dialogText: {
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(3),
  },
});

class ContactUsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  getInitialState = () => {
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      message: '',
    };

    return initialState;
  };

  checkPhoneOpen = () => {
    const now = moment().tz('America/New_York');
    if (now.isoWeekday() > 5) {
      return false;
    }
    if (now.hour() < 9) {
      return false;
    }
    if (now.hour() > 16) {
      return false;
    }

    return true;
  };

  contactUs = async (event) => {
    event.preventDefault();
    const {
      submitting, message,
    } = this.state;

    const { organizationId } = this.context;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    create(
      this,
      'contact-us',
      {
        organizationId,
        personContext: this.context,
        subject: 'Contact Us',
        message,
      },
    );
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const {
      loading, submitting, error, message,
    } = this.state;
    const {
      demo,
    } = this.context;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        maxWidth="sm"
        fullWidth
        onClose={closeDialog}
        onEnter={() => { this.setState(this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="contactUsDialog"
      >
        {!loading && !submitting && (
          <form onSubmit={this.contactUs}>
            <DialogTitle id="alert-dialog-title">Contact Us</DialogTitle>
            <DialogContent>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="h6" gutterBottom>Phone</Typography>
                <Typography variant="body1" component="span">(888)939-6865</Typography>
                {this.checkPhoneOpen()
                  ? <Typography variant="body1" component="span" className={classes.success}> (Open Now)</Typography>
                  : <Typography variant="body1" component="span" color="error"> (Closed Now)</Typography>}
                <Typography variant="caption" component="div">
                  Our office hours are 9 a.m. to 5 p.m. Eastern Time, Monday through Friday
                </Typography>
              </Box>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="h6" gutterBottom>Email</Typography>
                {demo && (
                  <Typography variant="body1" component="span">support@reihub.net</Typography>
                )}
                {!demo && (
                  <TextField
                    label="Message"
                    helperText="We typically respond within one business day"
                    fullWidth
                    multiline
                    required
                    rows="5"
                    variant="filled"
                    color="secondary"
                    margin="dense"
                    InputProps={{
                      value: message,
                      name: 'message',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                )}
              </Box>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              {!demo && (
                <Button type="submit" variant="contained" color="primary" disableElevation>
                  Submit
                </Button>
              )}
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && submitting && (
          <>
            <DialogTitle id="alert-dialog-title">Contact Us</DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.dialogText}>
                Thank you. We have received your message and will be in touch soon.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} variant="contained" color="primary" disableElevation>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

ContactUsDialog.contextType = PersonContext;

ContactUsDialog.defaultProps = {
};

ContactUsDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContactUsDialog);
