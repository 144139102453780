import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { remove } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import ContactUsDialog from './ContactUsDialog';

import {
  handleCheckboxChange,
} from '../functions/InputHandlers';

// const styles = {};

class CancelSubscriptionDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      step: 'cancel',
      confirmCancel: false,
      contactUs: false,
    };
  }

  cancelSubscription = async (event) => {
    event.preventDefault();

    const { confirmCancel } = this.state;

    if (!confirmCancel) {
      return;
    }

    const { organizationId } = this.context;

    try {
      await remove(
        this,
        'stripe-subscriptions',
        organizationId,
        true, // throw error
      );
      this.goToThanks();
    } catch (subscriptionErr) {
      this.setState({
        error: {
          message: `We're sorry, your subscription could not be cancelled - please contact us. Message: ${subscriptionErr.message}`,
        },
      });
    }
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({
      step: 'cancel',
      confirmCancel: false,
      contactUs: false,
    });
    closeDialog();
  };

  logOff = () => {
    const { logOut } = this.context;
    logOut();
  };

  goToThanks = () => {
    // const { organizationId, setOrganizationId } = this.context;

    // setOrganizationId(organizationId);
    this.setState({ step: 'goodbye' });
  };

  getDialogContent = () => {
    const { adminLogin } = this.context;
    const {
      step,
      confirmCancel, error,
    } = this.state;

    if (step === 'cancel') {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Really Cancel Subscription?</DialogTitle>
          <DialogContent>
            <form onSubmit={this.cancelSubscription}>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="body1">
                  {`Thank you for using REI Hub. We are sorry to see you go. 
                    Canceling will terminate your access to all portfolio financial records stored in REI Hub. 
                    Are you sure you want to cancel your subscription at this time?`}
                </Typography>
              </Box>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={confirmCancel} onChange={handleCheckboxChange(this)} name="confirmCancel" />}
                  label="Yes, cancel my subscription now"
                />
              </FormControl>
              <Box
                marginTop={2}
                marginBottom={1}
              >
                {error && <Typography color="error">{error.message}</Typography>}
                <Typography color="error">
                  <Button
                    variant="outlined"
                    color="inherit"
                    type="submit"
                    size="large"
                    disabled={!confirmCancel}
                    fullWidth
                    disableElevation
                  >
                    Cancel Subscription
                  </Button>
                </Typography>
              </Box>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                fullWidth
                disableElevation
                onClick={this.closeDialog}
              >
                Nevermind
              </Button>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ contactUs: true })} color="primary">
              Contact Us
            </Button>
          </DialogActions>
        </>
      );
    }

    if (step === 'goodbye') {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Subscription Cancelled</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              We have successfully cancelled your subscription.
            </Typography>
          </DialogContent>
          <DialogActions>
            {adminLogin && (
              <Button onClick={this.closeDialog} color="primary">
                Close (Admin)
              </Button>
            )}
            <Button onClick={this.logOff} color="primary">
              Sign Out
            </Button>
          </DialogActions>
        </>
      );
    }

    return null;
  };

  render() {
    const { isOpen } = this.props;
    const { contactUs } = this.state;

    if (contactUs) {
      return (
        <ContactUsDialog
          isOpen
          closeDialog={() => this.closeDialog()}
        />
      );
    }

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {this.getDialogContent()}
      </Dialog>
    );
  }
}

CancelSubscriptionDialog.contextType = PersonContext;

CancelSubscriptionDialog.defaultProps = {
};

CancelSubscriptionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default CancelSubscriptionDialog;
