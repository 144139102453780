import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';

import { Autocomplete } from '@material-ui/lab';
import { ButtonBase } from '@material-ui/core';
import { get, patch } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';
import {
  handleTextFieldChange,
  asyncHandleChange,
  handleNumberFormatChange,
  handleKeyboardDatePickerChange,
  handleChange,
  handleUploaderChange,
  handleTransactionScopeChange,
  handleAutocompleteChange,
} from '../../functions/InputHandlers';

import {
  sumProperty,
} from '../../functions/SumFunctions';

import {
  getCreditJournalLine,
  getDebitJournalLine,
} from '../SearchSelect/TransactionTypeOptions';
import TransactionScope from '../TransactionScope';
import {
  setJournalScope,
  setInitialJournalScope,
} from '../../functions/JournalFunctions';
import {
  getAccountOptions, getFixedAssetOptions, getVendorOptions, nameLabel, nameAndAddressLabel,
} from '../Autocomplete/Library';
import AddVendorDialog from '../AddVendorDialog';

const styles = (theme) => ({
  amountCell: {
    width: '30%',
    maxWidth: '120px',
  },
  hideWidget: {
    display: 'none',
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class EditTransactionDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true, journal: null };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { journal } = this.props;

    const stateJournal = { ...journal };
    const initialState = {
      loading: false,
      submitting: false,
      selectedProperty: null,
      error: null,
      displayNotesSection: !!journal.notes,
    };

    await setInitialJournalScope(stateJournal, this);

    initialState.vendorOptions = await getVendorOptions(this, true);
    initialState.fixedAssetOptions = await getFixedAssetOptions(this);
    initialState.accountOptions = await getAccountOptions(this, true);

    if (journal.fixed_asset) {
      stateJournal.fixedAssetIdSelect = await get(this, 'fixed-assets', journal.fixed_asset.id);
    }

    if (journal.vendorId) {
      stateJournal.vendorIdSelect = journal.vendor;
    }

    stateJournal.journalLines.forEach((journalLine) => {
      /* eslint-disable no-param-reassign */
      journalLine.accountIdSelect = journalLine.account;
    });

    initialState.journal = stateJournal;

    this.setState(initialState);
  };

  addDebitLine = () => {
    const { journal } = this.state;
    handleChange('nested_journal_debitLines', journal.debitLines.concat([getDebitJournalLine()]), this);
  };

  addCreditLine = () => {
    const { journal } = this.state;
    handleChange('nested_journal_creditLines', journal.creditLines.concat([getCreditJournalLine()]), this);
  };

  deleteDebitLine = (index) => {
    const { journal } = this.state;
    journal.debitLines.splice(index, 1);
    handleChange('nested_journal_debitLines', journal.debitLines, this);
  };

  deleteCreditLine = (index) => {
    const { journal } = this.state;
    journal.creditLines.splice(index, 1);
    handleChange('nested_journal_creditLines', journal.creditLines, this);
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  editTransaction = async (event) => {
    event.preventDefault();
    const {
      submitting, journal,
    } = this.state;

    const { onEditTransaction } = this.props;

    if (submitting) {
      return;
    }

    const debitAmount = sumProperty(journal.debitLines, 'debit');
    const creditAmount = sumProperty(journal.creditLines, 'credit');

    if (debitAmount !== creditAmount) {
      this.setState({ error: { message: 'The credit and debit amounts for a journal must be equal' } });
      return;
    }

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    setJournalScope(journal);
    this.setState({ submitting: true });

    journal.vendorId = journal.vendorIdSelect ? journal.vendorIdSelect.id : null;
    journal.fixedAssetId = journal.fixedAssetIdSelect ? journal.fixedAssetIdSelect.id : null;

    journal.amount = debitAmount;
    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    journal.journalLines.forEach((line) => {
      // eslint-disable-next-line no-param-reassign
      line.accountId = line.accountIdSelect.id;
    });

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    patch(this, 'journals', journal.id, journal, true)
      .then((result) => {
        this.closeDialog();
        onEditTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes } = this.props;
    const { vendorTracking } = this.context;
    const {
      loading,
      error,
      journal,
      displayNotesSection,
      vendorOptions,
      fixedAssetOptions,
      addVendorDialog,
      accountOptions,
    } = this.state;

    if (loading || journal === null) {
      return null;
    }

    if (addVendorDialog) {
      return (
        <AddVendorDialog
          isOpen={addVendorDialog}
          closeDialog={() => this.setState({ addVendorDialog: false })}
          onAddVendor={async (newValue) => {
            await asyncHandleChange('vendorOptions', await getVendorOptions(this, true), this);
            await asyncHandleChange('nested_journal_vendorIdSelect', await get(this, 'vendors', newValue.id), this);
          }}
        />
      );
    }

    return (
      <form onSubmit={this.editTransaction}>
        <DialogTitle id="alert-dialog-title">
          Edit Transaction
        </DialogTitle>
        <DialogContent>
          <KeyboardDatePicker
            label="Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={journal.date}
            onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
            margin="dense"
            fullWidth
            clearable
            required
          />
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValues) => {
              handleTransactionScopeChange(journal, newScopeValues, this);
            }}
          />
          <TextField
            label="Description (optional)"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {vendorTracking && (
            <Autocomplete
              options={vendorOptions}
              getOptionLabel={nameLabel}
              value={journal.vendorIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_vendorIdSelect',
                this,
                () => this.setState({ addVendorDialog: true }),
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Vendor (optional)"
                  placeholder="Type to Search"
                  fullWidth
                />
              )}
            />
          )}
          <Autocomplete
            options={fixedAssetOptions}
            getOptionLabel={nameAndAddressLabel}
            value={journal.fixedAssetIdSelect}
            onChange={handleAutocompleteChange(
              'nested_journal_fixedAssetIdSelect',
              this,
            )}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                margin="dense"
                label="Fixed Asset (optional)"
                placeholder="Type to Search"
                fullWidth
              />
            )}
          />
          <Box
            border={1}
            borderColor="grey.500"
            borderRadius="borderRadius"
            // bgcolor="common.white"
            padding={2}
            marginY={2}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="subtitle2">Debits</Typography></TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {journal.debitLines.map((journalLine, index) => (
                  <TableRow key={journalLine.key}>
                    <TableCell>
                      <Autocomplete
                        options={accountOptions}
                        getOptionLabel={nameLabel}
                        value={journal.debitLines[index].accountIdSelect}
                        onChange={handleAutocompleteChange(
                          `nested_journal_debitLines_${index}_accountIdSelect`,
                          this,
                        )}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                            margin="dense"
                            label="Account"
                            placeholder="Type to Search"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell className={classes.amountCell}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel required>
                          Amount
                        </InputLabel>
                        <NumberFormat
                          value={journalLine.debit}
                          thousandSeparator
                          required
                          prefix="$"
                          decimalScale={2}
                          onValueChange={handleNumberFormatChange(`nested_journal_debitLines_${index}_debit`, this)}
                          customInput={Input}
                          endAdornment={journal.debitLines.length > 1 && (
                            <InputAdornment position="end">
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  this.deleteDebitLine(index);
                                }}
                              >
                                <ClearIcon style={{ fontSize: 18 }} />
                              </IconButton>
                            </InputAdornment>
                          )}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Button onClick={this.addDebitLine} color="primary">
                      Add Line
                    </Button>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      displayType="text"
                      value={sumProperty(journal.debitLines, 'debit')}
                      thousandSeparator
                      prefix="Total: $"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
          <Box
            border={1}
            borderColor="grey.500"
            borderRadius="borderRadius"
            // bgcolor="common.white"
            padding={2}
            marginY={2}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="subtitle2">Credits</Typography></TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {journal.creditLines.map((journalLine, index) => (
                  <TableRow key={journalLine.key}>
                    <TableCell>
                      <Autocomplete
                        options={accountOptions}
                        getOptionLabel={nameLabel}
                        value={journal.creditLines[index].accountIdSelect}
                        onChange={handleAutocompleteChange(
                          `nested_journal_creditLines_${index}_accountIdSelect`,
                          this,
                        )}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                            margin="dense"
                            label="Account"
                            placeholder="Type to Search"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell className={classes.amountCell}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel required>
                          Amount
                        </InputLabel>
                        <NumberFormat
                          value={journalLine.credit}
                          thousandSeparator
                          required
                          prefix="$"
                          decimalScale={2}
                          onValueChange={handleNumberFormatChange(`nested_journal_creditLines_${index}_credit`, this)}
                          customInput={Input}
                          endAdornment={journal.creditLines.length > 1 && (
                            <InputAdornment position="end">
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  this.deleteCreditLine(index);
                                }}
                              >
                                <ClearIcon style={{ fontSize: 18 }} />
                              </IconButton>
                            </InputAdornment>
                          )}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Button onClick={this.addCreditLine} color="primary">
                      Add Line
                    </Button>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      displayType="text"
                      value={sumProperty(journal.creditLines, 'credit')}
                      thousandSeparator
                      prefix="Total: $"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple={journal.attachmentURL && journal.attachmentURL.includes('~')}
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Transaction
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

EditTransactionDialogContent.contextType = PersonContext;

EditTransactionDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditTransaction: PropTypes.func.isRequired,
  journal: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(EditTransactionDialogContent);
