import React, { useContext, useEffect, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { CircularProgress, makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Fade from '@material-ui/core/Fade';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AddIcon from '@material-ui/icons/Add';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import LaunchIcon from '@material-ui/icons/Launch';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import moment from 'moment';

// TODO: Uncomment this when we have enough support for onboarding calls
// import ScheduleOnboardingCall from '~/components/ScheduleOnboardingCall';
import { GET_STARTED_CHECKLIST_ITEM_STATUS, GET_STARTED_CHECKLIST_ITEMS } from '~/constants/get-started';
import client from '~/feathers';
import { find } from '~/feathersFunctionalWrapper';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

import AddBookedTransactionDialog from '../components/AddBookedTransactionDialog';
import Button from '../components/Button';
import ContactUsDialog from '../components/ContactUsDialog';
import DemoTour from '../components/DemoTour';
import ReferralProgramDialog from '../components/ReferralProgramDialog';
import SiteFeedbackDialog from '../components/SiteFeedbackDialog';
import SubscribeDialog from '../components/SubscribeDialog';
import SearchBar from '../components/UniversalSearchBar';
import UpdateFailedPaymentMethodDialog from '../components/UpdateFailedPaymentMethodDialog';
import WelcomeTour from '../components/WelcomeTour/WelcomeTour';
import WhoReferredDialog from '../components/WhoReferredDialog';
import { PersonContext } from '../contexts/PersonContext';
import { checkIsSmallScreen } from '../functions/ScreenSizeFunctions';
import history from '../history';
import InventoryOutlined from '../icons/InventoryOutlined';
import DashboardRoutes from '../routes/DashboardRoutes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    zIndex: theme.zIndex.drawer - 1,
  },
  spacer: {
    width: '235px',
    transition: '0.4s ease-in',
  },
  minimizedSpacer: {
    width: '50px',
    transition: '0.4s ease-out',
  },
  smallScreenSpacer: {
    width: '10px',
  },
  welcomeAppBar: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    zIndex: theme.zIndex.drawer + 3,
  },
  spacingToolbar: {
    marginTop: '1px',
  },
  container: {
    padding: 0,
    display: 'flex',
    minHeight: '100vh',
    maxWidth: '100vw',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  freeTrialAppBar: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  },
  freeTrialToolBar: {
    minHeight: 'auto',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  bottomAppBar: {
    zIndex: 1,
    backgroundColor: theme.tokens.materialColors.grey[800],
  },
  footer: {
    justifyContent: 'space-between',
  },
  inline: {
    display: 'inline',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  accountIcon: {
    marginLeft: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    lineHeight: 1,
  },
  logo: {
    // imageRendering: '-webkit-optimize-contrast',
  },
  logoBox: {
    marginTop: '5px',
    justifySelf: 'center',
    alignSelf: 'center',
  },
  flexGrow: {
    flexGrow: '1',
  },
  drawer: {
    width: 250,
    flexShrink: 0,
  },
  permanentDrawerPaper: {
    width: 250,
    left: 'auto',
  },
  sideNav: {
    width: '250px',
    transition: '0.4s ease-in',
    overflowX: 'hidden',
  },
  sideNavMini: {
    width: '65px',
    transition: '0.4s ease-out',
    overflowX: 'hidden',
  },
  tempDrawerPaper: {
    width: 275,
  },
  minimizedAddButton: {
    height: '34px',
    width: '34px',
    display: 'flex',
    justifyContent: 'center',
    borderColor: theme.palette.secondary.main,
    border: 1,
    borderRadius: '10px',
    paddingBottom: '10px',
  },
  toggleDrawerButton: {
    height: '25px',
    width: '25px',
    display: 'flex',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '50%',
    borderWidth: '1px',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  nestedList: {
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.action.selected,
    borderLeftWidth: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    color: theme.palette.text.primary,
  },
  activeNav: {
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.secondary.main,
    borderLeftWidth: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.action.selected,
  },
  activeCollapse: {
    color: theme.palette.secondary.main,
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#F5F5F5',
    zIndex: theme.zIndex.drawer + 2,
  },
  addIcon: {
    color: theme.palette.secondary.main,
  },
  searchBarContainer: {
    height: 'auto',
    width: '50%',
    marginLeft: theme.spacing(3),
  },
  progressIndicatorContainer: {
    position: 'relative',
    display: 'inline-flex',
  },
  progressIndicatorLabelContainer: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressIndicatorLabel: {
    fontSize: '10px',
    color: theme.palette.primary.main,
  },
  circle: {
    strokeLinecap: 'round',
    color: '#431C5D',
  },
}));

export default function Dashboard() {
  const drawerRef = useRef();
  const timeoutRef = useRef();
  const idleTimerRef = useRef();
  const classes = useStyles();
  const context = useContext(PersonContext);
  const location = useLocation();
  const {
    accountantLogin,
    adminLogin,
    adminUserSelect,
    airbnbAccountActive,
    airbnbAccountId,
    demo,
    dismissPayment,
    dismissUpdateFailedPaymentMethod,
    freeTrialExpires,
    id,
    // TODO: Uncomment this when we have enough support for onboarding calls
    // isScheduleCallDialogVisible,
    isTourVisible,
    logOut,
    multiEntity,
    organizationCreated,
    organizationId,
    organizationName,
    partnerName,
    partnerSubscribed,
    promptPayment,
    promptUpdatePaymentMethod,
    recurringTransactions,
    setAdminUserSelect,
    setContextState,
    setDismissPayment,
    setDismissUpdateFailedPaymentMethod,
    setOrganizationId,
    showWelcome,
    viewDemoPortfolio,
    vrboAccountActive,
    vrboAccountId,
  } = context;
  const tracking = useSegmentTrack();
  const SEGMENT_LEFT_NAVIGATION_LOCATION = 'Left Navigation Bar';

  const initialDrawerState = {
    getStartedDrawer: false,
    portfolioDrawer: false,
    transactionsDrawer: false,
    accountsDrawer: false,
    organizationDrawer: false,
    reportsDrawer: false,
    helpDrawer: false,
    helpUsDrawer: false,
    right: false,
    left: false,
    highlightCollapse: null,
  };

  const [drawersState, setDrawersState] = useState(initialDrawerState);

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [isSiteFeedbackOpen, setIsSiteFeedbackOpen] = useState(false);
  const [isReferralProgramOpen, setIsReferralProgramOpen] = useState(false);
  const [isWhoReferredOpen, setIsWhoReferredOpen] = useState(false);
  const [isAddTransactionDialogOpen, setIsAddTransactionDialogOpen] = useState(false);
  const [shouldPaymentFade, setShouldPaymentFade] = useState(false);
  const [isGetStartedDisplayed, setIsGetStartedDisplayed] = useState(false);
  const [oldLocation, setOldLocation] = useState(location);
  const [isSideNavMinimized, setIsSideNavMinimized] = useState(false);
  const [isGetStartedComplete, setIsGetStartedComplete] = useState(false);
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);

  const resetDrawers = () => {
    const newDrawerState = { ...initialDrawerState };
    if (location.pathname === '/get-started') {
      newDrawerState.getStartedDrawer = true;
      newDrawerState.highlightCollapse = 'getStarted';
    }
    if (
      location.pathname === '/dashboard' ||
      location.pathname.startsWith('/properties') ||
      location.pathname.startsWith('/subportfolios')
    ) {
      newDrawerState.portfolioDrawer = true;
      newDrawerState.highlightCollapse = 'portfolio';
    } else if (location.pathname.startsWith('/accounts')) {
      newDrawerState.accountsDrawer = true;
      newDrawerState.highlightCollapse = 'accounts';
    } else if (location.pathname.startsWith('/transactions')) {
      newDrawerState.transactionsDrawer = true;
      newDrawerState.highlightCollapse = 'transactions';
    } else if (location.pathname.startsWith('/organization')) {
      newDrawerState.organizationDrawer = true;
      newDrawerState.highlightCollapse = 'organization';
    } else if (location.pathname.startsWith('/reports')) {
      newDrawerState.reportsDrawer = true;
      newDrawerState.highlightCollapse = 'reports';
    }
    setDrawersState(newDrawerState);
  };

  const getProgressPercentage = (data) => {
    if (data.completedAt) {
      setIsGetStartedComplete(true);
      return;
    }

    const checklistItems = [...GET_STARTED_CHECKLIST_ITEMS];

    // If user is partner subscribed, remove the subscribe item from the checklist
    if (partnerSubscribed) {
      const subscribeIndex = checklistItems.findIndex((checklistItem) => checklistItem.column === 'subscribe');
      checklistItems.splice(subscribeIndex, 1);
    }

    const finishedItemCount = checklistItems.filter((checklistItem) =>
      [GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED, GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED].includes(
        data[checklistItem.column],
      ),
    ).length;
    const percentage = ((finishedItemCount + 1) / checklistItems.length) * 100;
    setProgressBarPercentage(percentage);
  };

  useEffect(() => {
    async function setInitialState() {
      resetDrawers();
      setContextState({ autoSelectOrganization: false });
      if (dismissPayment) {
        setTimeout(() => {
          setShouldPaymentFade(true);
        }, 3500);
      } else {
        setShouldPaymentFade(true);
      }
      if (localStorage.getItem('page') && !showWelcome && !(promptPayment && !dismissPayment)) {
        history.replace(localStorage.getItem('page'));
      }
      localStorage.removeItem('page');

      const checklistStatusesResponse = await find('get-started-checklist', { query: { organizationId } });
      const statuses = checklistStatusesResponse.data[0];

      if (statuses) {
        getProgressPercentage(statuses);
        setIsGetStartedDisplayed(true);
      }

      client.service('get-started-checklist').on('patched', (data) => {
        getProgressPercentage(data);
      });
    }

    setInitialState();
  }, []);

  useEffect(() => {
    if (location.pathname === '/reload') {
      return;
    }
    if (location !== oldLocation) {
      setOldLocation(location);
      resetDrawers();
    }
  }, [location]);

  const toggleDrawer = (side, open) => () => {
    setDrawersState({ ...drawersState, [side]: open });
  };

  const toggleDropdown = (dropdown) => () => {
    const { [dropdown]: current } = drawersState;
    setDrawersState({ ...drawersState, [dropdown]: !current });
  };

  const navigateTo = (link) => {
    if (location.pathname === link) {
      history.push(`/reload?link=${link}`);
    } else {
      history.push(link);
    }
  };

  const reloadPage = (event, link) => {
    if (location.pathname === link) {
      event.preventDefault();
      history.push(`/reload?link=${link}`);
    }
  };

  const goToRegister = async () => {
    await logOut();
    if (partnerName === 'TurboTenant') {
      window.location.replace('https://rental.turbotenant.com/owners/payments/accounting');
      return;
    }
    if (partnerName === 'RentRedi') {
      window.location.replace('https://app.rentredi.com/accounting');
      return;
    }
    history.replace('/register');
  };

  const handleAddTransactionClicked = () => {
    tracking('add_transaction clicked', { location: SEGMENT_LEFT_NAVIGATION_LOCATION });
    setIsAddTransactionDialogOpen(true);
  };

  const isTurboTenantPartner = partnerName === 'TurboTenant';

  const getNavList = () => {
    return (
      <>
        <List>
          <ListItem>
            <Hidden smDown>
              {!isSideNavMinimized && (
                <MuiButton
                  color="secondary"
                  variant="outlined"
                  size="small"
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={() => handleAddTransactionClicked()}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Add Transaction
                </MuiButton>
              )}
              {isSideNavMinimized && (
                <IconButton className={classes.minimizedAddButton} onClick={() => handleAddTransactionClicked()}>
                  <AddIcon className={classes.addIcon} />
                </IconButton>
              )}
            </Hidden>
            <Hidden mdUp>
              <MuiButton
                color="secondary"
                variant="outlined"
                size="small"
                fullWidth
                startIcon={<AddIcon />}
                onClick={() => handleAddTransactionClicked()}
                style={{ whiteSpace: 'nowrap' }}
              >
                Add Transaction
              </MuiButton>
            </Hidden>
          </ListItem>
          {isGetStartedComplete || !isGetStartedDisplayed ? null : (
            <ListItem
              button
              onClick={() => {
                tracking('get_started clicked', { location: SEGMENT_LEFT_NAVIGATION_LOCATION });
                navigateTo('/get-started');
              }}
            >
              <ListItemIcon className={drawersState.highlightCollapse === 'getStarted' ? classes.activeCollapse : ''}>
                <InventoryOutlined
                  className={drawersState.highlightCollapse === 'getStarted' ? classes.activeCollapse : ''}
                  height={27.42}
                  width={27.42}
                />
              </ListItemIcon>
              <ListItemText primary="Get Started" />
              <Box className={classes.progressIndicatorContainer}>
                {isSideNavMinimized ? null : (
                  <>
                    <CircularProgress
                      size={32}
                      variant="determinate"
                      value={progressBarPercentage}
                      thickness={5}
                      className={classes.circle}
                    />
                    <Box className={classes.progressIndicatorLabelContainer}>
                      <Typography
                        className={classes.progressIndicatorLabel}
                        variant="caption"
                      >{`${Number(progressBarPercentage).toFixed()}%`}</Typography>
                    </Box>
                  </>
                )}
              </Box>
            </ListItem>
          )}
          <Box onClick={() => tracking('portfolio clicked', { location: SEGMENT_LEFT_NAVIGATION_LOCATION })}>
            <ListItem button onClick={toggleDropdown('portfolioDrawer')}>
              <ListItemIcon className={drawersState.highlightCollapse === 'portfolio' ? classes.activeCollapse : ''}>
                <HomeWorkOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Portfolio" />
              {drawersState.portfolioDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
          </Box>
          {!isSideNavMinimized && (
            <Collapse in={drawersState.portfolioDrawer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/dashboard"
                  onClick={(event) => reloadPage(event, '/dashboard')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/properties"
                  onClick={(event) => reloadPage(event, '/properties')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Properties" />
                </ListItem>
                {multiEntity && (
                  <ListItem
                    button
                    className={classes.nestedList}
                    component={NavLink}
                    to="/subportfolios"
                    onClick={(event) => reloadPage(event, '/subportfolios')}
                    activeClassName={classes.activeNav}
                  >
                    <ListItemText primary="Sub-Portfolios" />
                  </ListItem>
                )}
              </List>
            </Collapse>
          )}
          <Box onClick={() => tracking('accounts clicked', { location: SEGMENT_LEFT_NAVIGATION_LOCATION })}>
            <ListItem button onClick={toggleDropdown('accountsDrawer')}>
              <ListItemIcon className={drawersState.highlightCollapse === 'accounts' ? classes.activeCollapse : ''}>
                <AccountBalanceOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Accounts" />
              {drawersState.accountsDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
          </Box>
          {!isSideNavMinimized && (
            <Collapse in={drawersState.accountsDrawer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/accounts/banking"
                  onClick={(event) => reloadPage(event, '/accounts/banking')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Banking" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/accounts/loans"
                  onClick={(event) => reloadPage(event, '/accounts/loans')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Loans" />
                </ListItem>
                {partnerName && (
                  <ListItem
                    button
                    className={classes.nestedList}
                    component={NavLink}
                    to="/accounts/partner"
                    onClick={(event) => reloadPage(event, '/accounts/partner')}
                    activeClassName={classes.activeNav}
                  >
                    <ListItemText primary={partnerName} />
                  </ListItem>
                )}
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/accounts/property-managers"
                  onClick={(event) => reloadPage(event, '/accounts/property-managers')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Property Managers" />
                </ListItem>
                {airbnbAccountActive && (
                  <ListItem
                    button
                    className={classes.nestedList}
                    component={NavLink}
                    to={`/accounts/airbnb/${airbnbAccountId}`}
                    onClick={(event) => {
                      reloadPage(event, `/accounts/airbnb/${airbnbAccountId}`);
                    }}
                    activeClassName={classes.activeNav}
                  >
                    <ListItemText primary="Airbnb" />
                  </ListItem>
                )}
                {vrboAccountActive && (
                  <ListItem
                    button
                    className={classes.nestedList}
                    component={NavLink}
                    to={`/accounts/vrbo/${vrboAccountId}`}
                    onClick={(event) => {
                      reloadPage(event, `/accounts/vrbo/${vrboAccountId}`);
                    }}
                    activeClassName={classes.activeNav}
                  >
                    <ListItemText primary="VRBO" />
                  </ListItem>
                )}
              </List>
            </Collapse>
          )}
          <Box onClick={() => tracking('transactions clicked', { location: SEGMENT_LEFT_NAVIGATION_LOCATION })}>
            <ListItem button onClick={toggleDropdown('transactionsDrawer')}>
              <ListItemIcon className={drawersState.highlightCollapse === 'transactions' ? classes.activeCollapse : ''}>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="Transactions" />
              {drawersState.transactionsDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
          </Box>
          {!isSideNavMinimized && (
            <Collapse in={drawersState.transactionsDrawer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/transactions/revenues"
                  onClick={(event) => reloadPage(event, '/transactions/revenues')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Revenues" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/transactions/expenses"
                  onClick={(event) => reloadPage(event, '/transactions/expenses')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Expenses" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/transactions/booked"
                  onClick={(event) => reloadPage(event, '/transactions/booked')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Booked Transactions" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/transactions/imported"
                  onClick={(event) => reloadPage(event, '/transactions/imported')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Import Feed" />
                </ListItem>
                {recurringTransactions && (
                  <ListItem
                    button
                    className={classes.nestedList}
                    component={NavLink}
                    to="/transactions/recurring-transactions"
                    onClick={(event) => reloadPage(event, '/transactions/recurring-transactions')}
                    activeClassName={classes.activeNav}
                  >
                    <ListItemText primary="Recurring Transactions" />
                  </ListItem>
                )}
              </List>
            </Collapse>
          )}
          <Box onClick={() => tracking('organization clicked', { location: SEGMENT_LEFT_NAVIGATION_LOCATION })}>
            <ListItem button onClick={toggleDropdown('organizationDrawer')}>
              <ListItemIcon className={drawersState.highlightCollapse === 'organization' ? classes.activeCollapse : ''}>
                <CloudUploadOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Organization" />
              {drawersState.organizationDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
          </Box>
          {!isSideNavMinimized && (
            <Collapse in={drawersState.organizationDrawer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/fixed-assets"
                  onClick={(event) => reloadPage(event, '/organization/fixed-assets')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Fixed Assets" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/leases"
                  onClick={(event) => reloadPage(event, '/organization/leases')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Leases" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/vendors"
                  onClick={(event) => reloadPage(event, '/organization/vendors')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Vendors" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/mileage"
                  onClick={(event) => reloadPage(event, '/organization/mileage')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Mileage Log" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/time-tracking"
                  onClick={(event) => reloadPage(event, '/organization/time-tracking')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Time Tracking" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/notes"
                  onClick={(event) => reloadPage(event, '/organization/notes')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Documents & Notes" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/contacts"
                  onClick={(event) => reloadPage(event, '/organization/contacts')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Contacts" />
                </ListItem>
              </List>
            </Collapse>
          )}
          <Box onClick={() => tracking('reports clicked', { location: SEGMENT_LEFT_NAVIGATION_LOCATION })}>
            <ListItem
              button
              onClick={(event) => {
                if (drawersState.reportsDrawer) {
                  toggleDropdown('reportsDrawer')(event);
                  return;
                }
                navigateTo('/reports');
              }}
            >
              <ListItemIcon className={drawersState.highlightCollapse === 'reports' ? classes.activeCollapse : ''}>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
              {drawersState.reportsDrawer ? <ExpandLessIcon /> : null}
            </ListItem>
          </Box>
          {!isSideNavMinimized && (
            <Collapse in={drawersState.reportsDrawer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/reports"
                  onClick={(event) => reloadPage(event, '/reports')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="All" />
                </ListItem>
              </List>
            </Collapse>
          )}
        </List>
        <div className={classes.flexGrow} />
        <Divider />
        <List>
          <Box onClick={() => tracking('get_help clicked', { location: SEGMENT_LEFT_NAVIGATION_LOCATION })}>
            <ListItem button onClick={toggleDropdown('helpDrawer')}>
              <ListItemIcon>
                <HelpOutlineIcon />
              </ListItemIcon>
              <ListItemText style={{ whiteSpace: 'nowrap' }} primary="Get Help" />
              {drawersState.helpDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
          </Box>
          {!isSideNavMinimized && (
            <Collapse in={drawersState.helpDrawer} timeout="auto" unmountOnExit onClick={toggleDropdown('helpDrawer')}>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={Link}
                  underline="none"
                  href="https://support.reihub.net"
                  target="_blank"
                >
                  <ListItemText primary="Knowledge Base" />
                </ListItem>
                <ListItem button className={classes.nestedList} onClick={viewDemoPortfolio}>
                  <ListItemText primary="Demo Portfolio" />
                </ListItem>
                <ListItem button className={classes.nestedList} onClick={() => setIsContactUsOpen(true)}>
                  <ListItemText primary="Contact Us" />
                </ListItem>
              </List>
            </Collapse>
          )}
          <Box onClick={() => tracking('help_us clicked', { location: SEGMENT_LEFT_NAVIGATION_LOCATION })}>
            <ListItem button onClick={toggleDropdown('helpUsDrawer')}>
              <ListItemIcon>
                <VerifiedUserOutlinedIcon />
              </ListItemIcon>
              <ListItemText style={{ whiteSpace: 'nowrap' }} primary="Help Us" />
              {drawersState.helpUsDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
          </Box>
          {!isSideNavMinimized && (
            <Collapse
              in={drawersState.helpUsDrawer}
              timeout="auto"
              unmountOnExit
              onClick={toggleDropdown('helpUsDrawer')}
            >
              <List component="div" disablePadding>
                <ListItem button className={classes.nestedList} onClick={() => setIsReferralProgramOpen(true)}>
                  <ListItemText primary="Referral Program" />
                </ListItem>
                <ListItem button className={classes.nestedList} onClick={() => setIsSiteFeedbackOpen(true)}>
                  <ListItemText primary="Site Feedback" />
                </ListItem>
              </List>
            </Collapse>
          )}
        </List>
      </>
    );
  };

  const nullOrganizationText = () => {
    if (adminLogin) {
      return 'Admin';
    }
    if (accountantLogin) {
      return 'Accountant';
    }
    return 'Portfolios';
  };

  const subscriptionMessage = () => {
    const isSmallScreen = checkIsSmallScreen();

    if (moment().isSameOrBefore(moment(organizationCreated).add(7, 'days'), 'day')) {
      if (isSmallScreen) return 'Subscribe and Save';

      return `Subscribe and Save 40% - Ends ${moment(organizationCreated).add(7, 'days').format('MMM D')}`;
    }
    if (moment().isSameOrBefore(moment(freeTrialExpires), 'day')) {
      if (isSmallScreen) return 'Subscribe and Save';

      return `Subscribe and Save 10% - Ends ${moment(freeTrialExpires).format('MMM D')}`;
    }
    return 'Subscribe';
  };

  const handleDrawerChange = () => {
    if (isSideNavMinimized) {
      drawerRef.current.collapsed = false;
      setIsSideNavMinimized(false);
    } else {
      drawerRef.current.collapsed = true;
      setIsSideNavMinimized(true);
    }
  };

  const handleHover = (type) => {
    if (drawerRef.current.collapsed === true) {
      timeoutRef.current = setTimeout(() => {
        if (type === 'enter') {
          setIsSideNavMinimized(false);
          clearTimeout();
        }
        if (type === 'leave') {
          setIsSideNavMinimized(true);
          clearTimeout();
        }
      }, 750);
    }
  };

  return (
    <div className={classes.root}>
      <IdleTimer
        ref={(ref) => {
          idleTimerRef.current = ref;
        }}
        element={document}
        // 30 minute logout
        timeout={1000 * 60 * 120}
      />
      <ContactUsDialog
        isOpen={isContactUsOpen}
        closeDialog={() => {
          setIsContactUsOpen(false);
        }}
      />
      <WhoReferredDialog
        isOpen={isWhoReferredOpen}
        closeDialog={() => {
          setIsWhoReferredOpen(false);
        }}
      />
      <ReferralProgramDialog
        isOpen={isReferralProgramOpen}
        closeDialog={() => {
          setIsReferralProgramOpen(false);
        }}
        wasReferred={() => {
          setIsWhoReferredOpen(true);
          setIsReferralProgramOpen(false);
        }}
      />
      <SiteFeedbackDialog
        isOpen={isSiteFeedbackOpen}
        closeDialog={() => {
          setIsSiteFeedbackOpen(false);
        }}
        location={location}
      />
      {!isContactUsOpen && promptPayment && !dismissPayment ? (
        <SubscribeDialog
          isOpen
          closeDialog={() => {
            setDismissPayment(true);
          }}
          isContactUsOpen={() => {
            setIsContactUsOpen(true);
          }}
        />
      ) : null}
      <UpdateFailedPaymentMethodDialog
        isOpen={promptUpdatePaymentMethod && !dismissUpdateFailedPaymentMethod}
        closeDialog={() => {
          setDismissUpdateFailedPaymentMethod(true);
        }}
      />
      <AddBookedTransactionDialog
        isOpen={isAddTransactionDialogOpen}
        closeDialog={() => setIsAddTransactionDialogOpen(false)}
        onAddTransaction={() => navigateTo(location.pathname)}
      />
      <AppBar position="fixed" color="inherit" className={`${classes.appBar} noprint`}>
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              onClick={toggleDrawer('left', true)}
              aria-label="Menu"
              className={classes.menuIcon}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.flexGrow} />
            <RouterLink to="/dashboard" className={classes.link}>
              <img
                src={isTurboTenantPartner ? '/reihub-turbotenant-logo.svg' : '/rei_hub_logo_purple.svg'}
                height={isTurboTenantPartner ? '62px' : '54px'}
                alt="REI Hub"
                className={classes.logo}
              />
            </RouterLink>
          </Hidden>
          <Hidden smDown>
            {!isSideNavMinimized ? <Box className={classes.spacer} /> : <Box className={classes.minimizedSpacer} />}
            <IconButton
              className={classes.toggleDrawerButton}
              onClick={() => {
                handleDrawerChange();
              }}
            >
              {!isSideNavMinimized && <ChevronLeft fontSize="small" />}
              {isSideNavMinimized && <ChevronRight fontSize="small" />}
            </IconButton>
            <Box className={classes.searchBarContainer}>
              <SearchBar />
            </Box>
            <div className={classes.flexGrow} />
          </Hidden>
          <div className={classes.flexGrow} />
          <Hidden smDown>
            <div className={classes.inline}>
              <Typography variant="body2" color="inherit">
                <span className={classes.inlineBlock}>
                  {organizationName}
                  {adminLogin && ` (${organizationId})`}
                </span>
              </Typography>
            </div>
          </Hidden>
          <div className={classes.inline}>
            <Box onClick={() => tracking('right_sidebar_menu clicked', { location: 'Right Navigation Bar' })}>
              <IconButton
                aria-label="Settings Menu"
                color="inherit"
                onClick={toggleDrawer('right', true)}
                className={classes.accountIcon}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </div>
          <Drawer
            anchor="right"
            open={drawersState.right}
            onClose={toggleDrawer('right', false)}
            classes={{
              paper: classes.tempDrawerPaper,
            }}
          >
            <List>
              <ListItem button onClick={logOut}>
                <ListItemIcon>
                  <LockOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItem>
              <Divider />
              <ListItem button component={RouterLink} to="/settings" onClick={toggleDrawer('right', false)}>
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Portfolio Settings" />
              </ListItem>
              <ListItem button component={RouterLink} to="/chart-of-accounts" onClick={toggleDrawer('right', false)}>
                <ListItemIcon>
                  <ListOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Chart of Accounts" />
              </ListItem>
              <ListItem button component={RouterLink} to="/subscription" onClick={toggleDrawer('right', false)}>
                <ListItemIcon>
                  <CheckCircleOutlineOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Subscription" />
              </ListItem>
              <ListItem button component={RouterLink} to="/access" onClick={toggleDrawer('right', false)}>
                <ListItemIcon>
                  <PersonAddOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Access" />
              </ListItem>
              <Divider />
              <ListItem button component={RouterLink} to="/my-profile" onClick={toggleDrawer('right', false)}>
                <ListItemIcon>
                  <FaceOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="My Profile" />
              </ListItem>
              {adminLogin && adminUserSelect && (
                <ListItem
                  button
                  onClick={() => {
                    setOrganizationId(null);
                  }}
                >
                  <ListItemIcon>
                    <LaunchIcon />
                  </ListItemIcon>
                  <ListItemText primary="Portfolios" />
                </ListItem>
              )}
              <ListItem
                button
                onClick={() => {
                  if (adminLogin) {
                    setAdminUserSelect(null);
                  }
                  setOrganizationId(null);
                }}
              >
                <ListItemIcon>
                  <LaunchIcon />
                </ListItemIcon>
                <ListItemText primary={nullOrganizationText()} />
              </ListItem>
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Hidden smDown>
          <Box
            onTouchEnd={() => {
              setIsSideNavMinimized(false);
            }}
            onMouseEnter={() => {
              handleHover('enter');
            }}
            onMouseLeave={() => {
              if (timeoutRef) {
                clearTimeout(timeoutRef);
              }
              handleHover('leave');
            }}
          >
            <Drawer
              className={isSideNavMinimized ? `${classes.sideNavMini} noprint` : `${classes.sideNav} noprint`}
              variant="permanent"
              open
              anchor="left"
              classes={{
                paper: isSideNavMinimized ? classes.sideNavMini : classes.sideNav,
              }}
              ref={drawerRef}
            >
              {!isSideNavMinimized && (
                <Box className={classes.logoBox}>
                  <RouterLink to="/dashboard" className={classes.link}>
                    <img
                      src={isTurboTenantPartner ? '/reihub-turbotenant-logo.svg' : '/rei_hub_logo_purple.svg'}
                      height={isTurboTenantPartner ? '62px' : '54px'}
                      alt="REI Hub"
                      className={classes.logo}
                    />
                  </RouterLink>
                </Box>
              )}
              {isSideNavMinimized && (
                <Box className={classes.logoBox}>
                  <RouterLink to="/dashboard" className={classes.link}>
                    <img src="/rei_hub_favicon_purple.png" height="54px" alt="REI Hub" className={classes.logo} />
                  </RouterLink>
                </Box>
              )}
              {getNavList()}
            </Drawer>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Drawer
            anchor="left"
            open={drawersState.left}
            onClose={toggleDrawer('left', false)}
            classes={{
              paper: classes.tempDrawerPaper,
            }}
          >
            {getNavList()}
          </Drawer>
        </Hidden>

        <div className={classes.content}>
          <Toolbar className={`${classes.spacingToolbar} noprint`} />
          {promptPayment && !isTourVisible && (
            <Fade in={shouldPaymentFade} timeout={1500}>
              <AppBar color="default" position="static" className={`${classes.freeTrialAppBar} noprint`}>
                <Toolbar variant="dense" className={classes.freeTrialToolBar}>
                  <Typography variant="body2" align="center">
                    {`Trial: ${moment().diff(moment(freeTrialExpires).endOf('day')) < 0 ? `${moment(freeTrialExpires).diff(moment(), 'days') + 1} days left` : 'Expired'}`}
                  </Typography>
                  <div className={classes.flexGrow} />
                  <Box paddingY={0.5}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setDismissPayment(false);
                      }}
                    >
                      {subscriptionMessage()}
                    </Button>
                  </Box>
                </Toolbar>
              </AppBar>
            </Fade>
          )}
          {demo && (
            <>
              <AppBar color="default" position="static" className={`${classes.freeTrialAppBar} noprint`}>
                <Toolbar variant="dense" className={classes.freeTrialToolBar}>
                  <Typography variant="body2" align="center">
                    Demo Portfolio
                  </Typography>
                  <div className={classes.flexGrow} />
                  {id && (
                    <Box paddingY={0.5}>
                      <MuiButton color="secondary" variant="outlined" onClick={() => setOrganizationId(null)}>
                        Go To My Portfolios
                      </MuiButton>
                    </Box>
                  )}
                  {!id && (
                    <Box paddingY={0.5}>
                      <MuiButton color="secondary" variant="outlined" onClick={() => goToRegister()}>
                        Get Started Now
                      </MuiButton>
                    </Box>
                  )}
                </Toolbar>
              </AppBar>
              <DemoTour />
            </>
          )}
          {/* TODO: Uncomment these 2 lines and remove the third line when we have enough support for onboarding calls */}
          {/* {isScheduleCallDialogVisible && <ScheduleOnboardingCall />} */}
          {/* {!isScheduleCallDialogVisible && isTourVisible && <WelcomeTour />} */}
          {isTourVisible && <WelcomeTour />}
          <DashboardRoutes location={location} />
          <div className={classes.flexGrow} />
          <AppBar position="static" className={`${classes.bottomAppBar} noprint`}>
            <Toolbar className={classes.footer}>
              <Typography variant="body2" color="inherit">
                {`© ${moment().format('YYYY')}, `}
                <Link href="https://www.reihub.net" target="_blank>" color="inherit" underline="always">
                  REI Hub
                </Link>
                <br />
                <Link href="https://support.reihub.net" target="_blank>" color="inherit" underline="always">
                  Knowledge Base
                </Link>
              </Typography>
              <Typography variant="body2" color="inherit" align="right">
                <Link href="https://www.reihub.net/terms" target="_blank>" color="inherit" underline="always">
                  Terms of Service
                </Link>
                <br />
                <Link href="https://www.reihub.net/privacy" target="_blank>" color="inherit" underline="always">
                  Privacy Policy
                </Link>
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
      </Container>
    </div>
  );
}
