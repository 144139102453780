import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { DialogActions } from '@material-ui/core';
import history from '../history';
import { PersonContext } from '../contexts/PersonContext';

import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import FilterDialog from '../components/FilterDialog';
import { asyncHandleChange } from '../functions/InputHandlers';

class AttachmentsExport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterDialog: true,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
      dialogOpen: false,
    };
  }

  goToReports = () => {
    history.replace('/reports');
  }

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.setState({ filterDialog: false, error: null });
    this.exportZip();
  };

  closeFilter = () => {
    this.setState({ filterDialog: false });
    this.goToReports();
  };

  closeExportDialog = () => {
    this.setState({ dialogOpen: false });
    this.goToReports();
  };

  exportZip = async () => {
    const { organizationId, organizationName } = this.context;
    const { location } = this.props;
    const { filter } = this.state;

    this.setState({ dialogOpen: true });

    await fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-zip`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = await response.json();
          return this.setState({ error });
        }
        const blob = new Blob([await response.blob()], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${organizationName} ${moment(filter.startDate).format('ll')}-${moment(filter.endDate).format('ll')} REI Hub Attachments Export`;
        document.body.appendChild(a);
        a.click();
        return this.setState({ exportComplete: true });
      })
      .catch((error) => {
        this.setState({ error, filterDialog: true });
      });
  };

  render() {
    const { match } = this.props;
    const {
      filter, filterDialog, error, dialogOpen, exportComplete,
    } = this.state;
    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Attachments Export"
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          required={['dateRange']}
          exportError={error}
        />
        <Dialog
          open={dialogOpen}
          scroll="body"
          maxWidth="sm"
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="exportAttachmentDialog"
        >
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              Exporting Attachments
            </Typography>
            {!error && (
              <Typography gutterBottom>
                Please allow up to 60 seconds for the export to complete. Your file will download
                automatically when finished.
              </Typography>
            )}
            <Typography color="error">{error && `Error: ${error.message}`}</Typography>
            <DialogActions>
              {!error && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={this.goToReports}
                disabled={!exportComplete}
              >
                Done
              </Button>
              )}
              {error && (
              <Button
                color="primary"
                onClick={() => {
                  this.setState(
                    { dialogOpen: false, filterDialog: true },
                  );
                }}
              >
                Back
              </Button>
              )}
            </DialogActions>
          </DialogContent>
        </Dialog>
      </PageGrid>
    );
  }
}

AttachmentsExport.contextType = PersonContext;

AttachmentsExport.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AttachmentsExport;
