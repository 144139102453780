import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import history from '../history';
import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import {
  asyncHandleChange,
  asyncSetState,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine2 from '../components/FinancialAccountLine2';

class NetOperatingIncomeByPeriod extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        period: null,
        entityId: null,
        propertyId: null,
        unitId: null,
        startDateSelect: null,
        endDateSelect: null,
        periodSelect: null,
        entityIdSelect: null,
        propertyIdSelect: null,
        unitIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');
      newFilter.periodSelect = { name: 'Monthly', id: 'month' };

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate && filter.period)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const query = Object.assign(buildQuery(this, true), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });

    const accountsObject = {
      revenues: {
        accounts: [],
      },
      expenses: {
        accounts: [],
      },
      allIncluded: {
        accounts: [],
      },
      excluded: {
        accounts: [],
      },
      byId: {},
    };

    const accountsPromises = [
      create(this, 'reports', query)
        .then((result) => {
          /* eslint-disable no-param-reassign */
          result.forEach((account) => {
            account.display = account.netCredits !== 0;
            account.subaccounts = [];
            account.values = [account.netCredits];
            if (
              account.type2 === 'Investing Revenue'
              || account.type2 === 'Interest Expense'
              || account.type2 === 'Depreciation Expense'
            ) {
              accountsObject.excluded.accounts.push(account);
            } else if (account.type === 'Expense') {
              accountsObject.expenses.accounts.push(account);
              accountsObject.allIncluded.accounts.push(account);
            } else if (account.type === 'Revenue') {
              accountsObject.revenues.accounts.push(account);
              accountsObject.allIncluded.accounts.push(account);
            }
            accountsObject.byId[account.id] = account;
          });
          /* eslint-enable no-param-reassign */
          return result;
        }),
    ];

    const periods = [];
    const endDateMoment = moment(filter.endDate, 'YYYY-MM-DD');

    const periodStartDate = moment(filter.startDate, 'YYYY-MM-DD');
    let periodEndDate = moment.min(
      moment(filter.startDate, 'YYYY-MM-DD').endOf(filter.period),
      moment(filter.endDate, 'YYYY-MM-DD'),
    );

    while (periodStartDate.isBefore(endDateMoment)) {
      periods.push({
        name: this.getPeriodName(periodStartDate, filter.period),
        startDate: periodStartDate.format('YYYY-MM-DD'),
        endDate: periodEndDate.format('YYYY-MM-DD'),
      });
      periodStartDate.add(1, filter.period).startOf(filter.period);
      periodEndDate.add(1, filter.period).endOf(filter.period);
      if (endDateMoment.isBefore(periodEndDate)) {
        periodEndDate = moment(filter.endDate, 'YYYY-MM-DD');
      }
    }

    periods.forEach((period) => {
      accountsPromises.push(
        create(this, 'reports', {
          ...query,
          startDate: period.startDate,
          endDate: period.endDate,
        }),
      );
    });

    const accountsResults = await Promise.all(accountsPromises);

    accountsResults[0].forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
        if (account.netCredits !== 0) {
          accountsObject.byId[account.parentAccountId].display = true;
        }
      }
    });

    for (let i = 1; i < accountsResults.length; i += 1) {
      accountsResults[i].forEach((account, index) => {
        accountsResults[0][index].values.push(account.netCredits);
        if (account.netCredits !== 0) {
          accountsResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    const headers = periods.map((period) => period.name);
    headers.unshift('Total');

    const additionalCriteriaArray = [{}];
    periods.forEach((period) => {
      additionalCriteriaArray.push({
        startDate: period.startDate,
        endDate: period.endDate,
      });
    });

    await asyncSetState({
      loading: false,
      headers,
      accountsObject,
      additionalCriteria: additionalCriteriaArray,
    }, this);
    return { success: true };
  };

  getPeriodName = (periodStartDateMoment, period) => {
    if (period === 'month') {
      return periodStartDateMoment.format('MMM-YY');
    }
    if (period === 'quarter') {
      return periodStartDateMoment.format('[Q]Q-YY');
    }
    return periodStartDateMoment.format('YYYY');
  }

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const {
      filter, accountsObject, headers, exportOptions,
    } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        headers,
        exportOptions,
        reportName: 'Net Operating Income By Period',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'net-operating-income-by-period.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  }

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Export', action: (() => this.setState({ downloadDialog: true })), class: 'export' },
    ]
  );

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      headers,
      accountsObject,
      filter,
      filterDialog,
      loading,
      downloadDialog,
      additionalCriteria,
    } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Net Operating Income By Period"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          period
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property', 'Unit']}
          required={['dateRange', 'period', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                  <FinancialAccountLine2
                    values={headers}
                    displayType="text"
                    bold
                  />
                  <FinancialAccountLine2
                    label="Operating Revenues"
                    underline
                    overline
                    bold
                  />
                  {accountsObject.revenues.accounts.map((account) => (
                    <FinancialAccountLine2
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      indent={1}
                      filter={filter}
                      additionalCriteria={additionalCriteria}
                    />
                  ))}
                  <FinancialAccountLine2
                    label="Total Operating Revenues"
                    values={this.sumColumnValues(accountsObject.revenues.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine2
                    label="Operating Expenses"
                    underline
                    overline
                    bold
                  />
                    {accountsObject.expenses.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                  <FinancialAccountLine2
                    label="Total Operating Expenses"
                    values={this.sumColumnValues(accountsObject.expenses.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine2
                    label="Net Operating Income"
                    values={this.sumColumnValues(accountsObject.allIncluded.accounts)}
                    underline
                    overline
                    bold
                  />
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

NetOperatingIncomeByPeriod.contextType = PersonContext;

NetOperatingIncomeByPeriod.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NetOperatingIncomeByPeriod;
