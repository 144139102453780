import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { find } from '../../feathersWrapper';

import { PersonContext } from '../../contexts/PersonContext';

const styles = (theme) => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(2),
    alignContent: 'flex-start',
  },
  pageCenter: {
    alignContent: 'center',
  },
  loginCard: {
    maxWidth: '550px',
    textAlign: 'center',
    width: '100%',
    zIndex: 2,
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  resetLink: {
    color: '#FFF',
    textDecoration: 'none',
  },
  noUnderlineLink: {
    textDecoration: 'none',
  },
});

class AccountantFirms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.verifyRole();
  }

  verifyRole = async () => {
    const {
      id, setContextState,
    } = this.context;

    const firms = await find(this, 'accounting-administrators', { query: { personId: id } });

    if (firms.total === 1) {
      localStorage.setItem('accountingFirmId', firms.data[0].accountingFirmId);
      setContextState({ accountingFirmId: firms.data[0].accountingFirmId });
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    const { classes } = this.props;
    const cx = classNames.bind(classes);
    const { loading } = this.state;

    return (
      <Grid container justify="center" className={cx({ root: true, pageCenter: true })}>
        <Grid item xs={12} className={classes.centerContent}>
          {!loading && (
            <Card className={classes.loginCard}>
              <CardContent>
                <Typography>Multiple roles found. please notify Jon</Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    );
  }
}

AccountantFirms.contextType = PersonContext;

AccountantFirms.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(AccountantFirms);
