import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

// import {PersonContext} from '../contexts/PersonProvider.jsx';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
  },
};

class CenterContentContainer extends React.PureComponent {
  render() {
    const { classes, children } = this.props;

    return (
      <div className={classes.root}>
        {children}
      </div>
    );
  }
}

CenterContentContainer.defaultProps = {
  children: null,
};

CenterContentContainer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(CenterContentContainer);
