import React, { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { patch, create } from '../feathersWrapper';
import history from '../history';

const OAuthLink = () => {
  const plaidOAuthData = JSON.parse(localStorage.getItem('plaid_oauth_data'));

  // eslint-disable-next-line camelcase
  const onSuccess = React.useCallback(async (publicToken, metadata) => {
    if (plaidOAuthData.updateItem) {
      await patch(this, 'plaid-items', plaidOAuthData.updateItem, {
        requiresUpdate: false,
      });
    } else if (plaidOAuthData.accountId) {
      await create(this, 'plaid-items', {
        organizationId: plaidOAuthData.organizationId,
        accountId: plaidOAuthData.accountId,
        importStartDate: plaidOAuthData.importStartDate,
        plaidPublicToken: publicToken,
        plaidMetadata: metadata,
      });
    } else if (plaidOAuthData.plaidItemId) {
      await create(this, 'plaid-items', {
        organizationId: plaidOAuthData.organizationId,
        accountId: plaidOAuthData.accountId,
        importStartDate: plaidOAuthData.importStartDate,
        plaidPublicToken: publicToken,
        plaidMetadata: metadata,
        plaidItemId: plaidOAuthData.plaidItemId,
      });
    } else {
      await create(this, 'plaid-items', {
        organizationId: plaidOAuthData.organizationId,
        importStartDate: plaidOAuthData.importStartDate,
        plaidPublicToken: publicToken,
        plaidMetadata: metadata,
      });
    }
    localStorage.removeItem('plaid_oauth_data');
    history.replace('/accounts/banking');
  }, [plaidOAuthData]);

  const onExit = (err, metadata) => {
    // eslint-disable-next-line no-console
    console.log(err);
    // eslint-disable-next-line no-console
    console.log(metadata);
    localStorage.removeItem('plaid_oauth_data');
    history.replace('/accounts/banking');
  };

  const config = {
    token: plaidOAuthData.linkToken,
    receivedRedirectUri: window.location.href,
    onSuccess,
    onExit,
  };

  // const { open, ready, error } = usePlaidLink(config);
  const { open, ready } = usePlaidLink(config);

  // automatically re-initialize Link
  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return <></>;
};

export default OAuthLink;
