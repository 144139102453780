import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncSetState,
  handleTextFieldChange,
} from '../functions/InputHandlers';

class AddVehicleDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = async () => {
    const { organizationId } = this.context;
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      vehicle: {
        organizationId,
        name: '',
      },
    };

    await asyncSetState(initialState, this);
  };

  addVehicle = async (event) => {
    event.preventDefault();
    const {
      submitting, vehicle,
    } = this.state;

    const { onAdd, closeDialog } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    create(this, 'vehicles', vehicle, true)
      .then((result) => {
        onAdd(result);
        closeDialog();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, error, vehicle,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && (
          <form onSubmit={this.addVehicle}>
            <DialogTitle id="alert-dialog-title">Add Vehicle</DialogTitle>
            <DialogContent>
              <TextField
                label="Vehicle Name"
                fullWidth
                multiline
                margin="dense"
                InputProps={{
                  value: vehicle.name,
                  name: 'nested_vehicle_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Vehicle
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

AddVehicleDialog.contextType = PersonContext;

AddVehicleDialog.defaultProps = {
};

AddVehicleDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddVehicleDialog;
