import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import PlaceIcon from '@material-ui/icons/Place';
import LaunchIcon from '@material-ui/icons/Launch';

import { PersonContext } from '../../contexts/PersonContext';

import { find } from '../../feathersWrapper';

import LinkBase from '../../components/LinkBase';
import CardBase from '../../components/CardBase';

const styles = (theme) => ({
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  imageBox: {
    flexShrink: 0,
    width: '30%',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  imagePaper: {
    width: '100%',
    paddingTop: 'calc(100% * 2 / 3)',
    position: 'relative',
    overflow: 'hidden',
  },
  noImageDiv: {
    backgroundColor: theme.palette.grey['200'],
    paddingTop: 'calc(((100% * 2 / 3) - 36px)/2)',
    paddingBottom: 'calc(((100% * 2 / 3) - 36px)/2)',
    fontSize: '36px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
  },
  propertyImage: {
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  addressBox: {
    flexGrow: 1,
    padding: '6px 6px 6px 16px',
  },
  inlineIcon: {
    verticalAlign: 'bottom',
  },
});

class PropertiesCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { organizationId } = this.context;
    const { view, id } = this.props;
    const subportfolio = {};
    if (view === 'subportfolio') {
      subportfolio.entityId = id;
    }
    const propertiesPromise = find(this, 'properties', {
      query: {
        organizationId,
        ...subportfolio,
        inactive: false,
        $limit: 3,
        $sort: {
          image: -1,
          address1: 1,
        },
      },
    });

    const properties = await propertiesPromise;

    this.setState({
      properties: properties.data,
      totalProperties: properties.total,
      loading: false,
    });
  };

  getPropertiesLink = () => {
    const {
      view, id,
    } = this.props;
    const { totalProperties } = this.state;

    if (view === 'subportfolio') {
      return (
        <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
          <LinkBase to={`/properties?entityId=${id}`}>
            {`View Properties (${totalProperties})`}
          </LinkBase>
        </Box>
      );
    }
    return (
      <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
        <LinkBase to="/properties">
          {`View Properties (${totalProperties})`}
        </LinkBase>
      </Box>
    );
  };

  render() {
    const {
      loading, properties, totalProperties,
    } = this.state;
    const { classes } = this.props;

    if (loading) {
      return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Properties
              </Typography>
            </Box>
          </CardBase>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <CardBase>
          <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
            <Typography variant="h6" gutterBottom>
              Properties
            </Typography>
            {totalProperties === 0 && (
              <Typography variant="subtitle2" gutterBottom>No properties created</Typography>
            )}
            {properties.map((property) => (
              <Box
                key={property.id}
                display="flex"
                flexDirection="row"
                className={classes.bottomBorder}
              >
                <Box display="flex" flexDirection="column" justifyContent="center" className={classes.imageBox}>
                  {property.image && property.image !== '' ? (
                    <Paper className={classes.imagePaper} elevation={0}>
                      <img
                        src={property.image}
                        alt={property.address1}
                        className={classes.propertyImage}
                      />
                    </Paper>
                  ) : (
                    <div className={classes.noImageDiv}>
                      <PlaceIcon color="disabled" fontSize="inherit" />
                    </div>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" className={classes.addressBox}>
                  <Typography variant="subtitle2">
                    {property.address1}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Link
                    to={`/properties/property/${property.id}`}
                  >
                    <LaunchIcon fontSize="small" color="secondary" className={classes.inlineIcon} />
                  </Link>
                </Box>
              </Box>
            ))}
            {this.getPropertiesLink()}
          </Box>
        </CardBase>
      </Grid>
    );
  }
}

PropertiesCard.contextType = PersonContext;

PropertiesCard.defaultProps = {
  view: 'portfolio',
  id: null,
};

PropertiesCard.propTypes = {
  view: PropTypes.string,
  id: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(PropertiesCard);
