import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
} from '../functions/InputHandlers';

const styles = {};

class SiteFeedbackDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  getInitialState = () => {
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      message: '',
    };

    return initialState;
  };

  contactUs = async (event) => {
    event.preventDefault();
    const {
      submitting, message,
    } = this.state;

    const { organizationId } = this.context;
    const { location } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    create(
      this,
      'contact-us',
      {
        organizationId,
        personContext: this.context,
        location,
        subject: 'Site Feedback',
        message: `Site Feedback: ${message}`,
      },
    );
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, submitting, error, message,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        maxWidth="sm"
        fullWidth
        onClose={closeDialog}
        onEnter={() => { this.setState(this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="siteFeedbackDialog"
      >
        {!loading && !submitting && (
          <form onSubmit={this.contactUs}>
            <DialogTitle id="alert-dialog-title">Site Feedback</DialogTitle>
            <DialogContent>
              <TextField
                label="Feedback"
                helperText="Thank you for helping us improve REI Hub."
                fullWidth
                multiline
                rows="7"
                variant="filled"
                color="secondary"
                margin="dense"
                InputProps={{
                  value: message,
                  name: 'message',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Submit
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && submitting && (
          <>
            <DialogTitle id="alert-dialog-title">Site Feedback</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`We have received your message. Thank you for sharing your feedback. 
                  It helps guide our next steps as we work to improve REI Hub.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} variant="contained" color="primary" disableElevation>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

SiteFeedbackDialog.contextType = PersonContext;

SiteFeedbackDialog.defaultProps = {
};

SiteFeedbackDialog.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default withStyles(styles)(SiteFeedbackDialog);
