import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CardCheckbox from '~/components/CardCheckbox/CardCheckbox';
import { useOnboardingContext } from '~/contexts/OnboardingProvider';
import { PersonContext } from '~/contexts/PersonContext';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import CurrencyExchangeOutlined from '~/icons/CurrencyExchangeOutlined';
import HolidayVillageOutlined from '~/icons/HolidayVillageOutlined';
import HomeWorkOutlined from '~/icons/HomeWorkOutlined';

import { BEST_DESCRIBES_YOU } from '../constants';
import useUpdateUserOnboarding from '../hooks/useUpdateUserOnboarding';

const SEGMENT_LOCATION = 'Onboarding - Questions: Describes You';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '960px',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardCheckboxesContainer: {
    'display': 'flex',
    'flexDirection': 'column',
    'gap': theme.spacing(2),
    'marginBottom': theme.spacing(4),

    '& > div': {
      flex: 1,
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  submitButton: {
    marginBottom: theme.spacing(2),
    marginInline: 'auto',
    color: theme.palette.primary.contrastText,
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      width: '400px',
    },
  },
  orContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      paddingInline: '88px',
    },
  },
  divider: {
    flex: 1,
    backgroundColor: '#0000003B',
  },
  accountantLink: {
    marginInline: 'auto',
    marginTop: theme.spacing(1),
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      width: '400px',
    },
  },
}));

export default function DescribesYou() {
  const [bestDescribesYou, setBestDescribesYou] = useState('');

  const { userOnboarding } = useOnboardingContext();

  useEffect(() => {
    if (userOnboarding?.bestDescribesYou) {
      setBestDescribesYou(userOnboarding.bestDescribesYou);
    }
  }, [userOnboarding?.bestDescribesYou]);

  function handleBestDescribesYouChange(value) {
    if (value === bestDescribesYou) {
      setBestDescribesYou(undefined);
    } else {
      setBestDescribesYou(value);
    }
  }

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { triggerSnackbar } = useContext(PersonContext);
  const updateUserOnboarding = useUpdateUserOnboarding();
  const tracking = useSegmentTrack();
  const history = useHistory();

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);

      if (!bestDescribesYou || bestDescribesYou === BEST_DESCRIBES_YOU.ACCOUNTANT) {
        setIsSubmitting(false);
        triggerSnackbar({
          message: 'Please select an option.',
          type: 'warning',
        });
        return;
      }

      await updateUserOnboarding({ bestDescribesYou });

      tracking('next clicked', { location: SEGMENT_LOCATION });

      if (bestDescribesYou === BEST_DESCRIBES_YOU.INVESTOR) {
        history.push('/manage-rentals');
      } else {
        history.push('/about-you');
      }
    } catch {
      setIsSubmitting(false);
      triggerSnackbar({
        message: 'Something went wrong. Please try again.',
        type: 'error',
      });
    }
  }

  async function handleAccountantLink() {
    try {
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);

      await updateUserOnboarding({ bestDescribesYou: BEST_DESCRIBES_YOU.ACCOUNTANT });

      tracking('im_an_accountant clicked', { location: SEGMENT_LOCATION });

      history.push('/previous-process');
    } catch {
      setIsSubmitting(false);
      triggerSnackbar({
        message: 'Something went wrong. Please try again.',
        type: 'error',
      });
    }
  }

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography variant="h6" className={classes.title}>
        What best describes you?
      </Typography>

      <form onSubmit={handleSubmit} className={classes.form}>
        <div className={classes.cardCheckboxesContainer}>
          <CardCheckbox
            alignment={isMediumScreen ? 'vertical' : 'horizontal'}
            checked={bestDescribesYou === BEST_DESCRIBES_YOU.INVESTOR}
            description="Individual who owns rentals"
            icon={<CurrencyExchangeOutlined />}
            title="Investor"
            onChange={() => handleBestDescribesYouChange(BEST_DESCRIBES_YOU.INVESTOR)}
          />
          <CardCheckbox
            alignment={isMediumScreen ? 'vertical' : 'horizontal'}
            checked={bestDescribesYou === BEST_DESCRIBES_YOU.PROPERTY_MANAGER}
            description="You manage rentals for others"
            icon={<HomeWorkOutlined />}
            title="Property Manager"
            onChange={() => handleBestDescribesYouChange(BEST_DESCRIBES_YOU.PROPERTY_MANAGER)}
          />
          <CardCheckbox
            alignment={isMediumScreen ? 'vertical' : 'horizontal'}
            checked={bestDescribesYou === BEST_DESCRIBES_YOU.MIX_OF_BOTH}
            description="You manage rentals for yourself and others"
            icon={<HolidayVillageOutlined />}
            title="Mix of Both"
            onChange={() => handleBestDescribesYouChange(BEST_DESCRIBES_YOU.MIX_OF_BOTH)}
          />
        </div>

        <Button
          color="primary"
          disabled={isSubmitting}
          size="large"
          type="submit"
          variant="contained"
          className={classes.submitButton}
        >
          Next
        </Button>
      </form>

      <div className={classes.orContainer}>
        <Divider className={classes.divider} />
        <Typography component="span" variant="body1">
          or
        </Typography>
        <Divider className={classes.divider} />
      </div>

      <Typography align="center" variant="body2">
        Are you using REI Hub to maintain someone else&apos;s books?
      </Typography>

      <Button
        color="primary"
        disabled={isSubmitting}
        size="large"
        variant="text"
        onClick={() => handleAccountantLink()}
        className={classes.accountantLink}
      >
        I&apos;m an Accountant
      </Button>
    </Card>
  );
}
