import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import ButtonBase from '@material-ui/core/ButtonBase';

import { asyncHandleChange } from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';

import FilterDialog from '../components/FilterDialog';
import PageGrid from '../components/PageGrid';

import AddHoursDialog from '../components/AddHoursDialog';
import EditHoursDialog from '../components/EditHoursDialog';
import DownloadDialog from '../components/DownloadDialog';

const styles = (theme) => ({
  textButton: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
});

class Hours extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hours: null,
      loading: true,
      addDialog: false,
      editDialog: false,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        page: 0,
        startDate: null,
        endDate: null,
        propertyId: null,
        startDateSelect: null,
        endDateSelect: null,
        propertyIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    await this.getHours();

    this.setState({ loading: false });
  }

  getHours = async () => {
    const { filter } = this.state;
    const query = Object.assign(buildQuery(this), {
      $sort: {
        date: -1,
        hours: -1,
      },
      $limit: 50,
      $skip: filter.page * 50,
    });

    const response = await find(this, 'hours', { query });
    const records = response.data;

    const totalRecords = response.total;

    this.setState({
      totalRecords,
      hours: records,
      loading: false,
    });
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getHours();
  };

  closeDialogs = () => {
    this.setState({
      filterDialog: false,
      addDialog: false,
      editDialog: false,
    });
  };

  onDialogs = async () => {
    await this.getHours();
    this.closeDialogs();
  };

  handlePageChange = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getHours();
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, hours, exportOptions } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        hours,
        exportOptions,
        reportName: 'Time Tracking',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'time-tracking.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    asyncHandleChange('exportOptions', options, this);
  }

  actionButtons = () => {
    const buttons = [
      {
        text: 'Filter',
        action: () => this.setState({ filterDialog: true }),
        class: 'filter',
      },
      {
        text: 'Export',
        action: () => this.setState({ downloadDialog: true }),
        class: 'export',
      },
      {
        text: 'Add Hours',
        action: () => this.setState({ addDialog: true }),
        class: 'add',
      },
    ];
    return buttons;
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading,
      addDialog,
      hours,
      filter,
      filterDialog,
      totalRecords,
      editDialog,
      downloadDialog,
      hoursId,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Time Tracking"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        {addDialog && (
          <AddHoursDialog
            isOpen
            closeDialog={this.closeDialogs}
            onAdd={this.onDialogs}
          />
        )}
        {editDialog && (
          <EditHoursDialog
            isOpen
            closeDialog={this.closeDialogs}
            hoursId={hoursId}
            onEdit={this.onDialogs}
            onDelete={this.onDialogs}
          />
        )}
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
          setExportOptions={this.setExportOptions}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={() => this.setState({ filterDialog: false })}
          updateFilter={this.updateFilter}
          dateRange
          property
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">Date</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Hours</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Property</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Description</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hours.map((record) => (
                    <TableRow key={record.id}>
                      <TableCell>
                        <ButtonBase
                          className={classes.textButton}
                          onClick={() => {
                            this.setState({
                              hoursId: record.id,
                              editDialog: true,
                            });
                          }}
                        >
                          <Typography
                            variant="body2"
                            component="div"
                            gutterBottom
                          >
                            {moment(record.date).format('M/D/YYYY')}
                          </Typography>
                        </ButtonBase>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <NumberFormat
                            displayType="text"
                            value={record.hours}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" gutterBottom>
                          {record.property
                            ? `${record.property.address1}`
                            : 'No Property Assigned'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" gutterBottom>
                          {record.description}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={totalRecords}
                      page={filter.page}
                      rowsPerPage={50}
                      onPageChange={this.handlePageChange}
                      rowsPerPageOptions={[50]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

Hours.contextType = PersonContext;

Hours.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Hours);
