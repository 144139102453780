import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import withStyles from '@material-ui/core/styles/withStyles';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ButtonBase } from '@material-ui/core';
import { get, create } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';
import {
  handleAutocompleteChange,
  handleTextFieldChange,
  handleNumberFormatChange,
  asyncHandleChange,
  handleKeyboardDatePickerChange,
  handleUploaderChange,
  handleTransactionScopeChange,
} from '../../functions/InputHandlers';

import {
  nameLabel,
  getAccountOptions,
  getPaymentAccountOptions,
  getVendorOptions,
  getFixedAssetOptions,
} from '../Autocomplete/Library';

import {
  getManualBasicJournal,
} from '../SearchSelect/TransactionTypeOptions';

import {
  setJournalScope,
} from '../../functions/JournalFunctions';

import TransactionScope from '../TransactionScope';

const styles = (theme) => ({
  hideWidget: {
    display: 'none',
  },
  red: {
    color: 'red',
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddRefundDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { yodleeTransaction } = this.props;
    const {
      propertyId, unitId, propertyManagerId, bankAccountId,
    } = this.props;
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      displayNotesSection: false,
    };

    const journal = getManualBasicJournal();
    journal.type = 'Refund';

    if (yodleeTransaction) {
      journal.debitLines[0].accountIdSelect = yodleeTransaction.account;
      journal.debitLines[0].yodleeTransactionId = yodleeTransaction.id;
      journal.description = yodleeTransaction.description;
      journal.date = yodleeTransaction.date;
      journal.amount = yodleeTransaction.amount;
    }

    const usePropertyId = propertyId;
    const useUnitId = unitId;

    if (useUnitId) {
      const unit = await get(this, 'units', useUnitId);
      journal.propertyIdSelect = unit.property;
      journal.unitIdSelect = unit;
    } else if (usePropertyId) {
      const property = await get(this, 'properties', usePropertyId);
      journal.propertyIdSelect = property;
    } else if (propertyManagerId) {
      const propertyManager = await get(this, 'property-managers', propertyManagerId);
      journal.creditLines[0].accountIdSelect = propertyManager.account;
    } else if (bankAccountId) {
      const account = await get(this, 'accounts', bankAccountId);
      journal.creditLines[0].accountIdSelect = account;
      if (account.property) {
        journal.propertyIdSelect = account.property;
      }
    }

    journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
    initialState.journal = journal;
    initialState.expenseAccountOptions = await getAccountOptions(this, { $or: [{ type: 'Expense' }, { type2: ['Sales and Use Tax', 'Fixed Asset'] }] });
    initialState.paymentAccountOptions = await getPaymentAccountOptions(this);
    initialState.vendorOptions = await getVendorOptions(this);
    initialState.fixedAssetOptions = await getFixedAssetOptions(this);

    this.setState(initialState);
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const {
      submitting, journal,
    } = this.state;

    const { onAddTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    if (!journal.amount) {
      this.setState({ error: { message: 'Please enter a non-zero amount for this transaction' } });
      return;
    }

    setJournalScope(journal);

    this.setState({ submitting: true });

    journal.organizationId = organizationId;
    journal.debitLines[0].debit = journal.amount;
    journal.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;
    journal.creditLines[0].credit = journal.amount;
    journal.creditLines[0].accountId = journal.creditLines[0].accountIdSelect.id;
    journal.vendorId = journal.vendorIdSelect ? journal.vendorIdSelect.id : null;
    journal.fixedAssetId = journal.fixedAssetIdSelect ? journal.fixedAssetIdSelect.id : null;
    journal.type = 'Refund';
    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    // errors will be displayed within the dialog rather than throwing to the error boundary
    create(this, 'journals', journal, true)
      .then(async (journalResult) => {
        onAddTransaction(journalResult);
        this.closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return (
          <span className={classes.red}>
            {`(${value})`}
          </span>
        );
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return (
          <span className={classes.red}>
            {`(${value})`}
          </span>
        );
      }
    }
    return value;
  };

  render() {
    const {
      classes, yodleeTransaction,
    } = this.props;
    const { vendorTracking } = this.context;
    const {
      loading,
      error,
      journal,
      expenseAccountOptions,
      paymentAccountOptions,
      vendorOptions,
      fixedAssetOptions,
      displayNotesSection,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        <form onSubmit={this.addTransaction}>
          <DialogTitle id="alert-dialog-title">
            Add Refund
          </DialogTitle>
          <DialogContent>
            {yodleeTransaction && (
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Transaction
                </FormLabel>
                <Typography>
                  {moment(yodleeTransaction.date).format('M/D/YYYY')}
                  <Box px={2} component="span">|</Box>
                  <NumberFormat
                    displayType="text"
                    value={yodleeTransaction.amount}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                  />
                  <Box px={2} component="span">|</Box>
                  {yodleeTransaction.account.name}
                </Typography>
              </FormControl>
            )}
            <KeyboardDatePicker
              label="Date"
              format="MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              value={journal.date}
              onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
              margin="dense"
              fullWidth
              clearable
              required
            />
            <FormControl margin="dense" fullWidth>
              <InputLabel required>
                Amount
              </InputLabel>
              <NumberFormat
                value={journal.amount}
                required
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
                onValueChange={handleNumberFormatChange('nested_journal_amount', this)}
                customInput={Input}
              />
            </FormControl>
            <TransactionScope
              journal={journal}
              transactionScopeChange={(newScopeValues) => {
                handleTransactionScopeChange(journal, newScopeValues, this);
              }}
            />
            <Autocomplete
              options={expenseAccountOptions}
              getOptionLabel={nameLabel}
              value={journal.creditLines[0].accountIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_creditLines_0_accountIdSelect',
                this,
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Expense Account"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
            <Autocomplete
              options={paymentAccountOptions}
              getOptionLabel={nameLabel}
              value={journal.debitLines[0].accountIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_debitLines_0_accountIdSelect',
                this,
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Payment Account Refunded"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
            {journal.creditLines[0].accountIdSelect && journal.creditLines[0].accountIdSelect.type2 === 'Fixed Asset' && (
              <Autocomplete
                options={fixedAssetOptions}
                getOptionLabel={nameLabel}
                value={journal.fixedAssetIdSelect}
                onChange={handleAutocompleteChange(
                  'nested_journal_fixedAssetIdSelect',
                  this,
                )}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Fixed Asset"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
            )}
            {vendorTracking && (
              <Autocomplete
                options={vendorOptions}
                getOptionLabel={nameLabel}
                value={journal.vendorIdSelect}
                onChange={handleAutocompleteChange(
                  'nested_journal_vendorIdSelect',
                  this,
                )}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Vendor (optional)"
                    placeholder="Type to Search"
                    fullWidth
                  />
                )}
              />
            )}
            <TextField
              label="Description (optional)"
              fullWidth
              margin="dense"
              InputProps={{
                value: journal.description,
                name: 'nested_journal_description',
                onChange: handleTextFieldChange(this),
              }}
            />
            {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
            )}
            {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
            )}
            <FormControl margin="dense">
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Attachments
              </FormLabel>
              <div className={journal.attachmentURL ? null : classes.hideWidget}>
                <Widget
                  ref={this.uploadcareWidget}
                  multiple
                  tabs="file url camera"
                  value={journal.attachmentURL}
                  onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
                />
              </div>
              {!journal.attachmentURL && (
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.editImageButton}
                  onClick={() => {
                    this.uploadcareWidget.current.openDialog();
                  }}
                >
                  Click to Add Pictures or Files
                </Button>
              )}
              {journal.attachmentURL && (
                <Button
                  color="primary"
                  className={classes.editImageButton}
                  onClick={async () => {
                    await asyncHandleChange('nested_journal_attachmentURL', '', this);
                    this.uploadcareWidget.current.reloadInfo();
                  }}
                >
                  Remove All Attachments
                </Button>
              )}
            </FormControl>
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary" disableElevation>
              Save Refund
            </Button>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </>
    );
  }
}

AddRefundDialogContent.contextType = PersonContext;

AddRefundDialogContent.defaultProps = {
  yodleeTransaction: null,
  unitId: null,
  propertyId: null,
  propertyManagerId: null,
  bankAccountId: null,
};

AddRefundDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  unitId: PropTypes.node,
  propertyId: PropTypes.node,
  propertyManagerId: PropTypes.node,
  bankAccountId: PropTypes.node,
  yodleeTransaction: PropTypes.objectOf(PropTypes.any),
};

export default withStyles(styles)(AddRefundDialogContent);
