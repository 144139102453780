import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { PersonContext } from '../contexts/PersonContext';

const styles = {};

class ReferralProgramDialog extends React.PureComponent {
  render() {
    const { isOpen, closeDialog, wasReferred } = this.props;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        maxWidth="md"
        fullWidth
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="referralProgramDialog"
      >
        <DialogTitle id="alert-dialog-title">Referral Program</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            <strong>
              {`Bring a friend to REI Hub and we will give you both $50 
                in bill credits when they subscribe.`}
            </strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`If we have helped you keep better books, please consider sharing us with a friend. 
              We respect privacy and won't ask you for their email. You can copy and paste the text below into a message or use your own.`}
          </Typography>
          <Box my={1.5} mx={4} fontStyle="italic">
            <Typography variant="body1">
              {`I use REI Hub to keep the books for my rentals. 
                Their accounting software is designed specifically for investment property owners so it has been easy to set up and use with my portfolio. 
                If you've ever been frustrated by bookkeeping, you can visit https://www.reihub.net to learn more and sign up for a free trial.`}
            </Typography>
          </Box>
          <Typography variant="body1">
            We work hard each day to support you and your portfolio; thank you for helping us.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} variant="contained" color="primary" disableElevation>
            Close
          </Button>
          <Button onClick={wasReferred} color="primary">
            I Was Referred
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ReferralProgramDialog.contextType = PersonContext;

ReferralProgramDialog.defaultProps = {
};

ReferralProgramDialog.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  wasReferred: PropTypes.func.isRequired,
};

export default withStyles(styles)(ReferralProgramDialog);
