import PropTypes from 'prop-types';
import React from 'react';

export default function HomeWorkOutlined({ color, ...rest }) {
  return (
    <svg fill={color} viewBox="0 0 24 24" {...rest}>
      <path clipRule="evenodd" d="M10 3V4.51L12 5.84V5H21V19H17V21H23V3H10ZM17 15H19V17H17V15ZM17 11H19V13H17V11ZM17 7H19V9H17V7ZM13.74 7L15 7.84V7H13.74ZM15 10.2502L8.17 5.7002L1 10.4802V21.0002H15V10.2502ZM13 19.0002H10V13.0002H6V19.0002H3V11.3802L8.17 8.0902L13 11.1602V19.0002Z" />
    </svg>
  );
}

HomeWorkOutlined.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

HomeWorkOutlined.defaultProps = {
  color: 'currentColor',
  height: 24,
  width: 24,
};
