import React, { forwardRef } from 'react';
import MuiButton from '@material-ui/core/Button';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles, { COLOR, SIZE, VARIANT } from './styles';

const Button = forwardRef(
  (
    {
      children,
      className,
      color = COLOR.PRIMARY,
      hasFullWidth = false,
      size = SIZE.LARGE,
      variant = VARIANT.CONTAINED,
      ...rest
    },
    ref,
  ) => {
    const classes = useStyles({ color, variant });

    return (
      <MuiButton
        disableElevation={variant === VARIANT.CONTAINED}
        fullWidth={hasFullWidth}
        ref={ref}
        size={size}
        variant={variant}
        className={cx(classes.button, className)}
        {...rest}
      >
        {children}
      </MuiButton>
    );
  },
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(COLOR)),
  hasFullWidth: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZE)),
  variant: PropTypes.oneOf(Object.values(VARIANT)),
};

export default Button;
