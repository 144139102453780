import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  getBalanceSheetAccountOptions,
  nameLabel,
} from './Autocomplete/Library';
import { getManualBasicJournal } from './SearchSelect/TransactionTypeOptions';
import TransactionScope from './TransactionScope';
import {
  handleAutocompleteChange,
  handleTransactionScopeChange,
} from '../functions/InputHandlers';
import { setJournalScope } from '../functions/JournalFunctions';
import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

class BatchBookTransfersDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setInitialState();
  }

    setInitialState = async () => {
      const { organizationName, organizationId } = this.context;

      const initialState = {
        loading: false,
        transferAccount: null,
        transferScope: null,
      };

      const journal = getManualBasicJournal();
      journal.organizationId = organizationId;
      journal.type = 'Transfer';
      journal.journalScopeSelect = { name: `${organizationName} Portfolio`, id: 'Portfolio' };

      initialState.journal = journal;

      initialState.balanceSheetAccountOptions = await getBalanceSheetAccountOptions(this);

      this.setState(initialState);
    };

    bookTransfers = async () => {
      const { journal, submitting, transferAccount } = this.state;
      const { fullTransactions, onBatchBookTransfers } = this.props;
      const { organizationId } = this.context;

      if (submitting) {
        return;
      }

      this.setState({ submitting: true });

      setJournalScope(journal);

      const batchJournalsArray = [];

      fullTransactions.forEach((transaction) => {
        const journalTemplate = getManualBasicJournal();
        const transactionType = transaction.yodleeType;

        journalTemplate.organizationId = organizationId;
        journalTemplate.description = transaction.description;
        journalTemplate.date = transaction.date;
        journalTemplate.amount = transaction.amount;

        journalTemplate.journalScopeSelect = journal.journalScopeSelect;
        journalTemplate.propertyIdSelect = journal.propertyIdSelect;
        journalTemplate.propertyId = journal.propertyId;
        journalTemplate.unitIdSelect = journal.unitIdSelect;
        journalTemplate.unitId = journal.unitId;
        journalTemplate.entityIdSelect = journal.entityIdSelect;
        journalTemplate.entityId = journal.entityId;

        journalTemplate.type = journal.type;

        journalTemplate.debitLines[0].debit = transaction.amount;
        journalTemplate.creditLines[0].credit = transaction.amount;

        if (transactionType === 'CREDIT') {
          journalTemplate.debitLines[0].accountId = transaction.accountId;
          journalTemplate.debitLines[0].accountIdSelect = transaction.account;
          journalTemplate.debitLines[0].yodleeTransactionId = transaction.id;
          journalTemplate.creditLines[0].accountId = transferAccount.id;
          journalTemplate.creditLines[0].accountIdSelect = transferAccount;
        } else if (transactionType === 'DEBIT') {
          journalTemplate.creditLines[0].accountId = transaction.accountId;
          journalTemplate.creditLines[0].accountIdSelect = transaction.account;
          journalTemplate.creditLines[0].yodleeTransactionId = transaction.id;
          journalTemplate.debitLines[0].accountId = transferAccount.id;
          journalTemplate.debitLines[0].accountIdSelect = transferAccount;
        }

        journalTemplate.journalLines = journalTemplate.debitLines.concat(
          journalTemplate.creditLines,
        );

        batchJournalsArray.push(journalTemplate);
      });

      const createPromises = batchJournalsArray.map(
        (batchJournalsArrayItem) => create(this, 'journals', batchJournalsArrayItem, true),
      );

      Promise.all(createPromises)
        .then(() => {
          onBatchBookTransfers();
          this.setState({ submitting: false });
          this.closeDialog();
        })
        .catch((error) => {
          this.setState({ submitting: false, error });
        });
    };

    closeDialog = () => {
      const { closeDialog } = this.props;
      closeDialog();
    };

    render() {
      const { fullTransactions } = this.props;
      const {
        loading,
        balanceSheetAccountOptions,
        journal,
        transferAccount,
        error,
      } = this.state;

      if (loading) {
        return null;
      }

      return (
        <Dialog
          open
          scroll="body"
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          aria-describedby="form-dialog-description"
          id="batch-book-transfers-dialog"
        >
          <DialogTitle id="form-dialog-title">Batch Book Transfers</DialogTitle>
          <DialogContent>
            <TransactionScope
              journal={journal}
              transactionScopeChange={(newScopeValues) => {
                handleTransactionScopeChange(journal, newScopeValues, this);
              }}
            />
            <Autocomplete
              options={balanceSheetAccountOptions}
              getOptionLabel={nameLabel}
              getOptionSelected={(option, value) => option.id === value.id}
              value={transferAccount}
              onChange={handleAutocompleteChange(
                'transferAccount',
                this,
              )}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Transfer Account"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeDialog}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={this.bookTransfers}
            >
              { fullTransactions.length === 1 ? 'Book Transfer' : 'Book Transfers' }
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
}

BatchBookTransfersDialog.contextType = PersonContext;

BatchBookTransfersDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  fullTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBatchBookTransfers: PropTypes.func.isRequired,
};

export default BatchBookTransfersDialog;
