import React from 'react';
import PropTypes from 'prop-types';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  withStyles,
  Typography,
  TextField,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import DialogTemplate from './DialogTemplate';

import { patchMultiple, removeMultiple } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import TransactionScope from './TransactionScope';
import { handleAutocompleteChange, handleTransactionScopeChange } from '../functions/InputHandlers';
import { getAccountOptions, nameLabel } from './Autocomplete/Library';

import {
  setJournalScope,
} from '../functions/JournalFunctions';

const styles = () => ({
  deleteConfirmationButton: {
    color: 'red',
  },
});

class BatchChangeImportedTransactionDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      submitting: false,
      category: {},
      journal: {
        journalScopeSelect: null,
        entityIdSelect: null,
        propertyId: null,
        propertyIdSelect: null,
        unitId: null,
        unitIdSelect: null,
      },
    };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { screen } = this.props;

    const initialState = {};

    if (screen === 'Expense') {
      initialState.accountOptions = await getAccountOptions(
        this,
        {
          $or: [{ type: 'Expense' },
            { type2: 'Sales and Use Tax' }],
        },
      );
    } else if (screen === 'Revenue') {
      initialState.accountOptions = await getAccountOptions(
        this,
        {
          $or: [{ type: 'Revenue' },
            { type2: 'Deferred Revenue' }],
        },
      );
    }

    this.setState(initialState);
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    closeDialog();
  };

  onBatchChangeTransactions = () => {
    const { onBatchChangeTransactions } = this.props;
    onBatchChangeTransactions();
  };

  batchChangeTransactions = async (event) => {
    event.preventDefault();

    const { organizationId } = this.context;
    const {
      onBatchChangeTransactions,
      transactions,
      batchType,
      fullTransactions,
    } = this.props;
    const { submitting, journal } = this.state;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    if (['Ignore', 'Unignore', 'Delete'].includes(batchType.id)) {
      let data = {};
      if (batchType.id === 'Ignore') {
        data = { ignored: true };
      }
      if (batchType.id === 'Unignore') {
        data = { ignored: false };
      }
      if (batchType.id === 'Delete') {
        data = { deleted: true };
      }

      await patchMultiple(
        this,
        'yodlee-transactions',
        {
          query: {
            organizationId,
            id: transactions,
          },
        },
        data,
      )
        .then((result) => {
          this.closeDialog();
          onBatchChangeTransactions(result);
        })
        .catch((error) => {
          this.setState({ error });
          this.setState({ submitting: false });
        });
    } else if (batchType.id === 'Undo') {
      const transactionJournalIds = fullTransactions.map(
        (transaction) => transaction.journalId,
      );

      await removeMultiple(
        this,
        'journals',
        {
          query: {
            organizationId,
            id: transactionJournalIds,
          },
        },
      )
        .then((result) => {
          this.closeDialog();
          onBatchChangeTransactions(result);
        })
        .catch((error) => {
          this.setState({ error });
          this.setState({ submitting: false });
        });
    } else if (batchType.id === 'Journal Delete') {
      await removeMultiple(
        this,
        'journals',
        {
          query: {
            organizationId,
            id: transactions,
          },
        },
      )
        .then((result) => {
          this.closeDialog();
          onBatchChangeTransactions(result);
        })
        .catch((error) => {
          this.setState({ error });
          this.setState({ submitting: false });
        });
    } else if (batchType.id === 'Edit Scope') {
      setJournalScope(journal);
      const data = {
        entityId: journal.entityId,
        propertyId: journal.propertyId,
        unitId: journal.unitId,
      };

      await patchMultiple(
        this,
        'journals',
        {
          query: {
            organizationId,
            id: transactions,
          },
        },
        data,
      )
        .then((result) => {
          this.closeDialog();
          onBatchChangeTransactions(result);
        })
        .catch((error) => {
          this.setState({ error });
          this.setState({ submitting: false });
        });
    } else if (batchType.id === 'Edit Account') {
      const { category } = this.state;

      const batchChangeIds = fullTransactions.map((transaction) => transaction.accountLine.id);

      await patchMultiple(
        this,
        'journal-lines',
        {
          query: {
            organizationId,
            id: batchChangeIds,
          },
        },
        { accountId: category.accountIdSelect.id },
      )
        .then((result) => {
          this.closeDialog();
          onBatchChangeTransactions(result);
        })
        .catch((error) => {
          this.setState({ error });
          this.setState({ submitting: false });
        });
    }
  };

  getDialogContent = () => {
    const {
      classes, transactions, batchType, screen,
    } = this.props;
    const {
      error, journal, accountOptions, category,
    } = this.state;

    switch (batchType.name) {
      case 'Ignore':
        return (
          <>
            <DialogTitle id="form-dialog-title">
              Ignore Transactions
            </DialogTitle>
            <DialogContent>
              {transactions.length === 1 ? (
                <DialogContentText>
                  {`Are you sure you want to ignore this ${transactions.length} transaction?`}
                </DialogContentText>
              ) : (
                <DialogContentText>
                  {`Are you sure you want to ignore these ${transactions.length} transactions?`}
                </DialogContentText>
              )}
              <Typography color="error">{error}</Typography>
              <DialogActions>
                <Button onClick={this.closeDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={this.batchChangeTransactions}
                >
                  Confirm
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        );
      case 'Unignore':
        return (
          <>
            <DialogTitle id="form-dialog-title">
              Unignore Transactions
            </DialogTitle>
            <DialogContent>
              {transactions.length === 1 ? (
                <DialogContentText>
                  {`Are you sure you want to unignore this ${transactions.length} transaction?`}
                </DialogContentText>
              ) : (
                <DialogContentText>
                  {`Are you sure you want to unignore these ${transactions.length} transactions?`}
                </DialogContentText>
              )}
              <DialogActions>
                <Button onClick={this.closeDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={this.batchChangeTransactions}
                >
                  Confirm
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        );
      case 'Delete':
        return (
          <>
            <DialogTitle id="form-dialog-title">
              Delete Transactions
            </DialogTitle>
            <DialogContent>
              {transactions.length === 1 ? (
                <DialogContentText>
                  {`Are you sure you want to delete this ${transactions.length} transaction? This action cannot be undone.`}
                </DialogContentText>
              ) : (
                <DialogContentText>
                  {`Are you sure you want to delete these ${transactions.length} transactions? This action cannot be undone.`}
                </DialogContentText>
              )}
              <DialogActions>
                <Button onClick={this.closeDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={this.batchChangeTransactions}
                  className={classes.deleteConfirmationButton}
                >
                  Delete Transactions
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        );
      case 'Undo':
        return (
          <>
            <DialogTitle id="form-dialog-title">
              Really Undo Bookings?
            </DialogTitle>
            <DialogContent>
              {transactions.length === 1 ? (
                <DialogContentText>
                  Undoing this transaction will delete the booked transaction and restore the
                  imported transaction to unbooked status in your import feed.
                  Are you sure you want to undo?
                </DialogContentText>
              ) : (
                <DialogContentText>
                  {`Undoing these ${transactions.length} transactions will delete the booked transactions and restore imported transactions to unbooked status in your import feed. Are you sure you want to undo?`}
                </DialogContentText>
              )}
              <DialogActions>
                <Button onClick={this.closeDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={this.batchChangeTransactions}
                  className={classes.deleteConfirmationButton}
                >
                  Undo Transactions
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        );
      case 'Edit Account':
        return (
          <>
            <DialogTitle id="form-dialog-title">
              Edit Account
            </DialogTitle>
            <DialogContent>
              <Autocomplete
                options={accountOptions}
                getOptionLabel={nameLabel}
                value={category.accountIdSelect}
                onChange={handleAutocompleteChange(
                  'nested_category_accountIdSelect',
                  this,
                )}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label={`${screen} Account`}
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <DialogActions>
                <Button onClick={this.closeDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={this.batchChangeTransactions}
                >
                  Confirm
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        );
      case 'Edit Scope':
        return (
          <>
            <DialogTitle id="form-dialog-title">
              Edit Scope
            </DialogTitle>
            <DialogContent>
              <TransactionScope
                journal={journal}
                transactionScopeChange={(newScopeValues) => {
                  handleTransactionScopeChange(journal, newScopeValues, this);
                }}
              />
              <DialogActions>
                <Button onClick={this.closeDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={this.batchChangeTransactions}
                >
                  Confirm Scope Change
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <DialogTemplate
        open
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        {this.getDialogContent()}
      </DialogTemplate>
    );
  }
}

BatchChangeImportedTransactionDialog.contextType = PersonContext;

BatchChangeImportedTransactionDialog.defaultProps = {
  screen: '',
};

BatchChangeImportedTransactionDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  transactions: PropTypes.array.isRequired,
  fullTransactions: PropTypes.array.isRequired,
  onBatchChangeTransactions: PropTypes.func.isRequired,
  batchType: PropTypes.objectOf(PropTypes.string).isRequired,
  screen: PropTypes.string,
};

export default withStyles(styles)(BatchChangeImportedTransactionDialog);
