import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import CardActionButtons from './CardActionButtons';

const styles = (theme) => ({
  flexGrow: {
    flexGrow: '1',
  },
  reverseMargins: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
});

class CardTitle extends React.PureComponent {
  render() {
    const {
      classes, title, actionButtons,
    } = this.props;

    return (
      <Box display="flex" alignItems="center" flexWrap="wrap" marginTop={-1} marginLeft={-1}>
        <div className={classes.reverseMargins}>
          {title ? <Typography variant="h6" gutterBottom>{title}</Typography> : ''}
        </div>
        <div className={classes.flexGrow} />
        {actionButtons ? <CardActionButtons actionButtons={actionButtons} /> : ''}
      </Box>
    );
  }
}

CardTitle.defaultProps = {
  actionButtons: null,
};

CardTitle.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  actionButtons: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(styles)(CardTitle);
