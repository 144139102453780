import Alert from '@material-ui/lab/Alert';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PersonContext } from '~/contexts/PersonContext';
import { get } from '~/feathersFunctionalWrapper';

function ImpersonateFromIntercom() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { setAdminUserSelect } = useContext(PersonContext);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    const loadUser = async () => {
      const userId = query.get('userId');

      if (!userId) {
        setHasError(true);
        return;
      }

      try {
        const userResponse = await get('users', userId);
        setUser(userResponse);
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };
    loadUser();
  }, []);

  useEffect(() => {
    if (user) {
      setAdminUserSelect({ id: user.id });
    }
  }, [user]);

  if (isLoading) {
    return null;
  }

  if (hasError) {
    return <Alert severity="error">Error loading user</Alert>;
  }

  return (
    <Alert severity="info">
      Impersonating
      {' '}
      {user.firstName}
      {' '}
      {user.lastName}
    </Alert>
  );
}

export default ImpersonateFromIntercom;
