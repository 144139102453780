import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';

import {
  get, patch, create, remove,
} from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
  handleAutocompleteChange,
  handleNumberFormatChange,
  handleCheckboxChange,
  handleTransactionScopeChange,
} from '../functions/InputHandlers';

import {
  getAccountOptions,
  getBalanceSheetAccountOptions,
  getVendorOptions,
  getFixedAssetOptions,
  nameLabel,
} from './Autocomplete/Library';

import {
  setJournalScope,
  setInitialJournalScope,
} from '../functions/JournalFunctions';

import TransactionScope from './TransactionScope';

const styles = (theme) => ({
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: 'red',
  },
  deleteConfirmationButton: {
    color: 'red',
  },
});

class EditImportedTransactionRuleDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  getData = async () => {
    const { ruleId } = this.props;
    let rule = await get(this, 'imported-transaction-rules', ruleId);

    rule = {
      vendor: null, fixedAsset: null, analysisAccount: null, ...rule,
    };
    // eslint-disable-next-line no-unneeded-ternary
    rule.applyToAllAccounts = rule.analysisAccount ? false : true;

    const journal = {
      unitId: rule.unitId,
      propertyId: rule.propertyId,
      entityId: rule.entityId,
    };

    await setInitialJournalScope(journal, this);

    const initialState = {
      rule,
      journal,
      error: null,
      loading: false,
      submitting: false,
      confirmDelete: false,
      autobookResult: false,
    };

    initialState.expenseAccountOptions = await getAccountOptions(this, { type: 'Expense' });
    initialState.revenueAccountOptions = await getAccountOptions(this, { type: 'Revenue' });
    initialState.fixedAssetAccountOptions = await getAccountOptions(this, { type2: 'Fixed Asset' });
    initialState.balanceSheetAccountOptions = await getBalanceSheetAccountOptions(this);
    initialState.vendorOptions = await getVendorOptions(this);
    initialState.fixedAssetOptions = await getFixedAssetOptions(this);
    initialState.ruleAccountOptions = await getAccountOptions(this, { type2: ['Bank', 'Credit Card'] });

    this.setState(initialState);
  };

  edit = async (event) => {
    event.preventDefault();
    const {
      submitting, rule, journal,
    } = this.state;

    const { onEdit, ruleId } = this.props;

    if (submitting) {
      return;
    }
    if (!rule.descriptionContains && rule.transactionAmount === null) {
      this.setState({ error: { message: 'Either the description or amount must be set for each rule' } });
      return;
    }
    this.setState({ submitting: true });

    const ruleSubmit = { ...rule };
    if (ruleSubmit.type !== 'Split' && ruleSubmit.type !== 'Ignore') {
      setJournalScope(journal);
      Object.assign(ruleSubmit, journal);
      ruleSubmit.destinationAccountId = ruleSubmit.destinationAccount.id;
    }

    ruleSubmit.vendorId = ruleSubmit.vendor ? ruleSubmit.vendor.id : null;
    ruleSubmit.fixedAssetId = ruleSubmit.fixedAsset ? ruleSubmit.fixedAsset.id : null;
    ruleSubmit.analysisAccountId = ruleSubmit.applyToAllAccounts
      ? null
      : ruleSubmit.analysisAccount.id;

    patch(this, 'imported-transaction-rules', ruleId, ruleSubmit, true)
      .then(async (result) => {
        onEdit(result);
        if (ruleSubmit.autobookMatchingTransactions) {
          let automationResult;
          if (ruleSubmit.type === 'Split') {
            automationResult = await create(this, 'automations', {
              organizationId: ruleSubmit.organizationId,
              automationName: 'splitRuleMatching',
              ruleId: result.id,
            });
          } else if (ruleSubmit.type === 'Ignore') {
            automationResult = await create(this, 'automations', {
              organizationId: ruleSubmit.organizationId,
              automationName: 'ignoreRuleMatching',
              ruleId: result.id,
            });
          } else {
            automationResult = await create(this, 'automations', {
              organizationId: ruleSubmit.organizationId,
              automationName: 'bookRuleMatching',
              ruleId: result.id,
            });
          }
          if (automationResult.newTransactions) {
            this.setState({
              autobookResult: true,
              autobookedTransactionCount: automationResult.newTransactions,
            });
          } else {
            this.closeDialog();
          }
        } else {
          this.closeDialog();
        }
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  delete = () => {
    const { submitting } = this.state;
    const { onDelete, ruleId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    remove(this, 'imported-transaction-rules', ruleId, true)
      .then(() => {
        onDelete();
        this.closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  }

  render() {
    const { classes, isOpen } = this.props;
    const { vendorTracking } = this.context;
    const {
      loading, error, rule, journal, confirmDelete,
      revenueAccountOptions, expenseAccountOptions, fixedAssetAccountOptions,
      balanceSheetAccountOptions, ruleAccountOptions,
      vendorOptions, fixedAssetOptions,
      autobookResult, autobookedTransactionCount,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.getData}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && !confirmDelete && !autobookResult && (
          <form onSubmit={this.edit}>
            <DialogTitle id="alert-dialog-title">
              Edit Rule
              <IconButton
                className={classes.deleteIconButton}
                aria-label="delete"
                onClick={() => this.setState({ confirmDelete: true })}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {rule.type === 'Expense' && (
                <Autocomplete
                  options={expenseAccountOptions}
                  getOptionLabel={nameLabel}
                  value={rule.destinationAccount}
                  onChange={handleAutocompleteChange(
                    'nested_rule_destinationAccount',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Expense Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {rule.type === 'Revenue' && (
                <Autocomplete
                  options={revenueAccountOptions}
                  getOptionLabel={nameLabel}
                  value={rule.destinationAccount}
                  onChange={handleAutocompleteChange(
                    'nested_rule_destinationAccount',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Revenue Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {rule.type === 'Fixed Asset Purchase' && (
                <>
                  <Autocomplete
                    options={fixedAssetOptions}
                    getOptionLabel={nameLabel}
                    value={rule.fixedAsset}
                    onChange={handleAutocompleteChange(
                      'nested_rule_fixedAsset',
                      this,
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                        margin="dense"
                        label="Fixed Asset"
                        placeholder="Type to Search"
                        fullWidth
                        required
                      />
                    )}
                  />
                  <Autocomplete
                    options={fixedAssetAccountOptions}
                    getOptionLabel={nameLabel}
                    value={rule.destinationAccount}
                    onChange={handleAutocompleteChange(
                      'nested_rule_destinationAccount',
                      this,
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                        margin="dense"
                        label="Fixed Asset Account"
                        placeholder="Type to Search"
                        fullWidth
                        required
                      />
                    )}
                  />
                </>
              )}
              {rule.type === 'Transfer To' && (
                <Autocomplete
                  options={balanceSheetAccountOptions}
                  getOptionLabel={nameLabel}
                  value={rule.destinationAccount}
                  onChange={handleAutocompleteChange(
                    'nested_rule_destinationAccount',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Transferred To Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {rule.type === 'Transfer From' && (
                <Autocomplete
                  options={balanceSheetAccountOptions}
                  getOptionLabel={nameLabel}
                  value={rule.destinationAccount}
                  onChange={handleAutocompleteChange(
                    'nested_rule_destinationAccount',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Transferred From Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              <TextField
                label="Description Contains (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: rule.descriptionContains,
                  name: 'nested_rule_descriptionContains',
                  onChange: handleTextFieldChange(this),
                }}
              />
              {rule.type !== 'Split' && (
                <>
                  <FormControl margin="dense" fullWidth>
                    <InputLabel>
                      Transaction Amount (optional)
                    </InputLabel>
                    <NumberFormat
                      value={rule.transactionAmount}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      onValueChange={handleNumberFormatChange('nested_rule_transactionAmount', this)}
                      customInput={Input}
                    />
                  </FormControl>
                  {rule.type !== 'Ignore' && (
                    <TransactionScope
                      journal={journal}
                      transactionScopeChange={(newScopeValues) => {
                        handleTransactionScopeChange(journal, newScopeValues, this);
                      }}
                    />
                  )}
                </>
              )}
              {vendorTracking
                && ['Expense', 'Fixed Asset Purchase'].includes(rule.type) && (
                <Autocomplete
                  options={vendorOptions}
                  getOptionLabel={nameLabel}
                  value={rule.vendor}
                  onChange={handleAutocompleteChange(
                    'nested_rule_vendor',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Vendor (optional)"
                      placeholder="Type to Search"
                      fullWidth
                    />
                  )}
                />
              )}
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={rule.autobookMatchingTransactions} onChange={handleCheckboxChange(this)} name="nested_rule_autobookMatchingTransactions" />}
                  label="Automatically book matching transactions"
                />
              </FormControl>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={rule.applyToAllAccounts} onChange={handleCheckboxChange(this)} name="nested_rule_applyToAllAccounts" />}
                  label="Apply this rule to all accounts"
                />
              </FormControl>
              {!rule.applyToAllAccounts && (
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  // bgcolor="common.white"
                  padding={2}
                  marginY={2}
                >
                  <Autocomplete
                    options={ruleAccountOptions}
                    getOptionLabel={nameLabel}
                    value={rule.analysisAccount}
                    onChange={handleAutocompleteChange(
                      'nested_rule_analysisAccount',
                      this,
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                        margin="dense"
                        label="Rule Applies to Account"
                        placeholder="Type to Search"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Box>
              )}
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Rule
              </Button>
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <>
            <DialogTitle id="alert-dialog-title">Really Delete Rule?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`This rule will be removed. 
                  Existing transactions booked using this rule will not be affected.`}
              </DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={this.delete} className={classes.deleteConfirmationButton}>
                Delete Rule
              </Button>
            </DialogActions>
          </>
        )}
        {!loading && autobookResult && (
          <>
            <DialogTitle id="alert-dialog-title">Rule Updated</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Your rule was created. ${autobookedTransactionCount} transactions matched the template and were booked automatically.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary" variant="contained" disableElevation>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

EditImportedTransactionRuleDialog.contextType = PersonContext;

EditImportedTransactionRuleDialog.defaultProps = {
  ruleId: null,
};

EditImportedTransactionRuleDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  ruleId: PropTypes.node,
};

export default withStyles(styles)(EditImportedTransactionRuleDialog);
