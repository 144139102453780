import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';

// import { Link as RouterLink } from 'react-router-dom';

import { PersonContext } from '../../contexts/PersonContext';

import { find } from '../../feathersWrapper';

import FieldGrid from '../../components/FieldGrid';
import LinkBase from '../../components/LinkBase';
import ErrorCard from '../../components/ErrorCard';
import CardBase from '../../components/CardBase';
import EditContactDialog from '../../components/EditContactDialog';

const styles = (theme) => ({
  textButton: {
    display: 'block',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
});

class LeasesCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      editContactDialog: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { view, id } = this.props;
    const { organizationId } = this.context;
    const query = {
      organizationId,
      startDate: { $lte: moment().format('YYYY-MM-DD') },
      endDate: {
        $or: [
          { $gte: moment().format('YYYY-MM-DD') },
          null,
        ],
      },
      $limit: 1,
    };
    const countQuery = {
      organizationId,
      $limit: 0,
    };
    if (view === 'property') {
      query.propertyId = id;
      countQuery.propertyId = id;
      query.unitId = null;
      countQuery.unitId = null;
    } else if (view === 'unit') {
      query.unitId = id;
      countQuery.unitId = id;
    }
    const resultPromise = find(this, 'leases', { query });
    const countPromise = find(this, 'leases', { query: countQuery });

    const result = await resultPromise;
    const countResult = await countPromise;

    this.setState({
      leases: result.data,
      currentTotal: result.total,
      total: countResult.total,
      loading: false,
    });
  };

  onEditContactDialog = async () => {
    await this.getData();
    this.setState({ editContactDialog: false });
  };

  render() {
    const {
      loading, error, leases, currentTotal, total, personId, editContactDialog,
    } = this.state;
    const { classes, view, id } = this.props;

    if (loading) {
      return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Current Lease
              </Typography>
            </Box>
          </CardBase>
        </Grid>
      );
    }
    if (error) {
      return <ErrorCard error={error.message} />;
    }

    return (
      <>
        <EditContactDialog
          isOpen={editContactDialog}
          closeDialog={() => this.setState({ editContactDialog: false })}
          onEditContact={this.onEditContactDialog}
          personId={personId}
        />
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Current Lease
              </Typography>
              {currentTotal === 1 && (
                <>
                  <Typography variant="subtitle2">Details</Typography>
                  <FieldGrid label="Start Date:" value={moment(leases[0].startDate).format('M/D/YYYY')} />
                  <FieldGrid label="End Date:" value={leases[0].endDate ? moment(leases[0].endDate).format('M/D/YYYY') : 'None'} />
                  <Typography variant="subtitle2">Tenants</Typography>
                  {leases[0].people.map((person, index) => (
                    <FieldGrid
                      key={person.id}
                      label={`Tenant ${index + 1}`}
                      value={(
                        <ButtonBase
                          key={person.id}
                          className={classes.textButton}
                          onClick={() => {
                            this.setState({
                              personId: person.id,
                              editContactDialog: true,
                            });
                          }}
                        >
                          <Typography variant="body2">
                            {`${person.firstName} ${person.lastName}`}
                          </Typography>
                        </ButtonBase>
                      )}
                    />
                  ))}
                  <Typography variant="subtitle2">Rent Items</Typography>
                  {leases[0].recurringRents.map((rent) => (
                    <FieldGrid
                      key={rent.id}
                      label={rent.description}
                      value={(
                        <NumberFormat
                          displayType="text"
                          value={rent.amount}
                          thousandSeparator
                          prefix="$"
                          decimalScale={0}
                          fixedDecimalScale
                        />
                      )}
                    />
                  ))}
                  <Typography variant="subtitle2">Security Deposit</Typography>
                  <FieldGrid
                    label="Amount"
                    value={(
                      <NumberFormat
                        displayType="text"
                        value={leases[0].securityDepositAmount}
                        thousandSeparator
                        prefix="$"
                        decimalScale={0}
                        fixedDecimalScale
                      />
                    )}
                  />
                </>
              )}
              {currentTotal === 0 && (
                <Typography variant="subtitle2" gutterBottom>No current lease found</Typography>
              )}
              <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
                <LinkBase to={`/organization/leases?${view}Id=${id}`}>
                  <Typography variant="body2">{`View All Leases (${total})`}</Typography>
                </LinkBase>
              </Box>
            </Box>
          </CardBase>
        </Grid>
      </>
    );
  }
}

LeasesCard.contextType = PersonContext;

LeasesCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  view: PropTypes.string.isRequired,
  id: PropTypes.node.isRequired,
  // theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(LeasesCard);
