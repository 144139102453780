import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { PersonContext } from '../../contexts/PersonContext';

import {
  nameLabel,
  addressLabel,
  getAccountOptions,
  getTransactionTypeOptions,
  getImportAccountOptions,
  getPropertyOptionsOrAll,
  getImportedTransactionStatusOptions,
  getAccountTypeOptions,
} from '../../components/Autocomplete/Library';

import { asyncHandleChange } from '../../functions/InputHandlers';

import CardBase from '../../components/CardBase';

class FilterCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { filter, fields } = this.props;
    const initialState = { loading: false };
    if (filter) {
      initialState.filter = filter;
    }

    if (fields.includes('importAccount')) {
      initialState.importAccountOptions = await getImportAccountOptions(this);
    }
    if (fields.includes('property')) {
      initialState.propertyOptions = await getPropertyOptionsOrAll(this);
    }
    if (fields.includes('revenueAccount')) {
      initialState.revenueAccountOptions = await getAccountOptions(this, { type: 'Revenue' });
      initialState.revenueAccountOptions.unshift({ name: 'All Revenues', id: 'All' });
    }
    if (fields.includes('expenseAccount')) {
      initialState.expenseAccountOptions = await getAccountOptions(this, { type: 'Expense' });
      initialState.expenseAccountOptions.unshift({ name: 'All Expenses', id: 'All' });
    }
    if (fields.includes('partnerAccount')) {
      initialState.partnerAccountOptions = await getAccountOptions(this, { type2: 'Partner' });
      initialState.partnerAccountOptions.unshift({ name: 'All Accounts', id: 'All' });
    }
    if (fields.includes('accountType')) {
      // we need a new array to avoid modifying the const object in memory
      initialState.accountTypeOptions = getAccountTypeOptions.map((type) => type);
      initialState.accountTypeOptions.unshift({ name: 'All Account Types', id: 'All' });
    }

    this.setState(initialState);
  }

  async componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (filter !== prevProps.filter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filter });
    }
  }

  handleAutocompleteChange = (name) => async (event, value) => {
    const { updateFilter } = this.props;
    await asyncHandleChange(name, value, this);
    await asyncHandleChange('nested_filter_page', 0, this);
    const { filter } = this.state;
    updateFilter(filter);
  };

  handleDatepickerChange = (name) => async (value) => {
    const { updateFilter } = this.props;

    if (moment.isMoment(value) && moment(value).isValid()) {
      await asyncHandleChange(name, moment(value).format('YYYY-MM-DD'), this);
    } else {
      await asyncHandleChange(name, null, this);
    }

    if (moment.isMoment(value) && moment(value).creationData().input === '__/__/____') {
      await asyncHandleChange(`${name}Select`, null, this);
    } else {
      await asyncHandleChange(`${name}Select`, value, this);
    }

    await asyncHandleChange('nested_filter_page', 0, this);
    const { filter } = this.state;
    updateFilter(filter);
  };

  handlePropertyAutocompleteChange = () => async (event, value) => {
    const { updateFilter } = this.props;
    if (value.id !== 'All') {
      await asyncHandleChange('nested_filter_propertyId', value.id, this);
      await asyncHandleChange('nested_filter_propertyIdSelect', value, this);
    } else {
      await asyncHandleChange('nested_filter_propertyId', null, this);
      await asyncHandleChange('nested_filter_propertyIdSelect', { address1: 'All Properties', id: 'All' }, this);
    }
    await asyncHandleChange('nested_filter_unitId', null, this);
    await asyncHandleChange('nested_filter_unitIdSelect', null, this);

    await asyncHandleChange('nested_filter_page', 0, this);
    const { filter } = this.state;
    updateFilter(filter);
  };

  handleTextFieldChange = () => async (event) => {
    const { updateFilter } = this.props;
    await asyncHandleChange('nested_filter_accountName', event.target.value, this);
    const { filter } = this.state;
    updateFilter(filter);
  };

  renderField = (field) => {
    const {
      filter,
      importAccountOptions,
      revenueAccountOptions,
      expenseAccountOptions,
      partnerAccountOptions,
      propertyOptions,
      accountTypeOptions,
    } = this.state;
    switch (field) {
      case 'transactionType':
        return (
          <Grid item xs={5} key={field}>
            <Autocomplete
              options={getTransactionTypeOptions}
              getOptionLabel={nameLabel}
              value={filter.transactionTypeSelect}
              onChange={this.handleAutocompleteChange(
                'nested_filter_transactionTypeSelect',
              )}
              disableClearable
              blurOnSelect
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  label="Transaction Type"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
        );
      case 'property':
        return (
          <Grid item xs={5} key={field}>
            <Autocomplete
              options={propertyOptions}
              getOptionLabel={addressLabel}
              value={filter.propertyIdSelect}
              onChange={this.handlePropertyAutocompleteChange(
                'nested_filter_propertyIdSelect',
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              disableClearable
              blurOnSelect
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  label="Property"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
        );
      case 'revenueAccount':
        return (
          <Grid item xs={5} key={field}>
            <Autocomplete
              options={revenueAccountOptions}
              getOptionLabel={nameLabel}
              value={filter.revenueAccountIdSelect}
              onChange={this.handleAutocompleteChange(
                'nested_filter_revenueAccountIdSelect',
              )}
              disableClearable
              blurOnSelect
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  label="Revenue Account"
                  placeholder="Type to Search"
                  required
                />
              )}
            />
          </Grid>
        );
      case 'expenseAccount':
        return (
          <Grid item xs={5} key={field}>
            <Autocomplete
              options={expenseAccountOptions}
              getOptionLabel={nameLabel}
              value={filter.expenseAccountIdSelect}
              onChange={this.handleAutocompleteChange(
                'nested_filter_expenseAccountIdSelect',
              )}
              disableClearable
              blurOnSelect
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  label="Expense Account"
                  placeholder="Type to Search"
                  required
                />
              )}
            />
          </Grid>
        );
      case 'importAccount':
        return (
          <Grid item xs={5} key={field}>
            <Autocomplete
              options={importAccountOptions}
              getOptionLabel={nameLabel}
              value={filter.accountIdSelect}
              onChange={this.handleAutocompleteChange(
                'nested_filter_accountIdSelect',
              )}
              disableClearable
              blurOnSelect
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  label="Import Account"
                  placeholder="Type to Search"
                  required
                />
              )}
            />
          </Grid>
        );
      case 'importedTransactionStatus':
        return (
          <Grid item xs={5} key={field}>
            <Autocomplete
              options={getImportedTransactionStatusOptions}
              getOptionLabel={nameLabel}
              value={filter.transactionStatusSelect}
              onChange={this.handleAutocompleteChange(
                'nested_filter_transactionStatusSelect',
              )}
              disableClearable
              blurOnSelect
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  label="Transaction Status"
                  placeholder="Type to Search"
                  required
                />
              )}
            />
          </Grid>
        );
      case 'partnerAccount':
        return (
          <Grid item xs={5} key={field}>
            <Autocomplete
              options={partnerAccountOptions}
              getOptionLabel={nameLabel}
              value={filter.partnerAccountIdSelect}
              onChange={this.handleAutocompleteChange(
                'nested_filter_partnerAccountIdSelect',
              )}
              disableClearable
              blurOnSelect
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  label="Partner Payment Account"
                  placeholder="Type to Search"
                  required
                />
              )}
            />
          </Grid>
        );
      case 'startDate':
        return (
          <Grid item xs={5} key={field}>
            <KeyboardDatePicker
              label="Start Date"
              format="MM/DD/YYYY"
              value={filter.startDateSelect}
              onChange={this.handleDatepickerChange('nested_filter_startDate')}
              clearable
              fullWidth
            />
          </Grid>
        );
      case 'endDate':
        return (
          <Grid item xs={5} key={field}>
            <KeyboardDatePicker
              label="End Date"
              format="MM/DD/YYYY"
              value={filter.endDateSelect}
              onChange={this.handleDatepickerChange('nested_filter_endDate')}
              clearable
              fullWidth
            />
          </Grid>
        );
      case 'accountName':
        return (
          <Grid item xs={5} key={field}>
            <TextField
              label="Account Name"
              InputProps={{
                value: filter.accountName,
                name: 'nested_filter_accountName',
                onChange: this.handleTextFieldChange('nested_filter_accountName'),
              }}
              fullWidth
            />
          </Grid>
        );
      case 'accountType':
        return (
          <Grid item xs={5} key={field}>
            <Autocomplete
              options={accountTypeOptions}
              getOptionLabel={nameLabel}
              value={filter.accountType}
              onChange={this.handleAutocompleteChange(
                'nested_filter_accountType',
              )}
              disableClearable
              blurOnSelect
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  label="Account Type"
                  placeholder="Type to Search"
                />
              )}
            />
          </Grid>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      loading,
    } = this.state;
    const {
      openFilter, fields,
    } = this.props;

    if (loading) {
      return (
        <Grid item xs={12}>
          <CardBase>
            <CardContent />
          </CardBase>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <CardBase>
          <CardContent>
            <Grid
              container
              spacing={4}
            >
              {fields.map((field) => this.renderField(field))}
              <Grid item container xs={2} alignItems="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<FilterListIcon />}
                  onClick={openFilter}
                >
                  Filter
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </CardBase>
      </Grid>
    );
  }
}

FilterCard.contextType = PersonContext;

FilterCard.defaultProps = {
};

FilterCard.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.string).isRequired,
  openFilter: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  // theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default FilterCard;
