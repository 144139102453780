import { planOptions } from '~/constants/buy-it-now';
import DISCOUNTS from '~/constants/discounts';

// NOTE: Hardcoded to set Black Friday and Cyber Monday deal at
// '/*-limited' urls
export function getDiscount(isLimitedTimeOffer) {
  return DISCOUNTS.find((discountItem) => discountItem.id === (isLimitedTimeOffer ? 'buy_now_75off' : 'buy_now_50'));
}

export function getPlans(discount) {
  return planOptions.map((planOption) => ({
    ...planOption,
    discountPrice: planOption.price * (1.0 - (discount.discountPercent / 100).toFixed(2)),
  }));
}
