import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ElementsConsumer } from '@stripe/react-stripe-js';

import AuthenticateContainer from '../layouts/Authenticate/Container';
import GrayLayout from '../layouts/Authenticate/GrayLayout';
import AuthenticateLayout from '../layouts/Authenticate/Layout';
import AccessInvite from '../views/authenticate/AccessInvite';
import AccountantFirms from '../views/authenticate/AccountantFirms';
import AccountantLogin from '../views/authenticate/AccountantLogin';
import AccountantRegister from '../views/authenticate/AccountantRegister';
import AdminLogin from '../views/authenticate/AdminLogin';
import Checkout from '../views/authenticate/Checkout';
import Login from '../views/authenticate/Login';
import Organizations from '../views/authenticate/Organizations';
import Register from '../views/authenticate/Register/View';
import RegisterProfile from '../views/authenticate/RegisterProfile';
import ResetPassword from '../views/authenticate/ResetPassword';
import SelectPricing from '../views/authenticate/SelectPricing';
import FurnishedFinder from '../views/authenticate/FurnishedFinder';
import SetPassword from '../views/authenticate/SetPassword';
import Sso from '../views/authenticate/Sso';
import VerifyEmail from '../views/authenticate/VerifyEmail';
import Demo from '../views/Demo';

export default function AuthenticateRoutes() {
  return (
    <AuthenticateContainer>
      <Switch>
        <Route exact path="/register" component={Register} />
        <Route
          exact
          path="/select-pricing"
          component={(props) => (
            <GrayLayout>
              <SelectPricing {...props} />
            </GrayLayout>
          )}
        />
        <Route
          exact
          path="/checkout"
          component={(props) => (
            <GrayLayout>
              <ElementsConsumer>
                {({ elements, stripe }) => <Checkout elements={elements} stripe={stripe} {...props} />}
              </ElementsConsumer>
            </GrayLayout>
          )}
        />
        <Route
          exact
          path="/register-profile"
          component={(props) => (
            <GrayLayout>
              <RegisterProfile {...props} />
            </GrayLayout>
          )}
        />
        {/* routes for limited time offer */}
        <Route
          exact
          path="/select-pricing-limited"
          component={(props) => (
            <GrayLayout>
              <SelectPricing {...props} isLimitedTimeOffer />
            </GrayLayout>
          )}
        />
        <Route
          exact
          path="/register-profile-limited"
          component={(props) => (
            <GrayLayout>
              <RegisterProfile {...props} isLimitedTimeOffer />
            </GrayLayout>
          )}
        />
        <Route
          exact
          path="/checkout-limited"
          component={(props) => (
            <GrayLayout>
              <ElementsConsumer>
                {({ elements, stripe }) => (
                  <Checkout elements={elements} stripe={stripe} {...props} isLimitedTimeOffer />
                )}
              </ElementsConsumer>
            </GrayLayout>
          )}
        />
        {/* routes for limited time offer */}
        {/* routes for furnished finder */}
        <Route
          exact
          path="/furnished-finder"
          component={(props) => (
            <GrayLayout>
              <FurnishedFinder {...props} isFurnishedFinder />
            </GrayLayout>
          )}
        />
        {/* routes for furnished finder */}
        <Route
          exact
          path="/resetpassword"
          component={(props) => (
            <AuthenticateLayout>
              <ResetPassword {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/setpassword"
          component={(props) => (
            <AuthenticateLayout>
              <SetPassword {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/verifyemail"
          component={(props) => (
            <AuthenticateLayout>
              <VerifyEmail {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/sign-in"
          component={(props) => (
            <AuthenticateLayout>
              <Login {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/sso"
          component={(props) => (
            <AuthenticateLayout>
              <Sso {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route exact path="/portfolios" component={(props) => <Organizations {...props} />} />
        <Route
          exact
          path="/invite"
          component={(props) => (
            <AuthenticateLayout>
              <AccessInvite {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/demo"
          component={(props) => (
            <AuthenticateLayout>
              <Demo {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/turbotenant"
          component={(props) => (
            <AuthenticateLayout>
              <Demo {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/rentredi"
          component={(props) => (
            <AuthenticateLayout>
              <Demo {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/accountant/sign-in"
          component={(props) => (
            <AuthenticateLayout>
              <AccountantLogin {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/accountant/register"
          component={(props) => (
            <AuthenticateLayout>
              <AccountantRegister {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/accountant/firms"
          component={(props) => (
            <AuthenticateLayout>
              <AccountantFirms {...props} />
            </AuthenticateLayout>
          )}
        />
        <Route
          exact
          path="/admin/sign-in"
          component={(props) => (
            <AuthenticateLayout>
              <AdminLogin {...props} />
            </AuthenticateLayout>
          )}
        />

        <Redirect from="/accountant" to="/accountant/sign-in" />
        <Redirect from="/admin" to="/admin/sign-in" />
        <Redirect to="/sign-in" />
      </Switch>
    </AuthenticateContainer>
  );
}
