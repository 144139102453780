import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
// import Typography from '@material-ui/core/Typography';

import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
} from '../functions/InputHandlers';

import AddPropertyManagerDialog from '../components/AddPropertyManagerDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import LinkBase from '../components/LinkBase';

class PropertyManagers extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addPropertyManagerDialog: false,
      filter: {
        page: 0,
        pageSize: 50,
      },
    };
  }

  async componentDidMount() {
    await this.getManagers();
  }

  getManagers = async () => {
    const { organizationId } = this.context;
    const { filter } = this.state;

    const propertyManagementAccounts = await find(this, 'accounts', {
      query: {
        organizationId,
        type2: 'Property Management',
        $limit: filter.pageSize,
        $skip: filter.page * filter.pageSize,
        $sort: {
          name: 1,
          id: 1,
        },
      },
    });

    this.setState({
      propertyManagers: propertyManagementAccounts.data,
      total: propertyManagementAccounts.total,
      loading: false,
    });
  };

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getManagers();
  };

  closeDialogs = () => {
    this.setState({ addPropertyManagerDialog: false });
    this.getManagers();
  }

  actionButtons = () => (
    [
      { text: 'Add Account', action: (() => this.setState({ addPropertyManagerDialog: true })), class: 'add' },
    ]
  );

  render() {
    const { match } = this.props;
    const {
      propertyManagers, addPropertyManagerDialog,
      filter, total, loading,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader match={match} title="Property Managers" actionButtons={this.actionButtons()} />
        <AddPropertyManagerDialog
          isOpen={addPropertyManagerDialog}
          closeDialog={this.closeDialogs}
          onAddPropertyManager={this.closeDialogs}
        />
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {propertyManagers.map((account) => (
                    <TableRow key={account.id}>
                      <TableCell>
                        <LinkBase to={`/accounts/property-managers/${account.id}`}>
                          {account.name}
                        </LinkBase>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={total}
                      page={filter.page}
                      rowsPerPage={filter.pageSize}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[filter.pageSize]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

PropertyManagers.contextType = PersonContext;

PropertyManagers.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PropertyManagers;
