import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { PersonContext } from '../contexts/PersonContext';

class SelectTransactionTypeDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = async () => {
    const initialState = {
      loading: false,
    };

    this.setState(initialState);
  };

  getDialogContent = () => {
    const {
      fixedAssetId,
    } = this.props;

    if (fixedAssetId) {
      return (
        <>
          <DialogContent>
            <ButtonGroup
              color="secondary"
              orientation="vertical"
              variant="outlined"
              size="large"
              fullWidth
            >
              <Button
                onClick={() => this.selectTransactionType('Opening Balance')}
              >
                Opening Balance
              </Button>
              <Button
                onClick={() => this.selectTransactionType('Fixed Asset Purchase')}
              >
                Fixed Asset Purchase
              </Button>
              <Button
                onClick={() => this.selectTransactionType('Depreciation')}
              >
                Depreciation
              </Button>
              <Button
                onClick={() => this.selectTransactionType('Manual Journal')}
              >
                Manual Journal
              </Button>
            </ButtonGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </>
      );
    }
    return null;
  };

  selectTransactionType = (type) => {
    const { selectTransactionType } = this.props;
    selectTransactionType(type);
    this.closeDialog();
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  render() {
    const { isOpen } = this.props;
    const {
      loading,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={this.closeDialog}
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="addTransactionDialog"
      >
        {!loading && this.getDialogContent()}
      </Dialog>
    );
  }
}

SelectTransactionTypeDialog.contextType = PersonContext;

SelectTransactionTypeDialog.defaultProps = {
  fixedAssetId: null,
};

SelectTransactionTypeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  selectTransactionType: PropTypes.func.isRequired,
  fixedAssetId: PropTypes.node,
};

export default SelectTransactionTypeDialog;
