import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import OnboardingProvider from '~/contexts/OnboardingProvider';
import OnboardingRoutes from '~/routes/OnboardingRoutes';

import OnboardingLayout from './Layout';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '32px',
  },
}));

function Container() {
  const classes = useStyles();

  return (
    <OnboardingLayout className={classes.content}>
      <OnboardingProvider>
        <OnboardingRoutes />
      </OnboardingProvider>
    </OnboardingLayout>
  );
}

export default Container;
