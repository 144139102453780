import React from 'react';
import PropTypes from 'prop-types';

import {
  handleChange,
  asyncHandleChange,
} from '../functions/InputHandlers';

import { get } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import history from '../history';

import GraphsCard from './cards/GraphsCard';
import QuicklinksCard from './cards/QuicklinksCard';
import PropertiesCard from './cards/PropertiesCard';
import ValueCard from './cards/ValueCard';
import ExpensesCard from './cards/ExpensesCard';

import FilterDialog from '../components/FilterDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import EditSubportfolioDialog from '../components/EditSubportfolioDialog';

class Subportfolio extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { match } = this.props;

    const { subportfolioId } = match.params;

    const subportfolio = await get(this, 'entities', subportfolioId);

    this.setState({
      subportfolio,
      filterDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
      stale: {
        notes: false,
        expenses: false,
        cashflow: false,
      },
      loading: false,
      editSubportfolioDialog: false,
    });
  }

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.setState({ stale: { cashflow: true, expenses: true } });
  };

  markStale = (area) => {
    handleChange(`nested_stale_${area}`, true, this);
  };

  removeStale = (area) => {
    handleChange(`nested_stale_${area}`, false, this);
  };

  reload = () => {
    const { location } = this.props;
    history.push(`/reload?link=${location.pathname}`);
  }

  actionButtons = () => (
    [
      // { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Edit', action: () => this.setState({ editSubportfolioDialog: true }), class: 'edit' },
    ]
  );

  render() {
    const { match } = this.props;
    const {
      subportfolio, filter, stale, loading, filterDialog, editSubportfolioDialog,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title={subportfolio.name}
          actionButtons={this.actionButtons()}
        />
        {editSubportfolioDialog && (
          <EditSubportfolioDialog
            closeDialog={() => this.setState({ editSubportfolioDialog: false })}
            onEditSubportfolio={this.reload}
            onDeleteSubportfolio={() => history.replace('/subportfolios')}
            subportfolioId={subportfolio.id}
          />
        )}
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={() => this.setState({ filterDialog: false })}
          updateFilter={this.updateFilter}
          dateRange
        />
        <GraphsCard
          view="subportfolio"
          id={subportfolio.id}
        />
        <QuicklinksCard
          markStale={this.markStale}
          view="subportfolio"
          id={subportfolio.id}
        />
        <PropertiesCard
          view="subportfolio"
          id={subportfolio.id}
        />
        <ValueCard
          view="subportfolio"
          id={subportfolio.id}
        />
        <ExpensesCard
          stale={stale.expenses}
          removeStale={this.removeStale}
          view="subportfolio"
          id={subportfolio.id}
          startDate={filter.startDate ? filter.startDate : undefined}
          endDate={filter.endDate ? filter.endDate : undefined}
        />
      </PageGrid>
    );
  }
}

Subportfolio.contextType = PersonContext;

Subportfolio.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Subportfolio;
