import React from 'react';
import PropTypes from 'prop-types';
import generateHash from 'random-hash';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import NavBreadcrumbs from './NavBreadcrumbs';
import ActionButtons from './ActionButtons';

const styles = (theme) => ({
  applied: {
    textDecoration: 'underline',
  },
  flexGrow: {
    flexGrow: '1',
  },
  inlineButton: {
    padding: 0,
    color: theme.palette.action.active,
    verticalAlign: 'text-bottom',
  },
  reverseMargins: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
});

class PageHeader extends React.PureComponent {
  displayFilterLine = (filterElement) => {
    if (typeof filterElement === 'object') {
      return (
        <Box marginLeft={2} key={generateHash()}>
          <Typography variant="caption">
            {`${filterElement.label}:`}
          </Typography>
          <Box marginLeft={1} component="span">
            <Typography variant="caption">
              {filterElement.value}
            </Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Box marginLeft={2} key={generateHash()}>
        <Typography variant="caption">
          {filterElement}
        </Typography>
      </Box>
    );
  }

  render() {
    const {
      classes, match, title, appliedFilter, children, actionButtons,
    } = this.props;

    return (
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" flexWrap="wrap" marginTop={-1} marginLeft={-1}>
          <div className={classes.reverseMargins}>
            {match && <NavBreadcrumbs match={match} />}
            {title && <Typography variant="h5">{title}</Typography>}
            {appliedFilter.length > 0 && (
              <>
                <Typography variant="caption" component="div" className={classes.applied}>
                  Applied Filter
                </Typography>
                {appliedFilter.map((filterElement) => this.displayFilterLine(filterElement))}
              </>
            )}
            {children}
          </div>
          <div className={classes.flexGrow} />
          {actionButtons ? <ActionButtons actionButtons={actionButtons} /> : ''}
        </Box>
      </Grid>
    );
  }
}

PageHeader.defaultProps = {
  title: null,
  actionButtons: null,
  appliedFilter: [],
  children: null,
  match: null,
};

PageHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any),
  actionButtons: PropTypes.arrayOf(PropTypes.object),
  appliedFilter: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
  children: PropTypes.node,
};

export default withStyles(styles)(PageHeader);
