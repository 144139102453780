import React, {
  useEffect, useMemo, useState, useContext,
} from 'react';
import { TextField, debounce } from '@material-ui/core';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';

import history from '../history';
import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import ViewBookedTransactionDialog from './ViewBookedTransactionDialog';
import { reportsOptions } from './Autocomplete/Library';
import { generateSafeRegExp } from '../functions/RegExpFunctions';

export default function UniversalSearchBar() {
  const { organizationId } = useContext(PersonContext);
  const { location } = history;
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedJournalId, setSelectedJournalId] = useState(null);

  const loaded = React.useRef(false);

  const search = useMemo(
    () => debounce(async (input, callBack) => {
      const cleanUpDateString = (searchInput) => moment(searchInput).format('YYYY-MM-DD');

      const cleanUpCurrencyString = (searchInput) => searchInput.replace('$', '').replace(',', '');
      /**
      * Composes a query based on the search type and the user's input
      * @param {*} type - one of account, date, amount, description, property
      * @param {*} searchInput - text user has entered into the search bar
      * @returns - query object
      */
      const setUpQueryBasedOnType = (type, searchInput) => {
        const query = {
          organizationId,
          $limit: 10,
        };
        switch (type) {
          case 'account':
            query.name = { $iLike: `%${searchInput}%` };
            query.$sort = { name: 1 };
            break;
          case 'date':
            query.date = cleanUpDateString(searchInput);
            query.$sort = { id: -1 };
            break;
          case 'amount':
            query.amount = Number(cleanUpCurrencyString(searchInput));
            query.$sort = { date: -1 };
            break;
          case 'description':
            query.description = { $iLike: `%${searchInput}%` };
            query.$sort = { date: -1 };
            break;
          case 'property':
            query.address1 = { $iLike: `%${searchInput}%` };
            query.$sort = { address1: 1 };
            break;
          default:
            break;
        }
        return query;
      };

      /**
      * Sets the table name for the query based on the type
      * @param {*} type - one of account, date, amount, description, property
      * @returns - table name
      */
      const getTableNameBasedOnType = (type) => {
        switch (type) {
          case 'account':
            return 'accounts';
          case 'date':
          case 'amount':
          case 'description':
            return 'journals';
          case 'property':
            return 'properties';
          default:
            return '';
        }
      };

      let searchTypes = ['account', 'date', 'amount', 'description', 'property'];

      if (input.length < 3) {
        return;
      }

      const dateRegex = /\d{1,2}[-\\/\\.\s]\d{1,2}[-\\/\\.\s]\d{2,4}/g;
      const anyDigitRegex = /\d/g;
      // If the input does not include any digits, filter the amount and date search types out of
      // the searchTypes array
      if (!anyDigitRegex.test(inputValue)) {
        searchTypes = searchTypes.filter((type) => type !== 'amount' && type !== 'date');
      } else {
        // If the input does contains digits, but not in the format of a date, filter date type out
        // of the search types array
        if (!dateRegex.test(inputValue)) {
          searchTypes = searchTypes.filter((type) => type !== 'date');
        }
        // If the input does contain digits, but not in the format of a currency amount, filter the
        // amount type out of the search types array
        if (Number.isNaN(Number(cleanUpCurrencyString(inputValue)))) {
          searchTypes = searchTypes.filter((type) => type !== 'amount');
        }
      }
      const searchPromises = [];
      searchTypes.forEach((type) => {
        const query = setUpQueryBasedOnType(type, input);
        const tableName = getTableNameBasedOnType(type);
        searchPromises.push(find(this, tableName, { query }));
      });

      await Promise.all(searchPromises)
        .then((results) => {
          // Push reports into the searchTypes array and check through the reportsOptions array to
          // see if any reports match the user's input
          searchTypes.push('reports');
          const reports = reportsOptions;
          const matchingReports = [];
          const reportNameRegex = generateSafeRegExp(input);
          reports.forEach((report) => {
            if (reportNameRegex.test(report.name)) {
              matchingReports.push(report);
            }
          });
          // If there are reports that match the user's input, push them into the results array,
          // otherwise filter the reports type out of the searchTypes array
          if (matchingReports.length) {
            results.push({ data: matchingReports });
          } else {
            searchTypes = searchTypes.filter((type) => type !== 'reports');
          }
          const formattedResults = [];
          results.forEach((result, index) => {
            if (result.data.length > 0) {
              formattedResults.push({ type: searchTypes[index], data: result.data });
            }
          });
          callBack(formattedResults);
        });
    }, 1000), [organizationId, inputValue],
  );

  /**
   * @param {*} account - account object
   * @returns - link to the account register or booked transactions page for the account
   */
  const getAccountLink = (account) => {
    if (account.type2 === 'Bank') {
      return `/accounts/banking/bank/${account.id}`;
    }
    if (account.type2 === 'Credit Card') {
      return `/accounts/banking/card/${account.id}`;
    }
    return `/reports/account-register?accountId=${account.id}`;
  };

  useEffect(() => {
    let active = true;

    if (!loaded.current) {
      loaded.current = true;
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
    }

    if (!inputValue) {
      setValue(null);
    } else {
      search(inputValue, (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results.length > 0) {
            results.forEach((result) => {
              if (result.data.length < 0) {
                return;
              }
              if (result.type === 'account') {
                const formatted = result.data.map((account) => ({
                  label: `${account.name} ${account.type2 ? `- ${account.type2}` : `- ${account.type}`}`,
                  id: account.id,
                  groupBy: 'Accounts',
                  link: getAccountLink(account),
                  type: result.type,
                }));
                newOptions = [...newOptions, ...formatted];
              } else if (result.type === 'date') {
                const formatted = result.data.map((journal) => ({
                  label: `${moment(journal.date).format('MM/DD/YYYY')} - ${journal.type} ($${parseFloat(journal.amount).toFixed(2)})`,
                  id: journal.id,
                  groupBy: 'Transaction Date',
                  link: null,
                  type: result.type,
                }));
                newOptions = [...newOptions, ...formatted];
              } else if (result.type === 'amount') {
                const formatted = result.data.map((journal) => ({
                  label: `${moment(journal.date).format('MM/DD/YYYY')} - ${journal.type} ($${parseFloat(journal.amount).toFixed(2)})`,
                  id: journal.id,
                  groupBy: 'Transaction Amount',
                  link: null,
                  type: result.type,
                }));
                newOptions = [...newOptions, ...formatted];
              } else if (result.type === 'description') {
                const formatted = result.data.map((journal) => ({
                  label: `${moment(journal.date).format('MM/DD/YYYY')} - ${journal.type} ($${parseFloat(journal.amount).toFixed(2)})`,
                  id: journal.id,
                  groupBy: 'Transaction Description',
                  link: null,
                  type: result.type,
                }));
                newOptions = [...newOptions, ...formatted];
              } else if (result.type === 'property') {
                const formatted = result.data.map((property) => ({
                  label: `${property.address1}`,
                  id: property.id,
                  groupBy: 'Properties',
                  link: `/properties/property/${property.id}`,
                  type: result.type,
                }));
                newOptions = [...newOptions, ...formatted];
              } else if (result.type === 'reports') {
                const formatted = result.data.map((report) => ({
                  label: `${report.name}`,
                  id: report.id,
                  groupBy: 'Reports',
                  link: `/reports/${report.id}`,
                  type: result.type,
                }));
                newOptions = [...newOptions, ...formatted];
              }
            });
          }
          setOptions(newOptions);
        }
      });
    }
    return () => {
      active = false;
    };
  }, [inputValue, search, value]);

  /**
   * Checks the current location to see if the user is on page that might contain the modified
   * transaction. If they are, the page is reloaded to reflect the changes.
   */
  const onTransactionModified = () => {
    history.push(`/reload?link=${location.pathname}`);
  };

  return (
    <>
      {selectedJournalId && (
        <ViewBookedTransactionDialog
          journalId={selectedJournalId}
          onTransactionModified={() => {
            onTransactionModified();
          }}
          closeDialog={() => {
            setSelectedJournalId(null);
          }}
        />
      )}
      <Autocomplete
        id="search-bar"
        blurOnSelect
        options={options}
        groupBy={(option) => option.groupBy}
        getOptionLabel={(option) => option.label}
        value={value}
        noOptionsText={value ? 'None Found' : 'Search for Accounts, Transactions, Properties, or Reports'}
        filterOptions={(x) => x}
        onChange={(event, newValue) => {
          setInputValue('');
          setValue(null);
          if (
            newValue.type === 'date'
            || newValue.type === 'description'
            || newValue.type === 'amount'
          ) {
            setSelectedJournalId(newValue.id);
          } else {
            history.push(`/reload?link=${newValue.link}`);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params /* eslint-disable-line react/jsx-props-no-spreading */}
            margin="dense"
            label="Search"
            variant="filled"
            color="secondary"
          />
        )}
      />
    </>
  );
}
