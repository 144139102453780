import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NumberFormat from 'react-number-format';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Dialog } from '@material-ui/core';
import { PersonContext } from '../contexts/PersonContext';
import { handleKeyboardDatePickerChange } from '../functions/InputHandlers';
import { create } from '../feathersWrapper';
import { getCreditJournalLine, getDebitJournalLine } from './SearchSelect/TransactionTypeOptions';

class DuplicateBookedTransactionDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dateLines: [
        {
          date: null,
        },
      ],
      submitting: false,
      error: null,
      key: 'duplicate',
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  removeDateLine = (index) => {
    const { dateLines } = this.state;
    const newDates = dateLines.slice();
    newDates.splice(index, 1);
    this.setState({
      dateLines: newDates,
    });
  };

  saveTransactions = async (event) => {
    event.preventDefault();
    const { journal } = this.props;
    const { dateLines, submitting } = this.state;

    if (submitting) {
      return;
    }

    let validDates = true;
    dateLines.forEach((dateLine) => {
      if (!dateLine.date || !moment(dateLine.date).isValid()) {
        validDates = false;
      }
    });

    if (!validDates) {
      this.setState({ error: { message: 'Invalid date selected. Please double check your entries.' } });
      return;
    }

    this.setState({ submitting: true, error: null });

    const createPromises = [];
    dateLines.forEach((dateLine) => {
      const workingJournal = { ...journal };

      delete workingJournal.id;
      delete workingJournal.createdAt;
      delete workingJournal.updatedAt;
      delete workingJournal.journalLines;

      workingJournal.date = dateLine.date;
      workingJournal.creditLines = [];
      workingJournal.debitLines = [];

      journal.journalLines.forEach((line) => {
        if (line.type === 'credit') {
          const newLine = getCreditJournalLine();
          newLine.credit = line.credit;
          newLine.accountId = line.accountId;
          workingJournal.creditLines.push(newLine);
        } else if (line.type === 'debit') {
          const newLine = getDebitJournalLine();
          newLine.debit = line.debit;
          newLine.accountId = line.accountId;
          workingJournal.debitLines.push(newLine);
        }
      });

      workingJournal.journalLines = workingJournal.debitLines.concat(workingJournal.creditLines);

      createPromises.push(create(this, 'journals', workingJournal, true));
    });

    await Promise.all(createPromises)
      .then((result) => {
        this.setState({
          transactionCount: result.length,
          submitting: false,
          key: 'success',
        });
      }).catch((error) => {
        this.setState({ error, submitting: false });
      });
  };

  getDialogContent = () => {
    const {
      key,
      dateLines,
      error,
      submitting,
      transactionCount,
    } = this.state;
    const {
      journal,
      onTransactionAdded,
      closeDialog,
    } = this.props;

    switch (key) {
      case 'duplicate':
        return (
          <form onSubmit={this.saveTransactions}>
            <DialogTitle id="alert-dialog-title">
              {`Duplicate Booked ${journal.type}`}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth margin="dense">
                <FormLabel
                  shrink
                  style={{ position: 'relative' }}
                  component={InputLabel}
                >
                  Original Transaction Date
                </FormLabel>
                <Typography>
                  {moment(journal.date).format('M/D/YYYY')}
                </Typography>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <FormLabel
                  shrink
                  style={{ position: 'relative' }}
                  component={InputLabel}
                >
                  Transaction Amount
                </FormLabel>
                <Typography>
                  <NumberFormat
                    displayType="text"
                    value={journal.amount}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <FormLabel
                  shrink
                  style={{ position: 'relative' }}
                  component={InputLabel}
                >
                  Transaction Description
                </FormLabel>
                <Typography style={{ overflow: 'hidden' }}>
                  {journal.description}
                </Typography>
              </FormControl>
              {dateLines.map((date, index) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  display="flex"
                  flexDirection="row"
                >
                  <KeyboardDatePicker
                    label="New Transaction Date"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    value={date.date}
                    onChange={handleKeyboardDatePickerChange(`nested_dateLines_${index}_date`, this)}
                    margin="dense"
                    fullWidth
                    clearable
                    required
                  />
                  {dateLines.length > 1 && (
                  <IconButton
                    style={{ marginTop: 16 }}
                    onClick={() => {
                      this.removeDateLine(index);
                    }}
                  >
                    <ClearIcon style={{ fontSize: 20 }} />
                  </IconButton>
                  )}
                </Box>
              ))}
              <Box
                paddingTop={2}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ maxWidth: 'fit-content', justifySelf: 'center' }}
                  onClick={() => {
                    this.setState({
                      dateLines: [
                        ...dateLines,
                        {
                          date: null,
                        },
                      ],
                    });
                  }}
                >
                  Add Another Date
                </Button>
              </Box>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                disabled={submitting}
              >
                {dateLines.length === 1 ? 'Save Transaction' : `Save ${dateLines.length} Transactions`}
              </Button>
              <Button
                onClick={closeDialog}
                color="primary"
                disableElevation
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        );
      case 'success':
        return (
          <>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                Success
              </Typography>
              <Typography variant="body1" gutterBottom>
                {`${transactionCount} ${transactionCount === 1
                  ? 'transaction was booked.'
                  : 'transactions were booked.'}`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  onTransactionAdded();
                  closeDialog();
                }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    const { loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Dialog
        open
        scroll="body"
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {this.getDialogContent()}
      </Dialog>
    );
  }
}

DuplicateBookedTransactionDialog.contextType = PersonContext;

DuplicateBookedTransactionDialog.propTypes = {
  journal: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onTransactionAdded: PropTypes.func.isRequired,
};

export default DuplicateBookedTransactionDialog;
