export const ACTION_TYPES = {
  FORM_SUBMITTED: 'FORM_SUBMITTED',
  FORM_RESET: 'FORM_RESET',
  CURRENT_PASSWORD_INCORRECT: 'CURRENT_PASSWORD_INCORRECT',
  PASSWORDS_EQUAL_UPDATED: 'PASSWORDS_EQUAL_UPDATED',
  FIELD_UPDATED: 'FIELD_UPDATED',
  PASSWORD_REQUIREMENTS_REVEALED: 'PASSWORD_REQUIREMENTS_REVEALED',
};

export const initialState = {
  currentPassword: '',
  isCurrentPasswordCorrect: true,
  newPassword: '',
  arePasswordRequirementsVisible: false,
  confirmPassword: '',
  arePasswordsEqual: true,
  isPasswordSubmitted: false,
  isSubmitting: false,
};

export default function updatePasswordReducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.FORM_SUBMITTED:
      return {
        ...state,
        isSubmitting: true,
        isPasswordSubmitted: true,
        isCurrentPasswordCorrect: true,
      };
    case ACTION_TYPES.FORM_RESET:
      return {
        ...state,
        isSubmitting: false,
      };
    case ACTION_TYPES.CURRENT_PASSWORD_INCORRECT:
      return { ...state, isCurrentPasswordCorrect: false };
    case ACTION_TYPES.PASSWORDS_EQUAL_UPDATED:
      return { ...state, arePasswordsEqual: action.value };
    case ACTION_TYPES.FIELD_UPDATED:
      return { ...state, [action.field]: action.value };
    case ACTION_TYPES.PASSWORD_REQUIREMENTS_REVEALED:
      return { ...state, arePasswordRequirementsVisible: true };
    default:
      return state;
  }
}
