import { find } from '../../feathersWrapper';

export const searchEntities = (component, additionalParams = {}) => async (search) => {
  const { organizationId } = component.context;
  const query = {
    ...additionalParams,
    organizationId,
    name: { $iLike: `${search}%` },
    $limit: 100,
    $sort: {
      name: 1,
    },
  };
  const entitiesResult = await find(component, 'entities', { query });
  const entities = entitiesResult.data;
  return Promise.resolve(entities.map((entity) => (
    { label: entity.name, value: entity.id }
  )));
};

export const searchProperties = (component, additionalParams = {}) => async (search) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: false,
    ...additionalParams,
    address1: { $iLike: `${search}%` },
    $limit: 200,
    $sort: {
      address1: 1,
    },
  };
  const propertiesResult = await find(component, 'properties', { query });

  const properties = propertiesResult.data;
  return Promise.resolve(properties.map((property) => (
    { label: property.address1, value: property.id }
  )));
};

export const searchUnits = (component, additionalParams = {}) => async (search) => {
  const { organizationId } = component.context;
  const query = {
    inactive: { $or: [null, false] },
    ...additionalParams,
    organizationId,
    name: { $iLike: `${search}%` },
    $limit: 100,
    $sort: {
      name: 1,
    },
  };
  const unitsResult = await find(component, 'units', { query });
  const units = unitsResult.data;
  return Promise.resolve(units.map((unit) => (
    { label: unit.name, value: unit.id }
  )));
};

export const searchContacts = (component, additionalParams = {}) => async (search) => {
  const { organizationId } = component.context;
  const query = { ...additionalParams, organizationId, search };
  const contactsResult = await find(component, 'contacts', { query });
  const contacts = contactsResult.data;
  return Promise.resolve(contacts.map((contact) => (
    { label: `${contact.person.firstName} ${contact.person.lastName}`, value: contact.personId }
  )));
};

export const searchUsers = (component, additionalParams = {}) => async (search) => {
  const query = { ...additionalParams, search };
  const usersResult = await find(component, 'users', { query });
  const users = usersResult.data;
  return Promise.resolve(users.map((user) => (
    { label: `${user.person.firstName} ${user.person.lastName} (${user.person.email})`, value: user.id }
  )));
};

export const searchAssets = (component, additionalParams = {}) => async (search) => {
  const { organizationId } = component.context;
  const query = {
    inactive: { $or: [null, false] },
    ...additionalParams,
    organizationId,
    name: { $iLike: `${search}%` },
    $sort: {
      name: 1,
    },
  };
  const assetsResult = await find(component, 'fixed-assets', { query });
  const assets = assetsResult.data;
  return Promise.resolve(assets.map((asset) => (
    { label: asset.name, value: asset.id }
  )));
};

export const searchVendors = (component, additionalParams = {}) => async (search) => {
  const { organizationId } = component.context;
  const query = {
    ...additionalParams,
    organizationId,
    name: { $iLike: `${search}%` },
    $sort: {
      name: 1,
    },
  };
  const vendorsResult = await find(component, 'vendors', { query });
  const vendors = vendorsResult.data;
  return Promise.resolve(vendors.map((vendor) => (
    { label: vendor.name, value: vendor.id }
  )));
};

export const searchPropertyManagers = (component, additionalParams = {}) => async (search) => {
  const { organizationId } = component.context;
  const query = {
    ...additionalParams,
    organizationId,
    name: { $iLike: `${search}%` },
    $sort: {
      name: 1,
    },
  };
  const propertyManagersResult = await find(component, 'property-managers', { query });
  const propertyManagers = propertyManagersResult.data;
  return Promise.resolve(propertyManagers.map((manager) => (
    { label: manager.name, value: manager.id }
  )));
};

export const searchAccounts = (component, additionalParams = {}) => async (search) => {
  const { organizationId } = component.context;
  const query = {
    inactive: { $or: [null, false] },
    ...additionalParams,
    organizationId,
    name: { $iLike: `${search}%` },
    $limit: 100,
    $sort: {
      name: 1,
    },
  };
  const accountsResult = await find(component, 'accounts', { query });
  const accounts = accountsResult.data;
  return Promise.resolve(accounts.map((account) => (
    { label: account.name, value: account.id }
  )));
};

export const searchPropertiesOrEntities = (
  component, additionalPropertyParams = {}, additionalEntityParams = {},
) => async (search) => {
  const { organizationId } = component.context;
  const propertyQuery = {
    organizationId,
    inactive: false,
    ...additionalPropertyParams,
    address1: { $iLike: `${search}%` },
    $limit: 10,
    $sort: {
      address1: 1,
    },
  };
  const entityQuery = {
    ...additionalEntityParams,
    organizationId,
    name: { $iLike: `${search}%` },
    $limit: 5,
    $sort: {
      name: 1,
    },
  };
  const propertiesPromise = find(component, 'properties', { query: propertyQuery });
  const entitiesPromise = find(component, 'entities', { query: entityQuery });
  const propertiesResult = await propertiesPromise;
  const entitiesResult = await entitiesPromise;

  const returnArray = [];
  returnArray.push({
    label: 'Properties',
    options: propertiesResult.data.map((property) => ({ label: property.address1, value: `pr_${property.id}` })),
  });
  returnArray.push({
    label: 'Entities',
    options: entitiesResult.data.map((entity) => ({ label: entity.name, value: `et_${entity.id}` })),
  });
  return Promise.resolve(returnArray);
};

export const searchPaymentAccounts = (component) => async (search) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    name: { $iLike: `${search}%` },
    type2: ['Bank', 'Credit Card', 'Property Management', 'Owner Funds'],
    $limit: 100,
    $sort: {
      name: 1,
    },
  };
  const accountsResult = await find(component, 'accounts', { query });
  const accounts = accountsResult.data;
  return Promise.resolve(accounts.map((account) => (
    { label: account.name, value: account.id }
  )));
};

export const searchDepositAccounts = (component) => async (search) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    name: { $iLike: `${search}%` },
    type2: ['Bank', 'Property Management', 'Owner Funds'],
    $limit: 100,
    $sort: {
      name: 1,
    },
  };
  const accountsResult = await find(component, 'accounts', { query });
  const accounts = accountsResult.data;
  return Promise.resolve(accounts.map((account) => (
    { label: account.name, value: account.id }
  )));
};

export const searchLoanAccounts = (component) => async (search) => {
  const { organizationId } = component.context;
  let query = {
    organizationId,
    includeProperty: true,
    inactive: { $or: [null, false] },
    type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
    name: { $iLike: `${search}%` },
    $limit: 50,
    $sort: {
      name: 1,
    },
  };
  let accountsResult = await find(component, 'accounts', { query });
  if (accountsResult.data.length === 0) {
    query = {
      organizationId,
      includeProperty: true,
      inactive: { $or: [null, false] },
      type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
      address1: { $iLike: `${search}%` },
      $limit: 50,
      $sort: {
        name: 1,
      },
    };
    accountsResult = await find(component, 'accounts', { query });
  }
  const accounts = accountsResult.data;
  return Promise.resolve(accounts.map((account) => (
    { label: account.name, value: account.id }
  )));
};

export const searchVehicles = (component, additionalParams = {}) => async (search) => {
  const { organizationId } = component.context;
  const query = {
    ...additionalParams,
    organizationId,
    name: { $iLike: `${search}%` },
    $sort: {
      name: 1,
    },
  };
  const result = await find(component, 'vehicles', { query });
  const vehicles = result.data;
  return Promise.resolve(vehicles.map((vehicle) => (
    { label: vehicle.name, value: vehicle.id }
  )));
};
