import React, { useState } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';

import FormGridContainer from './FormGridContainer';
import FormGridItem from './FormGridItem';

export default function CardDateFilterMenu({
  setFilter,
  setStale,
}) {
  CardDateFilterMenu.propTypes = {
    setFilter: PropTypes.func.isRequired,
    setStale: PropTypes.func.isRequired,
  };
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [dateRange, setDateRange] = useState('12 Months');
  const [customDateDialog, setCustomDateDialog] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);

  const handleCustomPeriodChange = (event) => {
    event.preventDefault();
    if (!customStartDate.isValid() || !customEndDate.isValid()) {
      return;
    }
    setFilter({
      startDate: customStartDate.format('YYYY-MM-DD'),
      endDate: customEndDate.format('YYYY-MM-DD'),
      filterText: `${customStartDate.format('M/D/YYYY')} to ${customEndDate.format('M/D/YYYY')}`,
    });
    setCustomDateDialog(false);
    setDateRange('Custom');
    setStale(true);
  };

  const handlePeriodChange = (text) => {
    switch (text) {
      case '12 Months':
        setFilter({
          startDate: moment().subtract(11, 'month').startOf('month').format('YYYY-MM-DD'),
          endDate: moment().endOf('month').format('YYYY-MM-DD'),
          filterText: 'Last 12 Months',
        });
        setStale(true);
        break;
      case 'Year To Date':
        setFilter({
          startDate: moment().startOf('year').format('YYYY-MM-DD'),
          endDate: moment().endOf('month').format('YYYY-MM-DD'),
          filterText: 'Year to Date',
        });
        setStale(true);
        break;
      case 'Last Year':
        setFilter({
          startDate: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
          endDate: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
          filterText: 'Last Year',
        });
        setStale(true);
        break;
      case 'Custom':
        setCustomDateDialog(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {customDateDialog && (
        <Dialog open={customDateDialog}>
          <form onSubmit={handleCustomPeriodChange}>
            <DialogTitle>Select Dates</DialogTitle>
            <DialogContent>
              <FormGridContainer>
                <FormGridItem xs={6}>
                  <KeyboardDatePicker
                    label="Start Date"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    value={customStartDate}
                    onChange={setCustomStartDate}
                    margin="dense"
                    required
                    fullWidth
                    clearable
                  />
                </FormGridItem>
                <FormGridItem xs={6}>
                  <KeyboardDatePicker
                    label="End Date"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    value={customEndDate}
                    onChange={setCustomEndDate}
                    margin="dense"
                    required
                    fullWidth
                    clearable
                  />
                </FormGridItem>
              </FormGridContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setCustomStartDate(null);
                  setCustomEndDate(null);
                  setCustomDateDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disableElevation
                type="submit"
                variant="contained"
                color="primary"
              >
                Apply
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
      <Box alignSelf="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={(event) => {
            setMenuOpen(true);
            setMenuAnchor(event.currentTarget);
          }}
          endIcon={<ArrowDropDown />}
        >
          Dates
        </Button>
        <Menu
          open={menuOpen}
          anchorEl={menuAnchor}
          onClose={() => { setMenuOpen(false); }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem
            selected={dateRange === '12 Months'}
            onClick={() => {
              setMenuOpen(false);
              setDateRange('12 Months');
              handlePeriodChange('12 Months');
            }}
          >
            12 Months
          </MenuItem>
          <MenuItem
            selected={dateRange === 'Year To Date'}
            onClick={() => {
              setMenuOpen(false);
              setDateRange('Year To Date');
              handlePeriodChange('Year To Date');
            }}
          >
            Year To Date
          </MenuItem>
          <MenuItem
            selected={dateRange === 'Last Year'}
            onClick={() => {
              setMenuOpen(false);
              setDateRange('Last Year');
              handlePeriodChange('Last Year');
            }}
          >
            Last Year
          </MenuItem>
          <MenuItem
            selected={dateRange === 'Custom'}
            onClick={() => {
              setMenuOpen(false);
              handlePeriodChange('Custom');
            }}
          >
            Custom
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
}
