import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  getAccountOptions,
  getFixedAssetOptions,
  //   getPaymentAccountOptions,
  getVendorOptions,
  nameAndAddressLabel,
  nameLabel,
} from './Autocomplete/Library';
import { getManualBasicJournal } from './SearchSelect/TransactionTypeOptions';
import TransactionScope from './TransactionScope';
import {
  asyncHandleChange,
  handleAutocompleteChange,
  handleTransactionScopeChange,
} from '../functions/InputHandlers';
import { setJournalScope } from '../functions/JournalFunctions';
import { create, get } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import AddVendorDialog from './AddVendorDialog';
import AddFixedAssetDialog from './AddFixedAssetDialog';

class BatchBookFixedAssetPurchasesDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const initialState = {
      loading: false,
      submitting: false,
      addVendorDialog: false,
      error: null,
    };

    const journal = getManualBasicJournal();
    journal.type = 'Fixed Asset Purchase';

    journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
    initialState.journal = journal;

    initialState.fixedAssetOptions = await getFixedAssetOptions(this, true);
    initialState.accountOptions = await getAccountOptions(this, { type2: 'Fixed Asset' });
    initialState.vendorOptions = await getVendorOptions(this, true);

    this.setState(initialState);
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    closeDialog();
  };

  onBatchBookFixedAssetPurchasesBatchBookTransactions = () => {
    const { onBatchBookFixedAssetPurchases } = this.props;
    onBatchBookFixedAssetPurchases();
  }

  batchBookFixedAssetPurchases = async () => {
    const { journal, submitting } = this.state;
    const { fullTransactions, onBatchBookFixedAssetPurchases } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true, loading: true });

    setJournalScope(journal);

    const batchJournalsArray = [];

    fullTransactions.forEach((transaction) => {
      const journalTemplate = getManualBasicJournal();

      journalTemplate.organizationId = organizationId;

      journalTemplate.description = transaction.description;
      journalTemplate.date = transaction.date;
      journalTemplate.amount = transaction.amount;

      journalTemplate.journalScopeSelect = journal.journalScopeSelect;
      journalTemplate.propertyIdSelect = journal.propertyIdSelect;
      journalTemplate.propertyId = journal.propertyId;
      journalTemplate.unitId = journal.unitId;
      journalTemplate.unitIdSelect = journal.unitIdSelect;
      journalTemplate.entityIdSelect = journal.entityIdSelect;
      journalTemplate.entityId = journal.entityId;

      journalTemplate.vendorIdSelect = journal.vendorIdSelect;
      if (journal.vendorIdSelect) {
        journalTemplate.vendorId = journal.vendorIdSelect.id;
      }

      journalTemplate.fixedAssetIdSelect = journal.fixedAssetIdSelect;
      if (journal.fixedAssetIdSelect) {
        journalTemplate.fixedAssetId = journal.fixedAssetIdSelect.id;
      }

      journalTemplate.debitLines[0].debit = transaction.amount;
      journalTemplate.creditLines[0].credit = transaction.amount;

      journalTemplate.type = journal.type;

      journalTemplate.debitLines[0].accountIdSelect = journal.debitLines[0].accountIdSelect;
      journalTemplate.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;

      journalTemplate.creditLines[0].yodleeTransactionId = transaction.id;
      journalTemplate.creditLines[0].accountIdSelect = transaction.account;
      journalTemplate.creditLines[0].accountId = transaction.account.id;

      journalTemplate.journalLines = journalTemplate.debitLines.concat(journalTemplate.creditLines);

      batchJournalsArray.push(journalTemplate);
    });

    const createPromises = batchJournalsArray.map(
      (batchJournalsArrayItem) => create(this, 'journals', batchJournalsArrayItem, true),
    );

    Promise.all(createPromises)
      .then(() => {
        onBatchBookFixedAssetPurchases();
        this.setState({ submitting: false });
        this.closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  }

  render() {
    const {
      journal,
      loading,
      fixedAssetOptions,
      accountOptions,
      vendorOptions,
      addVendorDialog,
      addFixedAssetDialog,
      error,
    } = this.state;
    const { vendorTracking } = this.context;
    const { fullTransactions } = this.props;

    if (loading) {
      return null;
    }

    if (addFixedAssetDialog) {
      return (
        <AddFixedAssetDialog
          isOpen={addFixedAssetDialog}
          closeDialog={() => this.setState({ addFixedAssetDialog: false })}
          onAddFixedAsset={async (newValue) => {
            await asyncHandleChange('fixedAssetOptions', await getFixedAssetOptions(this, true), this);
            await asyncHandleChange('nested_journal_fixedAssetIdSelect', await get(this, 'fixed-assets', newValue.id), this);
          }}
        />
      );
    }

    if (addVendorDialog) {
      return (
        <AddVendorDialog
          isOpen={addVendorDialog}
          closeDialog={() => this.setState({ addVendorDialog: false })}
          onAddVendor={async (newValue) => {
            await asyncHandleChange('vendorOptions', await getVendorOptions(this, true), this);
            await asyncHandleChange('nested_journal_vendorIdSelect', await get(this, 'vendors', newValue.id), this);
          }}
        />
      );
    }

    return (
      <>
        <Dialog
          open
          scroll="body"
          fullWidth
          disableBackdropClick
          disableEnforceFocus
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          aria-describedby="form-dialog-description"
          id="batch-book-fixed-asset-purchases-dialog"
        >
          <DialogTitle id="form-dialog-title">Batch Book Fixed Asset Purchases</DialogTitle>
          <DialogContent>
            <TransactionScope
              journal={journal}
              transactionScopeChange={(newScopeValues) => {
                handleTransactionScopeChange(journal, newScopeValues, this);
              }}
            />
            <Autocomplete
              options={fixedAssetOptions}
              getOptionLabel={nameAndAddressLabel}
              value={journal.fixedAssetIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_fixedAssetIdSelect',
                this,
                () => this.setState({ addFixedAssetDialog: true }),
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Fixed Asset"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
            <Autocomplete
              options={accountOptions}
              getOptionLabel={nameLabel}
              value={journal.debitLines[0].accountIdSelect}
              onChange={handleAutocompleteChange('nested_journal_debitLines_0_accountIdSelect', this)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Asset Account"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
            {vendorTracking && (
              <Autocomplete
                options={vendorOptions}
                getOptionLabel={nameLabel}
                value={journal.vendorIdSelect}
                onChange={handleAutocompleteChange(
                  'nested_journal_vendorIdSelect',
                  this,
                  () => this.setState({ addVendorDialog: true }),
                )}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Vendor (optional)"
                    placeholder="Type to Search"
                    fullWidth
                  />
                )}
              />
            )}
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeDialog}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={this.batchBookFixedAssetPurchases}
            >
              { fullTransactions.length === 1 ? 'Book Fixed Asset Purchase' : `Book ${fullTransactions.length} Fixed Asset Purchases` }
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

BatchBookFixedAssetPurchasesDialog.contextType = PersonContext;

BatchBookFixedAssetPurchasesDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  fullTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBatchBookFixedAssetPurchases: PropTypes.func.isRequired,
};

export default BatchBookFixedAssetPurchasesDialog;
