import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link as RouterLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import PasswordStrengthChecker from '~/components/PasswordStrengthChecker';
import usePasswordRequirements from '~/hooks/usePasswordRequirements';

import client from '../../feathers';
import { create, get } from '../../feathersWrapper';

import { PersonContext } from '../../contexts/PersonContext';
import {
  handleTextFieldChange,
  handleNumberFormatChange,
} from '../../functions/InputHandlers';

const styles = {
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  registerCard: {
    maxWidth: '440px',
    width: '100%',
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  registerCardContent: {
    padding: '30px 50px',
  },
  checks: {
    marginRight: '16px',
    color: '#431C5D',
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  resetLink: {
    color: '#FFF',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(() => ({
  passwordRequirements: {
    marginTop: '16px',
    width: '100%',
  },
}));

function PasswordChecker(password) {
  const classes = useStyles();
  const passwordRequirements = usePasswordRequirements(
    password,
    false,
  );

  return (
    <PasswordStrengthChecker
      requirements={passwordRequirements}
      className={classes.passwordRequirements}
    />
  );
}

class AccessInvite extends React.Component {
  recaptchaRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const parsed = queryString.parse(location.search);
    let type = 'register';
    try {
      const invite = await get(this, 'administrator-invites', parsed.id, true);
      const register = {
        ...invite,
        password: '',
        phone: '',
      };
      const login = {
        ...invite,
        password: '',
      };
      await client.service('authManagement').create({
        action: 'checkUnique',
        value: {
          email: invite.email.trim().toLowerCase(),
        },
      })
        .catch((error) => {
          // the email already exists
          if (error) {
            type = 'login';
            register.email = '';
          }
        });
      this.setState({
        loading: false,
        submitting: false,
        showPasswordValidation: false,
        type,
        register,
        login,
      });
    } catch (error) {
      this.setState({
        loading: false,
        inviteError: 'Unable to load invite. If you already accepted this invite, please sign in to access your portfolio.',
      });
    }
  }

  registerSubmit = async (event) => {
    event.preventDefault();
    const { submitting, register } = this.state;
    const { setContextState, setPerson } = this.context;
    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    create(
      this,
      'register-invite',
      {
        ...register,
        email: register.email.trim().toLowerCase(),
      },
      true,
    ).then(() => {
      client
        .authenticate({
          strategy: 'local',
          email: register.email.trim().toLowerCase(),
          password: register.password,
        })
        .then((response) => {
          setContextState({ sessionSource: 'register' });
          setPerson(response);
        });
    })
      .catch((feathersError) => {
        this.setState({ error: feathersError });
        this.setState({ submitting: false });
      });
  };

  loginSubmit = async (event) => {
    event.preventDefault();
    const { submitting, login } = this.state;
    const { setContextState, setPerson } = this.context;
    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    client
      .authenticate({
        strategy: 'local',
        email: login.email.trim().toLowerCase(),
        password: login.password,
      })
      .then(async (response) => {
        login.personId = response.user.personId;
        login.userId = response.user.id;
        await create(
          this,
          'register-invite',
          {
            ...login,
            email: login.email.trim().toLowerCase(),
          },
          true,
        );
        setContextState({ sessionSource: 'login' });
        setPerson(response);
      })
      .catch((error) => {
        client.service('authManagement')
          .create({
            action: 'checkUnique',
            value: {
              email: login.email.trim().toLowerCase(),
            },
          })
          .then(() => {
            // the email does not exist
            this.setState({
              error: {
                message: 'This email has not been registered. Please try again or use the button below to create a new account.',
                type: 'email',
              },
              submitting: false,
            });
          })
          .catch(() => {
            if (error.message === 'Invalid login') {
              this.setState({
                error: {
                  message: 'Incorrect password. Please try again or use the button below to reset it.',
                  type: 'password',
                },
                login: { ...login, password: '' },
                submitting: false,
              });
            } else {
              this.setState({
                error: {
                  message: `${error.message}. Please try again.`,
                  type: 'other',
                },
                submitting: false,
              });
            }
          });
      });
  };

  render() {
    const { classes } = this.props;
    const {
      loading, submitting, type, inviteError, error,
      register, login, showPasswordValidation,
    } = this.state;

    if (loading) {
      return null;
    }

    if (inviteError) {
      return (
        <Grid container justify="center" className={classes.root}>
          <Hidden mdDown>
            <Grid item md={6} className={classes.centerContent}>
              <Box mt="80px" ml="100px">
                <Box mb="25px">
                  <Typography variant="h5" align="left" component="div">
                    Everything You Need For Your
                    <br />
                    Rental Property Books
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Easy setup for rentals
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Track income & expenses
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Tax ready reports
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Free and friendly support
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6} className={classes.centerContent}>
            <Card className={classes.registerCard}>
              <CardContent className={classes.registerCardContent}>
                <form onSubmit={this.registerSubmit}>
                  <Box mb="30px">
                    <Typography variant="h5" align="center" component="div">
                      Error Accepting Invite
                    </Typography>
                  </Box>
                  <ReCAPTCHA
                    sitekey="6LdCnJscAAAAANpuCS4G9OWfER1Sphen8uXne-bD"
                    ref={this.recaptchaRef}
                    size="invisible"
                    badge="bottomRight"
                  />
                  <Typography color="error" gutterBottom>
                    {inviteError}
                  </Typography>
                  <Box marginTop="25px" marginBottom="16px">
                    <Button
                      color="primary"
                      type="submit"
                      size="large"
                      fullWidth
                      variant="contained"
                      disableElevation
                      disabled={submitting}
                      component={RouterLink}
                      to="/sign-in"
                    >
                      Sign In
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      );
    }

    if (type === 'register') {
      return (
        <Grid container justify="center" className={classes.root}>
          <Hidden mdDown>
            <Grid item md={6} className={classes.centerContent}>
              <Box mt="80px" ml="100px">
                <Box mb="25px">
                  <Typography variant="h5" align="left" component="div">
                    Everything You Need For Your
                    <br />
                    Rental Property Books
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Easy setup for rentals
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Track income & expenses
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Tax ready reports
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Free and friendly support
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6} className={classes.centerContent}>
            <Card className={classes.registerCard}>
              <CardContent className={classes.registerCardContent}>
                <form onSubmit={this.registerSubmit}>
                  <Box mb="30px">
                    <Typography variant="h5" align="center" component="div">
                      Register to Accept Invite
                    </Typography>
                  </Box>
                  <Box mb="12px">
                    <TextField
                      label="First Name"
                      id="firstName"
                      fullWidth
                      required
                      variant="outlined"
                      InputProps={{
                        value: register.firstName,
                        name: 'nested_register_firstName',
                        onChange: handleTextFieldChange(this),
                        autoComplete: 'given-name',
                      }}
                    />
                  </Box>
                  <Box mb="12px">
                    <TextField
                      label="Last Name"
                      id="lastName"
                      fullWidth
                      required
                      variant="outlined"
                      InputProps={{
                        value: register.lastName,
                        name: 'nested_register_lastName',
                        onChange: handleTextFieldChange(this),
                        autoComplete: 'family-name',
                      }}
                    />
                  </Box>
                  <Box mb="12px">
                    <TextField
                      label="Email"
                      id="email"
                      fullWidth
                      required
                      variant="outlined"
                      InputProps={{
                        value: register.email,
                        name: 'nested_register_email',
                        onChange: handleTextFieldChange(this),
                        type: 'email',
                        autoComplete: 'email',
                      }}
                    />
                  </Box>
                  <Box mb="12px">
                    <TextField
                      label="Create a Password"
                      id="password"
                      fullWidth
                      required
                      variant="outlined"
                      InputProps={{
                        value: register.password,
                        name: 'nested_register_password',
                        onChange: handleTextFieldChange(this),
                        onFocus: () => this.setState({ showPasswordValidation: true }),
                        type: 'password',
                        autoComplete: 'new-password',
                      }}
                    />
                  </Box>
                  {showPasswordValidation && (
                    <PasswordChecker
                      password={register.password}
                    />
                  )}
                  <Box mb="6px">
                    <FormControl
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel>
                        Phone (Recommended)
                      </InputLabel>
                      <NumberFormat
                        label="Phone (Recommended)"
                        value={register.phone}
                        type="tel"
                        format="(###) ###-####"
                        placeholder="(___) ___-____"
                        mask="_"
                        autoComplete="tel-national"
                        onValueChange={handleNumberFormatChange('nested_register_phone', this)}
                        customInput={OutlinedInput}
                      />
                    </FormControl>
                  </Box>
                  <Typography component="div" variant="caption" align="left">
                    {`Our US-based customer support is here to help if you need us. 
                      If you enter a phone number, you agree that we may contact you at this number. 
                      Your carrier's normal phone charges may apply.`}
                  </Typography>
                  <ReCAPTCHA
                    sitekey="6LdCnJscAAAAANpuCS4G9OWfER1Sphen8uXne-bD"
                    ref={this.recaptchaRef}
                    size="invisible"
                    badge="bottomright"
                  />
                  <Box marginTop="25px" marginBottom="16px">
                    {error && (
                      <Typography color="error" gutterBottom>
                        {error.message}
                      </Typography>
                    )}
                    <Button
                      type="submit"
                      disabled={submitting}
                      fullWidth
                      disableElevation
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Accept Invite
                    </Button>
                    <Box marginTop={1.5}>
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled={submitting}
                        size="large"
                        fullWidth
                        onClick={() => this.setState({ type: 'login', login: { ...login, password: '' }, error: null })}
                      >
                        Sign In Instead
                      </Button>
                    </Box>
                  </Box>
                  <Box mx={2}>
                    <Typography variant="caption" align="center" component="div">
                      {'By clicking Accept Invite you agree to our '}
                      <Link href="https://www.reihub.net/terms" color="inherit" underline="always" target="_blank">terms of service</Link>
                      {' and '}
                      <Link href="https://www.reihub.net/privacy" color="inherit" underline="always" target="_blank">privacy policy</Link>
                      .
                    </Typography>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      );
    }

    if (type === 'login') {
      return (
        <Grid container justify="center" className={classes.root}>
          <Hidden mdDown>
            <Grid item md={6} className={classes.centerContent}>
              <Box mt="80px" ml="100px">
                <Box mb="25px">
                  <Typography variant="h5" align="left" component="div">
                    Everything You Need For Your
                    <br />
                    Rental Property Books
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Easy setup for rentals
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Track income & expenses
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Tax ready reports
                  </Typography>
                </Box>
                <Box mb="10px">
                  <Typography align="left" variant="body1">
                    <span className={classes.checks}>✔</span>
                    Free and friendly support
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6} className={classes.centerContent}>
            <Card className={classes.registerCard}>
              <CardContent className={classes.registerCardContent}>
                <form onSubmit={this.loginSubmit}>
                  <Box mb="30px">
                    <Typography variant="h5" align="center" component="div">
                      Sign In to Accept Invite
                    </Typography>
                  </Box>
                  <Box mb="12px">
                    <TextField
                      label="Email"
                      id="email"
                      fullWidth
                      required
                      variant="outlined"
                      InputProps={{
                        value: login.email,
                        name: 'nested_login_email',
                        onChange: handleTextFieldChange(this),
                        autoComplete: 'email',
                        type: 'email',
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <TextField
                    label="Password"
                    id="password"
                    fullWidth
                    required
                    variant="outlined"
                    InputProps={{
                      value: login.password,
                      name: 'nested_login_password',
                      onChange: handleTextFieldChange(this),
                      type: 'password',
                      autoComplete: 'current-password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ReCAPTCHA
                    sitekey="6LdCnJscAAAAANpuCS4G9OWfER1Sphen8uXne-bD"
                    ref={this.recaptchaRef}
                    size="invisible"
                    badge="bottomRight"
                  />
                  <Box marginTop="25px" marginBottom="16px">
                    {error && (
                      <Typography color="error" gutterBottom>
                        {error.message}
                      </Typography>
                    )}
                    <Button
                      color="primary"
                      type="submit"
                      size="large"
                      fullWidth
                      variant="contained"
                      disableElevation
                      disabled={submitting}
                    >
                      Accept Invite
                    </Button>
                    <Box marginTop={1.5}>
                      <Button
                        color="primary"
                        variant="outlined"
                        disabled={submitting}
                        size="large"
                        fullWidth
                        onClick={() => this.setState({ type: 'register', error: null })}
                      >
                        Create an Account Instead
                      </Button>
                    </Box>
                    {error && error.type === 'password' && (
                      <Box marginTop={1.5}>
                        <Button
                          color="primary"
                          variant="outlined"
                          disabled={submitting}
                          size="large"
                          fullWidth
                          component={RouterLink}
                          to={
                            login.email
                              ? `/resetpassword?email=${login.email}`
                              : '/resetpassword'
                          }
                        >
                          Reset My Password
                        </Button>
                      </Box>
                    )}
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      );
    }

    return null;
  }
}

AccessInvite.contextType = PersonContext;

AccessInvite.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(AccessInvite);
