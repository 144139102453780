import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Typography, CardContent, Button, Grid,
} from '@material-ui/core';

import history from '../history';
import { PersonContext } from '../contexts/PersonContext';

import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import FilterDialog from '../components/FilterDialog';

import { asyncHandleChange } from '../functions/InputHandlers';

class GeneralLedger extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dialog: true,
      filter: {
        startDate: null,
        endDate: null,
        propertyId: null,
        unitId: null,
        accountId: null,
        vendorId: null,
        transactionType: null,
        description: '',
        startDateSelect: null,
        endDateSelect: null,
        unitIdSelect: null,
        accountIdSelect: null,
        vendorIdSelect: null,
        transactionTypeSelect: { name: 'All Transactions', id: 'All' },
      },
    };
  }

  goToReports = () => {
    history.replace('/reports');
  };

  closedialog = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({
        dialog: false,
      });
    }
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    await this.exportXlsx();
    this.setState({
      loading: false,
    });
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'general-ledger.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  render() {
    const { match } = this.props;
    const { filter, dialog, loading } = this.state;

    return (
      <PageGrid>
        <PageHeader match={match} title="General Ledger" />
        {loading && (
          <FilterDialog
            filter={filter}
            isOpen={dialog}
            closeDialog={this.closedialog}
            updateFilter={this.updateFilter}
            dateRange
            transactionType
            scope={['Full Portfolio', 'Sub-Portfolio', 'Property', 'Unit']}
            required={['scope']}
            account
            vendor
            description
          />
        )}
        {!loading && (
          <Grid item xs={12}>
            <CardBase>
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" align="center" gutterBottom>
                    Export complete
                  </Typography>
                  <Typography variant="body1" align="center" gutterBottom>
                    Your transactions have been downloaded to your computer as a .xslx spreadsheet.
                  </Typography>
                  <Box mt={2}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => this.goToReports()}
                    >
                      Back to Reports
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </CardBase>
          </Grid>
        )}
      </PageGrid>
    );
  }
}

GeneralLedger.contextType = PersonContext;

GeneralLedger.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default GeneralLedger;
