import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import NumberFormat from 'react-number-format';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { get, create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncSetState,
  asyncHandleChange,
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleTextFieldChange,
  handleAutocompleteChange,
  handleTransactionScopeChange,
} from '../functions/InputHandlers';

import {
  nameLabel,
  getVehicleOptions,
  // getPropertyOptions,
} from './Autocomplete/Library';

import AddVehicleDialog from './AddVehicleDialog';
import TransactionScope from './TransactionScope';
import { setJournalScope } from '../functions/JournalFunctions';

const styles = {
  datetime: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '4px',
  },
};

class AddMileageDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true, newVehicle: false };
  }

  setInitialState = async () => {
    const { organizationId } = this.context;
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      mileage: {
        organizationId,
        date: moment().format('YYYY-MM-DD'),
        dateSelect: moment(),
        miles: null,
        description: '',
        vehicleId: null,
        vehicleIdSelect: null,
        propertyId: null,
        propertyIdSelect: null,
        unitId: null,
        unitIdSelect: null,
        entityId: null,
        entityIdSelect: null,
      },
      journal: {
        journalScopeSelect: { name: 'Property/Unit', id: 'Property/Unit' },
      },
    };

    initialState.vehicleOptions = await getVehicleOptions(this);
    if (initialState.vehicleOptions.length === 0) {
      initialState.vehicleOptions = await getVehicleOptions(this, true);
    } else if (initialState.vehicleOptions.length === 1) {
      [initialState.mileage.vehicleIdSelect] = initialState.vehicleOptions;
    }

    await asyncSetState(initialState, this);
  };

  onAddVehicleDialog = async (vehicle) => {
    const vehicleOptions = await getVehicleOptions(this);
    const newVehicle = await get(this, 'vehicles', vehicle.id);
    await asyncHandleChange('vehicleOptions', vehicleOptions, this);
    await asyncHandleChange('nested_mileage_vehicleIdSelect', newVehicle, this);
    this.setState({ newVehicle: false });
  };

  addMileage = async (event) => {
    event.preventDefault();
    const {
      submitting, mileage, journal,
    } = this.state;

    const { onAdd } = this.props;

    if (submitting) {
      return;
    }

    if (!mileage.date) {
      this.setState({ error: { message: 'Please enter a valid date for this trip' } });
      return;
    }

    if (!mileage.miles) {
      this.setState({ error: { message: 'Please enter the miles driven for this trip' } });
      return;
    }

    mileage.vehicleId = mileage.vehicleIdSelect.id;

    setJournalScope(journal);
    mileage.propertyId = journal.propertyId;
    mileage.unitId = journal.unitId;
    mileage.entityId = journal.entityId;

    this.setState({ submitting: true });

    create(this, 'mileage', mileage, true)
      .then((result) => {
        onAdd(result);
        this.setState({ loading: true, newVehicle: false });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const {
      loading,
      error,
      mileage,
      newVehicle,
      vehicleOptions,
      journal,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AddVehicleDialog
          isOpen={newVehicle}
          closeDialog={() => { this.setState({ newVehicle: false }); }}
          onAdd={async (newValue) => {
            await asyncHandleChange('vehicleOptions', await getVehicleOptions(this), this);
            await asyncHandleChange('nested_mileage_vehicleIdSelect', await get(this, 'vehicles', newValue.id), this);
          }}
        />
        {!loading && (
          <form onSubmit={this.addMileage}>
            <DialogTitle id="alert-dialog-title">Add Mileage</DialogTitle>
            <DialogContent>
              <KeyboardDatePicker
                className={classes.datetime}
                label="Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={mileage.date}
                onChange={handleKeyboardDatePickerChange('nested_mileage_date', this)}
                margin="dense"
                fullWidth
                clearable
                required
              />
              <Autocomplete
                options={vehicleOptions}
                getOptionLabel={nameLabel}
                value={mileage.vehicleIdSelect}
                onChange={handleAutocompleteChange(
                  'nested_mileage_vehicleIdSelect',
                  this,
                  () => this.setState({ newVehicle: true }),
                )}
                disableClearable
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Vehicle"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <FormControl margin="dense" fullWidth>
                <InputLabel required>
                  Miles
                </InputLabel>
                <NumberFormat
                  value={mileage.miles}
                  required
                  thousandSeparator
                  decimalScale={1}
                  onValueChange={handleNumberFormatChange('nested_mileage_miles', this)}
                  customInput={Input}
                />
              </FormControl>
              <TextField
                label="Trip purpose/description"
                fullWidth
                multiline
                rowsMax="5"
                required
                margin="dense"
                InputProps={{
                  value: mileage.description,
                  name: 'nested_mileage_description',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TransactionScope
                journal={journal}
                transactionScopeChange={(newScopeValues) => {
                  handleTransactionScopeChange(journal, newScopeValues, this);
                }}
                label="Mileage"
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Mileage
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

AddMileageDialog.contextType = PersonContext;

AddMileageDialog.defaultProps = {
};

AddMileageDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddMileageDialog);
