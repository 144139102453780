import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  FormLabel,
  Box,
  makeStyles,
} from '@material-ui/core';

import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { PersonContext } from '../contexts/PersonContext';
import { patch } from '../feathersWrapper';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  archiveButtonContainer: {
    marginRight: 'auto',
  },
  dialogActionButtonsContainer: {
    gap: theme.spacing(1),
    marginLeft: 'auto',
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export default function EditRecurringTransaction({
  isOpen,
  closeDialog,
  onEdit,
  recurringTransactionToEdit,
}) {
  const classes = useStyles();
  const context = useContext(PersonContext);
  const { organizationName } = context;
  const { journal } = recurringTransactionToEdit;

  const [key, setKey] = useState('view');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [transactionName, setTransactionName] = useState(recurringTransactionToEdit.name);
  const [endDate, setEndDate] = useState(recurringTransactionToEdit.endDate);
  const [inactive, setInactive] = useState(recurringTransactionToEdit.inactive);

  const editRecurringTransaction = async () => {
    if (submitting) {
      return;
    }

    if (endDate && !moment(endDate).isValid()) {
      setError({ message: 'Please enter a valid end date' });
      return;
    }

    setSubmitting(true);

    try {
      await patch(this, 'recurring-transactions', recurringTransactionToEdit.id, {
        ...recurringTransactionToEdit,
        inactive,
        name: transactionName,
        endDate,
      });
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }

    onEdit();
    closeDialog();
  };

  const getScopeText = () => {
    if (recurringTransactionToEdit.propertyId && !recurringTransactionToEdit.unitId) {
      return (
        <Typography>
          {journal.propertyIdSelect.address1}
        </Typography>
      );
    }
    if (recurringTransactionToEdit.propertyId && recurringTransactionToEdit.unitId) {
      return (
        <>
          <Typography>
            {journal.propertyIdSelect.address1}
          </Typography>
          <Typography>
            {journal.unitIdSelect.name}
          </Typography>
        </>
      );
    }
    if (recurringTransactionToEdit.entityId && !recurringTransactionToEdit.propertyId) {
      return (
        <Typography>
          {journal.entityIdSelect.name}
          {' '}
          Sub-Portfolio
        </Typography>
      );
    }
    return (
      <Typography>
        {organizationName}
        {' '}
        Portfolio
      </Typography>
    );
  };

  const getTransactionTypeAccountInformation = () => {
    const transactionType = recurringTransactionToEdit.journal.type;
    const debitLine = recurringTransactionToEdit.journal.debitLines[0];
    const creditLine = recurringTransactionToEdit.journal.creditLines[0];
    switch (transactionType) {
      case 'Expense':
        return (
          <>
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Expense Account
              </FormLabel>
              <Typography>
                {debitLine.accountIdSelect.name}
              </Typography>
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Payment Account
              </FormLabel>
              <Typography>
                {creditLine.accountIdSelect.name}
              </Typography>
            </FormControl>
            {recurringTransactionToEdit.journal.vendorId && (
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Vendor
                </FormLabel>
                <Typography>
                  {recurringTransactionToEdit.journal.vendorIdSelect.name}
                </Typography>
              </FormControl>
            )}
          </>
        );
      case 'Revenue':
        return (
          <>
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Revenue Account
              </FormLabel>
              <Typography>
                {creditLine.accountIdSelect.name}
              </Typography>
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Deposit Account
              </FormLabel>
              <Typography>
                {debitLine.accountIdSelect.name}
              </Typography>
            </FormControl>
          </>
        );
      case 'Transfer':
        return (
          <>
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Transferred From Account
              </FormLabel>
              <Typography>
                {debitLine.accountIdSelect.name}
              </Typography>
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Transferred To Account
              </FormLabel>
              <Typography>
                {creditLine.accountIdSelect.name}
              </Typography>
            </FormControl>
          </>
        );
      default:
        return null;
    }
  };

  const getDialogContent = () => {
    switch (key) {
      case 'view':
        return (
          <>
            <DialogTitle id="alert-dialog-title">
              Recurring Transaction
            </DialogTitle>
            <DialogContent>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Name
                </FormLabel>
                <Typography>
                  {recurringTransactionToEdit.name}
                </Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Type
                </FormLabel>
                <Typography>
                  {recurringTransactionToEdit.type}
                </Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Transaction Scope
                </FormLabel>
                {getScopeText()}
              </FormControl>
              {getTransactionTypeAccountInformation()}
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Amount
                </FormLabel>
                <Typography>
                  {`$${recurringTransactionToEdit.journal.amount.toFixed(2)}`}
                </Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Description
                </FormLabel>
                <Typography>
                  {
                    recurringTransactionToEdit.journal.description
                      ? recurringTransactionToEdit.journal.description
                      : '-----'
                  }
                </Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Frequency
                </FormLabel>
                <Typography>
                  {recurringTransactionToEdit.frequency}
                </Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Start Date
                </FormLabel>
                <Typography>
                  {moment(recurringTransactionToEdit.startDate).format('MM/DD/YYYY')}
                </Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  End Date
                </FormLabel>
                <Typography>
                  {
                  recurringTransactionToEdit.endDate
                    ? moment(recurringTransactionToEdit.endDate).format('MM/DD/YYYY')
                    : '-----'
                }
                </Typography>
              </FormControl>
              {recurringTransactionToEdit.inactive && (
                <FormControl margin="dense" fullWidth>
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Inactive
                  </FormLabel>
                  <Typography>
                    True
                  </Typography>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Close
              </Button>
              {!recurringTransactionToEdit.inactive && (
                <Button
                  onClick={() => setKey('edit')}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Edit
                </Button>
              )}
            </DialogActions>
          </>
        );
      case 'edit':
        return (
          <>
            <DialogTitle id="alert-dialog-title">
              Edit Recurring Transaction
            </DialogTitle>
            <DialogContent>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Box className={classes.alertContainer}>
                  <Typography variant="body1" gutterBottom>
                    Only the name and end date of a recurring transaction can be edited. To make
                    other changes, please archive this recurring transaction and create a new one.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Archiving a recurring transaction does not delete any past transactions that
                    have already been created.
                  </Typography>
                </Box>
              </Box>
              <TextField
                label="Transaction Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  name: 'transactionName',
                  value: transactionName,
                  onChange: (event) => {
                    setTransactionName(event.target.value);
                  },
                }}
              />
              {(
                !recurringTransactionToEdit.endDate
                || moment(recurringTransactionToEdit.endDate).isAfter(moment())
              ) && (
                <Box mb={2}>
                  <KeyboardDatePicker
                    label="End Date"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    value={moment(endDate).isValid() ? endDate : null}
                    onChange={(date) => {
                      if (moment(date).isBefore(moment())) {
                        setError({ message: 'End date must occur after today\'s date' });
                        return;
                      }
                      setEndDate(date);
                    }}
                    margin="dense"
                    fullWidth
                    clearable
                  />
                </Box>
              )}
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <Box className={classes.buttonsContainer}>
              <Box className={classes.archiveButtonContainer}>
                <Button
                  onClick={() => {
                    setKey('archive');
                    setInactive(true);
                  }}
                  color="primary"
                  variant="outlined"
                >
                  Archive
                </Button>
              </Box>
              <Box className={classes.dialogActionButtonsContainer}>
                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={editRecurringTransaction}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Save
                </Button>
              </Box>
            </Box>
          </>
        );
      case 'archive':
        return (
          <>
            <DialogTitle id="alert-dialog-title">
              Archive Recurring Transaction
            </DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to archive this recurring transaction? This action cannot be
                undone.
              </Typography>
              <DialogActions>
                <Button
                  onClick={() => {
                    setInactive(false);
                    setKey('edit');
                  }}
                  color="primary"
                >
                  Go Back
                </Button>
                <Button
                  onClick={() => {
                    editRecurringTransaction();
                  }}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Archive
                </Button>
              </DialogActions>
            </DialogContent>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return null;
        }
        return closeDialog();
      }}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {getDialogContent()}
    </Dialog>
  );
}

EditRecurringTransaction.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  recurringTransactionToEdit: PropTypes.objectOf(PropTypes.any).isRequired,
};
