import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link as RouterLink } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
});

class LinkBase extends React.PureComponent {
  render() {
    const {
      classes, children, className, ...rest
    } = this.props;
    const cardClasses = classNames({
      [classes.root]: true,
      [className]: className !== undefined,
    });

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <RouterLink className={cardClasses} {...rest}>
        {children}
      </RouterLink>
    );
  }
}

LinkBase.defaultProps = {
  className: undefined,
};

LinkBase.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(LinkBase);
