import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import client from '../../feathers';

const styles = {
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loginCard: {
    maxWidth: '440px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  resetLink: {
    color: '#FFF',
    textDecoration: 'none',
  },
};

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resetSent: false,
      submitting: false,
      passwordResetFormEmail: '',
    };
  }

  componentDidMount() {
    const { location } = this.props;

    const parsed = queryString.parse(location.search);
    if (parsed.email) {
      this.setState({ passwordResetFormEmail: parsed.email });
    }
  }

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  passwordResetSubmit = (event) => {
    const { submitting, passwordResetFormEmail } = this.state;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    const payload = {
      action: 'sendResetPwd',
      value: {
        email: passwordResetFormEmail.toLowerCase(),
      },
    };
    const authManagementService = client.service('authManagement');
    authManagementService
      .create(payload)
      .then(() => {
        this.setState({ resetSent: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });

    event.preventDefault();
  };

  render() {
    const { classes } = this.props;
    const {
      submitting, error,
      resetSent, passwordResetFormEmail,
    } = this.state;
    return (
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} className={classes.centerContent}>
          {resetSent ? (
            <Card className={classes.loginCard}>
              <Box display="flex" flexDirection="column" width={1} px="50px" py="30px">
                <Box mb="30px">
                  <Typography variant="h5" align="center" component="div">
                    Reset in Progress
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {`We have sent a password reset link to ${passwordResetFormEmail}. 
                  Please check your email and click the link. 
                  This reset link will expire in 10 minutes.`}
                </Typography>
              </Box>
            </Card>
          ) : (
            <Card className={classes.loginCard}>
              <Box display="flex" flexDirection="column" width={1} px="50px" py="30px">
                <form onSubmit={this.passwordResetSubmit}>
                  <Box mb="30px">
                    <Typography variant="h5" align="center" component="div">
                      Reset Password
                    </Typography>
                  </Box>
                  <TextField
                    variant="outlined"
                    label="Email"
                    id="email"
                    fullWidth
                    required
                    InputProps={{
                      value: passwordResetFormEmail,
                      name: 'passwordResetFormEmail',
                      onChange: this.handleInputChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box marginTop="30px">
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      size="large"
                      fullWidth
                      disableElevation
                      disabled={submitting}
                    >
                      Send Password Reset Link
                    </Button>
                    <Box marginTop={1.5}>
                      <Button
                        component={Link}
                        to="/sign-in"
                        color="primary"
                        size="large"
                        variant="outlined"
                        fullWidth
                      >
                        Sign In Instead
                      </Button>
                    </Box>
                  </Box>
                  <Typography color="error">{error && error.message}</Typography>
                </form>
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ResetPassword);
