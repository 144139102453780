import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NumberFormat from 'react-number-format';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import LinkIcon from '@material-ui/icons/Link';

import { get, remove } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import DialogTemplate from './DialogTemplate';
import EditBookedTransactionDialog from './EditBookedTransactionDialog';
import DuplicateBookedTransactionDialog from './DuplicateBookedTransactionDialog';

const styles = {
  deleteConfirmationButton: {
    color: 'red',
  },
  red: {
    color: 'red',
  },
};

class ViewBookedTransactionDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true, editTransaction: false };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { journalId } = this.props;

    let initialState;

    try {
      const journal = await get(this, 'journals', journalId, true);

      let hasImportedTransaction = false;
      journal.journalLines.forEach((line) => {
        if (line.yodleeTransactionId) {
          hasImportedTransaction = true;
        }
      });

      initialState = {
        loading: false,
        submitting: false,
        error: null,
        journal,
        hasImportedTransaction,
        key: 'viewBookedTransaction',
      };
    } catch (error) {
      if (error.code === 404) {
        initialState = {
          loading: false,
          submitting: false,
          key: 'transactionRemoved',
        };
      } else {
        throw error;
      }
    }

    this.setState(initialState);
  };

  deleteTransaction = async () => {
    const { submitting } = this.state;
    const { journalId, onTransactionModified, closeDialog } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    remove(this, 'journals', journalId, true)
      .then(() => {
        closeDialog();
        onTransactionModified();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  closeAndRefresh = async () => {
    const { onTransactionModified, closeDialog } = this.props;

    closeDialog();
    onTransactionModified();
  };

  getDialogContent = (key) => {
    const {
      journal, hasImportedTransaction, error,
    } = this.state;

    const { closeDialog, classes } = this.props;
    const { multiEntity } = this.context;

    switch (key) {
      case 'viewBookedTransaction':
        return (
          <>
            <DialogTitle id="alert-dialog-title">
              Booked Transaction
            </DialogTitle>
            <DialogContent>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Date
                </FormLabel>
                <Typography>
                  {moment(journal.date).format('M/D/YYYY')}
                </Typography>
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Type
                </FormLabel>
                <Typography>
                  {journal.type}
                </Typography>
              </FormControl>
              {multiEntity && (
                <FormControl margin="dense" fullWidth>
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Sub-Portfolio
                  </FormLabel>
                  <Typography>
                    {journal.entity ? journal.entity.name : 'None Assigned'}
                  </Typography>
                </FormControl>
              )}
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Property
                </FormLabel>
                <Typography>
                  {journal.property ? journal.property.address1 : 'None Assigned'}
                </Typography>
              </FormControl>
              {journal.property && journal.property.unitSelection === 'multi' && (
                <FormControl margin="dense" fullWidth>
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Unit
                  </FormLabel>
                  <Typography>
                    {journal.unit ? journal.unit.name : 'All Units'}
                  </Typography>
                </FormControl>
              )}
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Description
                </FormLabel>
                <Typography>
                  {journal.description ? journal.description : '--'}
                </Typography>
              </FormControl>
              {journal.notes && (
                <FormControl margin="dense" fullWidth>
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Additional Notes
                  </FormLabel>
                  <Typography>
                    {journal.notes}
                  </Typography>
                </FormControl>
              )}
              {journal.vendor && (
                <FormControl margin="dense" fullWidth>
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Vendor
                  </FormLabel>
                  <Typography>
                    {journal.vendor.name}
                  </Typography>
                </FormControl>
              )}
              {journal.fixedAsset && (
                <FormControl margin="dense" fullWidth>
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Fixed Asset
                  </FormLabel>
                  <Typography>
                    {journal.fixedAsset.name}
                  </Typography>
                </FormControl>
              )}
              {journal.attachmentURL && (
                <FormControl margin="dense">
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Attachments
                  </FormLabel>
                  <Button
                    variant="outlined"
                    size="small"
                    href={journal.attachmentURL.replace('~1/', '~1/nth/0/')}
                    target="_blank"
                  >
                    View
                  </Button>
                </FormControl>
              )}
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Debit Lines
                </FormLabel>
                {journal.journalLines.map((line) => {
                  if (line.type !== 'debit') {
                    return null;
                  }
                  return (
                    <Box key={line.id} display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography component="span">
                        {line.account.name}
                      </Typography>
                      {line.yodleeTransactionId && (
                        <>
                          <Box flexGrow="1" />
                          <Box component="span" marginRight={3}>
                            <LinkIcon fontSize="small" />
                          </Box>
                        </>
                      )}
                      <Typography
                        component="span"
                        style={{
                          width: '110px',
                          textAlign: 'right',
                        }}
                      >
                        <NumberFormat
                          displayType="text"
                          value={line.debit}
                          thousandSeparator
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </Typography>
                    </Box>
                  );
                })}
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Credit Lines
                </FormLabel>
                {journal.journalLines.map((line) => {
                  if (line.type !== 'credit') {
                    return null;
                  }
                  return (
                    <Box key={line.id} display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography component="span">
                        {line.account.name}
                      </Typography>
                      {line.yodleeTransactionId && (
                        <>
                          <Box flexGrow="1" />
                          <Box component="span" marginRight={3}>
                            <LinkIcon fontSize="small" />
                          </Box>
                        </>
                      )}
                      <Typography
                        component="span"
                        style={{
                          width: '110px',
                          textAlign: 'right',
                        }}
                      >
                        <NumberFormat
                          displayType="text"
                          value={line.credit}
                          thousandSeparator
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </Typography>
                    </Box>
                  );
                })}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeDialog}
                variant="contained"
                color="primary"
                disableElevation
              >
                Close
              </Button>
              <Button
                onClick={() => this.setState({ editTransaction: true })}
                color="primary"
              >
                Edit
              </Button>
              <Button
                onClick={() => { this.setState({ duplicateTransaction: true }); }}
                color="primary"
              >
                Duplicate
              </Button>
              {hasImportedTransaction && (
                <Button
                  onClick={() => this.setState({ key: 'undoTransaction' })}
                  className={classes.deleteConfirmationButton}
                >
                  Undo
                </Button>
              )}
              {!hasImportedTransaction && (
                <Button
                  onClick={() => this.setState({ key: 'deleteTransaction' })}
                  className={classes.deleteConfirmationButton}
                >
                  Delete
                </Button>
              )}
            </DialogActions>
          </>
        );
      case 'undoTransaction':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Really Undo Transaction?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Undoing this transaction will delete the booked transaction and
                return imported transactions to your import feed.`}
              </DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeDialog}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={this.deleteTransaction}
                className={classes.deleteConfirmationButton}
              >
                Undo Transaction
              </Button>
            </DialogActions>
          </>
        );
      case 'deleteTransaction':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Really Delete Transaction?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This transaction will be removed.
              </DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeDialog}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={this.deleteTransaction}
                className={classes.deleteConfirmationButton}
              >
                Delete Transaction
              </Button>
            </DialogActions>
          </>
        );
      case 'transactionRemoved':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Transaction Deleted</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This transaction has already been removed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeAndRefresh} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        );
      default:
        return null;
    }
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return (
          <span className={classes.red}>
            {`(${value})`}
          </span>
        );
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return (
          <span className={classes.red}>
            {`(${value})`}
          </span>
        );
      }
    }
    return value;
  };

  render() {
    const { journalId, closeDialog, onTransactionModified } = this.props;
    const {
      loading, key, editTransaction, duplicateTransaction, journal,
    } = this.state;

    if (loading) {
      return null;
    }

    if (editTransaction) {
      return (
        <EditBookedTransactionDialog
          journalId={journalId}
          isOpen
          closeDialog={closeDialog}
          onEditTransaction={onTransactionModified}
        />
      );
    }

    if (duplicateTransaction) {
      return (
        <DuplicateBookedTransactionDialog
          journal={journal}
          closeDialog={closeDialog}
          onTransactionAdded={onTransactionModified}
        />
      );
    }

    return (
      <DialogTemplate
        open
        scroll="body"
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {this.getDialogContent(key)}
      </DialogTemplate>
    );
  }
}

ViewBookedTransactionDialog.contextType = PersonContext;

ViewBookedTransactionDialog.defaultProps = {
};

ViewBookedTransactionDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onTransactionModified: PropTypes.func.isRequired,
  journalId: PropTypes.number.isRequired,
};

export default withStyles(styles)(ViewBookedTransactionDialog);
