import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  underline: {
    height: '100%',
    borderBottom: `1px dotted ${theme.palette.text.disabled}`,
  },
});

class FieldGridSpacer extends React.PureComponent {
  render() {
    const {
      classes,
    } = this.props;
    return (
      <Grid item className={classes.root}>
        <div className={classes.underline} />
      </Grid>
    );
  }
}

FieldGridSpacer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(FieldGridSpacer);
