import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';

import history from '../history';
import {
  create,
} from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  // handleChange,
  handleRadioGroupChange,
  handleAutocompleteChange,
  handleKeyboardDatePickerChange,
  handleTextFieldChange,
  handleNumberFormatChange,
} from '../functions/InputHandlers';

import {
  addressLabel,
  nameLabel,
  getPropertyOptions,
  getOtherFixedAssetTypeOptions,
} from './Autocomplete/Library';

class AddFixedAssetDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = async () => {
    const { organizationId } = this.context;
    const initialState = {
      loading: false,
      submitting: false,
      key: 'fixedAssetType',
      asset: {
        organizationId,
        isProperty: 'property',
        propertyType: 'residential',
        name: '',
        type: '',
        typeSelect: null,
        propertyId: null,
        propertyIdSelect: null,
        placedInServiceDate: null,
        lifespan: null,
      },
      error: null,
    };

    initialState.propertyOptions = await getPropertyOptions(this);

    this.setState(initialState);
  };

  processFixedAssetType = (event) => {
    event.preventDefault();

    const { asset } = this.state;
    const updatedAsset = { ...asset };

    if (asset.isProperty === 'property') {
      this.setState({ key: 'selectProperty' });
    } else {
      updatedAsset.type = asset.typeSelect.id;
      updatedAsset.name = asset.typeSelect.id;
      updatedAsset.lifespan = asset.typeSelect.lifespan;
      this.setState({ asset: updatedAsset, key: 'assetName' });
    }
  };

  processSelectProperty = async (event) => {
    event.preventDefault();

    const { asset } = this.state;
    const updatedAsset = { ...asset };
    updatedAsset.propertyId = asset.propertyIdSelect.id;
    updatedAsset.name = asset.propertyIdSelect.address1;
    this.setState({ asset: updatedAsset, key: 'selectPropertyType' });
  };

  processSelectPropertyType = (event) => {
    event.preventDefault();
    const { asset } = this.state;
    const updatedAsset = { ...asset };
    if (asset.propertyType === 'residential') {
      updatedAsset.type = 'Residential Property';
      updatedAsset.lifespan = 27.5;
    } else {
      updatedAsset.type = 'Commercial Property';
      updatedAsset.lifespan = 39;
    }

    this.setState({ asset: updatedAsset, key: 'propertyPlacedInService' });
  };

  processAssetName = (event) => {
    event.preventDefault();

    this.setState({ key: 'selectAssetProperty' });
  };

  processSelectAssetProperty = (event) => {
    event.preventDefault();

    const { asset } = this.state;
    const updatedAsset = { ...asset };
    if (asset.propertyIdSelect) {
      updatedAsset.propertyId = asset.propertyIdSelect.id;
    } else {
      updatedAsset.propertyId = null;
    }
    this.setState({ asset: updatedAsset, key: 'assetLifespan' });
  };

  processAssetLifespan = (event) => {
    event.preventDefault();

    this.setState({ key: 'assetPlacedInService' });
  };

  saveAsset = async (event) => {
    event.preventDefault();
    const {
      submitting, asset,
    } = this.state;
    const { onAddFixedAsset, openAssetPage } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    create(this, 'fixed-assets', asset, true)
      .then((newAsset) => {
        onAddFixedAsset(newAsset);
        if (openAssetPage) {
          history.push(`/organization/fixed-assets/${newAsset.id}`);
        } else {
          this.closeDialog();
        }
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  getDialogContent = (key) => {
    const {
      asset, propertyOptions,
      error,
    } = this.state;

    switch (key) {
      case 'fixedAssetType':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processFixedAssetType} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="h6" gutterBottom>Which type of fixed asset are you adding?</Typography>
                </Box>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="basis" name="nested_asset_isProperty" value={asset.isProperty} onChange={handleRadioGroupChange(this)}>
                    <FormControlLabel value="property" control={<Radio required />} label="A Property" />
                    <FormControlLabel value="other" control={<Radio required />} label="Something Else" />
                  </RadioGroup>
                </FormControl>
                {asset.isProperty === 'other' && (
                  <Autocomplete
                    options={getOtherFixedAssetTypeOptions}
                    getOptionLabel={nameLabel}
                    value={asset.typeSelect}
                    onChange={handleAutocompleteChange(
                      'nested_asset_typeSelect',
                      this,
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                        margin="dense"
                        label="Asset Type"
                        placeholder="Type to Search"
                        fullWidth
                        required
                      />
                    )}
                  />
                )}
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body2">
                    {`Fixed assets are used to track the basis of depreciable items on your balance sheet. 
                      Each property can have one or more fixed assets, including the property itself.`}
                  </Typography>
                </Box>
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Next
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'selectProperty':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processSelectProperty} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="h6" gutterBottom>
                    Which property are you adding?
                  </Typography>
                </Box>
                <Autocomplete
                  options={propertyOptions}
                  getOptionLabel={addressLabel}
                  value={asset.propertyIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_asset_propertyIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Property"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Next
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'selectPropertyType':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processSelectPropertyType} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="caption" gutterBottom>
                    {asset.propertyIdSelect.address1}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    What type of property is this?
                  </Typography>
                </Box>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="basis" name="nested_asset_propertyType" value={asset.propertyType} onChange={handleRadioGroupChange(this)}>
                    <FormControlLabel value="residential" control={<Radio required />} label="Residential Property" />
                    <FormControlLabel value="commercial" control={<Radio required />} label="Commercial Property" />
                  </RadioGroup>
                </FormControl>
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body2">
                    {asset.propertyType === 'residential' && 'Residential property depreciates over a 27.5 year lifespan.'}
                    {asset.propertyType === 'commercial' && 'Commercial property depreciates over a 39 year lifespan.'}
                  </Typography>
                </Box>
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Next
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'propertyPlacedInService':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.saveAsset} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="caption" gutterBottom>
                    {asset.propertyIdSelect.address1}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    (Optional) When was this property placed in service?
                  </Typography>
                </Box>
                <KeyboardDatePicker
                  label="Date Placed in Service (Optional)"
                  format="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                  value={asset.placedInServiceDate}
                  onChange={handleKeyboardDatePickerChange('nested_asset_placedInServiceDate', this)}
                  margin="dense"
                  fullWidth
                  clearable
                />
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body2">
                    {`A property is generally considered "placed in service" when it is first listed for rent. 
                    In the first and last tax year that you are eligible to claim depreciation on a property 
                    the amount will be prorated based on the date the property was placed in service.`}
                  </Typography>
                </Box>
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Typography color="error">{error && error.message}</Typography>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Save Property
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'assetName':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processAssetName} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="h6" gutterBottom>
                    Please give the asset a name.
                  </Typography>
                </Box>
                <TextField
                  label="Asset Name"
                  fullWidth
                  required
                  margin="dense"
                  InputProps={{
                    value: asset.name,
                    name: 'nested_asset_name',
                    onChange: handleTextFieldChange(this),
                  }}
                />
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Next
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'selectAssetProperty':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processSelectAssetProperty} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="caption" gutterBottom>
                    {asset.name}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    (Optional) Select the property where this asset is located or used.
                  </Typography>
                </Box>
                <Autocomplete
                  options={propertyOptions}
                  getOptionLabel={addressLabel}
                  value={asset.propertyIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_asset_propertyIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Property (Optional)"
                      placeholder="Type to Search"
                      fullWidth
                    />
                  )}
                />
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Next
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'assetLifespan':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processAssetLifespan} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="caption" gutterBottom>
                    {asset.name}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    (Optional) What is the useful life of this asset?
                  </Typography>
                </Box>
                <FormControl margin="dense" fullWidth>
                  <InputLabel>
                    Useful Life (Optional)
                  </InputLabel>
                  <NumberFormat
                    value={asset.lifespan}
                    decimalScale={1}
                    suffix=" years"
                    onValueChange={handleNumberFormatChange('nested_asset_lifespan', this)}
                    customInput={Input}
                  />
                </FormControl>
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body2">
                    {`The useful life of an asset is defined by the IRS. 
                      It is the length of time during which you are eligible to claim a depreciation expense. 
                      For tangible assets, the useful life is a fixed length. 
                      For loan closing cost assets, enter the term of the loan.`}
                  </Typography>
                </Box>
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Next
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'assetPlacedInService':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.saveAsset} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="caption" gutterBottom>
                    {asset.name}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    (Optional) When was this asset placed in service?
                  </Typography>
                </Box>
                <KeyboardDatePicker
                  label="Date Placed in Service (Optional)"
                  format="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                  value={asset.placedInServiceDate}
                  onChange={handleKeyboardDatePickerChange('nested_asset_placedInServiceDate', this)}
                  margin="dense"
                  fullWidth
                  clearable
                />
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body2">
                    {`An asset is placed in service at the start of its useful life. 
                    In the first and last tax year that you are eligible to claim depreciation on an asset 
                    the amount will be prorated based on the date the asset was placed in service.`}
                  </Typography>
                </Box>
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Typography color="error">{error && error.message}</Typography>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Save Fixed Asset
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    const { isOpen } = this.props;
    const {
      loading, key,
    } = this.state;

    return (
      <>
        <Dialog
          open={isOpen}
          scroll="body"
          maxWidth="sm"
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
          onEnter={this.setInitialState}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {!loading && this.getDialogContent(key)}
        </Dialog>
      </>
    );
  }
}

AddFixedAssetDialog.contextType = PersonContext;

AddFixedAssetDialog.defaultProps = {
  onAddFixedAsset: () => {},
  openAssetPage: false,
};

AddFixedAssetDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddFixedAsset: PropTypes.func,
  openAssetPage: PropTypes.bool,
};

export default AddFixedAssetDialog;
