import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import history from '../history';
import { create, find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import {
  asyncHandleChange,
  asyncSetState,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine from '../components/FinancialAccountLine';

class OperatingCashFlow extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        entityId: null,
        propertyId: null,
        startDateSelect: null,
        endDateSelect: null,
        entityIdSelect: null,
        propertyIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis, organizationId } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const incomeQuery = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });

    const principalQuery = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
      journalType: ['Loan Payment'],
    });

    const escrowQuery = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type2: ['Escrow'],
    });

    const idParam = {};
    if (incomeQuery.propertyId) {
      idParam.propertyId = incomeQuery.propertyId;
    } else if (incomeQuery.entityId) {
      idParam.entityId = incomeQuery.entityId;
    }

    const mvQuery = {
      organizationId,
      reportName: 'sumMarketValue',
      ...idParam,
    };

    const loanTransactionsQuery = {
      organizationId,
      ...idParam,
      reportName: 'loanTransactionsSinceBalanceSummary',
    };

    const loansQuery = {
      organizationId,
      ...idParam,
      inactive: { $or: [null, false] },
      type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
      $limit: 100,
    };

    const accountsObject = {
      operatingRevenues: {
        accounts: [],
      },
      operatingExpenses: {
        accounts: [],
      },
      noi: {
        accounts: [],
      },
      interestExpenses: {
        accounts: [],
      },
      principalPaydown: {
        accounts: [],
      },
      debtService: {
        accounts: [],
      },
      escrows: {
        accounts: [],
      },
      allIncluded: {
        accounts: [],
      },
      excluded: {
        accounts: [],
      },
      byId: {},
    };
    // launch async calls
    await create(this, 'reports', incomeQuery)
      .then((result) => {
        /* eslint-disable no-param-reassign */
        result.forEach((account) => {
          account.display = account.netCredits !== 0;
          account.subaccounts = [];
          account.values = [account.netCredits];
          if (
            account.type2 === 'Investing Revenue'
            || account.type2 === 'Depreciation Expense'
          ) {
            accountsObject.excluded.accounts.push(account);
          } else if (account.type2 === 'Interest Expense') {
            accountsObject.interestExpenses.accounts.push(account);
            accountsObject.debtService.accounts.push(account);
            accountsObject.allIncluded.accounts.push(account);
          } else if (account.type === 'Expense') {
            accountsObject.operatingExpenses.accounts.push(account);
            accountsObject.noi.accounts.push(account);
            accountsObject.allIncluded.accounts.push(account);
          } else if (account.type === 'Revenue') {
            accountsObject.operatingRevenues.accounts.push(account);
            accountsObject.noi.accounts.push(account);
            accountsObject.allIncluded.accounts.push(account);
          }
          accountsObject.byId[account.id] = account;
        });
        /* eslint-enable no-param-reassign */
        return result;
      });

    await create(this, 'reports', principalQuery)
      .then((result) => {
        /* eslint-disable no-param-reassign */
        result.forEach((account) => {
          account.display = account.netCredits !== 0;
          account.subaccounts = [];
          account.values = [account.netCredits];

          accountsObject.principalPaydown.accounts.push(account);
          accountsObject.debtService.accounts.push(account);
          accountsObject.allIncluded.accounts.push(account);

          accountsObject.byId[account.id] = account;
        });
        /* eslint-enable no-param-reassign */
        return result;
      });

    await create(this, 'reports', escrowQuery)
      .then((result) => {
        /* eslint-disable no-param-reassign */
        result.forEach((account) => {
          account.display = account.netCredits !== 0;
          account.subaccounts = [];
          account.values = [account.netCredits];
          account.contributions = [-account.totalDebits];
          account.withdrawals = [account.totalCredits];

          accountsObject.escrows.accounts.push(account);
          accountsObject.allIncluded.accounts.push(account);

          accountsObject.byId[account.id] = account;
        });
        /* eslint-enable no-param-reassign */
        return result;
      });

    const marketValue = await create(this, 'reports', mvQuery);
    const annualScaler = moment(filter.endDate).add(1, 'days').diff(moment(filter.startDate), 'years', true);

    const loansPromise = find(this, 'accounts', {
      query: loansQuery,
    });

    const loanTransactionsSinceBalanceSummaryPromise = create(this, 'reports', loanTransactionsQuery);

    const loans = await loansPromise;
    const loanTransactionsSinceBalance = await loanTransactionsSinceBalanceSummaryPromise;

    let loanBalance = 0;
    const includedLoansById = {};

    loans.data.forEach((loan) => {
      if (loan.lastCurrentBalance) {
        loanBalance -= loan.lastCurrentBalance;
      }
      includedLoansById[loan.id] = loan;
    });

    loanTransactionsSinceBalance.forEach((loan) => {
      if (includedLoansById[loan.id]) {
        loanBalance -= loan.netCredits;
      }
    });

    const headers = [null];

    await asyncSetState({
      loading: false,
      headers,
      accountsObject,
      marketValue,
      annualScaler,
      loanBalance,
    }, this);
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  sumColumnValues = (accounts, property = 'values') => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account[property].forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  exportPdf = async () => {
    const { organizationId, accountingFirmId } = this.context;
    const { location } = this.props;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-pdf`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        jwt: localStorage.getItem('feathers-jwt'),
        organizationId,
        accountingFirmId,
        page: `${location.pathname}${location.search}`,
      }),
    })
      .then(async (resp) => {
        const blob = new Blob([await resp.blob()], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'net-income.pdf';
        document.body.appendChild(a);
        a.click();
      });
  }

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'net-operating-income.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      // {
      //  text: 'Export',
      //  action: (() => this.setState({ downloadDialog: true })),
      //  class: 'export'
      // },
    ]
  );

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      accountsObject, marketValue, annualScaler, loanBalance,
      filter, filterDialog, loading,
      downloadDialog,
    } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Cash on Cash"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property']}
          required={['dateRange', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          // exportPdf={this.exportPdf}
          // exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <>
                  <Box mx="auto" my="50px" maxWidth="600px">
                    <FinancialAccountLine
                      label="Cash Flow"
                      underline
                      overline
                      bold
                    />
                    <FinancialAccountLine
                      label="Net Operating Income"
                      values={this.sumColumnValues(accountsObject.noi.accounts)}
                      indent={1}
                    />
                    <FinancialAccountLine
                      label="Debt Service"
                      values={this.sumColumnValues(accountsObject.debtService.accounts)}
                      indent={1}
                    />
                    <FinancialAccountLine
                      label="Escrow Payments"
                      values={this.sumColumnValues(accountsObject.escrows.accounts)}
                      indent={1}
                    />
                    <FinancialAccountLine
                      label="Operating Cash Flow"
                      values={this.sumColumnValues(accountsObject.allIncluded.accounts)}
                      indent={1}
                      overline
                    />
                    <FinancialAccountLine
                      label="Annualized Operating Cash Flow"
                      values={[
                        this.sumColumnValues(accountsObject.allIncluded.accounts) / annualScaler,
                      ]}
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine
                      label="Cash Invested"
                      underline
                      overline
                      bold
                    />
                    <FinancialAccountLine
                      label="Estimated Market Value*"
                      values={[marketValue.marketValue]}
                      decimalScale={0}
                      indent={1}
                    />
                    <FinancialAccountLine
                      label="Outstanding Loans"
                      values={[loanBalance]}
                      decimalScale={0}
                      indent={1}
                    />
                    <FinancialAccountLine
                      label="Net Cash Invested"
                      values={[marketValue.marketValue + loanBalance]}
                      decimalScale={0}
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine
                      label="Annual Cash on Cash Return"
                      values={marketValue.marketValue && (marketValue.marketValue + loanBalance) > 0
                        ? [((this.sumColumnValues(accountsObject.allIncluded.accounts) / annualScaler) * 100) / (marketValue.marketValue + loanBalance)] : ['N/A']}
                      prefix=""
                      suffix="%"
                      underline
                      overline
                      bold
                    />
                  </Box>
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    // bgcolor="common.white"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="body2">
                      {`* During acquisition and the initial stages of an investment, cash invested is typically calculated by adding the down payment and cost of capital improvements. 
                        Using the estimated market value normalizes cash on cash for properties that have appreciated since purchase. It shows 
                        the ongoing annual cash on cash return of continuing to hold investments in your portfolio including their financing.`}
                    </Typography>
                  </Box>
                </>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

OperatingCashFlow.contextType = PersonContext;

OperatingCashFlow.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OperatingCashFlow;
