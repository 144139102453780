import React from 'react';
import PropTypes from 'prop-types';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import {
  handleAutocompleteChange,
  handleCheckboxChange,
  handleTextFieldChange,
} from '../functions/InputHandlers';

import DialogTemplate from './DialogTemplate';
import { getAccountTypeOptions, nameLabel } from './Autocomplete/Library';

class FilterChartOfAccountsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { filter: props.filter };
  }

  updateFilter = async (event) => {
    event.preventDefault();
    const { filter } = this.state;
    const { updateFilter, closeDialog } = this.props;

    filter.page = 0;
    updateFilter(filter);

    closeDialog();
  }

  render() {
    const { closeDialog } = this.props;
    const { filter } = this.state;

    return (
      <DialogTemplate
        open
        scroll="body"
        maxWidth="sm"
        fullWidth
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="filterChartOfAccountsDialog"
      >
        <form onSubmit={this.updateFilter}>
          <DialogTitle id="alert-dialog-title">Filter Accounts</DialogTitle>
          <DialogContent>
            <TextField
              label="Account Name"
              margin="dense"
              fullWidth
              InputProps={{
                value: filter.accountName,
                name: 'nested_filter_accountName',
                onChange: handleTextFieldChange(this),
              }}
            />
            <Autocomplete
              options={getAccountTypeOptions}
              getOptionLabel={nameLabel}
              value={filter.accountType}
              onChange={handleAutocompleteChange('nested_filter_accountType', this)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  margin="dense"
                  label="Account Type"
                  placeholder="Type to Search"
                  fullWidth
                />
              )}
            />
            <FormControl margin="none" fullWidth>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={filter.hideInactive}
                    onChange={handleCheckboxChange(this)}
                    name="nested_filter_hideInactive"
                  />
                )}
                label="Hide Inactive Accounts"
              />
            </FormControl>
            <FormControl margin="none" fullWidth>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={filter.hideDefaults}
                    onChange={handleCheckboxChange(this)}
                    name="nested_filter_hideDefaults"
                  />
                )}
                label="Hide System Defaults"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary" disableElevation>
              Update Filter
            </Button>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogTemplate>
    );
  }
}

FilterChartOfAccountsDialog.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default FilterChartOfAccountsDialog;
