import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { PersonContext } from '../contexts/PersonContext';

import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import LinkBase from '../components/LinkBase';

const styles = {
  grid: {
    width: '100%',
    margin: 0,
  },
  scheduledPaymentsCard: {
    width: '100%',
    overflowX: 'auto',
  },
  reportName: {
    display: 'block',
    textDecoration: 'none',
  },
};

class Reports extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    const { classes, match, theme } = this.props;
    const {
      loading,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Grid spacing={3} container className={classes.grid}>
        <PageHeader match={match} title="Reports" />
        <Grid item xs={12} sm={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Income
              </Typography>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/net-income" className={classes.reportName}>
                  Net Income (Profit & Loss)
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/net-income-by-property" className={classes.reportName}>
                  Net Income By Property
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/net-income-by-subportfolio" className={classes.reportName}>
                  Net Income By Sub-Portfolio
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/net-income-by-unit" className={classes.reportName}>
                  Net Income By Unit
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/net-income-by-period" className={classes.reportName}>
                  Net Income By Period
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/owner-statement" className={classes.reportName}>
                  Owner Statement
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/owner-statement-by-property" className={classes.reportName}>
                  Owner Statement By Property
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/rent-roll" className={classes.reportName}>
                  Rent Roll
                </LinkBase>
              </Box>
            </Box>
          </CardBase>
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Cash Flow
              </Typography>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/operating-cash-flow" className={classes.reportName}>
                  Operating Cash Flow
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/operating-cash-flow-by-property" className={classes.reportName}>
                  Operating Cash Flow By Property
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/operating-cash-flow-by-subportfolio" className={classes.reportName}>
                  Operating Cash Flow By Sub-Portfolio
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/operating-cash-flow-by-unit" className={classes.reportName}>
                  Operating Cash Flow By Unit
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/operating-cash-flow-by-period" className={classes.reportName}>
                  Operating Cash Flow By Period
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/cash-flows" className={classes.reportName}>
                  Cash Flow Statement
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/cash-flows-by-property" className={classes.reportName}>
                  Cash Flows By Property
                </LinkBase>
              </Box>
            </Box>
          </CardBase>
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Performance
              </Typography>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/net-operating-income" className={classes.reportName}>
                  Net Operating Income (NOI)
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/net-operating-income-by-property" className={classes.reportName}>
                  Net Operating Income By Property
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/net-operating-income-by-subportfolio" className={classes.reportName}>
                  Net Operating Income By Sub-Portfolio
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/net-operating-income-by-unit" className={classes.reportName}>
                  Net Operating Income By Unit
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/net-operating-income-by-period" className={classes.reportName}>
                  Net Operating Income By Period
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/cash-on-cash" className={classes.reportName}>
                  Cash on Cash
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/cap-rate" className={classes.reportName}>
                  Cap Rate
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/operating-expense-ratio" className={classes.reportName}>
                  Operating Expense Ratio
                </LinkBase>
              </Box>
            </Box>
          </CardBase>
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Assets
              </Typography>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/portfolio-value-by-property" className={classes.reportName}>
                  Portfolio Value By Property
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/balance-sheet" className={classes.reportName}>
                  Balance Sheet
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/balance-sheet-by-property" className={classes.reportName}>
                  Balance Sheet By Property
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/balance-sheet-by-subportfolio" className={classes.reportName}>
                  Balance Sheet By Sub-Portfolio
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/balance-sheet-by-period" className={classes.reportName}>
                  Balance Sheet By Period
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/fixed-assets" className={classes.reportName}>
                  Fixed Assets Schedule
                </LinkBase>
              </Box>
            </Box>
          </CardBase>
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Tax
              </Typography>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/tax-packet" className={classes.reportName}>
                  Tax Packet Export
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/tax-review" className={classes.reportName}>
                  Tax Review
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/schedule-e" className={classes.reportName}>
                  Schedule E
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/form-8825" className={classes.reportName}>
                  Form 8825
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/expenses-by-vendor" className={classes.reportName}>
                  Expenses By Vendor
                </LinkBase>
              </Box>
            </Box>
          </CardBase>
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Other
              </Typography>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/trial-balance" className={classes.reportName}>
                  Trial Balance
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/account-register" className={classes.reportName}>
                  Account Register
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/security-deposits-held" className={classes.reportName}>
                  Security Deposits Held
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/general-ledger" className={classes.reportName}>
                  General Ledger Export
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/mileage-by-property" className={classes.reportName}>
                  Mileage By Property
                </LinkBase>
              </Box>
              <Box width="100%" p={1} borderBottom={`1px solid ${theme.palette.divider}`}>
                <LinkBase to="/reports/attachments-export" className={classes.reportName}>
                  Attachments Export
                </LinkBase>
              </Box>
            </Box>
          </CardBase>
        </Grid>
      </Grid>
    );
  }
}

Reports.contextType = PersonContext;

Reports.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles, { withTheme: true })(Reports);
