import { createTheme } from '@material-ui/core/styles';

import { BREAKPOINTS, COMPONENTS, MATERIAL_COLORS, PALETTE, SHAPE, SPACING, TYPOGRAPHY } from './constants';

export default createTheme({
  breakpoints: { values: BREAKPOINTS },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: TYPOGRAPHY.fontWeight.semiBold,
      },
    },
    MuiInput: {
      underline: {
        '&$focused:after': {
          borderBottomColor: PALETTE.secondary.main,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid ${PALETTE.secondary.main}`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        'maxWidth': '100%',
        'display': 'block',
        'overflow': 'hidden',
        'paddingBottom': SPACING * 0.25,
        'textOverflow': 'ellipsis',
        'whiteSpace': 'nowrap',
        '&$shrink': {
          overflow: 'visible',
          textOverflow: 'clip',
        },
      },
    },
  },
  palette: PALETTE,
  shape: {
    borderRadius: SHAPE.borderRadius.sm,
  },
  spacing: SPACING,
  tokens: {
    breakpoints: BREAKPOINTS,
    components: COMPONENTS,
    materialColors: MATERIAL_COLORS,
    palette: PALETTE,
    shape: SHAPE,
    spacing: SPACING,
    typography: TYPOGRAPHY,
  },
  typography: {
    body1: {
      fontSize: TYPOGRAPHY.fontSize.body1,
      lineHeight: TYPOGRAPHY.lineHeight.body1,
    },
    body2: {
      fontSize: TYPOGRAPHY.fontSize.body2,
      lineHeight: TYPOGRAPHY.lineHeight.body2,
    },
    caption: {
      fontSize: TYPOGRAPHY.fontSize.caption,
      lineHeight: TYPOGRAPHY.lineHeight.caption,
    },
    fontFamily: TYPOGRAPHY.fontFamily.primary,
    fontSize: TYPOGRAPHY.fontSize.body2,
    fontWeightBold: TYPOGRAPHY.fontWeight.bold,
    fontWeightLight: TYPOGRAPHY.fontWeight.light,
    fontWeightMedium: TYPOGRAPHY.fontWeight.semiBold,
    fontWeightRegular: TYPOGRAPHY.fontWeight.regular,
    h1: {
      fontFamily: TYPOGRAPHY.fontFamily.headers,
      fontSize: TYPOGRAPHY.fontSize.h1,
      fontWeight: TYPOGRAPHY.fontWeight.semiBold,
      lineHeight: TYPOGRAPHY.lineHeight.h1,
    },
    h2: {
      fontFamily: TYPOGRAPHY.fontFamily.headers,
      fontSize: TYPOGRAPHY.fontSize.h2,
      fontWeight: TYPOGRAPHY.fontWeight.semiBold,
      lineHeight: TYPOGRAPHY.lineHeight.h2,
    },
    h3: {
      fontFamily: TYPOGRAPHY.fontFamily.headers,
      fontSize: TYPOGRAPHY.fontSize.h3,
      fontWeight: TYPOGRAPHY.fontWeight.semiBold,
      lineHeight: TYPOGRAPHY.lineHeight.h3,
    },
    h4: {
      fontFamily: TYPOGRAPHY.fontFamily.headers,
      fontSize: TYPOGRAPHY.fontSize.h4,
      fontWeight: TYPOGRAPHY.fontWeight.semiBold,
      lineHeight: TYPOGRAPHY.lineHeight.h4,
    },
    h5: {
      fontFamily: TYPOGRAPHY.fontFamily.headers,
      fontSize: TYPOGRAPHY.fontSize.h5,
      fontWeight: TYPOGRAPHY.fontWeight.semiBold,
      lineHeight: TYPOGRAPHY.lineHeight.h5,
    },
    h6: {
      fontFamily: TYPOGRAPHY.fontFamily.headers,
      fontSize: TYPOGRAPHY.fontSize.h6,
      fontWeight: TYPOGRAPHY.fontWeight.semiBold,
      lineHeight: TYPOGRAPHY.lineHeight.h6,
    },
    overline: {
      fontSize: TYPOGRAPHY.fontSize.overline,
      lineHeight: TYPOGRAPHY.lineHeight.overline,
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontSize: TYPOGRAPHY.fontSize.subtitle1,
      fontWeight: TYPOGRAPHY.fontWeight.semiBold,
      lineHeight: TYPOGRAPHY.lineHeight.subtitle1,
    },
    subtitle2: {
      fontSize: TYPOGRAPHY.fontSize.subtitle2,
      fontWeight: TYPOGRAPHY.fontWeight.semiBold,
      lineHeight: TYPOGRAPHY.lineHeight.subtitle2,
    },
  },
});
