import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';

import EditIcon from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
// import { Link as RouterLink } from 'react-router-dom';

import { PersonContext } from '../../../contexts/PersonContext';

import { find } from '../../../feathersWrapper';

import ErrorCard from '../../../components/ErrorCard';
import CardBase from '../../../components/CardBase';
import CardTitle from '../../../components/CardTitle';
import FilterLoginsDialog from '../../../components/FilterLoginsDialog';

import {
  asyncHandleChange,
} from '../../../functions/InputHandlers';
import EditOrganizationDialog from '../components/EditOrganizationDialog';

const styles = (theme) => ({
  actionButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
});

class Logins extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        userId: null,
        userIdSelect: null,
        startDate: null,
        startDateSelect: null,
        endDate: null,
        endDateSelect: null,
        organizationId: null,
        page: 0,
      },
      filterLoginsDialog: false,
      pageSize: 8,
      loading: true,
      editOrganizationDialog: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { filter, pageSize } = this.state;
    const query = {
      $limit: pageSize,
      $skip: filter.page * pageSize,
      $sort: {
        id: -1,
      },
    };

    if (filter.userId !== null) {
      query.userId = filter.userId;
    }

    if (filter.startDate !== null) {
      query.createdAt = { $gte: filter.startDate };
    }

    if (filter.endDate !== null) {
      if (query.createdAt) {
        query.createdAt.$lte = filter.endDate;
      } else {
        query.createdAt = { $lte: filter.endDate };
      }
    }

    if (filter.organizationId !== null) {
      query.organizationId = filter.organizationId;
    }

    const result = await find(this, 'logins', { query });
    this.setState({
      data: result.data,
      total: result.total,
      loading: false,
    });
  };

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getData();
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getData();
  };

  actionButtons = () => (
    [
      { class: 'filter', action: (() => this.setState({ filterLoginsDialog: true })) },
    ]
  );

  render() {
    const {
      loading, error, data, total,
      filterLoginsDialog, filter, pageSize,
      editOrganizationDialog, item,
    } = this.state;
    const { setOrganizationId } = this.context;
    const { classes } = this.props;

    if (loading) {
      return (
        <Grid item xs={12} sm={8} md={8}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <CardTitle title="Logins" />
            </Box>
          </CardBase>
        </Grid>
      );
    }

    if (error) {
      return <ErrorCard error={error.message} />;
    }

    return (
      <>
        {editOrganizationDialog && (
        <EditOrganizationDialog
          isOpen={editOrganizationDialog}
          closeDialog={() => this.setState({ editOrganizationDialog: false })}
          login={item}
        />
        )}
        <Grid item xs={12} sm={8} md={8}>
          <FilterLoginsDialog
            filter={filter}
            isOpen={filterLoginsDialog}
            closeDialog={() => this.setState({ filterLoginsDialog: false })}
            updateFilter={this.updateFilter}
          />
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <CardTitle title="Logins" actionButtons={this.actionButtons()} />
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Login
                    </TableCell>
                    <TableCell>
                      Time
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((login) => (
                    <TableRow key={login.id}>
                      <TableCell>
                        <Typography variant="body2" gutterBottom>
                          {`${login.organization.name} - ${login.organization.id}`}
                          <Button
                            className={classes.actionButton}
                            aria-label="imported"
                            onClick={() => { setOrganizationId(login.organizationId); }}
                          >
                            <LaunchIcon fontSize="small" />
                          </Button>
                          <Button
                            className={classes.actionButton}
                            aria-label="imported"
                            onClick={() => {
                              this.setState({
                                editOrganizationDialog: true, item: login,
                              });
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </Button>
                        </Typography>
                        <Typography variant="caption" component="div">
                          {`(${login.user.person.firstName} ${login.user.person.lastName}) (${login.user.person.email}) (${login.user.person.phone})`}
                        </Typography>
                        {login.user.loginCount && (
                        <Typography variant="caption" component="div">
                          {`(${login.user.loginCount} logins)`}
                        </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(login.createdAt).format('HH:mm:ss a on M/D/YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={total}
                      page={filter.page}
                      rowsPerPage={pageSize}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[pageSize]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Box>
          </CardBase>
        </Grid>
      </>
    );
  }
}

Logins.contextType = PersonContext;

Logins.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  // match: PropTypes.objectOf(PropTypes.any).isRequired,
  // theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(Logins);
