import React, { useContext, useEffect, useState } from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Check } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

import { annualPlanOptions, planOptions } from '~/constants/buy-it-now';
import { create, patch } from '~/feathersFunctionalWrapper';

import { PersonContext } from '../contexts/PersonContext';

import Button from './Button';

const styles = makeStyles((theme) => ({
  dialogContentContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    width: '100%',
    backgroundColor: '#FAFAFA',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  autocomplete: {
    backgroundColor: 'white',
  },
  autoCompleteContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  autocompleteCaption: {
    color: theme.palette.text.secondary,
    marginLeft: '14px',
    marginBottom: theme.spacing(2),
  },
  subscriptionTotalCard: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    border: '1px solid #C4C4C4',
    borderRadius: '10px',
    marginBottom: '24px',
    backgroundColor: 'white',
  },
  subscriptionLineContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  subscriptionLineLeft: {
    marginLeft: 0,
    marginRight: 'auto',
  },
  subscriptionLineRight: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  totalTextContainer: {
    color: '#441c5d',
    fontWeight: 'bold',
  },
  savingsBox: {
    backgroundColor: '#EDF7ED',
    padding: theme.spacing(1.5),
    borderRadius: '4px',
    color: '#1E4620',
  },
  bulletsContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    gap: theme.spacing(1),
  },
  bulletContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  dialogButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
  dialogButtonsSecondRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    maxWidth: '400px',
    width: '100%',
    margin: 'auto',
  },
}));

export default function UpdateSubscriptionDialog({
  isOpen,
  closeDialog,
  newUnitCount,
  isFromAddProperty,
  isFromAddUnit,
}) {
  const classes = styles();
  const context = useContext(PersonContext);
  const { organizationId, subscriptionItem, setOrganizationId } = context;

  const [step, setStep] = useState(null);
  const [planSelect, setPlanSelect] = useState(null);
  const [unitCount, setUnitCount] = useState(null);
  const [plans, setPlans] = useState([]);
  const [error, setError] = useState(null);
  const [savings, setSavings] = useState(0);
  const [total, setTotal] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const isAnnualPlan = subscriptionItem.interval === 'year';
  const planPeriod = isAnnualPlan ? 'year' : 'month';

  useEffect(() => {
    const getData = async () => {
      if (step === null) {
        let units = await create('reports', {
          organizationId,
          reportName: 'unitCount',
        });

        units += newUnitCount;

        let newPlanSelect;
        let newPlanOptions;
        if (subscriptionItem.interval === 'year') {
          newPlanOptions = annualPlanOptions;
        } else {
          newPlanOptions = planOptions;
        }

        if (units > 20) {
          newPlanSelect = newPlanOptions.find((option) => option.maxUnits === 'unlimited');
        } else if (units > 10) {
          newPlanSelect = newPlanOptions.find((option) => option.maxUnits === 20);
        } else if (units > 3) {
          newPlanSelect = newPlanOptions.find((option) => option.maxUnits === 10);
        } else {
          newPlanSelect = newPlanOptions.find((option) => option.maxUnits === 3);
        }

        setPlanSelect(newPlanSelect);
        setUnitCount(units);
        setPlans(newPlanOptions);
        setStep('select');
      }
    };
    getData();
  }, []);

  useEffect(() => {
    setPlans(isAnnualPlan ? annualPlanOptions : planOptions);
    if (planSelect) {
      setPlanSelect(
        isAnnualPlan
          ? annualPlanOptions.find((plan) => plan.name === planSelect.name)
          : planOptions.find((plan) => plan.name === planSelect.name),
      );
      setSavings(isAnnualPlan ? (planSelect.price * 0.4).toFixed(2) : 0);
      setTotal(isAnnualPlan ? (planSelect.price - planSelect.price * 0.4).toFixed(2) : planSelect.price.toFixed(2));
    }
  }, [isAnnualPlan, planSelect]);

  const updateSubscription = async () => {
    if (planSelect.maxUnits !== undefined && unitCount > planSelect.maxUnits) {
      setError({
        message: `You ${newUnitCount ? 'will ' : ''}have ${unitCount} active units in REI Hub. Please select a plan that includes enough units.`,
      });
      return;
    }

    setSubmitting(true);

    try {
      await patch('stripe-subscriptions', organizationId, {
        plan: planSelect.id,
        maxUnits: planSelect.maxUnits,
      });
      setOrganizationId(organizationId);
      setSubmitting(false);
      setStep('thanks');
    } catch (subscriptionErr) {
      setError({
        message: `Your subscription could not be updated - please contact us. Message: ${subscriptionErr.message}`,
      });
      setSubmitting(false);
    }
  };

  const close = () => {
    setStep(null);
    setPlanSelect(null);
    closeDialog();
  };

  const getHeaderText = () => {
    let headerText = 'Update your subscription';
    if (isFromAddProperty) {
      headerText = 'Update your subscription to add this property';
    }
    if (isFromAddUnit) {
      headerText = 'Update your subscription to add this unit';
    }
    return headerText;
  };

  const getDialogContent = () => {
    switch (step) {
      case 'select':
        return (
          <>
            <Box className={classes.messageContainer}>
              <Typography variant="h5">{getHeaderText()}</Typography>
              <Box className={classes.bodyContainer}>
                <Typography variant="body1">
                  We will credit you the pro-rated amount for the time unused in your current plan and then subscribe
                  you to this new plan.
                </Typography>
                <Typography variant="body1">Your billing cycle will be reset today</Typography>
              </Box>
            </Box>
            <Box className={classes.autoCompleteContainer}>
              <Autocomplete
                options={plans}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => `${option.units} Units`}
                value={planSelect}
                onChange={(_event, newValue) => setPlanSelect(newValue)}
                disableClearable
                variant="outlined"
                size="medium"
                className={classes.autocomplete}
                renderInput={(params) => <TextField {...params} label="Plan Tier" fullWidth variant="outlined" />}
              />
              <Box className={classes.autocompleteCaption}>
                <Typography variant="caption">Based on the number of units in your portfolio.</Typography>
              </Box>
              <Box className={classes.subscriptionTotalCard}>
                <Box className={classes.subscriptionLineContainer}>
                  <Box className={classes.subscriptionLineLeft}>
                    <Typography variant="subtitle2">{`${isAnnualPlan ? 'Annual' : 'Monthly'} Plan`}</Typography>
                  </Box>
                  <Typography variant="subtitle2" className={classes.subscriptionLineRight}>
                    {`$${planSelect.price}.00/${planPeriod}`}
                  </Typography>
                </Box>
                {isAnnualPlan ? (
                  <Box className={classes.subscriptionLineContainer}>
                    <Box className={classes.subscriptionLineLeft}>
                      <Typography variant="body2" className={classes.semiBoldText}>
                        40% Annual Discount
                      </Typography>
                    </Box>
                    <Typography variant="subtitle2" className={classes.subscriptionLineRight}>
                      {`-$${savings}`}
                    </Typography>
                  </Box>
                ) : null}
                <Divider />
                <Box className={classes.subscriptionLineContainer}>
                  <Box className={classes.subscriptionLineLeft}>
                    <Typography variant="subtitle2" className={classes.semiBoldText}>
                      Your Total
                    </Typography>
                  </Box>
                  <Box className={classes.subscriptionLineRight}>
                    <Typography variant="subtitle2" className={classes.totalTextContainer}>
                      {`$${total}/${planPeriod}`}
                    </Typography>
                  </Box>
                </Box>
                {isAnnualPlan ? (
                  <Box className={classes.savingsBox}>
                    <Typography variant="subtitle2">{`You are saving a total of $${savings}`}</Typography>
                  </Box>
                ) : null}
                <Box className={classes.bulletsContainer}>
                  <Box className={classes.bulletContainer}>
                    <Check fontSize="small" />
                    <Typography variant="body2">{`Billed ${isAnnualPlan ? 'yearly' : 'monthly'}`}</Typography>
                  </Box>
                  <Box className={classes.bulletContainer}>
                    <Check fontSize="small" />
                    <Typography variant="body2">Cancel anytime</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {error && (
              <Typography variant="body1" color="error">
                {error.message}
              </Typography>
            )}
            <Box className={classes.dialogButtonsContainer}>
              <Button
                disabled={submitting}
                hasFullWidth
                variant="contained"
                onClick={() => {
                  updateSubscription();
                }}
              >
                Update My Plan
              </Button>
              <Button
                hasFullWidth
                variant="text"
                onClick={() => {
                  close();
                }}
              >
                Not Right Now
              </Button>
            </Box>
          </>
        );
      case 'thanks':
        return (
          <>
            <Box className={classes.messageContainer}>
              <Typography variant="h5">Thank you.</Typography>
              <Box className={classes.bodyContainer}>
                <Typography variant="body1">We have successfully updated your subscription.</Typography>
              </Box>
            </Box>
            <Box className={classes.dialogButtonsContainer}>
              <Button
                hasFullWidth
                variant="contained"
                onClick={() => {
                  close();
                }}
              >
                Close
              </Button>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          close();
        }
      }}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.dialogContentContainer}>{getDialogContent()}</Box>
    </Dialog>
  );
}

UpdateSubscriptionDialog.defaultProps = {
  newUnitCount: 0,
  isFromAddProperty: false,
  isFromAddUnit: false,
};

UpdateSubscriptionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  newUnitCount: PropTypes.number,
  isFromAddProperty: PropTypes.bool,
  isFromAddUnit: PropTypes.bool,
};
