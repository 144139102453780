import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import history from '../history';
import { PersonContext } from '../contexts/PersonContext';

import {
  handleKeyboardDatePickerChange,
  handleCheckboxChange,
} from '../functions/InputHandlers';

import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';

class TaxPacket extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dialog: true,
      step: 'welcome',
      filterError: null,
      filter: {
        taxYear: moment().subtract(1, 'years').format('YYYY'),
        taxYearSelect: moment().subtract(1, 'years'),
        includeNetIncomeByProperty: true,
        includeFixedAssetSchedule: true,
        includeBalanceSheet: false,
        includeMileageLog: true,
        includeScheduleE: true,
        includeForm8825: false,
        scheduleEProration: true,
        scheduleEMileage: true,
        form8825Proration: true,
        form8825Mileage: true,
      },
    };
  }

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ dialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  goToReview = () => {
    history.replace('/reports/tax-review');
  }

  startExport = (event) => {
    event.preventDefault();
    const { filter } = this.state;
    if (
      !(filter.includeNetIncomeByProperty
      || filter.includeFixedAssetSchedule
      || filter.includeBalanceSheet
      || filter.includeMileageLog
      || filter.includeScheduleE
      || filter.includeForm8825
      )
    ) {
      this.setState({ filterError: { message: 'Please select at least one report to include.' } });
      return;
    }
    this.setState({ step: 'working' });
    this.exportZip();
  }

  exportZip = async () => {
    const { organizationId, basis, organizationName } = this.context;
    const { location } = this.props;
    const { filter } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-zip`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        basis,
        page: `${location.pathname}`,
        filter,
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        const blob = new Blob([await resp.blob()], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${organizationName} ${filter.taxYearSelect.format('YYYY')} REI Hub Tax Packet.zip`;
        document.body.appendChild(a);
        a.click();
        this.setState({ dialog: false, loading: false });
      });
  }

  render() {
    const { match } = this.props;
    const {
      filter, dialog, filterError, step, loading,
    } = this.state;

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Tax Packet"
        />
        <Dialog
          open={dialog}
          scroll="body"
          maxWidth="sm"
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
        >
          {step === 'welcome' && (
            <>
              <DialogTitle id="alert-dialog-title">Export Tax Packet</DialogTitle>
              <DialogContent>
                <Typography variant="body1" gutterBottom>
                  {`Your tax packet exports the information from your portfolio that you or your tax preparer may need to file your return. 
                    Before running the export, please consider running a Tax Review to check for commonly overlooked items that can help you maximize your deductions and file a correct return.`}
                </Typography>
                <Box my={2}>
                  <ButtonGroup fullWidth orientation="vertical">
                    <Button
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      size="large"
                      onClick={this.goToReview}
                    >
                      Run Tax Review
                    </Button>
                    <Button
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      size="large"
                      onClick={() => this.setState({ step: 'criteria' })}
                    >
                      Continue to Export
                    </Button>
                  </ButtonGroup>
                </Box>
              </DialogContent>
            </>
          )}
          {step === 'criteria' && (
            <form onSubmit={this.startExport}>
              <DialogTitle id="alert-dialog-title">Export Tax Packet</DialogTitle>
              <DialogContent>
                <KeyboardDatePicker
                  label="Tax Year"
                  views={['year']}
                  format="YYYY"
                  placeholder="YYYY"
                  value={filter.taxYearSelect}
                  onChange={handleKeyboardDatePickerChange('nested_filter_taxYear', this)}
                  required
                  fullWidth
                />
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Included Information
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={filter.includeNetIncomeByProperty} onChange={handleCheckboxChange(this)} name="nested_filter_includeNetIncomeByProperty" />}
                      label="Net Income by Property"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filter.includeFixedAssetSchedule} onChange={handleCheckboxChange(this)} name="nested_filter_includeFixedAssetSchedule" />}
                      label="Fixed Asset Schedule"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filter.includeMileageLog} onChange={handleCheckboxChange(this)} name="nested_filter_includeMileageLog" />}
                      label="Mileage Log"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filter.includeScheduleE} onChange={handleCheckboxChange(this)} name="nested_filter_includeScheduleE" />}
                      label="Schedule E Reports"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filter.includeForm8825} onChange={handleCheckboxChange(this)} name="nested_filter_includeForm8825" />}
                      label="Form 8825 Reports"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filter.includeBalanceSheet} onChange={handleCheckboxChange(this)} name="nested_filter_includeBalanceSheet" />}
                      label="Balance Sheet"
                    />
                  </FormGroup>
                </Box>
                {filter.includeScheduleE && (
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="subtitle2" gutterBottom>
                      Schedule E Settings
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={filter.scheduleEProration} onChange={handleCheckboxChange(this)} name="nested_filter_scheduleEProration" />}
                        label="Include prorated portfolio transactions not assigned to a property"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={filter.scheduleEMileage} onChange={handleCheckboxChange(this)} name="nested_filter_scheduleEMileage" />}
                        label="Include mileage expenses from the mileage log"
                      />
                    </FormGroup>
                  </Box>
                )}
                {filter.includeForm8825 && (
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="subtitle2" gutterBottom>
                      Form 8825 Report Settings
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={filter.form8825Proration} onChange={handleCheckboxChange(this)} name="nested_filter_form8825Proration" />}
                        label="Include prorated portfolio transactions not assigned to a property"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={filter.form8825Mileage} onChange={handleCheckboxChange(this)} name="nested_filter_form8825Mileage" />}
                        label="Include mileage expenses from the mileage log"
                      />
                    </FormGroup>
                  </Box>
                )}
                <Typography color="error">{filterError && filterError.message}</Typography>
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary" disableElevation>
                  Start Export
                </Button>
                <Button onClick={this.closeFilter} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </form>
          )}
          {step === 'working' && (
            <>
              <DialogTitle id="alert-dialog-title">Working</DialogTitle>
              <DialogContent>
                <Typography variant="body1" align="center" gutterBottom>
                  We are preparing your tax packet. Your export will begin shortly.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeFilter} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
        {!loading && (
          <Grid item xs={12}>
            <CardBase>
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" align="center" gutterBottom>
                    Export complete
                  </Typography>
                  <Typography variant="body1" align="center" gutterBottom>
                    {`Your ${filter.taxYearSelect.format('YYYY')} tax packet has been downloaded to your computer as a .zip archive. 
                    You should provide this file to your tax preparer or open it to view its reports.`}
                  </Typography>
                  <Box mt={2}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => this.goToReports()}
                    >
                      Back to Reports
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </CardBase>
          </Grid>
        )}
      </PageGrid>
    );
  }
}

TaxPacket.contextType = PersonContext;

TaxPacket.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TaxPacket;
