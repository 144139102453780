import React, { useContext, useEffect, useState } from 'react';
import Script from 'react-load-script';
import NumberFormat from 'react-number-format';
import { Card, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PlaceIcon from '@material-ui/icons/Place';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import PropTypes from 'prop-types';
import generateHash from 'random-hash';
import uploadcare from 'uploadcare-widget';
import url from 'url';

import { PersonContext } from '../contexts/PersonContext';
import { create } from '../feathersWrapper';
import { useSegmentTrack } from '../functions/SegmentFunctions';

import { getStateCodeOptions, nameLabel } from './Autocomplete/Library';
import Button from './Button';
import UpdateSubscriptionDialog from './UpdateSubscriptionDialog';

const ONBOARDING_ADD_PROPERTY_SEGMENT_LOCATION = 'Onboarding - Add Properties: Property Type';
const ADD_PROPERTY_SEGMENT_LOCATION = 'Add Property: Property Type';

const styles = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: 0,
  },
  stateSelect: {
    marginTop: '4px',
  },
  imagePaper: {
    padding: '0',
    backgroundColor: theme.palette.grey['200'],
  },
  noImageDiv: {
    paddingTop: 'calc(((100% * 3 / 7) - 72px)/2)',
    paddingBottom: 'calc(((100% * 3 / 7) - 72px)/2)',
    borderRadius: theme.shape.borderRadius,
    fontSize: '72px',
    display: 'flex',
    justifyContent: 'center',
  },
  rentalImage: {
    borderRadius: theme.shape.borderRadius,
    display: 'block',
  },
  labels: {
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '13.72px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
  },
  onboardingCard: {
    width: '100%',
    maxWidth: '600px',
  },
}));

export default function AddPropertyDialog({ isOpen, closeDialog, onAddProperty }) {
  const classes = styles();
  const context = useContext(PersonContext);
  const { adminLogin, organizationId, partnerName, partnerSubscribed, showWelcome, subscriptionMaxUnits } = context;
  const uploadcareWidget = React.createRef();
  const tracking = useSegmentTrack();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [key, setKey] = useState(null);
  const [updateSubscriptionDialog, setUpdateSubscriptionDialog] = useState(false);
  const [defaultImage, setDefaultImage] = useState(null);
  const [property, setProperty] = useState({
    unitSelection: '',
    unitCount: 1,
    address1: '',
    address2: '',
    city: '',
    state: '',
    stateSelect: null,
    zip: '',
    image: '',
    entityIdSelect: null,
    inactive: false,
    rentalType: 'LONG_TERM',
  });
  const [units, setUnits] = useState(null);
  const [isAutocompleted, setIsAutocompleted] = useState(false);
  const [isAutocompleteTouched, setIsAutocompleteTouched] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (adminLogin) {
      setKey('unitSelection');
    } else {
      setKey(partnerSubscribed ? 'partnerSubscribed' : 'unitSelection');
    }
    setIsLoading(false);
  }, [adminLogin, partnerSubscribed]);

  const placeChanged = () => {
    // Get the place details from the autocomplete object.
    const place = window.autocomplete.getPlace();

    if (place && place.address_components) {
      setIsAutocompleted(true);
      const streetNumber = place.address_components.find((component) => component.types[0] === 'street_number');
      const street = place.address_components.find((component) => component.types[0] === 'route');
      let city = place.address_components.find((component) => component.types[0] === 'locality');
      if (!city) {
        city = place.address_components.find((component) => component.types[0] === 'neighborhood');
      }
      const state = place.address_components.find((component) => component.types[0] === 'administrative_area_level_1');
      const zip = place.address_components.find((component) => component.types[0] === 'postal_code');

      setProperty((prevProperty) => ({
        ...prevProperty,
        address1: `${streetNumber.short_name} ${street.short_name}`,
        city: city.short_name,
        stateSelect: { name: state.short_name, id: state.short_name },
        zip: zip.short_name,
      }));
    }
  };

  const onAutocompleteScriptLoaded = () => {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    window.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
      types: ['geocode'],
    });

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    window.autocomplete.setFields(['address_component']);

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    window.autocomplete.addListener('place_changed', () => placeChanged());
  };

  const autocompleteFocus = () => {
    // handleChange('isAutocompleteTouched', true, this);
    setIsAutocompleteTouched(true);
    const element = document.getElementById('autocomplete');
    element.setAttribute('autocomplete', 'new-password');
  };

  const autocompleteBlur = () => {
    if (property.address1 === '') {
      setIsAutocompleteTouched(false);
    }
    const elements = document.getElementsByClassName('pac-container');
    // manually hide the suggestions in case of autocomplete api error
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].style.display = 'none';
    }
  };

  const loadDefaultImage = () => {
    if (!property.image) {
      const urlString = url.format({
        protocol: 'https',
        hostname: 'maps.googleapis.com',
        pathname: 'maps/api/streetview',
        query: {
          size: '640x275',
          location: `${property.address1} ${property.zip}`,
          key: 'AIzaSyDl2J7lBbyTlDlZiBssp6W8DppMcuTMKhM',
          return_error_code: true,
        },
      });

      const file = uploadcare.fileFrom('url', urlString);

      if (file) {
        return new Promise((resolve) => {
          file
            .done((fileInfo) => {
              setDefaultImage(fileInfo.cdnUrl);
              resolve();
            })
            .fail(() => {
              resolve();
            });
        });
      }
    }
  };

  const processUnitSelection = (event) => {
    event.preventDefault();
    const newUnits = [];
    if (property.unitCount === 1) {
      property.unitSelection = 'single';
    } else {
      property.unitSelection = 'multi';
      for (let i = 1; i <= property.unitCount; i += 1) {
        newUnits.push({ organizationId, name: `Unit ${i}`, key: generateHash() });
      }
    }

    setUnits(newUnits);
    setKey('address');
  };

  const checkSubscription = async () => {
    const unitCount = await create(this, 'reports', {
      organizationId,
      reportName: 'unitCount',
    });

    if (subscriptionMaxUnits && unitCount + property.unitCount > subscriptionMaxUnits) {
      setKey('updateSubscriptionPrompt');
    } else if (property.unitSelection === 'multi') {
      setKey('unitNames');
    } else {
      setKey('marketValue');
    }
    setIsSubmitting(false);
  };

  const processAddress = async (event) => {
    event.preventDefault();
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    property.state = property.stateSelect.id;
    if (process.env.NODE_ENV === 'production') {
      const batchData = await create(this, 'batch-data', {
        address1: property.address1,
        address2: property.address2,
        city: property.city,
        state: property.state,
        zip: property.zip,
      });
      property.batchData = batchData.record;
      property.marketValue = batchData.estimatedValue;
    }
    setProperty(property);
    // try to fetch default image if the operation completes before advancing to the image screen
    loadDefaultImage();
    checkSubscription();
  };

  const processUnitNames = (event) => {
    event.preventDefault();
    setKey('marketValue');
  };

  const processMarketValue = (event) => {
    event.preventDefault();
    if (defaultImage) {
      setProperty({ ...property, image: defaultImage });
    }
    setKey('image');
  };

  const saveProperty = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    const propertySubmit = { ...property };
    propertySubmit.organizationId = organizationId;
    propertySubmit.units = units;

    try {
      await create(this, 'properties', propertySubmit);
      onAddProperty();
      closeDialog();
    } catch (err) {
      setError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const closeAddPropertyDialog = () => {
    setIsLoading(true);
    setKey(null);
    closeDialog();
  };

  const getDialogContent = () => {
    switch (key) {
      case 'unitSelection':
        return (
          <DialogContent>
            <form onSubmit={processUnitSelection}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  How many rental units are in this property?
                </Typography>
              </Box>
              <Box mb={2}>
                <FormControl margin="dense" fullWidth>
                  <InputLabel required>Number of Units</InputLabel>
                  <NumberFormat
                    value={property.unitCount}
                    required
                    thousandSeparator
                    decimalScale={0}
                    fixedDecimalScale
                    onValueChange={(value) => {
                      const { floatValue } = value;
                      setProperty({ ...property, unitCount: floatValue });
                    }}
                    customInput={Input}
                    isAllowed={(values) => {
                      const { formattedValue, floatValue } = values;
                      return formattedValue === '' || (floatValue <= 400 && floatValue > 0);
                    }}
                  />
                </FormControl>
                <Typography className={classes.labels}>
                  Setting up a property with multiple units enables you to track and view an additional, unit-by-unit
                  layer of data.
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">What best describes this property?</Typography>
                <Box marginLeft={1}>
                  <RadioGroup
                    aria-label="rental type"
                    name="nested_property_rentalType"
                    value={property.rentalType}
                    onChange={(event) => setProperty({ ...property, rentalType: event.target.value })}
                    required
                  >
                    <FormControlLabel
                      value="LONG_TERM"
                      control={<Radio required />}
                      label="Long-term rental (There is a lease, rent is paid monthly, etc.)"
                    />
                    <FormControlLabel
                      value="SHORT_TERM"
                      control={<Radio required />}
                      label="Short-term rental (Renting it via Airbnb, VRBO, etc.)"
                    />
                    <FormControlLabel value="REHAB_FLIP" control={<Radio required />} label="Rehab or flip" />
                    <FormControlLabel value="NOT_SURE" control={<Radio required />} label="Not sure yet" />
                    <FormControlLabel value="OTHER" control={<Radio required />} label="Other" />
                  </RadioGroup>
                </Box>
              </Box>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <Button
                  hasFullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    tracking('next clicked', {
                      location: showWelcome ? ONBOARDING_ADD_PROPERTY_SEGMENT_LOCATION : ADD_PROPERTY_SEGMENT_LOCATION,
                    });
                  }}
                >
                  Next
                </Button>
                <Box mt={1}>
                  <MuiButton
                    color="secondary"
                    size="large"
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      tracking('cancel clicked', {
                        location: showWelcome
                          ? ONBOARDING_ADD_PROPERTY_SEGMENT_LOCATION
                          : ADD_PROPERTY_SEGMENT_LOCATION,
                      });
                      closeAddPropertyDialog();
                    }}
                  >
                    Cancel
                  </MuiButton>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'address':
        return (
          <DialogContent>
            <form onSubmit={processAddress}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  What is the property&apos;s address?
                </Typography>
              </Box>
              {!isAutocompleted && (
                <>
                  <TextField
                    id="autocomplete"
                    label="Street Address"
                    placeholder=""
                    fullWidth
                    required
                    type="text"
                    autoComplete="off"
                    margin="dense"
                    onBlur={autocompleteBlur}
                    onFocus={autocompleteFocus}
                    InputProps={{
                      value: property.address1,
                      onChange: (event) => {
                        setProperty({ ...property, address1: event.target.value });
                      },
                    }}
                    InputLabelProps={!isAutocompleteTouched ? { shrink: false } : {}}
                  />
                  <Script
                    url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDl2J7lBbyTlDlZiBssp6W8DppMcuTMKhM&libraries=places"
                    onLoad={onAutocompleteScriptLoaded}
                  />
                </>
              )}
              {isAutocompleted && (
                <TextField
                  label="Street Address"
                  fullWidth
                  type="text"
                  autoComplete="off"
                  required
                  margin="dense"
                  InputProps={{
                    value: property.address1,
                    onChange: (event) => {
                      setProperty({ ...property, address1: event.target.value });
                    },
                  }}
                />
              )}
              {property.unitSelection === 'single' && (
                <TextField
                  label="Apt, Fl, Ste, Etc. (Optional)"
                  fullWidth
                  margin="dense"
                  autoComplete="off"
                  InputProps={{
                    value: property.address2,
                    onChange: (event) => {
                      setProperty({ ...property, address2: event.target.value });
                    },
                  }}
                />
              )}
              <TextField
                label="City"
                fullWidth
                type="text"
                autoComplete="off"
                required
                margin="dense"
                InputProps={{
                  value: property.city,
                  onChange: (event) => {
                    setProperty({ ...property, city: event.target.value });
                  },
                }}
              />
              <Autocomplete
                options={getStateCodeOptions}
                getOptionLabel={nameLabel}
                value={property.stateSelect}
                onChange={(_event, value) => {
                  setProperty({ ...property, stateSelect: value });
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} margin="dense" label="State" placeholder="Type to Search" fullWidth required />
                )}
              />
              <TextField
                label="Zip"
                fullWidth
                type="text"
                autoComplete="off"
                required
                margin="dense"
                InputProps={{
                  value: property.zip,
                  onChange: (event) => {
                    setProperty({ ...property, zip: event.target.value });
                  },
                }}
              />
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <Box>
                  <Button
                    hasFullWidth
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      if (showWelcome) {
                        tracking('next clicked', { location: 'Onboarding - Add Properties: Property Address' });
                      }
                    }}
                  >
                    Next
                  </Button>
                </Box>
                <Box mt={1}>
                  <MuiButton
                    variant="outlined"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={() => {
                      if (showWelcome) {
                        tracking('go back clicked', { location: 'Onboarding - Add Properties: Property Address' });
                      }
                      setKey('unitSelection');
                    }}
                  >
                    Go Back
                  </MuiButton>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'updateSubscriptionPrompt':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="caption" gutterBottom>
                {property.address1}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Please update your subscription plan
              </Typography>
            </Box>
            <Typography variant="body1">
              {`Adding this property requires updating your subscription to REI Hub. Please click
              below to choose a plan or see our `}
              <Link href="https://www.reihub.net/pricing" target="_blank" color="secondary">
                pricing page
              </Link>
              {' for more information.'}
            </Typography>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <Box>
                <Button
                  hasFullWidth
                  variant="contained"
                  onClick={() => {
                    setUpdateSubscriptionDialog(true);
                  }}
                >
                  Update Subscription
                </Button>
              </Box>
              <Box mt={1}>
                <MuiButton color="secondary" variant="outlined" size="large" fullWidth onClick={closeAddPropertyDialog}>
                  Cancel
                </MuiButton>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'unitNames':
        return (
          <DialogContent>
            <form onSubmit={processUnitNames}>
              <Box mx="auto" mb={2}>
                <Typography variant="caption" gutterBottom>
                  {property.address1}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  What are the unit's names?
                </Typography>
              </Box>
              {units.map((unit, index) => (
                <TextField
                  key={unit.key}
                  label="Apt, Fl, Unit, Etc."
                  fullWidth
                  required
                  margin="dense"
                  InputProps={{
                    value: units[index].name,
                    onChange: (event) => {
                      const newUnits = [...units];
                      newUnits[index].name = event.target.value;
                      setUnits(newUnits);
                    },
                  }}
                />
              ))}
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <Button
                  hasFullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    if (showWelcome) {
                      tracking('next clicked', { location: 'Onboarding - Add Properties: Unit Numbers' });
                    }
                  }}
                >
                  Next
                </Button>
                <Box mt={1}>
                  <MuiButton
                    variant="outlined"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={() => {
                      setKey('address');
                      if (showWelcome) {
                        tracking('go back clicked', { location: 'Onboarding - Add Properties: Unit Numbers' });
                      }
                    }}
                  >
                    Go Back
                  </MuiButton>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'marketValue':
        return (
          <DialogContent>
            <form onSubmit={processMarketValue}>
              <Box mx="auto" mb={2}>
                <Typography variant="caption" gutterBottom>
                  {property.address1}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  What is the approximate market value of this property?
                </Typography>
              </Box>
              <FormControl margin="dense" fullWidth>
                <InputLabel required>Estimated Market Value</InputLabel>
                <NumberFormat
                  value={property.marketValue}
                  required
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  onValueChange={(value) => {
                    const { floatValue } = value;
                    setProperty({ ...property, marketValue: floatValue });
                  }}
                  customInput={Input}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === '' || floatValue <= 99999999;
                  }}
                />
              </FormControl>
              <Typography className={classes.labels}>This will help us calculate your property's returns.</Typography>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <Button
                  hasFullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    if (showWelcome) {
                      tracking('next clicked', { location: 'Onboarding - Add Properties: Market Value' });
                    }
                  }}
                >
                  Next
                </Button>
                <Box mt={1}>
                  <MuiButton
                    variant="outlined"
                    size="large"
                    fullWidth
                    color="secondary"
                    onClick={() => {
                      if (property.unitSelection === 'multi') {
                        setKey('unitNames');
                      } else {
                        setKey('address');
                      }
                      if (showWelcome) {
                        tracking('go back clicked', { location: 'Onboarding - Add Properties: Market Value' });
                      }
                    }}
                  >
                    Go Back
                  </MuiButton>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'image':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="caption" gutterBottom>
                {property.address1}
              </Typography>
              <Typography variant="h6" gutterBottom>
                (Optional) Select an image for this property.
              </Typography>
            </Box>
            <Paper
              width="100%"
              className={classes.imagePaper}
              onClick={() => {
                uploadcareWidget.current.openDialog();
              }}
            >
              {property.image && property.image !== null ? (
                <img src={property.image} width="100%" alt="Property" className={classes.rentalImage} />
              ) : (
                <div className={classes.noImageDiv}>
                  <PlaceIcon color="disabled" fontSize="inherit" />
                </div>
              )}
            </Paper>
            <Box textAlign="center" mt={1}>
              <MuiButton
                color="primary"
                onClick={() => {
                  uploadcareWidget.current.openDialog();
                }}
              >
                Click to Edit Image
              </MuiButton>
            </Box>
            <Box display="none">
              <Widget
                id="nested_property_image"
                name="nested_property_image"
                ref={uploadcareWidget}
                imagesOnly
                crop="7:3"
                previewStep
                tabs="file url camera"
                value={property.image}
                onChange={(event) => {
                  setProperty({ ...property, image: event.cdnUrl });
                }}
              />
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <Typography color="error">{error && error.message}</Typography>
              <Button
                hasFullWidth
                variant="contained"
                onClick={() => {
                  if (showWelcome) {
                    tracking('save clicked', { location: 'Onboarding - Add Properties: Property Image' });
                  }
                  saveProperty();
                }}
              >
                Save
              </Button>
              <Box mt={1}>
                <MuiButton
                  variant="outlined"
                  color="secondary"
                  size="large"
                  fullWidth
                  onClick={() => {
                    if (showWelcome) {
                      tracking('go back clicked', { location: 'Onboarding - Add Properties: Property Image' });
                    }
                    setKey('marketValue');
                  }}
                >
                  Go Back
                </MuiButton>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'partnerSubscribed':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Partner Portfolio
              </Typography>
            </Box>
            <Typography variant="body1">
              {`Your properties are synced to REI Hub from our partner ${partnerName}.
                Please visit their site to add additional properties.`}
            </Typography>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <MuiButton onClick={closeAddPropertyDialog} color="secondary" variant="outlined" size="large" fullWidth>
                Close
              </MuiButton>
            </Box>
          </DialogContent>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {updateSubscriptionDialog && (
        <UpdateSubscriptionDialog
          isOpen
          newUnitCount={property.unitCount}
          closeDialog={() => {
            setUpdateSubscriptionDialog(false);
            checkSubscription();
          }}
          isFromAddProperty
        />
      )}
      {!showWelcome && (
        <Dialog
          open={isOpen}
          scroll="body"
          maxWidth="sm"
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {!isLoading && getDialogContent()}
        </Dialog>
      )}
      {showWelcome && (
        <Card open={isOpen} className={classes.onboardingCard}>
          {!isLoading && getDialogContent()}
        </Card>
      )}
    </>
  );
}

AddPropertyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddProperty: PropTypes.func.isRequired,
};
