import React, { useEffect, useState } from 'react';
import { Box, DialogContent, DialogTitle, makeStyles, TextField, Typography } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';

import { nameLabel, RECURRING_TRANSACTION_FREQUENCY_OPTIONS } from '../Autocomplete/Library';
import Button from '../Button';

import getRecurringFrequencyText from './Functions/RecurringTransactionDialogFunctions';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    maxWidth: '400px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(1),
  },
  alertContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export default function AddRecurringTransactionFrequencyDialogContent({
  onAddRecurringTransaction,
  error,
  frequency,
  setFrequency,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  type,
  onNavigateBack,
}) {
  const classes = useStyles();
  const [transactionCount, setTransactionCount] = useState(0);

  useEffect(() => {
    if (!startDate || !moment(startDate).isValid() || (endDate && !moment(endDate).isValid()) || !frequency) {
      setTransactionCount(0);
      return;
    }

    if (startDate && frequency) {
      const startDateMoment = moment(startDate);
      if (startDateMoment.isBefore(moment(), 'day')) {
        switch (frequency.id) {
          case 'Monthly': {
            const months = moment().diff(moment(startDate), 'months', true);
            if (months) {
              setTransactionCount(Math.ceil(months));
            }
            break;
          }
          case 'Quarterly': {
            const months = moment().diff(moment(startDate), 'months', true);
            const quarters = Math.floor(months / 3);
            if (quarters) {
              setTransactionCount(Math.ceil(quarters));
            }
            break;
          }
          case 'Annually': {
            const years = moment().diff(moment(startDate), 'years', true);
            if (years) {
              setTransactionCount(Math.ceil(years));
            }
            break;
          }
          default:
            break;
        }
      } else {
        setTransactionCount(0);
      }
    }
  }, [startDate, frequency]);

  return (
    <form onSubmit={onAddRecurringTransaction}>
      <DialogTitle id="alert-dialog-title">{`Add Recurring ${type} (2 of 2)`}</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={RECURRING_TRANSACTION_FREQUENCY_OPTIONS}
          getOptionLabel={nameLabel}
          value={frequency}
          onChange={(_event, value) => {
            setFrequency(value);
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} margin="dense" label="Frequency" placeholder="Type to Search" fullWidth required />
          )}
        />
        <KeyboardDatePicker
          label="Start Date"
          format="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
          value={moment(startDate).isValid() ? startDate : null}
          onChange={(date) => {
            setStartDate(date);
          }}
          margin="dense"
          fullWidth
          clearable
          required
        />
        <Box mb={2}>
          <KeyboardDatePicker
            label="End Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={moment(endDate).isValid() ? endDate : null}
            onChange={(date) => {
              setEndDate(date);
            }}
            margin="dense"
            fullWidth
            clearable
          />
        </Box>
        {frequency && startDate && (
          <Typography variant="body2">{getRecurringFrequencyText(startDate, endDate, frequency)}</Typography>
        )}
      </DialogContent>
      <Typography style={{ marginLeft: 25 }} color="error">
        {error && error.message}
      </Typography>
      {transactionCount > 0 ? (
        <Box className={classes.alertContainer}>
          <Alert severity="info">
            {`This will create and book ${transactionCount} ${type.toLowerCase()}
            ${transactionCount > 1 ? 'transactions' : 'transaction'} in the past based on the
            chosen start date`}
          </Alert>
        </Box>
      ) : null}
      <Box className={classes.buttonContainer}>
        <Button hasFullWidth type="submit" variant="contained">
          Continue
        </Button>
        <Button hasFullWidth variant="outlined" onClick={onNavigateBack}>
          Go Back
        </Button>
      </Box>
    </form>
  );
}

AddRecurringTransactionFrequencyDialogContent.defaultProps = {
  error: null,
  frequency: null,
  startDate: null,
  endDate: null,
};

AddRecurringTransactionFrequencyDialogContent.propTypes = {
  onAddRecurringTransaction: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  error: PropTypes.object,
  frequency: PropTypes.object,
  setFrequency: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.object,
  setEndDate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
};
