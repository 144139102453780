import React from 'react';

import { PersonContext } from '../contexts/PersonContext';

class Logout extends React.Component {
  componentDidMount() {
    const { logOut } = this.context;
    logOut();
  }

  render() {
    return null;
  }
}

Logout.contextType = PersonContext;

export default Logout;
