import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import PasswordStrengthChecker from '~/components/PasswordStrengthChecker';
import { STATUSES as PASSWORD_STATUSES } from '~/constants/password-requirements';
import { PersonContext } from '~/contexts/PersonContext';
import { patch } from '~/feathersFunctionalWrapper';
import usePasswordRequirements from '~/hooks/usePasswordRequirements';

import updatePasswordReducer, { ACTION_TYPES, initialState } from './update-password-reducer';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    width: '384px',
  },
  title: {
    marginBottom: '16px',
    width: '100%',
  },
  textField: {
    marginBottom: '16px',
    width: '100%',

    '&:last-of-type': {
      marginBottom: '0',
    },
  },
  passwordRequirements: {
    marginTop: '16px',
    width: '100%',
  },
  saveChangesButton: {
    marginTop: '32px',
  },
}));

export default function UpdatePassword({ onClose }) {
  const [state, dispatch] = useReducer(updatePasswordReducer, initialState);
  const passwordRequirements = usePasswordRequirements(
    state.newPassword,
    state.isPasswordSubmitted,
  );
  const isPasswordValid = useMemo(
    () => Object.values(passwordRequirements).every((status) => status === PASSWORD_STATUSES.MET),
    [passwordRequirements],
  );
  const { triggerSnackbar, user } = useContext(PersonContext);

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (state.isSubmitting) {
      return;
    }
    dispatch({ type: ACTION_TYPES.FORM_SUBMITTED });

    if (state.newPassword !== state.confirmPassword) {
      triggerSnackbar({
        horizontal: 'center',
        message: 'New password and confirmation password are not equal.',
        type: 'warning',
      });
      dispatch({ type: ACTION_TYPES.FORM_RESET });
      return;
    }

    if (!isPasswordValid) {
      triggerSnackbar({
        horizontal: 'center',
        message: 'Your new password must meet the requirements.',
        type: 'warning',
      });
      dispatch({ type: ACTION_TYPES.FORM_RESET });
      return;
    }

    try {
      await patch('users', user.id, {
        currentPassword: state.currentPassword,
        password: state.newPassword,
      });
      triggerSnackbar({
        horizontal: 'center',
        message: 'Password updated successfully.',
        type: 'success',
      });
      onClose();
    } catch (error) {
      if (error.message === 'Current password is incorrect') {
        dispatch({ type: ACTION_TYPES.CURRENT_PASSWORD_INCORRECT });
        triggerSnackbar({
          horizontal: 'center',
          message: 'Current password is incorrect.',
          type: 'warning',
        });
        dispatch({ type: ACTION_TYPES.FORM_RESET });
      } else {
        triggerSnackbar({
          horizontal: 'center',
          message: 'Failed to update password. Please try again.',
          type: 'error',
        });
        dispatch({ type: ACTION_TYPES.FORM_RESET });
      }
    }
  }

  useEffect(() => {
    if (state.isPasswordSubmitted) {
      dispatch({
        type: ACTION_TYPES.PASSWORDS_EQUAL_UPDATED,
        value: state.newPassword === state.confirmPassword,
      });
    }
  }, [state.isPasswordSubmitted, state.newPassword, state.confirmPassword]);

  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="update-password-dialog-title"
      open
      onClose={onClose}
    >
      <form onSubmit={handleSubmit} className={classes.container}>
        <Typography
          variant="h6"
          id="update-password-dialog-title"
          className={classes.title}
        >
          Change your password
        </Typography>

        <TextField
          error={!state.isCurrentPasswordCorrect}
          fullWidth
          label="Current Password"
          required
          type="password"
          value={state.currentPassword}
          onChange={(e) => dispatch({ type: ACTION_TYPES.FIELD_UPDATED, field: 'currentPassword', value: e.target.value })}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div className={classes.textField}>
          <TextField
            error={!state.arePasswordsEqual || (!isPasswordValid && state.isPasswordSubmitted)}
            fullWidth
            label="New Password"
            required
            type="password"
            value={state.newPassword}
            onChange={(e) => dispatch({ type: ACTION_TYPES.FIELD_UPDATED, field: 'newPassword', value: e.target.value })}
            onFocus={() => dispatch({ type: ACTION_TYPES.PASSWORD_REQUIREMENTS_REVEALED })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.arePasswordRequirementsVisible && (
            <PasswordStrengthChecker
              requirements={passwordRequirements}
              className={classes.passwordRequirements}
            />
          )}
        </div>
        <TextField
          error={!state.arePasswordsEqual}
          fullWidth
          label="Confirm Password"
          required
          type="password"
          value={state.confirmPassword}
          onChange={(e) => dispatch({ type: ACTION_TYPES.FIELD_UPDATED, field: 'confirmPassword', value: e.target.value })}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Button
          color="primary"
          disabled={state.isSubmitting}
          type="submit"
          variant="contained"
          className={classes.saveChangesButton}
        >
          Save changes
        </Button>
      </form>
    </Dialog>
  );
}

UpdatePassword.propTypes = {
  onClose: PropTypes.func.isRequired,
};
