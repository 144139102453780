import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import { asyncHandleChange } from '../functions/InputHandlers';

import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import AddSubportfolioDialog from '../components/AddSubportfolioDialog';

class Subportfolios extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addSubportfolioDialog: false,
      filter: {
        page: 0,
      },
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  getData = async () => {
    const { organizationId } = this.context;
    const { filter } = this.state;
    const query = {
      organizationId,
      $limit: 20,
      $skip: filter.page * 20,
      $sort: {
        name: 1,
        id: 1,
      },
    };
    const subportfolios = await find(this, 'entities', { query });
    this.setState({
      subportfolios: subportfolios.data,
      totalSubportfolios: subportfolios.total,
      loading: false,
    });
  };

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getData();
  };

  actionButtons = () => {
    const buttons = [
      { text: 'Add Sub-Portfolio', action: (() => this.setState({ addSubportfolioDialog: true })), class: 'add' },
    ];
    return buttons;
  };

  render() {
    const { match } = this.props;
    const {
      loading, subportfolios, totalSubportfolios, filter, addSubportfolioDialog,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader match={match} title="Sub-Portfolios" actionButtons={this.actionButtons()} />
        {addSubportfolioDialog && (
          <AddSubportfolioDialog
            closeDialog={() => this.setState({ addSubportfolioDialog: false })}
            onAdd={this.getData}
          />
        )}
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="subtitle2">Name</Typography></TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subportfolios.map((subportfolio) => (
                    <TableRow key={subportfolio.id}>
                      <TableCell>
                        {subportfolio.name}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          color="secondary"
                          variant="outlined"
                          component={Link}
                          to={`/subportfolios/subportfolio/${subportfolio.id}`}
                        >
                          Dashboard
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={totalSubportfolios}
                      page={filter.page}
                      rowsPerPage={20}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[20]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

Subportfolios.contextType = PersonContext;

Subportfolios.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Subportfolios;
