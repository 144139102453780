import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import history from '../history';
import { create, get } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine2 from '../components/FinancialAccountLine2';

class BalanceSheet extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        endDate: null,
        entityId: null,
        propertyId: null,
        unitId: null,
        endDateSelect: null,
        entityIdSelect: null,
        propertyIdSelect: null,
        unitIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis, organizationId } = this.context;
    const { filter } = this.state;

    if (!filter.endDate) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const accountsReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
    });

    const headers = [moment(filter.endDate).format('MM/DD/YYYY')];

    const accountsPromise = create(this, 'reports', accountsReportFilter);

    const accountsArray = await accountsPromise;

    const accountsObject = {
      assets: {
        accounts: [],
        bankAccounts: {
          accounts: [],
        },
        propertyManagement: {
          accounts: [],
        },
        escrowAccounts: {
          accounts: [],
        },
        fixedAssets: {
          accounts: [],
          fixedAssetAccounts: {
            accounts: [],
          },
          depreciationAccounts: {
            accounts: [],
          },
        },
        otherAssets: {
          accounts: [],
        },
      },
      liabilities: {
        accounts: [],
        creditCards: {
          accounts: [],
        },
        loans: {
          accounts: [],
        },
        otherLiabilities: {
          accounts: [],
        },
      },
      income: {
        accounts: [],
      },
      equity: {
        accounts: [],
      },
      incomeAndEquity: {
        accounts: [],
      },
      liabilitiesIncomeAndEquity: {
        accounts: [],
      },
      byId: {},
    };

    // sort accounts
    accountsArray.forEach((account) => {
      /* eslint-disable no-param-reassign */
      account.display = account.netCredits !== 0;
      account.subaccounts = [];
      if (account.type === 'Asset') {
        if (account.type2 === 'Bank') {
          accountsObject.assets.bankAccounts.accounts.push(account);
        } else if (account.type2 === 'Property Management') {
          accountsObject.assets.propertyManagement.accounts.push(account);
        } else if (account.type2 === 'Escrow') {
          accountsObject.assets.escrowAccounts.accounts.push(account);
        } else if (account.type2 === 'Fixed Asset') {
          accountsObject.assets.fixedAssets.fixedAssetAccounts.accounts.push(account);
          accountsObject.assets.fixedAssets.accounts.push(account);
        } else if (account.type2 === 'Accumulated Depreciation') {
          accountsObject.assets.fixedAssets.depreciationAccounts.accounts.push(account);
          accountsObject.assets.fixedAssets.accounts.push(account);
        } else {
          accountsObject.assets.otherAssets.accounts.push(account);
        }
        accountsObject.assets.accounts.push(account);
        account.values = [-account.netCredits];
      } else if (account.type === 'Liability') {
        if (account.type2 === 'Credit Card') {
          accountsObject.liabilities.creditCards.accounts.push(account);
        } else if (account.type2 === 'Mortgage') {
          accountsObject.liabilities.loans.accounts.push(account);
        } else if (['HELOC', 'Hard Money Loan', 'Loan'].includes(account.type2)) {
          accountsObject.liabilities.loans.accounts.push(account);
        } else {
          accountsObject.liabilities.otherLiabilities.accounts.push(account);
        }
        accountsObject.liabilities.accounts.push(account);
        accountsObject.liabilitiesIncomeAndEquity.accounts.push(account);
        account.values = [account.netCredits];
      } else if (['Revenue', 'Expense'].includes(account.type)) {
        accountsObject.income.accounts.push(account);
        accountsObject.incomeAndEquity.accounts.push(account);
        accountsObject.liabilitiesIncomeAndEquity.accounts.push(account);
        account.values = [account.netCredits];
      } else {
        accountsObject.equity.accounts.push(account);
        accountsObject.incomeAndEquity.accounts.push(account);
        accountsObject.liabilitiesIncomeAndEquity.accounts.push(account);
        account.values = [account.netCredits];
      }

      accountsObject.byId[account.id] = account;
      /* eslint-disable no-param-reassign */
    });

    accountsArray.forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
      }

      if (account.netCredits !== 0) {
        account.display = true;
        if (account.parentAccountId) {
          accountsObject.byId[account.parentAccountId].display = true;
        }
      }
    });

    const incomeAccountsObject = {
      netIncome: {
        accounts: [],
      },
      retainedEarnings: {
        accounts: [],
      },
    };

    const netIncomeReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });

    netIncomeReportFilter.date.$gte = moment(filter.endDate).startOf('year').format('YYYY-MM-DD');
    netIncomeReportFilter.date.$lte = moment(filter.endDate).format('YYYY-MM-DD');
    const netIncomeArray = await create(this, 'reports', netIncomeReportFilter);

    netIncomeArray.forEach((account) => {
      account.values = [account.netCredits];
      incomeAccountsObject.netIncome.accounts.push(account);
    });

    const retainedEarningsReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });
    retainedEarningsReportFilter.date.$lte = moment(filter.endDate).startOf('year').subtract(1, 'days').format('YYYY-MM-DD');
    delete retainedEarningsReportFilter.date.$gte;
    const retainedEarningsArray = await create(this, 'reports', retainedEarningsReportFilter);

    retainedEarningsArray.forEach((account) => {
      account.values = [account.netCredits];
      incomeAccountsObject.retainedEarnings.accounts.push(account);
    });

    const organization = await get(this, 'organizations', organizationId);

    let displayRetainedEarnings = true;
    if (organization.retainedEarningsAccountId) {
      const retainedEarningsAccount = accountsObject.byId[organization.retainedEarningsAccountId];
      retainedEarningsArray.forEach((account) => {
        retainedEarningsAccount.values[0] += account.netCredits;
        const incomeAccount = accountsObject.byId[account.id];
        incomeAccount.values[0] -= account.netCredits;
      });
      retainedEarningsAccount.display = true;
      displayRetainedEarnings = false;
    }

    this.setState({
      loading: false,
      accountsObject,
      headers,
      displayRetainedEarnings,
      incomeAccountsObject,
    });
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  showSection = (accounts) => {
    let showSection = false;
    accounts.forEach((account) => {
      if (account.display) {
        showSection = true;
      }
    });
    return showSection;
  };

  exportPdf = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const {
      filter,
      accountsObject,
      displayRetainedEarnings,
      incomeAccountsObject,
      headers,
      exportOptions,
    } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-pdf`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        displayRetainedEarnings,
        incomeAccountsObject,
        headers,
        exportOptions,
        reportName: 'Balance Sheet',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'balance-sheet.pdf';
        document.body.appendChild(a);
        a.click();
      });
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const {
      filter,
      accountsObject,
      headers,
      incomeAccountsObject,
      displayRetainedEarnings,
      exportOptions,
    } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        headers,
        accountsObject,
        incomeAccountsObject,
        displayRetainedEarnings,
        exportOptions,
        reportName: 'Balance Sheet',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'balance-sheet.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  }

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Export', action: (() => this.setState({ downloadDialog: true })), class: 'export' },
    ]
  );

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      accountsObject,
      headers,
      loading,
      filter,
      filterDialog,
      downloadDialog,
      displayRetainedEarnings,
      incomeAccountsObject,
    } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Balance Sheet"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          date
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property', 'Unit']}
          required={['date', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          exportPdf={this.exportPdf}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
              <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                <FinancialAccountLine2
                  values={headers}
                  bold
                />
                <FinancialAccountLine2
                  label="Assets"
                  overline
                  bold
                />
                {this.showSection(accountsObject.assets.bankAccounts.accounts) && (
                  <>
                    <FinancialAccountLine2
                      label="Bank Accounts"
                      bold
                      indent={1}
                    />
                    {accountsObject.assets.bankAccounts.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Bank Accounts"
                      values={this.sumColumnValues(accountsObject.assets.bankAccounts.accounts)}
                      sumline
                      bold
                      indent={1}
                    />
                  </>
                )}
                {this.showSection(accountsObject.assets.propertyManagement.accounts) && (
                <>
                  <FinancialAccountLine2
                    label="Property Management"
                    bold
                    indent={1}
                  />
                  {accountsObject.assets.propertyManagement.accounts.map((account) => (
                    <FinancialAccountLine2
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      indent={2}
                      filter={filter}
                    />
                  ))}
                  <FinancialAccountLine2
                    label="Total Property Management"
                    values={this.sumColumnValues(
                      accountsObject.assets.propertyManagement.accounts,
                    )}
                    sumline
                    bold
                    indent={1}
                  />
                </>
                )}
                {this.showSection(
                  accountsObject.assets.escrowAccounts.accounts,
                ) && (
                  <>
                    <FinancialAccountLine2
                      label="Escrow Accounts"
                      bold
                      indent={1}
                    />
                    {accountsObject.assets.escrowAccounts.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Escrow Accounts"
                      values={this.sumColumnValues(
                        accountsObject.assets.escrowAccounts.accounts,
                      )}
                      sumline
                      bold
                      indent={1}
                    />
                  </>
                )}
                {this.showSection(accountsObject.assets.fixedAssets.accounts) && (
                <>
                  <FinancialAccountLine2
                    label="Fixed Assets"
                    bold
                    indent={1}
                  />
                  {accountsObject.assets.fixedAssets.fixedAssetAccounts.accounts.map(
                    (account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                      />
                    ),
                  )}
                  {accountsObject.assets.fixedAssets.depreciationAccounts.accounts.map(
                    (account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                      />
                    ),
                  )}
                  <FinancialAccountLine2
                    label="Total Fixed Assets"
                    values={this.sumColumnValues(accountsObject.assets.fixedAssets.accounts)}
                    sumline
                    bold
                    indent={1}
                  />
                </>
                )}
                {accountsObject.assets.otherAssets.accounts.map((account) => (
                  <FinancialAccountLine2
                    key={account.id}
                    accountId={account.id}
                    label={account.name}
                    values={account.values}
                    display={account.display && !account.parentAccountId}
                    subaccounts={account.subaccounts}
                    indent={1}
                    filter={filter}
                  />
                ))}
                <FinancialAccountLine2
                  label="Total Assets"
                  values={this.sumColumnValues(accountsObject.assets.accounts)}
                  underline
                  overline
                  bold
                  marginBottom
                />
                <FinancialAccountLine2
                  label="Liabilites"
                  overline
                  bold
                />
                {this.showSection(accountsObject.liabilities.creditCards.accounts) && (
                <>
                  <FinancialAccountLine2
                    label="Credit Cards"
                    bold
                    indent={1}
                  />
                  {accountsObject.liabilities.creditCards.accounts.map((account) => (
                    <FinancialAccountLine2
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      indent={2}
                      filter={filter}
                    />
                  ))}
                  <FinancialAccountLine2
                    label="Total Credit Cards"
                    values={this.sumColumnValues(
                      accountsObject.liabilities.creditCards.accounts,
                    )}
                    sumline
                    bold
                    indent={1}
                  />
                </>
                )}
                {this.showSection(accountsObject.liabilities.loans.accounts) && (
                <>
                  <FinancialAccountLine2
                    label="Loans"
                    bold
                    indent={1}
                  />
                  {accountsObject.liabilities.loans.accounts.map((account) => (
                    <FinancialAccountLine2
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      indent={2}
                      filter={filter}
                    />
                  ))}
                  <FinancialAccountLine2
                    label="Total Loans"
                    values={this.sumColumnValues(accountsObject.liabilities.loans.accounts)}
                    sumline
                    bold
                    indent={1}
                  />
                </>
                )}
                {accountsObject.liabilities.otherLiabilities.accounts.map((account) => (
                  <FinancialAccountLine2
                    key={account.id}
                    accountId={account.id}
                    label={account.name}
                    values={account.values}
                    display={account.display && !account.parentAccountId}
                    subaccounts={account.subaccounts}
                    indent={1}
                    filter={filter}
                  />
                ))}
                <FinancialAccountLine2
                  label="Total Liabilities"
                  values={this.sumColumnValues(accountsObject.liabilities.accounts)}
                  underline
                  overline
                  bold
                  marginBottom
                />
                <FinancialAccountLine2
                  label="Equity"
                  overline
                  bold
                />
                <FinancialAccountLine2
                  label="Net Income"
                  values={this.sumColumnValues(incomeAccountsObject.netIncome.accounts)}
                  indent={1}
                />
                {displayRetainedEarnings
                  && (
                  <FinancialAccountLine2
                    label="Retained Earnings"
                    values={this.sumColumnValues(incomeAccountsObject.retainedEarnings.accounts)}
                    indent={1}
                  />
                  )}
                {accountsObject.equity.accounts.map((account) => (
                  <FinancialAccountLine2
                    key={account.id}
                    accountId={account.id}
                    label={account.name}
                    values={account.values}
                    display={account.display && !account.parentAccountId}
                    subaccounts={account.subaccounts}
                    indent={1}
                    filter={filter}
                  />
                ))}
                <FinancialAccountLine2
                  label="Total Equity"
                  values={this.sumColumnValues(accountsObject.incomeAndEquity.accounts)}
                  underline
                  overline
                  bold
                  marginBottom
                />
                <FinancialAccountLine2
                  label="Total Liabilities and Equity"
                  values={this.sumColumnValues(accountsObject.liabilitiesIncomeAndEquity.accounts)}
                  underline
                  overline
                  bold
                />
              </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

BalanceSheet.contextType = PersonContext;

BalanceSheet.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BalanceSheet;
