import { useCallback, useContext } from 'react';

import { useOnboardingContext } from '~/contexts/OnboardingProvider';
import { PersonContext } from '~/contexts/PersonContext';
import { patch, create } from '~/feathersWrapper';

export default function useUpdateUserOnboarding() {
  const { setUserOnboarding, userOnboarding } = useOnboardingContext();
  const { user } = useContext(PersonContext);

  return useCallback(async (data) => {
    let response;
    if (userOnboarding?.id) {
      response = await patch(this, 'user-onboardings', userOnboarding.id, {
        ...data,
      });
    } else {
      response = await create(this, 'user-onboardings', {
        userId: user.id,
        ...data,
      });
    }
    setUserOnboarding(response);
  }, [userOnboarding?.id, user]);
}
