import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';

import CustomStepper from '~/components/CustomStepper';
import { getDiscount } from '~/functions/BuyNowFunctions';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  logoContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
    width: '100%',
    textAlign: 'center',
  },
  contentContainer: {
    width: '100%',
    maxWidth: '900px',
    flexGrow: 1,
  },
  stepperContainer: {
    maxWidth: '900px',
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 0,
    [theme.breakpoints.up('sm')]: {
      '& div': {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      'paddingBottom': theme.spacing(3.5),
      '& div': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  limitedTimeOfferBanner: {
    width: '100%',
    backgroundColor: '#2E1341',
    color: 'white',
    lineHeight: '24px',
    position: 'sticky',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    gap: theme.spacing(0.75),
    padding: theme.spacing(2),
  },
  bannerTextSecondaryColor: {
    color: '#FFCD00',
  },
}));

export default function GrayLayout({ children }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const location = useLocation();

  const isLimitedTimeOffer = location.pathname.includes('-limited');
  const isLimitedTimeOfferBannerDisplayed = location.pathname.includes('select-pricing-limited');
  const isFurnishedFinderOffer = location.pathname.includes('furnished-finder');
  const isFurnishedFinderBannerDisplayed = location.pathname.includes('furnished-finder');

  const discount = getDiscount(isLimitedTimeOffer || isFurnishedFinderOffer);

  const stepperProps = [
    {
      label: 'Pricing',
      url: '/pricing',
    },
    {
      label: 'Create Profile',
      url: isLimitedTimeOffer ? '/register-profile-limited' : '/register-profile',
    },
    {
      label: 'Review & Pay',
      url: isLimitedTimeOffer ? '/checkout-limited' : '/checkout',
    },
  ];

  const checkIfStepperShouldBeDisplayed = () => {
    const registerRegex = /register-profile/;
    const checkoutRegex = /checkout/;
    return location.pathname.match(registerRegex) || location.pathname.match(checkoutRegex);
  };

  return (
    <>
      {isLimitedTimeOfferBannerDisplayed ? (
        <Box className={classes.limitedTimeOfferBanner}>
          <Box>
            <Typography variant="subtitle2">{discount.headerText}</Typography>
          </Box>
          <Box className={classes.bannerTextSecondaryColor}>
            <Typography variant="subtitle2">
              {`Get ${discount.discountPercent}% off your first ${discount.duration} months.`}
            </Typography>
          </Box>
        </Box>
      ) : null}
      {isFurnishedFinderBannerDisplayed ? (
        <Box className={classes.limitedTimeOfferBanner}>
          <Box>
            <Typography variant="subtitle2">Furnished Finder Deal!</Typography>
          </Box>
          <Box className={classes.bannerTextSecondaryColor}>
            <Typography variant="subtitle2">
              {`Get ${discount.discountPercent}% off your first ${discount.duration} months.`}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box className={classes.outerContainer}>
        <Box className={classes.logoContainer}>
          {isFurnishedFinderBannerDisplayed ? (
            <img
              src="/rei_hub_and_furnished_finder.png"
              height="54px"
              alt="REI Hub and Furnished Finder"
              className={classes.logo}
            />
          ) : (
            <img src="/rei_hub_logo_purple.svg" height="54px" alt="REI Hub" className={classes.logo} />
          )}
        </Box>
        {checkIfStepperShouldBeDisplayed() && (
          <Box className={classes.stepperContainer}>
            <CustomStepper orientation="horizontal" stepProps={stepperProps} allCompletedUrl="/dashboard" />
          </Box>
        )}
        <Box className={classes.contentContainer}>{children}</Box>
      </Box>
    </>
  );
}

GrayLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
