import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AsyncSelect from 'react-select/async';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';

const styles = (theme) => ({
  root: {
    // marginTop: '12px',
    // marginBottom: '8px',
    // flexGrow: 1,
    // height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {},
  placeholder: {
    position: 'absolute',
    left: 2,
  },
  searchIcon: {
    position: 'relative',
    bottom: '-4px',
  },
  paper: {
    zIndex: 20,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
function NoOptionsMessage(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    selectProps, innerRef, children, innerProps,
  } = props;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  const {
    innerRef, isFocused, isSelected, innerProps, children,
  } = props;
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children !== '' ? <SearchIcon fontSize="small" className={selectProps.classes.searchIcon} /> : ''}
      {children}
    </Typography>
  );
}

function SingleValue(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
}

function ValueContainer(props) {
  const { selectProps, children } = props;
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

function MultiValue(props) {
  const {
    selectProps, isFocused, children, removeProps,
  } = props;
  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused,
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
}

function Menu(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class SearchSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focus: false,
    };
  }

  textFieldProps = () => {
    const {
      value, label, helperText, required,
    } = this.props;
    const { focus } = this.state;
    return {
      label,
      helperText,
      required,
      InputLabelProps: focus
      || (value !== null && (value.length > 0
      || Object.keys(value).length > 0))
        ? { shrink: true }
        : { shrink: false },
    };
  };

  handleChange = (value) => {
    const { changeFunction, name, isMulti } = this.props;
    const { focus } = this.state;
    changeFunction(name, value, isMulti);
    if (focus || (value !== null && (value.length > 0 || Object.keys(value).length > 0))) {
      this.textFieldProps.InputLabelProps = {
        shrink: true,
      };
    } else {
      this.textFieldProps.InputLabelProps = { shrink: false };
    }
  };

  handleInputChange = (value) => value;
  /* {
    this.setState({ inputValue: value });
    return value;
  }; */

  render() {
    const {
      classes, theme, searchFunction, value,
      isClearable, isMulti, target, required, isDisabled, ...rest
    } = this.props;
    const { focus } = this.state;

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 1400,
      }),
    };

    return (
      <div
        className={classes.root}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        <NoSsr>
          <AsyncSelect
            classes={classes}
            styles={selectStyles}
            menuPortalTarget={document.body}
            textFieldProps={this.textFieldProps()}
            defaultOptions
            loadOptions={searchFunction}
            components={components}
            value={value}
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            placeholder={focus ? 'Type to Search' : ''}
            isMulti={isMulti}
            isClearable={isClearable}
            isDisabled={isDisabled}
            {...rest}
          />
          {!isDisabled && (
            <input
              tabIndex={-1}
              onChange={() => {}}
              autoComplete="off"
              style={{ position: 'absolute', opacity: 0, height: 0 }}
              value={value !== null ? value : ''}
              required={required}
            />
          )}
        </NoSsr>
      </div>
    );
  }
}

SearchSelect.defaultProps = {
  isClearable: true,
  isMulti: false,
  isDisabled: false,
  label: '',
  helperText: null,
  value: null,
  required: false,
  target: document.body,
};

SearchSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  isClearable: PropTypes.bool,
  required: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  searchFunction: PropTypes.func.isRequired,
  changeFunction: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  target: PropTypes.any, // eslint-disable-line react/forbid-prop-types,
};

export default withStyles(styles, { withTheme: true })(SearchSelect);
