import React from 'react';
import Box from '@material-ui/core/Box';
import green from '@material-ui/core/colors/green';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CheckCircleOutlinedIcon from '../../icons/CheckCircleOutlined';

const useStyles = makeStyles((theme) => ({
  container: ({ alignment, checked }) => ({
    'display': 'flex',
    'flexDirection': alignment === 'horizontal' ? 'row' : 'column',
    'alignItems': 'center',
    'justifyContent': 'flex-start',
    'backgroundColor': checked ? green[50] : theme.palette.background.paper,
    'borderRadius': theme.tokens.shape.borderRadius.md,
    'boxShadow': `0px 0px 0px 1px ${checked ? theme.palette.success.light : theme.palette.border.default} inset`,
    'padding': alignment === 'horizontal' ? theme.spacing(2) : theme.spacing(2.25),
    'cursor': 'pointer',

    '&:focus': {
      outline: 'none',
    },
    '&:focus-visible,&:hover': {
      border: 'none',
      boxShadow: `0px 0px 0px 2px ${checked ? theme.palette.success.dark : theme.palette.border.dark} inset`,
    },
  }),
  icon: ({ alignment, checked }) => ({
    'display': 'flex',
    'marginBottom': alignment === 'horizontal' ? 0 : theme.spacing(1.5),
    'marginRight': alignment === 'horizontal' ? theme.spacing(1.5) : 0,
    'color': checked ? theme.palette.success.main : theme.palette.primary.main,

    '& > svg': {
      height: alignment === 'horizontal' ? theme.tokens.components.icon.size.lg : theme.tokens.components.icon.size.xlg,
      width: alignment === 'horizontal' ? theme.tokens.components.icon.size.lg : theme.tokens.components.icon.size.xlg,
    },
  }),
  title: ({ alignment }) => ({
    fontSize: theme.tokens.typography.fontSize.body1,
    fontWeight: theme.tokens.typography.fontWeight.bold,
    textAlign: alignment === 'horizontal' ? 'left' : 'center',
  }),
  description: ({ alignment }) => ({
    fontSize: theme.tokens.typography.fontSize.body2,
    textAlign: alignment === 'horizontal' ? 'left' : 'center',
  }),
}));

function CardCheckbox({
  alignment,
  checked,
  description,
  icon,
  title,
  onChange,
  onClick,
  onKeyDown,
  className,
  ...rest
}) {
  function handleClick(event) {
    if (onClick) {
      onClick(event);
    }

    onChange();
  }
  function handleKeyDown(event) {
    if (onKeyDown) {
      onKeyDown(event);
    }

    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      onChange();
    }
  }

  const classes = useStyles({ alignment, checked });

  return (
    <Box
      aria-checked={checked}
      role="checkbox"
      tabIndex="0"
      onClick={(event) => handleClick(event)}
      onKeyDown={(event) => handleKeyDown(event)}
      className={classNames(classes.container, className)}
      {...rest}
    >
      <Box className={classes.icon}>
        {checked ? <CheckCircleOutlinedIcon data-testid="card-checkbox__checked-icon" /> : icon}
      </Box>
      <Box className={classes.textContainer}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>
    </Box>
  );
}

CardCheckbox.propTypes = {
  alignment: PropTypes.oneOf(['horizontal', 'vertical']),
  checked: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
};

CardCheckbox.defaultProps = {
  alignment: 'vertical',
  className: '',
  onClick: undefined,
  onKeyDown: undefined,
};

export default CardCheckbox;
