import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import NumberFormat from 'react-number-format';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import FormGridContainer from './FormGridContainer';
import FormGridItem from './FormGridItem';

import { PersonContext } from '../contexts/PersonContext';
import {
  handleSearchSelectChange,
  handleNumberFormatChange,
  handleKeyboardDatePickerChange,
} from '../functions/InputHandlers';

import SearchSelect from './SearchSelect/SearchSelect';
import {
  searchUsers,
} from './SearchSelect/SearchFunctions';

const styles = {};

class FilterLoginsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  getInitialState = async () => {
    const { filter } = this.props;
    if (!filter) {
      return { loading: true };
    }

    return { filter, loading: false };
  };

  updateFilter = async (event) => {
    event.preventDefault();
    const { filter } = this.state;
    const { updateFilter, closeDialog } = this.props;

    filter.page = 0;
    updateFilter(filter);

    closeDialog();
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, filter,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={closeDialog}
        onEnter={async () => { this.setState(await this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="filterLoginsDialog"
      >
        {!loading && (
          <form onSubmit={this.updateFilter}>
            <DialogTitle id="alert-dialog-title">Filter Logins</DialogTitle>
            <DialogContent>
              <SearchSelect
                searchFunction={searchUsers(this)}
                changeFunction={handleSearchSelectChange(this)}
                label="User"
                name="nested_filter_userId"
                isMulti={false}
                value={filter.userIdSelect}
                margin="dense"
                target={document.getElementById('filterLoginsDialog')}
              />
              <FormGridContainer>
                <FormGridItem xs={6}>
                  <KeyboardDatePicker
                    label="Start Date"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    value={filter.startDateSelect}
                    onChange={handleKeyboardDatePickerChange('nested_filter_startDate', this)}
                    margin="dense"
                    fullWidth
                    clearable
                  />
                </FormGridItem>
                <FormGridItem xs={6}>
                  <KeyboardDatePicker
                    label="End Date"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    value={filter.endDateSelect}
                    onChange={handleKeyboardDatePickerChange('nested_filter_endDate', this)}
                    margin="dense"
                    fullWidth
                    clearable
                  />
                </FormGridItem>
              </FormGridContainer>
              <FormControl margin="dense" fullWidth>
                <InputLabel>
                  Organization ID
                </InputLabel>
                <NumberFormat
                  value={filter.organizationId}
                  decimalScale={0}
                  onValueChange={handleNumberFormatChange('nested_filter_organizationId', this)}
                  customInput={Input}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Update Filter
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

FilterLoginsDialog.contextType = PersonContext;

FilterLoginsDialog.defaultProps = {
};

FilterLoginsDialog.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterLoginsDialog);
