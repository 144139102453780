import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

import { get, patch } from '../../feathersWrapper';
import history from '../../history';
import { PersonContext } from '../../contexts/PersonContext';

import {
  asyncHandleChange,
  handleTextFieldChange,
  handleUploaderChange,
} from '../../functions/InputHandlers';

import CardBase from '../../components/CardBase';
import PageGrid from '../../components/PageGrid';
import PageHeader from '../../components/PageHeader';

const styles = {
  grid: {
    width: '100%',
    margin: 0,
  },
  hideWidget: {
    display: 'none',
  },
};

const actionButtons = [
  { text: 'Cancel', link: '/accountant', class: 'cancel' },
];

class Settings extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async componentDidMount() {
    const { accountingFirmId } = this.context;
    const firm = await get(this, 'accounting-firms', accountingFirmId);
    this.setState({
      firm,
      loading: false,
    });
  }

  save = async (event) => {
    event.preventDefault();
    const { accountingFirmId } = this.context;
    const {
      firm, submitting,
    } = this.state;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    const patchData = { ...firm };

    await patch(this, 'accounting-firms', accountingFirmId, patchData);

    history.push('/accountant');
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading, submitting, error, firm,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader match={match} actionButtons={actionButtons} title="Settings" />
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <form onSubmit={this.save}>
                <TextField
                  label="Firm Name"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    value: firm.name,
                    name: 'nested_firm_name',
                    onChange: handleTextFieldChange(this),
                  }}
                />
                <TextField
                  label="Phone"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    value: firm.phone,
                    name: 'nested_firm_phone',
                    onChange: handleTextFieldChange(this),
                    type: 'tel',
                    autoComplete: 'tel-national',
                  }}
                />
                <TextField
                  label="Email"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    value: firm.email,
                    name: 'nested_firm_email',
                    onChange: handleTextFieldChange(this),
                    type: 'email',
                  }}
                />
                <FormControl margin="dense">
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Logo
                  </FormLabel>
                  <div className={firm.logoURL ? null : classes.hideWidget}>
                    <Widget
                      ref={this.uploadcareWidget}
                      tabs="file url"
                      value={firm.logoURL}
                      onChange={handleUploaderChange('nested_firm_logoURL', this)}
                    />
                  </div>
                  {!firm.logoURL && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        this.uploadcareWidget.current.openDialog();
                      }}
                    >
                      Click to Add Logo
                    </Button>
                  )}
                  {firm.logoURL && (
                    <Button
                      color="primary"
                      onClick={async () => {
                        await asyncHandleChange('nested_firm_logoURL', '', this);
                        this.uploadcareWidget.current.reloadInfo();
                      }}
                    >
                      Remove Logo
                    </Button>
                  )}
                </FormControl>
                <Box
                  marginY={1}
                >
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                  >
                    Update Settings
                  </Button>
                </Box>
                <Typography color="error">{error && error.message}</Typography>
              </form>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

Settings.contextType = PersonContext;

Settings.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Settings);
