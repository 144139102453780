import React from 'react';
import PropTypes from 'prop-types';
import { PlaidLink } from 'react-plaid-link';

import { create } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';

class PlaidLinkAdd extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  async componentDidMount() {
    const { organizationId } = this.context;
    const {
      banking, creditCards, importStartDate, plaidItemId,
    } = this.props;
    const token = await create(this, 'plaid-link-tokens', {
      plaidItemId,
      organizationId,
      banking,
      creditCards,
      addExisting: true,
      oauth: true,
    });
    localStorage.setItem('plaid_oauth_data', JSON.stringify({
      linkToken: token,
      organizationId,
      importStartDate,
      plaidItemId,
    }));
    this.setState({ token, loading: false });
  }

  componentWillUnmount() {
    document.body.style.removeProperty('overflow');
  }

  onSuccess = async (token, metadata) => {
    const { organizationId } = this.context;
    const { onSuccess, importStartDate, plaidItemId } = this.props;
    const plaidItem = await create(this, 'plaid-items', {
      organizationId,
      importStartDate,
      plaidPublicToken: token,
      plaidMetadata: metadata,
      plaidItemId,
    });
    onSuccess(plaidItem);

    // for some reason plaid adds overflow hidden on closing - this prevents scrolling
    setTimeout(() => { document.body.style.removeProperty('overflow'); }, 2000);
  };

  // eslint-disable-next-line no-unused-vars
  onExit = async (error, metadata) => {
    const { closeDialog } = this.props;
    closeDialog();
    // for some reason plaid adds overflow hidden on closing - this prevents scrolling
    setTimeout(() => { document.body.style.removeProperty('overflow'); }, 2000);
  };

  render() {
    const { loading, token } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PlaidLink
        token={token}
        onSuccess={this.onSuccess}
        onExit={this.onExit}
        onLoad={() => { document.getElementById('plaidLinkAdd').click(); }}
        style={{ display: 'none' }}
      >
        <span id="plaidLinkAdd">Connect a bank account</span>
      </PlaidLink>
    );
  }
}

PlaidLinkAdd.contextType = PersonContext;

PlaidLinkAdd.defaultProps = {
  banking: false,
  creditCards: false,
};

PlaidLinkAdd.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  importStartDate: PropTypes.string.isRequired,
  plaidItemId: PropTypes.string.isRequired,
  banking: PropTypes.bool,
  creditCards: PropTypes.bool,
};

export default PlaidLinkAdd;
