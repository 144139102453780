import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import history from '../history';
import { create, get } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine2 from '../components/FinancialAccountLine2';

class BalanceSheetByPeriod extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        period: null,
        entityId: null,
        propertyId: null,
        unitId: null,
        startDateSelect: null,
        endDateSelect: null,
        periodSelect: null,
        entityIdSelect: null,
        propertyIdSelect: null,
        unitIdSelect: null,
      },
      additionalCriteria: [],
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');
      newFilter.periodSelect = { name: 'Monthly', id: 'month' };

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis, organizationId } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate && filter.period)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const accountsObject = {
      assets: {
        accounts: [],
        bankAccounts: {
          accounts: [],
        },
        propertyManagement: {
          accounts: [],
        },
        escrowAccounts: {
          accounts: [],
        },
        fixedAssets: {
          accounts: [],
          fixedAssetAccounts: {
            accounts: [],
          },
          depreciationAccounts: {
            accounts: [],
          },
        },
        otherAssets: {
          accounts: [],
        },
      },
      liabilities: {
        accounts: [],
        creditCards: {
          accounts: [],
        },
        loans: {
          accounts: [],
        },
        otherLiabilities: {
          accounts: [],
        },
      },
      income: {
        accounts: [],
      },
      equity: {
        accounts: [],
      },
      incomeAndEquity: {
        accounts: [],
      },
      liabilitiesIncomeAndEquity: {
        accounts: [],
      },
      byId: {},
    };

    const accountsReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
    });
    delete accountsReportFilter.date.$gte;

    const accountsPromises = [];

    const periods = [];
    const endDateMoment = moment(filter.endDate, 'YYYY-MM-DD');

    const periodStartDate = moment(filter.startDate, 'YYYY-MM-DD');
    let periodEndDate = moment.min(
      moment(filter.startDate, 'YYYY-MM-DD').endOf(filter.period),
      moment(filter.endDate, 'YYYY-MM-DD'),
    );

    while (periodStartDate.isBefore(endDateMoment)) {
      periods.push({
        name: this.getPeriodName(periodStartDate, filter.period),
        startDate: periodStartDate.format('YYYY-MM-DD'),
        endDate: periodEndDate.format('YYYY-MM-DD'),
      });
      periodStartDate.add(1, filter.period).startOf(filter.period);
      periodEndDate.add(1, filter.period).endOf(filter.period);
      if (endDateMoment.isBefore(periodEndDate)) {
        periodEndDate = moment(filter.endDate, 'YYYY-MM-DD');
      }
    }

    periods.forEach((period) => {
      accountsPromises.push(
        create(this, 'reports', {
          ...accountsReportFilter,
          date: {
            $lte: moment(period.endDate).endOf('day').format('YYYY-MM-DD'),
          },
        }),
      );
    });

    const headers = periods.map((period) => period.name);

    const additionalCriteriaArray = [];
    periods.forEach((period) => {
      additionalCriteriaArray.push(
        {
          endDate: period.endDate,
        },
      );
    });

    const accountsResults = await Promise.all(accountsPromises);

    accountsResults[0].forEach((account) => {
      /* eslint-disable no-param-reassign */
      account.display = account.netCredits !== 0;
      account.subaccounts = [];
      account.values = [];
      if (account.type === 'Asset') {
        if (account.type2 === 'Bank') {
          accountsObject.assets.bankAccounts.accounts.push(account);
        } else if (account.type2 === 'Property Management') {
          accountsObject.assets.propertyManagement.accounts.push(account);
        } else if (account.type2 === 'Escrow') {
          accountsObject.assets.escrowAccounts.accounts.push(account);
        } else if (account.type2 === 'Fixed Asset') {
          accountsObject.assets.fixedAssets.fixedAssetAccounts.accounts.push(account);
          accountsObject.assets.fixedAssets.accounts.push(account);
        } else if (account.type2 === 'Accumulated Depreciation') {
          accountsObject.assets.fixedAssets.depreciationAccounts.accounts.push(account);
          accountsObject.assets.fixedAssets.accounts.push(account);
        } else {
          accountsObject.assets.otherAssets.accounts.push(account);
        }
        accountsObject.assets.accounts.push(account);
      } else if (account.type === 'Liability') {
        if (account.type2 === 'Credit Card') {
          accountsObject.liabilities.creditCards.accounts.push(account);
        } else if (account.type2 === 'Mortgage') {
          accountsObject.liabilities.loans.accounts.push(account);
        } else if (['HELOC', 'Hard Money Loan', 'Loan'].includes(account.type2)) {
          accountsObject.liabilities.loans.accounts.push(account);
        } else {
          accountsObject.liabilities.otherLiabilities.accounts.push(account);
        }
        accountsObject.liabilities.accounts.push(account);
        accountsObject.liabilitiesIncomeAndEquity.accounts.push(account);
      } else if (['Revenue', 'Expense'].includes(account.type)) {
        accountsObject.income.accounts.push(account);
        accountsObject.incomeAndEquity.accounts.push(account);
        accountsObject.liabilitiesIncomeAndEquity.accounts.push(account);
      } else {
        accountsObject.equity.accounts.push(account);
        accountsObject.incomeAndEquity.accounts.push(account);
        accountsObject.liabilitiesIncomeAndEquity.accounts.push(account);
      }

      accountsObject.byId[account.id] = account;
      /* eslint-disable no-param-reassign */
    });

    accountsResults[0].forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
      }
    });

    for (let i = 0; i < accountsResults.length; i += 1) {
      accountsResults[i].forEach((account, index) => {
        if (account.type === 'Asset') {
          accountsResults[0][index].values.push(-account.netCredits);
        } else {
          accountsResults[0][index].values.push(account.netCredits);
        }
        if (account.netCredits !== 0) {
          accountsResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    const incomeAccountsObject = {
      netIncome: {
        accounts: [],
      },
      retainedEarnings: {
        accounts: [],
      },
    };

    const netIncomeReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });
    const netIncomeArrayPromises = [];

    const retainedEarningsReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });
    delete retainedEarningsReportFilter.date.$gte;
    const retainedEarningsArrayPromises = [];

    periods.forEach((period) => {
      const netIncomeStartDate = moment(period.endDate).startOf('year').format('YYYY-MM-DD');
      const netIncomeEndDate = period.endDate;
      const retainedEarningsEndDate = moment(period.endDate).subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
      netIncomeArrayPromises.push(
        create(this, 'reports', {
          ...netIncomeReportFilter,
          date: {
            $gte: netIncomeStartDate,
            $lte: netIncomeEndDate,
          },
        }),
      );
      retainedEarningsArrayPromises.push(
        create(this, 'reports', {
          ...retainedEarningsReportFilter,
          date: {
            $lte: retainedEarningsEndDate,
          },
        }),
      );
    });

    const netIncomeArrays = await Promise.all(netIncomeArrayPromises);
    const retainedEarningsArrays = await Promise.all(retainedEarningsArrayPromises);

    netIncomeArrays[0].forEach((account) => {
      account.values = [];
      incomeAccountsObject.netIncome.accounts.push(account);
    });

    retainedEarningsArrays[0].forEach((account) => {
      account.values = [];
      incomeAccountsObject.retainedEarnings.accounts.push(account);
    });

    for (let i = 0; i < netIncomeArrays.length; i += 1) {
      netIncomeArrays[i].forEach((account, index) => {
        incomeAccountsObject.netIncome.accounts[index].values.push(account.netCredits);
      });
    }

    for (let i = 0; i < retainedEarningsArrays.length; i += 1) {
      retainedEarningsArrays[i].forEach((account, index) => {
        incomeAccountsObject.retainedEarnings.accounts[index].values.push(account.netCredits);
      });
    }

    const organization = await get(this, 'organizations', organizationId);

    let displayRetainedEarnings = true;
    if (organization.retainedEarningsAccountId) {
      const retainedEarningsAccount = accountsObject.byId[organization.retainedEarningsAccountId];
      incomeAccountsObject.retainedEarnings.accounts.forEach((account) => {
        const incomeAccount = accountsObject.byId[account.id];
        for (let i = 0; i < account.values.length; i += 1) {
          retainedEarningsAccount.values[i] += account.values[i];
          incomeAccount.values[i] -= account.values[i];
        }
      });
      retainedEarningsAccount.display = true;
      displayRetainedEarnings = false;
    }

    this.setState({
      loading: false,
      accountsObject,
      headers,
      additionalCriteria: additionalCriteriaArray,
      displayRetainedEarnings,
      incomeAccountsObject,
    });
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  showSection = (accounts) => {
    let showSection = false;
    accounts.forEach((account) => {
      if (account.display) {
        showSection = true;
      }
    });
    return showSection;
  };

  getPeriodName = (periodStartDateMoment, period) => {
    if (period === 'month') {
      return periodStartDateMoment.format('MMM-YY');
    }
    if (period === 'quarter') {
      return periodStartDateMoment.format('[Q]Q-YY');
    }
    return periodStartDateMoment.format('YYYY');
  }

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const {
      filter,
      accountsObject,
      incomeAccountsObject,
      displayRetainedEarnings,
      headers,
      exportOptions,
    } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        incomeAccountsObject,
        displayRetainedEarnings,
        headers,
        exportOptions,
        reportName: 'Balance Sheet By Period',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'balance-sheet-by-period.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Export', action: (() => this.setState({ downloadDialog: true })), class: 'export' },
    ]
  );

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      filter,
      filterDialog,
      downloadDialog,
      loading,
      headers,
      accountsObject,
      additionalCriteria,
      incomeAccountsObject,
      displayRetainedEarnings,
    } = this.state;
    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Balance Sheet By Period"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          period
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property', 'Unit']}
          required={['dateRange', 'period', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                  <FinancialAccountLine2
                    values={headers}
                    displayType="text"
                    bold
                  />
                  <FinancialAccountLine2
                    label="Assets"
                    overline
                    bold
                  />
                  {this.showSection(accountsObject.assets.bankAccounts.accounts) && (
                  <>
                    <FinancialAccountLine2
                      label="Bank Accounts"
                      bold
                      indent={1}
                    />
                    {accountsObject.assets.bankAccounts.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Bank Accounts"
                      values={this.sumColumnValues(accountsObject.assets.bankAccounts.accounts)}
                      sumline
                      bold
                      indent={1}
                    />
                  </>
                  )}
                  {this.showSection(accountsObject.assets.propertyManagement.accounts) && (
                  <>
                    <FinancialAccountLine2
                      label="Property Management"
                      bold
                      indent={1}
                    />
                    {accountsObject.assets.propertyManagement.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Property Management"
                      values={this.sumColumnValues(
                        accountsObject.assets.propertyManagement.accounts,
                      )}
                      sumline
                      bold
                      indent={1}
                    />
                  </>
                  )}
                  {this.showSection(
                    accountsObject.assets.escrowAccounts.accounts,
                  ) && (
                  <>
                    <FinancialAccountLine2
                      label="Escrow Accounts"
                      bold
                      indent={1}
                    />
                    {accountsObject.assets.escrowAccounts.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Escrow Accounts"
                      values={this.sumColumnValues(
                        accountsObject.assets.escrowAccounts.accounts,
                      )}
                      sumline
                      bold
                      indent={1}
                    />
                  </>
                  )}
                  {this.showSection(accountsObject.assets.fixedAssets.accounts) && (
                  <>
                    <FinancialAccountLine2
                      label="Fixed Assets"
                      bold
                      indent={1}
                    />
                    {accountsObject.assets.fixedAssets.fixedAssetAccounts.accounts.map(
                      (account) => (
                        <FinancialAccountLine2
                          key={account.id}
                          accountId={account.id}
                          label={account.name}
                          values={account.values}
                          display={account.display && !account.parentAccountId}
                          subaccounts={account.subaccounts}
                          indent={2}
                          filter={filter}
                          additionalCriteria={additionalCriteria}
                        />
                      ),
                    )}
                    {accountsObject.assets.fixedAssets.depreciationAccounts.accounts.map(
                      (account) => (
                        <FinancialAccountLine2
                          key={account.id}
                          accountId={account.id}
                          label={account.name}
                          values={account.values}
                          display={account.display && !account.parentAccountId}
                          subaccounts={account.subaccounts}
                          indent={2}
                          filter={filter}
                          additionalCriteria={additionalCriteria}
                        />
                      ),
                    )}
                    <FinancialAccountLine2
                      label="Total Fixed Assets"
                      values={this.sumColumnValues(accountsObject.assets.fixedAssets.accounts)}
                      sumline
                      bold
                      indent={1}
                    />
                  </>
                  )}
                  {accountsObject.assets.otherAssets.accounts.map((account) => (
                    <FinancialAccountLine2
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      indent={1}
                      filter={filter}
                      additionalCriteria={additionalCriteria}
                    />
                  ))}
                  <FinancialAccountLine2
                    label="Total Assets"
                    values={this.sumColumnValues(accountsObject.assets.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine2
                    label="Liabilites"
                    overline
                    bold
                  />
                  {this.showSection(accountsObject.liabilities.creditCards.accounts) && (
                  <>
                    <FinancialAccountLine2
                      label="Credit Cards"
                      bold
                      indent={1}
                    />
                    {accountsObject.liabilities.creditCards.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Credit Cards"
                      values={this.sumColumnValues(
                        accountsObject.liabilities.creditCards.accounts,
                      )}
                      sumline
                      bold
                      indent={1}
                    />
                  </>
                  )}
                  {this.showSection(accountsObject.liabilities.loans.accounts) && (
                  <>
                    <FinancialAccountLine2
                      label="Loans"
                      bold
                      indent={1}
                    />
                    {accountsObject.liabilities.loans.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={2}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Loans"
                      values={this.sumColumnValues(accountsObject.liabilities.loans.accounts)}
                      sumline
                      bold
                      indent={1}
                    />
                  </>
                  )}
                  {accountsObject.liabilities.otherLiabilities.accounts.map((account) => (
                    <FinancialAccountLine2
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      indent={1}
                      filter={filter}
                      additionalCriteria={additionalCriteria}
                    />
                  ))}
                  <FinancialAccountLine2
                    label="Total Liabilities"
                    values={this.sumColumnValues(accountsObject.liabilities.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine2
                    label="Equity"
                    overline
                    bold
                  />
                  <FinancialAccountLine2
                    label="Net Income"
                    values={this.sumColumnValues(incomeAccountsObject.netIncome.accounts)}
                    indent={1}
                  />
                  {displayRetainedEarnings
                  && (
                  <FinancialAccountLine2
                    label="Retained Earnings"
                    values={this.sumColumnValues(incomeAccountsObject.retainedEarnings.accounts)}
                    indent={1}
                  />
                  )}
                  {accountsObject.equity.accounts.map((account) => (
                    <FinancialAccountLine2
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      indent={1}
                      filter={filter}
                      additionalCriteria={additionalCriteria}
                    />
                  ))}
                  <FinancialAccountLine2
                    label="Total Equity"
                    values={this.sumColumnValues(accountsObject.incomeAndEquity.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine2
                    label="Total Liabilities and Equity"
                    values={this.sumColumnValues(
                      accountsObject.liabilitiesIncomeAndEquity.accounts,
                    )}
                    underline
                    overline
                    bold
                  />
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

BalanceSheetByPeriod.contextType = PersonContext;

BalanceSheetByPeriod.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BalanceSheetByPeriod;
