import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
} from '../functions/InputHandlers';

class AddPortfolioDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  getInitialState = () => {
    const initialState = {
      submitting: false,
      organization: {
        name: '',
      },
      error: null,
      loading: false,
    };
    return initialState;
  };

  addPortfolio = async (event) => {
    event.preventDefault();
    const { submitting, organization } = this.state;
    const { accountingFirmId } = this.context;
    const { selectOrganization } = this.props;

    if (submitting) {
      return;
    }
    if (accountingFirmId) {
      organization.accountingFirmId = accountingFirmId;
    }

    this.setState({ submitting: true });
    try {
      const organizationId = await create(this, 'register-portfolio', organization, true);
      /* create(
        this,
        'contact-us',
        {
          organizationId,
          personContext: this.context,
          subject: 'New Portfolio Added',
          message: `A new portfolio named ${organization.name} was created`,
        },
      ); */
      selectOrganization(organizationId);
    } catch (error) {
      this.setState({ error, submitting: false });
    }
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, error, organization,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        onClose={closeDialog}
        onEnter={() => { this.setState(this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && (
          <form onSubmit={this.addPortfolio}>
            <DialogTitle id="alert-dialog-title">Add Portfolio</DialogTitle>
            <DialogContent>
              <TextField
                label="Portfolio Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: organization.name,
                  name: 'nested_organization_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Save Portfolio
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

AddPortfolioDialog.contextType = PersonContext;

AddPortfolioDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  selectOrganization: PropTypes.func.isRequired,
};

export default AddPortfolioDialog;
