import React from 'react';
import history from '../history';

import { PersonContext } from '../contexts/PersonContext';

class Demo extends React.Component {
  componentDidMount() {
    const { viewDemoPortfolio, demo } = this.context;
    if (!demo) {
      viewDemoPortfolio();
    } else {
      history.push('/reload?link=/dashboard');
    }
  }

  render() {
    return null;
  }
}

Demo.contextType = PersonContext;

export default Demo;
