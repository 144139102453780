import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';

import AddBookedTransactionDialog from '../../components/AddBookedTransactionDialog';
import ReconcileAccountDialog from '../../components/ReconcileAccountDialog';

import CardBase from '../../components/CardBase';

class LoanAccountQuickLinksCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      addBookedTransactionDialog: false,
      reconcileAccountDialog: false,
    };
  }

  setTransactionsStale = () => {
    const { markStale } = this.props;
    markStale('accountGraph');
    markStale('journals');
  };

  render() {
    const {
      addBookedTransactionDialog, reconcileAccountDialog,
    } = this.state;

    const {
      accountId, mortgageEscrowAccountId,
    } = this.props;

    return (
      <>
        <AddBookedTransactionDialog
          isOpen={addBookedTransactionDialog}
          closeDialog={() => this.setState({
            addBookedTransactionDialog: false,
          })}
          onAddTransaction={this.setTransactionsStale}
        />
        {reconcileAccountDialog && (
          <ReconcileAccountDialog
            isOpen
            closeDialog={() => this.setState({
              reconcileAccountDialog: false,
            })}
            onReconcile={this.setTransactionsStale}
            accountId={accountId}
          />
        )}
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Quicklinks
              </Typography>
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => this.setState({
                    addBookedTransactionDialog: true,
                  })}
                >
                  Add Transaction
                </Button>
              </Box>
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => this.setState({
                    reconcileAccountDialog: true,
                  })}
                >
                  Reconcile Account
                </Button>
              </Box>
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  component={RouterLink}
                  to={`/transactions/booked?accountId=${accountId}`}
                >
                  Expanded Transactions
                </Button>
              </Box>
              {mortgageEscrowAccountId && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    component={RouterLink}
                    to={`/accounts/loans/escrow/${mortgageEscrowAccountId}`}
                  >
                    View Escrow Account
                  </Button>
                </Box>
              )}
            </Box>
          </CardBase>
        </Grid>
      </>
    );
  }
}

LoanAccountQuickLinksCard.defaultProps = {};

LoanAccountQuickLinksCard.propTypes = {
  markStale: PropTypes.func.isRequired,
  accountId: PropTypes.node.isRequired,
  mortgageEscrowAccountId: PropTypes.node.isRequired,
};

export default LoanAccountQuickLinksCard;
