import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  getAccountOptions,
  getVendorOptions,
  nameLabel,
} from './Autocomplete/Library';
import { getManualBasicJournal } from './SearchSelect/TransactionTypeOptions';
import TransactionScope from './TransactionScope';
import {
  asyncHandleChange,
  handleAutocompleteChange,
  handleTransactionScopeChange,
} from '../functions/InputHandlers';
import { setJournalScope } from '../functions/JournalFunctions';
import { create, get } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import AddVendorDialog from './AddVendorDialog';

class BatchBookTransactionsDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { batchType } = this.props;
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      addVendorDialog: false,
    };

    if (batchType.name === 'Book Revenues') {
      initialState.revenueAccountOptions = await getAccountOptions(
        this,
        {
          $or: [{ type: 'Revenue' },
            { type2: 'Deferred Revenue' }],
        },
      );
    }

    if (batchType.name === 'Book Expenses') {
      initialState.expenseAccountOptions = await getAccountOptions(
        this,
        {
          $or: [{ type: 'Expense' },
            { type2: 'Sales and Use Tax' }],
        },
      );
    }

    initialState.vendorOptions = await getVendorOptions(this, true);

    // Add a basic journal to state which we can append scope, expense or revenue, and vendor data
    // to be added to batch targets when submitting
    const journal = getManualBasicJournal();
    journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };

    initialState.journal = journal;

    this.setState(initialState);
  }

  closeDialog = () => {
    const { closeDialog } = this.props;
    closeDialog();
  };

  onBatchBookTransactions = () => {
    const { onBatchBookTransactions } = this.props;
    onBatchBookTransactions();
  }

  /**
   * Sets up batch journals depending on selected type, adds them to an array, and then
   * submits/creates the journals for each item added to the array.
   */
  batchBookTransactions = async () => {
    const { journal, submitting } = this.state;
    const { onBatchBookTransactions, fullTransactions, batchType } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true, loading: true });

    setJournalScope(journal);

    const batchJournalsArray = [];

    fullTransactions.forEach((transaction) => {
      const journalTemplate = getManualBasicJournal();

      // Set all journal attributes that are not transaction type specific
      journalTemplate.organizationId = organizationId;

      journalTemplate.description = transaction.description;
      journalTemplate.date = transaction.date;
      journalTemplate.amount = transaction.amount;

      journalTemplate.journalScopeSelect = journal.journalScopeSelect;
      journalTemplate.propertyIdSelect = journal.propertyIdSelect;
      journalTemplate.propertyId = journal.propertyId;
      journalTemplate.unitId = journal.unitId;
      journalTemplate.unitIdSelect = journal.unitIdSelect;
      journalTemplate.entityIdSelect = journal.entityIdSelect;
      journalTemplate.entityId = journal.entityId;

      journalTemplate.vendorIdSelect = journal.vendorIdSelect;
      if (journalTemplate.vendorIdSelect) {
        journalTemplate.vendorId = journalTemplate.vendorIdSelect.id;
      }

      journalTemplate.debitLines[0].debit = transaction.amount;
      journalTemplate.creditLines[0].credit = transaction.amount;

      if (batchType.name === 'Book Expenses') {
        // Set journal type for expenses
        journalTemplate.type = 'Expense';
        // Set debit and credit line data for expenses
        journalTemplate.debitLines[0].accountIdSelect = journal.debitLines[0].accountIdSelect;
        journalTemplate.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;

        journalTemplate.creditLines[0].yodleeTransactionId = transaction.id;

        journalTemplate.creditLines[0].accountId = transaction.account.id;
        journalTemplate.creditLines[0].accountIdSelect = transaction.account;
      } else if (batchType.name === 'Book Revenues') {
        // Set journal type for revenues
        journalTemplate.type = 'Revenue';
        // Set debit and credit line data for revenues
        journalTemplate.debitLines[0].yodleeTransactionId = transaction.id;

        journalTemplate.debitLines[0].accountIdSelect = transaction.account;
        journalTemplate.debitLines[0].accountId = transaction.account.id;

        journalTemplate.creditLines[0].accountIdSelect = journal.creditLines[0].accountIdSelect;
        journalTemplate.creditLines[0].accountId = journal.creditLines[0].accountIdSelect.id;
      }

      journalTemplate.journalLines = journalTemplate.debitLines.concat(journalTemplate.creditLines);

      batchJournalsArray.push(journalTemplate);
    });

    const createPromises = batchJournalsArray.map(
      (batchJournalsArrayItem) => create(this, 'journals', batchJournalsArrayItem, true),
    );

    Promise.all(createPromises)
      .then(() => {
        onBatchBookTransactions();
        this.setState({ submitting: false });
        this.closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  }

  render() {
    const {
      journal,
      expenseAccountOptions,
      revenueAccountOptions,
      loading,
      vendorOptions,
      addVendorDialog,
      error,
    } = this.state;
    const { vendorTracking } = this.context;
    const { fullTransactions, batchType } = this.props;

    if (loading) {
      return null;
    }

    if (addVendorDialog) {
      return (
        <AddVendorDialog
          isOpen={addVendorDialog}
          closeDialog={() => this.setState({ addVendorDialog: false })}
          onAddVendor={async (newValue) => {
            await asyncHandleChange('vendorOptions', await getVendorOptions(this, true), this);
            await asyncHandleChange('nested_journal_vendorIdSelect', await get(this, 'vendors', newValue.id), this);
          }}
        />
      );
    }

    return (
      <>
        <Dialog
          open
          scroll="body"
          fullWidth
          disableBackdropClick
          disableEnforceFocus
          onClose={this.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          id="addTransactionDialog"
        >
          {batchType.name === 'Book Expenses' && (
            <DialogTitle id="alert-dialog-title">
              Book Expenses
            </DialogTitle>
          )}
          {batchType.name === 'Book Revenues' && (
            <DialogTitle id="alert-dialog-title">
              Book Revenues
            </DialogTitle>
          )}
          <DialogContent>
            <TransactionScope
              journal={journal}
              transactionScopeChange={(newScopeValues) => {
                handleTransactionScopeChange(journal, newScopeValues, this);
              }}
            />
            {batchType.name === 'Book Expenses' && (
            <Autocomplete
              options={expenseAccountOptions}
              getOptionLabel={nameLabel}
              value={journal.debitLines[0].accountIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_debitLines_0_accountIdSelect',
                this,
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Expense Account"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
            )}
            {batchType.name === 'Book Revenues' && (
            <Autocomplete
              options={revenueAccountOptions}
              getOptionLabel={nameLabel}
              value={journal.creditLines[0].accountIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_creditLines_0_accountIdSelect',
                this,
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Revenue Account"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
            )}
            {vendorTracking && batchType.name === 'Book Expenses' && (
            <Autocomplete
              options={vendorOptions}
              getOptionLabel={nameLabel}
              value={journal.vendorIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_vendorIdSelect',
                this,
                () => this.setState({ addVendorDialog: true }),
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Vendor (optional)"
                  placeholder="Type to Search"
                  fullWidth
                />
              )}
            />
            )}
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeDialog}
              color="primary"
            >
              Cancel
            </Button>
            {batchType.name === 'Book Expenses' && (
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={this.batchBookTransactions}
            >
              {
            fullTransactions.length === 1
              ? `Book ${fullTransactions.length} Expense`
              : `Book ${fullTransactions.length} Expenses`
            }
            </Button>
            )}
            {batchType.name === 'Book Revenues' && (
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={this.batchBookTransactions}
            >
              {
                fullTransactions.length === 1
                  ? `Book ${fullTransactions.length} Revenue`
                  : `Book ${fullTransactions.length} Revenues`
                }
            </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

BatchBookTransactionsDialog.contextType = PersonContext;

BatchBookTransactionsDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  fullTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBatchBookTransactions: PropTypes.func.isRequired,
  batchType: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default BatchBookTransactionsDialog;
