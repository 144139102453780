import { feathers } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import authentication from '@feathersjs/authentication-client';

const client = feathers();

const socket = io(process.env.REACT_APP_FEATHERS_SOCKET, {
  withCredentials: true,
  transports: ['websocket', 'polling'],
});

client.configure(socketio(socket, {
  timeout: 10000,
}));

client.configure(
  authentication({
    storage: window.localStorage,
  }),
);

client.service('yodlee-accounts').timeout = 30000;
client.service('authManagement').timeout = 20000;

export default client;
