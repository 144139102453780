import { paramsForServer } from 'feathers-hooks-common';
import client from './feathers';

export const get = async (component, service, id, throwError = false) => {
  const feathersParams = paramsForServer({ url: window.location.href });
  return client
    .service(service)
    .get(id, feathersParams)
    .catch((err) => {
      if (throwError) {
        throw err;
      } else {
        return new Promise((resolve) => {
          component.setState(() => { throw err; }, () => resolve());
        });
      }
    });
};

export const find = async (component, service, params, throwError = false) => {
  const feathersParams = paramsForServer({
    url: window.location.href,
    ...params,
  });
  return client
    .service(service)
    .find(feathersParams)
    .catch((err) => {
      if (throwError) {
        throw err;
      } else {
        return new Promise((resolve) => {
          component.setState(() => { throw err; }, () => resolve());
        });
      }
    });
};

export const create = async (component, service, newInstance, throwError = false) => {
  const feathersParams = paramsForServer({ url: window.location.href });
  return client
    .service(service)
    .create(newInstance, feathersParams)
    .catch((err) => {
      if (throwError) {
        throw err;
      } else {
        return new Promise((resolve) => {
          component.setState(() => { throw err; }, () => resolve());
        });
      }
    });
};

export const patch = async (component, service, id, data, throwError = false) => {
  const feathersParams = paramsForServer({ url: window.location.href });
  return client
    .service(service)
    .patch(id, data, feathersParams)
    .catch((err) => {
      if (throwError) {
        throw err;
      } else {
        return new Promise((resolve) => {
          component.setState(() => { throw err; }, () => resolve());
        });
      }
    });
};

export const patchMultiple = async (component, service, params, data, throwError = false) => {
  const feathersParams = paramsForServer({
    url: window.location.href,
    ...params,
  });
  return client
    .service(service)
    .patch(null, data, feathersParams)
    .catch((err) => {
      if (throwError) {
        throw err;
      } else {
        return new Promise((resolve) => {
          component.setState(() => { throw err; }, () => resolve());
        });
      }
    });
};

export const remove = async (component, service, id, throwError = false) => {
  const feathersParams = paramsForServer({ url: window.location.href });
  return client
    .service(service)
    .remove(id, feathersParams)
    .catch((err) => {
      if (throwError) {
        throw err;
      } else {
        return new Promise((resolve) => {
          component.setState(() => { throw err; }, () => resolve());
        });
      }
    });
};

export const removeMultiple = async (component, service, params, throwError = false) => {
  const feathersParams = paramsForServer({
    url: window.location.href,
    ...params,
  });
  return client
    .service(service)
    .remove(null, feathersParams)
    .catch((err) => {
      if (throwError) {
        throw err;
      } else {
        return new Promise((resolve) => {
          component.setState(() => { throw err; }, () => resolve());
        });
      }
    });
};
