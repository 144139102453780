import React from 'react';
import PropTypes from 'prop-types';
import { ElementsConsumer } from '@stripe/react-stripe-js';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';

import AddPaymentCardDialog from './AddPaymentCardDialog';
import PlaidLinkAuth from './Plaid/PlaidLinkAuth';

import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import { handleAutocompleteChange } from '../functions/InputHandlers';

import {
  nameLabel,
  getPaymentMethodOptions,
} from './Autocomplete/Library';

class UpdateFailedPaymentMethodDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethodSelect: null,
      step: 'select',
      error: null,
    };
  }

  continueToPayment = (event) => {
    event.preventDefault();

    const { paymentMethodSelect } = this.state;

    if (paymentMethodSelect.id === 'Card') {
      this.setState({
        step: 'card',
      });
    }

    if (paymentMethodSelect.id === 'ACH') {
      this.setState({
        step: 'ACH',
      });
    }
  }

  addACHPaymentMethod = async (stripeBankAccountToken) => {
    const { organizationId } = this.context;

    try {
      await create(
        this,
        'stripe-sources',
        {
          organizationId,
          stripeSource: stripeBankAccountToken,
        },
        true, // throw error
      );
      this.goToThanks();
    } catch (error) {
      this.setState({
        step: 'updateError',
        error,
      });
    }
  };

  goToThanks = () => {
    /* const { organizationId, setOrganizationId } = this.context;

    setOrganizationId(organizationId);
    */
    this.setState({ step: 'thanks' });
  };

  closeAndRefreshOrg = () => {
    const { closeDialog } = this.props;
    const { organizationId, setOrganizationId } = this.context;

    setOrganizationId(organizationId);
    closeDialog();
  };

  getDialogContent = () => {
    const { unpaidInvoiceData } = this.context;
    const { closeDialog } = this.props;
    const { step, paymentMethodSelect, error } = this.state;

    switch (step) {
      case 'select':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Update Payment Method</DialogTitle>
            <DialogContent>
              <form onSubmit={this.continueToPayment}>
                <Typography variant="body1" gutterBottom>
                  {'Your last payment of '}
                  <NumberFormat
                    displayType="text"
                    value={unpaidInvoiceData.total / 100}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                  {` due on ${unpaidInvoiceData.dueDate} to REI Hub did not process successfully. 
                  Please update your payment method below.`}
                </Typography>
                <Autocomplete
                  options={getPaymentMethodOptions}
                  getOptionLabel={nameLabel}
                  value={paymentMethodSelect}
                  onChange={handleAutocompleteChange(
                    'paymentMethodSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Payment Method"
                      fullWidth
                      required
                    />
                  )}
                />
                {paymentMethodSelect && paymentMethodSelect.id === 'ACH' && (
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body1">
                    {`Please click continue and sign in with your bank 
                      to securely select and authorize a payment account. Account authorization services are provided by Plaid.`}
                  </Typography>
                </Box>
                )}
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    size="large"
                    fullWidth
                    disableElevation
                  >
                    Continue
                  </Button>
                </Box>
                <DialogActions>
                  <Button onClick={closeDialog} color="primary">
                    Dismiss
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </>
        );
      case 'ACH':
        return (
          <PlaidLinkAuth
            closeDialog={() => this.setState({ step: 'select' })}
            onSuccess={this.addACHPaymentMethod}
          />
        );
      case 'card':
        return (
          <ElementsConsumer>
            {({ elements, stripe }) => (
              <AddPaymentCardDialog
                elements={elements}
                stripe={stripe}
                onSuccess={() => this.goToThanks()}
                onCancel={() => this.setState({ step: 'select' })}
                cancelLabel="Go Back"
              />
            )}
          </ElementsConsumer>
        );
      case 'updateError':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Subscription Error</DialogTitle>
            <DialogContent>
              <Typography variant="body1" color="error" gutterBottom>
                {`Sorry, we were unable to process your payment method update with the information
                provided. Please try again. Message: ${error.message}`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ step: 'select' })} color="primary">
                Go Back
              </Button>
            </DialogActions>
          </>
        );
      case 'thanks':
        return (
          <>
            <DialogTitle id="alert-dialog-title">Update Successful</DialogTitle>
            <DialogContent>
              <Typography variant="body1" gutterBottom>
                Thank you. We have successfully updated your payment method.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeAndRefreshOrg} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        );
      default:
        return null;
    }
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {this.getDialogContent()}
      </Dialog>
    );
  }
}

UpdateFailedPaymentMethodDialog.contextType = PersonContext;

UpdateFailedPaymentMethodDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default UpdateFailedPaymentMethodDialog;
