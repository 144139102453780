// eslint-disable-next-line import/prefer-default-export
export const fixedAssetTypes = [
  {
    label: 'Residential Property',
    value: 'Residential Property',
    lifespan: 27.5,
  },
  {
    label: 'Commercial Property',
    value: 'Commercial Property',
    lifespan: 39,
  },
  {
    label: 'Appliances',
    value: 'Appliances',
    lifespan: 5,
  },
  {
    label: 'Carpets and Furniture',
    value: 'Carpets and Furniture',
    lifespan: 5,
  },
  {
    label: 'Land Improvements',
    value: 'Land Improvements',
    lifespan: 15,
  },
  {
    label: 'Residential Additions and Improvements',
    value: 'Residential Improvements',
    lifespan: 27.5,
  },
  {
    label: 'Commercial Additions and Improvements',
    value: 'Commercial Improvements',
    lifespan: 39,
  },
  {
    label: 'Loan Closing Costs',
    value: 'Loan Closing Costs',
    lifespan: null,
  },
  {
    label: 'Closing Costs',
    value: 'Closing Costs',
    lifespan: null,
  },
  {
    label: 'Other Fixed Assets',
    value: 'Other Fixed Assets',
    lifespan: 7,
  },
  {
    label: 'Other Property',
    value: 'Other Property',
    lifespan: 7,
  },
];
