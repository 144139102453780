import React from 'react';
import PropTypes from 'prop-types';
import generateHash from 'random-hash';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
  handleCheckboxChange,
} from '../functions/InputHandlers';

class AddAdministratorDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = () => {
    const { organizationId } = this.context;
    const initialState = {
      loading: false,
      submitting: false,
      administratorInvite: {
        organizationId,
        id: generateHash({ length: 15 }),
        firstName: '',
        lastName: '',
        email: '',
        isReadOnly: false,
      },
      error: null,
    };
    this.setState(initialState);
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addAdministrator = async (event) => {
    event.preventDefault();
    const { submitting, administratorInvite } = this.state;
    const { onAdd } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    try {
      await create(this, 'administrator-invites', {
        ...administratorInvite,
        email: administratorInvite.email.trim().toLowerCase(),
        accessLevel: administratorInvite.isReadOnly ? 'Read Only' : null,
      }, true);
      this.closeDialog();
      onAdd();
    } catch (error) {
      this.setState({ error, submitting: false });
    }
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, error, administratorInvite,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        onClose={closeDialog}
        onEnter={() => { this.setInitialState(); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && (
          <form onSubmit={this.addAdministrator}>
            <DialogTitle id="alert-dialog-title">Send Access Invite</DialogTitle>
            <DialogContent>
              <TextField
                label="First Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: administratorInvite.firstName,
                  name: 'nested_administratorInvite_firstName',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Last Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: administratorInvite.lastName,
                  name: 'nested_administratorInvite_lastName',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Email"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: administratorInvite.email,
                  name: 'nested_administratorInvite_email',
                  type: 'email',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={administratorInvite.isReadOnly} onChange={handleCheckboxChange(this)} name="nested_administratorInvite_isReadOnly" />}
                  label="Grant READ ONLY access"
                />
              </FormControl>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Send Invite
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

AddAdministratorDialog.contextType = PersonContext;

AddAdministratorDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddAdministratorDialog;
