import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import history from '../history';

class ViewEnableVendorsDialog extends React.PureComponent {
  render() {
    const { isOpen, closeDialog } = this.props;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Vendor Tracking</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {`Vendor tracking is currently disabled. When enabled, vendors can be created
              and tagged to transactions for an additional dimension of expense reporting.
              Please visit the settings page if you would like to enable this feature.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => history.push('/settings')}
          >
            Go To Settings
          </Button>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ViewEnableVendorsDialog.defaultProps = {
};

ViewEnableVendorsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default ViewEnableVendorsDialog;
