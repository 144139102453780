import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import PasswordStrengthChecker from '~/components/PasswordStrengthChecker';
import { GET_STARTED_CHECKLIST_ITEM_STATUS } from '~/constants/get-started';
import { STATUSES as PASSWORD_STATUSES } from '~/constants/password-requirements';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { patch } from '~/feathersWrapper';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import usePasswordRequirements from '~/hooks/usePasswordRequirements';

const CARD_KEYS = {
  PORTFOLIO_SETUP: 'portfolioSetup',
  SET_PASSWORD: 'setPassword',
};

export default function PortfolioSetup() {
  const [cardKey, setCardKey] = useState(CARD_KEYS.PORTFOLIO_SETUP);

  const showSetPasswordCard = useCallback(() => setCardKey(CARD_KEYS.SET_PASSWORD), [setCardKey]);

  if (cardKey === CARD_KEYS.PORTFOLIO_SETUP) {
    return <PortfolioSetupCard showSetPasswordCard={showSetPasswordCard} />;
  }

  if (cardKey === CARD_KEYS.SET_PASSWORD) {
    return <SetPasswordCard />;
  }

  return null;
}

const SEGMENT_LOCATION = 'Onboarding: Portfolio Setup';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '600px',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBlock: '0',
  },
  passwordRequirements: {
    marginTop: theme.spacing(1.5),
  },
  helperText: {
    marginTop: '2px',
    color: theme.palette.text.secondary,
    fontSize: '13px',
  },
  submitButton: {
    marginInline: 'auto',
    color: theme.palette.primary.contrastText,
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      width: '400px',
    },
  },
}));

function PortfolioSetupCard({ showSetPasswordCard }) {
  const [orgName, setOrgName] = useState('');
  const [orgBookkeepingStartDate, setOrgBookkeepingStartDate] = useState(null);

  const {
    bookkeepingStartDate,
    organizationId,
    organizationName,
    setOrganizationId,
    systemPassword,
    triggerSnackbar,
    partnerSubscribed,
  } = useContext(PersonContext);

  useEffect(() => {
    setOrgBookkeepingStartDate(bookkeepingStartDate);
    setOrgName(organizationName);
  }, [bookkeepingStartDate, organizationName]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const tracking = useSegmentTrack();
  const history = useHistory();

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);

      const patchData = {
        name: orgName,
        bookkeepingStartDate: orgBookkeepingStartDate,
      };

      await patch(this, 'organizations', organizationId, patchData);

      await setOrganizationId(organizationId);

      tracking('save_and_continue clicked', { location: SEGMENT_LOCATION });

      let query = { organizationId };

      // Automatically assume that if the user is subscribed through a partner, they already have a
      // property added through that partner and that the add property step is completed
      if (partnerSubscribed) {
        query = { ...query, addProperty: GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED };
      }

      await create('get-started-checklist', query);

      if (systemPassword) {
        setIsSubmitting(false);
        showSetPasswordCard();
      } else {
        history.push('/properties');
      }
    } catch {
      setIsSubmitting(false);
      triggerSnackbar({
        message: 'Something went wrong. Please try again.',
        type: 'error',
      });
    }
  }

  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Card className={classes.card}>
        <Typography variant="h6" className={classes.title}>
          Portfolio Setup
        </Typography>
        <Box mb={2}>
          <TextField
            label="Portfolio Name"
            fullWidth
            required
            margin="dense"
            InputProps={{
              value: orgName,
              name: orgName,
              onChange: (event) => {
                setOrgName(event.target.value);
              },
            }}
            className={classes.textField}
          />
          <Typography className={classes.helperText}>This can be updated any time from the settings menu.</Typography>
        </Box>

        <Box mb={3}>
          <KeyboardDatePicker
            label="Bookkeeping Start Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={orgBookkeepingStartDate}
            onChange={(date) => setOrgBookkeepingStartDate(date)}
            margin="dense"
            fullWidth
            clearable
            required
            className={classes.textField}
          />

          <Typography className={classes.helperText}>
            The start date is the first day you plan to record transactions in REI Hub. Most people choose the start of
            the tax year.
          </Typography>
        </Box>

        <Button
          color="primary"
          disabled={isSubmitting}
          size="large"
          type="submit"
          variant="contained"
          className={classes.submitButton}
        >
          Save and Continue
        </Button>
      </Card>
    </form>
  );
}

PortfolioSetupCard.propTypes = {
  showSetPasswordCard: PropTypes.func.isRequired,
};

function SetPasswordCard() {
  const [password, setPassword] = useState('');

  const [arePasswordRequirementsVisible, setArePasswordRequirementsVisible] = useState(false);
  const [isPasswordSubmitted, setIsPasswordSubmitted] = useState(false);
  const passwordRequirements = usePasswordRequirements(password, isPasswordSubmitted);
  const isPasswordValid = useMemo(
    () => Object.values(passwordRequirements).every((status) => status === PASSWORD_STATUSES.MET),
    [passwordRequirements],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setContextState, triggerSnackbar, user } = useContext(PersonContext);
  const history = useHistory();

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      if (isSubmitting) {
        return;
      }

      setIsPasswordSubmitted(true);

      if (!isPasswordValid) {
        triggerSnackbar({
          horizontal: 'center',
          message: 'Your password must meet the requirements.',
          type: 'warning',
        });
        return;
      }

      setIsSubmitting(true);

      const patchData = {
        password,
        systemPassword: false,
      };
      await patch(this, 'users', user.id, patchData);
      await setContextState({ systemPassword: false });

      history.push('/properties');
    } catch {
      setIsSubmitting(false);
      triggerSnackbar({
        message: 'Something went wrong. Please try again.',
        type: 'error',
      });
    }
  }

  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Card className={classes.card}>
        <Typography variant="h6" className={classes.title}>
          Please choose a password for your account
        </Typography>
        <Box mb={3}>
          <TextField
            error={!isPasswordValid && isPasswordSubmitted}
            label="New Password"
            fullWidth
            required
            InputProps={{
              value: password,
              name: 'password',
              onChange: (event) => {
                setPassword(event.target.value);
              },
              onFocus: () => setArePasswordRequirementsVisible(true),
              type: 'password',
              autoComplete: 'new-password',
            }}
          />
          {arePasswordRequirementsVisible && (
            <PasswordStrengthChecker requirements={passwordRequirements} className={classes.passwordRequirements} />
          )}
          <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
            <Typography variant="body2">
              {`You'll be able to log in to your portfolio at www.reihub.net
                using your email and password.`}
            </Typography>
          </Box>
        </Box>

        <Button
          color="primary"
          disabled={isSubmitting}
          size="large"
          type="submit"
          variant="contained"
          className={classes.submitButton}
        >
          Save and Continue
        </Button>
      </Card>
    </form>
  );
}
