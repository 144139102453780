import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { CardElement } from '@stripe/react-stripe-js';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

const styles = (theme) => ({
  bottomBorder: {
    borderBottomColor: 'rgba(0, 0, 0, 0.42)',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    paddingBottom: '5px',
    '&:hover': {
      borderBottomColor: theme.palette.secondary.main,
      borderBottomWidth: '2px',
      paddingBottom: '4px',
    },
  },
  elementFocus: {
    borderBottomColor: theme.palette.secondary.main,
    borderBottomWidth: '2px',
    paddingBottom: '4px',
  },
  dialogText: {
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(3),
  },
});

class AddPaymentCardDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      elementFocus: false,
      submitting: false,
    };
  }

  addPaymentMethod = async (event) => {
    event.preventDefault();
    const { stripe, elements, onSuccess } = this.props;
    const { organizationId } = this.context;
    const { submitting } = this.state;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    const cardElement = elements.getElement(CardElement);
    // Use your card Element with other Stripe.js APIs
    const { error, source } = await stripe.createSource(cardElement, {
      type: 'card',
    });

    if (error) {
      this.setState({ error, submitting: false });
      return;
    }

    try {
      await create(
        this,
        'stripe-sources',
        {
          organizationId,
          stripeSource: source.id,
        },
        true, // throw error
      );
    } catch (sourcesErr) {
      this.setState({ error: sourcesErr, submitting: false });
      return;
    }
    onSuccess();
  };

  render() {
    const {
      theme, classes, stripe, caption, onCancel, cancelLabel,
    } = this.props;

    const {
      error, elementFocus, submitting,
    } = this.state;

    if (!stripe) {
      return null;
    }

    return (
      <Dialog
        open
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Enter Payment Card</DialogTitle>
        <DialogContent>
          <form onSubmit={this.addPaymentMethod}>
            <CardElement
              className={`${classes.bottomBorder} ${elementFocus ? classes.elementFocus : ''}`}
              onFocus={() => this.setState({ elementFocus: true })}
              onBlur={() => this.setState({ elementFocus: false })}
              options={{
                style: {
                  base: {
                    // fontSize: `${theme.typography.fontSize}px`,
                    // fontFamily: theme.typography.fontFamily,
                    fontSize: '20px',
                    fontFamily: 'system-ui',
                    color: '#000000de',
                    // ':focus': {},
                  },
                  invalid: {
                    color: theme.palette.error.main,
                  },
                },
              }}
            />
            {caption && (
              <Typography variant="caption">
                {caption}
              </Typography>
            )}
            <Box
              marginTop={2}
            >
              <Typography color="error">{error && error.message}</Typography>
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                disabled={submitting}
                size="large"
                fullWidth
                disableElevation
              >
                {submitting ? 'Saving...' : 'Submit'}
              </Button>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCancel}
            disabled={submitting}
            color="primary"
          >
            {cancelLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddPaymentCardDialog.contextType = PersonContext;

AddPaymentCardDialog.defaultProps = {
  caption: null,
  cancelLabel: 'Cancel',
};

AddPaymentCardDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  theme: PropTypes.objectOf(PropTypes.any).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stripe: PropTypes.objectOf(PropTypes.any).isRequired,
  elements: PropTypes.objectOf(PropTypes.any).isRequired,
  caption: PropTypes.node,
  cancelLabel: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(AddPaymentCardDialog);
