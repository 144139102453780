import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';

// import {PersonContext} from '../contexts/PersonProvider.jsx';

const styles = {
  root: {
    width: 'calc(100%+24)',
    margin: '0px -12px',
  },
};

class FormGridContainer extends React.PureComponent {
  render() {
    const { classes, children } = this.props;

    return (
      <Grid container spacing={3} className={classes.root}>
        {children}
      </Grid>
    );
  }
}

FormGridContainer.defaultProps = {
  children: null,
};

FormGridContainer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(FormGridContainer);
