import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import NumberFormat from 'react-number-format';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormGridContainer from './FormGridContainer';
import FormGridItem from './FormGridItem';

import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
  handleNumberFormatChange,
  handleKeyboardDatePickerChange,
  handleAutocompleteChange,
} from '../functions/InputHandlers';

import {
  nameLabel,
  getImportAccountOptions,
  getImportedTransactionStatusOptions,
} from './Autocomplete/Library';

const styles = {};

class FilterYodleeTransactionsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  getInitialState = async () => {
    const { filter } = this.props;
    if (!filter) {
      return { loading: true };
    }
    const importAccountOptions = await getImportAccountOptions(this);

    return { filter, importAccountOptions, loading: false };
  };

  updateFilter = async (event) => {
    event.preventDefault();
    const { filter } = this.state;
    const { updateFilter, closeDialog } = this.props;

    filter.page = 0;
    updateFilter(filter);

    closeDialog();
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, error, filter, importAccountOptions,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={closeDialog}
        onEnter={async () => { this.setState(await this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="filterYodleeTransactionsDialog"
      >
        {!loading && (
          <form onSubmit={this.updateFilter}>
            <DialogTitle id="alert-dialog-title">Filter Transactions</DialogTitle>
            <DialogContent>
              <Autocomplete
                options={importAccountOptions}
                getOptionLabel={nameLabel}
                value={filter.accountIdSelect}
                onChange={handleAutocompleteChange(
                  'nested_filter_accountIdSelect',
                  this,
                )}
                disableClearable
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Account"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <Autocomplete
                options={getImportedTransactionStatusOptions}
                getOptionLabel={nameLabel}
                value={filter.transactionStatusSelect}
                onChange={handleAutocompleteChange(
                  'nested_filter_transactionStatusSelect',
                  this,
                )}
                disableClearable
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Transaction Status"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <TextField
                label="Description"
                fullWidth
                margin="dense"
                InputProps={{
                  value: filter.description,
                  name: 'nested_filter_description',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <FormControl margin="dense" fullWidth>
                <InputLabel>
                  Transaction Amount
                </InputLabel>
                <NumberFormat
                  value={filter.amount}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={handleNumberFormatChange('nested_filter_amount', this)}
                  customInput={Input}
                />
              </FormControl>
              <FormGridContainer>
                <FormGridItem xs={6}>
                  <KeyboardDatePicker
                    label="Start Date"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    value={filter.startDateSelect}
                    onChange={handleKeyboardDatePickerChange('nested_filter_startDate', this)}
                    margin="dense"
                    fullWidth
                    clearable
                  />
                </FormGridItem>
                <FormGridItem xs={6}>
                  <KeyboardDatePicker
                    label="End Date"
                    format="MM/DD/YYYY"
                    placeholder="MM/DD/YYYY"
                    value={filter.endDateSelect}
                    onChange={handleKeyboardDatePickerChange('nested_filter_endDate', this)}
                    margin="dense"
                    fullWidth
                    clearable
                  />
                </FormGridItem>
              </FormGridContainer>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Update Filter
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

FilterYodleeTransactionsDialog.contextType = PersonContext;

FilterYodleeTransactionsDialog.defaultProps = {
};

FilterYodleeTransactionsDialog.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterYodleeTransactionsDialog);
