import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import NumberFormat from 'react-number-format';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Input,
  InputLabel,
  FormControl,
  Typography,
} from '@material-ui/core/';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { create } from '../feathersWrapper';

import {
  asyncSetState,
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleSearchSelectChange,
  handleTextFieldChange,
} from '../functions/InputHandlers';

import SearchSelect from './SearchSelect/SearchSelect';
import { searchProperties } from './SearchSelect/SearchFunctions';
import { PersonContext } from '../contexts/PersonContext';

const styles = {
  datetime: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '4px',
  },
};

class AddHoursDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  setInitialState = async () => {
    const { organizationId } = this.context;
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      hours: {
        organizationId,
        date: moment().format('YYYY-MM-DD'),
        dateSelect: moment(),
        hours: null,
        description: '',
        property: null,
        propertyId: null,
        propertyIdSelect: null,
      },
    };

    await asyncSetState(initialState, this);
  };

  addHours = async (event) => {
    event.preventDefault();
    const { submitting, hours } = this.state;

    const { onAdd } = this.props;

    if (submitting) {
      return;
    }

    if (!hours.date) {
      this.setState({
        error: { message: 'Please enter a valid date for these hours' },
      });
      return;
    }

    if (!hours.hours) {
      this.setState({
        error: { message: 'Please enter the hours you would like to track' },
      });
      return;
    }

    this.setState({ submitting: true });

    create(this, 'hours', hours, true)
      .then((result) => {
        onAdd(result);
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const { loading, error, hours } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && (
          <form onSubmit={this.addHours}>
            <DialogTitle id="alert-dialog-title">Add Hours</DialogTitle>
            <DialogContent>
              <KeyboardDatePicker
                className={classes.datetime}
                label="Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={hours.date}
                onChange={handleKeyboardDatePickerChange(
                  'nested_hours_date',
                  this,
                )}
                margin="dense"
                fullWidth
                clearable
                required
              />
              <FormControl margin="dense" fullWidth>
                <InputLabel required>Hours</InputLabel>
                <NumberFormat
                  value={hours.hours}
                  required
                  thousandSeparator
                  decimalScale={2}
                  onValueChange={handleNumberFormatChange(
                    'nested_hours_hours',
                    this,
                  )}
                  customInput={Input}
                />
              </FormControl>
              <SearchSelect
                searchFunction={searchProperties(this, {})}
                changeFunction={handleSearchSelectChange(this)}
                label="Property"
                name="nested_hours_propertyId"
                isMulti={false}
                value={hours.propertyIdSelect}
                margin="dense"
              />
              <TextField
                label="Description (Optional)"
                fullWidth
                multiline
                maxRows="5"
                margin="dense"
                InputProps={{
                  value: hours.description,
                  name: 'nested_hours_description',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disableElevation
              >
                Save Hours
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

AddHoursDialog.contextType = PersonContext;

AddHoursDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddHoursDialog);
