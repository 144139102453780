import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';

import { PersonContext } from '../contexts/PersonContext';

import FormGridContainer from './FormGridContainer';
import FormGridItem from './FormGridItem';

import AddRevenueDialogContent from './TransactionTemplates/AddRevenueDialogContent';
import AddNetIncomeDialogContent from './TransactionTemplates/AddNetIncomeDialogContent';
import AddExpenseDialogContent from './TransactionTemplates/AddExpenseDialogContent';
import AddTransactionDialogContent from './TransactionTemplates/AddTransactionDialogContent';
import AddLoanPaymentDialogContent from './TransactionTemplates/AddLoanPaymentDialogContent';
import AddDepreciationDialogContent from './TransactionTemplates/AddDepreciationDialogContent';
import AddFixedAssetDialogContent from './TransactionTemplates/AddFixedAssetDialogContent';
import AddOpeningBalanceDialogContent from './TransactionTemplates/AddOpeningBalanceDialogContent';
import AddRefundDialogContent from './TransactionTemplates/AddRefundDialogContent';

class AddBookedTransactionDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  getInitialState = () => {
    const { type } = this.props;
    const initialState = {
      loading: false,
      transactionType: null,
      TransactionTypeLabel: null,
    };

    if (type) {
      initialState.transactionType = type;
      initialState.transactionTypeLabel = type;
    }

    return initialState;
  };

  chooseTransactionTypeContent = () => (
    <>
      <DialogTitle id="alert-dialog-title">
        Add Transaction
      </DialogTitle>
      <DialogContent>
        <FormGridContainer>
          <FormGridItem xs={12} md={4}>
            <Typography
              align="center"
              variant="subtitle1"
              gutterBottom
            >
              Cash In
            </Typography>
            <ButtonGroup
              color="secondary"
              orientation="vertical"
              variant="outlined"
              size="large"
              fullWidth
              aria-label="cash in button group"
            >
              <Button
                onClick={() => this.setTransactionType('Revenue')}
              >
                Revenue
              </Button>
              <Button
                onClick={() => this.setTransactionType('Net Income')}
              >
                Net Income
              </Button>
              <Button
                onClick={() => this.setTransactionType('Contribution', 'Owner Contribution')}
              >
                Owner Contribution
              </Button>
              <Button
                onClick={() => this.setTransactionType('securityDepositReceived', 'Security Deposit Received')}
              >
                Security Deposit Received
              </Button>
              <Button
                onClick={() => this.setTransactionType('Refund', 'Refund Received')}
              >
                Refund Received
              </Button>
            </ButtonGroup>
          </FormGridItem>
          <FormGridItem xs={12} md={4}>
            <Typography
              align="center"
              variant="subtitle1"
              gutterBottom
            >
              Cash Out
            </Typography>
            <ButtonGroup
              color="secondary"
              orientation="vertical"
              variant="outlined"
              size="large"
              fullWidth
              aria-label="cash out button group"
            >
              <Button
                onClick={() => this.setTransactionType('Expense')}
              >
                Expense
              </Button>
              <Button
                onClick={() => this.setTransactionType('Loan Payment')}
              >
                Loan Payment
              </Button>
              <Button
                onClick={() => this.setTransactionType('Fixed Asset Purchase')}
              >
                Fixed Asset Purchase
              </Button>
              <Button
                onClick={() => this.setTransactionType('Distribution', 'Owner Distribution')}
              >
                Owner Distribution
              </Button>
              <Button
                onClick={() => this.setTransactionType('Credit Card Payment', 'Credit Card Payment')}
              >
                Credit Card Payment
              </Button>
              <Button
                onClick={() => this.setTransactionType('securityDepositRefunded', 'Security Deposit Refunded')}
              >
                Security Deposit Refunded
              </Button>
              <Button
                onClick={() => this.setTransactionType('Refund Issued', 'Refund Issued')}
              >
                Refund Issued
              </Button>
            </ButtonGroup>
          </FormGridItem>
          <FormGridItem xs={12} md={4}>
            <Typography
              align="center"
              variant="subtitle1"
              gutterBottom
            >
              Other
            </Typography>
            <ButtonGroup
              color="secondary"
              orientation="vertical"
              size="large"
              fullWidth
              aria-label="other transactions button group"
            >
              <Button
                onClick={() => this.setTransactionType('Transfer', 'Transfer Between Accounts')}
              >
                Transfer Between Accounts
              </Button>
              <Button
                onClick={() => this.setTransactionType('securityDepositRetained', 'Security Deposit Retained')}
              >
                Security Deposit Retained
              </Button>
              <Button
                onClick={() => this.setTransactionType('Escrow Expense', 'Escrow Expense')}
              >
                Escrow Expense
              </Button>
              <Button
                onClick={() => this.setTransactionType('Depreciation')}
              >
                Depreciation
              </Button>
              <Button
                onClick={() => this.setTransactionType('Opening Balance', 'Opening Balance')}
              >
                Opening Balance
              </Button>
              <Button
                onClick={() => this.setTransactionType('Manual Journal', 'Manual Journal')}
              >
                Manual Journal
              </Button>
            </ButtonGroup>
          </FormGridItem>
        </FormGridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.closeDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  );

  transactionContent = () => {
    const {
      transactionType, transactionTypeLabel,
    } = this.state;
    const {
      propertyId, unitId, propertyManagerId, assetId, bankAccountId,
    } = this.props;

    switch (transactionType) {
      case 'Revenue':
        return (
          <AddRevenueDialogContent
            propertyId={propertyId}
            unitId={unitId}
            propertyManagerId={propertyManagerId}
            bankAccountId={bankAccountId}
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
          />
        );
      case 'Net Income':
        return (
          <AddNetIncomeDialogContent
            propertyId={propertyId}
            unitId={unitId}
            propertyManagerId={propertyManagerId}
            bankAccountId={bankAccountId}
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
          />
        );
      case 'Expense':
        return (
          <AddExpenseDialogContent
            propertyId={propertyId}
            unitId={unitId}
            propertyManagerId={propertyManagerId}
            bankAccountId={bankAccountId}
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            resetTransactionType={this.setTransactionType}
          />
        );
      case 'Loan Payment':
        return (
          <AddLoanPaymentDialogContent
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
          />
        );
      case 'Fixed Asset Purchase':
        return (
          <AddFixedAssetDialogContent
            assetId={assetId}
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            resetTransactionType={this.setTransactionType}
          />
        );
      case 'Opening Balance':
        return (
          <AddOpeningBalanceDialogContent
            assetId={assetId}
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
          />
        );
      case 'Depreciation':
        return (
          <AddDepreciationDialogContent
            assetId={assetId}
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
          />
        );
      case 'Refund':
        return (
          <AddRefundDialogContent
            propertyId={propertyId}
            unitId={unitId}
            propertyManagerId={propertyManagerId}
            bankAccountId={bankAccountId}
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
          />
        );
      default:
        return (
          <AddTransactionDialogContent
            propertyId={propertyId}
            unitId={unitId}
            assetId={assetId}
            closeDialog={this.closeDialog}
            onAddTransaction={this.onAddTransaction}
            transactionType={transactionType}
            transactionTypeLabel={transactionTypeLabel}
          />
        );
    }
  };

  setTransactionType = (value, label = null) => {
    this.setState({
      transactionType: value,
      transactionTypeLabel: label,
    });
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  onAddTransaction = () => {
    const { onAddTransaction } = this.props;
    this.closeDialog();
    onAddTransaction();
  };

  render() {
    const { isOpen } = this.props;
    const {
      loading, transactionType,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth={transactionType ? 'sm' : 'lg'}
        fullWidth
        disableBackdropClick
        disableEnforceFocus
        onClose={this.closeDialog}
        onEnter={() => { this.setState(this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="addTransactionDialog"
      >
        {!loading && !transactionType && this.chooseTransactionTypeContent()}
        {!loading && transactionType && this.transactionContent()}
      </Dialog>
    );
  }
}

AddBookedTransactionDialog.contextType = PersonContext;

AddBookedTransactionDialog.defaultProps = {
  unitId: null,
  propertyId: null,
  propertyManagerId: null,
  bankAccountId: null,
  assetId: null,
  type: null,
};

AddBookedTransactionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  unitId: PropTypes.node,
  propertyId: PropTypes.node,
  propertyManagerId: PropTypes.node,
  bankAccountId: PropTypes.node,
  assetId: PropTypes.node,
  type: PropTypes.node,
};

export default AddBookedTransactionDialog;
