import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const styles = (theme) => ({
  // reverse the negative margins from page header for proper spacing
  buttonGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  buttonIcon: {
    width: 28,
    height: 28,
  },
});

class CardActionButtons extends React.PureComponent {
  render() {
    const { actionButtons, classes } = this.props;

    return (
      <ButtonGroup className={`noprint ${classes.buttonGroup}`} aria-label="action buttons">
        {actionButtons.map((button, index) => {
          const buttonProps = {
            variant: 'outlined',
            className: button.class,
          };
          if (button.link) {
            buttonProps.component = Link;
            buttonProps.to = button.link;
          } else if (button.action) {
            buttonProps.onClick = button.action;
          }

          /* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
          if (button.class === 'filter') {
            return (
              <Button {...buttonProps} key={index}>
                <FilterListIcon className={classes.buttonIcon} />
              </Button>
            );
          }
          if (button.class === 'add') {
            return (
              <Button {...buttonProps} key={index}>
                <AddIcon className={classes.buttonIcon} />
              </Button>
            );
          }
          if (button.class === 'edit') {
            return (
              <Button {...buttonProps} key={index}>
                <EditIcon className={classes.buttonIcon} />
              </Button>
            );
          }
          if (button.class === 'delete') {
            return (
              <Button {...buttonProps} key={index}>
                <DeleteOutlineIcon className={classes.buttonIcon} />
              </Button>
            );
          }
          if (button.class === 'cancel') {
            return (
              <Button {...buttonProps} key={index}>
                <CloseOutlinedIcon className={classes.buttonIcon} />
              </Button>
            );
          }
          return <Button {...buttonProps} key={index}>{button.text}</Button>;
          /* eslint-enable react/jsx-props-no-spreading, react/no-array-index-key */
        })}
      </ButtonGroup>
    );
  }
}

CardActionButtons.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actionButtons: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(CardActionButtons);
