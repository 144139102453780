import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogTitle,
  withStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import { find, patch, removeMultiple } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import { asyncSetState } from '../functions/InputHandlers';

const styles = () => ({
  unsplitConfirmationButton: {
    color: 'red',
  },
});

class UnsplitYodleeTransactionDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: false, splitChildren: [] };
  }

  async componentDidMount() {
    const { transactionId } = this.props;
    const { organizationId } = this.context;

    const splitChildren = await find(this, 'yodlee-transactions', {
      query: {
        organizationId,
        splitParentId: transactionId,
        $limit: 200,
      },
    });

    const initialState = {
      splitChildren: splitChildren.data,
      submitting: false,
      error: null,
      loading: false,
    };

    await asyncSetState(initialState, this);
  }

  unsplitYodleeTransaction = async (event) => {
    event.preventDefault();
    const {
      submitting, splitChildren,
    } = this.state;

    const { transactionId, onSplitTransaction, closeDialog } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    const journalIds = splitChildren.reduce((filtered, child) => {
      if (child.journalId) {
        filtered.push(child.journalId);
      }
      return filtered;
    }, []);

    if (journalIds.length > 0) {
      await removeMultiple(this, 'journals', {
        query: { organizationId, id: journalIds },
      });
    }

    await removeMultiple(this, 'yodlee-transactions', {
      query: { organizationId, splitParentId: transactionId },
    });

    await patch(this, 'yodlee-transactions', transactionId, {
      split: false,
    });

    onSplitTransaction();
    closeDialog();
  };

  render() {
    const { closeDialog, classes } = this.props;
    const { loading, splitChildren } = this.state;

    return (
      <Dialog
        open
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && (
          <form onSubmit={this.unsplitYodleeTransaction}>
            <DialogTitle id="alert-dialog-title">
              Really Unsplit Transaction?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`The unsplit transaction will be restored to your import feed in unbooked
                  status and all `}
                <strong>
                  {`${splitChildren.length} associated split transactions`}
                </strong>
                {' will be deleted.'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                className={classes.unsplitConfirmationButton}
              >
                Unsplit
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

UnsplitYodleeTransactionDialog.contextType = PersonContext;

UnsplitYodleeTransactionDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onSplitTransaction: PropTypes.func.isRequired,
  transactionId: PropTypes.number.isRequired,
};

export default withStyles(styles)(UnsplitYodleeTransactionDialog);
