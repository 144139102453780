import moment from 'moment';
import queryString from 'query-string';
import {
  asyncHandleChange,
} from './InputHandlers';

import {
  getPeriodOptions,
  getTransactionTypeOptions,
  getRecieptFilterOptions,
  getReconciliationStatusOptions,
} from '../components/Autocomplete/Library';

import history from '../history';
import { get } from '../feathersWrapper';

export const parseQuery = async (component) => {
  const { location } = component.props;
  const { filter } = component.state;
  const parsedQuery = queryString.parse(location.search);
  const newFilter = { ...filter };

  if (parsedQuery.startDate && moment(parsedQuery.startDate).isValid()) {
    newFilter.startDate = parsedQuery.startDate;
    newFilter.startDateSelect = moment(parsedQuery.startDate, 'YYYY-MM-DD');
  }

  if (parsedQuery.endDate && moment(parsedQuery.endDate).isValid()) {
    newFilter.endDate = parsedQuery.endDate;
    newFilter.endDateSelect = moment(parsedQuery.endDate, 'YYYY-MM-DD');
  }

  if (parsedQuery.entityId && parsedQuery.entityId !== 'None') {
    const entity = await get(component, 'entities', parsedQuery.entityId);
    newFilter.entityId = parsedQuery.entityId;
    newFilter.entityIdSelect = { ...entity, label: entity.name, value: entity.id };
  }

  if (parsedQuery.entityId && parsedQuery.entityId === 'None') {
    newFilter.entityId = parsedQuery.entityId;
    newFilter.entityIdSelect = { name: 'No Sub-Portfolio Assigned', id: 'None' };
  }

  if (parsedQuery.propertyId && parsedQuery.propertyId !== 'None') {
    const property = await get(component, 'properties', parsedQuery.propertyId);
    newFilter.propertyId = parsedQuery.propertyId;
    newFilter.propertyIdSelect = { ...property, label: property.address1, value: property.id };
    newFilter.selectedProperty = property;
  }

  if (parsedQuery.propertyId && parsedQuery.propertyId === 'None') {
    newFilter.propertyId = parsedQuery.propertyId;
    newFilter.propertyIdSelect = { address1: 'No Property Assigned', id: 'None' };
  }

  if (parsedQuery.unitId && parsedQuery.unitId !== 'None') {
    const unit = await get(this, 'units', parsedQuery.unitId);
    newFilter.unitId = parsedQuery.unitId;
    newFilter.unitIdSelect = { ...unit, label: unit.name, value: unit.id };
  }

  if (parsedQuery.unitId && parsedQuery.unitId === 'None') {
    newFilter.unitId = parsedQuery.unitId;
    newFilter.unitIdSelect = { name: 'No Unit Assigned', id: 'None' };
  }

  if (parsedQuery.accountId) {
    const account = await get(this, 'accounts', parsedQuery.accountId);
    newFilter.accountId = parsedQuery.accountId;
    newFilter.accountIdSelect = account;
  }

  if (parsedQuery.revenueAccountId) {
    const revenueAccount = await get(this, 'accounts', parsedQuery.revenueAccountId);
    newFilter.revenueAccountId = parsedQuery.revenueAccountId;
    newFilter.revenueAccountIdSelect = revenueAccount;
  }

  if (parsedQuery.expenseAccountId) {
    const expenseAccount = await get(this, 'accounts', parsedQuery.expenseAccountId);
    newFilter.expenseAccountId = parsedQuery.expenseAccountId;
    newFilter.expenseAccountIdSelect = expenseAccount;
  }

  if (parsedQuery.partnerAccountId) {
    const partnerAccount = await get(this, 'accounts', parsedQuery.partnerAccountId);
    newFilter.partnerAccountId = parsedQuery.partnerAccountId;
    newFilter.partnerAccountIdSelect = partnerAccount;
  }

  if (parsedQuery.vendorId) {
    const vendor = await get(component, 'vendors', parsedQuery.vendorId);
    newFilter.vendorId = parsedQuery.vendorId;
    newFilter.vendorIdSelect = vendor;
  }

  if (parsedQuery.period) {
    const foundPeriod = getPeriodOptions.filter((item) => item.id === parsedQuery.period);
    if (foundPeriod.length > 0) {
      [newFilter.periodSelect] = foundPeriod;
      newFilter.period = parsedQuery.period;
    }
  }

  if (parsedQuery.transactionType) {
    const foundType = getTransactionTypeOptions.filter(
      (item) => item.id === parsedQuery.transactionType,
    );
    if (foundType.length > 0) {
      [newFilter.transactionTypeSelect] = foundType;
      newFilter.transactionType = parsedQuery.transactionType;
    }
  }

  if (parsedQuery.receiptStatusSelect) {
    const foundType = getRecieptFilterOptions.filter(
      (item) => item.id === parsedQuery.receiptStatusSelect,
    );
    if (foundType.length > 0) {
      [newFilter.receiptStatusSelect] = foundType;
    }
  }

  if (parsedQuery.reconciliationStatusSelect) {
    const foundType = getReconciliationStatusOptions.filter(
      (item) => item.id === parsedQuery.reconciliationStatusSelect,
    );
    if (foundType.length > 0) {
      [newFilter.reconciliationStatusSelect] = foundType;
    }
  }

  if (parsedQuery.amount) {
    newFilter.amount = parsedQuery.amount;
  }

  if (parsedQuery.proration !== undefined) {
    newFilter.proration = parsedQuery.proration === 'true';
  }

  if (parsedQuery.mileage !== undefined) {
    newFilter.mileage = parsedQuery.mileage === 'true';
  }

  if (parsedQuery.inactive !== undefined) {
    newFilter.inactive = parsedQuery.inactive === 'true';
  }

  if (Object.keys(parsedQuery).length > 0) {
    await asyncHandleChange('filter', newFilter, component);
  }
};

export const buildQuery = (component, useStartEndDate) => {
  const { filter } = component.state;
  const { organizationId } = component.context;
  let newQueryString = '';
  const query = {
    organizationId,
  };

  const pageSize = filter.pageSize ? filter.pageSize : 20;
  query.$limit = pageSize;
  query.$skip = filter.page * pageSize;

  if (filter.startDate) {
    if (useStartEndDate) {
      query.startDate = filter.startDate;
    } else {
      query.date = { $gte: filter.startDate };
    }
    newQueryString += `&startDate=${filter.startDate}`;
  }

  if (filter.endDate) {
    if (useStartEndDate) {
      query.endDate = filter.endDate;
    } else if (query.date) {
      query.date.$lte = filter.endDate;
    } else {
      query.date = { $lte: filter.endDate };
    }
    newQueryString += `&endDate=${filter.endDate}`;
  }

  if (filter.entityId) {
    if (filter.entityId === 'None') {
      query.entityId = null;
    } else {
      query.entityId = filter.entityId;
    }
    newQueryString += `&entityId=${filter.entityId}`;
  }

  if (filter.propertyId && filter.propertyId !== 'All') {
    if (filter.propertyId === 'None') {
      query.propertyId = null;
    } else {
      query.propertyId = filter.propertyId;
    }
    newQueryString += `&propertyId=${filter.propertyId}`;
  }

  if (filter.unitId) {
    if (filter.unitId === 'None') {
      query.unitId = null;
    } else {
      query.unitId = filter.unitId;
    }
    newQueryString += `&unitId=${filter.unitId}`;
  }

  if (filter.accountIdSelect && filter.accountIdSelect.id !== 'All') {
    query.accountId = filter.accountIdSelect.id;
    newQueryString += `&accountId=${filter.accountIdSelect.id}`;
  }

  if (filter.revenueAccountIdSelect && filter.revenueAccountIdSelect.id !== 'All') {
    query.accountId = filter.revenueAccountIdSelect.id;
    newQueryString += `&revenueAccountId=${filter.revenueAccountIdSelect.id}`;
  }

  if (filter.expenseAccountIdSelect && filter.expenseAccountIdSelect.id !== 'All') {
    query.accountId = filter.expenseAccountIdSelect.id;
    newQueryString += `&expenseAccountId=${filter.expenseAccountIdSelect.id}`;
  }

  if (filter.partnerAccountIdSelect && filter.partnerAccountIdSelect.id !== 'All') {
    query.accountId = filter.partnerAccountIdSelect.id;
    newQueryString += `&partnerAccountId=${filter.partnerAccountIdSelect.id}`;
  }

  if (filter.vendorIdSelect && filter.vendorIdSelect.id !== 'All') {
    query.vendorId = filter.vendorIdSelect.id;
    newQueryString += `&vendorId=${filter.vendorIdSelect.id}`;
  }

  if (filter.period) {
    query.period = filter.period;
    newQueryString += `&period=${filter.period}`;
  }

  if (filter.transactionTypeSelect && filter.transactionTypeSelect.id !== 'All') {
    query.transactionType = filter.transactionTypeSelect.id;
    newQueryString += `&transactionType=${filter.transactionTypeSelect.id}`;
  }

  if (filter.amount) {
    query.amount = filter.amount;
    newQueryString += `&amount=${filter.amount}`;
  }

  if (filter.description) {
    query.description = `%${filter.description}%`;
    newQueryString += `&description=${filter.description}`;
  }

  if (filter.proration !== undefined) {
    newQueryString += `&proration=${filter.proration}`;
  }

  if (filter.mileage !== undefined) {
    newQueryString += `&mileage=${filter.mileage}`;
  }

  if (filter.inactive) {
    query.inactive = filter.inactive;
    newQueryString += `&inactive=${filter.inactive}`;
  }

  if (filter.receiptStatusSelect && filter.receiptStatusSelect.id !== 'All') {
    query.receiptStatusSelect = filter.receiptStatusSelect.id;
    newQueryString += `&receiptStatusSelect=${filter.receiptStatusSelect.id}`;
  }

  if (filter.reconciliationStatusSelect && filter.reconciliationStatusSelect.id !== 'All') {
    if (filter.reconciliationStatusSelect.id === 'Reconciled Transactions') {
      query.reconciled = true;
    } else if (filter.reconciliationStatusSelect.id === 'Unreconciled Transactions') {
      query.reconciled = false;
    }
    newQueryString += `&reconciliationStatusSelect=${filter.reconciliationStatusSelect.id}`;
  }

  if (filter.basis) {
    query.basis = filter.basis;
  }

  // update url with new params if controling URL for page
  if (component.props.match) {
    if (newQueryString.length > 0) {
      history.replace(`${component.props.match.url}?${newQueryString.substring(1)}`);
    } else {
      history.replace(component.props.match.url);
    }
  }

  return query;
};

export const clearFilterFields = async (fieldList, updateFilterFunction, component) => {
  const { filter } = component.state;
  const newFilter = { ...filter };
  fieldList.forEach((fieldName) => {
    newFilter[fieldName] = null;
  });
  newFilter.page = 0;
  updateFilterFunction(newFilter);
};

export const appliedFilter = (updateFilterFunction, component) => {
  const { filter } = component.state;
  const filterArray = [];

  if (filter.startDateSelect && filter.endDateSelect) {
    filterArray.push({
      label: 'Between',
      value: `${filter.startDateSelect.format('M/D/YYYY')} and ${filter.endDateSelect.format('M/D/YYYY')}`,
    });
  } else if (filter.startDateSelect) {
    filterArray.push({
      label: 'On or After',
      value: filter.startDateSelect.format('M/D/YYYY'),
    });
  } else if (filter.endDateSelect) {
    filterArray.push({
      label: 'On or Before',
      value: filter.endDateSelect.format('M/D/YYYY'),
    });
  }
  if (filter.periodSelect) {
    filterArray.push({
      label: 'Reporting Period',
      value: filter.periodSelect.name,
    });
  }
  if (filter.transactionTypeSelect && filter.transactionTypeSelect.id !== 'All') {
    filterArray.push({
      label: 'Transaction Type',
      value: filter.transactionTypeSelect.name,
    });
  }
  if (filter.reconciliationStatusSelect && filter.reconciliationStatusSelect.id !== 'All') {
    filterArray.push({
      label: 'Reconciliation Status',
      value: filter.reconciliationStatusSelect.name,
    });
  }
  if (filter.receiptStatusSelect && filter.receiptStatusSelect.id !== 'All') {
    filterArray.push({
      label: 'Receipt Status',
      value: filter.receiptStatusSelect.name,
    });
  }
  if (filter.entityIdSelect) {
    filterArray.push({
      label: 'Sub-Portfolio',
      value: filter.entityIdSelect.name ? filter.entityIdSelect.name : filter.entityIdSelect.label,
    });
  }
  if (filter.propertyIdSelect && filter.propertyIdSelect.id !== 'All') {
    filterArray.push({
      label: 'Property',
      value: filter.propertyIdSelect.address1
        ? filter.propertyIdSelect.address1
        : filter.propertyIdSelect.label,
    });
  }
  if (filter.unitIdSelect) {
    filterArray.push({
      label: 'Unit',
      value: filter.unitIdSelect.name ? filter.unitIdSelect.name : filter.unitIdSelect.label,
    });
  }
  if (filter.accountIdSelect && filter.accountIdSelect.id !== 'All') {
    filterArray.push({
      label: 'Account',
      value: filter.accountIdSelect.name,
    });
  }
  if (filter.vendorIdSelect && filter.vendorIdSelect.id !== 'All') {
    filterArray.push({
      label: 'Vendor',
      value: filter.vendorIdSelect.name,
    });
  }
  if (filter.amount) {
    filterArray.push({
      label: 'Transaction Amount',
      value: `$${Number.parseFloat(filter.amount).toFixed(2)}`,
    });
  }
  if (filter.description) {
    filterArray.push({
      label: 'Description Contains',
      value: filter.description,
    });
  }
  if (filter.proration) {
    filterArray.push({
      label: 'Proration',
      value: 'Included',
    });
  }
  if (filter.mileage) {
    filterArray.push({
      label: 'Mileage',
      value: 'Included',
    });
  }
  if (filter.inactive) {
    filterArray.push({
      label: 'Status',
      value: 'Inactive',
    });
  }

  return filterArray;
};
