import { useEffect, useState } from 'react';

import { REQUIREMENTS, STATUSES } from '~/constants/password-requirements';

function hasCorrectLength(password) {
  return password.length >= 8;
}

function hasCorrectLetterCase(password) {
  return /[a-z]/.test(password) && /[A-Z]/.test(password);
}

function hasNumber(password) {
  return /\d/.test(password);
}

function hasSpecialChar(password) {
  return /[^a-zA-Z0-9\s]/.test(password);
}

export default function usePasswordRequirements(password, isPasswordSubmitted) {
  const [requirements, setRequirements] = useState({
    [REQUIREMENTS.LENGTH]: STATUSES.INIT,
    [REQUIREMENTS.LETTER_CASE]: STATUSES.INIT,
    [REQUIREMENTS.NUMBER]: STATUSES.INIT,
    [REQUIREMENTS.SPECIAL_CHAR]: STATUSES.INIT,
  });

  useEffect(() => {
    if (!isPasswordSubmitted) {
      setRequirements({
        [REQUIREMENTS.LENGTH]:
          password.length && hasCorrectLength(password) ? STATUSES.MET : STATUSES.INIT,
        [REQUIREMENTS.LETTER_CASE]:
          password.length && hasCorrectLetterCase(password) ? STATUSES.MET : STATUSES.INIT,
        [REQUIREMENTS.NUMBER]:
          password.length && hasNumber(password) ? STATUSES.MET : STATUSES.INIT,
        [REQUIREMENTS.SPECIAL_CHAR]:
          password.length && hasSpecialChar(password) ? STATUSES.MET : STATUSES.INIT,
      });
    } else {
      setRequirements({
        [REQUIREMENTS.LENGTH]: hasCorrectLength(password) ? STATUSES.MET : STATUSES.ERROR,
        [REQUIREMENTS.LETTER_CASE]:
          hasCorrectLetterCase(password) ? STATUSES.MET : STATUSES.ERROR,
        [REQUIREMENTS.NUMBER]: hasNumber(password) ? STATUSES.MET : STATUSES.ERROR,
        [REQUIREMENTS.SPECIAL_CHAR]: hasSpecialChar(password) ? STATUSES.MET : STATUSES.ERROR,
      });
    }
  }, [isPasswordSubmitted, password]);

  return requirements;
}
