import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { PersonContext } from '../../contexts/PersonContext';

import { find, create } from '../../feathersWrapper';

import {
  filterForProperty,
} from '../../functions/SumFunctions';

import LinkBase from '../../components/LinkBase';
import ErrorCard from '../../components/ErrorCard';
import CardBase from '../../components/CardBase';
import history from '../../history';

const styles = (theme) => ({
  connectionsButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
});

class BankingCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      erroredAccount: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { organizationId } = this.context;
    const myAccountsPromise = find(this, 'accounts', {
      query: {
        organizationId,
        type2: ['Bank', 'Credit Card'],
        inactive: { $or: [null, false] },
        $limit: 100,
        $sort: {
          name: 1,
        },
        includeYodleeAccounts: true,
        includePlaidAccounts: true,
      },
    });

    const unmatchedTransactionsPromise = create(this, 'reports', {
      organizationId,
      reportName: 'countUnmatchedTransactions',
    });

    // await new Promise((resolve) => setTimeout(resolve, 250));
    // resolve async results
    const myAccounts = await myAccountsPromise;
    const unmatchedTransactions = await unmatchedTransactionsPromise;

    let erroredAccount = false;
    myAccounts.data.forEach((account) => {
      if (account.plaid_account && account.plaid_account.plaid_item.requiresUpdate === true) {
        erroredAccount = true;
      }
      if (account.yodleeAccountId && account.yodlee_account.accountData.dataset[0].updateEligibility === 'ALLOW_UPDATE_WITH_CREDENTIALS') {
        erroredAccount = true;
      }
    });

    // myAccounts.data.length = 3;
    if (myAccounts.data.length > 3) {
      myAccounts.data.splice(3, myAccounts.data.length - 3);
    }

    myAccounts.data.forEach((account) => {
      // eslint-disable-next-line no-param-reassign
      account.unmatchedTransactionCount = filterForProperty(unmatchedTransactions, 'accountId', account.id, 'unmatchedTransactionCount', 0);
    });

    this.setState({
      myAccounts: myAccounts.data,
      totalMyAccounts: myAccounts.total,
      loading: false,
      erroredAccount,
    });
  };

  render() {
    const {
      loading, error, myAccounts, totalMyAccounts, erroredAccount,
    } = this.state;
    const { theme, classes } = this.props;

    if (loading) {
      return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Banking
              </Typography>
            </Box>
          </CardBase>
        </Grid>
      );
    }
    if (error) {
      return <ErrorCard error={error.message} />;
    }

    return (
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <CardBase>
          <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="h6" gutterBottom>
                Banking
              </Typography>
              {erroredAccount && (
              <Box style={{ justifySelf: 'flex-end' }}>
                <Button
                  className={classes.connectionsButton}
                  variant="outlined"
                  onClick={() => { history.push('/accounts/banking/connections'); }}
                  fullWidth
                >
                  Refresh Connections
                </Button>
              </Box>
              )}
            </Box>
            {myAccounts.length === 0 && (
              <Typography variant="subtitle2" gutterBottom>No accounts created</Typography>
            )}
            {myAccounts.map((account) => (
              <Box borderBottom={`1px solid ${theme.palette.divider}`} paddingBottom={2} key={account.id}>
                <Typography variant="subtitle2" gutterBottom>
                  {account.name}
                </Typography>
                {account.lastCurrentBalance !== null && (
                  <Box paddingLeft={2}>
                    <Typography variant="body2">
                      Bank Balance:&nbsp;
                      <NumberFormat
                        displayType="text"
                        value={account.lastCurrentBalance}
                        thousandSeparator
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale
                      />
                      {` on ${moment(account.lastCurrentBalanceDate).format('M/DD/YY')}`}
                    </Typography>
                    {account.unmatchedTransactionCount > 0 && (
                      <LinkBase to={`/accounts/banking/import-feed?accountId=${account.id}&status=Unbooked`}>
                        {`${account.unmatchedTransactionCount} unbooked transactions`}
                      </LinkBase>
                    )}
                  </Box>
                )}
              </Box>
            ))}
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
              <LinkBase to="/accounts/banking">
                {`View Accounts (${totalMyAccounts})`}
              </LinkBase>
            </Box>
          </Box>
        </CardBase>
      </Grid>
    );
  }
}

BankingCard.contextType = PersonContext;

BankingCard.defaultProps = {};

BankingCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  // view: PropTypes.string.isRequired,
  // id: PropTypes.node,
  theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles, { withTheme: true })(BankingCard);
