import React from 'react';
import { Box } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import PageGrid from '~/components/PageGrid';

import FilterDialog from '../components/FilterDialog';
import PageHeader from '../components/PageHeader';
import { PersonContext } from '../contexts/PersonContext';
import { asyncHandleChange, handleChange } from '../functions/InputHandlers';

import BankingCard from './cards/BankingCard';
import BookValueCard from './cards/BookValueCard';
import ExpensesCard from './cards/ExpensesCard';
import GraphsCard from './cards/GraphsCard';
import PropertiesCard from './cards/PropertiesCard';
import QuicklinksCard from './cards/QuicklinksCard';
import ValueCard from './cards/ValueCard';

const styles = (theme) => ({
  headerContainer: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
});

class Home extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { checkCache, checkLogin } = this.context;
    checkCache();
    await checkLogin();
    this.setState({
      stale: {
        cashflow: false,
        expenses: false,
      },
      filterDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
      loading: false,
    });
  }

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.setState({ stale: { cashflow: true, expenses: true } });
  };

  markStale = (area) => {
    handleChange(`nested_stale_${area}`, true, this);
  };

  removeStale = (area) => {
    handleChange(`nested_stale_${area}`, false, this);
  };

  render() {
    const { classes, match } = this.props;
    const { filter, stale, filterDialog, loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        <Box className={classes.headerContainer}>
          <PageHeader match={match} title="Portfolio Dashboard" />
        </Box>
        <PageGrid isMultiCard>
          <FilterDialog
            filter={filter}
            isOpen={filterDialog}
            closeDialog={() => this.setState({ filterDialog: false })}
            updateFilter={this.updateFilter}
            dateRange
          />
          <GraphsCard view="portfolio" id={null} />
          <QuicklinksCard markStale={this.markStale} view="portfolio" id={null} />
          <PropertiesCard />
          <ValueCard view="portfolio" id={null} />
          <BankingCard />
          <ExpensesCard
            stale={stale.expenses}
            removeStale={this.removeStale}
            view="portfolio"
            id={null}
            startDate={filter.startDate ? filter.startDate : undefined}
            endDate={filter.endDate ? filter.endDate : undefined}
          />
          <BookValueCard view="portfolio" id={null} />
        </PageGrid>
      </>
    );
  }
}

Home.contextType = PersonContext;

Home.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Home);
