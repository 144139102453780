import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import history from '../history';
import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine from '../components/FinancialAccountLine';

class TrialBalance extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        entityId: null,
        propertyId: null,
        unitId: null,
        startDateSelect: null,
        endDateSelect: null,
        entityIdSelect: null,
        propertyIdSelect: null,
        unitIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const accountsChangeReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
    });
    const startingAccountsReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
    });
    startingAccountsReportFilter.date.$lte = moment(filter.startDate).subtract(1, 'days').format('YYYY-MM-DD');
    delete startingAccountsReportFilter.date.$gte;

    const headers = [
      'Debit',
      'Credit',
    ];

    // launch async calls
    const accountsChangePromise = create(this, 'reports', accountsChangeReportFilter);
    const startingAccountsPromise = create(this, 'reports', startingAccountsReportFilter);

    const accountsArray = await accountsChangePromise;
    const accountsObject = {
      assets: {
        accounts: [],
      },
      liabilities: {
        accounts: [],
      },
      equity: {
        accounts: [],
      },
      revenues: {
        accounts: [],
      },
      expenses: {
        accounts: [],
      },
      byId: {},
    };

    // sort accounts
    accountsArray.forEach((account) => {
      /* eslint-disable no-param-reassign */
      account.display = false;
      account.subaccounts = [];
      /* eslint-enable no-param-reassign */
      if (account.type === 'Asset') {
        accountsObject.assets.accounts.push(account);
      } else if (account.type === 'Liability') {
        accountsObject.liabilities.accounts.push(account);
      } else if (account.type === 'Equity') {
        accountsObject.equity.accounts.push(account);
      } else if (account.type === 'Revenue') {
        accountsObject.revenues.accounts.push(account);
      } else if (account.type === 'Expense') {
        accountsObject.expenses.accounts.push(account);
      }

      accountsObject.byId[account.id] = account;
    });

    accountsArray.forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
      }
    });

    const startingAccountsArray = await startingAccountsPromise;
    startingAccountsArray.forEach((startingAccount) => {
      const account = accountsObject.byId[startingAccount.id];
      if (startingAccount.netCredits !== 0) {
        account.display = true;
        if (startingAccount.parentAccountId) {
          accountsObject.byId[startingAccount.parentAccountId].display = true;
        }
      }
      account.startingCredits = startingAccount.netCredits;
      account.creditBalance = account.startingCredits + account.netCredits;
    });

    const retainedEarnings = {
      startingCredits: 0,
      values: [],
    };

    const totals = {
      debits: 0,
      credits: 0,
      values: [],
    };

    accountsArray.forEach((account) => {
      /* eslint-disable no-param-reassign */
      account.values = [];
      if (['Asset', 'Liability', 'Equity'].includes(account.type)) {
        if (account.creditBalance < 0) {
          account.values.push(-account.creditBalance);
          account.values.push(0);
          totals.debits -= account.creditBalance;
        } else {
          account.values.push(0);
          account.values.push(account.creditBalance);
          totals.credits += account.creditBalance;
        }

        if (account.creditBalance !== 0) {
          account.display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      } else {
        if (account.netCredits < 0) {
          account.values.push(-account.netCredits);
          account.values.push(0);
          totals.debits -= account.netCredits;
        } else {
          account.values.push(0);
          account.values.push(account.netCredits);
          totals.credits += account.netCredits;
        }

        if (account.netCredits !== 0) {
          account.display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }

        retainedEarnings.startingCredits += account.startingCredits;
      }

      /* eslint-enable no-param-reassign */
    });

    if (retainedEarnings.startingCredits < 0) {
      retainedEarnings.values.push(-retainedEarnings.startingCredits);
      retainedEarnings.values.push(0);
      totals.debits -= retainedEarnings.startingCredits;
    } else {
      retainedEarnings.values.push(0);
      retainedEarnings.values.push(retainedEarnings.startingCredits);
      totals.credits += retainedEarnings.startingCredits;
    }

    totals.values.push(totals.debits);
    totals.values.push(totals.credits);

    this.setState({
      loading: false,
      accountsObject,
      retainedEarnings,
      totals,
      headers,
    });
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const {
      filter, accountsObject, retainedEarnings, totals, headers, exportOptions,
    } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        retainedEarnings,
        totals,
        headers,
        exportOptions,
        reportName: 'Trial Balance',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'trial-balance.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Export', action: (() => this.setState({ downloadDialog: true })), class: 'export' },
    ]
  );

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      accountsObject, retainedEarnings, totals, headers,
      loading, filter, filterDialog, downloadDialog,
    } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Trial Balance"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property', 'Unit']}
          required={['dateRange', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          // exportPdf={this.exportPdf}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                  <FinancialAccountLine
                    values={headers}
                    bold
                    underline
                  />
                  {accountsObject.assets.accounts.map((account) => (
                    <FinancialAccountLine
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      startDate={filter.startDate}
                      endDate={filter.endDate}
                    />
                  ))}
                  {accountsObject.liabilities.accounts.map((account) => (
                    <FinancialAccountLine
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      startDate={filter.startDate}
                      endDate={filter.endDate}
                    />
                  ))}
                  {accountsObject.equity.accounts.map((account) => (
                    <FinancialAccountLine
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      startDate={filter.startDate}
                      endDate={filter.endDate}
                    />
                  ))}
                  <FinancialAccountLine
                    label="Retained Earnings"
                    values={retainedEarnings.values}
                  />
                  {accountsObject.revenues.accounts.map((account) => (
                    <FinancialAccountLine
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      startDate={filter.startDate}
                      endDate={filter.endDate}
                    />
                  ))}
                  {accountsObject.expenses.accounts.map((account) => (
                    <FinancialAccountLine
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      startDate={filter.startDate}
                      endDate={filter.endDate}
                    />
                  ))}
                  <FinancialAccountLine
                    label="Total"
                    values={totals.values}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

TrialBalance.contextType = PersonContext;

TrialBalance.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TrialBalance;
