import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import {
  ResponsiveContainer, ComposedChart,
  Bar, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import { axisLabels, tooltipFormatter } from '../../functions/ChartFunctions';

import { PersonContext } from '../../contexts/PersonContext';

import { create } from '../../feathersWrapper';

import {
  asyncHandleChange,
} from '../../functions/InputHandlers';
import {
  buildQuery,
} from '../../functions/FilterFunctions';

import CardBase from '../../components/CardBase';

class RevenuesGraphCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { filter } = this.props;
    if (filter) {
      await asyncHandleChange('filter', filter, this);
    }
    this.getData();
  }

  async componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (filter !== prevProps.filter) {
      // eslint-disable-next-line react/no-did-update-set-state
      await asyncHandleChange('filter', filter, this);
    }
    const { stale, removeStale } = this.props;
    if (stale) {
      this.getData();
      removeStale('revenuesGraph');
    }
  }

  getData = async () => {
    const { basis } = this.context;

    const bargraphData = await create(this, 'reports', Object.assign(buildQuery(this), {
      basis,
      reportName: 'cashflowCard',
    }));

    this.setState({
      bargraphData,
      loading: false,
    });
  };

  render() {
    const {
      loading, bargraphData,
    } = this.state;

    if (loading) {
      return (
        <Grid item xs={12} sm={6} md={6} lg={8}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Operating Revenues
              </Typography>
            </Box>
          </CardBase>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} sm={6} md={6} lg={8}>
        <CardBase>
          <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
            <Typography variant="h6" gutterBottom>
              Operating Revenues
            </Typography>
            <ResponsiveContainer width="100%" height={275}>
              <ComposedChart
                data={bargraphData}
                margin={{
                  top: 30,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="name" />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={axisLabels}
                  domain={['auto', 1000]}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                />
                <Legend />
                <Bar name="Op. Revenues" stackId="x" dataKey="revenue" fill="#82CA9D" barSize={30} isAnimationActive={false} />
                <ReferenceLine y={0} stroke="#000" />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </CardBase>
      </Grid>
    );
  }
}

RevenuesGraphCard.contextType = PersonContext;

RevenuesGraphCard.defaultProps = {
  stale: false,
  removeStale: null,
};

RevenuesGraphCard.propTypes = {
  stale: PropTypes.bool,
  removeStale: PropTypes.func,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default RevenuesGraphCard;
