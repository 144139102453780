import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  handleCheckboxChange,
  handleTextFieldChange,
} from '../functions/InputHandlers';

import { create } from '../feathersWrapper';

import { PersonContext } from '../contexts/PersonContext';

const styles = {};

class ReferralProgramDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setState(this.getInitialState());
  }

  getInitialState = () => {
    const initialState = {
      loading: false,
      submitting: false,
      step: 'tracker',
      error: null,
      wasReferred: false,
      referredBy: '',
    };

    return initialState;
  };

  contactUs = async (event) => {
    event.preventDefault();
    const {
      submitting, wasReferred, referredBy,
    } = this.state;

    const { organizationId } = this.context;

    if (!wasReferred) {
      this.setState({ step: 'thanks' });
      return;
    }

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    await create(
      this,
      'contact-us',
      {
        organizationId,
        personContext: this.context,
        subject: 'Referral Submitted',
        message: `Referred By: ${referredBy}`,
      },
    );
    this.setState({ step: 'thanks' });
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, error, wasReferred, referredBy, step,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        maxWidth="sm"
        fullWidth
        onClose={closeDialog}
        onEnter={() => { this.setState(this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="whoReferredDialog"
      >
        {step === 'tracker' && (
          <form onSubmit={this.contactUs}>
            <DialogTitle id="alert-dialog-title">Referral Program</DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" gutterBottom>
                {`Please let us know if another REI Hub user referred you to our software. 
                We would love to thank them.`}
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={wasReferred}
                    name="wasReferred"
                    onChange={handleCheckboxChange(this)}
                  />
                )}
                label="I was referred by a friend"
              />
              <TextField
                label="Referred By"
                required={wasReferred}
                disabled={!wasReferred}
                fullWidth
                variant="filled"
                color="secondary"
                margin="dense"
                helperText="Name and (if known) email"
                InputProps={{
                  value: referredBy,
                  name: 'referredBy',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                {wasReferred ? 'Submit' : 'I Was Not Referred'}
              </Button>
            </DialogActions>
          </form>
        )}
        {step === 'thanks' && (
          <>
            <DialogTitle id="alert-dialog-title">Referral Program</DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" gutterBottom>
                {`Thanks for letting us know. 
                  If you would like to learn more about our referral program, please click the link below.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} variant="contained" color="primary" disableElevation>
                Close
              </Button>
              <Button onClick={() => this.setState({ step: 'learnMore' })} color="primary">
                Learn More
              </Button>
            </DialogActions>
          </>
        )}
        {step === 'learnMore' && (
          <>
            <DialogTitle id="alert-dialog-title">Referral Program</DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" gutterBottom>
                <strong>
                  {`Bring a friend to REI Hub and we will give you both $50 
                    in bill credits when they subscribe.`}
                </strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                {`If we have helped you keep better books, please consider sharing us with a friend. 
                  We respect privacy and won't ask you for their email. You can copy and paste the text below into a message or use your own.`}
              </Typography>
              <Box my={1.5} mx={4} fontStyle="italic">
                <Typography variant="body1">
                  {`I use REI Hub to keep the books for my rentals. 
                    Their accounting software is designed specifically for investment property owners so it has been easy to set up and use with my portfolio. 
                    If you've ever been frustrated by bookkeeping, you can visit https://www.reihub.net to learn more and sign up for a free trial.`}
                </Typography>
              </Box>
              <Typography variant="body1">
                We work hard each day to support you and your portfolio; thank you for helping us.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} variant="contained" color="primary" disableElevation>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

ReferralProgramDialog.contextType = PersonContext;

ReferralProgramDialog.defaultProps = {
};

ReferralProgramDialog.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default withStyles(styles)(ReferralProgramDialog);
