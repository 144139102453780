import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';

import AuthenticateLayout from '~/layouts/Authenticate/Layout';
import { create, find, get } from '../../feathersWrapper';
import history from '../../history';

import { PersonContext } from '../../contexts/PersonContext';

import AddPortfolioDialog from '../../components/AddPortfolioDialog';
import RemovePortfolioDialog from '../../components/RemovePortfolioDialog';

const styles = {
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loginCard: {
    maxWidth: '500px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  accountantContainer: {
    width: '100%',
    marginBottom: '20px',
  },
};

class Organizations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      addPortfolio: false,
      removeOrganizationId: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const {
      userId,
      autoSelectOrganization,
      adminUserSelect,
    } = this.context;
    if (!userId) {
      await history.push('/sign-in');
      return;
    }

    let userIdLookup = userId;
    if (adminUserSelect && adminUserSelect.id) {
      userIdLookup = adminUserSelect.id;
    }

    const administrators = await find(this, 'administrators', {
      query: {
        userId: userIdLookup,
        $limit: 50,
      },
    });

    const user = await get(this, 'users', userIdLookup);

    if (administrators.total === 1 && autoSelectOrganization && !user.isAccountant) {
      this.selectOrganization(administrators.data[0].organizationId);
    } else {
      const organizationsResults = await find(this, 'organizations', {
        query: {
          id: administrators.data.map((admin) => admin.organizationId),
          $limit: 50,
        },
      });

      let firms = null;
      if (user.isAccountant) {
        const accountingAdminResults = await find(this, 'accounting-administrators', { query: { userId: userIdLookup } });
        firms = accountingAdminResults.data;
      }

      this.setState({
        organizations: organizationsResults.data,
        firms,
        loading: false,
      });
    }
  };

  selectOrganization = (organizationId) => {
    const {
      userId, setOrganizationId, adminUserSelect,
    } = this.context;
    if (!adminUserSelect) {
      create(this, 'logins', { userId, organizationId });
    }
    localStorage.setItem('organizationId', organizationId);
    setOrganizationId(organizationId);
  };

  subscriptionMessage = (organization) => {
    if (organization.partnerSubscribed) {
      return `Subscribed via ${organization.partnerName}`;
    }
    if (organization.stripeSubscription) {
      return 'Subscribed';
    }
    if (organization.partnerName === 'Innago') {
      return `Innago Trial Account: ${moment().diff(moment(organization.freeTrialExpires).endOf('day')) < 0
        ? `${moment(organization.freeTrialExpires).diff(moment(), 'days') + 1} days remaining`
        : 'Expired'}`;
    }
    return `Trial Account: ${moment().diff(moment(organization.freeTrialExpires).endOf('day')) < 0
      ? `${moment(organization.freeTrialExpires).diff(moment(), 'days') + 1} days remaining`
      : 'Expired'}`;
  };

  removeOption = (organization) => {
    if (organization.partnerSubscribed) {
      return null;
    }
    if (organization.stripeSubscription) {
      return null;
    }
    return (
      <Box component="div" color="error.main">
        <Button
          variant="text"
          color="inherit"
          size="small"
          disableElevation
          onClick={() => this.setState({ removeOrganizationId: organization.id })}
        >
          Remove
        </Button>
      </Box>

    );
  };

  render() {
    const { viewDemoPortfolio, setContextState } = this.context;
    const { classes, theme } = this.props;
    const {
      loading, addPortfolio, organizations, removeOrganizationId, firms,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <AuthenticateLayout>
        <Grid container justify="center" className={classes.root}>
          <AddPortfolioDialog
            isOpen={addPortfolio}
            closeDialog={() => this.setState({ addPortfolio: false })}
            selectOrganization={this.selectOrganization}
          />
          {removeOrganizationId && (
          <RemovePortfolioDialog
            closeDialog={() => this.setState({ removeOrganizationId: null })}
            organizationId={removeOrganizationId}
            onModified={() => this.getData()}
          />
          )}
          {firms && (
          <Box className={classes.accountantContainer}>
            <Grid item xs={12} className={classes.centerContent}>
              <Card className={classes.loginCard}>
                <Box display="flex" flexDirection="column" width={1} px={2} py={2}>
                  <Typography variant="h6" align="center" gutterBottom>
                    Choose an Accountant
                  </Typography>
                  {firms.map((firm) => (
                    <Box
                      marginTop={1}
                      paddingY={1}
                      borderBottom={`1px solid ${theme.palette.divider}`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      key={firm.id}
                    >
                      <Box>
                        <Typography variant="subtitle1">{firm.accounting_firm.name}</Typography>
                      </Box>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        size="large"
                        startIcon={<LaunchIcon />}
                        onClick={() => {
                          setContextState({
                            accountingFirmId: firm.accountingFirmId,
                            accountantLogin: 1,
                          });
                          localStorage.setItem('accountingFirmId', firm.accountingFirmId);
                        }}
                      >
                        View
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Card>
            </Grid>
          </Box>
          )}
          <Grid item xs={12} className={classes.centerContent}>
            <Card className={classes.loginCard}>
              <Box display="flex" flexDirection="column" width={1} px={2} py={2}>
                <Typography variant="h6" align="center" gutterBottom>
                  Choose a Portfolio
                </Typography>
                {organizations.map((organization) => (
                  <Box
                    marginTop={1}
                    paddingY={1}
                    borderBottom={`1px solid ${theme.palette.divider}`}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    key={organization.id}
                  >
                    <Box>
                      <Typography variant="subtitle1">{organization.name}</Typography>
                      <Typography variant="caption">{this.subscriptionMessage(organization)}</Typography>
                      {this.removeOption(organization)}
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      size="large"
                      startIcon={<LaunchIcon />}
                      onClick={() => this.selectOrganization(organization.id)}
                    >
                      {this.subscriptionMessage(organization) !== 'Trial Account: Expired' ? 'View' : 'Subscribe'}
                    </Button>
                  </Box>
                ))}
                <Box
                  marginTop="30px"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => this.setState({ addPortfolio: true })}
                  >
                    Add a portfolio
                  </Button>
                  <Box
                    marginTop={1.5}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={viewDemoPortfolio}
                    >
                      View demo portfolio
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </AuthenticateLayout>
    );
  }
}

Organizations.contextType = PersonContext;

Organizations.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles, { withTheme: true })(Organizations);
