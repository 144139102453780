import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CardContent from '@material-ui/core/CardContent';

import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import { asyncHandleChange } from '../functions/InputHandlers';

import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import LinkBase from '../components/LinkBase';
import FilterDialog from '../components/FilterDialog';

import AddUnitDialog from '../components/AddUnitDialog';

class Units extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      addUnitDialog: false,
      filter: {
        inactive: undefined,
        page: 0,
        pageSize: 100,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    await this.getData();
  }

  getData = async () => {
    const { organizationId } = this.context;
    const { match } = this.props;
    const { propertyId } = match.params;

    const query = {
      inactive: { $or: [false, null] },
      ...buildQuery(this),
      organizationId,
      propertyId,
      $sort: {
        name: 1,
        id: 1,
      },
    };

    const units = await find(this, 'units', { query });
    const collator = new Intl.Collator('en', { numeric: true });
    this.setState({
      units: units.data.sort((a, b) => collator.compare(a.name, b.name)),
      totalUnits: units.total,
      loading: false,
    });
  };

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getData();
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getData();
  };

  actionButtons = () => {
    const buttons = [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Add Unit', action: (() => this.setState({ addUnitDialog: true })), class: 'add' },
    ];
    return buttons;
  };

  render() {
    const { match } = this.props;
    const { propertyId } = match.params;
    const {
      loading, units, totalUnits, filter, filterDialog, addUnitDialog,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          actionButtons={this.actionButtons()}
          appliedFilter={appliedFilter(this.updateFilter, this)}
          title="Units"
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={() => this.setState({ filterDialog: false })}
          updateFilter={this.updateFilter}
          inactive
        />
        {addUnitDialog && (
          <AddUnitDialog
            closeDialog={() => this.setState({ addUnitDialog: false })}
            onAddUnit={this.getData}
            propertyId={propertyId}
          />
        )}

        <Grid item xs={12} md={8}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="subtitle2">Name</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {units.map((unit) => (
                    <TableRow key={unit.id}>
                      <TableCell>
                        <LinkBase to={`/properties/property/${unit.propertyId}/units/unit/${unit.id}`}>
                          {unit.name}
                        </LinkBase>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={totalUnits}
                      page={filter.page}
                      rowsPerPage={filter.pageSize}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[filter.pageSize]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

Units.contextType = PersonContext;

Units.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Units;
