import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
} from '../functions/InputHandlers';

class AddPropertyManagerDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  getInitialState = () => {
    const { organizationId, demo } = this.context;
    const initialState = {
      loading: false,
      submitting: false,
      step: 'add',
      propertyManager: {
        organizationId,
        name: '',
      },
      newPropertyManager: null,
      error: null,
    };

    if (demo) {
      initialState.step = 'demo';
    }
    return initialState;
  };

  addPropertyManager = async (event) => {
    event.preventDefault();
    const { submitting, propertyManager } = this.state;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    const newPropertyManager = await create(this, 'property-managers', propertyManager)
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });

    await create(this, 'accounts', {
      organizationId: newPropertyManager.organizationId,
      name: newPropertyManager.name,
      propertyManagerId: newPropertyManager.id,
      type: 'Asset',
      type2: 'Property Management',
    })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });

    this.setState({ step: 'success', newPropertyManager });
  };

  closeAfterCreate = () => {
    const { onAddPropertyManager } = this.props;
    const { newPropertyManager } = this.state;
    onAddPropertyManager(newPropertyManager);
    this.closeDialog();
  }

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, error, propertyManager, step,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onClose={closeDialog}
        onEnter={() => { this.setState(this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && step === 'demo' && (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Demo
              </Typography>
            </Box>
            <Typography variant="body1">
              You are not allowed to save changes in the demo portfolio.
            </Typography>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <Button
                onClick={this.closeDialog}
                color="secondary"
                variant="outlined"
                size="large"
                fullWidth
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        )}
        {!loading && step === 'add' && (
          <form onSubmit={this.addPropertyManager}>
            <DialogTitle id="alert-dialog-title">Add Property Manager</DialogTitle>
            <DialogContent>
              <TextField
                label="Name"
                fullWidth
                margin="dense"
                InputProps={{
                  value: propertyManager.name,
                  name: 'nested_propertyManager_name',
                  onChange: handleTextFieldChange(this),
                  required: true,
                }}
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Property Manager
              </Button>
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && step === 'success' && (
          <>
            <DialogTitle id="alert-dialog-title">Success</DialogTitle>
            <DialogContent>
              <Typography variant="body2">
                {`You have successfully added a property manager. 
                You should select the property manager’s account when 
                recording transactions that they make on your behalf 
                (collecting rent, paying expenses, etc). 
                Add a distribution when the property manager makes a payout 
                to one of your business bank accounts or to your personal funds.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeAfterCreate} color="primary" variant="contained" disableElevation>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

AddPropertyManagerDialog.contextType = PersonContext;

AddPropertyManagerDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddPropertyManager: PropTypes.func.isRequired,
};

export default AddPropertyManagerDialog;
