import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  Box, IconButton, InputAdornment, TextField,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import {
  asyncHandleChange,
} from '../../functions/InputHandlers';

import { find } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';

import CardBase from '../../components/CardBase';
import PageHeader from '../../components/PageHeader';

import AddPortfolioDialog from '../../components/AddPortfolioDialog';

const styles = (theme) => ({
  grid: {
    width: '100%',
    margin: 0,
  },
  inlineButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
});

class Portfolios extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addPortfolioDialog: false,
      filter: {
        page: 0,
        searchText: '',
      },
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  getData = async () => {
    const { accountingFirmId } = this.context;
    const { filter } = this.state;
    // launch async calls
    const query = {
      accountingFirmId,
      $limit: 20,
      $skip: filter.page * 20,
    };
    if (filter.searchText) {
      query.name = filter.searchText;
    }
    const clients = await find(this, 'accounting-clients', { query });

    this.setState({
      total: clients.total,
      clients: clients.data,
      loading: false,
    });
  };

  updateFilter = async (value) => {
    await asyncHandleChange('nested_filter_searchText', value, this);
    this.getData();
  };

  actionButtons = () => (
    [
      { text: 'Add Portfolio', action: (() => this.setState({ addPortfolioDialog: true })), class: 'add' },
    ]
  );

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getData();
  };

  closeDialogs = () => {
    this.setState({
      // editContactDialog: false,
      addPortfolioDialog: false,
    });
  };

  onDialogs = async () => {
    await this.getData();
    this.closeDialogs();
  };

  selectOrganization = (organizationId) => {
    const { setOrganizationId } = this.context;

    // create(this, 'logins', { userId: user.id, organizationId });
    localStorage.setItem('organizationId', organizationId);
    setOrganizationId(organizationId);
  };

  render() {
    const { classes } = this.props;
    const {
      loading, total, clients, filter, addPortfolioDialog,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Grid spacing={3} alignItems="center" justify="center" container className={classes.grid}>
        <PageHeader actionButtons={this.actionButtons()} title="Client Portfolios" />
        <AddPortfolioDialog
          isOpen={addPortfolioDialog}
          closeDialog={this.closeDialogs}
          selectOrganization={this.selectOrganization}
        />
        <Grid item xs={12} md={12}>
          <Box style={{ paddingBottom: '20px' }}>
            <CardBase>
              <CardContent>
                <Box style={{ width: '40%' }}>
                  <TextField
                    label="Filter by Name"
                    fullWidth
                    value={filter.searchText}
                    onChange={(event) => this.updateFilter(event.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              this.updateFilter('');
                            }}
                          >
                            <ClearIcon style={{ fontSize: 18 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </CardContent>
            </CardBase>
          </Box>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="subtitle2">Name</Typography></TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients.map((client) => (
                    <TableRow key={client.id}>
                      <TableCell>
                        {client.organization.name}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="large"
                          startIcon={<LaunchIcon />}
                          onClick={() => this.selectOrganization(client.organization.id)}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={total}
                      page={filter.page}
                      rowsPerPage={20}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[20]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </Grid>
    );
  }
}

Portfolios.contextType = PersonContext;

Portfolios.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  // match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Portfolios);
