import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import { find, create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import { sumProperty } from '../functions/SumFunctions';

import {
  asyncHandleChange,
} from '../functions/InputHandlers';

import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import PageHeader from '../components/PageHeader';
import PageGrid from '../components/PageGrid';
import CardBase from '../components/CardBase';
import FinancialAccountLine from '../components/FinancialAccountLine';
import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';

class PortfolioValueByProperty extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        entityId: null,
        propertyId: null,
        entityIdSelect: null,
        propertyIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    await this.updateReport();
  }

  updateReport = async () => {
    const { organizationId } = this.context;

    const idParam = {};
    const query = Object.assign(buildQuery(this), {
      inactive: false,
      $limit: 200,
      $skip: 0,
      $sort: {
        address1: 1,
        id: 1,
      },
    });
    const properties = await find(this, 'properties', { query });

    if (query.entityId) {
      // idParam.propertyId = properties.data.map((property) => property.id);
      idParam.entityId = query.entityId;
    }

    const loansPromise = find(this, 'accounts', {
      query: {
        organizationId,
        ...idParam,
        inactive: { $or: [null, false] },
        type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
        $limit: 200,
      },
    });

    const loanTransactionsSinceBalanceSummaryPromise = create(this, 'reports', {
      organizationId,
      ...idParam,
      reportName: 'loanTransactionsSinceBalanceSummary',
    });

    const loans = await loansPromise;
    const loanTransactionsSinceBalance = await loanTransactionsSinceBalanceSummaryPromise;

    const propertiesById = {};
    properties.data.forEach((property) => {
      // eslint-disable-next-line no-param-reassign
      property.loanBalance = 0;
      propertiesById[property.id] = property;
    });

    let unassignedLoanBalance = 0;
    const includedLoansById = {};

    loans.data.forEach((loan) => {
      if (loan.lastCurrentBalance) {
        if (loan.propertyId && propertiesById[loan.propertyId]) {
          propertiesById[loan.propertyId].loanBalance -= loan.lastCurrentBalance;
        } else {
          unassignedLoanBalance -= loan.lastCurrentBalance;
        }
      }
      includedLoansById[loan.id] = loan;
    });

    loanTransactionsSinceBalance.forEach((loan) => {
      if (includedLoansById[loan.id]) {
        if (loan.propertyId) {
          propertiesById[loan.propertyId].loanBalance -= loan.netCredits;
        } else {
          unassignedLoanBalance -= loan.netCredits;
        }
      }
    });

    const headers = properties.data.map((property) => property.address1);
    headers.unshift('Total');
    headers.push('None Assigned');

    const marketValues = properties.data.map((property) => property.marketValue);
    marketValues.unshift(parseFloat(sumProperty(properties.data, 'marketValue'), 10));
    marketValues.push(0);

    const loanBalances = properties.data.map((property) => property.loanBalance);
    loanBalances.unshift(
      parseFloat(sumProperty(properties.data, 'loanBalance'), 10) + unassignedLoanBalance,
    );
    loanBalances.push(unassignedLoanBalance);

    this.setState({
      loading: false,
      headers,
      marketValues,
      loanBalances,
    });
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const {
      filter, marketValues, loanBalances, headers, exportOptions,
    } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        marketValues,
        loanBalances,
        headers,
        exportOptions,
        reportName: 'Portfolio Value By Property',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'portfolio-value-by-property.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Export', action: (() => this.setState({ downloadDialog: true })), class: 'export' },
    ]
  );

  render() {
    const { match } = this.props;
    const {
      headers, marketValues, loanBalances, loading,
      filter, filterDialog, downloadDialog,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Portfolio Value By Property"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          scope={['Full Portfolio', 'Sub-Portfolio']}
          required={['scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                <FinancialAccountLine
                  values={headers}
                  bold
                />
                <FinancialAccountLine
                  label="Estimated Market Value"
                  values={marketValues}
                  decimalScale={0}
                />
                <FinancialAccountLine
                  label="Outstanding Loans"
                  values={loanBalances}
                  decimalScale={0}
                  marginBottom
                />
                <FinancialAccountLine
                  label="Estimated Owner Equity"
                  values={this.sumColumnValues([marketValues, loanBalances])}
                  decimalScale={0}
                  underline
                  overline
                />
              </Box>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

PortfolioValueByProperty.contextType = PersonContext;

PortfolioValueByProperty.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PortfolioValueByProperty;
