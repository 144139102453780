export const sumProperty = (items, prop, precision = 2) => (0.0001 + items.reduce(
  (a, b) => {
    if (b[prop] !== null) {
      return a + parseFloat(b[prop]);
    }
    return a;
  },
  0,
)).toFixed(precision);

export const sumNestedProperty = (items, prop, precision = 2) => {
  const pathSegments = prop.split('.');

  return (0.0001 + items.reduce(
    (a, b) => {
      let last = b;
      for (let i = 0; i < pathSegments.length; i += 1) {
        if (last[pathSegments[i]] !== null) {
          last = last[pathSegments[i]];
        } else {
          return a;
        }
      }
      return a + parseFloat(last);
    },
    0,
  )).toFixed(precision);
};

export const filterForProperty = (items, searchProp, search, findProp, notFound) => {
  const found = items.filter((item) => item[searchProp] === search);
  if (found.length > 0) {
    return found[0][findProp];
  }
  return notFound;
};
