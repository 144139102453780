import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

import PageGrid from '~/components/PageGrid';
import {
  asyncHandleChange,
} from '../functions/InputHandlers';

import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import ErrorCard from '../components/ErrorCard';
import CardBase from '../components/CardBase';
import AddContactDialog from '../components/AddContactDialog';
import EditContactDialog from '../components/EditContactDialog';
import PageHeader from '../components/PageHeader';

const styles = (theme) => ({
  inlineButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
});

class Contacts extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addContactDialog: false,
      editContactDialog: false,
      filter: {
        page: 0,
      },
    };
  }

  async componentDidMount() {
    await this.getContacts();
  }

  getContacts = async () => {
    const { organizationId } = this.context;
    const { filter } = this.state;
    try {
      // launch async calls
      const query = {
        organizationId,
        $limit: 20,
        $skip: filter.page * 20,
      };
      const contacts = await find(this, 'contacts', { query });

      this.setState({
        totalContacts: contacts.total,
        contacts: contacts.data,
        loading: false,
      });
    } catch (error) {
      this.setState({ error });
      this.setState({ loading: false });
    }
  };

  actionButtons = () => (
    [
      { text: 'Add Contact', action: (() => this.setState({ addContactDialog: true })), class: 'add' },
    ]
  );

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getContacts();
  };

  closeContactDialogs = () => {
    this.setState({
      editContactDialog: false,
      addContactDialog: false,
    });
  };

  onContactDialogs = async () => {
    await this.getContacts();
    this.closeContactDialogs();
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading, error, contacts, contactId, addContactDialog, editContactDialog,
      totalContacts, filter,
    } = this.state;

    return (
      <>
        {!loading && error && (
          <ErrorCard error={error.message} />
        )}
        {!loading && !error && (
          <PageGrid>
            <PageHeader match={match} actionButtons={this.actionButtons()} title="Contacts" />
            <AddContactDialog
              isOpen={addContactDialog}
              closeDialog={this.closeContactDialogs}
              onAddContact={this.onContactDialogs}
            />
            <EditContactDialog
              isOpen={editContactDialog}
              closeDialog={this.closeContactDialogs}
              onEditContact={this.onContactDialogs}
              contactId={contactId}
            />
            <Grid item xs={12} md={12}>
              <CardBase>
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><Typography variant="subtitle2">Name</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2">Email</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2">Phone</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contacts.map((contact) => (
                        <TableRow key={`contact-${contact.id}`}>
                          <TableCell>
                            {`${contact.person.firstName} ${contact.person.lastName}`}
                            <Button
                              className={classes.inlineButton}
                              aria-label="edit"
                              onClick={() => {
                                this.setState({
                                  contactId: contact.id,
                                  editContactDialog: true,
                                });
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </Button>
                          </TableCell>
                          <TableCell>
                            {contact.person.email}
                          </TableCell>
                          <TableCell>{contact.person.phone}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          variant="footer"
                          count={totalContacts}
                          page={filter.page}
                          rowsPerPage={20}
                          onChangePage={this.handleChangePage}
                          rowsPerPageOptions={[20]}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </CardBase>
            </Grid>
          </PageGrid>
        )}
      </>
    );
  }
}

Contacts.contextType = PersonContext;

Contacts.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Contacts);
