import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import history from '../history';
import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
  asyncSetState,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine from '../components/FinancialAccountLine';

class SecurityDepositsHeld extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const reportFilter = Object.assign(buildQuery(this, true), {
      basis,
      reportName: 'securityDepositsHeld',
    });

    // pull data
    const report = await create(this, 'reports', reportFilter);

    report.securityDepositsByProperty.forEach((property) => {
      if (property.unitSelection === 'multi') {
        const collator = new Intl.Collator('en', { numeric: true });
        property.units.sort((a, b) => collator.compare(a.name, b.name));
      }
    });

    await asyncSetState({
      loading: false,
      report,
    }, this);
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'security-deposits-held.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      // {
      //   text: 'Export',
      //   action: (() => this.setState({ downloadDialog: true })),
      //   class: 'export'
      // },
    ]
  );

  render() {
    const { match } = this.props;
    const {
      report,
      filter, filterDialog, loading,
      downloadDialog,
    } = this.state;

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Security Deposits Held"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          required={['dateRange']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                  <FinancialAccountLine
                    values={report.headers}
                    bold
                  />
                  {report.securityDepositsByProperty.map((property) => (
                    <React.Fragment key={property.id}>
                      <FinancialAccountLine
                        label={property.address1}
                        overline
                        bold
                      />
                      {property.unitSelection === 'multi' && property.units.map((unit) => (
                        <FinancialAccountLine
                          key={unit.id}
                          label={unit.name}
                          values={unit.securityDeposits}
                          indent={1}
                        />
                      ))}
                      {property.unitSelection === 'multi' && (
                        <FinancialAccountLine
                          label="Unassigned"
                          values={property.unassignedSecurityDeposits}
                          indent={1}
                        />
                      )}
                      <FinancialAccountLine
                        label="Total"
                        values={property.securityDeposits}
                        indent={1}
                        marginBottom
                      />
                    </React.Fragment>
                  ))}
                  <FinancialAccountLine
                    label="Portfolio"
                    values={report.totalSecurityDeposits}
                    underline
                    overline
                    bold
                  />
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

SecurityDepositsHeld.contextType = PersonContext;

SecurityDepositsHeld.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SecurityDepositsHeld;
