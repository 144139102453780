import PropTypes from 'prop-types';
import React from 'react';

export default function InventoryOutlined({ color, ...rest }) {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill={color} {...rest}>
      <path d="M7.99805 6.50147H5.71289V22.4976H12.5684V24.7827H5.71289C4.45606 24.7827 3.42773 23.7544 3.42773 22.4976V6.50147C3.42773 5.24463 4.45606 4.21631 5.71289 4.21631H10.4889C10.9688 2.89092 12.2256 1.93115 13.7109 1.93115C15.1963 1.93115 16.4531 2.89092 16.933 4.21631H21.709C22.9658 4.21631 23.9942 5.24463 23.9942 6.50147V12.2144H21.709V6.50147H19.4238V9.92921H7.99805V6.50147ZM14.8535 5.35889C14.8535 4.73047 14.3394 4.21631 13.7109 4.21631C13.0825 4.21631 12.5684 4.73047 12.5684 5.35889C12.5684 5.98731 13.0825 6.50147 13.7109 6.50147C14.3394 6.50147 14.8535 5.98731 14.8535 5.35889ZM17.7214 20.2124L23.9942 13.9282L25.708 15.6421L17.7214 23.6402L12.5684 18.4985L14.2822 16.7847L17.7214 20.2124Z" />
    </svg>
  );
}

InventoryOutlined.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

InventoryOutlined.defaultProps = {
  color: 'currentColor',
  height: 29,
  width: 28,
};
