import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import { Button } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';

import FilterDialog from '../components/FilterDialog';
import PageGrid from '../components/PageGrid';

import AddRecurringTransactionDialog from '../components/AddRecurringTransactionDialog';
import EditRecurringTransactionDialog from '../components/EditRecurringTransactionDialog';

export default function RecurringTransactions() {
  const match = useRouteMatch();
  const context = useContext(PersonContext);
  const { organizationId } = context;
  const [isLoading, setIsLoading] = useState(true);
  const [recurringTransactions, setRecurringTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [recurringTransactionToEdit, setRecurringTransactionToEdit] = useState(null);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [filter, setFilter] = useState({
    page: 0,
    propertyId: null,
    unitId: null,
    entityId: null,
    propertyIdSelect: null,
    unitIdSelect: null,
    entityIdSelect: null,
    amount: null,
    startDate: null,
    inactive: false,
    transactionType: null,
    transactionTypeSelect: { id: 'All', label: 'All Transactions' },
  });

  const getRecurringTransactions = async () => {
    const query = {
      organizationId,
      $limit: 10,
      $skip: filter.page * 10,
      $sort: {
        name: 1,
      },
      inactive: filter.inactive,
    };

    if (filter.transactionType) {
      query.type = filter.transactionType;
    }
    if (filter.entityId) {
      query.entityId = filter.entityId;
    }
    if (filter.propertyId) {
      query.propertyId = filter.propertyId;
    }
    if (filter.unitId) {
      query.unitId = filter.unitId;
    }

    const response = await find(this, 'recurring-transactions', { query });

    setRecurringTransactions(response.data);
    setTotalTransactions(response.total);
    setIsLoading(false);
  };

  useEffect(() => {
    getRecurringTransactions();
  }, [filter]);

  const updateFilter = (updatedFilter) => {
    const newFilter = { ...updatedFilter };
    if (
      updatedFilter.transactionTypeSelect.id
      && updatedFilter.transactionTypeSelect.id !== 'All'
    ) {
      newFilter.transactionType = updatedFilter.transactionTypeSelect.id;
    }
    if (updatedFilter.transactionTypeSelect.id === 'All') {
      newFilter.transactionType = null;
    }
    setFilter(newFilter);
  };

  const actionButtons = () => (
    [
      { text: 'Filter', action: (() => setIsFilterDialogOpen(true)), class: 'filter' },
      { text: 'Add Recurring Transaction', action: (() => setIsAddDialogOpen(true)), class: 'add' },
    ]
  );

  if (isLoading) {
    return null;
  }

  return (
    <PageGrid>
      <PageHeader
        match={match}
        title="Recurring Transactions"
        actionButtons={actionButtons()}
      />
      {isAddDialogOpen && (
        <AddRecurringTransactionDialog
          isOpen
          closeDialog={() => setIsAddDialogOpen(false)}
          onAddRecurringTransaction={() => getRecurringTransactions()}
        />
      )}
      {isEditDialogOpen && (
        <EditRecurringTransactionDialog
          isOpen
          closeDialog={() => setIsEditDialogOpen(false)}
          onEdit={() => getRecurringTransactions()}
          recurringTransactionToEdit={recurringTransactionToEdit}
        />
      )}
      <FilterDialog
        filter={filter}
        isOpen={isFilterDialogOpen}
        closeDialog={() => setIsFilterDialogOpen(false)}
        updateFilter={(updatedFilter) => { updateFilter(updatedFilter); }}
        transactionType
        transactionTypeFilters={['All', 'Expense', 'Revenue', 'Transfer']}
        scope={['Full Portfolio', 'Sub-Portfolio', 'Property', 'Unit']}
        inactive
        customLabel="Show Archived Transactions"
        customScopeLabel="Scope"
      />
      <Grid item xs={12}>
        <CardBase>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Amount
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Frequency
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Start Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      End Date
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {recurringTransactions.map((recurringTransaction) => (
                  <TableRow key={recurringTransaction.id}>
                    <TableCell>
                      <Typography variant="body2">
                        {recurringTransaction.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {recurringTransaction.type}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        <NumberFormat
                          displayType="text"
                          value={recurringTransaction.journal.amount}
                          thousandSeparator
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {recurringTransaction.frequency}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {moment(recurringTransaction.startDate).format('M/D/YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {
                          recurringTransaction.endDate
                            ? moment(recurringTransaction.endDate).format('M/D/YYYY')
                            : '-----'
                        }
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          setRecurringTransactionToEdit(recurringTransaction);
                          setIsEditDialogOpen(true);
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    variant="footer"
                    count={totalTransactions}
                    page={filter.page}
                    rowsPerPage={10}
                    onChangePage={(event, value) => {
                      const newFilter = { ...filter, page: value };
                      setFilter(newFilter);
                    }}
                    rowsPerPageOptions={[10]}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </CardBase>
      </Grid>
    </PageGrid>
  );
}
