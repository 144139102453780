import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import client from '../../feathers';

import { PersonContext } from '../../contexts/PersonContext';

const styles = (theme) => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(2),
    alignContent: 'flex-start',
  },
  pageCenter: {
    alignContent: 'center',
  },
  loginCard: {
    maxWidth: '500px',
    textAlign: 'center',
    width: '100%',
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  resetLink: {
    color: '#FFF',
    textDecoration: 'none',
  },
  noUnderlineLink: {
    textDecoration: 'none',
  },
});

class VerifyEmail extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      verificationComplete: false,
      submitting: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const parsed = queryString.parse(location.search);
    this.verifyEmailSubmit(parsed.token);
  }

  verifyEmailSubmit = (token) => {
    const { submitting } = this.state;
    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    const payload = {
      action: 'verifySignupLong',
      value: token,
    };
    const authManagementService = client.service('authManagement');
    authManagementService
      .create(payload)
      .then(() => {
        this.setState({ verificationComplete: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes } = this.props;
    const cx = classNames.bind(classes);
    const { verificationComplete, error } = this.state;

    return (
      <PersonContext.Consumer>
        {(person) => (
          <Grid container justify="center" className={cx({ root: true, pageCenter: !person.id })}>
            <Grid item xs={12} className={classes.centerContent}>
              {verificationComplete ? (
                <Card className={classes.loginCard}>
                  <CardContent>
                    <Typography>Thank you for verifying your email</Typography>
                    {person.id ? (
                      <Box marginTop={1}>
                        <Button
                          component={Link}
                          to="/dashboard"
                          color="secondary"
                          size="large"
                          variant="outlined"
                        >
                          Home
                        </Button>
                      </Box>
                    ) : (
                      <Box marginTop={1}>
                        <Button
                          component={Link}
                          to="/sign-in"
                          color="secondary"
                          size="large"
                          variant="outlined"
                        >
                          Sign In
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              ) : (
                <Card className={classes.loginCard}>
                  <CardContent>
                    <Typography>
                      {error
                        ? error.message
                        : 'Verifying your email'}
                    </Typography>
                    {person.id ? (
                      <Box marginTop={1}>
                        <Button
                          component={Link}
                          to="/dashboard"
                          color="secondary"
                          size="large"
                          variant="outlined"
                        >
                          Home
                        </Button>
                      </Box>
                    ) : (
                      <Box marginTop={1}>
                        <Button
                          component={Link}
                          to="/sign-in"
                          color="secondary"
                          size="large"
                          variant="outlined"
                        >
                          Sign In
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        )}
      </PersonContext.Consumer>
    );
  }
}

VerifyEmail.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(VerifyEmail);
