import React from 'react';
import { Box, DialogContent, Fab, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const SEGMENT_LOCATION = 'Onboarding - Add Properties: Intro';

const useStyles = makeStyles((theme) => ({
  heroImage: {
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '248px',
    },
  },
}));

export default function PropertiesOverview({ openVideoDialog, openAddPropertyDialog }) {
  const tracking = useSegmentTrack();
  const classes = useStyles();

  return (
    <DialogContent>
      <Box mx="auto" textAlign="center" mb={2}>
        <Box>
          <Box style={{ position: 'relative' }} mb={1}>
            <img className={classes.heroImage} src="./rei_hub_properties_intro_hero.webp" alt="Properties" />
            <Fab
              color="secondary"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
              }}
              onClick={() => {
                tracking('watch_properties_overview clicked', { location: SEGMENT_LOCATION });
                openVideoDialog();
              }}
            >
              <PlayCircleFilledIcon color="white" fontSize="24px" />
            </Fab>
          </Box>
          <Box mb={3}>
            <Typography variant="h6">Organize your portfolio by property</Typography>
            <Typography variant="body1">
              Categorize your transactions by property and create property-specific reports like your Schedule E (or
              Form 8825).
            </Typography>
          </Box>
          <Box maxWidth="400px" marginX="auto" textAlign="center" mb={2}>
            <Button
              hasFullWidth
              variant="contained"
              onClick={() => {
                tracking('add_first_property clicked', { location: SEGMENT_LOCATION });
                openAddPropertyDialog();
              }}
            >
              Add Your First Property
            </Button>
          </Box>
        </Box>
      </Box>
    </DialogContent>
  );
}

PropertiesOverview.propTypes = {
  openVideoDialog: PropTypes.func.isRequired,
  openAddPropertyDialog: PropTypes.func.isRequired,
};
