import React from 'react';
// import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { Button, CardContent } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { PersonContext } from '../../../contexts/PersonContext';

import CardBase from '../../../components/CardBase';
import CardTitle from '../../../components/CardTitle';

import EditOrganizationDialog from '../components/EditOrganizationDialog';

import AsyncAutocomplete from '../../../components/AsyncAutocomplete';

class AdminSearch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userSelect: null,
      portfolioSelect: null,
      editOrganizationDialog: false,
    };
  }

  render() {
    const {
      editOrganizationDialog,
      item,
      portfolioSelect,
      userSelect,
    } = this.state;
    const { setOrganizationId, setAdminUserSelect } = this.context;

    return (
      <>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <CardBase>
            <Box height={1} px={2} pt={2} pb={1}>
              <CardTitle title="User Search" />
              <CardContent>
                <AsyncAutocomplete
                  type="user"
                  onAddValue={(value) => {
                    this.setState({
                      userSelect: value ? { id: value.id } : null,
                    });
                  }}
                  label="Name/Email/Phone"
                />
                {userSelect && (
                  <Box pt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => {
                        setAdminUserSelect(userSelect);
                      }}
                    >
                      Go to User
                    </Button>
                  </Box>
                )}
              </CardContent>
            </Box>
          </CardBase>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <CardBase>
            <Box height={1} px={2} pt={2} pb={1}>
              <CardTitle title="Portfolio Search" />
              <CardContent>
                <AsyncAutocomplete
                  type="portfolio"
                  onAddValue={(value) => {
                    this.setState({ portfolioSelect: value ? { id: value.id } : null });
                  }}
                  label="Portfolio Name/ID"
                />
                {portfolioSelect && (
                  <Box pt={2} alignItems="right">
                    <Box pr={2} display="inline-block">
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => {
                          setOrganizationId(portfolioSelect.id);
                        }}
                      >
                        Go to Portfolio
                      </Button>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => {
                        this.setState({ editOrganizationDialog: true });
                      }}
                    >
                      Edit Portfolio
                      <EditIcon fontSize="small" />
                    </Button>
                  </Box>
                )}
              </CardContent>
            </Box>
          </CardBase>
        </Grid>
        {editOrganizationDialog && (
        <EditOrganizationDialog
          isOpen={editOrganizationDialog}
          closeDialog={() => this.setState({ editOrganizationDialog: false })}
          login={item}
          organizationId={portfolioSelect.id}
        />
        )}
      </>
    );
  }
}

AdminSearch.contextType = PersonContext;

AdminSearch.propTypes = {
};

export default AdminSearch;
