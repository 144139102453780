import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';

import history from '../history';
import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange, asyncSetState,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import CardBase from '../components/CardBase';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import ViewBookedTransactionDialog from '../components/ViewBookedTransactionDialog';

import FilterDialog from '../components/FilterDialog';
import FilterCard from './cards/FilterCard';
import DownloadDialog from '../components/DownloadDialog';

const styles = (theme) => ({
  red: {
    color: 'red',
  },
  tagButton: {
    padding: 0,
    color: theme.palette.action.active,
    minWidth: '32px',
  },
  tagSpacer: {
    minWidth: '32px',
    display: 'inline-block',
  },
  truncateOneLine: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

class AccountRegister extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      filter: {
        accountId: null,
        startDate: null,
        endDate: null,
        entityId: null,
        propertyId: null,
        unitId: null,
        transactionType: null,
        description: '',
        accountIdSelect: null,
        startDateSelect: null,
        endDateSelect: null,
        propertyIdSelect: { address1: 'All Properties', id: 'All' },
        unitIdSelect: null,
        entityIdSelect: null,
        transactionTypeSelect: { name: 'All Transactions', id: 'All' },
        page: 0,
        pageSize: 100,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);

    const result = await this.getJournals();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  getJournals = async () => {
    const { basis } = this.context;
    const { filter } = this.state;

    if (!filter.accountIdSelect) {
      return { success: false, message: 'Please complete all required fields' };
    }

    let query;
    let journalLinesResponse;
    let journalLines = [];
    do {
      query = Object.assign(buildQuery(this), {
        basis,
        accountId: filter.accountIdSelect.id,
        includeFullJournals: true,
        $dateSort: 'ASC',
      });
      // eslint-disable-next-line no-await-in-loop
      journalLinesResponse = await find(this, 'journal-lines', { query });
      journalLines = journalLines.concat(journalLinesResponse.data);
      filter.page += 1;
    } while (journalLines.length < journalLinesResponse.total);
    filter.page = 0;

    const journals = [];
    journalLines.forEach((journalLine) => {
      const { journal } = journalLine;
      // eslint-disable-next-line no-param-reassign
      journal.creditLines = [];
      // eslint-disable-next-line no-param-reassign
      journal.debitLines = [];
      journal.journalLines.forEach((line) => {
        if (line.id === journalLine.id) {
          // eslint-disable-next-line no-param-reassign
          journal.accountLine = line;
        }

        if (line.type === 'credit') {
          journal.creditLines.push(line);
        } else {
          journal.debitLines.push(line);
        }
      });
      journals.push(journal);
    });

    let runningBalance = 0;
    for (let i = 0; i < journals.length; i += 1) {
      if (['Asset', 'Expense'].includes(filter.accountIdSelect.type)) {
        if (journals[i].accountLine.type === 'debit') {
          journals[i].accountLine.value = journals[i].accountLine.debit;
          runningBalance += journals[i].accountLine.debit;
          journals[i].accountLine.balance = runningBalance;
        } else {
          journals[i].accountLine.value = -journals[i].accountLine.credit;
          runningBalance -= journals[i].accountLine.credit;
          journals[i].accountLine.balance = runningBalance;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (journals[i].accountLine.type === 'credit') {
          journals[i].accountLine.value = journals[i].accountLine.credit;
          runningBalance += journals[i].accountLine.credit;
          journals[i].accountLine.balance = runningBalance;
        } else {
          journals[i].accountLine.value = -journals[i].accountLine.debit;
          runningBalance -= journals[i].accountLine.debit;
          journals[i].accountLine.balance = runningBalance;
        }
      }
    }

    await asyncSetState({
      loading: false,
      journals,
    }, this);
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.getJournals();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  splitAccount = (journal) => {
    if (journal.accountLine.type === 'credit') {
      if (journal.debitLines.length === 1) {
        return journal.debitLines[0].account.name;
      }
      return 'Split';
    }
    if (journal.creditLines.length === 1) {
      return journal.creditLines[0].account.name;
    }
    return 'Split';
  };

  formatNegative = (amount) => {
    const { classes } = this.props;
    if (amount < 0) {
      return (
        <span className={classes.red}>
          {`(${-amount})`}
        </span>
      );
    }
    return amount;
  };

  /* formatNegativeBalance = (value) => {
    const { classes } = this.props;
    if (value[0] === '-') {
      return (
        <span className={classes.red}>
          {`(${value.substring(1)})`}
        </span>
      );
    }
    return value;
  }; */

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, exportOptions } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        account: filter.accountIdSelect,
        exportOptions,
        reportName: 'Account Register',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob(
          [await resp.blob()],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
        );
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'account-register.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
    ]
  );

  render() {
    const { match, classes } = this.props;
    const {
      loading, journals, filter,
      selectedJournal, filterDialog, downloadDialog,
    } = this.state;

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title={filter.accountId ? filter.accountIdSelect.name : 'Account Register'}
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          required={['account']}
          account
          dateRange
          transactionType
          property
          unit
        />
        {selectedJournal && (
          <ViewBookedTransactionDialog
            closeDialog={() => this.setState({ selectedJournal: null })}
            onTransactionModified={this.getJournals}
            journalId={selectedJournal.id}
          />
        )}
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({ downloadDialog: false })}
          setExportOptions={this.setExportOptions}
        />
        {false && (
          <FilterCard
            openFilter={() => this.setState({ filterDialog: true })}
            updateFilter={this.updateJournalsFilter}
            filter={filter}
            fields={['transactionType', 'property']}
          />
        )}
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell><Typography variant="subtitle2">Date</Typography></TableCell>
                      <TableCell><Typography variant="subtitle2">Type</Typography></TableCell>
                      <TableCell><Typography variant="subtitle2">Description</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">Amount</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">Balance</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">Action</Typography></TableCell>
                    </TableRow>
                    {journals.map((journal) => (
                      <TableRow key={journal.accountLine.id} className="noprintbreak">
                        <TableCell>
                          <Typography variant="body2" gutterBottom>
                            {moment(journal.date).format('M/D/YYYY')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.truncateOneLine} variant="body2" gutterBottom>
                            {journal.type}
                          </Typography>
                          <Typography className={classes.truncateOneLine} variant="caption" component="div">
                            {this.splitAccount(journal)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.truncateOneLine} variant="body2" gutterBottom>
                            {journal.description ? journal.description : '---'}
                          </Typography>
                          {journal.entityId !== null && journal.propertyId === null && (
                            <Typography variant="caption" component="div">
                              {journal.entity.name}
                            </Typography>
                          )}
                          {journal.propertyId !== null && (
                            <Typography variant="caption" component="div">
                              {journal.property.address1}
                            </Typography>
                          )}
                          {journal.unitId !== null && (
                            <Typography variant="caption" component="div">
                              {journal.unit.name}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={journal.accountLine.value}
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                            renderText={
                              (value) => this.formatNegative(value)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={journal.accountLine.balance}
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                            renderText={
                              (value) => this.formatNegative(value)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              this.setState({
                                selectedJournal: journal,
                              });
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter className="noprint">
                    <TableRow>
                      <TablePagination
                        variant="footer"
                        count={journals.length}
                        page={filter.page}
                        rowsPerPage={journals.length}
                        onPageChange={() => {}}
                        rowsPerPageOptions={[journals.length]}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

AccountRegister.contextType = PersonContext;

AccountRegister.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(AccountRegister);
