import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import generateHash from 'random-hash';
import moment from 'moment';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { ButtonBase } from '@material-ui/core';
import { get, find, create } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';
import {
  handleAutocompleteChange,
  handleTextFieldChange,
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleChange,
  handleTransactionScopeChange,
} from '../../functions/InputHandlers';

import {
  sumProperty,
} from '../../functions/SumFunctions';

import {
  setJournalScope,
} from '../../functions/JournalFunctions';

import {
  getManualBasicJournal,
  getDebitJournalLine,
  getCreditJournalLine,
} from '../SearchSelect/TransactionTypeOptions';

import { nameLabel, getAccountOptions } from '../Autocomplete/Library';
import TransactionScope from '../TransactionScope';

const styles = (theme) => ({
  amountCell: {
    width: '30%',
    maxWidth: '120px',
    verticalAlign: 'top',
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddOpeningBalanceDialogContent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { assetId } = this.props;
    const { bookkeepingStartDate } = this.context;
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      displayNotesSection: false,
    };

    const balanceLines = [];
    const journal = getManualBasicJournal();
    journal.type = 'Opening Balance';
    if (bookkeepingStartDate) {
      journal.date = moment(bookkeepingStartDate).subtract(1, 'days').format('MM/DD/YYYY');
    }
    journal.description = 'Opening balance';

    if (assetId) {
      const asset = await get(this, 'fixed-assets', assetId);
      initialState.accountOptions = await getAccountOptions(this, { type2: ['Fixed Asset', 'Accumulated Depreciation'] });
      journal.fixedAssetIdSelect = asset;
      journal.fixedAssetId = assetId;
      if (asset.propertyId) {
        journal.propertyIdSelect = await get(this, 'properties', asset.propertyId);
        journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
        if (journal.propertyIdSelect.unitSelection === 'multi') {
          journal.unitIdSelect = { name: 'All Units', id: 'All' };
        }
      }
      if (['Residential Property', 'Commercial Property'].includes(asset.type)) {
        balanceLines.push(this.getBalanceLine());
        balanceLines.push(this.getBalanceLine());
        balanceLines.push(this.getBalanceLine());
        [balanceLines[0].accountIdSelect] = initialState.accountOptions.filter((account) => account.default === 'land');
        [balanceLines[1].accountIdSelect] = initialState.accountOptions.filter((account) => account.default === 'buildings');
        [balanceLines[2].accountIdSelect] = initialState.accountOptions.filter((account) => account.default === 'accumulatedDepreciation');
      } else {
        balanceLines.push(this.getBalanceLine());
      }
    } else {
      balanceLines.push(this.getBalanceLine());
      initialState.accountOptions = await getAccountOptions(this);
    }

    initialState.balanceLines = balanceLines;
    initialState.journal = journal;

    this.setState(initialState);
  };

  getBalanceLine = () => (
    {
      key: generateHash(),
      accountIdSelect: null,
      amount: null,
    }
  );

  addBalanceLine = () => {
    const { balanceLines } = this.state;
    handleChange('balanceLines', balanceLines.concat([this.getBalanceLine()]), this);
  };

  deleteBalanceLine = (index) => {
    const { balanceLines } = this.state;
    balanceLines.splice(index, 1);
    handleChange('balanceLines', [...balanceLines], this);
  };

  getBalanceLineHelperText = (balanceLine) => {
    if (balanceLine.accountIdSelect) {
      if (balanceLine.accountIdSelect.type2 === 'Accumulated Depreciation') {
        return 'Accumulated depreciation should be entered as a negative number';
      }
    }
    return null;
  };

  getAutobalanceAmount = () => {
    const { balanceLines } = this.state;

    let autobalance = 0;
    autobalance = balanceLines.reduce(
      (a, b) => {
        if (b.accountIdSelect && !Number.isNaN(parseFloat(b.amount))) {
          if (b.accountIdSelect.type === 'Asset' || b.accountIdSelect.type === 'Expense') {
            return a + parseFloat(b.amount);
          }
          return a - parseFloat(b.amount);
        }
        return a;
      },
      autobalance,
    ).toFixed(2);
    return autobalance;
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const {
      submitting, journal, balanceLines,
    } = this.state;

    const { onAddTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    const debitLines = [];
    const creditLines = [];

    balanceLines.forEach((line) => {
      let journalLine;
      if (line.accountIdSelect.type === 'Asset' || line.accountIdSelect.type === 'Expense') {
        if (line.amount < 0) {
          journalLine = getCreditJournalLine();
          creditLines.push(journalLine);
          journalLine.credit = -line.amount;
        } else {
          journalLine = getDebitJournalLine();
          debitLines.push(journalLine);
          journalLine.debit = line.amount;
        }
      } else if (line.amount < 0) {
        journalLine = getDebitJournalLine();
        debitLines.push(journalLine);
        journalLine.debit = -line.amount;
      } else {
        journalLine = getCreditJournalLine();
        creditLines.push(journalLine);
        journalLine.credit = line.amount;
      }
      journalLine.accountIdSelect = line.accountIdSelect;
    });

    const autobalanceAmount = this.getAutobalanceAmount();
    let autobalanceLine;
    const autobalanceAccounts = await find(this, 'accounts', {
      query: {
        organizationId, default: 'autoBalance',
      },
    });
    if (autobalanceAmount > 0) {
      autobalanceLine = getCreditJournalLine();
      creditLines.push(autobalanceLine);
      autobalanceLine.credit = autobalanceAmount;
      [autobalanceLine.accountIdSelect] = autobalanceAccounts.data;
    } else if (autobalanceAmount < 0) {
      autobalanceLine = getDebitJournalLine();
      debitLines.push(autobalanceLine);
      autobalanceLine.debit = -autobalanceAmount;
      [autobalanceLine.accountIdSelect] = autobalanceAccounts.data;
    }

    journal.debitLines = debitLines;
    journal.creditLines = creditLines;

    const debitAmount = sumProperty(journal.debitLines, 'debit');
    const creditAmount = sumProperty(journal.creditLines, 'credit');

    if (debitAmount !== creditAmount) {
      this.setState({ error: { message: 'The credit and debit amounts for a journal must be equal' } });
      return;
    }

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    this.setState({ submitting: true });

    journal.organizationId = organizationId;
    journal.amount = debitAmount;
    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    setJournalScope(journal);

    journal.journalLines.forEach((line) => {
      if (line.accountIdSelect) {
        // eslint-disable-next-line no-param-reassign
        line.accountId = line.accountIdSelect.id;
      }
    });

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    create(this, 'journals', journal, true)
      .then((result) => {
        this.closeDialog();
        onAddTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      error,
      journal,
      balanceLines,
      accountOptions,
      displayNotesSection,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <form onSubmit={this.addTransaction}>
        <DialogTitle id="alert-dialog-title">
          Add Opening Balance
        </DialogTitle>
        <DialogContent>
          <KeyboardDatePicker
            label="Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={journal.date}
            onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
            margin="dense"
            fullWidth
            clearable
            required
          />
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValues) => {
              handleTransactionScopeChange(journal, newScopeValues, this);
            }}
          />
          <TextField
            label="Description (optional)"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          <Box
            border={1}
            borderColor="grey.500"
            borderRadius="borderRadius"
            padding={2}
            marginY={2}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="subtitle2">Account Balances</Typography></TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {balanceLines.map((balanceLine, index) => (
                  <TableRow key={balanceLine.key}>
                    <TableCell>
                      <Autocomplete
                        options={accountOptions}
                        getOptionLabel={nameLabel}
                        value={balanceLines[index].accountIdSelect}
                        onChange={handleAutocompleteChange(
                          `nested_balanceLines_${index}_accountIdSelect`,
                          this,
                        )}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                            margin="dense"
                            label="Account"
                            placeholder="Type to Search"
                            fullWidth
                            required
                            helperText={this.getBalanceLineHelperText(balanceLine)}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell className={classes.amountCell}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel required>
                          Amount
                        </InputLabel>
                        <NumberFormat
                          value={balanceLine.amount}
                          required
                          thousandSeparator
                          prefix="$"
                          decimalScale={2}
                          onValueChange={handleNumberFormatChange(`nested_balanceLines_${index}_amount`, this)}
                          customInput={Input}
                          endAdornment={balanceLines.length > 1 && (
                            <InputAdornment position="end">
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  this.deleteBalanceLine(index);
                                }}
                              >
                                <ClearIcon style={{ fontSize: 18 }} />
                              </IconButton>
                            </InputAdornment>
                          )}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Button onClick={this.addBalanceLine} color="primary">
                      Add Line
                    </Button>
                  </TableCell>
                  <TableCell>
                    {'Autobalance: '}
                    <NumberFormat
                      displayType="text"
                      value={this.getAutobalanceAmount()}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Opening Balance
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

AddOpeningBalanceDialogContent.contextType = PersonContext;

AddOpeningBalanceDialogContent.defaultProps = {
  assetId: null,
};

AddOpeningBalanceDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  assetId: PropTypes.node,
};

export default withStyles(styles)(AddOpeningBalanceDialogContent);
