import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import Portfolios from '../views/accountant/Portfolios';
import Settings from '../views/accountant/Settings';
import MyProfile from '../views/MyProfile';

export const accountantRouteArray = [
  {
    exact: true,
    path: '/accountant/clients',
    component: Portfolios,
  },
  {
    exact: true,
    path: '/accountant/settings',
    component: Settings,
  },
  {
    exact: true,
    path: '/accountant/my-profile',
    component: MyProfile,
  },
];

/* eslint-disable react/jsx-props-no-spreading */
class AccountantRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        {accountantRouteArray.map((route) => (
          <Route
            {...route}
            key={route.path}
          />
        ))}
        <Redirect
          to="/accountant/clients"
          push
        />

      </Switch>
    );
  }
}

AccountantRoutes.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default AccountantRoutes;
