import React from 'react';
import queryString from 'query-string';

import history from '../history';

class Reload extends React.Component {
  constructor(props) {
    super(props);

    /* eslint-disable react/prop-types */
    const { location } = this.props;
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.link) {
      history.replace(parsedQuery.link);
    } else {
      history.replace('/dashboard');
    }
  }

  render() {
    return null;
  }
}

export default Reload;
