import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import FieldGridSpacer from './FieldGridSpacer';

class FieldGrid extends React.PureComponent {
  render() {
    const {
      label, value,
    } = this.props;
    return (
      <Box paddingLeft={2}>
        <Grid spacing={3} container>
          <Grid item>
            <Typography variant="body2">
              {label}
            </Typography>
          </Grid>
          <FieldGridSpacer />
          <Grid item>
            <Typography variant="body2">
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

FieldGrid.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

export default FieldGrid;
