import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
// import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';

import history from '../history';
import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
} from '../functions/InputHandlers';

import AddFixedAssetDialog from '../components/AddFixedAssetDialog';
import AddFixedAssetDepreciationDialog from '../components/AddFixedAssetDepeciationDialog';
import FilterDialog from '../components/FilterDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import LinkBase from '../components/LinkBase';

import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

class FixedAssets extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addFixedAssetDialog: false,
      filterDialog: false,
      filter: {
        propertyId: null,
        propertyIdSelect: { address1: 'All Properties', id: 'All' },
        inactive: undefined,
        page: 0,
        pageSize: 50,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const { location } = this.props;
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.addPropertyId) {
      this.setState({ addPropertyId: parsedQuery.addPropertyId, addFixedAssetDialog: true });
    }

    await this.getAssets();
  }

  getAssets = async () => {
    const { organizationId } = this.context;

    const query = {
      inactive: { $or: [null, false] },
      ...buildQuery(this),
      organizationId,
      $sort: {
        name: 1,
        id: 1,
      },
    };

    const fixedAssetsPromise = find(this, 'fixed-assets', { query });

    const fixedAssets = await fixedAssetsPromise;

    this.setState({
      fixedAssets,
      loading: false,
    });
  };

  clearFilterFields = (fieldList) => {
    const { filter } = this.state;
    const newFilter = { ...filter };
    fieldList.forEach((fieldName) => {
      newFilter[fieldName] = null;
    });
    newFilter.page = 0;
    this.updateFilter(newFilter);
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getAssets();
  };

  handleChangeAssetsPage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getAssets();
  };

  closeDialogs = () => {
    const { location } = this.props;
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.addPropertyId) {
      history.replace(window.location.pathname);
    }
    this.setState({ addFixedAssetDialog: false, addFixedAssetDepreciationDialog: false });
    this.getAssets();
  }

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Add Asset', action: (() => this.setState({ addFixedAssetDialog: true })), class: 'add' },
      { text: 'Add Depreciation', action: (() => this.setState({ addFixedAssetDepreciationDialog: true })), class: 'add' },
    ]
  );

  render() {
    const { match } = this.props;
    const {
      fixedAssets, addFixedAssetDialog, addPropertyId,
      filter, filterDialog, loading, addFixedAssetDepreciationDialog,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Fixed Assets"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        {addFixedAssetDialog && (
          <AddFixedAssetDialog
            isOpen={addFixedAssetDialog}
            closeDialog={this.closeDialogs}
            propertyId={addPropertyId}
            openAssetPage
          />
        )}
        {addFixedAssetDepreciationDialog && (
          <AddFixedAssetDepreciationDialog
            isOpen={addFixedAssetDepreciationDialog}
            closeDialog={this.closeDialogs}
          />
        )}
        {filterDialog && (
          <FilterDialog
            filter={filter}
            isOpen={filterDialog}
            closeDialog={() => this.setState({ filterDialog: false })}
            updateFilter={this.updateFilter}
            property
            inactive
          />
        )}
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Property
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fixedAssets.data.map((asset) => (
                    <TableRow key={asset.id}>
                      <TableCell>
                        <LinkBase to={`/organization/fixed-assets/${asset.id}`}>
                          {asset.name}
                        </LinkBase>
                      </TableCell>
                      <TableCell>
                        {asset.property ? asset.property.address1 : 'None'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={fixedAssets.total}
                      page={filter.page}
                      rowsPerPage={filter.pageSize}
                      onChangePage={this.handleChangeAssetsPage}
                      rowsPerPageOptions={[filter.pageSize]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

FixedAssets.contextType = PersonContext;

FixedAssets.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  // classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default FixedAssets;
