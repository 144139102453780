import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { get, create, remove } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';
import {
  handleAutocompleteChange,
  handleTextFieldChange,
  asyncHandleChange,
  handleKeyboardDatePickerChange,
  handleUploaderChange,
  handleCheckboxChange,
  handleTransactionScopeChange,
} from '../../functions/InputHandlers';

import {
  testMatchingRule,
} from '../../functions/RegExpFunctions';

import {
  sumProperty,
} from '../../functions/SumFunctions';

import {
  getManualBasicJournal,
} from '../SearchSelect/TransactionTypeOptions';

import {
  nameLabel,
  nameAndAddressLabel,
  getFixedAssetOptions,
  getAccountOptions,
  getPaymentAccountOptions,
  getVendorOptions,
} from '../Autocomplete/Library';

import {
  setJournalScope,
  setInitialJournalScope,
} from '../../functions/JournalFunctions';

import AddFixedAssetDialog from '../AddFixedAssetDialog';
import AddVendorDialog from '../AddVendorDialog';
import TransactionScope from '../TransactionScope';

const styles = (theme) => ({
  hideWidget: {
    display: 'none',
  },
  red: {
    color: 'red',
  },
  aButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddFixedAssetDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { yodleeTransaction, assetId, ruleId } = this.props;
    const initialState = {
      loading: false,
      submitting: false,
      addFixedAssetDialog: false,
      addVendorDialog: false,
      showCapitalizationBox: false,
      error: null,
      rule: {
        createRule: false,
        matchDescription: false,
        matchAmount: false,
        descriptionContains: '',
        autobookMatchingTransactions: true,
        applyToAllAccounts: false,
      },
      displayNotesSection: false,
    };

    const journal = getManualBasicJournal();
    journal.type = 'Fixed Asset Purchase';
    journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };

    if (yodleeTransaction) {
      journal.creditLines[0].accountId = yodleeTransaction.accountId;
      journal.creditLines[0].yodleeTransactionId = yodleeTransaction.id;
      journal.description = yodleeTransaction.description;
      journal.date = yodleeTransaction.date;
      journal.creditLines[0].credit = yodleeTransaction.amount;
      journal.debitLines[0].debit = yodleeTransaction.amount;
    }

    if (assetId) {
      const asset = await get(this, 'fixed-assets', assetId);
      journal.fixedAssetIdSelect = asset;
      // journal.unitId = asset.unitId;
      journal.propertyId = asset.propertyId;
      journal.entityId = asset.entityId;
      await setInitialJournalScope(journal, this);
    } else if (ruleId) {
      const rule = await get(this, 'imported-transaction-rules', ruleId);
      journal.debitLines[0].accountIdSelect = rule.destinationAccount;
      journal.fixedAssetIdSelect = rule.fixedAsset;
      journal.unitId = rule.unitId;
      journal.propertyId = rule.propertyId;
      journal.entityId = rule.entityId;
      await setInitialJournalScope(journal, this);
      if (rule.vendor) {
        journal.vendorIdSelect = rule.vendor;
      }
    }

    initialState.journal = journal;

    initialState.fixedAssetOptions = await getFixedAssetOptions(this, true);
    initialState.accountOptions = await getAccountOptions(this, { type2: 'Fixed Asset' });
    initialState.paymentAccountOptions = await getPaymentAccountOptions(this);
    initialState.vendorOptions = await getVendorOptions(this, true);

    this.setState(initialState);
  };

  handleAmountChange = async (values) => {
    if (typeof values.floatValue === 'undefined') {
      await asyncHandleChange('nested_journal_debitLines_0_debit', null, this);
      await asyncHandleChange('nested_journal_creditLines_0_credit', null, this);
    } else {
      await asyncHandleChange('nested_journal_debitLines_0_debit', values.floatValue, this);
      await asyncHandleChange('nested_journal_creditLines_0_credit', values.floatValue, this);
    }
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return (
          <span className={classes.red}>
            {`(${value})`}
          </span>
        );
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return (
          <span className={classes.red}>
            {`(${value})`}
          </span>
        );
      }
    }
    return value;
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const {
      submitting, journal, rule,
    } = this.state;

    const { yodleeTransaction, onAddTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    const debitAmount = sumProperty(journal.debitLines, 'debit');
    const creditAmount = sumProperty(journal.creditLines, 'credit');

    if (debitAmount !== creditAmount) {
      this.setState({ error: { message: 'The credit and debit amounts for a journal must be equal' } });
      return;
    }

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    if (rule.createRule) {
      if (!rule.matchDescription && !rule.matchAmount) {
        this.setState({
          error: {
            message: 'Please select a description or amount to create a matching rule',
          },
        });
        return;
      }
      if (rule.matchDescription) {
        if (
          !testMatchingRule(rule.descriptionContains, yodleeTransaction.description)
        ) {
          this.setState({
            error: {
              message: `The imported transaction description does not contain the matching rule text. 
              Please update the rule so it is contained within the text: ${yodleeTransaction.description}`,
            },
          });
          return;
        }
      }
    }

    this.setState({ submitting: true });

    setJournalScope(journal);

    journal.organizationId = organizationId;
    journal.amount = debitAmount;
    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    if (journal.fixedAssetIdSelect) {
      journal.fixedAssetId = journal.fixedAssetIdSelect.id;
    }
    journal.vendorId = journal.vendorIdSelect ? journal.vendorIdSelect.id : null;

    journal.journalLines.forEach((line) => {
      if (line.accountIdSelect) {
        // eslint-disable-next-line no-param-reassign
        line.accountId = line.accountIdSelect.id;
      }
    });

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    create(this, 'journals', journal, true)
      .then(async (journalResult) => {
        if (rule.createRule) {
          const ruleSubmit = {
            type: 'Fixed Asset Purchase',
            organizationId,
            destinationAccountId: journal.debitLines[0].accountId,
            fixedAssetId: journal.fixedAssetId,
            entityId: journal.entityId,
            propertyId: journal.propertyId,
            unitId: journal.unitId,
            vendorId: journal.vendorId,
            transactionAmount: rule.matchAmount ? journal.amount : null,
            descriptionContains: rule.matchDescription ? rule.descriptionContains : '',
            autobookMatchingTransactions: rule.autobookMatchingTransactions,
            analysisAccountId: rule.applyToAllAccounts ? null : yodleeTransaction.account.id,
          };

          await create(this, 'imported-transaction-rules', ruleSubmit, true)
            .then(async (ruleResult) => {
              if (ruleSubmit.autobookMatchingTransactions) {
                const automationResult = await create(this, 'automations', {
                  organizationId: ruleSubmit.organizationId,
                  automationName: 'bookRuleMatching',
                  ruleId: ruleResult.id,
                });
                onAddTransaction(journalResult);
                if (automationResult.newTransactions) {
                  this.setState({
                    autobookResult: true,
                    autobookedTransactionCount: automationResult.newTransactions,
                  });
                } else {
                  this.closeDialog();
                }
              } else {
                onAddTransaction(journalResult);
                this.closeDialog();
              }
            })
            .catch((error) => {
              remove(this, 'journals', journalResult.id, true);
              this.setState({ error });
              this.setState({ submitting: false });
            });
        } else {
          onAddTransaction(journalResult);
          this.closeDialog();
        }
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const {
      classes, assetId, ruleId, yodleeTransaction, resetTransactionType,
    } = this.props;
    const {
      loading,
      error,
      addFixedAssetDialog,
      addVendorDialog,
      journal,
      showCapitalizationBox,
      fixedAssetOptions,
      accountOptions,
      paymentAccountOptions,
      vendorOptions,
      displayNotesSection,
      rule, autobookResult, autobookedTransactionCount,
    } = this.state;

    const { vendorTracking } = this.context;

    if (loading) {
      return null;
    }

    if (addFixedAssetDialog) {
      return (
        <AddFixedAssetDialog
          isOpen={addFixedAssetDialog}
          closeDialog={() => this.setState({ addFixedAssetDialog: false })}
          onAddFixedAsset={async (newValue) => {
            await asyncHandleChange('fixedAssetOptions', await getFixedAssetOptions(this, true), this);
            await asyncHandleChange('nested_journal_fixedAssetIdSelect', await get(this, 'fixed-assets', newValue.id), this);
          }}
        />
      );
    }

    if (addVendorDialog) {
      return (
        <AddVendorDialog
          isOpen={addVendorDialog}
          closeDialog={() => this.setState({ addVendorDialog: false })}
          onAddVendor={async (newValue) => {
            await asyncHandleChange('vendorOptions', await getVendorOptions(this, true), this);
            await asyncHandleChange('nested_journal_vendorIdSelect', await get(this, 'vendors', newValue.id), this);
          }}
        />
      );
    }

    if (autobookResult) {
      return (
        <>
          <DialogTitle id="alert-dialog-title">
            Rule Created
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {`Your rule was created. ${autobookedTransactionCount} additional transactions matched the template and were booked automatically.`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary" variant="contained" disableElevation>
              Close
            </Button>
          </DialogActions>
        </>
      );
    }

    return (
      <form onSubmit={this.addTransaction}>
        <DialogTitle id="alert-dialog-title">
          Add Fixed Asset Purchase
        </DialogTitle>
        <DialogContent>
          {yodleeTransaction && (
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Transaction
              </FormLabel>
              <Typography>
                {moment(yodleeTransaction.date).format('M/D/YYYY')}
                <Box px={2} component="span">|</Box>
                <NumberFormat
                  displayType="text"
                  value={yodleeTransaction.amount}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                />
                <Box px={2} component="span">|</Box>
                {yodleeTransaction.account.name}
              </Typography>
            </FormControl>
          )}
          <KeyboardDatePicker
            label="Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={journal.date}
            onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
            margin="dense"
            fullWidth
            clearable
            required
          />
          <FormControl margin="dense" fullWidth>
            <InputLabel required>
              Amount
            </InputLabel>
            <NumberFormat
              value={journal.debitLines[0].debit}
              required
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              onValueChange={this.handleAmountChange}
              customInput={Input}
            />
            {journal.debitLines[0].debit <= 2500 && !showCapitalizationBox && (
              <FormHelperText>
                <Typography variant="inherit">
                  {'Purchases $2,500 or less may be eligible for the de minimis election. '}
                  <ButtonBase
                    component="span"
                    className={classes.aButton}
                    onClick={() => {
                      this.setState({
                        showCapitalizationBox: true,
                      });
                    }}
                  >
                    Learn More
                  </ButtonBase>
                </Typography>
              </FormHelperText>
            )}
          </FormControl>
          {showCapitalizationBox === true && (
            <Box
              border={1}
              borderColor="grey.500"
              borderRadius="borderRadius"
              padding={2}
              marginY={2}
            >
              <Typography variant="body2">
                {`The IRS allows you to make a de minimis safe harbor election. 
                  Taxpayers making this election may expense, rather than capitalize, items costing up to $2,500. 
                  This typically reduces up front taxes and simplifies recordkeeping. `}
                <ButtonBase
                  component="span"
                  className={classes.aButton}
                  onClick={() => {
                    resetTransactionType('Expense');
                  }}
                >
                  Use an expense
                </ButtonBase>
                {' to record these transactions in REI Hub if you will be making this common election.'}
              </Typography>
            </Box>
          )}
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValues) => {
              handleTransactionScopeChange(journal, newScopeValues, this);
            }}
          />
          {!assetId && (
            <Autocomplete
              options={fixedAssetOptions}
              getOptionLabel={nameAndAddressLabel}
              value={journal.fixedAssetIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_fixedAssetIdSelect',
                this,
                () => this.setState({ addFixedAssetDialog: true }),
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Fixed Asset"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
          )}
          {!yodleeTransaction && (
            <Autocomplete
              options={paymentAccountOptions}
              getOptionLabel={nameLabel}
              value={journal.creditLines[0].accountIdSelect}
              onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Payment Account"
                  placeholder="Type to Search"
                  fullWidth
                  required
                />
              )}
            />
          )}
          <Autocomplete
            options={accountOptions}
            getOptionLabel={nameLabel}
            value={journal.debitLines[0].accountIdSelect}
            onChange={handleAutocompleteChange('nested_journal_debitLines_0_accountIdSelect', this)}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                margin="dense"
                label="Asset Account"
                placeholder="Type to Search"
                fullWidth
                required
              />
            )}
          />
          {vendorTracking && (
            <Autocomplete
              options={vendorOptions}
              getOptionLabel={nameLabel}
              value={journal.vendorIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_vendorIdSelect',
                this,
                () => this.setState({ addVendorDialog: true }),
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Vendor (optional)"
                  placeholder="Type to Search"
                  fullWidth
                />
              )}
            />
          )}
          <TextField
            label="Description (optional)"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          {yodleeTransaction && !ruleId && (
            <FormControl margin="dense">
              <FormControlLabel
                control={<Checkbox checked={rule.createRule} onChange={handleCheckboxChange(this)} name="nested_rule_createRule" />}
                label="Create a new transaction matching rule"
              />
            </FormControl>
          )}
          {rule.createRule && (
            <Box
              border={1}
              borderColor="grey.500"
              borderRadius="borderRadius"
              padding={2}
              marginY={2}
            >
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={rule.matchDescription} onChange={handleCheckboxChange(this)} name="nested_rule_matchDescription" />}
                  label="Match by description"
                />
              </FormControl>
              {rule.matchDescription && (
                <>
                  <Typography variant="body2">
                    {`Enter a matching phrase contained in the imported description. 
                      The imported description was: ${yodleeTransaction.description}`}
                  </Typography>
                  <TextField
                    label="Description Contains"
                    fullWidth
                    required
                    margin="dense"
                    InputProps={{
                      value: rule.descriptionContains,
                      name: 'nested_rule_descriptionContains',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                </>
              )}
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={rule.matchAmount} onChange={handleCheckboxChange(this)} name="nested_rule_matchAmount" />}
                  label="Match by amount"
                />
              </FormControl>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={rule.autobookMatchingTransactions} onChange={handleCheckboxChange(this)} name="nested_rule_autobookMatchingTransactions" />}
                  label="Automatically book matching transactions"
                />
              </FormControl>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={rule.applyToAllAccounts} onChange={handleCheckboxChange(this)} name="nested_rule_applyToAllAccounts" />}
                  label="Apply this rule to all accounts"
                />
              </FormControl>
              {!rule.applyToAllAccounts && (
                <Typography variant="body2">
                  {`This rule will be applied only to transactions in ${yodleeTransaction.account.name}`}
                </Typography>
              )}
            </Box>
          )}
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.editImageButton}
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                className={classes.editImageButton}
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Fixed Asset Purchase
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

AddFixedAssetDialogContent.contextType = PersonContext;

AddFixedAssetDialogContent.defaultProps = {
  yodleeTransaction: null,
  assetId: null,
  ruleId: null,
  resetTransactionType: () => {},
};

AddFixedAssetDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  yodleeTransaction: PropTypes.objectOf(PropTypes.any),
  assetId: PropTypes.node,
  ruleId: PropTypes.node,
  resetTransactionType: PropTypes.func,
};

export default withStyles(styles)(AddFixedAssetDialogContent);
