import React, { useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core';
import { PersonContext } from '~/contexts/PersonContext';
import EmptyState from '~/components/EmptyState';
import PageGrid from '~/components/PageGrid';
import { create, find } from '../feathersWrapper';
import { filterForProperty } from '../functions/SumFunctions';
import AddLoanDialog from '../components/AddLoanDialog';
import EditLoanDialog from '../components/EditLoanDialog';
import FilterAccountsDialog from '../components/FilterAccountsDialog';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import LinkBase from '../components/LinkBase';

const LOANS_EMPTY_STATE_SEGMENT_LOCATION = 'Loans Empty State';

const styles = makeStyles((theme) => ({
  inlineButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
  textButton: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  image: {
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '248px',
    },
  },
}));

export default function Loans() {
  const theme = useTheme();
  const classes = styles(theme);
  const context = useContext(PersonContext);
  const location = useLocation();
  const match = useRouteMatch();
  const { organizationId } = context;
  const [isLoading, setIsLoading] = useState(true);
  const [accountId, setAccountId] = useState(null);
  const [addAccount, setAddAccount] = useState(false);
  const [editAccountDialog, setEditAccountDialog] = useState(false);
  const [filterAccountsDialog, setFilterAccountsDialog] = useState(false);
  const [filter, setFilter] = useState({
    showInactive: false,
    pageSize: 10,
    page: 0,
  });
  const [hasInactiveLoans, setHasInactiveLoans] = useState(false);
  const [totalLoans, setTotalLoans] = useState(0);
  const [loans, setLoans] = useState([]);

  const getLoans = async () => {
    const transactionsSinceBalancePromise = create(this, 'reports', {
      organizationId,
      reportName: 'loanTransactionsSinceBalanceSummary',
    });

    const loansPromise = find(this, 'accounts', {
      query: {
        organizationId,
        includePlaidAccounts: true,
        includeYodleeAccounts: true,
        includeProperty: true,
        includeUnit: true,
        includeEntity: true,
        type: 'Liability',
        type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
        inactive: filter.showInactive ? true : { $or: [null, false] },
        $limit: filter.pageSize,
        $skip: filter.page * filter.pageSize,
        $sort: {
          name: 1,
          id: 1,
        },
      },
    });

    const allLoansCountPromise = find(this, 'accounts', {
      query: {
        organizationId,
        type: 'Liability',
        type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
        $limit: 0,
      },
    });

    const [allLoansCount, transactionsSinceBalance, loansResponse] = await Promise.all([
      allLoansCountPromise, transactionsSinceBalancePromise, loansPromise,
    ]);

    /* eslint-disable no-param-reassign */
    loansResponse.data.forEach((account) => {
      account.transactionsSinceBalance = filterForProperty(transactionsSinceBalance, 'id', account.id, 'netCredits', 0);
      account.currentBalance = account.lastCurrentBalance + account.transactionsSinceBalance;

      if (account.type2 !== 'Mortgage') {
        return;
      }
      account.templateIncomplete = false;
      // check template criteria
      if (account.mortgagePaymentAmount === null
        || account.lastCurrentBalance === null
        || account.mortgageInterestRate === null) {
        account.templateIncomplete = true;
      }
      if (account.mortgageEscrowAccountId && account.mortgageEscrowTransferAmount === null) {
        account.templateIncomplete = true;
      }
    });
    /* eslint-enable no-param-reassign */

    setHasInactiveLoans(allLoansCount.total > 0 && loansResponse.total === 0);

    setTotalLoans(loansResponse.total);
    setLoans(loansResponse.data);
    setIsLoading(false);
  };

  useEffect(() => {
    async function getData() {
      await getLoans();
      const parsedQuery = queryString.parse(location.search);
      if (parsedQuery.add) {
        setAddAccount(true);
      }
      if (parsedQuery.edit) {
        setAccountId(parsedQuery.edit);
        setEditAccountDialog(true);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    getLoans();
  }, [filter]);

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage });
    getLoans();
  };

  const closeAccountDialogs = () => {
    setAddAccount(false);
    setEditAccountDialog(false);
  };

  const actionButtons = () => {
    const buttons = [
      { text: 'Filter', action: (() => setFilterAccountsDialog(true)), class: 'filter' },
      { text: 'Add Loan', action: (() => setAddAccount(true)), class: 'add' },
    ];

    if (totalLoans === 0 && !filter.showInactive && !hasInactiveLoans) {
      return [];
    }

    return buttons;
  };

  const clearFilters = async () => {
    setIsLoading(true);
    setFilter({
      showInactive: false,
      pageSize: 10,
      page: 0,
    });
  };

  const hasNoLoans = loans.length === 0 && !filter.showInactive;
  const hasNoFilteredLoans = loans.length === 0 && filter.showInactive;

  if (isLoading) {
    return null;
  }

  return (
    <PageGrid>
      <PageHeader match={match} title="Loans" actionButtons={actionButtons()} />
      <AddLoanDialog
        isOpen={addAccount}
        closeDialog={closeAccountDialogs}
        onAddAccount={getLoans}
      />
      <EditLoanDialog
        accountId={accountId}
        isOpen={editAccountDialog}
        closeDialog={closeAccountDialogs}
        onEditAccount={getLoans}
      />
      {filterAccountsDialog && (
        <FilterAccountsDialog
          filter={filter}
          closeDialog={() => setFilterAccountsDialog(false)}
          updateFilter={(newFilter) => setFilter(newFilter)}
        />
      )}
      {hasNoLoans ? (
        <EmptyState
          image="/rei_hub_loans_empty_state.png"
          title="Add a loan account to track your financing"
          body={(
            <Typography variant="body1" align="center">
              Adding your Loans allows you to track the flow of funds through your business, as
              well as things like principal paydown and estimated equity.
            </Typography>
            )}
          buttonProps={[
            {
              color: 'purple',
              variant: 'contained',
              text: 'Add Loan',
              segmentProps: { event: 'add_loan clicked', location: LOANS_EMPTY_STATE_SEGMENT_LOCATION },
              onClickFunction: () => { setAddAccount(true); },
            },
          ]}
        />
      ) : null}
      {hasNoFilteredLoans ? (
        <EmptyState
          body={(
            <Typography variant="body1" align="center">
              Try adjusting your filter options to find what you are looking for or add a new
              loan below.
            </Typography>
          )}
          buttonProps={[
            {
              color: 'purple',
              variant: 'contained',
              text: 'Clear Filters',
              onClickFunction: clearFilters,
            },
            {
              color: 'secondary',
              segmentProps: { event: 'add_loan clicked', location: LOANS_EMPTY_STATE_SEGMENT_LOCATION },
              variant: 'outlined',
              text: 'Add Loan',
              onClickFunction: () => setAddAccount(true),
            },
          ]}
          icon="/icons/user_action_empty_state_icon.svg"
          title="No loan accounts were found"
        />
      ) : null}
      {totalLoans > 0 && (
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Balance
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loans.map((account) => (
                    <TableRow key={account.id}>
                      <TableCell>
                        <Typography variant="body2">
                          <LinkBase
                            to={`/accounts/loans/loan/${account.id}`}
                          >
                            {account.name}
                          </LinkBase>
                          <Button
                            className={classes.inlineButton}
                            aria-label="edit"
                            onClick={() => {
                              setAccountId(account.id);
                              setEditAccountDialog(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </Button>
                        </Typography>
                        <Box paddingLeft={2}>
                          {account.property && (
                            <Typography variant="caption" component="div">
                              {`Property: ${account.property.address1}`}
                            </Typography>
                          )}
                          {account.unit && (
                            <Typography variant="caption" component="div">
                              {`Unit: ${account.unit.name}`}
                            </Typography>
                          )}
                          {!account.property && account.entity && (
                            <Typography variant="caption" component="div">
                              {`Sub-Portfolio: ${account.entity.name}`}
                            </Typography>
                          )}
                          {account.mortgageEscrowAccountId && (
                            <Typography variant="caption" component="div">
                              <LinkBase to={`/accounts/loans/escrow/${account.mortgageEscrowAccountId}`}>
                                View Escrow Account
                              </LinkBase>
                            </Typography>
                          )}
                          {account.templateIncomplete && (
                            <Typography variant="caption" component="div" color="error">
                              {'Payment template incomplete '}
                              <ButtonBase
                                component="span"
                                className={classes.textButton}
                                onClick={() => {
                                  setAccountId(account.id);
                                  setEditAccountDialog(true);
                                }}
                              >
                                Click here to complete it
                              </ButtonBase>
                            </Typography>
                          )}
                          {!account.templateIncomplete && (
                            <Typography variant="caption" component="div">
                              <NumberFormat
                                displayType="text"
                                value={account.mortgagePaymentAmount}
                                thousandSeparator
                                prefix="Payment: $"
                                suffix="/month"
                                decimalScale={2}
                                fixedDecimalScale
                              />
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          displayType="text"
                          value={account.currentBalance}
                          thousandSeparator
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={totalLoans}
                      page={filter.page}
                      rowsPerPage={filter.pageSize}
                      onChangePage={handleChangePage}
                      rowsPerPageOptions={[filter.pageSize]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      )}
    </PageGrid>
  );
}
