import React from 'react';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import CenterContentContainer from './CenterContentContainer';
import CardContentContainer from './CardContentContainer';
import CardBase from './CardBase';

class ErrorCard extends React.PureComponent {
  render() {
    const { error } = this.props;

    return (
      <CenterContentContainer>
        <CardContentContainer>
          <Grid item xs={12}>
            <CardBase>
              <CardContent>
                <Typography align="center" color="error">{error}</Typography>
              </CardContent>
            </CardBase>
          </Grid>
        </CardContentContainer>
      </CenterContentContainer>
    );
  }
}

ErrorCard.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorCard;
