import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles,
} from '@material-ui/core';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import { PersonContext } from '../../../contexts/PersonContext';
import { get, patch } from '../../../feathersWrapper';

class EditOrganizationDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { login, organizationId } = this.props;

    let value;
    if (organizationId) {
      value = organizationId;
    } else {
      value = login.organizationId;
    }

    const organization = await get(this, 'organizations', value);

    this.setState({ loading: false, organization });
  }

  getTrialStatus = () => {
    const { classes } = this.props;
    const { organization, freeTrialExpires } = this.state;

    const datePicker = (
      <KeyboardDatePicker
        className={classes.dateTime}
        label="Change Trial Expiration"
        format="MM/DD/YYYY"
        placeholder="MM/DD/YYYY"
        value={organization.freeTrialExpires}
        onChange={(date) => this.setState({ freeTrialExpires: date })}
        margin="dense"
        fullWidth
        clearable
      />
    );

    if (organization.partnerSubscribed) {
      return (
        <Typography>
          {`Subscribed via ${organization.partnerName}`}
        </Typography>
      );
    }
    if (organization.stripeSubscription) {
      return (
        <Typography>
          Subscribed
        </Typography>
      );
    }

    if (!freeTrialExpires) {
      if ((moment().diff(moment(organization.freeTrialExpires).endOf('day')) < 0)) {
        return (
          <>
            <Typography>
              {`${moment(organization.freeTrialExpires).diff(moment(), 'days') + 1} days remaining`}
            </Typography>
            {datePicker}
          </>
        );
      } if ((moment().diff(moment(organization.freeTrialExpires).endOf('day')) >= 0)) {
        return (
          <>
            <Typography>
              Expired
            </Typography>
            {datePicker}
          </>
        );
      }
    }

    if (freeTrialExpires) {
      return (
        <>
          <Typography color="error">
            {`${moment(freeTrialExpires).diff(moment(), 'days') + 1} days remaining`}
          </Typography>
          {datePicker}
        </>
      );
    }

    return (
      <></>
    );
  }

  updateOrganization = async () => {
    const { closeDialog } = this.props;
    const { freeTrialExpires, organization } = this.state;

    if (freeTrialExpires !== organization.freeTrialExpires) {
      await patch(this, 'organizations', organization.id, { freeTrialExpires });
    }

    closeDialog();
  };

  render() {
    const { loading } = this.state;
    const { isOpen, closeDialog } = this.props;

    if (loading) {
      return null;
    }

    return (
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Organization</DialogTitle>
        <DialogContent>
          {this.getTrialStatus()}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => { this.updateOrganization(); }} color="primary" variant="contained" disableElevation>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditOrganizationDialog.contextType = PersonContext;

EditOrganizationDialog.defaultProps = {
  organizationId: '',
};

EditOrganizationDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  login: PropTypes.object.isRequired,
  organizationId: PropTypes.string,
};

export default withStyles(styles)(EditOrganizationDialog);
