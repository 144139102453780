import React from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';

import FaceIcon from '@material-ui/icons/Face';
import LockIcon from '@material-ui/icons/Lock';

import client from '../feathers';

import { PersonContext } from '../contexts/PersonContext';
import AdminRoutes from '../routes/AdminRoutes';

const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  },
  content: {
    flexGrow: 1,
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    overflow: 'auto',
  },
  bottomAppBar: {
    zIndex: 1,
  },
  footer: {
    justifyContent: 'space-between',
  },
  inline: {
    display: 'inline',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    lineHeight: 1,
  },
  logo: {
    // imageRendering: '-webkit-optimize-contrast',
  },
  tabContainer: {
    marginLeft: 32,
  },
  tabItem: {
    paddingTop: 23,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: 'auto',
  },
  appBarGrow: {
    flexGrow: '1',
  },
  rightDrawer: {
    width: 250,
  },
  nestedList: {
    paddingLeft: theme.spacing(4),
  },
});

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      right: false,
    };
  }

  async componentDidMount() {
    const { logOut } = this.context;
    await client
      .reAuthenticate()
      .then(async (response) => {
        if (response.user.isAdmin !== 1) {
          await logOut();
        }
      })
      .catch(async () => {
        await logOut();
      });
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { classes, location } = this.props;
    const { logOut, firstName } = this.context;
    const {
      right,
    } = this.state;

    return (
      <div className={classes.root}>
        <IdleTimer
          ref={(ref) => { this.idleTimer = ref; }}
          element={document}
          // onActive={this.onActive}
          onIdle={logOut}
          // onAction={this.onAction}
          // debounce={250}
          // 30 minute logout
          timeout={1000 * 60 * 30}
        />

        <AppBar position="sticky" color="inherit" className={classes.appBar}>
          <Toolbar>
            <RouterLink to="/admin" className={classes.link}>
              <img src="/rei_hub_logo_purple.svg" height="54px" alt="REI Hub" className={classes.logo} />
            </RouterLink>
            { /* tabs removed here */}
            <div className={classes.appBarGrow} />
            <Hidden smDown>
              <div className={classes.inline}>
                <Typography variant="body2" color="inherit">
                  <span className={classes.inlineBlock}>{`${firstName} (Admin)`}</span>
                </Typography>
              </div>
              <div className={classes.inline}>
                <IconButton
                  aria-label="Menu"
                  color="inherit"
                  onClick={this.toggleDrawer('right', true)}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </Hidden>
            <Hidden mdUp>
              <IconButton color="inherit" onClick={this.toggleDrawer('right', true)} aria-label="Menu">
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Drawer anchor="right" open={right} onClose={this.toggleDrawer('right', false)}>
              <div
                aria-label="Menu"
                tabIndex={0}
                role="button"
                type="button"
                onKeyDown={this.toggleDrawer('right', false)}
              >
                <div className={classes.rightDrawer}>
                  <List>
                    <ListItem button component={RouterLink} to="/admin/my-profile" onClick={this.toggleDrawer('right', false)}>
                      <ListItemIcon><FaceIcon /></ListItemIcon>
                      <ListItemText primary="My Profile" />
                    </ListItem>
                    <ListItem button onClick={logOut}>
                      <ListItemIcon><LockIcon /></ListItemIcon>
                      <ListItemText primary="Sign Out" />
                    </ListItem>
                  </List>
                </div>
              </div>
            </Drawer>
          </Toolbar>
        </AppBar>

        <div className={classes.content}>
          <AdminRoutes location={location} />
        </div>
        <AppBar position="static" className={classes.bottomAppBar}>
          <Toolbar className={classes.footer}>
            <Typography variant="body2" color="inherit">
              {`© ${moment().format('YYYY')}, `}
              <Link href="https://www.reihub.net" target="_blank>" color="inherit" underline="always">
                REI Hub
              </Link>
            </Typography>
            <Typography variant="body2" color="inherit" align="right">
              <Link href="https://www.reihub.net/terms" target="_blank>" color="inherit" underline="always">
                Terms of Service
              </Link>
              <br />
              <Link href="https://www.reihub.net/privacy" target="_blank>" color="inherit" underline="always">
                Privacy Policy
              </Link>
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Admin.contextType = PersonContext;

Admin.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Admin);
