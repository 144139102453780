import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ButtonBase } from '@material-ui/core';
import { get, create } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';
import {
  handleTextFieldChange,
  asyncHandleChange,
  handleKeyboardDatePickerChange,
  handleChange,
  handleUploaderChange,
  handleNumberFormatChange,
  handleAutocompleteChange,
  handleTransactionScopeChange,
} from '../../functions/InputHandlers';

import {
  sumProperty,
} from '../../functions/SumFunctions';

import {
  nameLabel,
  getAccountOptions,
  getDepositAccountOptions,
} from '../Autocomplete/Library';

import {
  getManualBasicJournal,
  getCreditJournalLine,
  getDebitJournalLine,
} from '../SearchSelect/TransactionTypeOptions';

import TransactionScope from '../TransactionScope';
import { setJournalScope } from '../../functions/JournalFunctions';

const styles = (theme) => ({
  amountCell: {
    width: '30%',
    maxWidth: '120px',
  },
  hideWidget: {
    display: 'none',
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddNetIncomeDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const {
      yodleeTransaction, unitId, propertyId, propertyManagerId, bankAccountId,
    } = this.props;
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      displayNotesSection: false,
    };

    const journal = getManualBasicJournal();
    journal.debitLines = journal.debitLines.concat([getDebitJournalLine()]);
    journal.type = 'Net Income';

    if (yodleeTransaction) {
      journal.debitLines[0].accountIdSelect = yodleeTransaction.account;
      journal.debitLines[0].yodleeTransactionId = yodleeTransaction.id;
      journal.debitLines[0].debit = yodleeTransaction.amount;
      journal.description = yodleeTransaction.description;
      journal.date = yodleeTransaction.date;
    }

    if (unitId) {
      const unit = await get(this, 'units', unitId);
      journal.propertyIdSelect = unit.property;
      journal.unitIdSelect = unit;
    } else if (propertyId) {
      const property = await get(this, 'properties', propertyId);
      journal.propertyIdSelect = property;
    }

    if (propertyManagerId) {
      const propertyManager = await get(this, 'property-managers', propertyManagerId);
      journal.debitLines[0].accountIdSelect = propertyManager.account;
    } else if (bankAccountId) {
      const account = await get(this, 'accounts', bankAccountId);
      journal.debitLines[0].accountIdSelect = account;
    }

    journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
    initialState.journal = journal;
    initialState.depositAccountOptions = await getDepositAccountOptions(this);
    initialState.revenueAccountOptions = await getAccountOptions(this, { $or: [{ type: 'Revenue' }, { type2: 'Sales and Use Tax' }] });
    initialState.expenseAccountOptions = await getAccountOptions(this, { type: 'Expense' });

    this.setState(initialState);
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return (
          <span className={classes.red}>
            {`(${value})`}
          </span>
        );
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return (
          <span className={classes.red}>
            {`(${value})`}
          </span>
        );
      }
    }
    return value;
  };

  addDebitLine = () => {
    const { journal } = this.state;
    handleChange('nested_journal_debitLines', journal.debitLines.concat([getDebitJournalLine()]), this);
  };

  addCreditLine = () => {
    const { journal } = this.state;
    handleChange('nested_journal_creditLines', journal.creditLines.concat([getCreditJournalLine()]), this);
  };

  deleteDebitLine = (index) => {
    const { journal } = this.state;
    journal.debitLines.splice(index, 1);
    handleChange('nested_journal_debitLines', journal.debitLines, this);
  };

  deleteCreditLine = (index) => {
    const { journal } = this.state;
    journal.creditLines.splice(index, 1);
    handleChange('nested_journal_creditLines', journal.creditLines, this);
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const {
      submitting, journal,
    } = this.state;

    const { onAddTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    const debitAmount = sumProperty(journal.debitLines, 'debit');
    const creditAmount = sumProperty(journal.creditLines, 'credit');

    if (debitAmount !== creditAmount) {
      this.setState({ error: { message: 'Amount Mismatch: Please double check the net amount entered equals the net calculated amount shown below expenses.' } });
      return;
    }

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    this.setState({ submitting: true });

    journal.organizationId = organizationId;
    journal.amount = debitAmount;
    journal.journalLines = journal.debitLines.concat(journal.creditLines);
    journal.journalLines.forEach((line) => {
      // eslint-disable-next-line no-param-reassign
      line.accountId = line.accountIdSelect.id;
    });

    setJournalScope(journal);

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    create(this, 'journals', journal, true)
      .then((result) => {
        this.closeDialog();
        onAddTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { yodleeTransaction, classes } = this.props;
    const {
      loading,
      error,
      journal,
      revenueAccountOptions,
      expenseAccountOptions,
      depositAccountOptions,
      displayNotesSection,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <form onSubmit={this.addTransaction}>
        <DialogTitle id="alert-dialog-title">
          Add Net Income
        </DialogTitle>
        <DialogContent>
          {yodleeTransaction && (
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Transaction
              </FormLabel>
              <Typography>
                {moment(yodleeTransaction.date).format('M/D/YYYY')}
                <Box px={2} component="span">|</Box>
                <NumberFormat
                  displayType="text"
                  value={yodleeTransaction.amount}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                />
                <Box px={2} component="span">|</Box>
                {yodleeTransaction.account.name}
              </Typography>
            </FormControl>
          )}
          <KeyboardDatePicker
            label="Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={journal.date}
            onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
            margin="dense"
            fullWidth
            clearable
            required
          />
          <FormControl margin="dense" fullWidth>
            <InputLabel required>
              Net Amount
            </InputLabel>
            <NumberFormat
              value={journal.debitLines[0].debit}
              required
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              onValueChange={handleNumberFormatChange('nested_journal_debitLines_0_debit', this)}
              customInput={Input}
            />
          </FormControl>
          <Autocomplete
            options={depositAccountOptions}
            getOptionLabel={nameLabel}
            value={journal.debitLines[0].accountIdSelect}
            onChange={handleAutocompleteChange(
              'nested_journal_debitLines_0_accountIdSelect',
              this,
            )}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                margin="dense"
                label="Deposit Account"
                placeholder="Type to Search"
                fullWidth
                required
              />
            )}
          />
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValues) => {
              handleTransactionScopeChange(journal, newScopeValues, this);
            }}
          />
          <TextField
            label="Description (optional)"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          <Box
            border={1}
            borderColor="grey.500"
            borderRadius="borderRadius"
            // bgcolor="common.white"
            padding={2}
            marginY={2}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="subtitle2">Revenues</Typography></TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {journal.creditLines.map((journalLine, index) => (
                  <TableRow key={journalLine.key}>
                    <TableCell>
                      <Autocomplete
                        options={revenueAccountOptions}
                        getOptionLabel={nameLabel}
                        value={journal.creditLines[index].accountIdSelect}
                        onChange={handleAutocompleteChange(
                          `nested_journal_creditLines_${index}_accountIdSelect`,
                          this,
                        )}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                            margin="dense"
                            label="Account"
                            placeholder="Type to Search"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell className={classes.amountCell}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel required>
                          Amount
                        </InputLabel>
                        <NumberFormat
                          value={journalLine.credit}
                          thousandSeparator
                          required
                          prefix="$"
                          decimalScale={2}
                          onValueChange={handleNumberFormatChange(`nested_journal_creditLines_${index}_credit`, this)}
                          customInput={Input}
                          endAdornment={journal.creditLines.length > 1 && (
                            <InputAdornment position="end">
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  this.deleteCreditLine(index);
                                }}
                              >
                                <ClearIcon style={{ fontSize: 18 }} />
                              </IconButton>
                            </InputAdornment>
                          )}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Button onClick={this.addCreditLine} color="primary">
                      Add Line
                    </Button>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      displayType="text"
                      value={sumProperty(journal.creditLines, 'credit')}
                      thousandSeparator
                      prefix={sumProperty(journal.creditLines, 'credit') < 0
                        ? 'Revenues: -$'
                        : 'Revenues: $'}
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
          <Box
            border={1}
            borderColor="grey.500"
            borderRadius="borderRadius"
            // bgcolor="common.white"
            padding={2}
            marginY={2}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="subtitle2">Expenses</Typography></TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {journal.debitLines.map((journalLine, index) => {
                  if (index > 0) {
                    return (
                      <TableRow key={journalLine.key}>
                        <TableCell>
                          <Autocomplete
                            options={expenseAccountOptions}
                            getOptionLabel={nameLabel}
                            value={journal.debitLines[index].accountIdSelect}
                            onChange={handleAutocompleteChange(
                              `nested_journal_debitLines_${index}_accountIdSelect`,
                              this,
                            )}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField
                                {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                                margin="dense"
                                label="Account"
                                placeholder="Type to Search"
                                fullWidth
                                required
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell className={classes.amountCell}>
                          <FormControl margin="dense" fullWidth>
                            <InputLabel required>
                              Amount
                            </InputLabel>
                            <NumberFormat
                              value={journalLine.debit}
                              thousandSeparator
                              required
                              prefix="$"
                              decimalScale={2}
                              onValueChange={handleNumberFormatChange(`nested_journal_debitLines_${index}_debit`, this)}
                              customInput={Input}
                              endAdornment={(
                                <InputAdornment position="end">
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      this.deleteDebitLine(index);
                                    }}
                                  >
                                    <ClearIcon style={{ fontSize: 18 }} />
                                  </IconButton>
                                </InputAdornment>
                              )}
                            />
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Button onClick={this.addDebitLine} color="primary">
                      Add Line
                    </Button>
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      displayType="text"
                      value={sumProperty(journal.debitLines, 'debit') - journal.debitLines[0].debit}
                      thousandSeparator
                      prefix={sumProperty(journal.debitLines, 'debit') - journal.debitLines[0].debit < 0
                        ? 'Expenses: -$'
                        : 'Expenses: $'}
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                    />
                    <br />
                    <NumberFormat
                      displayType="text"
                      value={sumProperty(journal.creditLines, 'credit') - sumProperty(journal.debitLines, 'debit') + journal.debitLines[0].debit}
                      thousandSeparator
                      prefix={
                        (sumProperty(journal.creditLines, 'credit') - sumProperty(journal.debitLines, 'debit') + journal.debitLines[0].debit) < 0
                          ? 'Net: -$'
                          : 'Net: $'
                      }
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.editImageButton}
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                className={classes.editImageButton}
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Net Income
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

AddNetIncomeDialogContent.contextType = PersonContext;

AddNetIncomeDialogContent.defaultProps = {
  yodleeTransaction: null,
  unitId: null,
  propertyId: null,
  propertyManagerId: null,
  bankAccountId: null,
};

AddNetIncomeDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  unitId: PropTypes.node,
  propertyId: PropTypes.node,
  propertyManagerId: PropTypes.node,
  bankAccountId: PropTypes.node,
  yodleeTransaction: PropTypes.objectOf(PropTypes.any),
};

export default withStyles(styles)(AddNetIncomeDialogContent);
