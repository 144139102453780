import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import {
  handleTextFieldChange,
  handleSearchSelectChange,
  filterSearchSelectOptions,
  handleCheckboxChange,
} from '../functions/InputHandlers';

import SearchSelect from './SearchSelect/SearchSelect';
import { stateCodeOptions } from './SearchSelect/StateCodeOptions';
import FormGridContainer from './FormGridContainer';
import FormGridItem from './FormGridItem';

const styles = {
  stateSelect: {
    marginTop: '4px',
  },
};

class AddVendorDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = () => {
    const initialState = {
      loading: false,
      addAddress: false,
      submitting: false,
      error: null,
      vendor: {
        name: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        stateSelect: null,
        zip: '',
        tin: '',
        is1099: false,
      },
    };

    this.setState(initialState);
  };

  addVendor = async (event) => {
    event.preventDefault();
    const {
      submitting, vendor, addAddress,
    } = this.state;

    const { organizationId } = this.context;
    const { onAddVendor, closeDialog } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    // eslint-disable-next-line prefer-object-spread
    const submitVendor = Object.assign({}, vendor, { organizationId });
    if (!addAddress) {
      submitVendor.address1 = '';
      submitVendor.address2 = '';
      submitVendor.city = '';
      submitVendor.state = '';
      submitVendor.zip = '';
    }

    if (!submitVendor.is1099) {
      submitVendor.tin = '';
    }

    create(this, 'vendors', submitVendor, true)
      .then((result) => {
        onAddVendor(result);
        closeDialog();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const {
      loading, error, vendor, addAddress,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        onClose={closeDialog}
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && (
          <form onSubmit={this.addVendor}>
            <DialogTitle id="alert-dialog-title">Add Vendor</DialogTitle>
            <DialogContent>
              <TextField
                label="Name"
                fullWidth
                margin="dense"
                required
                InputProps={{
                  value: vendor.name,
                  name: 'nested_vendor_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Email (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: vendor.email,
                  type: 'email',
                  name: 'nested_vendor_email',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Phone Number (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: vendor.phone,
                  type: 'tel',
                  name: 'nested_vendor_phone',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={addAddress}
                    name="addAddress"
                    onChange={handleCheckboxChange(this)}
                  />
                )}
                label="Add Address"
              />
              {addAddress && (
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  bgcolor="common.white"
                  padding={2}
                  marginY={2}
                >
                  <TextField
                    label="Street Address"
                    fullWidth
                    required
                    type="text"
                    autoComplete="off"
                    margin="dense"
                    InputProps={{
                      value: vendor.address1,
                      name: 'nested_vendor_address1',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                  <TextField
                    label="Ste, Fl, Unit, Etc. (optional)"
                    fullWidth
                    margin="dense"
                    autoComplete="off"
                    InputProps={{
                      value: vendor.address2,
                      name: 'nested_vendor_address2',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                  <FormGridContainer>
                    <FormGridItem xs={12} sm={6}>
                      <TextField
                        label="City"
                        fullWidth
                        required
                        type="text"
                        autoComplete="off"
                        margin="dense"
                        InputProps={{
                          value: vendor.city,
                          name: 'nested_vendor_city',
                          onChange: handleTextFieldChange(this),
                        }}
                      />
                    </FormGridItem>
                    <FormGridItem xs={12} sm={3}>
                      <SearchSelect
                        searchFunction={filterSearchSelectOptions(stateCodeOptions)}
                        changeFunction={handleSearchSelectChange(this)}
                        className={classes.stateSelect}
                        label="State"
                        autoComplete="off"
                        name="nested_vendor_state"
                        value={vendor.stateSelect}
                        required
                        isClearable
                      />
                    </FormGridItem>
                    <FormGridItem xs={12} sm={3}>
                      <TextField
                        label="Zip"
                        fullWidth
                        required
                        type="text"
                        autoComplete="off"
                        margin="dense"
                        InputProps={{
                          value: vendor.zip,
                          name: 'nested_vendor_zip',
                          onChange: handleTextFieldChange(this),
                        }}
                      />
                    </FormGridItem>
                  </FormGridContainer>
                </Box>
              )}
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={vendor.is1099}
                    name="nested_vendor_is1099"
                    onChange={handleCheckboxChange(this)}
                  />
                )}
                label="Track payments for 1099"
              />
              {vendor.is1099 && (
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  bgcolor="common.white"
                  padding={2}
                  marginY={2}
                >
                  <TextField
                    label="Tax Identification Number"
                    fullWidth
                    type="text"
                    autoComplete="off"
                    margin="dense"
                    InputProps={{
                      value: vendor.tin,
                      name: 'nested_vendor_tin',
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                </Box>
              )}
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Vendor
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

AddVendorDialog.contextType = PersonContext;

AddVendorDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddVendor: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddVendorDialog);
