import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import NumberFormat from 'react-number-format';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import EmptyState from '~/components/EmptyState';
import history from '../history';
import client from '../feathers';
import { find, get } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import { asyncHandleChange } from '../functions/InputHandlers';

import CardBase from '../components/CardBase';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';

import AddImportedTransactionDialog from '../components/AddImportedTransactionDialog';
import FilterYodleeTransactionsDialog from '../components/FilterYodleeTransactionsDialog';
import UploadTransactionsDialog from '../components/UploadTransactionsDialog';
import ViewBookedTransactionDialog from '../components/ViewBookedTransactionDialog';
import UnsplitYodleeTransactionDialog from '../components/UnsplitYodleeTransactionDialog';
import BatchChangeImportedTransactionDialog from '../components/BatchChangeImportedTransactionDialog';
import BatchChangeCard from './cards/BatchChangeCard';
import BatchBookTransactionsDialog from '../components/BatchBookTransactionsDialog';
import BatchBookFixedAssetPurchasesDialog from '../components/BatchBookFixedAssetPurchasesDialog';
import ImportFeedFilterCard from './cards/ImportFeedFilterCard';
import BatchBookTransfersDialog from '../components/BatchBookTransfersDialog';
import { TablePaginationButtons } from '../functions/TableFunctions';
import DownloadDialog from '../components/DownloadDialog';

const styles = (theme) => ({
  red: {
    color: 'red',
  },
  truncateOneLine: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  attachmentButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
  connectionsButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    width: 'fit-content',
  },
  childRow: {
    backgroundColor: theme.palette.grey[100],
  },
  whiteButton: {
    backgroundColor: 'white',
  },
});

class ImportedTransactions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterYodleeTransactionsDialog: false,
      uploadTransactionsDialog: false,
      addImportedTransactionDialog: false,
      unsplitYodleeTransactionDialog: false,
      selectedTransactionId: null,
      selectedTransaction: null,
      filter: {
        transactionStatusSelect: { name: 'All Transactions', id: 'All' },
        accountIdSelect: { name: 'All Accounts', id: 'All' },
        description: '',
        amount: null,
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
        page: 0,
      },
      batchChangeImportedTransactionDialog: false,
      batchBookTransactionsDialog: false,
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchType: null,
      batchPages: [],
      erroredAccount: false,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const { filter } = this.state;
    const newFilter = { ...filter };
    const parsedQuery = queryString.parse(location.search);

    if (parsedQuery.accountId) {
      const account = await get(this, 'accounts', parsedQuery.accountId);
      newFilter.accountIdSelect = account;
    }

    if (parsedQuery.description) {
      newFilter.description = parsedQuery.description;
    }

    if (parsedQuery.amount) {
      newFilter.amount = parsedQuery.amount;
    }

    if (parsedQuery.startDate) {
      newFilter.startDate = parsedQuery.startDate;
      newFilter.startDateSelect = moment(parsedQuery.startDate, 'YYYY-MM-DD');
    }

    if (parsedQuery.endDate) {
      newFilter.endDate = parsedQuery.endDate;
      newFilter.endDateSelect = moment(parsedQuery.endDate, 'YYYY-MM-DD');
    }

    if (
      ['Booked', 'Unbooked', 'Ignored', 'Split'].includes(parsedQuery.status)
    ) {
      newFilter.transactionStatusSelect = {
        name: parsedQuery.status,
        id: parsedQuery.status,
      };
    }
    await asyncHandleChange('filter', newFilter, this);

    await this.getYodleeTransactions();

    await this.checkForErroredAccounts();

    this.setState({
      loading: false,
    });
  }

  getYodleeTransactions = async () => {
    const { match } = this.props;
    const { organizationId } = this.context;
    const { filter } = this.state;

    let hasAccounts = true;

    const accountsPromise = find(this, 'accounts', {
      query: {
        organizationId,
        type: { $or: ['Asset', 'Liability'] },
        type2: { $or: ['Bank', 'Credit Card'] },
        inactive: { $or: [null, false] },
        $limit: 1,
      },
    });

    const accountsResponse = await accountsPromise;

    if (accountsResponse.data.length === 0) {
      hasAccounts = false;
    }

    const yodleeTransactionsService = client.service('yodlee-transactions');

    const query = {
      organizationId,
      pending: false,
      deleted: false,
      includeFullJournals: true,
      $limit: 50,
      $skip: filter.page * 50,
      $sort: {
        date: -1,
        amount: -1,
        id: 1,
      },
    };

    let newQuery = '';

    let displaySplitChildrenRows = false;

    // Used to determine if the filter is active so we can determine which empty state to display
    let isFilterActive = false;

    // If filter transaction status is 'all' or 'split' and the filter does not contain a value for
    // amount, set the displaySplitChildrenRows flag to true and exclude transactions with a split
    // parent ID.
    if (['All', 'Split'].includes(filter.transactionStatusSelect.id) && !filter.amount) {
      query.splitParentId = null;
      displaySplitChildrenRows = true;
    }

    if (['Booked', 'Unbooked'].includes(filter.transactionStatusSelect.id)) {
      query.transactionStatus = filter.transactionStatusSelect.id.toLowerCase();
      isFilterActive = true;
    }

    if (['Unbooked'].includes(filter.transactionStatusSelect.id)) {
      query.ignored = { $or: [false, null] };
      query.split = { $or: [false, null] };
      isFilterActive = true;
    }

    if (['Ignored'].includes(filter.transactionStatusSelect.id)) {
      query.ignored = true;
      isFilterActive = true;
    }

    if (['Split'].includes(filter.transactionStatusSelect.id)) {
      query.split = true;
      isFilterActive = true;
    }

    if (filter.accountIdSelect.id !== 'All') {
      query.accountId = filter.accountIdSelect.id;
      newQuery += `&accountId=${filter.accountIdSelect.id}`;
      isFilterActive = true;
    }

    if (filter.description) {
      query.description = { $iLike: `%${filter.description}%` };
      newQuery += `&description=${filter.description}`;
      isFilterActive = true;
    }

    if (filter.amount) {
      query.amount = filter.amount;
      newQuery += `&amount=${filter.amount}`;
      isFilterActive = true;
    }

    if (filter.startDate !== null) {
      query.date = { $gte: filter.startDate };
      newQuery += `&startDate=${filter.startDate}`;
      isFilterActive = true;
    }

    if (filter.endDate !== null) {
      if (query.date) {
        query.date.$lte = filter.endDate;
      } else {
        query.date = { $lte: filter.endDate };
      }
      newQuery += `&endDate=${filter.endDate}`;
      isFilterActive = true;
    }

    // update url with new params
    if (newQuery.length > 0) {
      history.replace(`${match.path}?${newQuery.substring(1)}`);
    } else {
      history.replace(match.path);
    }

    const yodleeTransactions = await yodleeTransactionsService.find({
      query,
    });

    const parentsAndChildren = [];

    // If the displaySplitChildrenRows flag is true, find all split transactions in the
    // yodleeTransactions array and find all their children. Then add all the children to the
    // parentsAndChildren array to refernece when rendering the table.
    if (displaySplitChildrenRows) {
      const splitParents = yodleeTransactions.data.filter(
        (transaction) => transaction.split === true,
      );
      splitParents.forEach((parent) => {
        parentsAndChildren.push({ parentId: parent.id, children: [] });
      });
      const childrenPromises = [];

      splitParents.forEach((parent) => {
        const splitQuery = {
          organizationId,
          pending: false,
          deleted: false,
          includeFullJournals: true,
          splitParentId: parent.id,
          $limit: 50,
          $sort: {
            amount: -1,
          },
        };
        childrenPromises.push(yodleeTransactionsService.find({ query: splitQuery }));
      });

      const childrenResults = await Promise.all(childrenPromises);

      childrenResults.forEach((result) => {
        if (result.data.length) {
          const resultParent = parentsAndChildren.find(
            (item) => item.parentId === result.data[0].splitParentId,
          );
          resultParent.children = result.data;
        }
      });
    }

    this.setState({
      totalTransactions: yodleeTransactions.total,
      yodleeTransactions: yodleeTransactions.data,
      displaySplitChildrenRows,
      parentsAndChildren: displaySplitChildrenRows ? parentsAndChildren : null,
      isFilterActive,
      hasAccounts,
    });
  };

  checkForErroredAccounts = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const parsedQuery = queryString.parse(location.search);
    let erroredAccount = false;
    const query = {
      organizationId,
      type2: ['Bank', 'Credit Card'],
      inactive: { $or: [null, false] },
      includeYodleeAccounts: true,
      includePlaidAccounts: true,
    };
    if (parsedQuery.accountId) {
      query.id = parsedQuery.accountId;
    }

    const accounts = await find(this, 'accounts', { query });

    accounts.data.forEach((account) => {
      if (account.plaid_account && account.plaid_account.plaid_item.requiresUpdate === true) {
        erroredAccount = true;
      }
      if (account.yodleeAccountId && account.yodlee_account.accountData.dataset[0].updateEligibility === 'ALLOW_UPDATE_WITH_CREDENTIALS') {
        erroredAccount = true;
      }
    });

    this.setState({ erroredAccount });
  };

  appliedFilter = () => {
    const { filter } = this.state;
    const filterArray = [];
    if (filter.description) {
      filterArray.push({
        label: 'Description Contains',
        value: filter.description,
        clear: () => this.clearFilterFields(['description']),
      });
    }
    if (filter.amount) {
      filterArray.push({
        label: 'Amount',
        value: `$${Number.parseFloat(filter.amount).toFixed(2)}`,
        clear: () => this.clearFilterFields(['amount']),
      });
    }
    if (filter.startDate !== null && filter.endDate !== null) {
      filterArray.push({
        label: 'Between',
        value: `${filter.startDateSelect.format(
          'M/D/YYYY',
        )} and ${filter.endDateSelect.format('M/D/YYYY')}`,
        clear: () => this.clearFilterFields([
          'startDate',
          'startDateSelect',
          'endDate',
          'endDateSelect',
        ]),
      });
    } else if (filter.startDate !== null) {
      filterArray.push({
        label: 'On or After',
        value: filter.startDateSelect.format('M/D/YYYY'),
        clear: () => this.clearFilterFields(['startDate', 'startDateSelect']),
      });
    } else if (filter.endDate !== null) {
      filterArray.push({
        label: 'On or Before',
        value: filter.endDateSelect.format('M/D/YYYY'),
        clear: () => this.clearFilterFields(['endDate', 'endDateSelect']),
      });
    }
    return filterArray;
  };

  clearFilterFields = (fieldList) => {
    const { filter } = this.state;
    const newFilter = { ...filter };
    fieldList.forEach((fieldName) => {
      newFilter[fieldName] = null;
    });
    newFilter.page = 0;
    this.updateFilter(newFilter);
  };

  closeAddImportedTransactionDialog = () => {
    this.setState({
      addImportedTransactionDialog: false,
    });
  };

  onAddImportedTransactionDialog = () => {
    this.getYodleeTransactions();

    this.setState({
      selectedTransactionId: null,
    });
  };

  openViewBookedTransactionDialog = (transaction) => {
    this.setState({
      selectedTransaction: transaction,
    });
  };

  openUnsplitDialog = (transactionId) => {
    this.setState({
      selectedTransactionId: transactionId,
      unsplitYodleeTransactionDialog: true,
    });
  };

  closeViewBookedTransactionDialog = () => {
    this.setState({
      selectedTransaction: null,
    });
  };

  onBookedTransactionUpdatedDialog = () => {
    this.getYodleeTransactions();

    this.setState({
      selectedTransaction: null,
    });
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getYodleeTransactions();
    this.checkForErroredAccounts();
    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchPages: [],
    });
  };

  closeFilterYodleeTransactionsDialog = () => {
    this.setState({
      filterYodleeTransactionsDialog: false,
    });
  };

  closeUploadTransactionsDialog = () => {
    this.setState({
      uploadTransactionsDialog: false,
    });
    this.getYodleeTransactions();
  };

  unignoreYodleeTransaction = async (yodleeTransactionId) => {
    const yodleeTransactionsService = client.service('yodlee-transactions');
    await yodleeTransactionsService.patch(yodleeTransactionId, {
      ignored: false,
    });
    this.getYodleeTransactions();
  };

  closeUnsplitYodleeTransactionDialog = () => {
    this.setState({
      unsplitYodleeTransactionDialog: false,
      selectedTransactionId: null,
    });
    this.getYodleeTransactions();
  };

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getYodleeTransactions();
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    return value;
  };

  bookedDisplay = (transaction) => {
    const { classes } = this.props;
    // return description for unbooked transactions
    if (!transaction.journal) {
      return null;
    }
    const nonTransactionLines = [];
    transaction.journal.journalLines.forEach((line) => {
      if (line.yodleeTransactionId !== transaction.id) {
        nonTransactionLines.push(line);
      }
    });
    const hasGutter = transaction.journal.propertyId || transaction.journal.entityId;
    return (
      <>
        <Typography
          className={classes.truncateOneLine}
          variant="body2"
          gutterBottom={hasGutter}
        >
          {`${transaction.journal.type}${nonTransactionLines.length !== 1
            ? ''
            : `: ${nonTransactionLines[0].account.name}`
          }`}
        </Typography>
      </>
    );
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, account, exportOptions } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        account,
        exportOptions,
        reportName: 'Import Feed',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'import-feed.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  }

  updateBatchType = async (type) => {
    const { nullBatchType, fullBatchChangeTargets } = this.state;

    await asyncHandleChange('batchType', type, this);

    let filteredTargets = [];
    let filteredTargetIds = [];

    if (nullBatchType) {
      if (type.id === 'Book Revenues') {
        filteredTargets = fullBatchChangeTargets.filter(
          (target) => !target.journalId
            && !target.ignored
            && !target.split
            && target.yodleeType === 'CREDIT',
        );

        filteredTargetIds = filteredTargets.map((target) => target.id);
      } else if ((type.id === 'Book Expenses') || (type.id === 'Book Fixed Asset Purchases')) {
        filteredTargets = fullBatchChangeTargets.filter(
          (target) => !target.journalId
            && !target.ignored
            && !target.split
            && target.yodleeType === 'DEBIT',
        );

        filteredTargetIds = filteredTargets.map((target) => target.id);
      } else if (type.id === 'Ignore' || type.id === 'Book Transfers') {
        filteredTargets = fullBatchChangeTargets.filter(
          (target) => !target.journalId
            && !target.ignored
            && !target.split,
        );

        filteredTargetIds = filteredTargets.map((target) => target.id);
      } else if (type.id === 'Delete') {
        filteredTargets = fullBatchChangeTargets.filter(
          (target) => !target.journalId
            && !target.split,
        );

        filteredTargetIds = filteredTargets.map((target) => target.id);
      } else if (type.id === 'Unignore') {
        filteredTargets = fullBatchChangeTargets.filter(
          (target) => target.ignored,
        );

        filteredTargetIds = filteredTargets.map((target) => target.id);
      } else if (type.id === 'Undo') {
        filteredTargets = fullBatchChangeTargets.filter(
          (target) => target.journal,
        );

        filteredTargetIds = filteredTargets.map((target) => target.id);
      }
      return this.setState({
        batchChangeTargets: filteredTargetIds,
        fullBatchChangeTargets: filteredTargets,
        nullBatchType: false,
      });
    }
    return this.setState({ batchChangeTargets: [], fullBatchChangeTargets: [], batchPages: [] });
  };

  openBatchChangeImportedTransactionDialog = () => {
    this.setState({ batchChangeImportedTransactionDialog: true });
  };

  openBatchBookTransactionsDialog = () => {
    this.setState({ batchBookTransactionsDialog: true });
  };

  setBatchEditState = () => {
    const { batchChange } = this.state;
    if (batchChange === true) {
      this.setState({
        batchChange: false,
        batchChangeTargets: [],
        fullBatchChangeTargets: [],
        batchPages: [],
        batchType: null,
      });
    } else {
      this.setState({ batchChange: true, nullBatchType: true });
    }
  };

  getBatchTransactions = (event, value, transaction) => {
    const { batchChangeTargets, fullBatchChangeTargets } = this.state;
    if (value) {
      this.setState({
        batchChangeTargets: [...batchChangeTargets, transaction.id],
        fullBatchChangeTargets: [...fullBatchChangeTargets, transaction],
      });
    } else {
      this.setState({
        batchChangeTargets: batchChangeTargets.filter(
          (target) => target !== transaction.id,
        ),
        fullBatchChangeTargets: fullBatchChangeTargets.filter(
          (target) => target.id !== transaction.id,
        ),
      });
    }
  };

  closeBatchDialogs = () => {
    this.setState({
      batchChangeImportedTransactionDialog: false,
      batchBookTransactionsDialog: false,
      batchBookFixedAssetPurchasesDialog: false,
      batchBookTransfersDialog: false,
    });
  };

  addAllPageTransactionsToBatch = (event, value) => {
    const {
      yodleeTransactions,
      batchChangeTargets,
      fullBatchChangeTargets,
      filter,
      batchPages,
      batchType,
    } = this.state;

    // Builds an array of transaction ids for the current page as dictated by the filter
    const batchArray = [];
    const fullBatchArray = [];

    if (!batchType) {
      yodleeTransactions.forEach((transaction) => {
        batchArray.push(transaction.id);
        fullBatchArray.push(transaction);
      });
    } else {
      switch (batchType.id) {
        case 'Ignore':
        case 'Book Transfers':
          for (let i = 0; i < yodleeTransactions.length; i += 1) {
            if (
              !yodleeTransactions[i].journalId
              && !yodleeTransactions[i].ignored
              && !yodleeTransactions[i].split
            ) {
              fullBatchArray.push(yodleeTransactions[i]);
              batchArray.push(yodleeTransactions[i].id);
            }
          }
          break;
        case 'Delete':
          for (let i = 0; i < yodleeTransactions.length; i += 1) {
            if (
              !yodleeTransactions[i].journalId
              && !yodleeTransactions[i].split
            ) {
              fullBatchArray.push(yodleeTransactions[i]);
              batchArray.push(yodleeTransactions[i].id);
            }
          }
          break;
        case 'Unignore':
          for (let i = 0; i < yodleeTransactions.length; i += 1) {
            if (yodleeTransactions[i].ignored) {
              fullBatchArray.push(yodleeTransactions[i]);
              batchArray.push(yodleeTransactions[i].id);
            }
          }
          break;
        case 'Undo':
          for (let i = 0; i < yodleeTransactions.length; i += 1) {
            if (yodleeTransactions[i].journalId) {
              batchArray.push(yodleeTransactions[i].id);
              fullBatchArray.push(yodleeTransactions[i]);
            }
          }
          break;
        case 'Book Revenues':
          for (let i = 0; i < yodleeTransactions.length; i += 1) {
            if (
              !yodleeTransactions[i].journalId
              && !yodleeTransactions[i].ignored
              && !yodleeTransactions[i].split
              && yodleeTransactions[i].yodleeType === 'CREDIT'
            ) {
              batchArray.push(yodleeTransactions[i].id);
              fullBatchArray.push(yodleeTransactions[i]);
            }
          }
          break;
        case 'Book Expenses':
        case 'Book Fixed Asset Purchases':
          for (let i = 0; i < yodleeTransactions.length; i += 1) {
            if (
              !yodleeTransactions[i].journalId
              && !yodleeTransactions[i].ignored
              && !yodleeTransactions[i].split
              && yodleeTransactions[i].yodleeType === 'DEBIT'
            ) {
              batchArray.push(yodleeTransactions[i].id);
              fullBatchArray.push(yodleeTransactions[i]);
            }
          }
          break;
        default:
          return;
      }
    }

    if (value) {
      // Ensures that duplicates are not present in the batch array if the users has already
      // checked some of the transactions on the page
      const newBatchTargets = [
        ...new Set([...batchChangeTargets, ...batchArray]),
      ];
      const newFullBatchTargets = [
        ...new Set([...fullBatchChangeTargets, ...fullBatchArray]),
      ];

      // Adds the current page to the batchPages array to be referenced when the user opts to
      // uncheck all - the check/uncheck functionality only affects the current paginated page
      const newBatchPage = [...batchPages, filter.page];

      this.setState({
        batchChangeTargets: newBatchTargets,
        fullBatchChangeTargets: newFullBatchTargets,
        batchPages: newBatchPage,
      });
    } else {
      this.setState({
        batchChangeTargets: batchChangeTargets.filter(
          (target) => !batchArray.includes(target),
        ),
        fullBatchChangeTargets: fullBatchChangeTargets.filter(
          (target) => !fullBatchArray.includes(target),
        ),
        batchPages: batchPages.filter((page) => page !== filter.page),
      });
    }
  };

  onBatchChangeTransactionsDialog = () => {
    this.getYodleeTransactions();

    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchPages: [],
      batchType: null,
    });
  };

  onBatchBookTransactionDialog = () => {
    this.getYodleeTransactions();

    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchPages: [],
      batchType: null,
    });
  }

  cancelBatchChange = () => {
    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchType: null,
    });
  };

  getBatchTransactionCheckBoxes = (transaction) => {
    const {
      batchType, batchChangeTargets, fullBatchChangeTargets,
    } = this.state;

    const checkBox = (
      <Checkbox
        onChange={(event, value) => this.getBatchTransactions(event, value, transaction)}
        checked={
          !!batchChangeTargets.find((target) => target === transaction.id)
        }
      />
    );

    if (!batchType) {
      return (
        <>
          <Checkbox
            onChange={(event, value) => this.getBatchTransactions(event, value, transaction)}
            checked={
              !!fullBatchChangeTargets.find(
                (target) => target.id === transaction.id,
              )
            }
          />
        </>
      );
    }

    if ((batchType.id === 'Book Expenses') || (batchType.id === 'Book Fixed Asset Purchases')) {
      if (
        !transaction.journalId
        && !transaction.ignored
        && !transaction.split
        && transaction.yodleeType === 'DEBIT'
      ) {
        return <>{checkBox}</>;
      }
    } else if (batchType.id === 'Book Revenues') {
      if (
        !transaction.journalId
        && !transaction.ignored
        && !transaction.split
        && transaction.yodleeType === 'CREDIT'
      ) {
        return <>{checkBox}</>;
      }
    } else if (batchType.id === 'Ignore' || batchType.id === 'Book Transfers') {
      if (
        !transaction.journalId
        && !transaction.ignored
        && !transaction.split
      ) {
        return <>{checkBox}</>;
      }
    } else if (batchType.id === 'Delete') {
      if (
        !transaction.journalId
        && !transaction.split
        && !transaction.splitParentId
      ) {
        return <>{checkBox}</>;
      }
    } else if (batchType.id === 'Unignore') {
      if (!transaction.journal && transaction.ignored) {
        return <>{checkBox}</>;
      }
    } else if (batchType.id === 'Undo') {
      if (transaction.journal) {
        return <>{checkBox}</>;
      }
    }
    return null;
  };

  getBatchTransactionStatus = (transaction) => {
    if (transaction.journal) {
      return 'Booked';
    }
    if (transaction.split) {
      return 'Split';
    }
    if (transaction.ignored) {
      return 'Ignored';
    }
    return 'Unbooked';
  };

  actionButtons = () => {
    const { match } = this.props;

    const { totalTransactions, isFilterActive } = this.state;

    if (totalTransactions === 0 && !isFilterActive) {
      return [];
    }

    const buttons = [
      {
        text: 'Upload',
        action: () => this.setState({ uploadTransactionsDialog: true }),
        class: 'add',
      },
      { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
      { text: 'Rules', link: `${match.path}/rules`, class: 'edit' },
      {
        text: 'Batch Book',
        action: () => this.setBatchEditState(),
        class: 'edit',
      },
    ];
    return buttons;
  };

  rowBuilder = (transaction, childRow) => {
    const { classes } = this.props;
    const {
      batchChange,
      filter,
      displaySplitChildrenRows,
    } = this.state;
    return (
      <TableRow className={childRow ? classes.childRow : null} key={`yodlee-${transaction.id}`}>
        {batchChange && (
          <TableCell>
            {this.getBatchTransactionCheckBoxes(transaction)}
          </TableCell>
        )}
        <TableCell>
          {!childRow && (
            <>
              {moment(transaction.date).format('M/D/YYYY')}
            </>
          )}
        </TableCell>
        <TableCell>
          {filter.accountIdSelect.id === 'All' ? (
            <>
              {displaySplitChildrenRows && !childRow && (
                <>
                  <Typography
                    className={classes.truncateOneLine}
                    variant="body2"
                    gutterBottom
                  >
                    {transaction.description}
                  </Typography>
                  <Typography
                    className={classes.truncateOneLine}
                    variant="caption"
                  >
                    {transaction.account.name}
                  </Typography>
                </>
              )}
              {displaySplitChildrenRows && childRow && (
                <Box marginLeft={2}>
                  <Typography
                    className={classes.truncateOneLine}
                    variant="body2"
                    gutterBottom
                  >
                    {`Split - ${transaction.description}`}
                  </Typography>
                  <Typography
                    className={classes.truncateOneLine}
                    variant="caption"
                  >
                    {transaction.account.name}
                  </Typography>
                </Box>
              )}
              {!displaySplitChildrenRows && (
                <>
                  <Typography
                    className={classes.truncateOneLine}
                    variant="body2"
                    gutterBottom
                  >
                    {transaction.splitParentId ? 'Split - ' : ''}
                    {transaction.description}
                  </Typography>
                  <Typography
                    className={classes.truncateOneLine}
                    variant="caption"
                  >
                    {transaction.account.name}
                  </Typography>
                </>
              )}
            </>
          ) : (
            <Box
              marginLeft={transaction.splitParentId ? 2 : 0}
            >
              <Typography
                className={classes.truncateOneLine}
                variant="body2"
              >
                {transaction.splitParentId ? 'Split - ' : ''}
                {transaction.description}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          {this.bookedDisplay(transaction)}
          {transaction.journal
            && transaction.journal.entityId
            && !transaction.journal.propertyId && (
              <Typography variant="caption" component="div">
                {transaction.journal.entity.name}
              </Typography>
          )}
          {transaction.journal
            && transaction.journal.propertyId && (
              <Typography variant="caption" component="div">
                {transaction.journal.property.address1}
              </Typography>
          )}
          {transaction.journal
            && transaction.journal.unitId && (
              <Typography variant="caption" component="div">
                {transaction.journal.unit.name}
              </Typography>
          )}
        </TableCell>
        <TableCell>
          {transaction.journal
            && transaction.journal.attachmentURL && (
              <Button
                className={classes.attachmentButton}
                aria-label="attachments"
                href={transaction.journal.attachmentURL.replace(
                  '~1/',
                  '~1/nth/0/',
                )}
                target="_blank"
              >
                <AttachFileIcon fontSize="small" />
              </Button>
          )}
        </TableCell>
        <TableCell align="right">
          <NumberFormat
            displayType="text"
            value={transaction.amount}
            thousandSeparator
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
            renderText={(value) => this.formatNegative(value, transaction)}
          />
        </TableCell>
        {batchChange && (
          <TableCell align="right">
            <Typography variant="body2">
              {this.getBatchTransactionStatus(transaction)}
            </Typography>
          </TableCell>
        )}
        {!batchChange && (
          <TableCell align="right">
            {!transaction.journal_line
              && !transaction.ignored
              && !transaction.split && (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disableElevation
                  onClick={() => {
                    this.setState({
                      selectedTransactionId: transaction.id,
                      addImportedTransactionDialog: true,
                    });
                  }}
                >
                  Book
                </Button>
            )}
            {transaction.journal_line && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.whiteButton}
                onClick={() => {
                  this.openViewBookedTransactionDialog(
                    transaction,
                  );
                }}
              >
                View
              </Button>
            )}
            {transaction.ignored && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.whiteButton}
                onClick={() => {
                  this.unignoreYodleeTransaction(
                    transaction.id,
                  );
                }}
              >
                Unignore
              </Button>
            )}
            {transaction.split && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  this.openUnsplitDialog(transaction.id);
                }}
              >
                Unsplit
              </Button>
            )}
          </TableCell>
        )}
      </TableRow>
    );
  }

  getSplitChildRow = (transaction) => {
    const { parentsAndChildren } = this.state;
    const parent = parentsAndChildren.find((item) => item.parentId === transaction.id);
    if (!parent) {
      return null;
    }
    return parent.children.map((child) => (
      <>
        {this.rowBuilder(child, true)}
      </>

    ));
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading,
      yodleeTransactions,
      filter,
      totalTransactions,
      addImportedTransactionDialog,
      filterYodleeTransactionsDialog,
      uploadTransactionsDialog,
      selectedTransactionId,
      selectedTransaction,
      unsplitYodleeTransactionDialog,
      batchChange,
      batchChangeTargets,
      fullBatchChangeTargets,
      batchChangeImportedTransactionDialog,
      batchBookTransactionsDialog,
      batchBookFixedAssetPurchasesDialog,
      batchBookTransfersDialog,
      batchType,
      batchPages,
      downloadDialog,
      erroredAccount,
      displaySplitChildrenRows,
      isFilterActive,
      hasAccounts,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Import Feed"
          actionButtons={this.actionButtons()}
          appliedFilter={this.appliedFilter()}
        />
        <AddImportedTransactionDialog
          isOpen={addImportedTransactionDialog}
          closeDialog={this.closeAddImportedTransactionDialog}
          onAddTransaction={this.onAddImportedTransactionDialog}
          yodleeTransactionId={selectedTransactionId}
        />
        {selectedTransaction && (
          <ViewBookedTransactionDialog
            closeDialog={this.closeViewBookedTransactionDialog}
            onTransactionModified={this.onBookedTransactionUpdatedDialog}
            journalId={selectedTransaction.journalId}
            importedTransactionId={selectedTransaction.id}
          />
        )}
        <FilterYodleeTransactionsDialog
          filter={filter}
          isOpen={filterYodleeTransactionsDialog}
          closeDialog={this.closeFilterYodleeTransactionsDialog}
          updateFilter={this.updateFilter}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({ downloadDialog: false })}
          setExportOptions={this.setExportOptions}
        />
        {uploadTransactionsDialog && (
          <UploadTransactionsDialog
            closeDialog={() => this.setState({ uploadTransactionsDialog: false })}
            onUploadTransactions={this.getYodleeTransactions}
            accountId={filter.accountIdSelect.id === 'All' ? null : filter.accountIdSelect.id}
          />
        )}
        {batchChangeImportedTransactionDialog && (
          <BatchChangeImportedTransactionDialog
            closeDialog={() => this.closeBatchDialogs()}
            transactions={batchChangeTargets}
            fullTransactions={fullBatchChangeTargets}
            onBatchChangeTransactions={this.onBatchChangeTransactionsDialog}
            batchType={batchType}
          />
        )}
        {batchBookTransactionsDialog && (
          <BatchBookTransactionsDialog
            closeDialog={() => this.closeBatchDialogs()}
            fullTransactions={fullBatchChangeTargets}
            onBatchBookTransactions={this.onBatchBookTransactionDialog}
            batchType={batchType}
          />
        )}
        {batchBookFixedAssetPurchasesDialog && (
          <BatchBookFixedAssetPurchasesDialog
            closeDialog={() => this.closeBatchDialogs()}
            fullTransactions={fullBatchChangeTargets}
            onBatchBookFixedAssetPurchases={this.onBatchBookTransactionDialog}
          />
        )}
        {batchBookTransfersDialog && (
          <BatchBookTransfersDialog
            closeDialog={() => this.closeBatchDialogs()}
            fullTransactions={fullBatchChangeTargets}
            onBatchBookTransfers={this.onBatchBookTransactionDialog}
          />
        )}
        {unsplitYodleeTransactionDialog && (
          <UnsplitYodleeTransactionDialog
            closeDialog={this.closeUnsplitYodleeTransactionDialog}
            onSplitTransaction={this.getYodleeTransactions}
            transactionId={selectedTransactionId}
          />
        )}
        {(totalTransactions > 0 || isFilterActive || hasAccounts) && (
          <ImportFeedFilterCard
            openFilter={() => this.setState({ filterYodleeTransactionsDialog: true })}
            updateFilter={this.updateFilter}
            filter={filter}
            fields={['importAccount', 'importedTransactionStatus']}
          />
        )}
        {erroredAccount && (
          <Grid item xs={12}>
            <CardBase>
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="body1" align="center">
                    {
                      filter.accountIdSelect.id !== 'All'
                        ? `This account could not be automatically refreshed. Click below
                        to view and restore your connections.`
                        : `One or more of your accounts could not be automatically refreshed. Click below
                        to view and restore your connections.`
                    }
                  </Typography>
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      className={classes.connectionsButton}
                      onClick={() => { history.push('/accounts/banking/connections'); }}
                      fullWidth
                    >
                      View Connections
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </CardBase>
          </Grid>
        )}
        {batchChange && (
          <BatchChangeCard
            openBatchChangeImportedTransactionDialog={
              () => this.setState({ batchChangeImportedTransactionDialog: true })
            }
            openBatchBookTransactionsDialog={
              () => this.setState({ batchBookTransactionsDialog: true })
            }
            openBatchBookFixedAssetPurchasesDialog={
              () => this.setState({ batchBookFixedAssetPurchasesDialog: true })
            }
            openBatchBookTransfersDialog={
              () => this.setState({ batchBookTransfersDialog: true })
            }
            updateBatchType={this.updateBatchType}
            transactions={batchChangeTargets}
            cancelBatchChange={this.cancelBatchChange}
            yodleeTransactions={yodleeTransactions}
          />
        )}
        {totalTransactions === 0 && isFilterActive && (
          <EmptyState
            icon="/icons/user_action_empty_state_icon.svg"
            title="No imported transactions were found"
            body={(
              <Typography variant="body1" align="center">
                Try adjusting your filter options to find what you are looking for or upload any
                new transactions.
              </Typography>
            )}
            buttonProps={[
              {
                color: 'purple',
                variant: 'contained',
                text: 'Clear Filters',
                onClickFunction: () => {
                  this.updateFilter({
                    transactionStatusSelect: { name: 'All Transactions', id: 'All' },
                    accountIdSelect: { name: 'All Accounts', id: 'All' },
                    description: '',
                    amount: null,
                    startDate: null,
                    endDate: null,
                    startDateSelect: null,
                    endDateSelect: null,
                    page: 0,
                  });
                },
              },
              {
                color: 'secondary',
                variant: 'outlined',
                text: 'Upload Transactions',
                segmentProps: {
                  event: 'import_manually clicked',
                  location: 'Import Feed Empty State',
                },
                onClickFunction: () => {
                  this.setState({ uploadTransactionsDialog: true });
                },
              },
            ]}
          />
        )}
        {totalTransactions === 0 && !isFilterActive && hasAccounts && (
          <EmptyState
            icon="/icons/no_data_empty_state_icon.svg"
            title="You don’t have any transactions"
            body={(
              <Typography variant="body1" align="center">
                If you&apos;re not seeing a transaction here, try checking the status of your
                linked accounts or you can also upload one below.
              </Typography>
            )}
            buttonProps={[
              {
                color: 'purple',
                variant: 'contained',
                text: 'Upload Transaction',
                onClickFunction: () => {
                  this.setState({ uploadTransactionsDialog: true });
                },
              },
              {
                color: 'secondary',
                variant: 'outlined',
                text: 'View Accounts',
                onClickFunction: () => {
                  history.push('/accounts/banking');
                  this.setState({ loading: true });
                },
              },
            ]}
          />
        )}
        {!hasAccounts && totalTransactions === 0 && !isFilterActive && (
          <EmptyState
            image="/rei_hub_accounts_intro_hero.png"
            title="Get your books in order within minutes"
            body={(
              <Typography variant="body1" align="center">
                Add financial transactions securely and automatically by linking an account.
                It&apos;ll accurately and automatically categorize revenue and expenses,
                eliminating manual data entry.
              </Typography>
            )}
            buttonProps={[
              {
                color: 'purple',
                variant: 'contained',
                text: 'Import Automatically',
                segmentProps: {
                  event: 'import_automatically clicked',
                  location: 'Import Feed Empty State',
                },
                onClickFunction: () => {
                  history.push('/accounts/banking?add=linked');
                },
              },
              {
                color: 'secondary',
                variant: 'outlined',
                text: 'Import Manually',
                segmentProps: {
                  event: 'import_manually clicked',
                  location: 'Import Feed Empty State',
                },
                onClickFunction: () => {
                  history.push('/accounts/banking?add=manual');
                },
              },
            ]}
            videoProps={{
              source: 'https://www.youtube.com/embed/FsUJU6eHLys?rel=0&amp;wmode=opaque',
              videoSegmentProps: {
                event: 'watch_overview_video clicked',
                location: 'Import Feed Empty State',
              },
            }}
          />
        )}
        {totalTransactions > 0 && (
          <Grid item xs={12}>
            <CardBase>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      {batchChange && (
                        <TableCell>
                          <Checkbox
                            onChange={(event, value) => {
                              this.addAllPageTransactionsToBatch(event, value);
                            }}
                            checked={
                              !!batchPages.includes(filter.page)
                              && batchChangeTargets.length > 0
                            }
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography variant="subtitle2">Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">Description</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">Booked</Typography>
                      </TableCell>
                      <TableCell />
                      <TableCell>
                        <Typography variant="subtitle2" align="right">
                          Amount
                        </Typography>
                      </TableCell>
                      {/* Hide the action column when batching... */}
                      {!batchChange && (
                        <TableCell>
                          <Typography variant="subtitle2" align="right">
                            Action
                          </Typography>
                        </TableCell>
                      )}
                      {/* ... and instead show a status column */}
                      {batchChange && (
                        <TableCell>
                          <Typography variant="subtitle2" align="right">
                            Status
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {yodleeTransactions.map((transaction) => (
                      <>
                        {!displaySplitChildrenRows && this.rowBuilder(transaction, false)}
                        {displaySplitChildrenRows && (
                          <>
                            {this.rowBuilder(transaction, false)}
                            {transaction.split && (
                              <>
                                {this.getSplitChildRow(transaction)}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        variant="footer"
                        count={totalTransactions}
                        page={filter.page}
                        rowsPerPage={50}
                        onPageChange={this.handleChangePage}
                        rowsPerPageOptions={[50]}
                        ActionsComponent={TablePaginationButtons}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </CardContent>
            </CardBase>
          </Grid>
        )}
      </PageGrid>
    );
  }
}

ImportedTransactions.contextType = PersonContext;

ImportedTransactions.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ImportedTransactions);
