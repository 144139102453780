import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import { Autocomplete } from '@material-ui/lab';
import { TextField, Typography } from '@material-ui/core';
import { PersonContext } from '../contexts/PersonContext';

import {
  handleAutocompleteChange,
  handleTextFieldChange,
} from '../functions/InputHandlers';
import { nameLabel, reportDeliveryFormats, reportExportFormats } from './Autocomplete/Library';

class DownloadDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  setInitialState = async () => {
    const { exportPdf, exportXlsx } = this.props;

    let exportFormats = reportExportFormats;

    if (!exportPdf) {
      exportFormats = exportFormats.filter(
        (option) => option.id !== '.pdf',
      );
    }

    if (!exportXlsx) {
      exportFormats = exportFormats.filter(
        (option) => option.id !== '.xlsx',
      );
    }

    this.setState({
      loading: false,
      step: 'exportOptions',
      exportFormats,
      exportOptions: {
        formatSelect: exportFormats[0],
        deliverySelect: { name: 'Download', id: 'download' },
        emailAddress: '',
      },
    });
  };

  export = async (event) => {
    event.preventDefault();
    const { exportPdf, exportXlsx, setExportOptions } = this.props;
    const { exportOptions } = this.state;

    await setExportOptions(exportOptions);

    this.setState({ step: 'exportInProgress' });

    if (exportOptions.formatSelect.id === '.xlsx') {
      await exportXlsx();
    } else {
      await exportPdf();
    }

    this.setState({ step: 'exportComplete' });
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, step, exportOptions, exportFormats, error,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={() => { this.setInitialState(); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="DownloadDialog"
      >
        {!loading && step === 'exportOptions' && (
          <form onSubmit={this.export}>
            <DialogTitle id="alert-dialog-title">Export Options</DialogTitle>
            <DialogContent>
              <Autocomplete
                options={exportFormats}
                getOptionLabel={nameLabel}
                value={exportOptions.formatSelect}
                onChange={handleAutocompleteChange(
                  'nested_exportOptions_formatSelect',
                  this,
                )}
                disableClearable
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line */}
                    margin="dense"
                    label="File Format"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <Autocomplete
                options={reportDeliveryFormats}
                getOptionLabel={nameLabel}
                value={exportOptions.deliverySelect}
                onChange={handleAutocompleteChange(
                  'nested_exportOptions_deliverySelect',
                  this,
                )}
                disableClearable
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line */}
                    margin="dense"
                    label="Delivery Method"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              {exportOptions.deliverySelect.id === 'email' && (
                <TextField
                  margin="dense"
                  label="Email Address"
                  type="email"
                  fullWidth
                  required
                  InputProps={{
                    value: exportOptions.emailAddress,
                    name: 'nested_exportOptions_emailAddress',
                    onChange: handleTextFieldChange(this),
                  }}
                />
              )}
              {error && <Typography color="error">{error}</Typography>}
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Export
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && step === 'exportInProgress' && (
          <DialogTitle id="alert-dialog-title">Preparing your export...</DialogTitle>
        )}
        {!loading && step === 'exportComplete' && (
          <>
            <DialogTitle id="alert-dialog-title">
              {exportOptions.deliverySelect.id === 'download' ? 'Export complete' : 'Email sent'}
            </DialogTitle>
            <DialogActions>
              <Button onClick={closeDialog} variant="contained" color="primary" disableElevation>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

DownloadDialog.contextType = PersonContext;

DownloadDialog.defaultProps = {
  exportPdf: null,
  exportXlsx: null,
  setExportOptions: null,
};

DownloadDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  exportPdf: PropTypes.func,
  exportXlsx: PropTypes.func,
  setExportOptions: PropTypes.func,
};

export default DownloadDialog;
