import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';

const styles = (theme) => ({
  // reverse the negative margins from page header for proper spacing
  buttonGroup: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
});

class ActionButtons extends React.PureComponent {
  render() {
    const { actionButtons, classes } = this.props;

    return (
      <ButtonGroup className={`noprint ${classes.buttonGroup}`} aria-label="action buttons">
        {actionButtons.map((button, index) => {
          const buttonProps = {
            variant: 'outlined',
            className: button.class,
          };
          if (button.link) {
            buttonProps.component = Link;
            buttonProps.to = button.link;
          } else if (button.action) {
            buttonProps.onClick = button.action;
          }

          if (button.class === 'filter') {
            buttonProps.startIcon = <FilterListIcon />;
          } else if (button.class === 'add') {
            buttonProps.startIcon = <AddIcon />;
          } else if (button.class === 'edit') {
            buttonProps.startIcon = <EditIcon />;
          } else if (button.class === 'delete') {
            buttonProps.startIcon = <DeleteOutlineIcon />;
          } else if (button.class === 'cancel') {
            buttonProps.startIcon = <CloseOutlinedIcon />;
          } else if (button.class === 'export') {
            buttonProps.startIcon = <GetAppIcon />;
          }
          // eslint-disable-next-line react/jsx-props-no-spreading, react/no-array-index-key
          return <Button {...buttonProps} key={index}>{button.text}</Button>;
        })}
      </ButtonGroup>
    );
  }
}

ActionButtons.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actionButtons: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(ActionButtons);
