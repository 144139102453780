import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Box, DialogContent, FormControl, Input, InputLabel, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { patch } from '~/feathersFunctionalWrapper';

export default function PropertyValues({ properties, changeToSubportfoliosOverview, setProperties }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMarketValueChange = (value, index) => {
    const property = properties[index];
    property.marketValue = value;
    const newProperties = [...properties];
    newProperties[index] = property;
    setProperties(newProperties);
  };

  const processPropertyValues = async (event) => {
    event.preventDefault();
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    const promises = [];
    properties.forEach((property) => {
      promises.push(
        patch('properties', property.id, {
          organizationId: property.organizationId,
          marketValue: property.marketValue,
        }),
      );
    });
    await Promise.all(promises);
    setIsSubmitting(false);
    changeToSubportfoliosOverview();
  };

  return (
    <DialogContent>
      <form onSubmit={processPropertyValues}>
        <Box mx="auto" mb={2}>
          <Typography variant="h6" gutterBottom>
            Please provide an estimated current market value for each property.
          </Typography>
        </Box>
        <Box mb={2}>
          {properties.map((property, index) => (
            <FormControl margin="dense" fullWidth key={property.id}>
              <InputLabel required>{`Market Value of ${property.address1}`}</InputLabel>
              <NumberFormat
                value={property.marketValue}
                required
                thousandSeparator
                prefix="$"
                decimalScale={2}
                onValueChange={(values) => {
                  handleMarketValueChange(values.floatValue, index);
                }}
                customInput={Input}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === '' || floatValue <= 99999999;
                }}
              />
            </FormControl>
          ))}
          <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
            <Typography variant="body2">
              {`REI Hub uses the current market value of properties in your portfolio to help
                    calculate your investment returns.`}
            </Typography>
          </Box>
        </Box>
        <Box maxWidth="400px" marginX="auto" mt={3} mb={2}>
          <Button hasFullWidth type="submit" variant="contained">
            Save and Continue
          </Button>
        </Box>
      </form>
    </DialogContent>
  );
}

PropertyValues.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeToSubportfoliosOverview: PropTypes.func.isRequired,
  setProperties: PropTypes.func.isRequired,
};
