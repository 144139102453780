import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';

import moment from 'moment';
import { create, find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleAutocompleteChange,
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleCheckboxChange,
} from '../functions/InputHandlers';

import { nameLabel } from './Autocomplete/Library';
import { buildQuery } from '../functions/FilterFunctions';
import FormGridContainer from './FormGridContainer';
import FormGridItem from './FormGridItem';
import { getManualBasicJournal } from './SearchSelect/TransactionTypeOptions';

class AddFixedAssetDepeciationDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dateRangeOptions: [
        { name: 'Last Year', id: 'Last Year' },
        { name: 'This Year', id: 'This Year' },
        { name: 'Custom', id: 'Custom' },
      ],
      dateRangeSelect: { name: 'Last Year', id: 'Last Year' },
      filter: {
        startDate: null,
        endDate: null,
      },
      step: 'dateRangeSelect',
    };
  }

  /**
   * Set filter start and end date based on date range selection
   */
  parseDate = () => {
    const { filter, dateRangeSelect } = this.state;

    const newFilter = filter;
    switch (dateRangeSelect.id) {
      case 'Last Year':
        newFilter.startDate = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
        newFilter.endDate = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
        break;
      case 'This Year':
        newFilter.startDate = moment().startOf('year').format('YYYY-MM-DD');
        newFilter.endDate = moment().endOf('year').format('YYYY-MM-DD');
        break;
      case 'Custom':
      default:
        newFilter.startDate = filter.startDate;
        newFilter.endDate = filter.endDate;
    }

    this.setState({ filter: newFilter });
  };

  getData = async (event) => {
    event.preventDefault();
    const { filter, submitting } = this.state;
    const { organizationId, basis } = this.context;
    const { fixedAssetId } = this.props;

    // Set the start and end dates based on the date range selection in previous step
    // warning: this currently uses state but is modifying a nested property
    // of the existing state object
    // rather than truly modifying and reloading state. It will need to be modified to async if
    // that becomes important
    this.parseDate();

    if (!filter.startDate || !filter.endDate) {
      return;
    }

    const assetQuery = {
      organizationId,
      $limit: 100,
      $skip: 0,
      $sort: {
        name: 1,
        id: 1,
      },
    };

    let assets = [];
    let assetsResult;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    if (fixedAssetId) {
      assetQuery.id = fixedAssetId;
      assetsResult = await find(this, 'fixed-assets', { query: assetQuery });
      assets = assetsResult.data;
    } else {
      do {
        // eslint-disable-next-line no-await-in-loop
        assetsResult = await find(this, 'fixed-assets', { query: assetQuery });
        assets = assets.concat(assetsResult.data);
        assetQuery.$skip += 100;
      } while (assets.length < assetsResult.total);
    }

    const reportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type2: ['Fixed Asset', 'Accumulated Depreciation'],
    });
    delete reportFilter.date.$gte;

    const accountsObject = {
      depreciable: {
        asset: {
          accounts: [],
        },
        depreciation: {
          accounts: [],
        },
        accounts: [],
      },
      nonDepreciable: {
        accounts: [],
      },
      all: {
        accounts: [],
      },
      byId: {},
    };
    // launch async calls
    // get totals and prep value array
    const accountsPromises = [
      create(this, 'reports', reportFilter)
        .then((result) => {
          /* eslint-disable no-param-reassign */
          result.forEach((account) => {
            account.values = [];
            account.display = false;
            account.subaccounts = [];
            if (account.type2 === 'Fixed Asset') {
              if (account.nonDepreciable) {
                accountsObject.nonDepreciable.accounts.push(account);
              } else {
                accountsObject.depreciable.asset.accounts.push(account);
                accountsObject.depreciable.accounts.push(account);
              }
            } else {
              accountsObject.depreciable.depreciation.accounts.push(account);
              accountsObject.depreciable.accounts.push(account);
            }
            accountsObject.all.accounts.push(account);
            accountsObject.byId[account.id] = account;
          });
          /* eslint-enable no-param-reassign */
          return result;
        }),
    ];
    // get data for each asset
    assets.forEach((asset) => {
      accountsPromises.push(
        create(this, 'reports', {
          ...reportFilter,
          fixedAssetId: asset.id,
        }),
      );
    });

    // get unassigned data
    accountsPromises.push(
      create(this, 'reports', {
        ...reportFilter,
        fixedAssetId: null,
      }),
    );

    // resolve promises
    const accountsResults = await Promise.all(accountsPromises);

    // populate values array
    for (let i = 0; i < accountsResults.length; i += 1) {
      accountsResults[i].forEach((account, index) => {
        accountsResults[0][index].values.push(-account.netCredits);
      });
    }

    const assetsArray = [];

    // eslint-disable-next-line array-callback-return
    assets.map((asset, index) => {
      if (asset.lifespan !== null) {
        let recommendedDepreciation = accountsObject.depreciable.asset.accounts.reduce(
          (total, account) => total + account.values[index + 1],
          0,
        ) / asset.lifespan;
        let start = moment(filter.startDate).year() * 12 + moment(filter.startDate).month();
        const end = moment(filter.endDate).year() * 12 + moment(filter.endDate).month() + 1;
        if (asset.placedInServiceDate !== null
          && moment(asset.placedInServiceDate).isSameOrAfter(moment(filter.startDate))) {
          start = moment(asset.placedInServiceDate).year() * 12
        + moment(asset.placedInServiceDate).month() + 0.5;
        }
        recommendedDepreciation *= (end - start) / 12;

        if (asset.placedInServiceDate !== null
          && moment(asset.placedInServiceDate).isAfter(moment(filter.endDate))) {
          recommendedDepreciation = 0;
        }

        const remainingDepreciation = accountsObject.depreciable.accounts.reduce(
          (total, account) => total + account.values[index + 1],
          0,
        );
        const maxDepreciation = Math.max(0, remainingDepreciation);

        /* eslint-disable no-param-reassign */
        asset.recommendedDepreciation = Math.min(maxDepreciation, recommendedDepreciation);
        asset.checked = asset.recommendedDepreciation > 0;
        /* eslint-ensable no-param-reassign */
      } else {
        /* eslint-disable no-param-reassign */
        asset.recommendedDepreciation = 0;
        asset.checked = false;
        /* eslint-ensable no-param-reassign */
      }

      assetsArray.push(
        { ...asset },
      );
    });

    this.setState({
      assetsArray,
      step: 'selectAssets',
      submitting: false,
    });
  };

  addTransactions = async (event) => {
    event.preventDefault();
    const { assetsArray, submitting, filter } = this.state;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    const depreciationExpenseAccount = await find(this, 'accounts', {
      query: {
        organizationId,
        default: 'depreciationExpense',
        $limit: 1,
      },
    });

    const accumulatedDepreciationAccount = await find(this, 'accounts', {
      query: {
        organizationId,
        default: 'accumulatedDepreciation',
        $limit: 1,
      },
    });

    const createPromises = [];

    assetsArray.forEach((asset) => {
      if (asset.checked) {
        const journalTemplate = getManualBasicJournal();

        journalTemplate.organizationId = organizationId;
        journalTemplate.date = filter.endDate;
        journalTemplate.type = 'Depreciation';
        journalTemplate.propertyId = asset.propertyId;
        journalTemplate.unitId = asset.unitId;
        journalTemplate.entityId = asset.entityId;

        journalTemplate.fixedAssetId = asset.id;

        journalTemplate.amount = asset.recommendedDepreciation;
        journalTemplate.creditLines[0].credit = asset.recommendedDepreciation;
        journalTemplate.creditLines[0].accountId = accumulatedDepreciationAccount.data[0].id;
        journalTemplate.debitLines[0].debit = asset.recommendedDepreciation;
        journalTemplate.debitLines[0].accountId = depreciationExpenseAccount.data[0].id;

        journalTemplate.journalLines = journalTemplate.creditLines.concat(
          journalTemplate.debitLines,
        );

        createPromises.push(create(this, 'journals', journalTemplate, true));
      }
    });

    await Promise.all(createPromises)
      .then((result) => {
        this.setState({ transactionCount: result.length, submitting: false, step: 'success' });
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };

  getDialogContent = () => {
    const { closeDialog } = this.props;
    const {
      step,
      filter,
      dateRangeOptions,
      dateRangeSelect,
      assetsArray,
      transactionCount,
      error,
    } = this.state;
    switch (step) {
      case 'dateRangeSelect':
        return (
          <form onSubmit={this.getData}>
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Select Depreciation Period
                </Typography>
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  // bgcolor="common.white"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body1">
                    Select the date range REI Hub should use to calculate
                    a recommended depreciation amount for each asset.
                    Depreciation expenses will be recorded on the final day of the selected period.
                  </Typography>
                </Box>
                <Autocomplete
                  options={dateRangeOptions}
                  getOptionLabel={nameLabel}
                  value={dateRangeSelect}
                  onChange={handleAutocompleteChange('dateRangeSelect', this)}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Date Range"
                      placeholder="Type to Search"
                      fullWidth
                    />
                  )}
                />
                {dateRangeSelect && dateRangeSelect.id === 'Custom' && (
                <FormGridContainer>
                  <FormGridItem xs={6}>
                    <KeyboardDatePicker
                      label="Start Date"
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      value={filter.startDate}
                      onChange={handleKeyboardDatePickerChange('nested_filter_startDate', this)}
                      margin="dense"
                      required
                      fullWidth
                      clearable
                    />
                  </FormGridItem>
                  <FormGridItem xs={6}>
                    <KeyboardDatePicker
                      label="End Date"
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      value={filter.endDate}
                      onChange={handleKeyboardDatePickerChange('nested_filter_endDate', this)}
                      margin="dense"
                      required
                      fullWidth
                      clearable
                    />
                  </FormGridItem>
                </FormGridContainer>
                )}
              </Box>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  Next
                </Button>
                <Button
                  color="primary"
                  onClick={() => closeDialog()}
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'selectAssets':
        return (
          <form onSubmit={this.addTransactions}>
            <DialogContent>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Select Fixed Assets
                </Typography>
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  // bgcolor="common.white"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body1">
                    Default depreciation amounts are calculated using the straight line method and
                    mid-month convention for the depreciation period.
                    Some assets may be eligible for bonus or accelerated
                    depreciation. Please consult your tax advisor.
                  </Typography>
                </Box>
              </Box>
              {assetsArray.map((asset, index) => (
                <Box
                  key={asset.id}
                  mx="auto"
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={asset.checked}
                        name={`nested_assetsArray_${index}_checked`}
                        onChange={handleCheckboxChange(this)}
                      />
                    )}
                    label={asset.name}
                  />
                  <Box mx="auto">
                    <Typography
                      variant="body2"
                      gutterBottom
                    >
                      {asset.property ? asset.property.address1 : 'No Property Assigned'}
                    </Typography>
                  </Box>
                  {asset.checked && (
                    <Box mx="auto">
                      <FormControl margin="dense" fullWidth>
                        <InputLabel required>
                          Depreciation
                        </InputLabel>
                        <NumberFormat
                          value={asset.recommendedDepreciation}
                          thousandSeparator
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale
                          onValueChange={handleNumberFormatChange(
                            `nested_assetsArray_${index}_recommendedDepreciation`,
                            this,
                          )}
                          customInput={Input}
                        />
                      </FormControl>
                    </Box>
                  )}
                </Box>
              ))}
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                <Typography color="error">{error && error.message}</Typography>
                <Button
                  type="submit"
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  Add Transactions
                </Button>
                <Button
                  color="primary"
                  onClick={() => closeDialog()}
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'success':
        return (
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              Success
            </Typography>
            <Typography variant="body1" gutterBottom>
              {`${transactionCount} ${transactionCount === 1
                ? 'depreciation expense was booked.'
                : 'depreciation expenses were booked.'}`}
            </Typography>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
              <Button
                color="primary"
                onClick={() => closeDialog()}
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        );
      default:
        return null;
    }
  };

  render() {
    const { isOpen, closeDialog } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        disableEscapeKeyDown
      >
        {this.getDialogContent()}
      </Dialog>
    );
  }
}

AddFixedAssetDepeciationDialog.contextType = PersonContext;

AddFixedAssetDepeciationDialog.defaultProps = {
  fixedAssetId: null,
};

AddFixedAssetDepeciationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  fixedAssetId: PropTypes.string,
};

export default AddFixedAssetDepeciationDialog;
