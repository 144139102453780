import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import history from '../history';
import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import {
  asyncHandleChange,
  asyncSetState,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine2 from '../components/FinancialAccountLine2';

class OperatingCashFlow extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        entityId: null,
        propertyId: null,
        unitId: null,
        startDateSelect: null,
        endDateSelect: null,
        entityIdSelect: null,
        propertyIdSelect: null,
        unitIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const incomeQuery = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });

    const principalQuery = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
      journalType: ['Loan Payment'],
    });

    const escrowQuery = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type2: ['Escrow'],
    });

    const accountsObject = {
      operatingRevenues: {
        accounts: [],
      },
      operatingExpenses: {
        accounts: [],
      },
      noi: {
        accounts: [],
      },
      interestExpenses: {
        accounts: [],
      },
      principalPaydown: {
        accounts: [],
      },
      debtService: {
        accounts: [],
      },
      escrows: {
        accounts: [],
      },
      allIncluded: {
        accounts: [],
      },
      excluded: {
        accounts: [],
      },
      byId: {},
    };

    // launch async calls
    // get totals and prep value array and display toggle
    const incomePromises = [
      await create(this, 'reports', incomeQuery)
        .then((result) => {
          /* eslint-disable no-param-reassign */
          result.forEach((account) => {
            account.display = false;
            account.subaccounts = [];
            account.values = [];
            if (
              account.type2 === 'Investing Revenue'
              || account.type2 === 'Depreciation Expense'
            ) {
              accountsObject.excluded.accounts.push(account);
            } else if (account.type2 === 'Interest Expense') {
              accountsObject.interestExpenses.accounts.push(account);
              accountsObject.debtService.accounts.push(account);
              accountsObject.allIncluded.accounts.push(account);
            } else if (account.type === 'Expense') {
              accountsObject.operatingExpenses.accounts.push(account);
              accountsObject.noi.accounts.push(account);
              accountsObject.allIncluded.accounts.push(account);
            } else if (account.type === 'Revenue') {
              accountsObject.operatingRevenues.accounts.push(account);
              accountsObject.noi.accounts.push(account);
              accountsObject.allIncluded.accounts.push(account);
            }
            accountsObject.byId[account.id] = account;
          });
          /* eslint-enable no-param-reassign */
          return result;
        }),
    ];

    // get totals and prep value array and display toggle
    const principalPromises = [
      await create(this, 'reports', principalQuery)
        .then((result) => {
          /* eslint-disable no-param-reassign */
          result.forEach((account) => {
            account.display = false;
            account.subaccounts = [];
            account.values = [];

            accountsObject.principalPaydown.accounts.push(account);
            accountsObject.debtService.accounts.push(account);
            accountsObject.allIncluded.accounts.push(account);

            accountsObject.byId[account.id] = account;
          });
          /* eslint-enable no-param-reassign */
          return result;
        }),
    ];

    // get totals and prep value array and display toggle
    const escrowPromises = [
      await create(this, 'reports', escrowQuery)
        .then((result) => {
          /* eslint-disable no-param-reassign */
          result.forEach((account) => {
            account.display = false;
            account.subaccounts = [];
            account.values = [];
            account.contributions = [];
            account.withdrawals = [];

            accountsObject.escrows.accounts.push(account);
            accountsObject.allIncluded.accounts.push(account);

            accountsObject.byId[account.id] = account;
          });
          /* eslint-enable no-param-reassign */
          return result;
        }),
    ];

    // resolve promises
    const incomeResults = await Promise.all(incomePromises);
    const principalResults = await Promise.all(principalPromises);
    const escrowResults = await Promise.all(escrowPromises);

    // assign parent accounts
    accountsObject.noi.accounts.forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
      }
    });

    // populate values arrays
    for (let i = 0; i < incomeResults.length; i += 1) {
      incomeResults[i].forEach((account, index) => {
        incomeResults[0][index].values.push(account.netCredits);
        if (account.netCredits !== 0) {
          incomeResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    for (let i = 0; i < principalResults.length; i += 1) {
      principalResults[i].forEach((account, index) => {
        principalResults[0][index].values.push(account.netCredits);
        if (account.netCredits !== 0) {
          principalResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    for (let i = 0; i < escrowResults.length; i += 1) {
      escrowResults[i].forEach((account, index) => {
        escrowResults[0][index].values.push(account.netCredits);
        escrowResults[0][index].contributions.push(-account.totalDebits);
        escrowResults[0][index].withdrawals.push(account.totalCredits);
        if (account.netCredits !== 0) {
          escrowResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    const headers = [null];

    await asyncSetState({
      loading: false,
      headers,
      accountsObject,
    }, this);
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  sumColumnValues = (accounts, property = 'values') => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account[property].forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  exportPdf = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const {
      filter,
      accountsObject,
      headers,
      exportOptions,
    } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-pdf`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        headers,
        exportOptions,
        reportName: 'Operating Cash Flow',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'operating-cash-flow.pdf';
        document.body.appendChild(a);
        a.click();
      });
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, accountsObject, exportOptions } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        exportOptions,
        reportName: 'Operating Cash Flow',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'operating-cash-flow.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Export', action: (() => this.setState({ downloadDialog: true })), class: 'export' },
    ]
  );

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      accountsObject,
      filter, filterDialog, loading,
      downloadDialog,
    } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Operating Cash Flow"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          scope={['Full Portfolio', 'Sub-Portfolio', 'Property', 'Unit']}
          required={['dateRange', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          exportPdf={this.exportPdf}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <>
                  <Box mx="auto" my="50px" maxWidth="600px">
                    <FinancialAccountLine2
                      label="Operating Income"
                      underline
                      overline
                      bold
                    />
                    {accountsObject.operatingRevenues.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        filter={filter}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Operating Revenues"
                      values={this.sumColumnValues(accountsObject.operatingRevenues.accounts)}
                      indent={1}
                      sumline
                      bold
                    />
                    {accountsObject.operatingExpenses.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        filter={filter}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Operating Expenses"
                      values={this.sumColumnValues(accountsObject.operatingExpenses.accounts)}
                      indent={1}
                      sumline
                      bold
                    />
                    <FinancialAccountLine2
                      label="Net Operating Income"
                      values={this.sumColumnValues(accountsObject.noi.accounts)}
                      underline
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine2
                      label="Debt Service"
                      underline
                      overline
                      bold
                    />
                    <FinancialAccountLine2
                      label="Interest Expenses"
                      values={this.sumColumnValues(accountsObject.interestExpenses.accounts)}
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Principal Repayment*"
                      values={this.sumColumnValues(accountsObject.principalPaydown.accounts)}
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Total Debt Service"
                      values={this.sumColumnValues(accountsObject.debtService.accounts)}
                      underline
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine2
                      label="Escrow Payments"
                      underline
                      overline
                      bold
                    />
                    <FinancialAccountLine2
                      label="Escrow Contributions"
                      values={this.sumColumnValues(accountsObject.escrows.accounts, 'contributions')}
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Escrow Withdrawals"
                      values={this.sumColumnValues(accountsObject.escrows.accounts, 'withdrawals')}
                      indent={1}
                    />
                    <FinancialAccountLine2
                      label="Net Escrow Payments"
                      values={this.sumColumnValues(accountsObject.escrows.accounts)}
                      underline
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine2
                      label="Operating Cash Flow"
                      values={this.sumColumnValues(accountsObject.allIncluded.accounts)}
                      underline
                      overline
                      bold
                    />
                  </Box>
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    // bgcolor="common.white"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="body2">
                      {`* Operating cash flow should include principal repayments that are part of your regular debt service. The Principal Repayments line totals Loan account activity recorded via the 
                      Loan Payment transaction type. Use a Manual Journal to exclude a Loan transaction 
                      from your operating cash flow.`}
                    </Typography>
                  </Box>
                </>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

OperatingCashFlow.contextType = PersonContext;

OperatingCashFlow.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OperatingCashFlow;
