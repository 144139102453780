import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { create, find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
  handleAutocompleteChange,
  handleCheckboxChange,
  handleUploaderChange,
} from '../functions/InputHandlers';

import {
  addressLabel,
  getPropertyOptions,
  getUnitOptions,
  nameLabel,
  padSubaccounts,
  getFilteredUnitOptions,
  getGroupedAccountOptions,
} from './Autocomplete/Library';
import { getManualBasicJournal } from './SearchSelect/TransactionTypeOptions';

const styles = {
  hideWidget: {
    display: 'none',
  },
};

class UploadPropertyManagerPdfDialog extends React.PureComponent {
    uploadcareWidget = React.createRef();

    constructor(props) {
      super(props);

      this.state = {
        key: null,
        data: {},
      };
    }

    setInitialState = async () => {
      const { organizationId } = this.context;
      const propertyOptions = await getPropertyOptions(this);
      const unitOptions = await getUnitOptions(this);

      const groupedAccountOptions = await getGroupedAccountOptions(this);

      const initialState = {
        key: 'uploadPdf',
        propertyOptions,
        unitOptions,
        groupedAccountOptions,
        submitting: false,
        error: null,
        data: {
          organizationId,
          filePath: '',
          filePath_info: {},
        },
      };

      this.setState(initialState);
    };

    closeDialog = () => {
      const { closeDialog } = this.props;
      this.setState({ key: null });
      closeDialog();
    }

    removeUploadedFile = async () => {
      await asyncHandleChange('nested_data_filePath', '', this);
    }

    /**
     * Uploads a pdf and hits the parsing service. Throws an error if the file is not a pdf, or if
     * the pdf is not recognized to be an Appfolio pdf. If the parsing is successful, this then
     * tries to match up properties/units and then advances onto the address select step of the
     * flow
     * @param {*} event
     * @returns parsed transactions and properties from the pdf
     */
    uploadPDF = async (event) => {
      event.preventDefault();
      const { data, submitting } = this.state;

      if (submitting) {
        return;
      }

      if (!data.filePath || data.filePath_info.mimeType !== 'application/pdf') {
        this.setState({ error: { message: 'Please add a .PDF file.' } });
        return;
      }

      this.setState({ submitting: true, error: null });

      create(this, 'upload-pm-pdf', data, true)
        .then(async (result) => {
          await asyncHandleChange('pdfData', result, this);
          this.matchSavedProperties();
        })
        .catch((err) => {
          // eslint-disable-next-line no-param-reassign
          err.message = 'We were unable to parse your pdf. Please email it to us at support@reihub.net for additional assistance.';
          this.setState({ error: err, submitting: false });
        });
    };

    /**
     * Attempts to match saved property rules to the pdf data and then sets the state to advance
     * to the next step of the flow
     */
    matchSavedProperties = async () => {
      const { organizationId } = this.context;
      const { propertyManagerId } = this.props;
      const {
        pdfData, propertyOptions, unitOptions,
      } = this.state;
      let skipAddressSelect = true;
      const unmappedPropertyDescriptions = [];

      const propertyMappingRulesResult = await find(this, 'property-manager-pdf-mapping-rules', {
        query: {
          organizationId,
          propertyManagerAccountId: propertyManagerId,
          mappingType: 'Property',
          $limit: 500,
        },
      });

      const propertyMappingRules = propertyMappingRulesResult.data;

      pdfData.forEach((item) => {
        // skip if already processed
        if (item.propertyRule) {
          return;
        }
        const propertyRule = propertyMappingRules.find(
          (rule) => rule.propertyName === item.property,
        );
        // If we can't find a matching property in the saved mapping rules, we know we need to
        // advance to the address select step and collect it
        if (!propertyRule) {
          skipAddressSelect = false;
          const unmappedProperty = {
            organizationId,
            mappingType: 'Property',
            propertyManagerAccountId: propertyManagerId,
            propertyIdSelect: null,
            unitIdSelect: null,
            propertyName: item.property,
            ignored: false,
          };
          this.autoFillPropertySelection(unmappedProperty);
          unmappedPropertyDescriptions.push(unmappedProperty);
        } else {
          // associate the found mapping rule
          // eslint-disable-next-line no-param-reassign
          item.propertyRule = propertyRule;

          // don't worry about anything else if it is an ignored property
          if (item.propertyRule.ignored) {
            return;
          }

          const property = propertyOptions.find(
            (propertyOption) => propertyOption.id === item.propertyRule.propertyId,
          );
          // process unit names if applicable
          // a single unit property can still prepend the unit name, which needs to be removed
          if (property.unitSelection === 'multi') {
            if (item.propertyRule.unitId) {
              property.units = unitOptions.filter(
                (unitOption) => unitOption.id === item.propertyRule.unitId,
              );
            } else {
              property.units = unitOptions.filter(
                (unitOption) => unitOption.propertyId === item.propertyRule.propertyId,
              );
            }

            // iterate transactions to look for matching unit names
            item.transactions.forEach((transaction) => {
              const unit = property.units.find(
                (unitOption) => {
                  if (!unitOption.name) {
                    return false;
                  }
                  let test = true;
                  try {
                    unitOption.name.split('-').forEach((nameSegment, index) => {
                      if (nameSegment.toLowerCase().trim()
                        !== transaction.splitDescription[index].toLowerCase().trim()) {
                        test = false;
                      }
                    });
                  } catch (error) {
                    test = false;
                  }
                  return test;
                },
              );
              if (unit) {
                // populate the unit id for the transaction
                // and remove the unit name (or multiple name segments) from the description array
                // eslint-disable-next-line no-param-reassign
                transaction.unitId = unit.id;
                unit.name.split('-').forEach(() => {
                  transaction.splitDescription.shift();
                });
              }
            });
          }
        }
      });

      // if we need to collect property data
      if (!skipAddressSelect) {
        this.setState({
          unmappedPropertyDescriptions,
          pdfData: [...pdfData],
          submitting: false,
          key: 'addressSelect',
        });
      } else {
        // else each item has a propertyRule, progress to accounts
        await asyncHandleChange('pdfData', [...pdfData], this);
        this.matchSavedAccounts();
      }
    }

    /**
    * Attempts to auto match the property for an unmapped property
    * @param {*} unmappedMroperty
    */
    autoFillPropertySelection = (unmappedProperty) => {
      const { propertyOptions } = this.state;

      const splitAddress = unmappedProperty.propertyName.split('-');

      const stateZipRegex = /([A-Z]{2})\s(\d{5})/;
      const foundAddressWithStateZip = splitAddress.find(
        (index) => index.match(stateZipRegex),
      );
      if (!foundAddressWithStateZip) {
        return;
      }
      const addressFirstNine = foundAddressWithStateZip.slice(0, 9).trim();

      const foundProperties = propertyOptions.filter((option) => (
        option.address1.includes(addressFirstNine)
      ));
      if (foundProperties.length === 1) {
        // eslint-disable-next-line no-param-reassign
        [unmappedProperty.propertyIdSelect] = foundProperties;
      }
    };

    // save the new property mappings then rerun the match function
    savePropertyMapping = async (event) => {
      event.preventDefault();
      const { submitting, unmappedPropertyDescriptions } = this.state;

      if (submitting) {
        return;
      }

      this.setState({ submitting: true });

      const createPromises = [];
      unmappedPropertyDescriptions.forEach((mapping) => {
        if (!mapping.ignored) {
          // eslint-disable-next-line no-param-reassign
          mapping.propertyId = mapping.propertyIdSelect.id;
          // if a unit in the property has been selected, use it
          if (mapping.unitIdSelect && mapping.unitIdSelect.propertyId === mapping.propertyId) {
            // eslint-disable-next-line no-param-reassign
            mapping.unitId = mapping.unitIdSelect.id;
          }
        }
        createPromises.push(create(this, 'property-manager-pdf-mapping-rules', mapping, true));
      });

      await Promise.all(createPromises)
        .then(() => {
          this.matchSavedProperties();
        })
        .catch((err) => {
          this.setState({ error: err, submitting: false });
        });
    }

    /**
     * Attempts to match saved account rules to the pdf data and then sets the state to advance
     * to the next step of the flow
     */
    matchSavedAccounts = async () => {
      const { organizationId } = this.context;
      const { propertyManagerId } = this.props;
      const {
        pdfData, groupedAccountOptions,
      } = this.state;
      let skipAccountSelect = true;
      const unmappedAccountDescriptions = [];

      const accountMappingRulesResult = await find(this, 'property-manager-pdf-mapping-rules', {
        query: {
          organizationId,
          propertyManagerAccountId: propertyManagerId,
          mappingType: 'Account',
          $limit: 500,
        },
      });

      const accountMappingRules = accountMappingRulesResult.data;

      accountMappingRules.forEach((accountMapping) => {
        // eslint-disable-next-line no-param-reassign
        accountMapping.account = groupedAccountOptions.find(
          (option) => option.id === accountMapping.accountId,
        );
      });

      pdfData.forEach((item) => {
        if (item.propertyRule.ignored) {
          // skip ignored properties
          return;
        }

        item.transactions.forEach((transaction) => {
          // skip if already processed
          if (transaction.accountRule) {
            return;
          }
          const accountRule = accountMappingRules.find(
            (rule) => rule.accountName === transaction.splitDescription[0],
          );
          // If we can't find a matching account in the saved mapping rules, we know we need to go
          // ahead and advance to the account select step
          if (!accountRule) {
            // see if it is already in our unmapped array and continue if so
            if (unmappedAccountDescriptions.find(
              (unmapped) => unmapped.accountName === transaction.splitDescription[0],
            )) {
              return;
            }
            // add it to the unmapped array if applicable
            skipAccountSelect = false;
            const unmappedAccount = {
              organizationId,
              mappingType: 'Account',
              propertyManagerAccountId: propertyManagerId,
              accountIdSelect: null,
              accountName: transaction.splitDescription[0],
              ignored: false,
            };
            this.autoFillAccountSelection(unmappedAccount);
            unmappedAccountDescriptions.push(unmappedAccount);
          } else {
            // eslint-disable-next-line no-param-reassign
            transaction.accountRule = accountRule;
          }
        });
      });

      if (!skipAccountSelect) {
        this.setState({
          unmappedAccountDescriptions,
          pdfData: [...pdfData],
          submitting: false,
          key: 'accountSelect',
        });
      } else {
        // each item has a accountRule, progress to save
        await asyncHandleChange('pdfData', [...pdfData], this);
        this.bookTransactions();
      }
    }

    /**
     * Attempts to match the description of each item in the dataObjects array to an account name
     * using regexes and then sets the account selection for each item in the array if possible
     * @param {*} dataObjects
     */
    autoFillAccountSelection = (unmappedAccount) => {
      const { groupedAccountOptions } = this.state;

      // Regex "dictionary" to attempt to match words in description to account names
      // NOTE: We should be able to add terms to these regexes as we come across them to make
      // account matching beefier
      const rentAccountRegex = /rent/gi;
      const repairAccountRegex = /repair|labor|hvac/gi;
      const maintenanceAccountRegex = /maintenance|cleaning|lawn|yard|landscap|mow|garden|pest|rodent|insect|bug/gi;
      const utilitiesAccountRegex = /utilit|water|sewer|electric|internet/gi;
      const managementFeesAccountRegex = /management/gi;
      const suppliesAccountRegex = /material|supplies/gi;

      if (unmappedAccount.accountName.match(rentAccountRegex)) {
        const rentAccount = groupedAccountOptions.find((option) => option.default === 'rentRevenue');
        // eslint-disable-next-line no-param-reassign
        unmappedAccount.accountIdSelect = rentAccount;
      } else if (unmappedAccount.accountName.match(repairAccountRegex)) {
        const repairAccount = groupedAccountOptions.find((option) => option.default === 'repairsExpense');
        // eslint-disable-next-line no-param-reassign
        unmappedAccount.accountIdSelect = repairAccount;
      } else if (unmappedAccount.accountName.match(maintenanceAccountRegex)) {
        const maintenanceAccount = groupedAccountOptions.find(
          (option) => option.default === 'cleaningAndMaintenanceExpense',
        );
        // eslint-disable-next-line no-param-reassign
        unmappedAccount.accountIdSelect = maintenanceAccount;
      } else if (unmappedAccount.accountName.match(utilitiesAccountRegex)) {
        const utilitiesAccount = groupedAccountOptions.find(
          (option) => option.default === 'utilitiesExpense',
        );
        // eslint-disable-next-line no-param-reassign
        unmappedAccount.accountIdSelect = utilitiesAccount;
      } else if (unmappedAccount.accountName.match(managementFeesAccountRegex)) {
        const managementFeesAccount = groupedAccountOptions.find(
          (option) => option.default === 'managementFeesExpense',
        );
        // eslint-disable-next-line no-param-reassign
        unmappedAccount.accountIdSelect = managementFeesAccount;
      } else if (unmappedAccount.accountName.match(suppliesAccountRegex)) {
        const suppliesAccount = groupedAccountOptions.find(
          (option) => option.default === 'suppliesExpense',
        );
        // eslint-disable-next-line no-param-reassign
        unmappedAccount.accountIdSelect = suppliesAccount;
      }

      if (this.checkForTransfers(unmappedAccount)) {
        // eslint-disable-next-line no-param-reassign
        unmappedAccount.ignored = true;
      }
    };

    // save the new account mappings then rerun the match function
    saveAccountMapping = async (event) => {
      event.preventDefault();
      const { unmappedAccountDescriptions, submitting } = this.state;

      if (submitting) {
        return;
      }

      this.setState({ submitting: true });

      const createPromises = [];
      unmappedAccountDescriptions.forEach((mapping) => {
        if (!mapping.ignored) {
          // eslint-disable-next-line no-param-reassign
          mapping.accountId = mapping.accountIdSelect.id;
        }
        createPromises.push(create(this, 'property-manager-pdf-mapping-rules', mapping, true));
      });

      await Promise.all(createPromises)
        .then(() => {
          this.matchSavedAccounts();
        })
        .catch((err) => {
          this.setState({ error: err, submitting: false });
        });
    }

    /**
     * Sets up journals for all included transactions, creates a promise array of all the journals,
     * and then books all the journals.
     * @param {*} event
     */
    bookTransactions = async () => {
      const { organizationId } = this.context;
      const { propertyManagerId, onBookUploadedTransactions } = this.props;
      const {
        pdfData, data,
      } = this.state;

      const pdfTransactions = [];

      pdfData.forEach((item) => {
        if (item.propertyRule.ignored) {
          // skip ignored properties
          return;
        }

        item.transactions.forEach((transaction) => {
          if (transaction.accountRule.ignored) {
            // skip ignored accounts
            return;
          }

          const journalTemplate = getManualBasicJournal();

          journalTemplate.organizationId = organizationId;
          journalTemplate.attachmentURL = data.filePath;

          // PDF data contains commas in the amount when it's a thousand or more and the api barfs
          // on that so any commas are stripped out here
          const transactionAmount = transaction.amount.replace(',', '');
          journalTemplate.amount = transactionAmount;
          journalTemplate.creditLines[0].credit = transactionAmount;
          journalTemplate.debitLines[0].debit = transactionAmount;

          // Set revenue and expense specific fields
          if (transaction.direction === 'Cash In') {
            journalTemplate.debitLines[0].accountId = propertyManagerId;
            journalTemplate.creditLines[0].accountId = transaction.accountRule.accountId;
            if (transaction.accountRule.account.type === 'Revenue') {
              journalTemplate.type = 'Revenue';
            } else if (transaction.accountRule.account.type === 'Expense') {
              journalTemplate.type = 'Refund';
            } else {
              journalTemplate.type = 'Transfer';
            }
          } else if (transaction.direction === 'Cash Out') {
            journalTemplate.creditLines[0].accountId = propertyManagerId;
            journalTemplate.debitLines[0].accountId = transaction.accountRule.accountId;
            if (transaction.accountRule.account.type === 'Revenue') {
              journalTemplate.type = 'Refund Issued';
            } else if (transaction.accountRule.account.type === 'Expense') {
              journalTemplate.type = 'Expense';
            } else {
              journalTemplate.type = 'Transfer';
            }
          }

          journalTemplate.journalLines = journalTemplate.creditLines.concat(
            journalTemplate.debitLines,
          );

          journalTemplate.description = transaction.description;
          journalTemplate.date = transaction.date;

          journalTemplate.propertyId = item.propertyRule.propertyId;
          journalTemplate.unitId = transaction.unitId
            ? transaction.unitId : item.propertyRule.unitId;

          pdfTransactions.push(journalTemplate);
        });
      });

      const createPromises = pdfTransactions.map(
        (journal) => create(this, 'journals', journal, true),
      );

      await Promise.all(createPromises)
        .then(async () => {
          this.setState({ submitting: false, key: 'confirmation' });
          onBookUploadedTransactions();
          /* await create(
            this,
            'contact-us',
            {
              organizationId,
              personContext: this.context,
              subject: 'PM pdf parsed',
              message: `PDF url: ${data.filePath}`,
            },
          ); */
        })
        .catch((err) => {
          this.setState({ error: err, submitting: false });
        });
    }

    /**
     * Checks to see if a transfer warning should be displayed
     * @param {*} item
     * @returns {boolean} true if item is a transfer, false otherwise
     */
    checkForTransfers = (accountMapping) => {
      if (accountMapping.accountName.toLowerCase().includes('transfer')
        || accountMapping.accountName.toLowerCase().includes('owner')) {
        return true;
      }
      return false;
    };

    getDialogContent = () => {
      const { classes } = this.props;
      const {
        key, data, error, propertyOptions, unitOptions,
        unmappedPropertyDescriptions, unmappedAccountDescriptions,
        groupedAccountOptions,
      } = this.state;

      switch (key) {
        case 'uploadPdf':
          return (
            <form onSubmit={this.uploadPDF}>
              <DialogContent>
                <Box mx="auto" mb={2}>
                  <Typography variant="h6" gutterBottom>
                    Please select the property manager statement PDF from your computer.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Note: We are currently only accepting PDFs from Appfolio.
                  </Typography>
                </Box>
                <FormControl margin="dense">
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    File
                  </FormLabel>
                  <div className={data.filePath ? null : classes.hideWidget}>
                    <Widget
                      ref={this.uploadcareWidget}
                      tabs="file"
                      value={data.filePath}
                      onChange={handleUploaderChange('nested_data_filePath', this)}
                    />
                  </div>
                  {!data.filePath && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      this.uploadcareWidget.current.openDialog();
                    }}
                  >
                    Click to add your PDF
                  </Button>
                  )}
                  {data.filePath && (
                  <Button
                    color="primary"
                    onClick={() => this.removeUploadedFile()}
                  >
                    Remove statement
                  </Button>
                  )}
                </FormControl>
                {error && (
                <Typography variant="body1" gutterBottom color="error">
                  {error.message}
                </Typography>
                )}
                <Box mx="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Upload PDF
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </DialogContent>
            </form>
          );
        case 'addressSelect':
          return (
            <form onSubmit={this.savePropertyMapping}>
              <DialogContent>
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Properties
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    We found the following unknown addresses in your upload and have tried to match
                    them to properties from your portfolio. Please ensure that the correct property
                    is selected for each address.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {`Note: These choices will be saved to automatically assign transactions to the
                    chosen properties on subsequent property manager PDF uploads.`}
                  </Typography>
                  {unmappedPropertyDescriptions.map((unmappedProperty, index) => (
                    <Box
                      border={1}
                      borderColor="grey.500"
                      borderRadius="borderRadius"
                      padding={2}
                      marginY={2}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <Typography variant="body1" gutterBottom>
                        {unmappedProperty.propertyName}
                      </Typography>
                      {!unmappedProperty.ignored && (
                        <>
                          <Autocomplete
                            options={propertyOptions}
                            getOptionLabel={addressLabel}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={unmappedProperty.propertyIdSelect}
                            onChange={handleAutocompleteChange(
                              `nested_unmappedPropertyDescriptions_${index}_propertyIdSelect`,
                              this,
                            )}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                margin="dense"
                                label="Property"
                                placeholder="Type to search"
                                fullWidth
                                required
                              />
                            )}
                          />
                          {unmappedProperty.propertyIdSelect && unmappedProperty.propertyIdSelect.unitSelection === 'multi' && (
                            <Autocomplete
                              options={getFilteredUnitOptions(
                                unitOptions,
                                unmappedProperty.propertyIdSelect.id,
                                { addAll: true },
                              )}
                              getOptionLabel={nameLabel}
                              getOptionSelected={(option, value) => option.id === value.id}
                              value={unmappedProperty.unitIdSelect}
                              onChange={handleAutocompleteChange(
                                `nested_unmappedPropertyDescriptions_${index}_unitIdSelect`,
                                this,
                              )}
                              disableClearable
                              renderInput={(params) => (
                                <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...params}
                                  margin="dense"
                                  label="Unit"
                                  placeholder="Type to search"
                                  fullWidth
                                  required
                                  helperText={'If your statement contains unit names within the transactions, select "All Units"'}
                                />
                              )}
                            />
                          )}
                        </>
                      )}
                      <FormControl margin="none">
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={unmappedProperty.ignored}
                              name={`nested_unmappedPropertyDescriptions_${index}_ignored`}
                              onChange={handleCheckboxChange(this)}
                            />
                          )}
                          label="Ignore"
                        />
                      </FormControl>
                    </Box>
                  ))}
                </Box>
                <Box mx="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Save and Continue
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </DialogContent>
            </form>
          );
        case 'accountSelect':
          return (
            <form onSubmit={this.saveAccountMapping}>
              <DialogContent>
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Accounts
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    We found the following unknown account descriptions in your upload. Please
                    ensure that the correct account is selected for each description.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {`Note: These choices will be saved to automatically assign transactions to the
                    chosen accounts on subsequent property manager PDF uploads.`}
                  </Typography>
                  {unmappedAccountDescriptions.map((unmappedAccount, index) => (
                    <Box
                      border={1}
                      borderColor="grey.500"
                      borderRadius="borderRadius"
                      padding={2}
                      marginY={2}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <Typography variant="body1" gutterBottom>
                        {unmappedAccount.accountName}
                      </Typography>
                      {!unmappedAccount.ignored && (
                        <Autocomplete
                          groupBy={(option) => option.type}
                          options={groupedAccountOptions}
                          getOptionLabel={nameLabel}
                          renderOption={padSubaccounts}
                          getOptionSelected={(option, value) => option.id === value.id}
                          value={unmappedAccount.accountIdSelect}
                          onChange={handleAutocompleteChange(
                            `nested_unmappedAccountDescriptions_${index}_accountIdSelect`,
                            this,
                          )}
                          disableClearable
                          renderInput={(params) => (
                            <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                              margin="dense"
                              label="Account"
                              placeholder="Type to search"
                              fullWidth
                              required
                            />
                          )}
                        />
                      )}
                      <FormControl margin="none">
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={unmappedAccount.ignored}
                              name={`nested_unmappedAccountDescriptions_${index}_ignored`}
                              onChange={handleCheckboxChange(this)}
                            />
                          )}
                          label="Ignore"
                        />
                      </FormControl>
                      {this.checkForTransfers(unmappedAccount) && (
                        <Typography variant="body2" gutterBottom>
                          In most cases inter-property transfers, owner contributions,
                          and draws should be ignored. Book any transfers to or from
                          your property manager from your bank import feed.
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Box>
                <Box mx="auto" textAlign="center" mt={4}>
                  <Typography color="error">{error && error.message}</Typography>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Complete Upload
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </DialogContent>
            </form>
          );
        case 'confirmation':
          return (
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Success
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {
                  `We have booked the transactions included in your PDF. Be sure to book any 
                  owner contributions or payouts included in this statement as transfers 
                  from your import feed. Please double check the imported transactions 
                  and email us at support@reihub.net if you require assistance.`
                  }
                </Typography>
              </Box>
              <Box mx="auto" textAlign="center" mt={4} mb={2}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Close
                </Button>
              </Box>
            </DialogContent>
          );
        default:
          return null;
      }
    };

    render() {
      const { isOpen } = this.props;

      return (
        <Dialog
          open={isOpen}
          scroll="body"
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
          onEnter={this.setInitialState}
        >
          {this.getDialogContent()}
        </Dialog>
      );
    }
}

UploadPropertyManagerPdfDialog.contextType = PersonContext;

UploadPropertyManagerPdfDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  propertyManagerId: PropTypes.number.isRequired,
  onBookUploadedTransactions: PropTypes.func.isRequired,
};

export default withStyles(styles)(UploadPropertyManagerPdfDialog);
