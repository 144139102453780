import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import client from '../../feathers';
import { create } from '../../feathersWrapper';

import { PersonContext } from '../../contexts/PersonContext';
import {
  handleTextFieldChange,
} from '../../functions/InputHandlers';

import FormGridContainer from '../../components/FormGridContainer';
import FormGridItem from '../../components/FormGridItem';

const styles = {
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  registerCard: {
    maxWidth: '700px',
    width: '100%',
    textAlign: 'center',
  },
  loginCard: {
    maxWidth: '500px',
    width: '100%',
    textAlign: 'center',
  },
  resetLink: {
    color: '#FFF',
    textDecoration: 'none',
  },
};

class AccountantRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      register: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        firmName: '',
      },
      submitting: false,
    };
  }

  registerSubmit = async (event) => {
    event.preventDefault();
    const { submitting, register } = this.state;
    const { setContextState, setPerson } = this.context;
    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    create(
      this,
      'register-accountant',
      {
        ...register,
        email: register.email.trim().toLowerCase(),
      },
      true,
    ).then(() => {
      client
        .authenticate({
          strategy: 'local',
          email: register.email.trim().toLowerCase(),
          password: register.password,
        })
        .then((response) => {
          localStorage.setItem('accountantLogin', 1);
          setContextState({
            sessionSource: 'accountantRegister',
            accountantLogin: true,
            showAccountantWelcome: true,
          });
          setPerson(response);
        });
    })
      .catch((feathersError) => {
        this.setState({ error: feathersError });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes } = this.props;
    const {
      submitting, error,
      register,
    } = this.state;

    return (
      <Grid container justify="center" alignContent="center" className={classes.root}>
        <Grid item xs={12} className={classes.centerContent}>
          <Card className={classes.registerCard}>
            <CardContent>
              <Typography variant="h5" align="left" gutterBottom>Create your accounting firm</Typography>
              <form onSubmit={this.registerSubmit}>
                <FormGridContainer>
                  <FormGridItem xs={12} sm={6}>
                    <TextField
                      label="Your First Name"
                      id="firstName"
                      fullWidth
                      required
                      margin="normal"
                      InputProps={{
                        value: register.firstName,
                        name: 'nested_register_firstName',
                        onChange: handleTextFieldChange(this),
                        autoComplete: 'given-name',
                      }}
                    />
                  </FormGridItem>
                  <FormGridItem xs={12} sm={6}>
                    <TextField
                      label="Your Last Name"
                      id="lastName"
                      fullWidth
                      required
                      margin="normal"
                      InputProps={{
                        value: register.lastName,
                        name: 'nested_register_lastName',
                        onChange: handleTextFieldChange(this),
                        autoComplete: 'family-name',
                      }}
                    />
                  </FormGridItem>
                </FormGridContainer>
                <TextField
                  label="Email"
                  id="email"
                  fullWidth
                  required
                  margin="normal"
                  InputProps={{
                    value: register.email,
                    name: 'nested_register_email',
                    onChange: handleTextFieldChange(this),
                    type: 'email',
                    autoComplete: 'email',
                  }}
                />
                <TextField
                  label="Create a Password"
                  id="password"
                  fullWidth
                  required
                  margin="normal"
                  InputProps={{
                    value: register.password,
                    name: 'nested_register_password',
                    onChange: handleTextFieldChange(this),
                    type: 'password',
                    autoComplete: 'new-password',
                  }}
                />
                <TextField
                  label="Phone"
                  id="phone"
                  fullWidth
                  required
                  margin="normal"
                  InputProps={{
                    value: register.phone,
                    name: 'nested_register_phone',
                    onChange: handleTextFieldChange(this),
                    type: 'tel',
                    autoComplete: 'tel-national',
                  }}
                />
                <TextField
                  label="Company Name"
                  id="firmName"
                  fullWidth
                  required
                  margin="normal"
                  InputProps={{
                    value: register.firmName,
                    name: 'nested_register_firmName',
                    onChange: handleTextFieldChange(this),
                    autoComplete: 'organization',
                  }}
                />
                <Box marginTop={2} maxWidth="400px" marginX="auto">
                  <Typography variant="body2" align="center" component="div" gutterBottom>
                    {'By clicking Register you agree to our '}
                    <Link href="https://www.reihub.net/terms" color="secondary" target="_blank">terms of service</Link>
                    {' and '}
                    <Link href="https://www.reihub.net/privacy" color="secondary" target="_blank">privacy policy</Link>
                    .
                  </Typography>
                  <Button
                    type="submit"
                    disabled={submitting}
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    size="large"
                  >
                    Register
                  </Button>
                </Box>
              </form>
              <Typography color="error">{error && error.message}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.centerContent}>
          <Box marginY={2}>
            <RouterLink
              to="/accountant/sign-in"
              className={classes.resetLink}
            >
              <Button color="primary" size="small">
                Sign In Instead
              </Button>
            </RouterLink>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

AccountantRegister.contextType = PersonContext;

AccountantRegister.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(AccountantRegister);
