import React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  multiRoot: {
    width: '100%',
    margin: 0,
  },
  root: {
    width: '100%',
    margin: 0,
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
});

class PageGrid extends React.PureComponent {
  render() {
    const { classes, children, isMultiCard } = this.props;

    return (
      <Grid container className={isMultiCard ? classes.multiRoot : classes.root} spacing={isMultiCard ? 3 : null}>
        {children}
      </Grid>
    );
  }
}

PageGrid.defaultProps = {
  children: null,
  isMultiCard: false,
};

PageGrid.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node,
  isMultiCard: PropTypes.bool,
};

export default withStyles(styles, { theme: true })(PageGrid);
