import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Cookies from 'js-cookie';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Onboarding from '~/layouts/Onboarding/Container';

import client from '../feathers';
import { create, find, get } from '../feathersWrapper';
import { asyncSetState } from '../functions/InputHandlers';
import history from '../history';
import Accountant from '../layouts/Accountant';
import Admin from '../layouts/Admin';
import Dashboard from '../layouts/Dashboard';
import Authenticate from '../routes/AuthenticateRoutes';

import { PersonContext } from './PersonContext';

const STATE_IS_TOUR_VISIBLE_KEY = 'isTourVisible';
const STATE_IS_SCHEDULE_CALL_DIALOG_VISIBLE_KEY = 'isScheduleCallDialogVisible';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey['100'],
    minHeight: '100vh',
    minWidth: '100%',
  },
});

class PersonProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const isTourVisible = localStorage.getItem(STATE_IS_TOUR_VISIBLE_KEY);
    if (isTourVisible === null) {
      localStorage.setItem(STATE_IS_TOUR_VISIBLE_KEY, false);
    }

    const isScheduleCallDialogVisible = localStorage.getItem(STATE_IS_SCHEDULE_CALL_DIALOG_VISIBLE_KEY);
    if (isScheduleCallDialogVisible === null) {
      localStorage.setItem(STATE_IS_SCHEDULE_CALL_DIALOG_VISIBLE_KEY, false);
    }

    return {
      id: null,
      userId: null,
      organizationId: null,
      loading: true,
      adminLogin: false,
      accountantLogin: false,
      accountingFirmId: null,
      showWelcome: false,
      promptPayment: false,
      dismissPayment: true,
      promptUpdatePaymentMethod: false,
      unpaidInvoiceData: null,
      autoSelectOrganization: true,
      sessionSource: null,
      demo: false,
      isTourVisible: localStorage.getItem(STATE_IS_TOUR_VISIBLE_KEY) === 'true',
      isScheduleCallDialogVisible: localStorage.getItem(STATE_IS_SCHEDULE_CALL_DIALOG_VISIBLE_KEY) === 'true',
    };
  };

  async componentDidMount() {
    this.checkCache();

    const { location } = this.props;

    if (location.pathname === '/invite') {
      await this.logOut();
      return;
    }

    const queryParams = queryString.parse(location.search);

    // Try to authenticate with any JWT stored in localStorage
    client
      .reAuthenticate()
      .then(async (response) => {
        // defaults the subscription dialog
        if (queryParams.subscribe) {
          localStorage.setItem('subscribe', queryParams.subscribe);
          delete queryParams.subscribe;
          history.replace({
            search: queryString.stringify(queryParams),
          });
        }
        await this.checkLocalStorage();
        this.setPerson(response).then(() => {
          if (queryParams.page && window.location.pathname !== '/sso') {
            localStorage.setItem('page', queryParams.page);
            delete queryParams.page;
            history.replace({
              search: queryString.stringify(queryParams),
            });
          }
          this.setState({ sessionSource: 'token', loading: false });
        });
      })
      .catch(() => {
        if (queryParams.page) {
          localStorage.setItem('page', queryParams.page);
          delete queryParams.page;
          history.replace({
            search: queryString.stringify(queryParams),
          });
        }
        // defaults the subscription dialog
        if (queryParams.subscribe) {
          localStorage.setItem('subscribe', queryParams.subscribe);
          delete queryParams.subscribe;
          history.replace({
            search: queryString.stringify(queryParams),
          });
        }
        this.setState({ loading: false });
      });
  }

  checkLocalStorage = async () => {
    // for admin
    if (parseInt(localStorage.getItem('adminLogin'), 10) === 1) {
      this.setAdmin();
    }

    // for accountant
    if (parseInt(localStorage.getItem('accountantLogin'), 10) === 1) {
      this.setState({ accountantLogin: true });
    }
    if (localStorage.getItem('accountingFirmId')) {
      this.setState({ accountingFirmId: parseInt(localStorage.getItem('accountingFirmId'), 10) });
    }

    // for dashboard
    if (localStorage.getItem('organizationId')) {
      await this.setOrganizationId(parseInt(localStorage.getItem('organizationId'), 10));
    }

    if (localStorage.getItem('adminUserSelect')) {
      this.setAdminUserSelect(JSON.parse(localStorage.getItem('adminUserSelect')));
    }
  };

  checkCache = async () => {
    if (window.location.hostname === 'localhost') {
      return;
    }
    await fetch(`/meta.json?time=${Date.now()}`)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        if (latestVersion !== currentVersion) {
          window.location.reload(true);
        }
      });
  };

  setPerson = async (authenticateResponse) => {
    const { location } = this.props;
    const { adminLogin } = this.state;
    const parsed = queryString.parse(location.search);

    const { personId, email, systemPassword } = authenticateResponse.user;
    window.pagesense = window.pagesense || [];
    window.pagesense.push(['identifyUser', email]);
    const person = await get(this, 'people', personId);
    asyncSetState(
      {
        ...person,
        systemPassword,
        userId: authenticateResponse.user.id,
      },
      this,
    );

    // fire identify in atatus
    if (window.atatus) {
      window.atatus.setUser(
        authenticateResponse.user.id,
        authenticateResponse.user.email,
        `${authenticateResponse.user.firstName} ${authenticateResponse.user.lastName}`,
      );
    }

    // fire identify in segment
    if (window.analytics && !adminLogin) {
      const traits = {
        createdAt: new Date(authenticateResponse.user.createdAt).toDateString(),
        partnerName: authenticateResponse.user.partnerName,
      };
      if (!authenticateResponse.user.partnerName || authenticateResponse.user.partnerName === 'TurboTenant') {
        // only fill PII for direct users or TurboTenant partner users
        traits.firstName = authenticateResponse.user.firstName;
        traits.lastName = authenticateResponse.user.lastName;
        traits.email = email;
        traits.phone = authenticateResponse.user.phone;
        traits.partnerUserId = authenticateResponse.user.partnerUserId;
      }

      window.analytics.ready(() => {
        window.analytics.identify(
          authenticateResponse.user.id,
          { ...traits },
          {} /* options */,
          () => {} /* callback */,
        );
      });
    }

    if (parsed.organizationId) {
      // localStorage.setItem('organizationId', parsed.organizationId);
      this.setOrganizationId(parseInt(parsed.organizationId, 10));
    }
    const peopleService = client.service('people');
    peopleService.on('patched', (update) => {
      this.setState({ ...update });
    });

    window.addEventListener('focus', this.checkLogin);

    return true;
  };

  setAdminUserSelect = async (value) => {
    asyncSetState(
      {
        adminUserSelect: value,
        autoSelectOrganization: false,
      },
      this,
    );
    localStorage.setItem('adminUserSelect', JSON.stringify(value));
  };

  checkLogin = async () => {
    const { demo } = this.state;
    await client.reAuthenticate().catch(async () => {
      if (demo) {
        return;
      }
      await this.logOut();
    });
  };

  setContextState = (data) => {
    this.setState(data);
  };

  setAdmin = () => {
    this.setState({ adminLogin: true });
  };

  logOut = async () => {
    await client.logout().then(() => {
      this.setState(this.getInitialState());
      localStorage.clear();
      this.setState({ loading: false });
      window.removeEventListener('focus', this.checkLogin);
      Cookies.remove('ajs_user_id');
    });
  };

  setDismissPayment = (dismissPayment) => {
    this.setState({ dismissPayment });
  };

  setIsTourVisible = (isTourVisible) => {
    localStorage.setItem(STATE_IS_TOUR_VISIBLE_KEY, isTourVisible);
    this.setState({ isTourVisible });
  };

  setIsScheduleCallDialogVisible = (isScheduleCallDialogVisible) => {
    localStorage.setItem(STATE_IS_SCHEDULE_CALL_DIALOG_VISIBLE_KEY, isScheduleCallDialogVisible);
    this.setState({ isScheduleCallDialogVisible });
  };

  setDismissUpdateFailedPaymentMethod = (dismissUpdateFailedPaymentMethod) => {
    this.setState({ dismissUpdateFailedPaymentMethod });
  };

  viewDemoPortfolio = async () => {
    const demoId = parseInt(process.env.REACT_APP_DEMO_ORGANIZATION_ID, 10);
    const turboTenantDemoId = parseInt(process.env.REACT_APP_TURBOTENANT_DEMO_ORGANIZATION_ID, 10);
    const rentRediDemoId = parseInt(process.env.REACT_APP_RENTREDI_DEMO_ORGANIZATION_ID, 10);

    const { partnerName } = this.state;
    const { location } = this.props;
    if ((location.pathname === '/turbotenant' || partnerName === 'TurboTenant') && turboTenantDemoId) {
      await this.setOrganizationId(turboTenantDemoId);
    } else if ((location.pathname === '/rentredi' || partnerName === 'RentRedi') && rentRediDemoId) {
      await this.setOrganizationId(rentRediDemoId);
    } else {
      await this.setOrganizationId(demoId);
    }
    history.push('/reload?link=/dashboard');
  };

  setOrganizationId = async (organizationId) => {
    if (organizationId === null) {
      this.setState({ organizationId: null, demo: false });
      localStorage.removeItem('organizationId');
      localStorage.removeItem('adminUserSelect');
      return;
    }
    const { adminLogin } = this.state;
    let promptPayment = false;
    let dismissPayment = true;
    let promptUpdatePaymentMethod = false;
    const dismissUpdateFailedPaymentMethod = false;
    let unpaidInvoiceData = {
      total: null,
      dueDate: null,
    };
    const organization = await get(this, 'organizations', organizationId);

    if (!organization.stripeSubscription && !organization.partnerSubscribed && !organization.demo) {
      promptPayment = true;
      if (!adminLogin && moment().isAfter(organization.freeTrialExpires, 'day')) {
        dismissPayment = false;
      }

      if (localStorage.getItem('subscribe') && !organization.showWelcome) {
        dismissPayment = false;
        localStorage.removeItem('subscribe');
      }
    }

    if (organization.stripeSubscription && ['past_due', 'unpaid'].includes(organization.stripeSubscriptionStatus)) {
      promptUpdatePaymentMethod = true;
      unpaidInvoiceData = organization.unpaidInvoiceData;
    }

    let airbnbAccountActive = false;
    let airbnbAccountId = null;
    let vrboAccountActive = false;
    let vrboAccountId = null;
    const account = await find(this, 'accounts', {
      query: {
        organizationId,
        $limit: 2,
        default: ['airbnb', 'vrbo'],
      },
    });
    account.data.forEach((result) => {
      if (result.default === 'airbnb') {
        airbnbAccountId = result.id;
        if (!result.inactive) {
          airbnbAccountActive = true;
        }
      }
      if (result.default === 'vrbo') {
        vrboAccountId = result.id;
        if (!result.inactive) {
          vrboAccountActive = true;
        }
      }
    });

    localStorage.setItem('organizationId', organizationId);
    await asyncSetState(
      {
        organizationId,
        multiEntity: organization.multiEntity === true,
        vendorTracking: organization.vendorTracking === true,
        recurringTransactions: organization.recurringTransactions === true,
        basis: organization.basis,
        organizationName: organization.name,
        partnerSubscribed: organization.partnerSubscribed,
        partnerName: organization.partnerName,
        promptPayment,
        dismissPayment,
        promptUpdatePaymentMethod,
        dismissUpdateFailedPaymentMethod,
        unpaidInvoiceData,
        showWelcome: organization.showWelcome === true,
        airbnbAccountActive,
        airbnbAccountId,
        vrboAccountActive,
        vrboAccountId,
        freeTrialExpires: organization.freeTrialExpires,
        organizationCreated: organization.createdAt,
        bookkeepingStartDate: organization.bookkeepingStartDate,
        demo: organization.demo,
        subscriptionDate: organization.subscriptionDate,
        subscriptionPaymentMethod: organization.subscriptionPaymentMethod,
        subscriptionItem: organization.subscriptionItem,
        subscriptionMaxUnits: organization.subscriptionMaxUnits,
      },
      this,
    );

    // This fires a call which syncs yodlee accounts on login or selecting an organization
    // It does not await the return so will execute in the background
    if (!organization.demo) {
      create(this, 'yodlee-accounts', { organizationId }, true).catch((e) => {
        console.error(`Error while syncing yodlee accouts: ${e.message}`);
      });
      create(this, 'plaid-item-status', { organizationId }, true).catch((e) => {
        console.error(`Error while syncing plaid accouts: ${e.message}`);
      });
    }
  };

  getComponent = () => {
    const { userId, organizationId, adminLogin, accountingFirmId, demo, adminUserSelect, showWelcome } = this.state;
    if (userId !== null && organizationId !== null) {
      if (showWelcome) {
        return Onboarding;
      }
      return Dashboard;
    }
    if (demo) {
      return Dashboard;
    }
    if (userId !== null && accountingFirmId !== null) {
      return Accountant;
    }
    if (userId !== null && adminLogin && !adminUserSelect) {
      return Admin;
    }
    return Authenticate;
  };

  render() {
    const { loading } = this.state;
    const { classes, triggerSnackbar } = this.props;

    return (
      <PersonContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          ...this.state,
          setPerson: this.setPerson,
          setContextState: this.setContextState,
          checkLogin: this.checkLogin,
          logOut: this.logOut,
          setOrganizationId: this.setOrganizationId,
          setDismissPayment: this.setDismissPayment,
          setDismissUpdateFailedPaymentMethod: this.setDismissUpdateFailedPaymentMethod,
          setAdmin: this.setAdmin,
          checkCache: this.checkCache,
          viewDemoPortfolio: this.viewDemoPortfolio,
          setIsTourVisible: this.setIsTourVisible,
          setIsScheduleCallDialogVisible: this.setIsScheduleCallDialogVisible,
          setAdminUserSelect: this.setAdminUserSelect,
          triggerSnackbar,
        }}
      >
        <div className={classes.root}>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <Switch>
              <Route path="/" component={this.getComponent()} />
            </Switch>
          )}
        </div>
      </PersonContext.Provider>
    );
  }
}

PersonProvider.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  triggerSnackbar: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(PersonProvider));
