import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
  handleCheckboxChange,
} from '../functions/InputHandlers';

import DialogTemplate from './DialogTemplate';

const styles = {};

class FilterAccountsDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    const { filter } = props;

    this.state = { filter };
  }

  updateFilter = async (event) => {
    event.preventDefault();
    const { filter } = this.state;
    const { updateFilter, closeDialog } = this.props;

    filter.page = 0;
    updateFilter(filter);

    closeDialog();
  };

  render() {
    const { closeDialog } = this.props;
    const {
      error, filter,
    } = this.state;

    return (
      <DialogTemplate
        open
        scroll="body"
        maxWidth="sm"
        fullWidth
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="editAccountDialog"
      >
        <form onSubmit={this.updateFilter}>
          <DialogTitle id="alert-dialog-title">Filter Accounts</DialogTitle>
          <DialogContent>
            <FormControl margin="none" fullWidth>
              <FormControlLabel
                control={<Checkbox checked={filter.showInactive} onChange={handleCheckboxChange(this)} name="nested_filter_showInactive" />}
                label="Show inactive accounts"
              />
            </FormControl>
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary" disableElevation>
              Update Filter
            </Button>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogTemplate>
    );
  }
}

FilterAccountsDialog.defaultProps = {
};

FilterAccountsDialog.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterAccountsDialog);
