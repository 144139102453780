import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';

class DialogTemplate extends React.PureComponent {
  onClose = (event, reason) => {
    const { onClose } = this.props;
    // disable close on backdrop click by default
    if (reason === 'backdropClick') {
      return null;
    }
    onClose(event, reason);
    return null;
  };

  render() {
    const { onClose, children, ...otherProps } = this.props;
    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <Dialog
        {...otherProps}
        onClose={this.onClose}
      >
        {children}
      </Dialog>
    );
  }
}

DialogTemplate.defaultProps = {
  onClose: () => {},
};

DialogTemplate.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default DialogTemplate;
