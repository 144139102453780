import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { PersonContext } from '../contexts/PersonContext';

import UpdateSubscriptionPaymentDialog from '../components/UpdateSubscriptionPaymentDialog';
import UpdateSubscriptionDialog from '../components/UpdateSubscriptionDialog';
import CancelSubscriptionDialog from '../components/CancelSubscriptionDialog';

import CardBase from '../components/CardBase';
import PageHeader from '../components/PageHeader';
import PageGrid from '../components/PageGrid';

class Subscription extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    this.setState({
      loading: false,
      updatePayment: false,
    });
  };

  formatPrice = (subscriptionItem) => (
    <NumberFormat
      displayType="text"
      value={subscriptionItem.price / 100}
      thousandSeparator
      prefix="$"
      suffix={` / ${subscriptionItem.interval_count > 1 ? `${subscriptionItem.interval_count} ${subscriptionItem.interval}s` : subscriptionItem.interval}`}
      decimalScale={2}
      fixedDecimalScale
    />
  );

  capitalize = (s) => s[0].toUpperCase() + s.slice(1);

  getSubscriptionContent = () => {
    const {
      demo, partnerSubscribed, partnerName,
      promptPayment, setDismissPayment, freeTrialExpires,
      subscriptionPaymentMethod, subscriptionItem,
    } = this.context;

    if (demo) {
      return (
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Demo Portfolio
              </Typography>
              <Typography variant="body1">
                {`This is a demo portfolio. 
                  Use this page to subscribe, unsubscribe, or update the subscription for any portfolios that you manage in REI Hub.`}
              </Typography>
            </CardContent>
          </CardBase>
        </Grid>
      );
    }

    if (partnerSubscribed) {
      return (
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Partner Subscription
              </Typography>
              <Typography variant="body1">
                {`Your subscription to REI Hub is managed through our partner ${partnerName}. 
                Please visit their site or contact ${partnerName} customer service for assistance 
                with your subscription or billing.`}
              </Typography>
            </CardContent>
          </CardBase>
        </Grid>
      );
    }

    if (promptPayment) {
      return (
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {`Trial Account: ${moment().diff(moment(freeTrialExpires).endOf('day')) < 0 ? `${moment(freeTrialExpires).diff(moment(), 'days') + 1} days remaining` : 'Expired'}`}
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                disableElevation
                onClick={() => { setDismissPayment(false); }}
              >
                Subscribe Now
              </Button>
            </CardContent>
          </CardBase>
        </Grid>
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {`Payment Method: ${subscriptionPaymentMethod.type}`}
              </Typography>
              {subscriptionPaymentMethod.type === 'Card' && (
                <Box ml={3} mb={3}>
                  <Typography variant="body1">
                    {`Brand: ${this.capitalize(subscriptionPaymentMethod.brand)}`}
                    <br />
                    {`Number: ****${subscriptionPaymentMethod.last4}`}
                    <br />
                    {`Expires: ${subscriptionPaymentMethod.exp_month}/${subscriptionPaymentMethod.exp_year}`}
                  </Typography>
                </Box>
              )}
              {subscriptionPaymentMethod.type === 'Bank Account' && (
                <Box ml={3} mb={3}>
                  <Typography variant="body1">
                    {`Bank Name: ${this.capitalize(subscriptionPaymentMethod.bank_name)}`}
                    <br />
                    {`Account Number: ****${subscriptionPaymentMethod.last4}`}
                  </Typography>
                </Box>
              )}
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                disableElevation
                onClick={() => this.setState({ updatePayment: true })}
              >
                Update Payment Method
              </Button>
            </CardContent>
          </CardBase>
        </Grid>
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {`Subscription: ${subscriptionItem.name}`}
              </Typography>
              <Box ml={3} mb={3}>
                <Typography variant="body1">
                  {'Price: '}
                  <NumberFormat
                    displayType="text"
                    value={subscriptionItem.price / 100}
                    thousandSeparator
                    prefix="$"
                    suffix={` / ${subscriptionItem.interval_count > 1 ? `${subscriptionItem.interval_count} ${subscriptionItem.interval}s` : subscriptionItem.interval}`}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  disableElevation
                  onClick={() => this.setState({ updateSubscription: true })}
                >
                  Update Subscription Plan
                </Button>
                <Box marginLeft={2} component="span" color="error.main">
                  <Button
                    variant="text"
                    color="inherit"
                    size="small"
                    disableElevation
                    onClick={() => this.setState({ cancelSubscription: true })}
                  >
                    Cancel Online
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </CardBase>
        </Grid>
      </>
    );
  };

  render() {
    const { match } = this.props;
    const {
      loading, updatePayment, updateSubscription, cancelSubscription,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        {updatePayment && (
          <UpdateSubscriptionPaymentDialog
            isOpen
            closeDialog={() => this.setState({ updatePayment: false })}
          />
        )}
        {updateSubscription && (
          <UpdateSubscriptionDialog
            isOpen
            closeDialog={() => this.setState({ updateSubscription: false })}
          />
        )}
        {cancelSubscription && (
          <CancelSubscriptionDialog
            isOpen
            closeDialog={() => this.setState({ cancelSubscription: false })}
          />
        )}
        <PageHeader
          match={match}
          title="Manage Subscription"
        />
        {this.getSubscriptionContent()}
      </PageGrid>
    );
  }
}

Subscription.contextType = PersonContext;

Subscription.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Subscription;
