import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
};

class CardBase extends React.PureComponent {
  render() {
    const {
      classes, children, className,
    } = this.props;
    const cardClasses = classNames({
      [classes.root]: true,
      [className]: className !== undefined,
    });

    return (
      <Card elevation={1} className={cardClasses}>
        {children}
      </Card>
    );
  }
}

CardBase.defaultProps = {
  className: undefined,
};

CardBase.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(CardBase);
