import React, { useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';

import { PersonContext } from '../../contexts/PersonContext';
import { create } from '../../feathersWrapper';
import { setJournalScope } from '../../functions/JournalFunctions';
import AddVendorDialog from '../AddVendorDialog';
import { getAccountOptions, getPaymentAccountOptions, getVendorOptions, nameLabel } from '../Autocomplete/Library';
import Button from '../Button';
import {
  getCreditJournalLine,
  getDebitJournalLine,
  getManualBasicJournal,
} from '../SearchSelect/TransactionTypeOptions';
import TransactionScope from '../TransactionScope';

import AddRecurringTransactionFrequencyDialogContent from './AddRecurringTransactionFrequencyDialogContent';

const useStyles = makeStyles((theme) => ({
  dialogContentContainer: {
    [theme.breakpoints.down('md')]: {
      width: '425px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
  },
  buttonContainer: {
    maxWidth: '400px',
    margin: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}));

export default function AddRecurringExpenseDialogContent({ isOpen, closeDialog, onAddRecurringTransaction }) {
  const classes = useStyles();
  const context = useContext(PersonContext);
  const { organizationId, vendorTracking } = context;
  const component = {
    context: {
      organizationId,
    },
  };

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [addVendorDialog, setAddVendorDialog] = useState(false);
  const [key, setKey] = useState('expenseTemplate');
  const [recurringTransactionName, setRecurringTransactionName] = useState('');
  const [frequency, setFrequency] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [expenseAccountOptions, setExpenseAccountOptions] = useState([]);
  const [paymentAccountOptions, setPaymentAccountOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [newVendor, setNewVendor] = useState(null);
  const [journal, setJournal] = useState(getManualBasicJournal());

  useEffect(() => {
    const getData = async () => {
      const expenseAccounts = await getAccountOptions(component, {
        $or: [{ type: 'Expense' }, { type2: 'Sales and Use Tax' }],
      });
      const paymentAccounts = await getPaymentAccountOptions(component);
      const vendors = await getVendorOptions(component, true);

      setExpenseAccountOptions(expenseAccounts);
      setPaymentAccountOptions(paymentAccounts);
      setVendorOptions(vendors);

      setJournal({
        ...journal,
        journalScopeSelect: journal.journalScopeSelect || { name: 'Property/Unit', id: 'Property/Unit' },
      });
      setLoading(false);
    };
    getData();
  }, []);

  useEffect(() => {
    if (newVendor) {
      const getData = async () => {
        const vendors = await getVendorOptions(component, true);
        setVendorOptions(vendors);
        const newJournal = { ...journal };
        newJournal.vendorIdSelect = vendors.find((vendor) => vendor.id === newVendor.id);
        setJournal(newJournal);
      };
      getData();
    }
  }, [newVendor]);

  const addRecurringExpense = async (event) => {
    event.preventDefault();

    if (submitting) {
      return;
    }

    if (endDate && endDate < startDate) {
      setError({ message: 'The end date cannot occur before the start date' });
      return;
    }

    if (!moment(startDate).isValid()) {
      setError({ message: 'Please enter a valid start date' });
      return;
    }

    if (endDate && !moment(endDate).isValid()) {
      setError({ message: 'Please enter a valid end date' });
      return;
    }

    const journalSubmit = { ...journal };

    setJournalScope(journalSubmit);

    journalSubmit.organizationId = organizationId;
    journalSubmit.type = 'Expense';

    journalSubmit.debitLines[0].debit = journalSubmit.amount;
    journalSubmit.debitLines[0].accountId = journalSubmit.debitLines[0].accountIdSelect.id;

    journalSubmit.creditLines[0].credit = journalSubmit.amount;
    journalSubmit.creditLines[0].accountId = journalSubmit.creditLines[0].accountIdSelect.id;
    journalSubmit.journalLines = journalSubmit.debitLines.concat(journalSubmit.creditLines);

    journalSubmit.vendorId = journalSubmit.vendorIdSelect ? journalSubmit.vendorIdSelect.id : null;
    if (journalSubmit.amount < 0) {
      // book as a refund
      journalSubmit.type = 'Refund';
      const newJournalLines = [];
      journalSubmit.journalLines.forEach((line) => {
        if (line.type === 'credit') {
          const newLine = getDebitJournalLine();
          newLine.debit = -line.credit;
          newLine.accountId = line.accountId;
          newJournalLines.push(newLine);
        } else {
          const newLine = getCreditJournalLine();
          newLine.credit = -line.debit;
          newLine.accountId = line.accountId;
          newJournalLines.push(newLine);
        }
      });
      journalSubmit.journalLines = newJournalLines;
    }

    const dayOfMonth = Number(moment(startDate).format('D')) <= 28 ? Number(moment(startDate).format('D')) : 32;

    const recurringTransaction = {
      organizationId,
      journal: journalSubmit,
      name: recurringTransactionName,
      entityId: journalSubmit.entityId,
      propertyId: journalSubmit.propertyId,
      unitId: journalSubmit.unitId,
      type: journalSubmit.type,
      frequency: frequency.id,
      startDate,
      endDate,
      dayOfMonth,
      inactive: false,
    };

    setSubmitting(true);
    try {
      await create(this, 'recurring-transactions', recurringTransaction, true);
    } catch (err) {
      setSubmitting(false);
      setError(err);
    }

    setSubmitting(false);
    onAddRecurringTransaction();
  };

  const getDialogContent = () => {
    switch (key) {
      case 'expenseTemplate':
        return (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              setKey('frequency');
            }}
          >
            <DialogTitle id="alert-dialog-title">Add Recurring Expense (1 of 2)</DialogTitle>
            <DialogContent>
              <TextField
                label="Recurring Transaction Name"
                fullWidth
                margin="dense"
                required
                InputProps={{
                  value: recurringTransactionName,
                  name: 'recurringTransactionName',
                  onChange: (event) => {
                    setRecurringTransactionName(event.target.value);
                  },
                }}
              />
              <FormControl margin="dense" fullWidth>
                <InputLabel required>Amount</InputLabel>
                <NumberFormat
                  value={journal.amount}
                  required
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={(values) => {
                    const newJournal = { ...journal };
                    newJournal.amount = values.floatValue;
                    setJournal(newJournal);
                  }}
                  customInput={Input}
                />
                {journal.amount < 0 && (
                  <FormHelperText>
                    <Typography color="error" variant="inherit">
                      {`Entering a negative number will create the transaction as a refund received.
                    Use a positive number if you would like to enter an expense.`}
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>
              <TransactionScope
                journal={journal}
                transactionScopeChange={(newScopeValues) => {
                  const newJournal = { ...journal, ...newScopeValues };
                  setJournal(newJournal);
                }}
              />
              <Autocomplete
                options={expenseAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.debitLines[0].accountIdSelect}
                onChange={(_event, value) => {
                  const newJournal = { ...journal };
                  newJournal.debitLines[0].accountId = value.id;
                  newJournal.debitLines[0].accountIdSelect = value;
                  setJournal(newJournal);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Expense Account"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <Autocomplete
                options={paymentAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.creditLines[0].accountIdSelect}
                onChange={(_event, value) => {
                  const newJournal = { ...journal };
                  newJournal.creditLines[0].accountId = value.id;
                  newJournal.creditLines[0].accountIdSelect = value;
                  setJournal(newJournal);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="Payment Account"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              {journal.creditLines[0].accountIdSelect &&
                (journal.creditLines[0].accountIdSelect.plaidAccountId ||
                  journal.creditLines[0].accountIdSelect.yodleeAccountId) && (
                  <FormHelperText>
                    <Typography variant="inherit">
                      You've selected a linked account. Consider using a transaction matching rule to book from the
                      import feed instead of adding a recurring manual expense here.
                    </Typography>
                  </FormHelperText>
                )}
              {vendorTracking && (
                <Autocomplete
                  options={vendorOptions}
                  getOptionLabel={nameLabel}
                  value={journal.vendorIdSelect}
                  onChange={(_event, value) => {
                    const newJournal = { ...journal };
                    if (value && value.id === 'New Instance') {
                      setAddVendorDialog(true);
                      return;
                    }
                    newJournal.vendorIdSelect = value;
                    setJournal(newJournal);
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Vendor (optional)"
                      placeholder="Type to Search"
                      fullWidth
                    />
                  )}
                />
              )}
              <TextField
                label="Description (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: journal.description,
                  name: 'nested_journal_description',
                  onChange: (event) => {
                    const newJournal = { ...journal };
                    newJournal.description = event.target.value;
                    setJournal(newJournal);
                  },
                }}
              />
              <Box className={classes.buttonContainer}>
                <Button disabled={submitting} hasFullWidth type="submit" variant="contained">
                  Continue
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'frequency':
        return (
          <AddRecurringTransactionFrequencyDialogContent
            onAddRecurringTransaction={addRecurringExpense}
            frequency={frequency}
            setFrequency={setFrequency}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            error={error}
            setError={setError}
            closeDialog={closeDialog}
            type="Expense"
            onNavigateBack={() => {
              setKey('expenseTemplate');
            }}
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return null;
  }

  return (
    <>
      {addVendorDialog && (
        <AddVendorDialog
          isOpen
          closeDialog={() => setAddVendorDialog(false)}
          onAddVendor={(newValue) => setNewVendor(newValue)}
        />
      )}
      <Box className={classes.dialogContentContainer}>{isOpen && getDialogContent()}</Box>
    </>
  );
}

AddRecurringExpenseDialogContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddRecurringTransaction: PropTypes.func.isRequired,
};
