import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import NumberFormat from 'react-number-format';

import history from '../history';
import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
} from '../functions/InputHandlers';

import AddImportedTransactionRuleDialog from '../components/AddImportedTransactionRuleDialog';
import EditImportedTransactionRuleDialog from '../components/EditImportedTransactionRuleDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';

const styles = (theme) => ({
  textButton: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
});

class ImportedTransactionRules extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addDialog: false,
      editDialog: false,
      editRuleId: null,
      // filterDialog: false,
      pageSize: 20,
      filter: {
        page: 0,
      },
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.addRule) {
      this.setState({ addDialog: true });
    }

    await this.getRules();
  }

  getRules = async () => {
    const { organizationId } = this.context;
    const { filter, pageSize } = this.state;

    const rulesQuery = {
      organizationId,
      $limit: pageSize,
      $skip: filter.page * pageSize,
      $sort: {
        descriptionContains: 1,
        id: 1,
      },
    };

    const rules = await find(this, 'imported-transaction-rules', {
      query: rulesQuery,
    });

    this.setState({
      rules,
      loading: false,
    });
  };

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getRules();
  };

  closeDialogs = () => {
    const { location } = this.props;
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.addRule) {
      history.replace(window.location.pathname);
    }
    this.setState({ addDialog: false, editDialog: false, editRuleId: null });
  }

  actionButtons = () => (
    [
      { text: 'Add Rule', action: (() => this.setState({ addDialog: true })), class: 'add' },
    ]
  );

  render() {
    const { classes, match } = this.props;
    const {
      rules, addDialog, editDialog, editRuleId,
      filter, pageSize, loading,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Imported Transaction Rules"
          actionButtons={this.actionButtons()}
        />
        <AddImportedTransactionRuleDialog
          isOpen={addDialog}
          closeDialog={this.closeDialogs}
          onAdd={this.getRules}
        />
        <EditImportedTransactionRuleDialog
          isOpen={editDialog}
          closeDialog={this.closeDialogs}
          onEdit={this.getRules}
          onDelete={this.getRules}
          ruleId={editRuleId}
        />
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Amount
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Type
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rules.data.map((rule) => (
                    <TableRow key={rule.id}>
                      <TableCell>
                        <ButtonBase
                          className={classes.textButton}
                          onClick={() => {
                            this.setState({ editRuleId: rule.id, editDialog: true });
                          }}
                        >
                          <Typography variant="body2" component="div">
                            {rule.descriptionContains ? rule.descriptionContains : '---'}
                          </Typography>
                        </ButtonBase>
                      </TableCell>
                      <TableCell>
                        {rule.transactionAmount !== null ? (
                          <NumberFormat
                            displayType="text"
                            value={rule.transactionAmount}
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        ) : '---'}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" component="div">
                          {rule.type}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={rules.total}
                      page={filter.page}
                      rowsPerPage={pageSize}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[pageSize]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

ImportedTransactionRules.contextType = PersonContext;

ImportedTransactionRules.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ImportedTransactionRules);
