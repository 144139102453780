import React from 'react';
import NumberFormat from 'react-number-format';
import { Box, Button, ButtonGroup, Grid, Typography, withStyles } from '@material-ui/core';
import { AccountBalance, CreditCard, PriorityHigh } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import PropTypes from 'prop-types';

import { PALETTE } from '~/theme/constants';

import CardBase from '../../components/CardBase';
import EditBankingAccountDialog from '../../components/EditBankingAccountDialog';
import { PersonContext } from '../../contexts/PersonContext';
import history from '../../history';

const styles = (theme) => ({
  circle: {
    width: '65px',
    height: '65px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    right: '20px',
  },
  errorCircle: {
    width: '65px',
    height: '65px',
    backgroundColor: theme.palette.error.main,
    color: 'white',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    right: '20px',
  },
  refreshConnectionButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    float: 'right',
    marginTop: '5px',
  },
  yodleeRefreshConnectionButton: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    float: 'right',
    marginTop: '5px',
  },
});

class BankAccountDisplayCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { editAccount: false };
  }

  /**
   * Returns the matching icon for each card type. Bank icon for bank accounts, credit card icon
   * for credit card accounts, and error icon for accounts in error state
   * @returns Icon for the account card
   */
  getCardIcon = () => {
    const { account, classes } = this.props;
    const accountType = account.type2;

    const bankIcon = (
      <div className={classes.circle}>
        <AccountBalance fontSize="large" style={{ color: 'white' }} aria-label="bank" />
      </div>
    );

    const creditIcon = (
      <div className={classes.circle}>
        <CreditCard fontSize="large" style={{ color: 'white' }} aria-label="credit" />
      </div>
    );

    const errorIcon = (
      <div className={classes.errorCircle}>
        <PriorityHigh fontSize="large" style={{ color: 'white' }} aria-label="error" />
      </div>
    );

    if (account.plaidAccountId || account.yodleeAccountId) {
      if (account.plaidAccountId && account.plaid_account.plaid_item.requiresUpdate) {
        return errorIcon;
      }
      if (account.plaidAccountId && account.plaid_account.deleted) {
        return errorIcon;
      }
      if (
        account.yodleeAccountId &&
        account.yodlee_account.accountData.dataset[0].updateEligibility === 'ALLOW_UPDATE_WITH_CREDENTIALS'
      ) {
        return errorIcon;
      }
    }

    if (accountType === 'Bank') {
      return bankIcon;
    }

    if (accountType === 'Credit Card') {
      return creditIcon;
    }

    return null;
  };

  /**
   * Checks to see if the account has an error state and returns the error section if it does.
   * Adds a refresh connection button for Yodlee accounts that show a last update date older than
   * 3 days.
   * @returns Error section for plaid and yodlee connections
   */
  getConnectionErrorSection = () => {
    const { account, classes } = this.props;
    const disconnectedSection = (
      <Box paddingLeft="20px">
        <Typography color="error">Plaid link disconnected</Typography>
      </Box>
    );

    const errorSection = (
      <Box paddingLeft="20px">
        <Typography color="error">This account could not be automatically refreshed</Typography>
        <Button
          variant="outlined"
          fullWidth
          className={classes.refreshConnectionButton}
          onClick={() => this.updateAccountConnection()}
        >
          Refresh Connection
        </Button>
      </Box>
    );

    const yodleeRefreshSection = (
      <Box paddingLeft="20px">
        <Button
          variant="outlined"
          fullWidth
          className={classes.yodleeRefreshConnectionButton}
          onClick={() => this.updateAccountConnection()}
        >
          Refresh Connection
        </Button>
      </Box>
    );

    if (account.plaidAccountId && account.plaid_account.deleted) {
      return disconnectedSection;
    }
    if (account.plaidAccountId && account.plaid_account.plaid_item.requiresUpdate) {
      return errorSection;
    }
    if (
      account.yodleeAccountId &&
      account.yodlee_account.accountData.dataset[0].updateEligibility === 'ALLOW_UPDATE_WITH_CREDENTIALS'
    ) {
      return errorSection;
    }
    if (account.yodleeAccountId && moment(account.lastCurrentBalanceDate) < moment().subtract(3, 'days')) {
      return yodleeRefreshSection;
    }

    return null;
  };

  /**
   * Stand in for account scope until we have ability to scope accounts to portfolio,
   * sub-portfolio, and property. Right now this is just looking to see if a property
   * is attached to the account and if so, returns the property address, else returns
   * the portfolio name.
   * @returns {string} Account scope
   */
  getAccountScope = () => {
    const { account } = this.props;
    const { organizationName } = this.context;

    if (account.property) {
      return (
        <Typography variant="body2" color="primary">
          {account.property.address1}
          {account.unit ? `, ${account.unit.name}` : ''}
        </Typography>
      );
    }

    if (account.entity) {
      return (
        <Typography variant="body2" color="primary">
          {account.entity.name}
        </Typography>
      );
    }
    return (
      <Typography variant="body2" color="primary">
        {`${organizationName} Portfolio`}
      </Typography>
    );
  };

  /**
   * @returns {string} Institution name and/or masked account number depending on which values are
   * present in the account object
   */
  getInstitutionNameAndMask = () => {
    const { account } = this.props;

    if (account.institutionName && account.accountNumberMask) {
      return `${account.institutionName} xxxx${account.accountNumberMask}`;
    }
    if (account.institutionName && !account.accountNumberMask) {
      return account.institutionName;
    }
    if (!account.institutionName && account.accountNumberMask) {
      return `xxxx${account.accountNumberMask}`;
    }
    return 'Institution Not Provided';
  };

  onEditAccount = () => {
    const { onEditAccount } = this.props;
    onEditAccount();
  };

  updateAccountConnection = () => {
    const { account } = this.props;

    if (account.plaidAccountId) {
      this.updatePlaidItem();
    } else if (account.yodleeAccountId) {
      this.updateYodleeAccount();
    }
  };

  updatePlaidItem = () => {
    const { onUpdatePlaidItem } = this.props;
    const { account } = this.props;

    onUpdatePlaidItem(account.plaid_account.plaid_item.plaidItemId);
  };

  updateYodleeAccount = () => {
    const { onUpdateYodleeAccount } = this.props;
    const { account } = this.props;

    onUpdateYodleeAccount(account.yodlee_account.providerAccountId);
  };

  render() {
    const { account, adminLogin } = this.props;
    const { editAccount } = this.state;
    return (
      <>
        {editAccount && (
          <EditBankingAccountDialog
            accountId={account.id}
            isOpen
            closeDialog={() => this.setState({ editAccount: false })}
            onEditAccount={() => this.onEditAccount()}
          />
        )}
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" padding="10px" pb={2}>
              {/* Account Information Section */}
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box justifyContent="space-between">
                  {/* Linked Account Name */}
                  <Typography variant="h6">
                    {account.name}
                    <EditIcon
                      fontSize="small"
                      color="primary"
                      aria-label="edit"
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                      onClick={() => this.setState({ editAccount: true })}
                    />
                  </Typography>
                  <Box paddingLeft="20px">
                    {/* Institution Name and/or Masked Account Number */}
                    <Typography variant="body2" color="primary">
                      {this.getInstitutionNameAndMask()}
                    </Typography>
                    {/* Account Scope */}
                    {this.getAccountScope()}
                  </Box>
                </Box>
                {/* Card Icon */}
                <Box paddingTop="8px" style={{ float: 'right' }}>
                  {this.getCardIcon()}
                </Box>
              </Box>
              <Box
                paddingTop="5px"
                paddingBottom="10px"
                paddingLeft="20px"
                borderBottom={`1px solid ${PALETTE.divider}`}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    history.push(
                      account.type2 === 'Bank'
                        ? `/accounts/banking/bank/${account.id}`
                        : `/accounts/banking/card/${account.id}`,
                    )
                  }
                >
                  View Account Register
                </Button>
              </Box>
              {/* Import Feed Section */}
              <Box borderBottom={`1px solid ${PALETTE.divider}`} paddingTop="10px" paddingBottom="10px">
                <Typography color="primary" style={{ paddingBottom: '5px' }}>
                  Import Feed
                </Typography>
                <Box paddingLeft="20px">
                  <ButtonGroup aria-label="outlined primary button group" fullWidth>
                    {/* Unkbooked Transactions Button */}
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        history.push(`/accounts/banking/import-feed?accountId=${account.id}&status=Unbooked`)
                      }
                    >
                      {`${account.unmatchedTransactionCount < 100 ? account.unmatchedTransactionCount : '99+'} Unbooked`}
                    </Button>
                    {/* View All Button */}
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => history.push(`/accounts/banking/import-feed?accountId=${account.id}`)}
                    >
                      View All
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
              {/* Account Status Section */}
              <Box paddingTop="10px">
                {/* Linked Account Text */}
                <Typography color="primary">Linked Account Balance</Typography>
                <Box paddingLeft="20px">
                  {/* If Plaid account */}
                  {account.plaidAccountId && (
                    <>
                      {/* Displays if Plaid connection was has been inititiated, but data has not
                    been received yet */}
                      {account.lastCurrentBalance === null && (
                        <Typography variant="h6">Awaiting Information</Typography>
                      )}
                      {account.lastCurrentBalance !== null && (
                        <>
                          {/* Current account balance */}
                          <Typography variant="h6">
                            <NumberFormat
                              displayType="text"
                              value={account.lastCurrentBalance}
                              thousandSeparator
                              prefix="$"
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </Typography>
                          {/* Displays account type last verified on based on account.type2 value */}
                          <Typography variant="body2" gutterBottom color="primary">
                            {`Last verified
                          ${moment(account.lastCurrentBalanceDate).format('M/DD/YY')}
                           via Plaid`}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                  {/* If Yodlee account */}
                  {account.yodleeAccountId && (
                    <>
                      <Typography variant="h6">
                        <NumberFormat
                          displayType="text"
                          value={account.lastCurrentBalance}
                          thousandSeparator
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </Typography>
                      {/* Displays account type last verified on based on account.type2 value */}
                      <Typography variant="body2" gutterBottom color="primary">
                        {`Last verified
                      ${moment(account.lastCurrentBalanceDate).format('M/DD/YY')}
                       via Yodlee`}
                      </Typography>
                    </>
                  )}
                  {/* If Manual account */}
                  {!account.plaidAccountId && !account.yodleeAccountId && (
                    <Typography variant="h6">Manual Account</Typography>
                  )}
                </Box>
              </Box>
              {/* Connection Error Section for linked Plaid and Yodlee Accounts */}
              {this.getConnectionErrorSection()}
              {/* Displays Plaid account/item ID if logged in user is admin user */}
              {account.plaidAccountId && adminLogin && (
                <Box marginTop="5px">
                  <Typography variant="caption">
                    <div>{`I: ${account.plaid_account.plaid_item.plaidItemId}`}</div>
                    <div>{`A: ${account.plaidAccountId}`}</div>
                  </Typography>
                </Box>
              )}
            </Box>
          </CardBase>
        </Grid>
      </>
    );
  }
}

BankAccountDisplayCard.contextType = PersonContext;

BankAccountDisplayCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  onEditAccount: PropTypes.func.isRequired,
  onUpdatePlaidItem: PropTypes.func.isRequired,
  onUpdateYodleeAccount: PropTypes.func.isRequired,
  adminLogin: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(BankAccountDisplayCard);
