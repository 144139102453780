import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';

import { PersonContext } from '../../contexts/PersonContext';

import { find } from '../../feathersWrapper';

import AddBookedTransactionDialog from '../../components/AddBookedTransactionDialog';
import AddNoteDialog from '../../components/AddNoteDialog';

import CardBase from '../../components/CardBase';

class QuicklinksCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { view } = this.props;
    const { organizationId } = this.context;
    let showAddProperty = false;
    let showAddBank = false;
    let showImportedTransactions = false;
    if (view === 'portfolio') {
      const propertiesPromise = find(this, 'properties', {
        query: { organizationId, $limit: 1 },
      });
      const bankingPromise = find(this, 'accounts', {
        query: { organizationId, type2: 'Bank', $limit: 1 },
      });
      const transactionsPromise = find(this, 'yodlee-transactions', {
        query: {
          organizationId,
          journalLineId: null,
          split: { $or: [false, null] },
          ignored: { $or: [false, null] },
          pending: false,
          deleted: false,
          $limit: 1,
        },
      });
      const properties = await propertiesPromise;
      const bankAccounts = await bankingPromise;
      const transactions = await transactionsPromise;
      if (properties.total === 0) {
        showAddProperty = true;
      }
      if (bankAccounts.total === 0) {
        showAddBank = true;
      }
      if (transactions.total > 0) {
        showImportedTransactions = true;
      }
    }
    this.setState({
      loading: false,
      showImportedTransactions,
      showAddProperty,
      showAddBank,
      addNoteDialog: false,
      addTransactionDialog: false,
      transactionType: null,
    });
  };

  setTransactionsStale = () => {
    const { markStale } = this.props;
    markStale('expenses');
    markStale('cashflow');
  };

  getBookedTransactionsLink = () => {
    const { view, id } = this.props;
    switch (view) {
      case 'property':
        return `/transactions/booked?propertyId=${id}`;
      case 'unit':
        return `/transactions/booked?unitId=${id}`;
      case 'subportfolio':
        return `/transactions/booked?entityId=${id}`;
      default:
        return '/transactions/booked';
    }
  };

  render() {
    const {
      loading,
      showAddProperty, showAddBank, showImportedTransactions,
      addTransactionDialog, transactionType, addNoteDialog,
    } = this.state;
    const {
      markStale, view, id,
    } = this.props;

    if (loading) {
      return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Quicklinks
              </Typography>
            </Box>
          </CardBase>
        </Grid>
      );
    }
    return (
      <>
        <AddBookedTransactionDialog
          propertyId={view === 'property' ? id : null}
          unitId={view === 'unit' ? id : null}
          type={transactionType}
          isOpen={addTransactionDialog}
          closeDialog={() => this.setState({
            addTransactionDialog: false,
            transactionType: null,
          })}
          onAddTransaction={this.setTransactionsStale}
        />
        <AddNoteDialog
          propertyId={view === 'property' ? id : null}
          unitId={view === 'unit' ? id : null}
          isOpen={addNoteDialog}
          closeDialog={() => this.setState({ addNoteDialog: false })}
          onAddNote={() => markStale('notes')}
        />
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Quicklinks
              </Typography>
              {showAddProperty && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    component={RouterLink}
                    to="/properties?add=1"
                  >
                    Add Property
                  </Button>
                </Box>
              )}
              {showAddBank && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    component={RouterLink}
                    to="/accounts/banking?add=1"
                  >
                    Add Bank Account
                  </Button>
                </Box>
              )}
              {showImportedTransactions && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    component={RouterLink}
                    to="/accounts/banking/import-feed?status=Unbooked"
                  >
                    Unbooked Transactions
                  </Button>
                </Box>
              )}
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  component={RouterLink}
                  to={this.getBookedTransactionsLink()}
                >
                  Booked Transactions
                </Button>
              </Box>
              {['property', 'unit'].includes(view) && (
                <>
                  <Box marginBottom={1}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="large"
                      fullWidth
                      onClick={() => this.setState({ addTransactionDialog: true, transactionType: 'Revenue' })}
                    >
                      Add Revenue
                    </Button>
                  </Box>
                  <Box marginBottom={1}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="large"
                      fullWidth
                      onClick={() => this.setState({ addTransactionDialog: true, transactionType: 'Expense' })}
                    >
                      Add Expense
                    </Button>
                  </Box>
                </>
              )}
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => this.setState({ addTransactionDialog: true })}
                >
                  {['property', 'unit'].includes(view) ? 'Add Any Transaction' : 'Add Transaction'}
                </Button>
              </Box>
              {view === 'entity' && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    component={RouterLink}
                    to={`/reports/net-income?${view}Id=${id}`}
                  >
                    Net Income Report
                  </Button>
                </Box>
              )}
              {view === 'portfolio' && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    component={RouterLink}
                    to="/reports/net-income-by-property"
                  >
                    Net Income By Property
                  </Button>
                </Box>
              )}
              {!['portfolio', 'entity'].includes(view) && (
                <>
                  <Box marginBottom={1}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="large"
                      fullWidth
                      component={RouterLink}
                      to={`/reports/net-income?${view}Id=${id}`}
                    >
                      Net Income Report
                    </Button>
                  </Box>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={() => this.setState({ addNoteDialog: true })}
                  >
                    Add Document or Note
                  </Button>
                </>
              )}
            </Box>
          </CardBase>
        </Grid>
      </>
    );
  }
}

QuicklinksCard.contextType = PersonContext;

QuicklinksCard.defaultProps = {
  id: null,
};

QuicklinksCard.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.string).isRequired,
  markStale: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  id: PropTypes.node,
  // theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default QuicklinksCard;
