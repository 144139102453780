import { createTheme } from '@material-ui/core/styles';

import theme from './theme';

export default createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiFormLabel: {
      asterisk: {
        display: 'none',
      },
    },
  },
});
