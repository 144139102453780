import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// import { Link as RouterLink } from 'react-router-dom';

import { PersonContext } from '../../contexts/PersonContext';

import { find } from '../../feathersWrapper';

import LinkBase from '../../components/LinkBase';
import ErrorCard from '../../components/ErrorCard';
import CardBase from '../../components/CardBase';

const styles = {};

class NotesCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    const { stale, removeStale } = this.props;
    if (stale) {
      this.getData();
      removeStale('notes');
    }
  }

  getData = async () => {
    const { view, id } = this.props;
    const { organizationId } = this.context;
    const query = {
      organizationId,
      $limit: 3,
      $sort: {
        date: -1,
      },
    };

    if (view === 'property') {
      query.propertyId = id;
    } else if (view === 'unit') {
      query.unitId = id;
    }
    const resultPromise = find(this, 'notes', { query });

    const result = await resultPromise;

    this.setState({
      notes: result.data,
      total: result.total,
      loading: false,
    });
  };

  render() {
    const {
      loading, error, notes, total,
    } = this.state;
    const { theme, view, id } = this.props;

    if (loading) {
      return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Documents & Notes
              </Typography>
            </Box>
          </CardBase>
        </Grid>
      );
    }
    if (error) {
      return <ErrorCard error={error.message} />;
    }
    return (
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <CardBase>
          <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
            <Typography variant="h6" gutterBottom>
              Documents & Notes
            </Typography>
            {notes.map((note) => (
              <Box borderBottom={`1px solid ${theme.palette.divider}`} paddingBottom={2} key={note.id}>
                <Typography variant="subtitle2">
                  {note.title}
                </Typography>
                <Box paddingLeft={2}>
                  <Typography variant="body2">
                    {`Date: ${moment(note.date).format('M/D/YYYY')}`}
                  </Typography>
                  {view === 'property' && note.unitId !== null && (
                    <Typography variant="body2">
                      {`Unit: ${note.unit.name}`}
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
              <LinkBase to={`/organization/notes?${view}Id=${id}`}>
                <Typography variant="body2">{`View All Documents & Notes (${total})`}</Typography>
              </LinkBase>
            </Box>
          </Box>
        </CardBase>
      </Grid>
    );
  }
}

NotesCard.contextType = PersonContext;

NotesCard.defaultProps = {
  stale: false,
  removeStale: null,
};

NotesCard.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.string).isRequired,
  stale: PropTypes.bool,
  removeStale: PropTypes.func,
  view: PropTypes.string.isRequired,
  id: PropTypes.node.isRequired,
  theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles, { withTheme: true })(NotesCard);
