import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { PersonContext } from '../contexts/PersonContext';
import { find } from '../feathersWrapper';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import AddVehicleDialog from '../components/AddVehicleDialog';
import EditVehicleDialog from '../components/EditVehicleDialog';

class Vehicles extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    await this.getVehicles();
  }

  getVehicles = async () => {
    const { organizationId } = this.context;

    const vehicles = await find(this, 'vehicles', {
      query: {
        organizationId,
        $sort: {
          name: 1,
          id: 1,
        },
      },
    });

    this.setState({
      vehicles: vehicles.data,
      loading: false,
    });
  };

  closeDialogs = () => {
    this.setState({
      addDialog: false,
      editDialog: false,
      vehicleToEdit: null,
    });
  };

  onDialogActions = async () => {
    await this.getVehicles();
    this.closeDialogs();
  };

  actionButtons = () => {
    const buttons = [
      { text: 'Add Vehicle', action: (() => this.setState({ addDialog: true })), class: 'add' },
    ];
    return buttons;
  }

  render() {
    const { match } = this.props;
    const {
      loading,
      vehicles,
      addDialog,
      editDialog,
      vehicleToEdit,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        {addDialog && (
          <AddVehicleDialog
            isOpen
            closeDialog={this.closeDialogs}
            onAdd={this.onDialogActions}
          />
        )}
        {editDialog && (
          <EditVehicleDialog
            isOpen
            closeDialog={this.closeDialogs}
            onEdit={this.onDialogActions}
            onDelete={this.onDialogActions}
            vehicleToEdit={vehicleToEdit}
          />
        )}
        <PageGrid>
          <PageHeader
            match={match}
            title="Vehicles"
            actionButtons={this.actionButtons()}
          />
          <Grid item xs={12} md={12}>
            <CardBase>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2">Vehicle</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="subtitle2">Action</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vehicles.map((vehicle) => (
                      <TableRow key={vehicle.id}>
                        <TableCell>
                          <Typography variant="body2">
                            {`${vehicle.name}${vehicle.inactive ? ' (inactive)' : ''}`}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.setState(
                              { editDialog: true, vehicleToEdit: vehicle },
                            )}
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </CardBase>
          </Grid>
        </PageGrid>
      </>
    );
  }
}

Vehicles.contextType = PersonContext;

Vehicles.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Vehicles;
