import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Box, ButtonBase } from '@material-ui/core';
import { get, find, create } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';
import {
  handleAutocompleteChange,
  handleTextFieldChange,
  asyncHandleChange,
  handleKeyboardDatePickerChange,
  handleUploaderChange,
  handleTransactionScopeChange,
} from '../../functions/InputHandlers';

import {
  sumProperty,
} from '../../functions/SumFunctions';

import {
  getManualBasicJournal,
} from '../SearchSelect/TransactionTypeOptions';

import {
  nameAndAddressLabel,
  getFixedAssetOptions,
} from '../Autocomplete/Library';
import AddFixedAssetDialog from '../AddFixedAssetDialog';
import TransactionScope from '../TransactionScope';
import { setJournalScope } from '../../functions/JournalFunctions';

const styles = (theme) => ({
  hideWidget: {
    display: 'none',
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddDepreciationDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { assetId } = this.props;
    const { organizationId } = this.context;
    const initialState = {
      loading: false,
      submitting: false,
      addFixedAssetDialog: false,
      error: null,
      displaytNotesSection: false,
    };

    const journal = getManualBasicJournal();
    journal.type = 'Depreciation';

    const depreciationExpensePromise = find(this, 'accounts', {
      query: {
        organizationId,
        default: 'depreciationExpense',
      },
    });
    const accumulatedDepreciationPromise = find(this, 'accounts', {
      query: {
        organizationId,
        default: 'accumulatedDepreciation',
      },
    });

    if (assetId) {
      const asset = await get(this, 'fixed-assets', assetId);
      journal.fixedAssetIdSelect = asset;
    }

    const depreciationExpenseAccountResult = await depreciationExpensePromise;
    const accumulatedDepreciationAccountResult = await accumulatedDepreciationPromise;

    journal.debitLines[0].accountId = depreciationExpenseAccountResult.data[0].id;
    journal.creditLines[0].accountId = accumulatedDepreciationAccountResult.data[0].id;

    journal.description = 'Depreciation Expense';

    journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };

    initialState.journal = journal;

    initialState.fixedAssetOptions = await getFixedAssetOptions(this, true);

    this.setState(initialState);
  };

  handleAmountChange = async (values) => {
    if (typeof values.floatValue === 'undefined') {
      await asyncHandleChange('nested_journal_debitLines_0_debit', null, this);
      await asyncHandleChange('nested_journal_creditLines_0_credit', null, this);
    } else {
      await asyncHandleChange('nested_journal_debitLines_0_debit', values.floatValue, this);
      await asyncHandleChange('nested_journal_creditLines_0_credit', values.floatValue, this);
    }
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const {
      submitting, journal,
    } = this.state;

    const { onAddTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    const debitAmount = sumProperty(journal.debitLines, 'debit');
    const creditAmount = sumProperty(journal.creditLines, 'credit');

    if (debitAmount !== creditAmount) {
      this.setState({ error: { message: 'The credit and debit amounts for a journal must be equal' } });
      return;
    }

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    this.setState({ submitting: true });

    journal.organizationId = organizationId;
    journal.amount = debitAmount;
    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    if (journal.fixedAssetIdSelect) {
      journal.fixedAssetId = journal.fixedAssetIdSelect.id;
      journal.propertyId = journal.fixedAssetIdSelect.propertyId;
      journal.entityId = journal.fixedAssetIdSelect.entityId;
    }

    setJournalScope(journal);

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    create(this, 'journals', journal, true)
      .then((result) => {
        this.closeDialog();
        onAddTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, assetId } = this.props;
    const {
      loading, error, addFixedAssetDialog, journal,
      fixedAssetOptions, displayNotesSection,
    } = this.state;

    if (loading) {
      return null;
    }

    if (addFixedAssetDialog) {
      return (
        <AddFixedAssetDialog
          isOpen={addFixedAssetDialog}
          closeDialog={() => this.setState({ addFixedAssetDialog: false })}
          onAddFixedAsset={async (newValue) => {
            await asyncHandleChange('fixedAssetOptions', await getFixedAssetOptions(this, true), this);
            await asyncHandleChange('nested_journal_fixedAssetIdSelect', await get(this, 'fixed-assets', newValue.id), this);
          }}
        />
      );
    }

    return (
      <form onSubmit={this.addTransaction}>
        <DialogTitle id="alert-dialog-title">
          Add Depreciation
        </DialogTitle>
        <DialogContent>
          <KeyboardDatePicker
            label="Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={journal.date}
            onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
            margin="dense"
            fullWidth
            clearable
            required
          />
          <FormControl margin="dense" fullWidth>
            <InputLabel required>
              Amount
            </InputLabel>
            <NumberFormat
              value={journal.debitLines[0].debit}
              required
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              onValueChange={this.handleAmountChange}
              customInput={Input}
            />
            <TransactionScope
              journal={journal}
              transactionScopeChange={(newScopeValues) => {
                handleTransactionScopeChange(journal, newScopeValues, this);
              }}
            />
          </FormControl>
          {!assetId && (
            <Autocomplete
              options={fixedAssetOptions}
              getOptionLabel={nameAndAddressLabel}
              value={journal.fixedAssetIdSelect}
              onChange={handleAutocompleteChange(
                'nested_journal_fixedAssetIdSelect',
                this,
                () => this.setState({ addFixedAssetDialog: true }),
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Fixed Asset"
                  placeholder="Type to Search"
                  fullWidth
                />
              )}
            />
          )}
          <TextField
            label="Description"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.editImageButton}
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                className={classes.editImageButton}
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Depreciation
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

AddDepreciationDialogContent.contextType = PersonContext;

AddDepreciationDialogContent.defaultProps = {
  assetId: null,
};

AddDepreciationDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  assetId: PropTypes.node,
};

export default withStyles(styles)(AddDepreciationDialogContent);
