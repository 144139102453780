import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Banking from '~/views/Banking';
import AboutYou from '~/views/onboarding/AboutYou/AboutYou';
import DescribesYou from '~/views/onboarding/DescribesYou/DescribesYou';
import ManageRentals from '~/views/onboarding/ManageRentals/ManageRentals';
import PortfolioSetup from '~/views/onboarding/PortfolioSetup/PortfolioSetup';
import PreviousProcess from '~/views/onboarding/PreviousProcess/PreviousProcess';
import Welcome from '~/views/onboarding/Welcome/Welcome';
import Properties from '~/views/Properties';

export default function OnboardingRoutes() {
  return (
    <Switch>
      <Route component={Welcome} exact path="/welcome" />
      <Route component={DescribesYou} exact path="/describes-you" />
      <Route component={ManageRentals} exact path="/manage-rentals" />
      <Route component={AboutYou} exact path="/about-you" />
      <Route component={PreviousProcess} exact path="/previous-process" />
      <Route component={PortfolioSetup} exact path="/portfolio-setup" />

      <Route component={Properties} exact path="/properties" />
      <Route component={Banking} exact path="/accounts/banking" />
      <Route component={Banking} exact path="/onboarding-complete" />

      <Redirect
        to="/welcome"
      />
    </Switch>
  );
}
