import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';

import AddRecurringExpenseDialogContent from './RecurringTransactionTemplates/AddRecurringExpenseDialogContent';
import AddRecurringRevenueDialogContent from './RecurringTransactionTemplates/AddRecurringRevenueDialogContent';
import AddRecurringTransferDialogContent from './RecurringTransactionTemplates/AddRecurringTransferDialogContent';
import Button from './Button';

const useStyles = makeStyles((theme) => ({
  buttonGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    maxWidth: '400px',
    width: '100%',
    margin: 'auto',
  },
  clearIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    color: 'white',
  },
  dialogContentContainer: {
    backgroundColor: 'white',
    marginRight: '25px',
    marginLeft: '25px',
    marginBottom: '25px',
    borderRadius: '5px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '425px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '600px',
    },
  },
}));
export default function AddRecurringTransactionDialog({ isOpen, closeDialog, onAddRecurringTransaction }) {
  const classes = useStyles();
  const [transactionType, setTransactionType] = useState(null);
  const [isDialogHidden, setIsDialogHidden] = useState(false);

  const closeRecurringTransactionDialog = () => {
    setIsDialogHidden(true);
    setTransactionType(null);
    closeDialog();
    // setTimeout(() => setIsDialogHidden(false), 1000);
  };

  const onAddTransaction = () => {
    setTransactionType(null);
    closeRecurringTransactionDialog();
    onAddRecurringTransaction();
  };

  const getTransactionTypeContent = () => {
    const dialogProps = {
      isOpen: true,
      closeDialog: closeRecurringTransactionDialog,
      onAddRecurringTransaction: onAddTransaction,
    };
    switch (transactionType) {
      case 'expense':
        return <AddRecurringExpenseDialogContent {...dialogProps} />;
      case 'revenue':
        return <AddRecurringRevenueDialogContent {...dialogProps} />;
      case 'transfer':
        return <AddRecurringTransferDialogContent {...dialogProps} />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      maxWidth="md"
      disableBackdropClick
      disableEscapeKeyDown
      onClose={closeRecurringTransactionDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="addRecurringTransactionDialog"
      hidden={isDialogHidden}
      PaperProps={{
        elevation: 0,
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Box className={classes.clearIconContainer}>
        <ClearIcon className={classes.closeIcon} onClick={closeRecurringTransactionDialog} />
      </Box>
      <Box className={classes.dialogContentContainer} boxShadow={3}>
        {transactionType ? (
          getTransactionTypeContent()
        ) : (
          <ChooseTransactionType classes={classes} setTransactionType={setTransactionType} />
        )}
      </Box>
    </Dialog>
  );
}

AddRecurringTransactionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddRecurringTransaction: PropTypes.func.isRequired,
};

function ChooseTransactionType({ classes, setTransactionType }) {
  return (
    <>
      <DialogTitle id="alert-dialog-title">Choose Recurring Transaction Type</DialogTitle>
      <DialogContent>
        <Box style={{ justifyContent: 'center' }}>
          <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
            <Typography variant="body1" gutterBottom>
              This will create a recurring manual transaction that will be booked automatically depending on the
              frequency you choose. Recurring imported transactions from a linked bank account should be booked using
              imported transaction matching rules from the import feed.
            </Typography>
          </Box>
          <Box className={classes.buttonGroupContainer}>
            <Button hasFullWidth variant="outlined" onClick={() => setTransactionType('expense')}>
              Expense
            </Button>
            <Button hasFullWidth variant="outlined" onClick={() => setTransactionType('revenue')}>
              Revenue
            </Button>
            <Button hasFullWidth variant="outlined" onClick={() => setTransactionType('transfer')}>
              Transfer
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </>
  );
}

ChooseTransactionType.propTypes = {
  classes: PropTypes.object.isRequired,
  setTransactionType: PropTypes.func.isRequired,
};
