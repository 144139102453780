import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';

import {
  Table, TableBody, TableCell, TableFooter, TableHead, TableRow, withStyles,
} from '@material-ui/core';
import { generateHash } from 'random-hash';
import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
  handleAutocompleteChange,
  handleNumberFormatChange,
  handleCheckboxChange,
  handleTransactionScopeChange,
} from '../functions/InputHandlers';

import {
  getAccountOptions,
  getBalanceSheetAccountOptions,
  getVendorOptions,
  getFixedAssetOptions,
  getTransactionRuleTypeOptions,
  nameLabel,
} from './Autocomplete/Library';
import { sumProperty } from '../functions/SumFunctions';

import {
  setJournalScope,
} from '../functions/JournalFunctions';

import TransactionScope from './TransactionScope';

const styles = () => ({
  amountCell: {
    width: '30%',
    maxWidth: '120px',
  },
});

class AddImportedTransactionRuleDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = async () => {
    const { organizationId } = this.context;
    const initialState = {
      loading: false,
      autobookResult: false,
      submitting: false,
      error: null,
      rule: {
        organizationId,
        descriptionContains: '',
        transactionAmount: null,
        typeSelect: { name: 'Expense', id: 'Expense' },
        destinationAccountIdSelect: null,
        vendorIdSelect: null,
        fixedAssetIdSelect: null,
        analysisAccountIdSelect: null,
        autobookMatchingTransactions: true,
        applyToAllAccounts: true,
      },
      // use a journal object for the scope select
      journal: {
        journalScopeSelect: { name: 'Property/Unit', id: 'Property/Unit' },
      },
      split: [
        this.getSplitLine(), this.getSplitLine(),
      ],
    };

    initialState.expenseAccountOptions = await getAccountOptions(this, { type: 'Expense' });
    initialState.revenueAccountOptions = await getAccountOptions(this, { type: 'Revenue' });
    initialState.fixedAssetAccountOptions = await getAccountOptions(this, { type2: 'Fixed Asset' });
    initialState.balanceSheetAccountOptions = await getBalanceSheetAccountOptions(this);
    initialState.vendorOptions = await getVendorOptions(this);
    initialState.fixedAssetOptions = await getFixedAssetOptions(this);
    initialState.ruleAccountOptions = await getAccountOptions(this, { type2: ['Bank', 'Credit Card'] });
    this.setState(initialState);
  };

  getSplitLine = () => ({ key: generateHash(), amount: null });

  addRule = async (event) => {
    event.preventDefault();
    const {
      submitting, rule, journal, split,
    } = this.state;

    const { onAdd } = this.props;

    if (submitting) {
      return;
    }

    if (!rule.descriptionContains && rule.transactionAmount === null) {
      this.setState({ error: { message: 'Either the description or amount must be set to create a rule' } });
      return;
    }

    if (rule.typeSelect.id === 'Split' && parseFloat(sumProperty(split, 'amount')) !== rule.transactionAmount) {
      this.setState({ error: { message: 'The total of the split lines must equal the transaction amount' } });
      return;
    }

    this.setState({ submitting: true });

    const ruleSubmit = { ...rule };
    ruleSubmit.type = ruleSubmit.typeSelect.id;

    if (ruleSubmit.type === 'Split') {
      ruleSubmit.type = 'Split';
      ruleSubmit.destinationAccountId = null;
      ruleSubmit.vendorId = null;
      ruleSubmit.fixedAssetId = null;
      ruleSubmit.applyToAllAccounts = true;
      ruleSubmit.entityId = null;
      ruleSubmit.propertyId = null;
      ruleSubmit.unitId = null;
      ruleSubmit.scopeSelect = null;
      ruleSubmit.splitAmounts = split.map((splitLine) => splitLine.amount);
      ruleSubmit.analysisAccountId = ruleSubmit.applyToAllAccounts
        ? null
        : ruleSubmit.analysisAccountIdSelect.id;
    } else if (ruleSubmit.type === 'Ignore') {
      ruleSubmit.type = 'Ignore';
      ruleSubmit.vendorId = null;
      ruleSubmit.fixedAssetId = null;
      ruleSubmit.entityId = null;
      ruleSubmit.propertyId = null;
      ruleSubmit.unitId = null;
      ruleSubmit.scopeSelect = null;
      if (ruleSubmit.applyToAllAccounts) {
        ruleSubmit.analysisAccountId = null;
      } else {
        ruleSubmit.analysisAccountId = ruleSubmit.analysisAccountIdSelect.id;
      }
    } else {
      setJournalScope(journal);
      Object.assign(ruleSubmit, journal);
      ruleSubmit.destinationAccountId = ruleSubmit.destinationAccountIdSelect.id;

      ruleSubmit.vendorId = ruleSubmit.vendorIdSelect ? ruleSubmit.vendorIdSelect.id : null;
      ruleSubmit.fixedAssetId = ruleSubmit.fixedAssetIdSelect
        ? ruleSubmit.fixedAssetIdSelect.id : null;
      ruleSubmit.analysisAccountId = ruleSubmit.applyToAllAccounts
        ? null
        : ruleSubmit.analysisAccountIdSelect.id;
    }

    create(this, 'imported-transaction-rules', ruleSubmit, true)
      .then(async (result) => {
        onAdd(result);
        if (ruleSubmit.autobookMatchingTransactions) {
          let automationResult;
          if (ruleSubmit.type === 'Split') {
            automationResult = await create(this, 'automations', {
              organizationId: ruleSubmit.organizationId,
              automationName: 'splitRuleMatching',
              ruleId: result.id,
            });
          } else if (ruleSubmit.type === 'Ignore') {
            automationResult = await create(this, 'automations', {
              organizationId: ruleSubmit.organizationId,
              automationName: 'ignoreRuleMatching',
              ruleId: result.id,
            });
          } else {
            automationResult = await create(this, 'automations', {
              organizationId: ruleSubmit.organizationId,
              automationName: 'bookRuleMatching',
              ruleId: result.id,
            });
          }
          if (automationResult.newTransactions) {
            this.setState({
              autobookResult: true,
              autobookedTransactionCount: automationResult.newTransactions,
            });
          } else {
            this.closeDialog();
          }
        } else {
          this.closeDialog();
        }
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  addSplitLine = () => {
    const { split } = this.state;
    this.setState({ split: split.concat([this.getSplitLine()]) });
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  render() {
    const { isOpen } = this.props;
    const {
      loading, error, rule, journal,
      revenueAccountOptions, expenseAccountOptions, fixedAssetAccountOptions,
      balanceSheetAccountOptions,
      vendorOptions, fixedAssetOptions, ruleAccountOptions,
      autobookResult, autobookedTransactionCount, split,
    } = this.state;
    const { vendorTracking } = this.context;
    const { classes } = this.props;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && !autobookResult && (
          <form onSubmit={this.addRule}>
            <DialogTitle id="alert-dialog-title">Add Rule</DialogTitle>
            <DialogContent>
              <Autocomplete
                options={getTransactionRuleTypeOptions}
                getOptionLabel={nameLabel}
                value={rule.typeSelect}
                onChange={handleAutocompleteChange(
                  'nested_rule_typeSelect',
                  this,
                )}
                disableClearable
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Rule Type"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              {rule.typeSelect && rule.typeSelect.id === 'Expense' && (
                <Autocomplete
                  options={expenseAccountOptions}
                  getOptionLabel={nameLabel}
                  value={rule.destinationAccountIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_rule_destinationAccountIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Expense Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {rule.typeSelect && rule.typeSelect.id === 'Revenue' && (
                <Autocomplete
                  options={revenueAccountOptions}
                  getOptionLabel={nameLabel}
                  value={rule.destinationAccountIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_rule_destinationAccountIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Revenue Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {rule.typeSelect && rule.typeSelect.id === 'Fixed Asset Purchase' && (
                <>
                  <Autocomplete
                    options={fixedAssetOptions}
                    getOptionLabel={nameLabel}
                    value={rule.fixedAssetIdSelect}
                    onChange={handleAutocompleteChange(
                      'nested_rule_fixedAssetIdSelect',
                      this,
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                        margin="dense"
                        label="Fixed Asset"
                        placeholder="Type to Search"
                        fullWidth
                        required
                      />
                    )}
                  />
                  <Autocomplete
                    options={fixedAssetAccountOptions}
                    getOptionLabel={nameLabel}
                    value={rule.destinationAccountIdSelect}
                    onChange={handleAutocompleteChange(
                      'nested_rule_destinationAccountIdSelect',
                      this,
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                        margin="dense"
                        label="Fixed Asset Account"
                        placeholder="Type to Search"
                        fullWidth
                        required
                      />
                    )}
                  />
                </>
              )}
              {rule.typeSelect && rule.typeSelect.id === 'Transfer From' && (
                <Autocomplete
                  options={balanceSheetAccountOptions}
                  getOptionLabel={nameLabel}
                  value={rule.destinationAccountIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_rule_destinationAccountIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Transferred From Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {rule.typeSelect && rule.typeSelect.id === 'Transfer To' && (
                <Autocomplete
                  options={balanceSheetAccountOptions}
                  getOptionLabel={nameLabel}
                  value={rule.destinationAccountIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_rule_destinationAccountIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Transferred To Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              <TextField
                label="Description Contains (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: rule.descriptionContains,
                  name: 'nested_rule_descriptionContains',
                  onChange: handleTextFieldChange(this),
                }}
              />
              {rule.typeSelect && rule.typeSelect.id === 'Split'
                ? (
                  <FormControl margin="dense" fullWidth>
                    <InputLabel>
                      Transaction Amount
                    </InputLabel>
                    <NumberFormat
                      value={rule.transactionAmount}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      onValueChange={handleNumberFormatChange('nested_rule_transactionAmount', this)}
                      customInput={Input}
                      required
                    />
                  </FormControl>
                ) : (
                  <>
                    <FormControl margin="dense" fullWidth>
                      <InputLabel>
                        Transaction Amount (optional)
                      </InputLabel>
                      <NumberFormat
                        value={rule.transactionAmount}
                        thousandSeparator
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale
                        onValueChange={handleNumberFormatChange('nested_rule_transactionAmount', this)}
                        customInput={Input}
                      />
                    </FormControl>
                    {rule.typeSelect.id !== 'Ignore' && (
                      <TransactionScope
                        journal={journal}
                        transactionScopeChange={(newScopeValues) => {
                          handleTransactionScopeChange(journal, newScopeValues, this);
                        }}
                      />
                    )}
                  </>
                )}
              {rule.typeSelect && rule.typeSelect.id === 'Split' && (
                <>
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    padding={2}
                    marginY={2}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell><Typography variant="subtitle2">Transactions</Typography></TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {split.map((splitLine, index) => (
                          <TableRow key={splitLine.key}>
                            <TableCell>
                              {`Transaction ${index + 1}`}
                            </TableCell>
                            <TableCell className={classes.amountCell}>
                              <FormControl margin="dense" fullWidth>
                                <InputLabel required>
                                  Amount
                                </InputLabel>
                                <NumberFormat
                                  value={split[index].amount}
                                  thousandSeparator
                                  required
                                  prefix="$"
                                  decimalScale={2}
                                  onValueChange={handleNumberFormatChange(`nested_split_${index}_amount`, this)}
                                  customInput={Input}
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell>
                            <Button onClick={this.addSplitLine} color="primary">
                              Add Line
                            </Button>
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              displayType="text"
                              value={sumProperty(split, 'amount')}
                              thousandSeparator
                              prefix="Total: $"
                              decimalScale={2}
                              fixedDecimalScale
                            />
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </Box>
                </>
              )}
              {vendorTracking && rule.typeSelect
                && ['Expense', 'Fixed Asset Purchase'].includes(rule.typeSelect.id) && (
                <Autocomplete
                  options={vendorOptions}
                  getOptionLabel={nameLabel}
                  value={rule.vendorIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_rule_vendorIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Vendor (optional)"
                      placeholder="Type to Search"
                      fullWidth
                    />
                  )}
                />
              )}
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={rule.autobookMatchingTransactions} onChange={handleCheckboxChange(this)} name="nested_rule_autobookMatchingTransactions" />}
                  label="Automatically book matching transactions"
                />
              </FormControl>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={rule.applyToAllAccounts} onChange={handleCheckboxChange(this)} name="nested_rule_applyToAllAccounts" />}
                  label="Apply this rule to all accounts"
                />
              </FormControl>
              {!rule.applyToAllAccounts && (
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  // bgcolor="common.white"
                  padding={2}
                  marginY={2}
                >
                  <Autocomplete
                    options={ruleAccountOptions}
                    getOptionLabel={nameLabel}
                    value={rule.analysisAccountIdSelect}
                    onChange={handleAutocompleteChange(
                      'nested_rule_analysisAccountIdSelect',
                      this,
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                        margin="dense"
                        label="Rule Applies to Account"
                        placeholder="Type to Search"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Box>
              )}
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Rule
              </Button>
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && autobookResult && (
          <>
            <DialogTitle id="alert-dialog-title">Rule Added</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Your rule was created. ${autobookedTransactionCount} transactions matched the template and were booked automatically.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary" variant="contained" disableElevation>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

AddImportedTransactionRuleDialog.contextType = PersonContext;

AddImportedTransactionRuleDialog.defaultProps = {
};

AddImportedTransactionRuleDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddImportedTransactionRuleDialog);
