import moment from 'moment';
import { RECURRING_TRANSACTION_DAY_OPTIONS } from '~/components/Autocomplete/Library';

export default function getRecurringFrequencyText(startDate, endDate, frequency) {
  if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
    return null;
  }
  const startDateNumber = Number(moment(startDate).format('D'));
  let findOption = null;
  let text = '';
  const formattedStartDate = moment(startDate).format('MM/DD/YYYY');
  const formattedEndDate = moment(endDate).format('MM/DD/YYYY');

  switch (frequency.id) {
    case 'Monthly':
      if (startDateNumber > 28) {
        findOption = RECURRING_TRANSACTION_DAY_OPTIONS.find((option) => option.id === 32);
        text = findOption ? `Monthly on the ${findOption.name}` : null;
        if (endDate) {
          text += ` until ${formattedEndDate}`;
        }
        return text;
      }
      findOption = RECURRING_TRANSACTION_DAY_OPTIONS.find(
        (option) => option.id === Number(moment(startDate).format('D')),
      );
      text = findOption ? `Monthly on the ${findOption.name}` : null;
      if (endDate) {
        text += ` until ${formattedEndDate}`;
      }
      return text;
    case 'Quarterly':
      text = `Quarterly beginning on ${formattedStartDate}`;
      if (endDate) {
        text += ` until ${formattedEndDate}`;
      }
      return text;
    case 'Annually':
      text = `Annually beginning on ${formattedStartDate}`;
      if (endDate) {
        text += ` until ${formattedEndDate}`;
      }
      return text;
    default:
      return null;
  }
}
