import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';

import { get, create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncSetState,
  handleChange,
  asyncHandleChange,
  handleKeyboardDatePickerChange,
  handleTextFieldChange,
  handleSearchSelectChange,
  handleUploaderChange,
} from '../functions/InputHandlers';

import SearchSelect from './SearchSelect/SearchSelect';
import {
  searchProperties, searchUnits,
} from './SearchSelect/SearchFunctions';

const styles = {
  datetime: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '4px',
  },
  hideWidget: {
    display: 'none',
  },
};

class AddNoteDialog extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = async () => {
    const { unitId, propertyId } = this.props;
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      selectedProperty: null,
      note: {
        date: moment().format('YYYY-MM-DD'),
        dateSelect: moment(),
        propertyId: null,
        propertyIdSelect: null,
        unitId: null,
        unitIdSelect: null,
        title: '',
        text: '',
        attachmentURL: '',
        attachmentURL_info: {},
      },
    };

    await asyncSetState(initialState, this);
    const { note } = this.state;
    const newNote = { ...note };
    if (propertyId) {
      const property = await get(this, 'properties', propertyId);
      handleChange('selectedProperty', property, this);
      newNote.propertyId = property.id;
      newNote.propertyIdSelect = { label: property.address1, value: property.id };
      handleChange('note', newNote, this);
    } else if (unitId) {
      const unit = await get(this, 'units', unitId);
      handleChange('selectedProperty', unit.property, this);
      newNote.propertyId = unit.property.id;
      newNote.propertyIdSelect = { label: unit.property.address1, value: unit.property.id };
      newNote.unitId = unit.id;
      newNote.unitIdSelect = { label: unit.name, value: unit.id };
      handleChange('note', newNote, this);
    }
  };

  handlePropertyChange = async (name, value, isMulti) => {
    const { note } = this.state;
    if (value !== null) {
      const property = await get(this, 'properties', value.value);
      handleChange('selectedProperty', property, this);
    } else {
      handleChange('selectedProperty', null, this);
    }
    note.unitId = null;
    note.unitIdSelect = null;
    await asyncHandleChange('note', { ...note }, this);

    handleSearchSelectChange(this)(name, value, isMulti);
  };

  addNote = async (event) => {
    event.preventDefault();
    const {
      submitting, note,
    } = this.state;

    const { organizationId } = this.context;
    const { onAddNote, closeDialog } = this.props;

    if (submitting) {
      return;
    }

    if (!note.date) {
      this.setState({ error: { message: 'Please enter a valid date for this note' } });
      return;
    }

    this.setState({ submitting: true });

    Object.assign(note, { organizationId });

    create(this, 'notes', note)
      .then((result) => {
        onAddNote(result);
        closeDialog();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const {
      loading, error, note, selectedProperty,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEnforceFocus
        onClose={closeDialog}
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="addNoteDialog"
      >
        {!loading && (
          <form onSubmit={this.addNote}>
            <DialogTitle id="alert-dialog-title">Add Note</DialogTitle>
            <DialogContent>
              <KeyboardDatePicker
                className={classes.datetime}
                label="Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={note.date}
                onChange={handleKeyboardDatePickerChange('nested_note_date', this)}
                margin="dense"
                fullWidth
                clearable
                required
              />
              <SearchSelect
                searchFunction={searchProperties(this)}
                changeFunction={this.handlePropertyChange}
                label="Property"
                name="nested_note_propertyId"
                isMulti={false}
                value={note.propertyIdSelect}
                margin="dense"
                target={document.getElementById('addNoteDialog')}
              />
              {selectedProperty && selectedProperty.unitSelection === 'multi' && (
                <SearchSelect
                  searchFunction={searchUnits(this, { propertyId: selectedProperty.id })}
                  changeFunction={handleSearchSelectChange(this)}
                  label="Unit"
                  name="nested_note_unitId"
                  isMulti={false}
                  value={note.unitIdSelect}
                  margin="dense"
                  target={document.getElementById('addNoteDialog')}
                />
              )}
              <TextField
                label="Title"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: note.title,
                  name: 'nested_note_title',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Text"
                fullWidth
                multiline
                rowsMax="5"
                margin="dense"
                InputProps={{
                  value: note.text,
                  name: 'nested_note_text',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <FormControl margin="dense">
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Attachments
                </FormLabel>
                <div className={note.attachmentURL ? null : classes.hideWidget}>
                  <Widget
                    ref={this.uploadcareWidget}
                    multiple
                    tabs="file url camera"
                    value={note.attachmentURL}
                    onChange={handleUploaderChange('nested_note_attachmentURL', this)}
                  />
                </div>
                {!note.attachmentURL && (
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.editImageButton}
                    onClick={() => {
                      this.uploadcareWidget.current.openDialog();
                    }}
                  >
                    Click to Add Pictures or Files
                  </Button>
                )}
                {note.attachmentURL && (
                  <Button
                    color="primary"
                    className={classes.editImageButton}
                    onClick={async () => {
                      await asyncHandleChange('nested_note_attachmentURL', '', this);
                      this.uploadcareWidget.current.reloadInfo();
                    }}
                  >
                    Remove All Attachments
                  </Button>
                )}
              </FormControl>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Note
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

AddNoteDialog.contextType = PersonContext;

AddNoteDialog.defaultProps = {
  unitId: null,
  propertyId: null,
};

AddNoteDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddNote: PropTypes.func.isRequired,
  unitId: PropTypes.node,
  propertyId: PropTypes.node,
};

export default withStyles(styles)(AddNoteDialog);
