import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {
  create,
} from '../feathersWrapper';

import { PersonContext } from '../contexts/PersonContext';

import {
  handleTextFieldChange,
} from '../functions/InputHandlers';

class AddContactDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  componentDidMount() {
    const { organizationId } = this.context;
    this.setState({ organizationId });
  }

  getInitialState = () => {
    const initialState = {
      submitting: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    };
    return initialState;
  };

  addContact = async (event) => {
    event.preventDefault();
    const {
      submitting, firstName, lastName, email, phone, organizationId,
    } = this.state;

    const { onAddContact } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    const person = {
      firstName,
      lastName,
      email,
      phone,
    };
    await create(this, 'contacts', {
      organizationId, person,
    }, true)
      .then((result) => {
        onAddContact(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      error, firstName, lastName, email, phone,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        onClose={closeDialog}
        onEnter={() => { this.setState(this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={this.addContact}>
          <DialogTitle id="alert-dialog-title">Add Contact</DialogTitle>
          <DialogContent>
            <TextField
              label="First Name"
              id="firstName"
              fullWidth
              margin="dense"
              required
              InputProps={{
                value: firstName,
                name: 'firstName',
                onChange: handleTextFieldChange(this),
              }}
            />
            <TextField
              label="Last Name"
              id="lastName"
              fullWidth
              margin="dense"
              required
              InputProps={{
                value: lastName,
                name: 'lastName',
                onChange: handleTextFieldChange(this),
              }}
            />
            <TextField
              label="Email (optional)"
              fullWidth
              margin="dense"
              InputProps={{
                value: email,
                type: 'email',
                name: 'email',
                onChange: handleTextFieldChange(this),
              }}
            />
            <TextField
              label="Phone Number (optional)"
              fullWidth
              margin="dense"
              InputProps={{
                value: phone,
                type: 'tel',
                name: 'phone',
                onChange: handleTextFieldChange(this),
              }}
            />
            <Typography color="error">{error && error.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained" disableElevation>
              Save Contact
            </Button>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

AddContactDialog.contextType = PersonContext;

AddContactDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddContact: PropTypes.func.isRequired,
};

export default AddContactDialog;
