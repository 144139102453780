import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import AdminHome from '../views/admin/AdminHome';
import ImpersonateFromIntercom from '../views/admin/ImpersonateFromIntercom';
import MyProfile from '../views/MyProfile';

export const adminRouteArray = [
  {
    exact: true,
    path: '/admin/home',
    component: AdminHome,
    breadcrumb: 'Admin',
  },
  {
    exact: true,
    path: '/admin/my-profile',
    component: MyProfile,
  },
  {
    exact: true,
    path: '/admin/intercom',
    component: ImpersonateFromIntercom,
  },
];

/* eslint-disable react/jsx-props-no-spreading */
class AdminRoutes extends React.PureComponent {
  render() {
    return (
      <Switch>
        {adminRouteArray.map((route) => (
          <Route
            {...route}
            key={route.path}
          />
        ))}
        <Redirect
          to="/admin/home"
          push
        />

      </Switch>
    );
  }
}

AdminRoutes.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default AdminRoutes;
