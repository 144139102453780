import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { useOnboardingContext } from '~/contexts/OnboardingProvider';
import { PersonContext } from '~/contexts/PersonContext';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

import useUpdateUserOnboarding from '../hooks/useUpdateUserOnboarding';

const SEGMENT_LOCATION = 'Onboarding - Questions: Manage Rentals';
const MANAGEMENT_PROCESS = {
  MANAGE_MYSELF: 'MANAGE_MYSELF',
  HIRE_PROPERTY_MANAGER: 'HIRE_PROPERTY_MANAGER',
  MIX_OF_BOTH: 'MIX_OF_BOTH',
};

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    width: '100%',
    maxWidth: '600px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    marginBottom: theme.spacing(1),
    marginInline: 'auto',
    color: theme.palette.primary.contrastText,
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
  },
  backButton: {
    marginInline: 'auto',
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
  },
}));

export default function ManageRentals() {
  const [managementProcess, setManagementProcess] = useState('');

  const { userOnboarding } = useOnboardingContext();

  useEffect(() => {
    if (userOnboarding?.managementProcess) {
      setManagementProcess(userOnboarding.managementProcess);
    }
  }, [userOnboarding?.managementProcess]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateUserOnboarding = useUpdateUserOnboarding();
  const tracking = useSegmentTrack();
  const history = useHistory();
  const { triggerSnackbar } = useContext(PersonContext);

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);

      await updateUserOnboarding({ managementProcess });

      tracking('next clicked', { location: SEGMENT_LOCATION });

      history.push('/about-you');
    } catch {
      setIsSubmitting(false);
      triggerSnackbar({
        message: 'Something went wrong. Please try again.',
        type: 'error',
      });
    }
  }

  function handleBack() {
    tracking('go_back clicked', { location: SEGMENT_LOCATION });
    history.push('/describes-you');
  }

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Typography id="manage-rentals-title" variant="h6" className={classes.title}>
          As an Investor, how do you manage your rentals?
        </Typography>

        <RadioGroup
          aria-labelledby="manage-rentals-title"
          name="managementProcess"
          required
          value={managementProcess}
          onChange={(event) => setManagementProcess(event.target.value)}
          className={classes.formControl}
        >
          <FormControlLabel
            control={<Radio required />}
            label="I manage my own rentals"
            value={MANAGEMENT_PROCESS.MANAGE_MYSELF}
          />
          <FormControlLabel
            control={<Radio required />}
            label="I use a property manager"
            value={MANAGEMENT_PROCESS.HIRE_PROPERTY_MANAGER}
          />
          <FormControlLabel control={<Radio required />} label="Mix of both" value={MANAGEMENT_PROCESS.MIX_OF_BOTH} />
        </RadioGroup>

        <Button
          color="primary"
          disabled={isSubmitting}
          size="large"
          type="submit"
          variant="contained"
          className={classes.submitButton}
        >
          Next
        </Button>
        <Button
          color="secondary"
          disabled={isSubmitting}
          size="large"
          variant="outlined"
          onClick={() => handleBack()}
          className={classes.backButton}
        >
          Go Back
        </Button>
      </form>
    </Card>
  );
}
