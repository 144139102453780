import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { find, remove } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import AddAdministratorDialog from '../components/AddAdministratorDialog';

import CardBase from '../components/CardBase';
import PageHeader from '../components/PageHeader';
import PageGrid from '../components/PageGrid';

class Access extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { addAdministrator: false, loading: true };
  }

  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { organizationId } = this.context;
    const administrators = await find(this, 'administrators', { query: { organizationId } });
    const administratorInvites = await find(this, 'administrator-invites', { query: { organizationId } });
    this.setState({
      administrators,
      administratorInvites,
      loading: false,
    });
  };

  removeAdmin = async (id) => {
    try {
      await remove(this, 'administrators', id, true);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Unable to remove record. Please try again or contact us for assistance.');
    }
    this.getData();
  };

  removeInvite = async (id) => {
    try {
      await remove(this, 'administrator-invites', id, true);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Unable to remove record. Please try again or contact us for assistance.');
    }
    this.getData();
  };

  actionButtons = () => (
    [
      { text: 'Send Access Invite', action: (() => this.setState({ addAdministrator: true })), class: 'add' },
    ]
  );

  render() {
    const { match } = this.props;
    const {
      loading, administrators, administratorInvites, addAdministrator,
    } = this.state;
    const {
      id,
    } = this.context;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <AddAdministratorDialog
          isOpen={addAdministrator}
          closeDialog={() => this.setState({ addAdministrator: false })}
          onAdd={this.getData}
        />
        <PageHeader match={match} actionButtons={this.actionButtons()} title="Portfolio Access" />
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Email
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Type
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {administrators.data.map((administrator) => (
                    <TableRow key={administrator.id}>
                      <TableCell>
                        {`${administrator.person.firstName} ${administrator.person.lastName}`}
                      </TableCell>
                      <TableCell>
                        {administrator.person.email}
                      </TableCell>
                      <TableCell>
                        {administrator.accessLevel === 'Read Only' ? 'Read Only' : 'Full Access'}
                      </TableCell>
                      <TableCell>
                        {id !== administrator.person.id && (
                          <Button onClick={() => this.removeAdmin(administrator.id)} color="primary" size="small">
                            Remove
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {administratorInvites.data.map((invite) => (
                    <TableRow key={invite.id}>
                      <TableCell>
                        {`${invite.firstName} ${invite.lastName} (invited)`}
                      </TableCell>
                      <TableCell>
                        {invite.email}
                      </TableCell>
                      <TableCell />
                      <TableCell>
                        <Button onClick={() => this.removeInvite(invite.id)} color="primary" size="small">
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

Access.contextType = PersonContext;

Access.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Access;
