import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { get } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleSearchSelectChange,
  asyncHandleChange,
} from '../functions/InputHandlers';

import SearchSelect from './SearchSelect/SearchSelect';
import {
  searchProperties, searchUnits,
} from './SearchSelect/SearchFunctions';

const styles = {};

class FilterLeasesDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  getInitialState = async () => {
    const { filter } = this.props;
    if (!filter) {
      return { loading: true };
    }

    return { filter, loading: false };
  };

  handlePropertyChange = async (name, value, isMulti) => {
    const { filter } = this.state;
    if (value !== null) {
      const property = await get(this, 'properties', value.value);
      filter.selectedProperty = property;
    } else {
      filter.selectedProperty = null;
    }
    filter.unitId = null;
    filter.unitIdSelect = null;
    await asyncHandleChange('filter', { ...filter }, this);

    handleSearchSelectChange(this)(name, value, isMulti);
  };

  updateFilter = async (event) => {
    event.preventDefault();
    const { filter } = this.state;
    const { updateFilter, closeDialog } = this.props;

    filter.page = 0;
    updateFilter(filter);

    closeDialog();
  };

  render() {
    const { isOpen, closeDialog } = this.props;
    const {
      loading, error, filter,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={closeDialog}
        onEnter={async () => { this.setState(await this.getInitialState()); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="filterLeasesDialog"
      >
        {!loading && (
          <form onSubmit={this.updateFilter}>
            <DialogTitle id="alert-dialog-title">Filter Leases</DialogTitle>
            <DialogContent>
              <SearchSelect
                searchFunction={searchProperties(this)}
                changeFunction={this.handlePropertyChange}
                label="Property"
                name="nested_filter_propertyId"
                isMulti={false}
                value={filter.propertyIdSelect}
                margin="dense"
                target={document.getElementById('filterLeasesDialog')}
              />
              {filter.selectedProperty && filter.selectedProperty.unitSelection === 'multi' && (
                <SearchSelect
                  searchFunction={searchUnits(this, { propertyId: filter.selectedProperty.id })}
                  changeFunction={handleSearchSelectChange(this)}
                  label="Unit"
                  name="nested_filter_unitId"
                  isMulti={false}
                  value={filter.unitIdSelect}
                  margin="dense"
                  target={document.getElementById('filterLeasesDialog')}
                />
              )}
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Update Filter
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

FilterLeasesDialog.contextType = PersonContext;

FilterLeasesDialog.defaultProps = {
};

FilterLeasesDialog.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterLeasesDialog);
