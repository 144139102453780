import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { PersonContext } from '../contexts/PersonContext';

import AddBookedTransactionDialog from './AddBookedTransactionDialog';

class SelectPropertiesMoreDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { addTransactionDialog: false };
  }

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ addTransactionDialog: false });
    closeDialog();
  };

  render() {
    const { isOpen, propertyId } = this.props;
    const {
      addTransactionDialog, transactionType,
    } = this.state;

    if (addTransactionDialog) {
      return (
        <AddBookedTransactionDialog
          propertyId={propertyId}
          isOpen
          type={transactionType}
          closeDialog={this.closeDialog}
          onAddTransaction={() => {}}
        />
      );
    }

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={this.closeDialog}
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="addTransactionDialog"
      >
        <DialogContent>
          <ButtonGroup
            color="secondary"
            orientation="vertical"
            variant="outlined"
            size="large"
            fullWidth
          >
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              fullWidth
              onClick={() => this.setState({ addTransactionDialog: true, transactionType: 'Revenue' })}
            >
              Add Revenue
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              fullWidth
              onClick={() => this.setState({ addTransactionDialog: true, transactionType: 'Expense' })}
            >
              Add Expense
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              fullWidth
              onClick={() => this.setState({ addTransactionDialog: true, transactionType: 'Loan Payment' })}
            >
              Add Loan Payment
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              fullWidth
              component={RouterLink}
              to={`/reports/net-operating-income?propertyId=${propertyId}`}
            >
              View Net Operating Income
            </Button>
          </ButtonGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SelectPropertiesMoreDialog.contextType = PersonContext;

SelectPropertiesMoreDialog.defaultProps = {
  propertyId: null,
};

SelectPropertiesMoreDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  propertyId: PropTypes.node,
};

export default SelectPropertiesMoreDialog;
