import React from 'react';
import PropTypes from 'prop-types';
import { PlaidLink } from 'react-plaid-link';

import { create, patch } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';

class PlaidLinkUpdate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  async componentDidMount() {
    const { organizationId } = this.context;
    const { plaidItemId } = this.props;
    const token = await create(this, 'plaid-link-tokens', {
      organizationId,
      plaidItemId,
      update: true,
    });
    this.setState({ token, loading: false });
  }

  componentWillUnmount() {
    document.body.style.removeProperty('overflow');
  }

  onSuccess = async () => {
    const { onSuccess, plaidItemId } = this.props;
    await patch(this, 'plaid-items', plaidItemId, {
      requiresUpdate: false,
    });
    onSuccess();

    // for some reason plaid adds overflow hidden on closing - this prevents scrolling
    setTimeout(() => { document.body.style.removeProperty('overflow'); }, 2000);
  };

  // eslint-disable-next-line no-unused-vars
  onExit = async (error, metadata) => {
    const { closeDialog } = this.props;
    closeDialog();
    // for some reason plaid adds overflow hidden on closing - this prevents scrolling
    setTimeout(() => { document.body.style.removeProperty('overflow'); }, 2000);
  };

  render() {
    const { loading, token } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PlaidLink
        token={token}
        onSuccess={this.onSuccess}
        onExit={this.onExit}
        onLoad={() => { document.getElementById('plaidLinkUpdate').click(); }}
        style={{ display: 'none' }}
      >
        <span id="plaidLinkUpdate">Update a bank account</span>
      </PlaidLink>
    );
  }
}

PlaidLinkUpdate.contextType = PersonContext;

PlaidLinkUpdate.defaultProps = {};

PlaidLinkUpdate.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  plaidItemId: PropTypes.string.isRequired,
};

export default PlaidLinkUpdate;
