import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import AddBookedTransactionDialog from '../../components/AddBookedTransactionDialog';
// import AddPMStatementDialog from '../../components/AddPMStatementDialog';
import ReconcileAccountDialog from '../../components/ReconcileAccountDialog';

import CardBase from '../../components/CardBase';
import UploadPropertyManagerPdfDialog from '../../components/UploadPropertyManagerPdfDialog';
import UploadAirbnbStatementDialog from '../../components/UploadAirbnbStatementDialog';
import UploadVRBOStatementDialog from '../../components/UploadVRBOStatementDialog';
import UploadPropertyManagerSpreadsheet from '../../components/UploadPropertyManagerSpreadsheetDialog';

class PMAccountQuicklinksCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      addBookedTransactionDialog: false,
      reconcileAccountDialog: false,
      uploadPmPdfDialog: false,
    };
  }

  setTransactionsStale = () => {
    const { markStale } = this.props;
    markStale('accountGraph');
    markStale('journals');
  };

  render() {
    const {
      addBookedTransactionDialog,
      reconcileAccountDialog,
      uploadPmPdfDialog,
      uploadPMSpreadsheetDialog,
      uploadAirbnbStatementDialog,
      uploadVRBOStatementDialog,
    } = this.state;

    const {
      accountId, isAirbnbAccount, isVRBOAccount,
    } = this.props;

    return (
      <>
        <AddBookedTransactionDialog
          isOpen={addBookedTransactionDialog}
          closeDialog={() => this.setState({
            addBookedTransactionDialog: false,
          })}
          onAddTransaction={this.setTransactionsStale}
          bankAccountId={accountId}
        />
        {uploadPmPdfDialog && (
          <UploadPropertyManagerPdfDialog
            isOpen
            closeDialog={() => this.setState({
              uploadPmPdfDialog: false,
            })}
            propertyManagerId={accountId}
            onBookUploadedTransactions={this.setTransactionsStale}
          />
        )}
        {uploadPMSpreadsheetDialog && (
          <UploadPropertyManagerSpreadsheet
            isOpen
            closeDialog={() => this.setState({
              uploadPMSpreadsheetDialog: false,
            })}
            propertyManagerId={accountId}
            onBookUploadedTransactions={this.setTransactionsStale}
          />
        )}
        {reconcileAccountDialog && (
          <ReconcileAccountDialog
            isOpen
            closeDialog={() => this.setState({
              reconcileAccountDialog: false,
            })}
            onReconcile={this.setTransactionsStale}
            accountId={accountId}
          />
        )}
        {uploadAirbnbStatementDialog && (
          <UploadAirbnbStatementDialog
            isOpen
            closeDialog={() => this.setState({
              uploadAirbnbStatementDialog: false,
            })}
            propertyManagerId={accountId}
            onBookUploadedTransactions={this.setTransactionsStale}
          />
        )}
        {uploadVRBOStatementDialog && (
          <UploadVRBOStatementDialog
            isOpen
            closeDialog={() => this.setState({
              uploadVRBOStatementDialog: false,
            })}
            propertyManagerId={accountId}
            onBookUploadedTransactions={this.setTransactionsStale}
          />
        )}
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Quicklinks
              </Typography>
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => this.setState({
                    addBookedTransactionDialog: true,
                  })}
                >
                  Add Transaction
                </Button>
              </Box>
              {!(isAirbnbAccount || isVRBOAccount) && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={() => this.setState({
                      uploadPmPdfDialog: true,
                    })}
                  >
                    Upload Statement (Beta)
                  </Button>
                </Box>
              )}
              {!(isAirbnbAccount || isVRBOAccount) && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={() => {
                      this.setState({
                        uploadPMSpreadsheetDialog: true,
                      });
                    }}
                  >
                    Spreadsheet Upload
                  </Button>
                </Box>
              )}
              {isAirbnbAccount && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={() => this.setState({
                      uploadAirbnbStatementDialog: true,
                    })}
                  >
                    Upload Airbnb Statement (Beta)
                  </Button>
                </Box>
              )}
              {isVRBOAccount && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={() => this.setState({
                      uploadVRBOStatementDialog: true,
                    })}
                  >
                    Upload VRBO Statement (Beta)
                  </Button>
                </Box>
              )}
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => this.setState({
                    reconcileAccountDialog: true,
                  })}
                >
                  Reconcile Account
                </Button>
              </Box>
            </Box>
          </CardBase>
        </Grid>
      </>
    );
  }
}

PMAccountQuicklinksCard.defaultProps = {};

PMAccountQuicklinksCard.propTypes = {
  markStale: PropTypes.func.isRequired,
  accountId: PropTypes.node.isRequired,
  isAirbnbAccount: PropTypes.bool.isRequired,
  isVRBOAccount: PropTypes.bool.isRequired,
};

export default PMAccountQuicklinksCard;
