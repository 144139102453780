import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
  get, find, patch, patchMultiple, remove,
} from '../feathersWrapper';
import client from '../feathers';

import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
  handleAutocompleteChange,
  handleKeyboardDatePickerChange,
  handleCheckboxChange,
  handleTransactionScopeChange,
} from '../functions/InputHandlers';

import {
  nameLabel,
  getBankingAccountTypeOptions,
  getPaymentAccountOptions,
} from './Autocomplete/Library';
import TransactionScope from './TransactionScope';
import { setInitialJournalScope, setJournalScope } from '../functions/JournalFunctions';

const styles = (theme) => ({
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: 'red',
  },
  deleteConfirmationButton: {
    color: 'red',
  },
});

class EditBankingAccountDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  getAccount = async () => {
    const { accountId } = this.props;
    const { organizationId } = this.context;
    const account = await get(this, 'accounts', accountId);

    account.type2Select = { name: account.type2, id: account.type2 };
    account.propertyIdSelect = account.propertyId ? account.property : null;

    const paymentAccountOptions = await getPaymentAccountOptions(this, { id: { $ne: account.id } });

    const journalLineQuery = { organizationId, accountId, $limit: 0 };
    const journalLinesResponse = await find(this, 'journal-lines', { query: journalLineQuery });

    const firstImportedTransactionQuery = {
      organizationId,
      accountId: account.id,
      pending: false,
      deleted: false,
      $limit: 1,
      $sort: {
        date: 1,
      },
    };

    const firstImportedResponse = await find(
      this,
      'yodlee-transactions',
      { query: firstImportedTransactionQuery },
    );

    const lastImportedTransactionQuery = {
      ...firstImportedTransactionQuery,
      $sort: {
        date: -1,
      },
    };

    const lastImportedResponse = await find(
      this,
      'yodlee-transactions',
      { query: lastImportedTransactionQuery },
    );

    const journal = {
      propertyId: account.propertyId,
      unitId: account.unitId,
      entityId: account.entityId,
    };
    await setInitialJournalScope(journal, this);

    this.setState({
      account,
      error: null,
      loading: false,
      submitting: false,
      confirmDelete: false,
      confirmMerge: false,
      confirmUnlink: false,
      blockMerge: true,
      totalTransactions: journalLinesResponse.total,
      firstImportedTransaction: firstImportedResponse.total
        ? firstImportedResponse.data[0].date : null,
      lastImportedTransaction: lastImportedResponse.total
        ? lastImportedResponse.data[0].date : null,
      paymentAccountOptions,
      newAccountIdSelect: null,
      removeUnbooked: false,
      moveUnbookedOnDelete: false,
      journal,
    });
  };

  handleMergeAccountSelect = (name, component) => async (event, value) => {
    if (value && value.id) {
      // if an account was selected, check for overlapping imported transaction range
      const { firstImportedTransaction, lastImportedTransaction } = this.state;
      const { organizationId } = this.context;

      if (firstImportedTransaction) {
        // if imported transactions exist in account being removed,
        // then check import range of destination account for overlap
        const existingTransactionQuery = {
          organizationId,
          accountId: value.id,
          pending: false,
          deleted: false,
          date: {
            $gte: firstImportedTransaction,
            $lte: lastImportedTransaction,
          },
          $limit: 1,
        };

        const existingTransactionResponse = await find(
          this,
          'yodlee-transactions',
          { query: existingTransactionQuery },
        );
        if (existingTransactionResponse.total) {
          this.setState({ blockMerge: true });
        } else {
          this.setState({ blockMerge: false });
        }
      } else {
        // no imported transactions in account being removed
        this.setState({ blockMerge: false });
      }
    } else {
      // destination account not selected
      this.setState({ blockMerge: true });
    }

    handleAutocompleteChange(name, component)(event, value);
  }

  editAccount = async (event) => {
    event.preventDefault();
    const {
      submitting, account, removeUnbooked, journal,
    } = this.state;

    const {
      onEditAccount, closeDialog, accountId,
    } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    if (account.inactive) {
      if (account.yodleeAccountId) {
        await remove(this, 'yodlee-accounts', account.yodleeAccountId);
        account.yodleeAccountId = null;
      }
      if (account.plaidAccountId) {
        await remove(this, 'plaid-accounts', account.plaidAccountId);
        account.plaidAccountId = null;
      }
    }

    const accountSubmit = { ...account, removeUnbooked };
    if (account.type2Select.id === 'Credit Card') {
      accountSubmit.type = 'Liability';
      accountSubmit.type2 = 'Credit Card';
    } else {
      accountSubmit.type = 'Asset';
      accountSubmit.type2 = 'Bank';
    }

    setJournalScope(journal);
    accountSubmit.propertyId = journal.propertyId;
    accountSubmit.entityId = journal.entityId;
    accountSubmit.unitId = journal.unitId;

    const accountsService = client.service('accounts');

    accountsService
      .patch(accountId, accountSubmit)
      .then(() => {
        closeDialog();
        onEditAccount();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  unlinkAccount = async (event) => {
    event.preventDefault();
    const {
      account, submitting,
    } = this.state;
    const { onEditAccount, closeDialog, accountId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    await patch(this, 'accounts', accountId, {
      yodleeAccountId: null,
      plaidAccountId: null,
    }, true)
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });

    if (account.yodleeAccountId) {
      await remove(this, 'yodlee-accounts', account.yodleeAccountId);
    }

    if (account.plaidAccountId) {
      await remove(this, 'plaid-accounts', account.plaidAccountId);
    }

    closeDialog();
    onEditAccount();
    this.setState({ loading: true });
  };

  deleteAccount = async (event) => {
    event.preventDefault();
    const {
      account, totalTransactions, newAccountIdSelect, moveUnbookedOnDelete, submitting,
    } = this.state;
    const { onEditAccount, closeDialog, accountId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    if (account.yodleeAccountId) {
      await remove(this, 'yodlee-accounts', account.yodleeAccountId);
    }

    if (account.plaidAccountId) {
      await remove(this, 'plaid-accounts', account.plaidAccountId);
    }

    if (totalTransactions > 0) {
      await patchMultiple(
        this,
        'journal-lines',
        {
          query: {
            accountId,
            organizationId: account.organizationId,
          },
        },
        { accountId: newAccountIdSelect.id },
      );
      // move over non-unbooked imported transactions
      await patchMultiple(
        this,
        'yodlee-transactions',
        {
          query: {
            $and: [
              { accountId: account.id },
              { organizationId: account.organizationId },
              {
                $or: [
                  { journalLineId: { $ne: null } },
                  { split: true },
                  { ignored: true },
                ],
              },
            ],
          },
        },
        { accountId: newAccountIdSelect.id },
      );

      if (moveUnbookedOnDelete) {
        // move over all imported transactions
        await patchMultiple(
          this,
          'yodlee-transactions',
          {
            query: {
              accountId: account.id,
              organizationId: account.organizationId,
            },
          },
          { accountId: newAccountIdSelect.id },
        );
      }
    }

    remove(this, 'accounts', accountId)
      .then(() => {
        closeDialog();
        onEditAccount();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const { adminLogin } = this.context;
    const {
      loading, error, account, removeUnbooked,
      confirmDelete, moveUnbookedOnDelete, confirmMerge,
      totalTransactions, paymentAccountOptions, newAccountIdSelect, confirmUnlink,
      journal, blockMerge,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={closeDialog}
        onEnter={this.getAccount}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="editAccountDialog"
      >
        {!loading && !confirmDelete && !confirmMerge && !confirmUnlink && (
          <form onSubmit={this.editAccount}>
            <DialogTitle id="alert-dialog-title">
              Edit Account
              {(account.plaidAccountId || account.yodleeAccountId) && (
                <IconButton
                  className={classes.deleteIconButton}
                  aria-label="unlink"
                  onClick={() => this.setState({ confirmUnlink: true })}
                >
                  <LinkOffIcon />
                </IconButton>
              )}
            </DialogTitle>
            <DialogContent>
              <TextField
                label="Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: account.name,
                  name: 'nested_account_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Autocomplete
                options={getBankingAccountTypeOptions}
                getOptionLabel={nameLabel}
                value={account.type2Select}
                onChange={handleAutocompleteChange(
                  'nested_account_type2Select',
                  this,
                )}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Account Type"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <TransactionScope
                journal={journal}
                transactionScopeChange={(newScopeValues) => {
                  handleTransactionScopeChange(journal, newScopeValues, this);
                }}
                label="Account"
              />
              {(account.plaidAccountId !== null || account.yodleeAccountId !== null) && (
                <KeyboardDatePicker
                  label="Transaction Import Start Date (optional)"
                  format="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                  value={account.importStartDate}
                  onChange={handleKeyboardDatePickerChange('nested_account_importStartDate', this)}
                  margin="dense"
                  fullWidth
                  clearable
                />
              )}
              <TextField
                label="Financial Institution Name (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: account.institutionName,
                  name: 'nested_account_institutionName',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Last Four Digits of Account Number (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: account.accountNumberMask,
                  name: 'nested_account_accountNumberMask',
                  onChange: handleTextFieldChange(this),
                }}
              />
              {(account.plaidAccountId !== null || account.yodleeAccountId !== null)
                && account.importStartDate && (
                <FormControl margin="none" fullWidth>
                  <FormControlLabel
                    control={<Checkbox checked={removeUnbooked} onChange={handleCheckboxChange(this)} name="removeUnbooked" />}
                    label="Remove unbooked imported transactions before the selected import start date"
                  />
                </FormControl>
              )}
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={account.inactive} onChange={handleCheckboxChange(this)} name="nested_account_inactive" />}
                  label="This account is inactive"
                />
                {account.inactive && (account.yodleeAccountId || account.plaidAccountId) && (
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="body2">
                      This account will be unlinked before being marked inactive
                    </Typography>
                  </Box>
                )}
              </FormControl>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Account
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              {totalTransactions === 0 && (
                <Button onClick={() => this.setState({ confirmDelete: true })} className={classes.deleteConfirmationButton} color="primary">
                  Delete
                </Button>
              )}
              {totalTransactions > 0 && (
                <Button onClick={() => this.setState({ confirmMerge: true })} className={classes.deleteConfirmationButton} color="primary">
                  Merge
                </Button>
              )}
            </DialogActions>
          </form>
        )}
        {!loading && confirmUnlink && (
          <form onSubmit={this.unlinkAccount}>
            <DialogTitle id="alert-dialog-title">Really Unlink Account?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`This account will no longer sync balance information or transactions 
                  from your financial institution.`}
              </DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" className={classes.deleteConfirmationButton}>
                Unlink Account
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <form onSubmit={this.deleteAccount}>
            <DialogTitle id="alert-dialog-title">Really Delete Account?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This account will be removed.
              </DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" className={classes.deleteConfirmationButton}>
                Delete Account
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmMerge && (
          <form onSubmit={this.deleteAccount}>
            <DialogTitle id="alert-dialog-title">Really Merge and Delete Account?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Merging and deleting this account will modify ${totalTransactions} 
                transactions currently booked to the account. 
                To proceed, please select an account. 
                Existing booked transaction lines assigned to the deleted account will be merged to the selected account.`}
              </DialogContentText>
              <Autocomplete
                options={paymentAccountOptions}
                getOptionLabel={nameLabel}
                value={newAccountIdSelect}
                onChange={this.handleMergeAccountSelect(
                  'newAccountIdSelect',
                  this,
                )}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Select Account"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={moveUnbookedOnDelete} onChange={handleCheckboxChange(this)} name="moveUnbookedOnDelete" />}
                  label="Also transfer unbooked imported transactions"
                />
              </FormControl>
              <Typography color="error">{error && error.message}</Typography>
              {newAccountIdSelect && blockMerge && (
                <Typography color="error">
                  These accounts contain imported transactions for an overlapping date range.
                  This can indicate potentially duplicate data.
                  Please contact us for assistance merging your accounts.
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                className={classes.deleteConfirmationButton}
                disabled={blockMerge && !adminLogin}
              >
                Merge and Delete Account
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

EditBankingAccountDialog.contextType = PersonContext;

EditBankingAccountDialog.defaultProps = {
  accountId: null,
};

EditBankingAccountDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditAccount: PropTypes.func.isRequired,
  accountId: PropTypes.node,
};

export default withStyles(styles)(EditBankingAccountDialog);
