import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ButtonBase } from '@material-ui/core';
import { get, find, create } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';
import {
  handleTextFieldChange,
  asyncHandleChange,
  handleKeyboardDatePickerChange,
  handleUploaderChange,
  handleNumberFormatChange,
  handleAutocompleteChange,
  handleTransactionScopeChange,
} from '../../functions/InputHandlers';

import {
  sumProperty,
} from '../../functions/SumFunctions';

import { nameLabel, getDepositAccountOptions } from '../Autocomplete/Library';

import {
  getManualBasicJournal,
  getDebitJournalLine,
} from '../SearchSelect/TransactionTypeOptions';

import {
  setJournalScope,
} from '../../functions/JournalFunctions';
import TransactionScope from '../TransactionScope';

const styles = (theme) => ({
  hideWidget: {
    display: 'none',
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddAirbnbDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const {
      yodleeTransaction, propertyId, unitId,
    } = this.props;
    const initialState = {
      loading: false,
      submitting: false,
      selectedProperty: null,
      error: null,
      displayNotesSection: false,
    };

    const journal = getManualBasicJournal();
    const airbnb = {
      grossEarnings: null,
      hostFee: null,
      adjustments: null,
    };
    journal.debitLines = journal.debitLines.concat([getDebitJournalLine()]);
    journal.type = 'Net Income';
    journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };

    if (yodleeTransaction) {
      journal.debitLines[0].accountIdSelect = yodleeTransaction.account;
      journal.debitLines[0].yodleeTransactionId = yodleeTransaction.id;
      journal.debitLines[0].debit = yodleeTransaction.amount;
      airbnb.grossEarnings = Math.round((yodleeTransaction.amount / 0.97) * 100) / 100;
      airbnb.hostFee = Math.round((yodleeTransaction.amount / 0.97) * 3) / 100;
      airbnb.adjustments = 0;
      journal.description = yodleeTransaction.description;
      journal.date = yodleeTransaction.date;
    }

    if (unitId) {
      const unit = await get(this, 'units', unitId);
      journal.unitIdSelect = unit;
      journal.propertyIdSelect = unit.property;
    } else if (propertyId) {
      journal.propertyIdSelect = await get(this, 'properties', propertyId);
    }

    initialState.journal = journal;
    initialState.airbnb = airbnb;

    initialState.depositAccountOptions = await getDepositAccountOptions(this);

    this.setState(initialState);
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return (
          <span className={classes.red}>
            {`(${value})`}
          </span>
        );
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return (
          <span className={classes.red}>
            {`(${value})`}
          </span>
        );
      }
    }
    return value;
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const {
      submitting, journal, airbnb,
    } = this.state;

    const { onAddTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    journal.creditLines[0].credit = airbnb.grossEarnings - airbnb.adjustments;
    const rentAccountData = await find(this, 'accounts', {
      query: {
        organizationId,
        default: 'rentRevenue',
      },
    });
    journal.creditLines[0].accountId = rentAccountData.data[0].id;
    journal.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;
    journal.debitLines[1].debit = airbnb.hostFee;
    const commissionsAccountData = await find(this, 'accounts', {
      query: {
        organizationId,
        default: 'commissionsExpense',
      },
    });
    journal.debitLines[1].accountId = commissionsAccountData.data[0].id;

    const debitAmount = sumProperty(journal.debitLines, 'debit');
    const creditAmount = sumProperty(journal.creditLines, 'credit');

    if (debitAmount !== creditAmount) {
      this.setState({ error: { message: 'Amount Mismatch: Please double check the net amount entered equals the net calculated amount shown below the components.' } });
      return;
    }

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    this.setState({ submitting: true });

    setJournalScope(journal);

    journal.organizationId = organizationId;
    journal.amount = debitAmount;
    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    create(this, 'journals', journal, true)
      .then((result) => {
        this.closeDialog();
        onAddTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { yodleeTransaction, classes } = this.props;
    const {
      loading,
      error,
      journal,
      airbnb,
      depositAccountOptions,
      displayNotesSection,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <form onSubmit={this.addTransaction}>
        <DialogTitle id="alert-dialog-title">
          Add Airbnb Income
        </DialogTitle>
        <DialogContent>
          {yodleeTransaction && (
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Transaction
              </FormLabel>
              <Typography>
                {moment(yodleeTransaction.date).format('M/D/YYYY')}
                <Box px={2} component="span">|</Box>
                <NumberFormat
                  displayType="text"
                  value={yodleeTransaction.amount}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                />
                <Box px={2} component="span">|</Box>
                {yodleeTransaction.account.name}
              </Typography>
            </FormControl>
          )}
          <KeyboardDatePicker
            label="Date"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            value={journal.date}
            onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
            margin="dense"
            fullWidth
            clearable
            required
          />
          <FormControl margin="dense" fullWidth>
            <InputLabel required>
              Net Amount
            </InputLabel>
            <NumberFormat
              value={journal.debitLines[0].debit}
              required
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              onValueChange={handleNumberFormatChange('nested_journal_debitLines_0_debit', this)}
              customInput={Input}
            />
          </FormControl>
          <Autocomplete
            options={depositAccountOptions}
            getOptionLabel={nameLabel}
            value={journal.debitLines[0].accountIdSelect}
            onChange={handleAutocompleteChange(
              'nested_journal_debitLines_0_accountIdSelect',
              this,
            )}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                margin="dense"
                label="Deposit Account"
                placeholder="Type to Search"
                fullWidth
                required
              />
            )}
          />
          <TransactionScope
            journal={journal}
            transactionScopeChange={(newScopeValues) => {
              handleTransactionScopeChange(journal, newScopeValues, this);
            }}
          />
          <TextField
            label="Description (optional)"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          <Box
            border={1}
            borderColor="grey.500"
            borderRadius="borderRadius"
            // bgcolor="common.white"
            padding={2}
            marginY={2}
          >
            <Typography variant="subtitle2">Components</Typography>
            <FormControl margin="dense" fullWidth>
              <InputLabel required>
                Gross Earnings
              </InputLabel>
              <NumberFormat
                value={airbnb.grossEarnings}
                required
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
                onValueChange={handleNumberFormatChange('nested_airbnb_grossEarnings', this)}
                customInput={Input}
              />
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <InputLabel required>
                Host Fee
              </InputLabel>
              <NumberFormat
                value={airbnb.hostFee}
                required
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
                onValueChange={handleNumberFormatChange('nested_airbnb_hostFee', this)}
                customInput={Input}
              />
              <FormHelperText>
                {'Airbnb\'s host fee is typically 3%'}
              </FormHelperText>
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <InputLabel required>
                Adjustments
              </InputLabel>
              <NumberFormat
                value={airbnb.adjustments}
                required
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
                onValueChange={handleNumberFormatChange('nested_airbnb_adjustments', this)}
                customInput={Input}
              />
              {journal.debitLines[0].debit
                !== airbnb.grossEarnings - airbnb.hostFee - airbnb.adjustments && (
                <FormHelperText>
                  <Typography color="error" variant="inherit">
                    <NumberFormat
                      displayType="text"
                      value={airbnb.grossEarnings - airbnb.hostFee - airbnb.adjustments}
                      thousandSeparator
                      prefix="Total: $"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </Typography>
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.editImageButton}
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                className={classes.editImageButton}
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Airbnb Income
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

AddAirbnbDialogContent.contextType = PersonContext;

AddAirbnbDialogContent.defaultProps = {
  yodleeTransaction: null,
  unitId: null,
  propertyId: null,
  bankAccountId: null,
};

AddAirbnbDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  unitId: PropTypes.node,
  propertyId: PropTypes.node,
  bankAccountId: PropTypes.node,
  yodleeTransaction: PropTypes.objectOf(PropTypes.any),
};

export default withStyles(styles)(AddAirbnbDialogContent);
