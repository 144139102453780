import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  outerContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: '20px',
  },
  background: {
    position: 'absolute',
    top: 0,
    backgroundImage: 'url(/experimental-layout-background.webp)',
    backgroundSize: '100% 100%',
    height: '62vh',
    width: '100%',
  },
  innerContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
});

export default function ExperimentalLayout({ children }) {
  const classes = useStyles();

  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.background} />

      <Box className={classes.innerContainer}>{children}</Box>
    </Box>
  );
}

ExperimentalLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
