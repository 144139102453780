import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';

import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { get, patch, remove } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleKeyboardDatePickerChange,
  handleTextFieldChange,
  handleAutocompleteChange,
  handleNumberFormatChange,
  handleTransactionScopeChange,
} from '../functions/InputHandlers';

import { nameLabel, getVehicleOptions } from './Autocomplete/Library';
import TransactionScope from './TransactionScope';
import { setInitialJournalScope, setJournalScope } from '../functions/JournalFunctions';

const styles = (theme) => ({
  datetime: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '4px',
  },
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: 'red',
  },
  deleteConfirmationButton: {
    color: 'red',
  },
});

class EditMileageDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  getData = async () => {
    const { mileageId } = this.props;
    const mileage = await get(this, 'mileage', mileageId);

    mileage.dateSelect = moment(mileage.date);

    mileage.vehicleIdSelect = mileage.vehicle;

    const vehicleOptions = await getVehicleOptions(this);
    const journal = {
      propertyId: mileage.propertyId,
      unitId: mileage.unitId,
      entityId: mileage.entityId,
    };
    await setInitialJournalScope(journal, this);

    this.setState({
      mileage,
      vehicleOptions,
      error: null,
      loading: false,
      submitting: false,
      confirmDelete: false,
      journal,
    });
  };

  editMileage = async (event) => {
    event.preventDefault();
    const {
      submitting, mileage, journal,
    } = this.state;

    const { onEdit, mileageId } = this.props;

    if (submitting) {
      return;
    }

    if (!mileage.date) {
      this.setState({ error: { message: 'Please enter a valid date for this trip' } });
      return;
    }

    if (!mileage.miles) {
      this.setState({ error: { message: 'Please enter the miles driven for this trip' } });
      return;
    }

    this.setState({ submitting: true });

    mileage.vehicleId = mileage.vehicleIdSelect.id;

    setJournalScope(journal);
    mileage.propertyId = journal.propertyId;
    mileage.unitId = journal.unitId;
    mileage.entityId = journal.entityId;

    patch(this, 'mileage', mileageId, mileage, true)
      .then((result) => {
        onEdit(result);
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  deleteMileage = () => {
    const { submitting } = this.state;
    const { onDelete, mileageId } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });
    remove(this, 'mileage', mileageId, true)
      .then(() => {
        onDelete();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const {
      loading,
      error,
      mileage,
      confirmDelete,
      vehicleOptions,
      // propertyOptions,
      journal,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.getData}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && !confirmDelete && (
          <form onSubmit={this.editMileage}>
            <DialogTitle id="alert-dialog-title">
              Edit Mileage
            </DialogTitle>
            <DialogContent>
              <KeyboardDatePicker
                className={classes.datetime}
                label="Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={mileage.date}
                onChange={handleKeyboardDatePickerChange('nested_mileage_date', this)}
                margin="dense"
                fullWidth
                clearable
                required
              />
              <Autocomplete
                options={vehicleOptions}
                getOptionLabel={nameLabel}
                value={mileage.vehicleIdSelect}
                onChange={handleAutocompleteChange(
                  'nested_mileage_vehicleIdSelect',
                  this,
                )}
                disableClearable
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Vehicle"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <FormControl margin="dense" fullWidth>
                <InputLabel required>
                  Miles
                </InputLabel>
                <NumberFormat
                  value={mileage.miles}
                  required
                  thousandSeparator
                  decimalScale={1}
                  onValueChange={handleNumberFormatChange('nested_mileage_miles', this)}
                  customInput={Input}
                />
              </FormControl>
              <TextField
                label="Trip purpose/description"
                fullWidth
                multiline
                rowsMax="5"
                required
                margin="dense"
                InputProps={{
                  value: mileage.description,
                  name: 'nested_mileage_description',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TransactionScope
                journal={journal}
                transactionScopeChange={(newScopeValues) => {
                  handleTransactionScopeChange(journal, newScopeValues, this);
                }}
                label="Mileage"
              />
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Mileage
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={() => this.setState({ confirmDelete: true })} className={classes.deleteConfirmationButton} color="primary">
                Delete
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <>
            <DialogTitle id="alert-dialog-title">Really Delete Mileage?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This trip will be removed.
              </DialogContentText>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={this.deleteMileage} className={classes.deleteConfirmationButton}>
                Delete Mileage
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
}

EditMileageDialog.contextType = PersonContext;

EditMileageDialog.defaultProps = {
  mileageId: null,
};

EditMileageDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  mileageId: PropTypes.node,
};

export default withStyles(styles)(EditMileageDialog);
