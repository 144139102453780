import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Button,
  TextField,
  Typography,
  Grid,
  CardContent,
  ButtonGroup,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';

import CardBase from '../../components/CardBase';
import { asyncHandleChange } from '../../functions/InputHandlers';

import {
  getBatchChangeTypeOptions,
  getExpenseAndRevenueBatchChangeTypeOptions,
  getBookedTransactionBatchChangeOptions,
} from '../../components/Autocomplete/Library';

import { find } from '../../feathersWrapper';

const styles = (theme) => ({
  background: {
    width: '100%',
    margin: 0,
    backgroundColor: theme.palette.white,
  },
});

class BatchChangeCard extends React.PureComponent {
  constructor(props) {
    super(props);

    switch (props.screen) {
      case 'Expense':
      case 'Revenue':
        this.state = {
          options: getExpenseAndRevenueBatchChangeTypeOptions,
        };
        break;
      case 'Register':
      case 'Property Manager':
        this.state = {
          options: getBookedTransactionBatchChangeOptions,
        };
        break;
      default:
        this.state = {
          options: getBatchChangeTypeOptions,
        };
        break;
    }
  }

  async componentDidMount() {
    await this.checkForAvailableBatchTypes();
  }

  handleOptionChange = (type) => async (event, value) => {
    const { updateBatchType } = this.props;
    await asyncHandleChange(type, value, this);
    const { batchType } = this.state;
    updateBatchType(batchType);
  };

  handleSubmit = () => {
    const {
      openBatchChangeImportedTransactionDialog,
      openBatchBookTransactionsDialog,
      openBatchBookFixedAssetPurchasesDialog,
      openBatchBookTransfersDialog,
      transactions,
    } = this.props;
    const { batchType } = this.state;

    if (!batchType) {
      this.setState({ error: 'Please select a batch option.' });
    } else if (transactions.length < 1) {
      this.setState({ error: 'Please select at least one transaction.' });
    } else if (batchType.name === 'Book Revenues' || batchType.name === 'Book Expenses') {
      openBatchBookTransactionsDialog();
    } else if (batchType.name === 'Book Fixed Asset Purchases') {
      openBatchBookFixedAssetPurchasesDialog();
    } else if (batchType.name === 'Book Transfers') {
      openBatchBookTransfersDialog();
    } else {
      openBatchChangeImportedTransactionDialog();
    }
  };

  handleCancel = () => {
    const { cancelBatchChange } = this.props;
    cancelBatchChange();
  };

  checkForAvailableBatchTypes = async () => {
    const { yodleeTransactions } = this.props;
    const { options } = this.state;

    const ignored = yodleeTransactions.find(
      (transaction) => transaction.ignored === true,
    );

    const booked = yodleeTransactions.find(
      (transaction) => transaction.journalId !== null,
    );

    let newOptions = options;

    if (!booked) {
      newOptions = newOptions.filter((option) => option.name !== 'Undo');
    }

    if (!ignored) {
      newOptions = newOptions.filter((option) => option.name !== 'Unignore');
    }

    const hasFixedAssets = await find(this, 'fixed-assets');
    if (!hasFixedAssets.data.length) {
      newOptions = newOptions.filter((option) => option.name !== 'Book Fixed Asset Purchases');
    }

    this.setState({ options: newOptions });
  };

  getBatchOptionButtonLabel = () => {
    const { batchType } = this.state;

    switch (batchType.name) {
      case 'Ignore':
        return 'Ignore';
      case 'Unignore':
        return 'Unignore';
      case 'Delete':
        return 'Delete';
      case 'Undo':
        return 'Undo';
      case 'Edit Scope':
      case 'Edit Account':
        return 'Edit';
      case 'Book Revenues':
      case 'Book Expenses':
      case 'Book Transfers':
      default:
        return 'Book';
    }
  };

  render() {
    const { classes, transactions } = this.props;
    const { error, batchType, options } = this.state;

    return (
      <Grid item xs={12}>
        <CardBase className={classes.background}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Batch Options
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={5}>
                <Autocomplete
                  options={options}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={this.handleOptionChange('nested_batchType')}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Batch Option"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              {transactions.length === 1 ? (
                <Grid
                  item
                  container
                  xs={4}
                  alignItems="flex-end"
                >
                  {`${transactions.length} Transaction Selected`}
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  xs={4}
                  alignItems="flex-end"
                >
                  {`${transactions.length} Transactions Selected`}
                </Grid>
              )}
              <Grid item container xs={3} alignItems="flex-end">
                <ButtonGroup fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<EditIcon />}
                    onClick={this.handleSubmit}
                    disableElevation
                  >
                    {!batchType ? 'Book' : this.getBatchOptionButtonLabel()}
                  </Button>
                  <Button
                    border="0"
                    color="primary"
                    variant="text"
                    fullWidth
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Typography color="error">{error}</Typography>
          </CardContent>
        </CardBase>
      </Grid>
    );
  }
}

BatchChangeCard.defaultProps = {
  openBatchChangeImportedTransactionDialog: () => {},
  openBatchBookTransactionsDialog: () => {},
  openBatchBookFixedAssetPurchasesDialog: () => {},
  openBatchBookTransfersDialog: () => {},
  screen: '',
};

BatchChangeCard.propTypes = {
  classes: PropTypes.object.isRequired,
  openBatchChangeImportedTransactionDialog: PropTypes.func,
  openBatchBookTransactionsDialog: PropTypes.func,
  openBatchBookFixedAssetPurchasesDialog: PropTypes.func,
  openBatchBookTransfersDialog: PropTypes.func,
  updateBatchType: PropTypes.func.isRequired,
  transactions: PropTypes.array.isRequired,
  cancelBatchChange: PropTypes.func.isRequired,
  yodleeTransactions: PropTypes.array.isRequired,
  screen: PropTypes.string,
};

export default withStyles(styles)(BatchChangeCard);
