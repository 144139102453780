import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';

import AddBookedTransactionDialog from '../../components/AddBookedTransactionDialog';

import CardBase from '../../components/CardBase';

class ExpensesQuicklinksCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      transactionType: null,
      addBookedTransactionDialog: false,
    };
  }

  setTransactionsStale = () => {
    const { markStale } = this.props;
    markStale('expensesGraph');
    markStale('journals');
  };

  render() {
    const {
      addBookedTransactionDialog, transactionType,
    } = this.state;

    return (
      <>
        <AddBookedTransactionDialog
          isOpen={addBookedTransactionDialog}
          type={transactionType}
          closeDialog={() => this.setState({
            addBookedTransactionDialog: false,
            transactionType: null,
          })}
          onAddTransaction={this.setTransactionsStale}
        />
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Quicklinks
              </Typography>
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => this.setState({
                    transactionType: 'Expense',
                    addBookedTransactionDialog: true,
                  })}
                >
                  Add Expense
                </Button>
              </Box>
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  component={RouterLink}
                  to="/organization/vendors"
                >
                  Manage Vendors
                </Button>
              </Box>
            </Box>
          </CardBase>
        </Grid>
      </>
    );
  }
}

ExpensesQuicklinksCard.defaultProps = {};

ExpensesQuicklinksCard.propTypes = {
  markStale: PropTypes.func.isRequired,
};

export default ExpensesQuicklinksCard;
