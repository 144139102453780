const DISCOUNTS = [
  {
    id: 'early_subscription_60',
    discountPercent: 60,
    duration: 2,
  },
  {
    id: 'early_subscription_40',
    discountPercent: 40,
    duration: 3,
  },
  {
    id: 'early_subscription_10',
    discountPercent: 10,
    duration: 3,
  },
  {
    id: 'trial_expired_60',
    discountPercent: 60,
    duration: 2,
  },
  {
    id: 'buy_now_50',
    discountPercent: 50,
    duration: 3,
  },
  {
    id: 'buy_now_75off',
    discountPercent: 75,
    duration: 3,
    headerText: 'Black Friday and Cyber Monday Deal!',
    footerText: 'Claim your discount and save 75%! Or try for 14 days free — no credit card required',
  },
  { id: 'annual_1080_off_1to3' },
  { id: 'annual_1800_off_4to10' },
  { id: 'annual_3240_off_11to20' },
  { id: 'annual_5760_off_unlimited' },
  { id: 'annual_270_off_1to3' },
  { id: 'annual_450_off_4to10' },
  { id: 'annual_810_off_11to20' },
  { id: 'annual_1440_off_unlimited' },
  { id: 'annual_2025_off_1to3' },
  { id: 'annual_3375_off_4to10' },
  { id: 'annual_6075_off_11to20' },
  { id: 'annual_10800_off_unlimited' },
];

export default DISCOUNTS;
