import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import history from '../history';
import { create, find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import {
  asyncHandleChange,
  asyncSetState,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import FinancialAccountLine2 from '../components/FinancialAccountLine2';
import ViewEnableSubportfoliosDialog from '../components/ViewEnableSubportfoliosDialog';

class NetOperatingIncomeBySubportfolio extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      viewEnableSubportfoliosDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
      additionalCriteria: [],
    };
  }

  async componentDidMount() {
    const { multiEntity } = this.context;

    if (!multiEntity) {
      this.setState({ viewEnableSubportfoliosDialog: true });
      return;
    }

    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();
      newFilter.startDateSelect = moment().startOf('year');

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis, organizationId } = this.context;
    const { filter } = this.state;

    if (!(filter.startDate && filter.endDate)) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const entities = await find(this, 'entities', {
      query: {
        organizationId,
        $limit: 100,
        $sort: {
          name: 1,
          id: 1,
        },
      },
    });

    const query = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });

    const accountsObject = {
      revenues: {
        accounts: [],
      },
      expenses: {
        accounts: [],
      },
      allIncluded: {
        accounts: [],
      },
      excluded: {
        accounts: [],
      },
      byId: {},
    };

    const accountsPromises = [
      create(this, 'reports', query)
        .then((result) => {
          /* eslint-disable no-param-reassign */
          result.forEach((account) => {
            account.display = account.netCredits !== 0;
            account.subaccounts = [];
            account.values = [];
            if (
              account.type2 === 'Investing Revenue'
                || account.type2 === 'Interest Expense'
                || account.type2 === 'Depreciation Expense'
            ) {
              accountsObject.excluded.accounts.push(account);
            } else if (account.type === 'Expense') {
              accountsObject.expenses.accounts.push(account);
              accountsObject.allIncluded.accounts.push(account);
            } else if (account.type === 'Revenue') {
              accountsObject.revenues.accounts.push(account);
              accountsObject.allIncluded.accounts.push(account);
            }
            accountsObject.byId[account.id] = account;
          });
          /* eslint-enable no-param-reassign */
          return result;
        }),
    ];

    entities.data.forEach((entity) => {
      accountsPromises.push(
        create(this, 'reports', {
          ...query,
          entityId: entity.id,
        }),
      );
    });

    accountsPromises.push(
      create(this, 'reports', {
        ...query,
        entityId: null,
      }),
    );

    const accountsResults = await Promise.all(accountsPromises);

    accountsResults[0].forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
      }
    });

    for (let i = 0; i < accountsResults.length; i += 1) {
      accountsResults[i].forEach((account, index) => {
        accountsResults[0][index].values.push(account.netCredits);
        if (account.netCredits !== 0) {
          accountsResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    const headers = entities.data.map((entity) => entity.name);
    headers.unshift('Total');
    headers.push('None Assigned');

    const additionalCriteriaArray = [{}];

    entities.data.forEach((entity) => {
      additionalCriteriaArray.push({
        entityId: entity.id,
      });
    });
    additionalCriteriaArray.push({
      entityId: 'None',
    });

    await asyncSetState({
      loading: false,
      headers,
      accountsObject,
      additionalCriteria: additionalCriteriaArray,
    }, this);
    return { success: true };
  };

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const {
      filter, accountsObject, headers, exportOptions,
    } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        headers,
        exportOptions,
        reportName: 'Net Operating Income By Sub-Porfolio',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'net-operating-income-by-subportfolio.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  }

  actionButtons = () => (
    [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Export', action: (() => this.setState({ downloadDialog: true })), class: 'export' },
    ]
  );

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      accountsObject,
      headers,
      filter,
      filterDialog,
      loading,
      viewEnableSubportfoliosDialog,
      downloadDialog,
      additionalCriteria,
    } = this.state;
    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Net Operating Income by Sub-Portfolio"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <ViewEnableSubportfoliosDialog
          isOpen={viewEnableSubportfoliosDialog}
          closeDialog={this.goToReports}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          dateRange
          required={['dateRange']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                  <FinancialAccountLine2
                    values={headers}
                    bold
                  />
                  <FinancialAccountLine2
                    label="Operating Revenues"
                    underline
                    overline
                    bold
                  />
                  {accountsObject.revenues.accounts.map((account) => (
                    <FinancialAccountLine2
                      key={account.id}
                      accountId={account.id}
                      label={account.name}
                      values={account.values}
                      display={account.display && !account.parentAccountId}
                      subaccounts={account.subaccounts}
                      indent={1}
                      filter={filter}
                      additionalCriteria={additionalCriteria}
                    />
                  ))}
                  <FinancialAccountLine2
                    label="Total Operating Revenues"
                    values={this.sumColumnValues(accountsObject.revenues.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine2
                    label="Operating Expenses"
                    underline
                    overline
                    bold
                  />
                    {accountsObject.expenses.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                  <FinancialAccountLine2
                    label="Total Operating Expenses"
                    values={this.sumColumnValues(accountsObject.expenses.accounts)}
                    underline
                    overline
                    bold
                    marginBottom
                  />
                  <FinancialAccountLine2
                    label="Net Operating Income"
                    values={this.sumColumnValues(accountsObject.allIncluded.accounts)}
                    underline
                    overline
                    bold
                  />
                </Box>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

NetOperatingIncomeBySubportfolio.contextType = PersonContext;

NetOperatingIncomeBySubportfolio.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NetOperatingIncomeBySubportfolio;
