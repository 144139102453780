import React from 'react';
import {
  Box,
} from '@material-ui/core';

import { find } from '../../feathersWrapper';

export function defaultAccountTypeSort(a, b) {
  const values = {
    Revenue: 1,
    Expense: 2,
    Asset: 3,
    Liability: 4,
    Equity: 5,
  };
  return values[a.type] - values[b.type];
}

export function subaccountSort(accounts) {
  const accountsObject = {};
  let currentAccount;

  // prep accounts for subaccount sort
  accounts.forEach((account) => {
    // eslint-disable-next-line no-param-reassign
    account.subAccounts = [];
    accountsObject[account.id] = account;
  });

  // build subaccounts
  accounts.forEach((account) => {
    if (account.parentAccountId) {
      accountsObject[account.parentAccountId].subAccounts.push(account);
    }
  });

  // eliminate all but parent accounts
  // eslint-disable-next-line no-param-reassign
  accounts = accounts.filter((account) => !account.parentAccountId);

  // add subaccounts into accounts array
  for (let i = 0; i < accounts.length; i += 1) {
    currentAccount = accounts[i];
    while (currentAccount.subAccounts.length) {
      i += 1;
      accounts.splice(i, 0, currentAccount.subAccounts.shift());
    }
  }

  return accounts;
}

export function nameAndAddressLabel(option) {
  let label = '';
  label += option.name ? option.name : '';
  if (label !== '+ Add New') {
    label += ` (${option.property ? option.property.address1 : ''})`;
  }
  return label;
}

export function nameLabel(option) {
  return option.name ? option.name : '';
}

export function institutionNameLabel(option) {
  return option.institutionName ? option.institutionName : '';
}

export function addressLabel(option) {
  return option.address1 ? option.address1 : '';
}

export const getFixedAssetOptions = async (component, addNew = false) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const assetsResult = await find(this, 'fixed-assets', { query });
  if (addNew) {
    assetsResult.data.unshift({
      name: '+ Add New',
      id: 'New Instance',
    });
  }
  return assetsResult.data;
};

export const getVendorOptions = async (component, addNew = false) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const vendorsResult = await find(this, 'vendors', { query });
  if (addNew) {
    vendorsResult.data.unshift({
      name: '+ Add New',
      id: 'New Instance',
    });
  }
  return vendorsResult.data;
};

export const getVehicleOptions = async (component, addNew = false) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const vehiclesResult = await find(this, 'vehicles', { query });
  if (addNew) {
    vehiclesResult.data.unshift({
      name: '+ Add New',
      id: 'New Instance',
    });
  }
  return vehiclesResult.data;
};

export const getPropertyManagerOptions = async (component, addNew = false) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const propertyManagersResult = await find(this, 'property-managers', { query });
  if (addNew) {
    propertyManagersResult.data.unshift({
      name: '+ Add New',
      id: 'New Instance',
    });
  }
  return propertyManagersResult.data;
};

export const getAccountOptions = async (component, additionalParams = {}) => {
  const { organizationId } = component.context;
  const accountsQuery = {
    $and: [
      { organizationId },
      { inactive: { $or: [null, false] }, ...additionalParams },
    ],
    $skip: 0,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };

  let accounts = [];
  let accountsResult;
  do {
    // eslint-disable-next-line no-await-in-loop
    accountsResult = await find(this, 'accounts', { query: accountsQuery });
    accounts = accounts.concat(accountsResult.data);
    accountsQuery.$skip += accountsQuery.$limit;
  } while (accountsResult.total > accounts.length);
  return accounts;
};

export const getGroupedAccountOptions = async (component, additionalParams = {}) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    ...additionalParams,
    $limit: 500,
    $skip: 0,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  let accounts = [];
  let accountsResult;

  // get all if over 500 accounts
  do {
    // eslint-disable-next-line no-await-in-loop
    accountsResult = await find(this, 'accounts', { query });
    accounts = accounts.concat(accountsResult.data);
    query.$skip += query.$limit;
  } while (accountsResult.total > accounts.length);

  // sort
  accounts.sort(defaultAccountTypeSort);
  accounts = subaccountSort(accounts);

  return accounts;
};

export const getPropertyOptions = async (component, additionalParams = {}) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: false,
    ...additionalParams,
    $limit: 500,
    $sort: {
      address1: 1,
      id: 1,
    },
  };
  const propertiesResult = await find(this, 'properties', { query });
  return propertiesResult.data;
};

export const getPropertyOptionsOrAll = async (component, additionalParams = {}) => {
  const data = await getPropertyOptions(component, additionalParams);
  data.unshift({ address1: 'No Property Assigned', id: 'None' });
  data.unshift({ address1: 'All Properties', id: 'All' });
  return data;
};

export const getUnitOptions = async (component, additionalParams = {}) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    ...additionalParams,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const collator = new Intl.Collator('en', { numeric: true });
  const result = await find(this, 'units', { query });
  return result.data.sort((a, b) => collator.compare(a.name, b.name));
};

export const getSubportfolioOptions = async (component, additionalParams = {}) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    ...additionalParams,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const result = await find(this, 'entities', { query });
  return result.data;
};

export const getFilteredUnitOptions = (unitOptions, propertyId, options = {}) => {
  const units = unitOptions.filter((unit) => unit.propertyId === propertyId);
  if (options.addAll) {
    units.unshift({ name: 'All Units', id: 'All' });
  }
  return units;
};

export const getPaymentAccountOptions = async (component, additionalParams = {}) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    type2: ['Bank', 'Credit Card', 'Property Management', 'Owner Funds', 'HELOC', 'Accounts Payable', 'Prepaid Expense'],
    ...additionalParams,
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const accountsResult = await find(this, 'accounts', { query });
  return accountsResult.data;
};

export const getDepositAccountOptions = async (component) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    type2: ['Bank', 'Property Management', 'Owner Funds', 'Accounts Receivable', 'Deferred Revenue'],
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const accountsResult = await find(this, 'accounts', { query });
  return accountsResult.data;
};

export const getBalanceSheetAccountOptions = async (component, additionalParams = {}) => {
  const { organizationId } = component.context;
  const accountsQuery = {
    organizationId,
    inactive: { $or: [null, false] },
    type: ['Asset', 'Liability', 'Equity'],
    ...additionalParams,
    $limit: 500,
    $skip: 0,
    $sort: {
      name: 1,
      id: 1,
    },
  };

  let accounts = [];
  let accountsResult;
  do {
    // eslint-disable-next-line no-await-in-loop
    accountsResult = await find(this, 'accounts', { query: accountsQuery });
    accounts = accounts.concat(accountsResult.data);
    accountsQuery.$skip += accountsQuery.$limit;
  } while (accountsResult.total > accounts.length);
  return accounts;
};

export const getImportAccountOptions = async (component) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    type2: ['Bank', 'Credit Card'],
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const accountsResult = await find(this, 'accounts', { query });
  accountsResult.data.unshift({
    name: 'All Accounts',
    id: 'All',
  });
  return accountsResult.data;
};

export const getUploadAccountOptions = async (component) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    inactive: { $or: [null, false] },
    type2: ['Bank', 'Credit Card'],
    $limit: 500,
    $sort: {
      name: 1,
      id: 1,
    },
  };
  const accountsResult = await find(this, 'accounts', { query });
  return accountsResult.data;
};

export const getPlaidItems = async (component) => {
  const { organizationId } = component.context;
  const query = {
    organizationId,
    deleted: false,
    auth: { $or: [null, false] },
    $limit: 500,
    $sort: {
      institutionName: 1,
      plaidItemId: 1,
    },
  };
  const plaidItemsResult = await find(this, 'plaid-items', { query });
  return plaidItemsResult.data;
};

export const getPaymentMethodOptions = [
  { name: 'ACH (Preferred)', id: 'ACH' },
  { name: 'Credit/Debit Card', id: 'Card' },
];

export const getPeriodOptions = [
  { name: 'Monthly', id: 'month' },
  { name: 'Quarterly', id: 'quarter' },
  { name: 'Annual', id: 'year' },
];

export const getDateRangeOptions = [
  { name: 'This Year to Date', id: 'This Year to Date' },
  { name: 'Last Year', id: 'Last Year' },
  { name: 'This Month to Date', id: 'This Month to Date' },
  { name: 'Last Month', id: 'Last Month' },
  { name: 'Custom', id: 'Custom' },
];

export const getDateOptions = [
  { name: 'Today', id: 'Today' },
  { name: 'Last Month', id: 'Last Month' },
  { name: 'Last Year', id: 'Last Year' },
  { name: 'Custom', id: 'Custom' },
];

export const getTransactionRuleTypeOptions = [
  { name: 'Expense', id: 'Expense' },
  { name: 'Revenue', id: 'Revenue' },
  { name: 'Fixed Asset Purchase', id: 'Fixed Asset Purchase' },
  { name: 'Split', id: 'Split' },
  { name: 'Transfer To', id: 'Transfer To' },
  { name: 'Transfer From', id: 'Transfer From' },
  { name: 'Ignore', id: 'Ignore' },
];

export const getAccountTypeOptions = [
  { name: 'Revenue', id: 'Revenue' },
  { name: 'Expense', id: 'Expense' },
  { name: 'Asset', id: 'Asset' },
  { name: 'Liability', id: 'Liability' },
  { name: 'Equity', id: 'Equity' },
];

export const getAssetType2Options = [
  { name: 'Fixed Asset', id: 'Fixed Asset' },
  { name: 'Accounts Receivable', cashType: 'Revenue', id: 'Accounts Receivable' },
  { name: 'Prepaid Expense', cashType: 'Expense', id: 'Prepaid Expense' },
  { name: 'Other', id: 'Other' },
];

export const getLiabilityType2Options = [
  { name: 'Sales and Use Tax', id: 'Sales and Use Tax' },
  { name: 'Accounts Payable', cashType: 'Expense', id: 'Accounts Payable' },
  { name: 'Deferred Revenue', cashType: 'Revenue', id: 'Deferred Revenue' },
  { name: 'Other', id: 'Other' },
];

export const getRevenueType2Options = [
  { name: 'Operating Revenue', id: 'Operating Revenue' },
  { name: 'Investing Revenue', id: 'Investing Revenue' },
];

const stateCodes = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];
export const getStateCodeOptions = stateCodes.map((code) => ({ name: code, id: code }));

export const getBankingAccountTypeOptions = [
  { name: 'Bank', id: 'Bank' },
  { name: 'Credit Card', id: 'Credit Card' },
];

export const getOtherFixedAssetTypeOptions = [
  {
    name: 'Appliances',
    id: 'Appliances',
    lifespan: 5,
  },
  {
    name: 'Carpets and Furniture',
    id: 'Carpets and Furniture',
    lifespan: 5,
  },
  {
    name: 'Residential Additions and Improvements',
    id: 'Residential Improvements',
    lifespan: 27.5,
  },
  {
    name: 'Commercial Additions and Improvements',
    id: 'Commercial Improvements',
    lifespan: 39,
  },
  {
    name: 'Land Improvements',
    id: 'Land Improvements',
    lifespan: 15,
  },
  {
    name: 'Loan Closing Costs',
    id: 'Loan Closing Costs',
    lifespan: null,
  },
  {
    name: 'Other Fixed Assets',
    id: 'Other Fixed Assets',
    lifespan: 7,
  },
];

export const getImportedTransactionStatusOptions = [
  { name: 'All Transactions', id: 'All' },
  { name: 'Unbooked', id: 'Unbooked' },
  { name: 'Booked', id: 'Booked' },
  { name: 'Split', id: 'Split' },
  { name: 'Ignored', id: 'Ignored' },
];

export const getRecieptFilterOptions = [
  { name: 'All Transactions', id: 'All' },
  { name: 'Transactions With Receipts', id: 'Transactions With Receipts' },
  { name: 'Transactions Without Receipts', id: 'Transactions Without Receipts' },
];

export const getReconciliationStatusOptions = [
  { name: 'All Transactions', id: 'All' },
  { name: 'Reconciled Transactions', id: 'Reconciled Transactions' },
  { name: 'Unreconciled Transactions', id: 'Unreconciled Transactions' },
];

export const getFilteredAccountOptions = (accountOptions, type) => (
  accountOptions.filter((account) => account.type === type)
);

export const getScopeOptions = (component, scopeOptionArray) => {
  const { multiEntity } = component.context;
  if (!multiEntity) {
    return scopeOptionArray.filter((option) => option !== 'Sub-Portfolio');
  }
  return scopeOptionArray;
};

export const getTransactionFileTypes = [
  { name: 'Open Financial Exchange (.ofx)', id: 'OFX' },
  { name: 'Quickbooks (.qbo)', id: 'Quickbooks' },
  { name: 'Quicken (.qfx)', id: 'Quicken' },
  { name: 'Excel (.xlsx)', id: 'Excel' },
  { name: 'Comma Separated Values (.csv)', id: 'CSV' },
];

export const getTransactionTypeOptions = [
  { name: 'All Transactions', id: 'All' },
  { name: 'Revenue', id: 'Revenue' },
  { name: 'Expense', id: 'Expense' },
  { name: 'Payment', id: 'Payment' },
  { name: 'Net Income', id: 'Net Income' },
  { name: 'Loan Payment', id: 'Loan Payment' },
  { name: 'Owner Distribution', id: 'Distribution' },
  { name: 'Owner Contribution', id: 'Contribution' },
  { name: 'Fixed Asset Purchase', id: 'Fixed Asset Purchase' },
  { name: 'Transfer Between Accounts', id: 'Transfer' },
  { name: 'Security Deposit', id: 'Security Deposit' },
  { name: 'Security Deposit Out', id: 'Security Deposit Out' },
  { name: 'Credit Card Payment', id: 'Credit Card Payment' },
  { name: 'Refund Received', id: 'Refund' },
  { name: 'Refund Issued', id: 'Refund Issued' },
  { name: 'Depreciation', id: 'Depreciation' },
  { name: 'Escrow Expense', id: 'Escrow Expense' },
  { name: 'Manual Journal', id: 'Manual Journal' },
];

export const getTransactionScopeOptions = (component) => {
  const { multiEntity, organizationName } = component.context;
  const options = [];
  options.push({ name: `${organizationName} Portfolio`, id: 'Portfolio' });
  if (multiEntity) {
    options.push({ name: 'Sub-Portfolio', id: 'Sub-Portfolio' });
  }
  options.push({ name: 'Property/Unit', id: 'Property/Unit' });
  return options;
};

export const getBatchChangeTypeOptions = [
  { name: 'Book Expenses', id: 'Book Expenses' },
  { name: 'Book Revenues', id: 'Book Revenues' },
  { name: 'Book Transfers', id: 'Book Transfers' },
  { name: 'Book Fixed Asset Purchases', id: 'Book Fixed Asset Purchases' },
  { name: 'Ignore', id: 'Ignore' },
  { name: 'Unignore', id: 'Unignore' },
  { name: 'Undo', id: 'Undo' },
  { name: 'Delete', id: 'Delete' },
];

export const getExpenseAndRevenueBatchChangeTypeOptions = [
  { name: 'Edit Account', id: 'Edit Account' },
  { name: 'Edit Scope', id: 'Edit Scope' },
  { name: 'Delete', id: 'Journal Delete' },
];

export const getBookedTransactionBatchChangeOptions = [
  { name: 'Edit Scope', id: 'Edit Scope' },
  { name: 'Delete', id: 'Journal Delete' },
];

export const padSubaccounts = (account) => {
  if (!account.parentAccountId) {
    return account.name;
  }
  return (
    <Box marginLeft={2}>
      {account.name}
    </Box>
  );
};

// warning: adding additional loan types also requires adding to the
// loanTransactionsSinceBalanceSummary report on the API
export const getLoanAccountTypeOptions = [
  { name: 'Mortgage', id: 'Mortgage' },
  { name: 'HELOC', id: 'HELOC' },
  { name: 'Hard Money Loan', id: 'Hard Money Loan' },
  { name: 'Other', id: 'Loan' },
];

export const RECURRING_TRANSACTION_DAY_OPTIONS = [
  { name: '1st', id: 1 },
  { name: '2nd', id: 2 },
  { name: '3rd', id: 3 },
  { name: '4th', id: 4 },
  { name: '5th', id: 5 },
  { name: '6th', id: 6 },
  { name: '7th', id: 7 },
  { name: '8th', id: 8 },
  { name: '9th', id: 9 },
  { name: '10th', id: 10 },
  { name: '11th', id: 11 },
  { name: '12th', id: 12 },
  { name: '13th', id: 13 },
  { name: '14th', id: 14 },
  { name: '15th', id: 15 },
  { name: '16th', id: 16 },
  { name: '17th', id: 17 },
  { name: '18th', id: 18 },
  { name: '19th', id: 19 },
  { name: '20th', id: 20 },
  { name: '21st', id: 21 },
  { name: '22nd', id: 22 },
  { name: '23rd', id: 23 },
  { name: '24th', id: 24 },
  { name: '25th', id: 25 },
  { name: '26th', id: 26 },
  { name: '27th', id: 27 },
  { name: '28th', id: 28 },
  { name: 'Last day of the month', id: 32 },
];

export const RECURRING_TRANSACTION_FREQUENCY_OPTIONS = [
  { name: 'Monthly', id: 'Monthly' },
  { name: 'Quarterly', id: 'Quarterly' },
  { name: 'Annually', id: 'Annually' },
];
export const reportsOptions = [
  { name: 'Net Income (P&L)', id: 'net-income' },
  { name: 'Net Income By Property', id: 'net-income-by-property' },
  { name: 'Net Income by Sub-Portfolio', id: 'net-income-by-subportfolio' },
  { name: 'Net Income by Unit', id: 'net-income-by-unit' },
  { name: 'Net Income By Period', id: 'net-income-by-period' },
  { name: 'Owner Statement', id: 'owner-statement' },
  { name: 'Owner Statement By Property', id: 'owner-statement-by-property' },
  { name: 'Rent Roll', id: 'rent-roll' },
  { name: 'Portfolio Value By Property', id: 'portfolio-value-by-property' },
  { name: 'Balance Sheet', id: 'balance-sheet' },
  { name: 'Balance Sheet By Property', id: 'balance-sheet-by-property' },
  { name: 'Balance Sheet By Sub-Portfolio', id: 'balance-sheet-by-subportfolio' },
  { name: 'Balance Sheet By Period', id: 'balance-sheet-by-period' },
  { name: 'Fixed Assets Schedule', id: 'fixed-assets' },
  { name: 'Operating Cash Flow', id: 'operating-cash-flow' },
  { name: 'Operating Cash Flow By Property', id: 'operating-cash-flow-by-property' },
  { name: 'Operating Cash Flow By Sub-Portfolio', id: 'operating-cash-flow-by-subportfolio' },
  { name: 'Operating Cash Flow By Unit', id: 'operating-cash-flow-by-unit' },
  { name: 'Operating Cash Flow By Period', id: 'operating-cash-flow-by-period' },
  { name: 'Cash Flow Statement', id: 'cash-flows' },
  { name: 'Cash Flows By Property', id: 'cash-flows-by-property' },
  { name: 'Tax Packet Export', id: 'tax-packet' },
  { name: 'Tax Review', id: 'tax-review' },
  { name: 'Schedule E', id: 'schedule-e' },
  { name: 'Form 8825', id: 'form-8825' },
  { name: 'Expenses By Vendor', id: 'expenses-by-vendor' },
  { name: 'Net Operating Income (NOI)', id: 'net-operating-income' },
  { name: 'Net Operating Income By Property', id: 'net-operating-income-by-property' },
  { name: 'Net Operating Income By Sub-Portfolio', id: 'net-operating-income-by-subportfolio' },
  { name: 'Net Operating Income By Unit', id: 'net-operating-income-by-unit' },
  { name: 'Net Operating Income By Period', id: 'net-operating-income-by-period' },
  { name: 'Cash on Cash', id: 'cash-on-cash' },
  { name: 'Cap Rate', id: 'cap-rate' },
  { name: 'Operating Expense Ratio', id: 'operating-expense-ratio' },
  { name: 'Trial Balance', id: 'trial-balance' },
  { name: 'Account Register', id: 'account-register' },
  { name: 'Security Deposits Held', id: 'security-deposits-held' },
  { name: 'General Ledger Export', id: 'general-ledger' },
  { name: 'Mileage By Property', id: 'mileage-by-property' },
  { name: 'Attachments Export', id: 'attachments-export' },
];

export const reportExportFormats = [
  { name: '.xlsx (spreadsheet)', id: '.xlsx' },
  { name: '.pdf', id: '.pdf' },
];

export const reportDeliveryFormats = [
  { name: 'Download', id: 'download' },
  { name: 'Email', id: 'email' },
];
