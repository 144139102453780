export const generateSafeRegExp = (description) => {
  // escape RegExp special characters
  let regExpString = description.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  // use % as a wildcard (matches database wildcard syntax)
  regExpString = regExpString.replace(/[%]/g, '(.)*');
  // use case insensitive expressions
  return new RegExp(regExpString, 'i');
};

export const testMatchingRule = (ruleDescription, transactionDescription) => {
  const ruleRegExp = generateSafeRegExp(ruleDescription);
  return ruleRegExp.test(transactionDescription);
};
