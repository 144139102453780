import React, { useContext, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from 'recharts';

import { axisLabels, monthAxisLabels, tooltipFormatter } from '../../functions/ChartFunctions';

import { PersonContext } from '../../contexts/PersonContext';

import { create } from '../../feathersWrapper';

import { sumProperty } from '../../functions/SumFunctions';

export default function CashflowGraph({
  stale,
  setStale,
  view,
  id,
  filter,
  tabValue,
}) {
  CashflowGraph.defaultProps = {
    view: '',
    id: null,
  };
  CashflowGraph.propTypes = {
    stale: PropTypes.bool.isRequired,
    setStale: PropTypes.func.isRequired,
    view: PropTypes.string,
    id: PropTypes.number,
    filter: PropTypes.objectOf(PropTypes.string).isRequired,
    tabValue: PropTypes.string.isRequired,
  };

  const { organizationId, basis } = useContext(PersonContext);
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [bargraphData, setBargraphData] = useState(null);

  const getData = async () => {
    const query = { organizationId, basis };

    if (view === 'property') {
      query.propertyId = id;
    } else if (view === 'unit') {
      query.unitId = id;
    } else if (view === 'subportfolio') {
      query.entityId = id;
    }

    const reportData = await create(this, 'reports', {
      reportName: 'cashflowCard',
      startDate: filter.startDate,
      endDate: filter.endDate,
      ...query,
    });
    setBargraphData(reportData);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (loading) {
        await getData();
        setLoading(false);
      }
      if (stale) {
        await getData();
        setStale(false);
      }
    };
    fetchData();
  });

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3} minHeight="346px" />
    );
  }

  return (
    <>
      <Typography variant="h4">
        <NumberFormat
          displayType="text"
          value={
            tabValue === 'Cashflow'
              ? sumProperty(bargraphData, 'cash')
              : sumProperty(bargraphData, 'income')
          }
          thousandSeparator
          prefix="$"
          decimalScale={0}
          fixedDecimalScale
        />
        <Box
          color={theme.palette.action.active}
          marginLeft={1}
          component="span"
        >
          <Typography variant="overline" component="span">
            {filter.filterText}
          </Typography>
        </Box>
      </Typography>
      <Box pb={2}>
        <ResponsiveContainer width="100%" height={275}>
          <ComposedChart
            data={bargraphData}
            margin={{
              top: 30,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              tickFormatter={monthAxisLabels}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={axisLabels}
              domain={['auto', 1000]}
            />
            <Tooltip
              formatter={tooltipFormatter}
            />
            <Bar
              name={tabValue === 'Cashflow' ? 'Operating Cashflow' : 'Net Operating Income'}
              dataKey={tabValue === 'Cashflow' ? 'cash' : 'income'}
              stackId="x"
              barSize={50}
              isAnimationActive={false}
            >
              {
              bargraphData.map((entry) => {
                let color;
                if (tabValue === 'Cashflow') {
                  color = entry.cash < 0 ? '#4F7CAC' : '#82CA9D';
                } else {
                  color = entry.income < 0 ? '#4F7CAC' : '#82CA9D';
                }
                return (
                  <Cell key={`cell-${entry.name}`} fill={color} />
                );
              })
            }
            </Bar>
            <ReferenceLine y={0} stroke="#000" />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}
