import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { PersonContext } from '../contexts/PersonContext';

const styles = (theme) => ({
  truncateOneLine: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  attachmentButton: {
    padding: 0,
    minWidth: 0,
    color: theme.palette.action.active,
  },
});

class BookedTransactionsList extends React.PureComponent {
  splitAccount = (journal) => {
    if (journal.accountLine.type === 'credit') {
      if (journal.debitLines.length === 1) {
        return journal.debitLines[0].account.name;
      }
      return 'Split';
    }
    if (journal.creditLines.length === 1) {
      return journal.creditLines[0].account.name;
    }
    return 'Split';
  };

  getScope = (journal) => {
    const { organizationName } = this.context;
    if (!journal.entityId && !journal.propertyId) {
      return `${organizationName} Portfolio`;
    }
    if (journal.entityId && !journal.propertyId) {
      return journal.entity.name;
    }
    if (journal.propertyId) {
      return journal.property.address1;
    }
    return null;
  };

  getRowData = (journal, field) => {
    const { classes, onClickViewButton } = this.props;

    let bodyText;
    let captionText;

    const { header, cell, button } = field;

    if (cell) {
      const { body, caption } = cell;
      if (body) {
        switch (body) {
          case 'date':
            bodyText = moment(journal.date).format('M/D/YYYY');
            break;
          case 'type':
            bodyText = journal.type;
            break;
          case 'description':
            bodyText = journal.description ? journal.description.substring(0, 75) : '---';
            break;
          case 'amount':
            bodyText = journal.amount;
            break;
          case 'scope':
            bodyText = this.getScope(journal);
            break;
          default:
            return null;
        }
      }
      if (caption) {
        switch (caption) {
          case 'scope':
            captionText = this.getScope(journal);
            break;
          case 'vendor':
            captionText = journal.vendor ? `Vendor: ${journal.vendor.name}` : null;
            break;
          default:
            return null;
        }
      }
    }

    if (button) {
      if (button.action === 'viewAttachment' && journal.attachmentURL) {
        return (
          <TableCell>
            <Button
              className={classes.attachmentButton}
              aria-label="attachments"
              href={journal.attachmentURL.replace('~1/', '~1/nth/0/')}
              target="_blank"

            >
              <AttachFileIcon fontSize="small" />
            </Button>
          </TableCell>
        );
      } if (button.action === 'viewAttachment' && !journal.attachmentURL) {
        return (
          <TableCell />
        );
      }
      return (
        <TableCell align="right">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => onClickViewButton(journal.id)}
          >
            {button.label}
          </Button>
        </TableCell>
      );
    }

    return (
      <>
        {bodyText !== undefined && (
          <TableCell align={
            (header === 'Amount' || header === 'Action')
              ? 'right'
              : 'left'
          }
          >
            {header === 'Amount' ? (
              <NumberFormat
                displayType="text"
                value={bodyText >= 0 ? bodyText : bodyText * -1}
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
              />
            ) : (
              <Typography
                className={classes.truncateOneLine}
                variant="body2"
              >
                {bodyText}
              </Typography>
            )}
            {captionText && (
              <Typography
                className={classes.truncateOneLine}
                variant="caption"
              >
                {captionText}
              </Typography>
            )}
          </TableCell>
        )}
      </>
    );
  };

  render() {
    const {
      fields,
      journal,
    } = this.props;
    return (
      <>
        {fields.map((field) => (
          <React.Fragment key={field.header + journal.id}>
            {this.getRowData(journal, field)}
          </React.Fragment>
        ))}
      </>
    );
  }
}

BookedTransactionsList.contextType = PersonContext;

BookedTransactionsList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  journal: PropTypes.objectOf(PropTypes.any).isRequired,
  onClickViewButton: PropTypes.func.isRequired,
};

export default withStyles(styles)(BookedTransactionsList);
