import React, { useContext, useEffect, useState } from 'react';
import { Card, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import AddPropertyDialog from '~/components/AddPropertyDialog';
import AddSubportfolioDialog from '~/components/AddSubportfolioDialog';
import VideoPlayerDialog from '~/components/VideoPlayerDialog';
import { PersonContext } from '~/contexts/PersonContext';
import { create, find, patch } from '~/feathersFunctionalWrapper';

import PartnerImported from './PartnerImported';
import PropertiesOverview from './PropertiesOverview';
import PropertyAdded from './PropertyAdded';
import PropertyValues from './PropertyValues';
import SubportfolioAdded from './SubportfolioAdded';
import SubportfoliosOverview from './SubportfoliosOverview';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: '600px',
    width: '100%',
  },
}));

export default function PropertiesSetup({ refreshProperties }) {
  const { organizationId, partnerName, multiEntity, setOrganizationId } = useContext(PersonContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isAddPropertyVisible, setIsAddPropertyVisible] = useState(false);
  const [isAddSubportfolioVisible, setIsAddSubportfolioVisible] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [state, setState] = useState({});

  const classes = useStyles();

  useEffect(() => {
    async function getData() {
      const query = { organizationId };
      const properties = await find('properties', { query });
      if (partnerName) {
        const unitCount = await create('reports', { organizationId, reportName: 'unitCount' });
        setState((prevState) => ({
          ...prevState,
          unitCount,
          key: 'partnerImported',
          properties: properties.data,
        }));
      } else if (properties.total > 0) {
        setState((prevState) => ({
          ...prevState,
          propertyCount: properties.total,
          key: 'propertyAdded',
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          key: 'propertiesOverview',
        }));
      }
      setIsLoading(false);
    }
    getData();
  }, [organizationId, partnerName]);

  const onAddProperty = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'onboardingAddProperty',
      organizationId,
    });

    refreshProperties();
    const query = { organizationId };
    const properties = await find('properties', { query });

    setState({
      ...state,
      key: 'propertyAdded',
      propertyCount: properties.total,
      properties: properties.data,
    });
  };

  const onAddSubportfolio = async () => {
    if (!multiEntity) {
      const patchData = {
        multiEntity: true,
      };
      await patch('organizations', organizationId, patchData);
      await setOrganizationId(organizationId);
    }
    const query = { organizationId };
    const subportfolios = await find('entities', { query });

    setState({
      ...state,
      key: 'subportfolioAdded',
      subportfolioCount: subportfolios.total,
    });
  };

  const getDialogContent = (key) => {
    switch (key) {
      case 'propertiesOverview':
        return (
          <PropertiesOverview
            openVideoDialog={() => setIsVideoVisible(true)}
            openAddPropertyDialog={() => setIsAddPropertyVisible(true)}
          />
        );
      case 'propertyAdded':
        return (
          <PropertyAdded
            propertyCount={state.propertyCount}
            changeToSubportfoliosOverview={() => setState({ ...state, key: 'subportfoliosOverview' })}
            openAddPropertyDialog={() => setIsAddPropertyVisible(true)}
          />
        );
      case 'subportfoliosOverview':
        return <SubportfoliosOverview openAddSubportfolioDialog={() => setIsAddSubportfolioVisible(true)} />;
      case 'subportfolioAdded':
        return (
          <SubportfolioAdded
            subportfolioCount={state.subportfolioCount}
            setAddSubportfolioDialog={() => setIsAddSubportfolioVisible(true)}
          />
        );
      case 'partnerImported':
        return (
          <PartnerImported
            propertyCount={state.properties.length}
            unitCount={state.unitCount}
            partnerName={partnerName}
            changeToPropertyValues={() => setState({ ...state, key: 'propertyValues' })}
            openVideoDialog={() => setIsVideoVisible(true)}
          />
        );
      case 'propertyValues':
        return (
          <PropertyValues
            properties={state.properties}
            changeToSubportfoliosOverview={() => setState({ ...state, key: 'subportfoliosOverview' })}
            setProperties={(properties) => setState({ ...state, properties })}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return null;
  }

  if (isAddPropertyVisible) {
    return (
      <AddPropertyDialog isOpen closeDialog={() => setIsAddPropertyVisible(false)} onAddProperty={onAddProperty} />
    );
  }

  if (isAddSubportfolioVisible) {
    return <AddSubportfolioDialog closeDialog={() => setIsAddSubportfolioVisible(false)} onAdd={onAddSubportfolio} />;
  }

  return (
    <>
      <VideoPlayerDialog
        open={isVideoVisible}
        onClose={() => {
          setIsVideoVisible(false);
        }}
        source="https://www.youtube.com/embed/BpbnZBbruhk?rel=0&amp;wmode=opaque"
        allowFullScreen={false}
      />
      <Card scroll="body" className={classes.card}>
        {getDialogContent(state.key)}
      </Card>
    </>
  );
}

PropertiesSetup.propTypes = {
  refreshProperties: PropTypes.func.isRequired,
};
