import Cookies from 'js-cookie';
import {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IMPACT_COOKIE_KEY } from '~/constants';
import { STATUSES as PASSWORD_STATUSES } from '~/constants/password-requirements';
import { PersonContext } from '~/contexts/PersonContext';
import client from '~/feathers';
import { create, patch } from '~/feathersFunctionalWrapper';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import usePasswordRequirements from '~/hooks/usePasswordRequirements';

function getImpactClickId() {
  const impactCookie = Cookies.get(IMPACT_COOKIE_KEY);
  if (!impactCookie) return null;

  const impactCookieDecoded = decodeURIComponent(impactCookie);
  if (impactCookieDecoded.split('|').length <= 4) return null;

  return impactCookieDecoded.split('|')[3];
}

export default function useRegisterForm({
  hasPasswordField = true,
  setEmailAlreadyExists,
  setError,
}) {
  const recaptchaRef = useRef();
  const tracking = useSegmentTrack();
  const { setPerson, setContextState, triggerSnackbar } = useContext(PersonContext);
  const [registerForm, setRegisterForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
  });
  const [submitting, setSubmitting] = useState(false);

  const checkForUniqueEmail = useCallback(async (event) => {
    const email = event.target.value.trim().toLowerCase();
    setRegisterForm((prev) => ({ ...prev, email }));

    const emailPattern = /^[\w._%+-]+@([\w-]+\.)+[\w-]+$/;
    if (!emailPattern.test(email)) {
      setEmailAlreadyExists(false);
      return;
    }

    try {
      await create('authManagement', {
        action: 'checkUnique',
        value: { email },
      });
      setEmailAlreadyExists(false);
    } catch (err) {
      setEmailAlreadyExists(true);
    }
  }, [registerForm, setEmailAlreadyExists]);

  const [isPasswordSubmitted, setIsPasswordSubmitted] = useState(false);
  const passwordRequirements = usePasswordRequirements(registerForm.password, isPasswordSubmitted);
  const isPasswordValid = useMemo(
    () => Object.values(passwordRequirements).every((status) => status === PASSWORD_STATUSES.MET),
    [passwordRequirements],
  );

  const registerSubmit = useCallback(async (event) => {
    event.preventDefault();
    if (submitting) return;

    setIsPasswordSubmitted(true);

    if (hasPasswordField && !isPasswordValid) {
      triggerSnackbar({
        horizontal: 'center',
        message: 'Your password must meet the requirements.',
        type: 'warning',
      });
      return;
    }

    try {
      setSubmitting(true);
      const recaptchaToken = await recaptchaRef.current.executeAsync();

      const registerResponse = await create(
        'register',
        {
          ...registerForm,
          email: registerForm.email.trim().toLowerCase(),
          recaptchaToken,
        },
      );

      // Track registration
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'newRegistrationComplete',
        email: registerForm.email,
        organizationId: registerResponse.organizationId,
        userId: registerResponse.id,
      });

      tracking(
        'start_for_free clicked',
        { location: 'Sign Up Page' },
        {
          groupId: registerResponse.organizationId,
          partnerName: null,
          showWelcome: true,
        },
      );

      // Authenticate user
      const authResponse = await client.authenticate({
        strategy: 'local',
        email: registerForm.email,
        password: registerForm.password,
      });

      setContextState({ sessionSource: 'register' });
      setPerson(authResponse);

      const impactClickId = getImpactClickId();

      if (impactClickId) {
        const { organizationId } = registerResponse;
        await patch('organizations', organizationId, { analyticsTracking: { impactClickId } });
      }
    } catch (err) {
      setError(err);
    } finally {
      setSubmitting(false);
    }
  }, [
    registerForm,
    setEmailAlreadyExists,
    setError,
    setPerson,
    setSubmitting,
    setContextState,
    recaptchaRef,
    tracking]);

  return {
    checkForUniqueEmail,
    isPasswordSubmitted,
    isPasswordValid,
    passwordRequirements,
    registerForm,
    registerSubmit,
    recaptchaRef,
    setRegisterForm,
    submitting,
  };
}
