export const REQUIREMENTS = {
  LENGTH: 'LENGTH',
  LETTER_CASE: 'LETTER_CASE',
  NUMBER: 'NUMBER',
  SPECIAL_CHAR: 'SPECIAL_CHAR',
};

export const MESSAGES = {
  [REQUIREMENTS.LENGTH]: 'At least 8 characters long',
  [REQUIREMENTS.LETTER_CASE]: 'Includes upper & lowercase letters',
  [REQUIREMENTS.NUMBER]: 'Includes a number',
  [REQUIREMENTS.SPECIAL_CHAR]: 'Includes a special character (!, @, #, %, etc.)',
};

export const STATUSES = {
  ERROR: 'ERROR',
  INIT: 'INIT',
  MET: 'MET',
};
