import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import { useSegmentTrack } from '~/functions/SegmentFunctions';

import ExperimentalLayout from '~/layouts/Authenticate/ExperimentalLayout';
import RegisterForm from './RegisterForm';
import SkipTrialBanner from './SkipTrialBanner';

const useStyles = makeStyles((theme) => ({
  buyNowAppBar: {
    backgroundColor: '#2E1341',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  },
  buyNowToolBar: {
    minHeight: '60px',
    color: 'white',
  },
  buyNowText: {
    width: '100%',
    fontWeight: 700,
  },
  buyNowLink: {
    color: '#FFEB3B',
    fontWeight: 700,
    textDecoration: 'underline',

    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  buyNowSavings: {
    [theme.breakpoints.up('md')]: {
      color: '#FFEB3B',
    },
  },
  container: {
    // for buy now
    // paddingTop: '55px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  logo: {
    position: 'relative',
    height: '40px',
    marginBottom: theme.spacing(2),
    width: 'auto',
  },
  card: {
    position: 'relative',
    maxWidth: '400px',
    padding: theme.spacing(4),
  },
  header: {
    marginBottom: '20px',
  },
  signInText: {
    position: 'relative',
    marginTop: theme.spacing(2),
  },
}));

export default function View() {
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const [error, setError] = useState(undefined);

  const tracking = useSegmentTrack();
  useEffect(() => {
    tracking(
      'sign_up_variant_a loaded',
      { location: 'Sign Up Page' },
    );
  }, []);

  const onBuyNowClick = useCallback(() => {
    tracking(
      'variant_a_buy_now_link clicked',
      { location: 'Sign Up Page' },
    );
  }, []);

  const classes = useStyles();

  return (
    <ExperimentalLayout>
      {false && (
        <SkipTrialBanner
          onBuyNowClick={onBuyNowClick}
        />
      )}
      <Helmet>
        <title>Create an Account | REI Hub</title>
        <meta
          name="description"
          content="Create an account to access REI Hub"
        />
      </Helmet>
      <Box className={classes.container}>
        <img
          alt="REI Hub logo"
          src="/rei_hub_logo_white.svg"
          className={classes.logo}
        />

        <Card className={classes.card}>
          <Box className={classes.header}>
            <Typography align="center" variant="h5">
              Try REI Hub for Free
            </Typography>
            <Typography align="center" variant="body2">
              No credit card required. Cancel anytime.
            </Typography>
          </Box>

          <RegisterForm
            emailAlreadyExists={emailAlreadyExists}
            error={error}
            setEmailAlreadyExists={setEmailAlreadyExists}
            setError={setError}
          />
        </Card>

        {!emailAlreadyExists && (
          <Typography
            align="center"
            variant="body2"
            className={classes.signInText}
          >
            {'Already have an account? '}
            <Link
              href="/sign-in"
              color="primary"
              underline="always"
            >
              Sign in
            </Link>
          </Typography>
        )}
      </Box>
    </ExperimentalLayout>
  );
}
