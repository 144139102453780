import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import NumberFormat from 'react-number-format';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';

import history from '../history';
import {
  get, patch, remove, removeMultiple,
} from '../feathersWrapper';

import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
  handleSearchSelectChange,
  handleTextFieldChange,
  handleNumberFormatChange,
  handleKeyboardDatePickerChange,
  handleCheckboxChange,
  filterSearchSelectOptions,
} from '../functions/InputHandlers';

import {
  fixedAssetTypes,
} from './SearchSelect/FixedAssetTypeOptions';
import {
  searchProperties,
} from './SearchSelect/SearchFunctions';
import SearchSelect from './SearchSelect/SearchSelect';

const styles = (theme) => ({
  deleteIconButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: 'red',
  },
  deleteConfirmationButton: {
    color: 'red',
  },
});

class EditFixedAssetDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  getAsset = async () => {
    const { assetId } = this.props;
    const asset = await get(this, 'fixed-assets', assetId);

    asset.typeSelect = asset.type ? { label: asset.type, value: asset.type } : null;
    asset.propertyIdSelect = asset.property
      ? { label: asset.property.address1, value: asset.propertyId } : null;

    this.setState({
      asset,
      deleteTransactions: false,
      error: null,
      loading: false,
      submitting: false,
      confirmDelete: false,
    });
  };

  handleAssetTypeChange = async (name, value, isMulti) => {
    const { asset } = this.state;
    const newAsset = { ...asset };
    newAsset.lifespan = value ? value.lifespan : null;
    await asyncHandleChange('asset', newAsset, this);

    handleSearchSelectChange(this)(name, value, isMulti);
  };

  editAsset = async (event) => {
    event.preventDefault();
    const {
      submitting, asset,
    } = this.state;

    const {
      onEditFixedAsset, closeDialog, assetId,
    } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    patch(this, 'fixed-assets', assetId, asset)
      .then(() => {
        closeDialog();
        onEditFixedAsset();
        this.setState({ loading: true });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  deleteAsset = async (event) => {
    event.preventDefault();
    const {
      deleteTransactions, submitting,
    } = this.state;
    const { assetId } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    if (deleteTransactions) {
      await removeMultiple(this, 'journals', { query: { organizationId, fixedAssetId: assetId } });
    }

    remove(this, 'fixed-assets', assetId)
      .then(() => {
        history.push('/organization/fixed-assets');
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const {
      loading, error, asset, confirmDelete, deleteTransactions,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        onClose={closeDialog}
        onEnter={this.getAsset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="editAssetDialog"
      >
        {!loading && !confirmDelete && (
          <form onSubmit={this.editAsset}>
            <DialogTitle id="alert-dialog-title">
              Edit Asset
              <IconButton
                className={classes.deleteIconButton}
                aria-label="delete"
                onClick={() => this.setState({ confirmDelete: true })}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TextField
                label="Asset Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: asset.name,
                  name: 'nested_asset_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <SearchSelect
                searchFunction={searchProperties(this)}
                changeFunction={handleSearchSelectChange(this)}
                label="Property (optional)"
                name="nested_asset_propertyId"
                value={asset.propertyIdSelect}
                margin="dense"
              />
              <SearchSelect
                searchFunction={filterSearchSelectOptions(fixedAssetTypes)}
                changeFunction={this.handleAssetTypeChange}
                label="Asset Type (optional)"
                name="nested_asset_type"
                value={asset.typeSelect}
                margin="dense"
              />
              <FormControl margin="dense" fullWidth>
                <InputLabel>
                  Useful Life (optional)
                </InputLabel>
                <NumberFormat
                  value={asset.lifespan}
                  decimalScale={1}
                  suffix=" years"
                  onValueChange={handleNumberFormatChange('nested_asset_lifespan', this)}
                  customInput={Input}
                />
              </FormControl>
              <KeyboardDatePicker
                label="Date Placed in Service (optional)"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={asset.placedInServiceDate}
                onChange={handleKeyboardDatePickerChange('nested_asset_placedInServiceDate', this)}
                margin="dense"
                fullWidth
                clearable
              />
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={asset.inactive} onChange={handleCheckboxChange(this)} name="nested_asset_inactive" />}
                  label="This asset is inactive"
                />
              </FormControl>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained" disableElevation>
                Save Asset
              </Button>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
        {!loading && confirmDelete && (
          <form onSubmit={this.deleteAsset}>
            <DialogTitle id="alert-dialog-title">Really Delete Asset?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This fixed asset will be removed.
              </DialogContentText>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={deleteTransactions}
                      name="deleteTransactions"
                      onChange={handleCheckboxChange(this)}
                    />
                  )}
                  label="Also delete all transactions for this fixed asset"
                />
              </Box>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" className={classes.deleteConfirmationButton}>
                Delete Asset
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

EditFixedAssetDialog.contextType = PersonContext;

EditFixedAssetDialog.defaultProps = {
  assetId: null,
};

EditFixedAssetDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onEditFixedAsset: PropTypes.func.isRequired,
  assetId: PropTypes.node,
};

export default withStyles(styles)(EditFixedAssetDialog);
