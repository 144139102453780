import React from 'react';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import PageGrid from '~/components/PageGrid';

import AddVendorDialog from '../components/AddVendorDialog';
import CardBase from '../components/CardBase';
import EditVendorDialog from '../components/EditVendorDialog';
import ErrorCard from '../components/ErrorCard';
import FilterVendorsDialog from '../components/FilterVendorsDialog';
import LinkBase from '../components/LinkBase';
import PageHeader from '../components/PageHeader';
import ViewEnableVendorsDialog from '../components/ViewEnableVendorsDialog';
import { PersonContext } from '../contexts/PersonContext';
import { find } from '../feathersWrapper';
import { asyncHandleChange } from '../functions/InputHandlers';
import history from '../history';

const styles = (theme) => ({
  inlineButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
});

class Vendors extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      viewEnableVendorsDialog: false,
      addVendorDialog: false,
      editVendorDialog: false,
      filterDialog: false,
      filter: {
        name: '',
        page: 0,
      },
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const { vendorTracking } = this.context;
    const { filter } = this.state;
    const parsedQuery = queryString.parse(location.search);
    const newFilter = { ...filter };

    if (parsedQuery.name) {
      newFilter.name = parsedQuery.name;
    }

    await asyncHandleChange('filter', newFilter, this);

    await this.getVendors();

    this.setState({
      viewEnableVendorsDialog: !vendorTracking,
      loading: false,
    });
  }

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getVendors();
  };

  clearFilterFields = (fieldList) => {
    const { filter } = this.state;
    const newFilter = { ...filter };
    fieldList.forEach((fieldName) => {
      newFilter[fieldName] = null;
    });
    newFilter.page = 0;
    this.updateFilter(newFilter);
  };

  appliedFilter = () => {
    const { filter } = this.state;
    const filterArray = [];
    if (filter.name) {
      filterArray.push({
        label: 'Name Contains',
        value: filter.name,
        clear: () => this.clearFilterFields(['name']),
      });
    }
    return filterArray;
  };

  getVendors = async () => {
    const { organizationId } = this.context;
    const { filter } = this.state;
    try {
      // launch async calls
      const query = {
        organizationId,
        $sort: {
          name: 1,
          id: 1,
        },
        $limit: 20,
        $skip: filter.page * 20,
      };

      if (filter.name) {
        query.name = { $iLike: `%${filter.name}%` };
      }

      const vendors = await find(this, 'vendors', { query });

      this.setState({
        totalVendors: vendors.total,
        vendors: vendors.data,
      });
    } catch (error) {
      this.setState({ error });
      this.setState({ loading: false });
    }
  };

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    { text: 'Add Vendor', action: () => this.setState({ addVendorDialog: true }), class: 'add' },
  ];

  handleChangePage = async (_event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getVendors();
  };

  closeVendorDialogs = () => {
    this.setState({
      editVendorDialog: false,
      addVendorDialog: false,
      viewEnableVendorsDialog: false,
    });
  };

  onVendorDialogs = async () => {
    await this.getVendors();
    this.closeVendorDialogs();
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading,
      error,
      vendors,
      vendorId,
      addVendorDialog,
      editVendorDialog,
      totalVendors,
      filter,
      filterDialog,
      viewEnableVendorsDialog,
    } = this.state;

    if (loading) {
      return null;
    }

    if (error) {
      return <ErrorCard error={error.message} />;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          actionButtons={this.actionButtons()}
          appliedFilter={this.appliedFilter()}
          title="Vendors"
        />
        <ViewEnableVendorsDialog isOpen={viewEnableVendorsDialog} closeDialog={() => history.push('/dashboard')} />
        <AddVendorDialog
          isOpen={addVendorDialog}
          closeDialog={this.closeVendorDialogs}
          onAddVendor={this.onVendorDialogs}
        />
        <EditVendorDialog
          isOpen={editVendorDialog}
          closeDialog={this.closeVendorDialogs}
          onEditVendor={this.onVendorDialogs}
          vendorId={vendorId}
        />
        <FilterVendorsDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={() => this.setState({ filterDialog: false })}
          updateFilter={this.updateFilter}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Email</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">Phone</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendors.map((vendor) => (
                    <TableRow key={vendor.id}>
                      <TableCell>
                        <LinkBase to={`/transactions/booked?vendorId=${vendor.id}`}>{vendor.name}</LinkBase>
                        <Button
                          className={classes.inlineButton}
                          aria-label="edit"
                          onClick={() => {
                            this.setState({
                              vendorId: vendor.id,
                              editVendorDialog: true,
                            });
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </Button>
                      </TableCell>
                      <TableCell>{vendor.email}</TableCell>
                      <TableCell>{vendor.phone}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={totalVendors}
                      page={filter.page}
                      rowsPerPage={20}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[20]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

Vendors.contextType = PersonContext;

Vendors.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Vendors);
