import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { PersonContext } from '../contexts/PersonContext';
import { get } from '../feathersWrapper';

const styles = (theme) => ({
  flexGrow: {
    flexGrow: '1',
  },
  reverseMargins: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  logo: {
    maxWidth: '250px',
    maxHeight: '200px',
  },
  hiddenLogo: {
    display: 'none',
  },
});

class AccountantPrintHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async componentDidMount() {
    const { accountingFirmId } = this.context;
    const firm = await get(this, 'accounting-firms', accountingFirmId);
    this.setState({
      firm,
      loading: false,
    });
  }

  render() {
    const { classes } = this.props;
    const { loading, firm } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Media
        queries={{
          screen: 'only screen',
          print: 'only print',
        }}
      >
        {(matches) => (
          <>
            {matches.screen && <img src={`${firm.logoURL}/-/preview/300x500/`} alt={firm.name} className={classes.hiddenLogo} />}
            {matches.print && (
              <Grid item xs={12}>
                <Box display="flex" alignItems="top" flexWrap="wrap" marginTop={-1} marginLeft={-1}>
                  <div className={classes.reverseMargins}>
                    {firm.logoURL ? (
                      <img src={`${firm.logoURL}/-/preview/300x500/`} alt={firm.name} className={classes.logo} />
                    ) : (
                      <Typography variant="subtitle2">{firm.name}</Typography>
                    )}
                  </div>
                  <div className={classes.flexGrow} />
                  <div>
                    {firm.logoURL && (
                      <Box marginY={2}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          align="right"
                        >
                          {firm.name}
                        </Typography>
                      </Box>
                    )}
                    {firm.phone && <Typography variant="body2" align="right" component="div">{firm.phone}</Typography>}
                    {firm.email && <Typography variant="body2" align="right" component="div">{firm.email}</Typography>}
                  </div>
                </Box>
              </Grid>
            )}
          </>
        )}
      </Media>
    );
  }
}

AccountantPrintHeader.contextType = PersonContext;

AccountantPrintHeader.defaultProps = {};

AccountantPrintHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(AccountantPrintHeader);
