import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    alignContent: 'flex-start',
    'max-width': theme.breakpoints.values.md,
  },
});

class CardContentContainer extends React.PureComponent {
  render() {
    const { classes, children } = this.props;

    return (
      <Grid container justify="center" spacing={2} className={classes.root}>
        {children}
      </Grid>
    );
  }
}

CardContentContainer.defaultProps = {
  children: null,
};

CardContentContainer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(CardContentContainer);
