import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import { KeyboardDatePicker } from '@material-ui/pickers';

import history from '../history';
import { find, create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import {
  handleKeyboardDatePickerChange,
} from '../functions/InputHandlers';

import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';

class TaxReview extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dialog: true,
      rereview: false,
      step: 'welcome',
      filter: {
        baseTaxYear: moment().subtract(1, 'years').format('YYYY'),
        taxYear: moment().subtract(1, 'years').format('YYYY'),
        taxYearSelect: moment().subtract(1, 'years'),
      },
      expandTransactions: true,
      expandDepreciation: true,
      expandMileage: true,
      expandEscrows: true,
    };
  }

  componentDidMount() {
    this.doTaxReview();
  }

  doTaxReview = async () => {
    this.setState({ loading: true });
    const { organizationId, basis } = this.context;
    const { filter } = this.state;
    const startDate = moment(filter.taxYearSelect).format('YYYY-01-01');
    const endDate = moment(filter.taxYearSelect).format('YYYY-12-31');

    // review transactions
    const operatingAccountsResult = await find(this, 'accounts', {
      query: {
        organizationId,
        type2: ['Bank', 'Credit Card'],
        $sort: {
          name: 1,
          id: 1,
        },
      },
    });

    const operatingAccounts = operatingAccountsResult.data;

    const importedTransactionsPromise = create(this, 'reports', {
      organizationId,
      reportName: 'countImportedTransactionsByStatus',
      startDate,
      endDate,
    });

    const journalLinePromises = [];

    const operatingAccountsById = {};
    operatingAccounts.forEach((account) => {
      operatingAccountsById[account.id] = account;

      // eslint-disable-next-line no-param-reassign
      account.counts = {
        total: 0,
        matched: 0,
        ignored: 0,
        booked: 0,
        unbooked: 0,
      };

      journalLinePromises.push(find(this, 'journal-lines', {
        query: {
          organizationId,
          basis,
          startDate,
          endDate,
          accountId: account.id,
        },
        $limit: 0,
      }));
    });

    const importedTransactionsResults = await importedTransactionsPromise;
    importedTransactionsResults.forEach((accountCounts) => {
      if (operatingAccountsById[accountCounts.accountId]) {
        operatingAccountsById[accountCounts.accountId].counts = accountCounts;
      }
    });

    const journalLineResults = await Promise.all(journalLinePromises);
    journalLineResults.forEach((accountResult, index) => {
      operatingAccounts[index].counts.journalLines = accountResult.total;
    });

    // review property assets and depreciaiton expenses
    const propertiesResult = await find(this, 'properties', {
      query: {
        organizationId,
        inactive: false,
        $limit: 500,
        $sort: {
          address1: 1,
          id: 1,
        },
      },
    });

    const properties = propertiesResult.data;

    // get asset data for each property
    const assetsPromises = [];
    properties.forEach((property) => {
      // set $0 starting values
      // eslint-disable-next-line no-param-reassign
      property.assets = 0;

      assetsPromises.push(
        create(this, 'reports', {
          organizationId,
          basis,
          reportName: 'accountJournalTotals',
          type2: 'Fixed Asset',
          endDate,
          propertyId: property.id,
        })
          .then((assetResult) => {
            assetResult.forEach((assetAccount) => {
              // eslint-disable-next-line no-param-reassign
              property.assets -= assetAccount.netCredits;
            });
          }),
      );
    });

    // get period depreciation expenses for each property
    const depreciationPromises = [];
    properties.forEach((property) => {
      // eslint-disable-next-line no-param-reassign
      property.depreciationExpenses = 0;

      depreciationPromises.push(
        create(this, 'reports', {
          organizationId,
          basis,
          reportName: 'accountJournalTotals',
          type2: 'Depreciation Expense',
          startDate,
          endDate,
          propertyId: property.id,
        })
          .then((depreciationResult) => {
            depreciationResult.forEach((depreciationAccount) => {
              // eslint-disable-next-line no-param-reassign
              property.depreciationExpenses -= depreciationAccount.netCredits;
            });
          }),
      );
    });

    await Promise.all(assetsPromises);
    await Promise.all(depreciationPromises);

    // review mileage log
    const vehiclesResult = await find(this, 'vehicles', {
      query: {
        organizationId,
        $sort: {
          name: 1,
          id: 1,
        },
      },
    });

    const vehicles = vehiclesResult.data;

    // get period mileage data for each vehicle
    const mileagePromises = [];
    vehicles.forEach((vehicle) => {
      mileagePromises.push(
        create(this, 'reports', {
          organizationId,
          reportName: 'mileageTotals',
          startDate,
          endDate,
          vehicleId: vehicle.id,
        })
          .then((mileageResult) => {
            // eslint-disable-next-line no-param-reassign
            vehicle.mileage = mileageResult;
          }),
      );
    });

    await Promise.all(mileagePromises);

    // get period escrow account totals
    const escrowAccounts = await create(this, 'reports', {
      organizationId,
      basis,
      reportName: 'accountJournalTotals',
      type2: 'Escrow',
      startDate,
      endDate,
    });

    this.setState({
      loading: false,
      operatingAccounts,
      properties,
      vehicles,
      escrowAccounts,
    });
  };

  startReview = async () => {
    const { filter } = this.state;
    if (filter.taxYearSelect.format('YYYY') !== filter.baseTaxYear) {
      await this.doTaxReview();
    }
    this.setState({ step: 'operatingAccounts' });
  };

  rereview = async () => {
    this.setState({ step: 'welcome', dialog: true, rereview: true });
    this.doTaxReview();
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ dialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  }

  goToExport = () => {
    history.replace('/reports/tax-packet');
  }

  render() {
    const { match } = this.props;
    const {
      filter, dialog, step, loading, rereview,
      operatingAccounts, properties, vehicles, escrowAccounts,
      expandTransactions, expandDepreciation, expandMileage, expandEscrows,
    } = this.state;

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Tax Review"
        />
        <Dialog
          open={dialog}
          scroll="body"
          maxWidth="sm"
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
        >
          {step === 'welcome' && (
            <>
              <DialogTitle id="alert-dialog-title">Tax Review</DialogTitle>
              <DialogContent>
                <Typography variant="body1" gutterBottom>
                  {`Welcome to your Tax Review. 
                    We'll double check your portfolio for commonly overlooked items to maximize your deductions and help you prepare for filing. 
                    You'll be able to review or update any records from this review on the final summary screen.`}
                </Typography>
                {!rereview && (
                  <KeyboardDatePicker
                    label="Tax Year"
                    views={['year']}
                    format="YYYY"
                    placeholder="YYYY"
                    value={filter.taxYearSelect}
                    onChange={handleKeyboardDatePickerChange('nested_filter_taxYear', this)}
                    required
                    fullWidth
                  />
                )}
                <Box mt={2}>
                  <ButtonGroup fullWidth orientation="vertical">
                    <Button
                      color="secondary"
                      variant="outlined"
                      disabled={loading}
                      fullWidth
                      size="large"
                      onClick={this.startReview}
                    >
                      Continue
                    </Button>
                    {rereview && (
                      <Button
                        color="secondary"
                        variant="outlined"
                        disabled={loading}
                        fullWidth
                        size="large"
                        onClick={() => this.setState({ dialog: false })}
                      >
                        Skip to Summary
                      </Button>
                    )}
                  </ButtonGroup>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.goToReports} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
          {step === 'operatingAccounts' && (
            <>
              <DialogTitle id="alert-dialog-title">Checking Unbooked Transactions</DialogTitle>
              <DialogContent>
                <Typography variant="body1" gutterBottom>
                  {`Before filing, you'll need to book all of your business transactions from the tax year. 
                  Using REI Hub's import feed with linked accounts can help ensure that nothing has been overlooked. 
                  We found the following ${filter.taxYearSelect.format('YYYY')} transactions in your bank and credit card accounts. 
                  Review any unbooked transactions to complete your records if necessary.`}
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell><Typography variant="subtitle2">Account</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">Booked</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">Unbooked</Typography></TableCell>
                    </TableRow>
                    {operatingAccounts.map((account) => (
                      <TableRow key={account.id}>
                        <TableCell>{account.name}</TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={account.counts.journalLines}
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={account.counts.unbooked}
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box mt={2}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    size="large"
                    onClick={() => this.setState({ step: 'depreciation' })}
                  >
                    Continue
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.goToReports} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
          {step === 'depreciation' && (
            <>
              <DialogTitle id="alert-dialog-title">Checking Depreciation Expenses</DialogTitle>
              <DialogContent>
                <Typography variant="body1" gutterBottom>
                  {`Depreciation is one of the largest deductible expenses available to most investors. 
                    You or your tax preparer will need to track the basis for your assets and calculate your allowable depreciation to claim it. 
                    Use the Fixed Asset Schedule to view assets created in REI Hub, their recommended allowable depreciation, and booked ${filter.taxYearSelect.format('YYYY')} depreciation expenses. 
                    If you choose not to enter or track basis and depreciation information, make sure you know who is managing it on your behalf and considering requesting a backup copy for your records.`}
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell><Typography variant="subtitle2">Property</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">Total Assets</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">{`${filter.taxYearSelect.format('YYYY')} Depreciation`}</Typography></TableCell>
                    </TableRow>
                    {properties.map((property) => (
                      <TableRow key={property.id}>
                        <TableCell>{property.address1}</TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={property.assets}
                            thousandSeparator
                            prefix="$"
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={property.depreciationExpenses}
                            thousandSeparator
                            prefix="$"
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box mt={2}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    size="large"
                    onClick={() => this.setState({ step: 'mileage' })}
                  >
                    Continue
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.goToReports} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
          {step === 'mileage' && (
            <>
              <DialogTitle id="alert-dialog-title">Checking Mileage Expenses</DialogTitle>
              <DialogContent>
                <Typography variant="body1" gutterBottom>
                  {`Investors can typically deduct mileage driven to manage or maintain properties. 
                    You are required to keep a mileage log of trips taken in order to claim this deduction. 
                    We found the following totals from your mileage log. 
                    Reported mileage is automatically included as an expense in REI Hub's Schedule E reports or can be exported using the Mileage Log report. 
                    If you have unrecorded trips, be sure to add them into the mileage log to maximize your deduction.`}
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell><Typography variant="subtitle2">Vehicle</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">Miles</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">Deduction</Typography></TableCell>
                    </TableRow>
                    {vehicles.map((vehicle) => (
                      <TableRow key={vehicle.id}>
                        <TableCell>{vehicle.name}</TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={vehicle.mileage.totalMiles}
                            thousandSeparator
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={vehicle.mileage.totalDeduction}
                            thousandSeparator
                            prefix="$"
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box mt={2}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    size="large"
                    onClick={() => this.setState({ step: 'escrowAccounts' })}
                  >
                    Continue
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.goToReports} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
          {step === 'escrowAccounts' && (
            <>
              <DialogTitle id="alert-dialog-title">Checking Escrow Accounts</DialogTitle>
              <DialogContent>
                <Typography variant="body1" gutterBottom>
                  {`Many mortgages include a pre-funded escrow account used to pay certain expenses. 
                  These expenses may be overlooked because money is withdrawn from the escrow to pay them on your behalf. 
                  Below are the total funds contributed to and withdrawn from your escrow accounts during the tax year. 
                  Double check any imbalanced accounts for missing expenses. 
                  If missing, `}
                  <Link
                    href="https://support.reihub.net/article/50-booking-escrow-expenses"
                    target="_blank"
                    color="secondary"
                  >
                    book expenses for the escrow payments
                  </Link>
                  {' of property taxes, homeowners insurance, HOA fees, or any other expenses.'}
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell><Typography variant="subtitle2">Account</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">Contributions</Typography></TableCell>
                      <TableCell align="right"><Typography variant="subtitle2">Withdrawals</Typography></TableCell>
                    </TableRow>
                    {escrowAccounts.map((account) => (
                      <TableRow key={account.id}>
                        <TableCell>{account.name}</TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={account.totalDebits}
                            thousandSeparator
                            prefix="$"
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={account.totalCredits}
                            thousandSeparator
                            prefix="$"
                            decimalScale={0}
                            fixedDecimalScale
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box mt={2}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    size="large"
                    onClick={() => this.setState({ step: 'complete' })}
                  >
                    Continue
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.goToReports} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
          {step === 'complete' && (
            <>
              <DialogTitle id="alert-dialog-title">Tax Review Complete</DialogTitle>
              <DialogContent>
                <Typography variant="body1" gutterBottom>
                  {`Your tax readiness review is complete. 
                    Use the information on the summary screen to view, confirm, or update information from the analyzed areas. 
                    When your records are finalized, downloading REI Hub's tax packet will export the information that you or your tax preparer may need to complete your ${filter.taxYearSelect.format('YYYY')} return.`}
                </Typography>
                <Box my={2}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    size="large"
                    onClick={() => this.setState({ dialog: false })}
                  >
                    View Summary
                  </Button>
                </Box>
              </DialogContent>
            </>
          )}
        </Dialog>
        {!loading && !dialog && (
          <Grid item xs={12}>
            <CardBase>
              <CardContent>
                <Box display="flex" flexDirection="column">
                  <Typography variant="h5" align="center" gutterBottom>
                    {`${filter.taxYearSelect.format('YYYY')} Tax Review Summary`}
                  </Typography>
                  <Box my={2} align="center">
                    <ButtonGroup>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => this.rereview()}
                      >
                        Rerun Tax Review
                      </Button>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => this.goToExport()}
                      >
                        Export Tax Packet
                      </Button>
                    </ButtonGroup>
                  </Box>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Transactions
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                              this.setState({ expandTransactions: !expandTransactions });
                            }}
                          >
                            {expandTransactions ? 'Collapse' : 'Expand'}
                          </Button>
                        </TableCell>
                      </TableRow>
                      {expandTransactions && (
                        <TableRow>
                          <TableCell colspan={2}>
                            <Typography variant="body2" gutterBottom>
                              {`Before filing, you'll need to book all of your business transactions from the tax year. 
                              Using REI Hub's import feed with linked accounts can help ensure that nothing has been overlooked. 
                              We found the following ${filter.taxYearSelect.format('YYYY')} transactions in your bank and credit card accounts. 
                              Review any unbooked transactions to complete your records if necessary.`}
                            </Typography>
                            <Box mx="auto" maxWidth="650px">
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell><Typography variant="subtitle2">Account</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="subtitle2">Booked</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="subtitle2">Unbooked</Typography></TableCell>
                                  </TableRow>
                                  {operatingAccounts.map((account) => (
                                    <TableRow key={account.id}>
                                      <TableCell>{account.name}</TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          displayType="text"
                                          value={account.counts.journalLines}
                                          decimalScale={0}
                                          fixedDecimalScale
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          displayType="text"
                                          value={account.counts.unbooked}
                                          decimalScale={0}
                                          fixedDecimalScale
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                            <Box my={2} align="center">
                              <ButtonGroup>
                                <Button
                                  color="secondary"
                                  variant="outlined"
                                  component={Link}
                                  href={`/transactions/imported?status=Unbooked&startDate=${filter.taxYearSelect.format('YYYY')}-01-01&endDate=${filter.taxYearSelect.format('YYYY')}-12-31`}
                                  target="_blank"
                                >
                                  View Unbooked Transactions
                                </Button>
                              </ButtonGroup>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Depreciation
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                              this.setState({ expandDepreciation: !expandDepreciation });
                            }}
                          >
                            {expandDepreciation ? 'Collapse' : 'Expand'}
                          </Button>
                        </TableCell>
                      </TableRow>
                      {expandDepreciation && (
                        <TableRow>
                          <TableCell colspan={2}>
                            <Typography variant="body2" gutterBottom>
                              {`Depreciation is one of the largest deductible expenses available to most investors. 
                                You or your tax preparer will need to track the basis for your assets and calculate your allowable depreciation to claim it. 
                                Use the Fixed Asset Schedule to view assets created in REI Hub, their recommended allowable depreciation, and booked ${filter.taxYearSelect.format('YYYY')} depreciation expenses. 
                                If you choose not to enter or track basis and depreciation information, make sure you know who is managing it on your behalf and considering requesting a backup copy for your records.`}
                            </Typography>
                            <Box mx="auto" maxWidth="650px">
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell><Typography variant="subtitle2">Property</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="subtitle2">Total Assets</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="subtitle2">{`${filter.taxYearSelect.format('YYYY')} Depreciation`}</Typography></TableCell>
                                  </TableRow>
                                  {properties.map((property) => (
                                    <TableRow key={property.id}>
                                      <TableCell>{property.address1}</TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          displayType="text"
                                          value={property.assets}
                                          thousandSeparator
                                          prefix="$"
                                          decimalScale={0}
                                          fixedDecimalScale
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          displayType="text"
                                          value={property.depreciationExpenses}
                                          thousandSeparator
                                          prefix="$"
                                          decimalScale={0}
                                          fixedDecimalScale
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                            <Box my={2} align="center">
                              <ButtonGroup>
                                <Button
                                  color="secondary"
                                  variant="outlined"
                                  component={Link}
                                  href="/organization/fixed-assets"
                                  target="_blank"
                                >
                                  View Fixed Assets
                                </Button>
                              </ButtonGroup>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Mileage
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => this.setState({ expandMileage: !expandMileage })}
                          >
                            {expandMileage ? 'Collapse' : 'Expand'}
                          </Button>
                        </TableCell>
                      </TableRow>
                      {expandMileage && (
                        <TableRow>
                          <TableCell colspan={2}>
                            <Typography variant="body2" gutterBottom>
                              {`Investors can typically deduct mileage driven to manage or maintain properties. 
                              You are required to keep a mileage log of trips taken in order to claim this deduction. 
                              We found the following totals from your mileage log. 
                              Reported mileage is automatically included as an expense in REI Hub's Schedule E reports or can be exported using the Mileage Log report. 
                              If you have unrecorded trips, be sure to add them into the mileage log to maximize your deduction.`}
                            </Typography>
                            <Box mx="auto" maxWidth="650px">
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell><Typography variant="subtitle2">Vehicle</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="subtitle2">Miles</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="subtitle2">Deduction</Typography></TableCell>
                                  </TableRow>
                                  {vehicles.map((vehicle) => (
                                    <TableRow key={vehicle.id}>
                                      <TableCell>{vehicle.name}</TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          displayType="text"
                                          value={vehicle.mileage.totalMiles}
                                          thousandSeparator
                                          decimalScale={0}
                                          fixedDecimalScale
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          displayType="text"
                                          value={vehicle.mileage.totalDeduction}
                                          thousandSeparator
                                          prefix="$"
                                          decimalScale={0}
                                          fixedDecimalScale
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                            <Box my={2} align="center">
                              <ButtonGroup>
                                <Button
                                  color="secondary"
                                  variant="outlined"
                                  component={Link}
                                  href="/organization/mileage"
                                  target="_blank"
                                >
                                  View Mileage Log
                                </Button>
                              </ButtonGroup>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Escrows
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => this.setState({ expandEscrows: !expandEscrows })}
                          >
                            {expandEscrows ? 'Collapse' : 'Expand'}
                          </Button>
                        </TableCell>
                      </TableRow>
                      {expandEscrows && (
                        <TableRow>
                          <TableCell colspan={2}>
                            <Typography variant="body2" gutterBottom>
                              {`Many mortgages include a pre-funded escrow account used to pay certain expenses. 
                              These expenses may be overlooked because money is withdrawn from the escrow to pay them on your behalf. 
                              Below are the total funds contributed to and withdrawn from your escrow accounts during the tax year. 
                              Double check any imbalanced accounts for missing expenses. 
                              If missing, `}
                              <Link
                                href="https://support.reihub.net/article/50-booking-escrow-expenses"
                                target="_blank"
                                color="secondary"
                              >
                                book expenses for the escrow payments
                              </Link>
                              {' of property taxes, homeowners insurance, HOA fees, or any other expenses.'}
                            </Typography>
                            <Box mx="auto" maxWidth="650px">
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell><Typography variant="subtitle2">Account</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="subtitle2">Contributions</Typography></TableCell>
                                    <TableCell align="right"><Typography variant="subtitle2">Withdrawals</Typography></TableCell>
                                  </TableRow>
                                  {escrowAccounts.map((account) => (
                                    <TableRow key={account.id}>
                                      <TableCell>{account.name}</TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          displayType="text"
                                          value={account.totalDebits}
                                          thousandSeparator
                                          prefix="$"
                                          decimalScale={0}
                                          fixedDecimalScale
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          displayType="text"
                                          value={account.totalCredits}
                                          thousandSeparator
                                          prefix="$"
                                          decimalScale={0}
                                          fixedDecimalScale
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                            <Box my={2} align="center">
                              <ButtonGroup>
                                <Button
                                  color="secondary"
                                  variant="outlined"
                                  component={Link}
                                  href="/accounts/loans"
                                  target="_blank"
                                >
                                  View Loans and Escrows
                                </Button>
                              </ButtonGroup>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Box my={2} align="center">
                    <ButtonGroup>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => this.rereview()}
                      >
                        Rerun Tax Review
                      </Button>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => this.goToExport()}
                      >
                        Export Tax Packet
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Box>
              </CardContent>
            </CardBase>
          </Grid>
        )}
      </PageGrid>
    );
  }
}

TaxReview.contextType = PersonContext;

TaxReview.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  // location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TaxReview;
