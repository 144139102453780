import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { PersonContext } from '../../contexts/PersonContext';

import { find, create } from '../../feathersWrapper';

import FieldGrid from '../../components/FieldGrid';
import LinkBase from '../../components/LinkBase';
import ErrorCard from '../../components/ErrorCard';
import CardBase from '../../components/CardBase';

const styles = {};

class BookValueCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { view, id } = this.props;
    const { organizationId, basis } = this.context;

    const assetAccountsQuery = {
      organizationId,
      basis,
      reportName: 'accountJournalTotals',
      type: 'Asset',
      type2: 'Fixed Asset',
    };

    const depreciationAccountsQuery = {
      organizationId,
      basis,
      reportName: 'accountJournalTotals',
      type: 'Asset',
      type2: 'Accumulated Depreciation',
    };

    const assetCountQuery = {
      organizationId,
      inactive: { $or: [null, false] },
      $limit: 1,
    };

    if (view === 'property') {
      assetAccountsQuery.propertyId = id;
      depreciationAccountsQuery.propertyId = id;
      assetCountQuery.propertyId = id;
    }

    const fixedAssetAccountsPromise = create(this, 'reports', assetAccountsQuery);
    const depreciationAccountsPromise = create(this, 'reports', depreciationAccountsQuery);
    const fixedAssetsCountPromise = find(this, 'fixed-assets', { query: assetCountQuery });

    const fixedAssetAccounts = await fixedAssetAccountsPromise;
    const depreciationAccounts = await depreciationAccountsPromise;
    const fixedAssetTotals = {
      bookValue: 0,
      depreciable: 0,
      nonDepreciable: 0,
      startingBasis: 0,
      accumulatedDepreciation: 0,
    };

    fixedAssetAccounts.forEach((account) => {
      fixedAssetTotals.bookValue -= account.netCredits;
      fixedAssetTotals.startingBasis -= account.netCredits;
      if (account.nonDepreciable) {
        fixedAssetTotals.nonDepreciable -= account.netCredits;
      } else {
        fixedAssetTotals.depreciable -= account.netCredits;
      }
    });

    depreciationAccounts.forEach((account) => {
      fixedAssetTotals.bookValue -= account.netCredits;
      fixedAssetTotals.depreciable -= account.netCredits;
      fixedAssetTotals.accumulatedDepreciation -= account.netCredits;
    });

    const fixedAssetCount = await fixedAssetsCountPromise;

    this.setState({
      fixedAssetTotals,
      assetsCount: fixedAssetCount.total,
      loading: false,
    });
  };

  getLink = () => {
    const { assetsCount } = this.state;
    const { view, id } = this.props;
    if (view === 'property') {
      if (assetsCount === 0) {
        return (
          <LinkBase to={`/organization/fixed-assets?addPropertyId=${id}`}>
            <Typography variant="body2">Add Purchase Information</Typography>
          </LinkBase>
        );
      }
      return (
        <LinkBase to={`/organization/fixed-assets?propertyId=${id}`}>
          <Typography variant="body2">{`View Fixed Assets (${assetsCount})`}</Typography>
        </LinkBase>
      );
    }
    return (
      <LinkBase to="/organization/fixed-assets">
        <Typography variant="body2">{`View Fixed Assets (${assetsCount})`}</Typography>
      </LinkBase>
    );
  };

  render() {
    const {
      loading, error, fixedAssetTotals, assetsCount,
    } = this.state;

    if (loading) {
      return (
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Fixed Assets
              </Typography>
            </Box>
          </CardBase>
        </Grid>
      );
    }
    if (error) {
      return <ErrorCard error={error.message} />;
    }
    return (
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <CardBase>
          <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
            <Typography variant="h6" gutterBottom>
              Fixed Assets
            </Typography>
            <Typography variant="h4">
              <NumberFormat
                displayType="text"
                value={fixedAssetTotals.bookValue}
                thousandSeparator
                prefix="$"
                decimalScale={0}
                fixedDecimalScale
              />
            </Typography>
            {assetsCount > 0 && (
              <>
                <Typography variant="subtitle2">
                  Book Value
                </Typography>
                <FieldGrid
                  label="Depreciable Basis"
                  value={(
                    <NumberFormat
                      displayType="text"
                      value={fixedAssetTotals.depreciable}
                      thousandSeparator
                      prefix="$"
                      decimalScale={0}
                      fixedDecimalScale
                    />
                  )}
                />
                <FieldGrid
                  label="Non-Depreciable Basis"
                  value={(
                    <NumberFormat
                      displayType="text"
                      value={fixedAssetTotals.nonDepreciable}
                      thousandSeparator
                      prefix="$"
                      decimalScale={0}
                      fixedDecimalScale
                    />
                  )}
                />
                <Typography variant="subtitle2">
                  Depreciation
                </Typography>
                <FieldGrid
                  label="Starting Basis"
                  value={(
                    <NumberFormat
                      displayType="text"
                      value={fixedAssetTotals.startingBasis}
                      thousandSeparator
                      prefix="$"
                      decimalScale={0}
                      fixedDecimalScale
                    />
                  )}
                />
                <FieldGrid
                  label="Accumulated Depreciation"
                  value={(
                    <NumberFormat
                      displayType="text"
                      value={fixedAssetTotals.accumulatedDepreciation}
                      thousandSeparator
                      prefix="$"
                      decimalScale={0}
                      fixedDecimalScale
                    />
                  )}
                />
              </>
            )}
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
              {this.getLink()}
            </Box>
          </Box>
        </CardBase>
      </Grid>
    );
  }
}

BookValueCard.contextType = PersonContext;

BookValueCard.defaultProps = {
  id: null,
};

BookValueCard.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.string).isRequired,
  view: PropTypes.string.isRequired,
  id: PropTypes.node,
  // theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles, { withTheme: true })(BookValueCard);
