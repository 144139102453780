import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import {
  ResponsiveContainer, ComposedChart,
  Bar, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import { axisLabels, tooltipFormatter } from '../../functions/ChartFunctions';

import { PersonContext } from '../../contexts/PersonContext';

import { create } from '../../feathersWrapper';

import CardBase from '../../components/CardBase';

class CardAccountGraphCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    const { stale, removeStale } = this.props;
    if (stale) {
      this.getData();
      removeStale('accountGraph');
    }
  }

  getData = async () => {
    const {
      startDate, endDate, accountId,
    } = this.props;
    const { organizationId, basis } = this.context;

    const query = { organizationId, basis };

    const bargraphData = await create(this, 'reports', {
      reportName: 'accountFlowCard',
      accountId,
      startDate,
      endDate,
      ...query,
    });

    const balanceReportQuery = {
      organizationId,
      basis,
      accountId,
      endDate: moment().format('YYYY-MM-DD'),
      reportName: 'accountJournalTotals',
    };

    const balanceReport = await create(this, 'reports', { ...balanceReportQuery });
    const bookBalance = balanceReport[0].netCredits;

    this.setState({
      bargraphData,
      bookBalance,
      loading: false,
    });
  };

  render() {
    const {
      loading, bargraphData, bookBalance,
    } = this.state;

    if (loading) {
      return (
        <Grid item xs={12} sm={6} md={6} lg={8}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                &nbsp;
              </Typography>
            </Box>
          </CardBase>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} sm={6} md={6} lg={8}>
        <CardBase>
          <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
            <Typography variant="h4">
              <NumberFormat
                displayType="text"
                value={bookBalance}
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
            <Typography variant="subtitle2">
              Book Balance
            </Typography>
            <ResponsiveContainer width="100%" height={275}>
              <ComposedChart
                data={bargraphData}
                margin={{
                  top: 30,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="name" />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={axisLabels}
                  domain={['auto', 1000]}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                />
                <Legend />
                <Bar name="Monthly Spending" dataKey="totalCredits" stackId="x" fill="#4F7CAC" barSize={30} isAnimationActive={false} />
                <ReferenceLine y={0} stroke="#000" />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </CardBase>
      </Grid>
    );
  }
}

CardAccountGraphCard.contextType = PersonContext;

CardAccountGraphCard.defaultProps = {
  stale: false,
  removeStale: null,
  startDate: moment().subtract(5, 'month').startOf('month').format('YYYY-MM-DD'),
  endDate: moment().endOf('month').format('YYYY-MM-DD'),
};

CardAccountGraphCard.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.string).isRequired,
  stale: PropTypes.bool,
  removeStale: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  accountId: PropTypes.node.isRequired,
  // theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default CardAccountGraphCard;
