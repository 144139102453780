import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, makeStyles } from '@material-ui/core';
import { create, find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import {
  addressLabel,
  nameLabel,
  padSubaccounts,
  getFilteredUnitOptions,
  defaultAccountTypeSort,
  subaccountSort,
} from './Autocomplete/Library';
import { getManualBasicJournal } from './SearchSelect/TransactionTypeOptions';
import LinkBase from './LinkBase';

const useStyle = makeStyles(() => ({
  downloadTremplateButtonContainer: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
}));

export default function UploadPropertyManagerSpreadsheet({
  isOpen,
  closeDialog,
  propertyManagerId,
  onBookUploadedTransactions,
}) {
  UploadPropertyManagerSpreadsheet.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    propertyManagerId: PropTypes.number.isRequired,
    onBookUploadedTransactions: PropTypes.func.isRequired,
  };
  const classes = useStyle();
  const uploadcareWidget = useRef(null);
  const { organizationId } = useContext(PersonContext);
  const [key, setKey] = useState('downloadTemplate');
  const [data, setData] = useState({
    accountIdSelect: null,
    filePath: '',
    filePath_info: {},
  });

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const [sheetData, setSheetData] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [unmappedProperties, setUnmappedProperties] = useState([]);
  const [unmappedAccounts, setUnmappedAccounts] = useState([]);

  const [propertyOptions, setPropertyOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [groupedAccountOptions, setGroupedAccountOptions] = useState([]);

  /**
   * Effect that runs on initial load to get the property, unit, and bank account options.
   */
  useEffect(() => {
    const collator = new Intl.Collator('en', { numeric: true });
    const getData = async () => {
      const propertiesQuery = {
        organizationId,
        inactive: false,
        $limit: 500,
        $sort: {
          address1: 1,
          id: 1,
        },
      };
      const propertiesResult = await find(this, 'properties', { query: propertiesQuery });
      setPropertyOptions(propertiesResult.data);

      const unitsQuery = {
        organizationId,
        inactive: { $or: [null, false] },
        $limit: 500,
        $sort: {
          name: 1,
          id: 1,
        },
      };
      const unitsResult = await find(this, 'units', { query: unitsQuery });
      setUnitOptions(unitsResult.data.sort((a, b) => collator.compare(a.name, b.name)));

      const groupedAccountsQuery = {
        organizationId,
        inactive: { $or: [null, false] },
        $limit: 500,
        $skip: 0,
        $sort: {
          name: 1,
          id: 1,
        },
      };
      let accounts = [];
      let accountsResult;

      do {
        // eslint-disable-next-line no-await-in-loop
        accountsResult = await find(this, 'accounts', { query: groupedAccountsQuery });
        accounts = accounts.concat(accountsResult.data);
        groupedAccountsQuery.$skip += groupedAccountsQuery.$limit;
      } while (accountsResult.total > accounts.length);

      accounts.sort(defaultAccountTypeSort);
      accounts = subaccountSort(accounts);
      setGroupedAccountOptions(accounts);
    };
    getData();
    // eslint-disable-next-line
  }, []);

  const downloadTemplate = async (event) => {
    event.preventDefault();
    document.getElementById('property_manager_template_link').click();
    setKey('completeTemplate');
  };

  const handleUploaderChange = (name) => (value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_info`]: value,
      [name]: value.cdnUrl,
    });
  };

  const uploadCompletedTemplate = (event) => {
    event.preventDefault();

    if (submitting) {
      return;
    }
    if (!data.filePath) {
      setError({ message: 'Please add a completed transaction template file.' });
      return;
    }

    if (!data.filePath_info.name.includes('.xlsx')) {
      setError({ message: 'The upload template was not saved in the the proper .xlsx file format. Please add a valid Excel file.' });
      return;
    }

    Object.assign(data, { organizationId });
    setSubmitting(true);

    create(this, 'upload-pm-spreadsheet', data, true)
      .then((reponse) => {
        setSheetData(reponse);
        setSubmitting(false);
      })
      .catch((err) => {
        setError(err);
        setSubmitting(false);
      });
  };

  const bookTransactions = async () => {
    const transactions = [];

    sheetData.forEach((item) => {
      if (item.propertyRule.ignored) {
        return;
      }

      item.transactions.forEach((transaction) => {
        if (transaction.accountRule.ignored) {
          return;
        }

        const journalTemplate = getManualBasicJournal();

        journalTemplate.organizationId = organizationId;
        journalTemplate.attachmentURL = data.filePath;

        const transactionAmount = transaction.amount;
        journalTemplate.amount = transactionAmount;
        journalTemplate.creditLines[0].credit = transactionAmount;
        journalTemplate.debitLines[0].debit = transactionAmount;

        if (transaction.direction === 'Cash In') {
          journalTemplate.debitLines[0].accountId = propertyManagerId;
          journalTemplate.creditLines[0].accountId = transaction.accountRule.accountId;
          if (transaction.accountRule.account.type === 'Revenue') {
            journalTemplate.type = 'Revenue';
          } else if (transaction.accountRule.account.type === 'Expense') {
            journalTemplate.type = 'Refund';
          } else {
            journalTemplate.type = 'Transfer';
          }
        } else if (transaction.direction === 'Cash Out') {
          journalTemplate.creditLines[0].accountId = propertyManagerId;
          journalTemplate.debitLines[0].accountId = transaction.accountRule.accountId;
          if (transaction.accountRule.account.type === 'Revenue') {
            journalTemplate.type = 'Refund Issued';
          } else if (transaction.accountRule.account.type === 'Expense') {
            journalTemplate.type = 'Expense';
          } else {
            journalTemplate.type = 'Transfer';
          }
        }

        journalTemplate.journalLines = journalTemplate.creditLines.concat(
          journalTemplate.debitLines,
        );

        journalTemplate.description = transaction.description;
        if (transaction.additionalNotes) {
          journalTemplate.notes = transaction.additionalNotes;
        }
        journalTemplate.date = transaction.date;

        journalTemplate.propertyId = item.propertyRule.propertyId;
        journalTemplate.unitId = transaction.unitId
          ? transaction.unitId : item.propertyRule.unitId;

        transactions.push(journalTemplate);
      });
    });

    const createPromises = transactions.map(
      (journal) => create(this, 'journals', journal, true),
    );

    await Promise.all(createPromises)
      .then(async () => {
        setSubmitting(false);
        setKey('confirmation');
        onBookUploadedTransactions();
      })
      .catch((err) => {
        setError(err);
        setSubmitting(false);
      });
  };

  const checkForTransfers = (accountMapping) => {
    if (accountMapping.accountName.toLowerCase().includes('transfer')
      || accountMapping.accountName.toLowerCase().includes('owner')) {
      return true;
    }
    return false;
  };

  const autoFillAccountSelection = (unmappedAccount) => {
    const rentAccountRegex = /rent/gi;
    const repairAccountRegex = /repair|labor|hvac/gi;
    const maintenanceAccountRegex = /maintenance|cleaning|lawn|yard|landscap|mow|garden|pest|rodent|insect|bug/gi;
    const utilitiesAccountRegex = /utilit|water|sewer|electric|internet/gi;
    const managementFeesAccountRegex = /management/gi;
    const suppliesAccountRegex = /material|supplies/gi;

    if (unmappedAccount.accountName.match(rentAccountRegex)) {
      const rentAccount = groupedAccountOptions.find((option) => option.default === 'rentRevenue');
      // eslint-disable-next-line no-param-reassign
      unmappedAccount.accountIdSelect = rentAccount;
    } else if (unmappedAccount.accountName.match(repairAccountRegex)) {
      const repairAccount = groupedAccountOptions.find((option) => option.default === 'repairsExpense');
      // eslint-disable-next-line no-param-reassign
      unmappedAccount.accountIdSelect = repairAccount;
    } else if (unmappedAccount.accountName.match(maintenanceAccountRegex)) {
      const maintenanceAccount = groupedAccountOptions.find(
        (option) => option.default === 'cleaningAndMaintenanceExpense',
      );
      // eslint-disable-next-line no-param-reassign
      unmappedAccount.accountIdSelect = maintenanceAccount;
    } else if (unmappedAccount.accountName.match(utilitiesAccountRegex)) {
      const utilitiesAccount = groupedAccountOptions.find(
        (option) => option.default === 'utilitiesExpense',
      );
      // eslint-disable-next-line no-param-reassign
      unmappedAccount.accountIdSelect = utilitiesAccount;
    } else if (unmappedAccount.accountName.match(managementFeesAccountRegex)) {
      const managementFeesAccount = groupedAccountOptions.find(
        (option) => option.default === 'managementFeesExpense',
      );
      // eslint-disable-next-line no-param-reassign
      unmappedAccount.accountIdSelect = managementFeesAccount;
    } else if (unmappedAccount.accountName.match(suppliesAccountRegex)) {
      const suppliesAccount = groupedAccountOptions.find(
        (option) => option.default === 'suppliesExpense',
      );
      // eslint-disable-next-line no-param-reassign
      unmappedAccount.accountIdSelect = suppliesAccount;
    }

    if (checkForTransfers(unmappedAccount)) {
      // eslint-disable-next-line no-param-reassign
      unmappedAccount.ignored = true;
    }
  };

  const matchSavedAccounts = async () => {
    let skipAccountSelect = true;
    const unmappedAccountsArray = [];

    const accountMappingRulesResult = await find(this, 'property-manager-pdf-mapping-rules', {
      query: {
        organizationId,
        propertyManagerAccountId: propertyManagerId,
        mappingType: 'Account',
        $limit: 500,
      },
    });

    const accountMappingRules = accountMappingRulesResult.data;

    accountMappingRules.forEach((accountMapping) => {
      // eslint-disable-next-line no-param-reassign
      accountMapping.account = groupedAccountOptions.find(
        (option) => option.id === accountMapping.accountId,
      );
    });

    sheetData.forEach((item, itemIndex) => {
      if (item.propertyRule.ignored) {
        return;
      }

      item.transactions.forEach((transaction, transactionIndex) => {
        if (transaction.accountRule) {
          return;
        }
        const accountRule = accountMappingRules.find(
          (rule) => rule.accountName === transaction.account,
        );
        if (!accountRule) {
          if (unmappedAccountsArray.find(
            (unmapped) => unmapped.accountName === transaction.account,
          )) {
            return;
          }
          skipAccountSelect = false;
          const unmappedAccount = {
            organizationId,
            mappingType: 'Account',
            propertyManagerAccountId: propertyManagerId,
            accountIdSelect: null,
            accountName: transaction.account,
            ignored: false,
          };
          autoFillAccountSelection(unmappedAccount);
          unmappedAccountsArray.push(unmappedAccount);
        } else {
          setSheetData((prevSheetData) => {
            const newSheetData = [...prevSheetData];
            newSheetData[itemIndex].transactions[transactionIndex].accountRule = accountRule;
            return newSheetData;
          });
        }
      });
    });

    if (!skipAccountSelect) {
      setKey('accountSelect');
      setUnmappedAccounts(unmappedAccountsArray);
      setSubmitting(false);
    } else {
      bookTransactions();
    }
  };

  const autoFillPropertyAndUnitSelection = (unmappedProperty) => {
    const unmappedPropertyName = unmappedProperty.propertyName.toString();
    const addressFirstNine = unmappedPropertyName.slice(0, 9).trim();
    const foundProperties = propertyOptions.filter((option) => (
      option.address1.includes(addressFirstNine)
    ));
    if (foundProperties.length === 1) {
      // eslint-disable-next-line no-param-reassign
      [unmappedProperty.propertyIdSelect] = foundProperties;
      if (
        foundProperties[0].unitSelection === 'multi'
          && unmappedProperty.propertyName.includes('-')
      ) {
        const unitName = unmappedProperty.propertyName.split('-')[1].trim();
        if (unitName) {
          const foundUnits = unitOptions.filter((unit) => unit.name === unitName);
          if (foundUnits.length === 1) {
            // eslint-disable-next-line no-param-reassign
            [unmappedProperty.unitIdSelect] = foundUnits;
          } else if (foundUnits.length > 1) {
            const matchingUnit = foundUnits.find(
              (unit) => unit.propertyId === foundProperties[0].id,
            );
            if (matchingUnit) {
              // eslint-disable-next-line no-param-reassign
              unmappedProperty.unitIdSelect = matchingUnit;
            }
          }
        }
      }
    }
  };

  const matchSavedProperties = async () => {
    let skipAddressSelect = true;
    const unmappedPropertiesArray = [];

    const propertyMappingRulesResult = await find(this, 'property-manager-pdf-mapping-rules', {
      query: {
        organizationId,
        propertyManagerAccountId: propertyManagerId,
        mappingType: 'Property',
        $limit: 500,
      },
    });

    const propertyMappingRules = propertyMappingRulesResult.data;

    sheetData.forEach((item, index) => {
      if (item.propertyRule) {
        return;
      }

      const propertyRule = propertyMappingRules.find(
        (rule) => rule.propertyName === item.property,
      );

      if (!propertyRule) {
        skipAddressSelect = false;
        const unmappedProperty = {
          organizationId,
          mappingType: 'Property',
          propertyManagerAccountId: propertyManagerId,
          propertyIdSelect: null,
          unitIdSelect: null,
          propertyName: item.property,
          ignored: false,
        };

        if (!unmappedPropertiesArray.find(
          (property) => property.propertyName === unmappedProperty.propertyName,
        )
        ) {
          autoFillPropertyAndUnitSelection(unmappedProperty);
          unmappedPropertiesArray.push(unmappedProperty);
        }
      } else {
        setSheetData((prevSheetData) => {
          const newSheetData = [...prevSheetData];
          newSheetData[index].propertyRule = propertyRule;
          return newSheetData;
        });
      }
    });

    if (!skipAddressSelect) {
      setKey('addressSelect');
      setUnmappedProperties(unmappedPropertiesArray);
      setSubmitting(false);
      return;
    }
    matchSavedAccounts();
  };

  /**
   * Kicks off after upload is completed and transactions are set.
   */
  useEffect(() => {
    if (sheetData.length && initialLoad) {
      setInitialLoad(false);
      matchSavedProperties();
    }
  // eslint-disable-next-line
  }, [sheetData, initialLoad]);

  const savePropertyMapping = async (event) => {
    event.preventDefault();
    if (submitting) {
      return;
    }

    setSubmitting(true);

    const createPromises = [];
    unmappedProperties.forEach((property) => {
      if (!property.ignored) {
        // eslint-disable-next-line no-param-reassign
        property.propertyId = property.propertyIdSelect.id;

        if (property.unitIdSelect && property.unitIdSelect.propertyId === property.propertyId) {
          // eslint-disable-next-line no-param-reassign
          property.unitId = property.unitIdSelect.id;
        }
      }
      createPromises.push(create(this, 'property-manager-pdf-mapping-rules', property, true));
    });

    await Promise.all(createPromises)
      .then(() => {
        matchSavedProperties();
      })
      .catch((err) => {
        setError(err);
        setSubmitting(false);
      });
  };

  const saveAccountMapping = async (event) => {
    event.preventDefault();
    if (submitting) {
      return;
    }

    setSubmitting(true);

    const createPromises = [];
    unmappedAccounts.forEach((account) => {
      if (!account.ignored) {
        // eslint-disable-next-line no-param-reassign
        account.accountId = account.accountIdSelect.id;
      }
      createPromises.push(create(this, 'property-manager-pdf-mapping-rules', account, true));
    });

    await Promise.all(createPromises)
      .then(() => {
        matchSavedAccounts();
      })
      .catch((err) => {
        setError(err);
        setSubmitting(false);
      });
  };

  const getDialogContent = () => {
    switch (key) {
      case 'downloadTemplate':
        return (
          <form onSubmit={downloadTemplate}>
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Please download the transaction template to your computer
                </Typography>
              </Box>
              <Box display="none">
                <LinkBase id="property_manager_template_link" to="/property_manager_import_template_v1.xlsx" target="_blank">
                  <Typography variant="body2">Property Manager Transaction Template</Typography>
                </LinkBase>
              </Box>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="body2" gutterBottom>
                  {`Click below to download REI Hub's property management spreadsheet upload
                  template. You will copy the information from the statement you received from
                  your property manager into this template and then upload it in the next step.`}
                </Typography>
                <Typography variant="body2">
                  If you already have a template downloaded, you can skip this step.
                </Typography>
              </Box>
              <Box className={classes.downloadTremplateButtonContainer}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  Download Template
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  disableElevation
                  onClick={() => setKey('completeTemplate')}
                >
                  Skip
                </Button>
                <Button
                  color="primary"
                  onClick={() => closeDialog()}
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'completeTemplate':
        return (
          <form onSubmit={
              (event) => {
                event.preventDefault();
                setKey('uploadCompletedTemplate');
              }
            }
          >
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Please add your transactions to the downloaded template
                </Typography>
              </Box>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="body2" gutterBottom>
                  Open the downloaded template and add the transactions from your property
                  management statement. Make sure each row contains an entry for the date,
                  property, unit (if applicable), account (rent, management fees, etc.),
                  description, and amount.
                </Typography>
                <Typography variant="body2">
                  Once you have added all the transaction information, save the file to your
                  computer and click the button below to advance to the upload step.
                </Typography>
              </Box>
              <Box mx="auto" textAlign="center" mt={4}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  Continue
                </Button>
                <Button
                  color="secondary"
                  onClick={() => closeDialog()}
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'uploadCompletedTemplate':
        return (
          <form onSubmit={uploadCompletedTemplate}>
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Please select the completed template file from your computer
                </Typography>
              </Box>
              <FormControl margin="dense">
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Template File
                </FormLabel>
                <div style={{ display: data.filePath ? null : 'none' }}>
                  <Widget
                    ref={uploadcareWidget}
                    tabs="file"
                    value={data.filePath}
                    onChange={handleUploaderChange('filePath')}
                  />
                </div>
                {!data.filePath && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      uploadcareWidget.current.openDialog();
                    }}
                  >
                    Click to add your completed spreadsheet
                  </Button>
                )}
                {data.filePath && (
                  <Button
                    color="primary"
                    onClick={() => {
                      setError(null);
                      setData({ ...data, filePath: '', filePath_info: {} });
                    }}
                  >
                    Remove spreadsheet file
                  </Button>
                )}
              </FormControl>
              <Typography color="error">{error && error.message}</Typography>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  disabled={submitting}
                >
                  Upload Spreadsheet
                </Button>
                <Button
                  color="primary"
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'addressSelect':
        return (
          <form onSubmit={savePropertyMapping}>
            <DialogContent>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Properties
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We found the following unknown property names in your upload. Please select
                  the corresponding property from your account to book these transactions to.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`Note: These choices will be saved to automatically assign transactions to the
                    chosen properties on future spreadsheet uploads.`}
                </Typography>
                {unmappedProperties.map((property, index) => (
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    padding={2}
                    marginY={2}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <Typography variant="body1" gutterBottom>
                      {property.propertyName}
                    </Typography>
                    {!property.ignored && (
                      <>
                        <Autocomplete
                          options={propertyOptions}
                          getOptionLabel={addressLabel}
                          getOptionSelected={(option, value) => option.id === value.id}
                          value={property.propertyIdSelect}
                          onChange={(event, newValue) => {
                            // eslint-disable-next-line no-param-reassign
                            property.propertyIdSelect = newValue;
                            setUnmappedProperties([...unmappedProperties]);
                          }}
                          disableClearable
                          renderInput={(params) => (
                            <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                              margin="dense"
                              label="Property"
                              placeholder="Type to Search"
                              fullWidth
                              required
                            />
                          )}
                        />
                        {property.propertyIdSelect && property.propertyIdSelect.unitSelection === 'multi' && (
                          <Autocomplete
                            options={getFilteredUnitOptions(
                              unitOptions,
                              property.propertyIdSelect.id,
                              { addAll: true },
                            )}
                            getOptionLabel={nameLabel}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={property.unitIdSelect}
                            onChange={(event, newValue) => {
                              // eslint-disable-next-line no-param-reassign
                              property.unitIdSelect = newValue;
                              setUnmappedProperties([...unmappedProperties]);
                            }}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                margin="dense"
                                label="Unit"
                                placeholder="Type to Search"
                                fullWidth
                                required
                              />
                            )}
                          />
                        )}
                      </>
                    )}
                    <FormControl margin="none">
                      <FormControlLabel
                        label="Ignore"
                        control={(
                          <Checkbox
                            checked={property.ignored}
                            onChange={(event) => {
                              // eslint-disable-next-line no-param-reassign
                              property.ignored = event.target.checked;
                              setUnmappedProperties([...unmappedProperties]);
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                ))}
              </Box>
              <Box mx="auto" textAlign="center" mt={4}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  Save and Continue
                </Button>
                <Button
                  color="primary"
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'accountSelect':
        return (
          <form onSubmit={saveAccountMapping}>
            <DialogContent>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Accounts
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We found the following unknown account descriptions in your upload. Please
                  ensure that the correct account is selected for each description.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`Note: These choices will be saved to automatically assign transactions to the
                    chosen accounts on subsequent spreadsheet uploads.`}
                </Typography>
                {unmappedAccounts.map((unmappedAccount, index) => (
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    padding={2}
                    marginY={2}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <Typography variant="body1" gutterBottom>
                      {unmappedAccount.accountName}
                    </Typography>
                    {!unmappedAccount.ignored && (
                      <Autocomplete
                        groupBy={(option) => option.type}
                        options={groupedAccountOptions}
                        getOptionLabel={nameLabel}
                        renderOption={padSubaccounts}
                        getOptionSelected={(option, value) => option.id === value.id}
                        value={unmappedAccount.accountIdSelect}
                        onChange={(event, newValue) => {
                          // eslint-disable-next-line no-param-reassign
                          unmappedAccount.accountIdSelect = newValue;
                          setUnmappedAccounts([...unmappedAccounts]);
                        }}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            margin="dense"
                            label="Account"
                            placeholder="Type to Search"
                            fullWidth
                            required
                          />
                        )}
                      />
                    )}
                    <FormControl margin="none">
                      <FormControlLabel
                        label="Ignore"
                        control={(
                          <Checkbox
                            checked={unmappedAccount.ignored}
                            onChange={(event) => {
                              // eslint-disable-next-line no-param-reassign
                              unmappedAccount.ignored = event.target.checked;
                              setUnmappedAccounts([...unmappedAccounts]);
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {checkForTransfers(unmappedAccount) && (
                    <Typography variant="body2" gutterBottom>
                      In most cases inter-property transfers, owner contributions,
                      and draws should be ignored. Book any transfers to or from
                      your property manager from your bank import feed.
                    </Typography>
                    )}
                  </Box>
                ))}
              </Box>
              <Box mx="auto" textAlign="center" mt={4}>
                <Typography color="error">{error && error.message}</Typography>
                <Button
                  type="submit"
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  disabled={submitting}
                >
                  Complete Upload
                </Button>
                <Button
                  color="primary"
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'confirmation':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Success
              </Typography>
              <Typography variant="body1" gutterBottom>
                {
                `We have booked the transactions included in your spreadsheet. Be sure to book any
                owner contributions or payouts included in the statement as transfers from your
                linked bank account's import feed. Please double check the imported transactions
                and email us at support@reihub.net for any assistance.`
                }
              </Typography>
            </Box>
            <Box mx="auto" textAlign="center" mt={4} mb={2}>
              <Button
                type="submit"
                color="secondary"
                variant="outlined"
                size="large"
                fullWidth
                onClick={closeDialog}
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      {getDialogContent()}
    </Dialog>
  );
}
