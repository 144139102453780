import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    const shouldClearLocalStorage = error.code === 403 || error.code === 401;

    if (shouldClearLocalStorage) {
      localStorage.clear();
    }

    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error, info) {
    if (window.atatus) {
      const augmentedInfo = { ...info };
      if (localStorage.getItem('organizationId')) {
        augmentedInfo.organizationId = localStorage.getItem('organizationId');
      }
      window.atatus.notify(
        `Error in ErrorBoundary component${JSON.stringify(error)}`,
        {
          error,
          info: augmentedInfo,
        },
        ['REI Hub FE'],
      );
    }
  }

  render() {
    const { error, hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // Error path
      if (error.code && (error.code === 403 || error.code === 401)) {
        return (
          <Box display="flex" flexDirection="column" minHeight="80vh" justifyContent="center" alignItems="center">
            <Typography variant="h6" gutterBottom>Sign In Expired</Typography>
            <Typography variant="body2">
              Your current session has expired. Please sign in again to continue.
            </Typography>
            <Box mt={2}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disableElevation
                component="a"
                href="/sign-in"
              >
                Sign In
              </Button>
            </Box>
          </Box>
        );
      }
      if (error.code && error.code === 408) {
        return (
          <Box display="flex" flexDirection="column" minHeight="80vh" justifyContent="center" alignItems="center">
            <Typography variant="h6" gutterBottom>Connection Interrupted</Typography>
            <Typography variant="body2">
              We&apos;ve lost our connection to REI Hub. Please reload the page to try again.
            </Typography>
            <Box mt={2}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disableElevation
                onClick={() => window.location.reload(true)}
              >
                Reload the page
              </Button>
            </Box>
          </Box>
        );
      }
      return (
        <Box display="flex" flexDirection="column" minHeight="80vh" justifyContent="center" alignItems="center">
          <Typography variant="h6" gutterBottom>Oops. Something went wrong.</Typography>
          <Typography variant="body2">
            Our team has been notified. We&apos;re sorry for the disruption.
          </Typography>
          <Box mt={2}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disableElevation
              component="a"
              href="/dashboard"
            >
              Return to the hompage
            </Button>
          </Box>
        </Box>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
