import moment from 'moment';

const getParsedObj = (name, component) => {
  const pathSegments = name.split('_');

  if (pathSegments[0] !== 'nested') {
    const sharedObj = {};
    return {
      newState: sharedObj,
      changedObj: sharedObj,
      propertyName: name,
    };
  }

  const newState = {};
  let changedObj = newState;
  let lastObj = component.state;
  for (let i = 1; i < pathSegments.length - 1; i += 1) {
    if (Array.isArray(lastObj[pathSegments[i]])) {
      lastObj = [...lastObj[pathSegments[i]]];
    } else {
      lastObj = { ...lastObj[pathSegments[i]] };
    }
    changedObj[pathSegments[i]] = lastObj;
    changedObj = lastObj;
  }
  return {
    newState,
    changedObj,
    propertyName: pathSegments[pathSegments.length - 1],
  };
};

export const handleChange = (name, value, component) => {
  const parsedObj = getParsedObj(name, component);
  parsedObj.changedObj[parsedObj.propertyName] = value;

  component.setState(parsedObj.newState);
};

export const asyncHandleChange = (name, value, component) => {
  const parsedObj = getParsedObj(name, component);
  parsedObj.changedObj[parsedObj.propertyName] = value;

  return new Promise((resolve) => {
    component.setState(parsedObj.newState, () => resolve());
  });
};

export const asyncSetState = (newState, component) => new Promise((resolve) => {
  component.setState(newState, () => resolve());
});

export const handleTextFieldChange = (component) => (event) => {
  const { target } = event;
  const { name, value } = target;

  const parsedObj = getParsedObj(name, component);
  parsedObj.changedObj[parsedObj.propertyName] = value;

  component.setState(parsedObj.newState);
};

export const handleRadioGroupChange = (component) => (event) => {
  const { target } = event;
  const { name, value } = target;

  const parsedObj = getParsedObj(name, component);
  parsedObj.changedObj[parsedObj.propertyName] = value;

  component.setState(parsedObj.newState);
};

export const handleCheckboxChange = (component) => (event) => {
  const { target } = event;
  const value = target.checked;
  const { name } = target;

  const parsedObj = getParsedObj(name, component);
  parsedObj.changedObj[parsedObj.propertyName] = value;

  component.setState(parsedObj.newState);
};

export const handleKeyboardDatePickerChange = (name, component) => (value) => {
  const parsedObj = getParsedObj(name, component);
  if (moment.isMoment(value) && moment(value).isValid()) {
    parsedObj.changedObj[parsedObj.propertyName] = moment(value).format('YYYY-MM-DD');
  } else {
    parsedObj.changedObj[parsedObj.propertyName] = null;
  }

  if (moment.isMoment(value) && moment(value).creationData().input === '__/__/____') {
    parsedObj.changedObj[`${parsedObj.propertyName}Select`] = null;
  } else {
    parsedObj.changedObj[`${parsedObj.propertyName}Select`] = value;
  }
  component.setState(parsedObj.newState);
};

export const handleSearchSelectChange = (component) => (name, value, isMulti) => {
  const parsedObj = getParsedObj(name, component);
  const { changedObj, propertyName, newState } = parsedObj;

  if (isMulti && (value === null || value === [])) {
    changedObj[propertyName] = [];
    changedObj[`${propertyName}Select`] = [];
  } else if (value === null || value === []) {
    changedObj[propertyName] = value;
    changedObj[`${propertyName}Select`] = value;
  } else {
    changedObj[propertyName] = isMulti ? value.map((item) => item.value) : value.value;
    changedObj[`${propertyName}Select`] = value;
  }

  component.setState(newState);
};

export const filterSearchSelectOptions = (options) => (search) => {
  if (search) {
    return Promise.resolve(
      options.filter((i) => i.label.toLowerCase().includes(search.toLowerCase())),
    );
  }
  return Promise.resolve(options);
};

export const handleUploaderChange = (name, component) => (value) => {
  const parsedObj = getParsedObj(name, component);

  if (value) {
    parsedObj.changedObj[`${parsedObj.propertyName}_info`] = value;
    parsedObj.changedObj[parsedObj.propertyName] = value.cdnUrl;
  } else {
    parsedObj.changedObj[`${parsedObj.propertyName}_info`] = {};
    parsedObj.changedObj[parsedObj.propertyName] = '';
  }

  component.setState(parsedObj.newState);
};

export const handleNumberFormatChange = (name, component) => (values) => {
  const parsedObj = getParsedObj(name, component);
  if (typeof values.floatValue === 'undefined') {
    parsedObj.changedObj[parsedObj.propertyName] = null;
  } else {
    parsedObj.changedObj[parsedObj.propertyName] = values.floatValue;
  }

  component.setState(parsedObj.newState);
};

export const handleToggleButtonChange = (name, component) => (event, value) => {
  const parsedObj = getParsedObj(name, component);
  parsedObj.changedObj[parsedObj.propertyName] = value;

  component.setState(parsedObj.newState);
};

export const handleAutocompleteChange = (name, component, addNew = () => {}) => (event, value) => {
  if (value && value.id === 'New Instance') {
    addNew();
    return;
  }

  const parsedObj = getParsedObj(name, component);
  parsedObj.changedObj[parsedObj.propertyName] = value;

  component.setState(parsedObj.newState);
};

export const handleTransactionScopeChange = (journal, newScopeValues, component) => {
  const newData = {
    ...journal,
    ...newScopeValues,
  };
  component.setState({ journal: newData });
};
