import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { create } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';

import ReconcileAccountDialog from '../../components/ReconcileAccountDialog';

import CardBase from '../../components/CardBase';

class PartnerQuicklinksCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      reconcileAccountDialog: false,
    };
  }

  setTransactionsStale = () => {
    const { markStale } = this.props;
    markStale('accountGraph');
    markStale('journals');
  };

  goToPartnerPortal = async (event) => {
    event.preventDefault();

    const { partnerName } = this.context;

    if (partnerName === 'RentRedi') {
      window.location.href = 'https://app.rentredi.com/';
    } else if (partnerName === 'TurboTenant') {
      window.location.href = 'https://rental.turbotenant.com/';
    } else if (partnerName === 'Lease Protector') {
      window.location.href = 'https://app.leaseprotector.com/';
    } else if (partnerName === 'Innago') {
      window.location.href = 'https://my.innago.com/';
    } else {
      // eslint-disable-next-line no-alert
      alert('Unknown partner portal URL');
    }
  };

  resyncTurboTenant = async (event) => {
    event.preventDefault();
    const {
      submitting,
    } = this.state;

    const { organizationId } = this.context;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    create(this, 'turbotenant-resync', { organizationId })
      .then(async () => {
        this.setTransactionsStale();
      });

    // eslint-disable-next-line no-alert
    alert('Your resync request was submitted successfully. Resynced data will automatically be written to your portfolio by TurboTenant, typically within 60 seconds.');
  };

  resyncRentRedi = async (event) => {
    event.preventDefault();
    const {
      submitting,
    } = this.state;

    const { organizationId } = this.context;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    create(this, 'rentredi-resync', { organizationId })
      .then(async () => {
        this.setTransactionsStale();
      });

    // eslint-disable-next-line no-alert
    alert('Your resync request was submitted successfully. Resynced data will automatically be written to your portfolio by RentRedi, typically within 60 seconds.');
  };

  resyncLeaseProtector = async (event) => {
    event.preventDefault();
    const {
      submitting,
    } = this.state;

    const { organizationId } = this.context;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    create(this, 'lease-protector-resync', { organizationId })
      .then(async () => {
        this.setTransactionsStale();
      });

    // eslint-disable-next-line no-alert
    alert('Your resync request was submitted successfully. Resynced data will automatically be written to your portfolio by Lease Protector, typically within 60 seconds.');
  };

  render() {
    const {
      reconcileAccountDialog,
    } = this.state;

    const {
      accountId,
    } = this.props;

    const { partnerSubscribed, partnerName } = this.context;

    return (
      <>
        {reconcileAccountDialog && (
          <ReconcileAccountDialog
            isOpen
            closeDialog={() => this.setState({
              reconcileAccountDialog: false,
            })}
            onReconcile={this.setTransactionsStale}
            accountId={accountId}
          />
        )}
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CardBase>
            <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
              <Typography variant="h6" gutterBottom>
                Quicklinks
              </Typography>
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.goToPartnerPortal}
                >
                  {`${partnerName} Portal`}
                </Button>
              </Box>
              <Box marginBottom={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  disabled={accountId === 'All'}
                  fullWidth
                  onClick={() => this.setState({
                    reconcileAccountDialog: true,
                  })}
                >
                  Reconcile Account
                </Button>
              </Box>
              {partnerSubscribed && partnerName === 'TurboTenant' && process.env.NODE_ENV === 'production' && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={this.resyncTurboTenant}
                  >
                    Resync Portfolio
                  </Button>
                </Box>
              )}
              {partnerSubscribed && partnerName === 'RentRedi' && process.env.NODE_ENV === 'production' && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={this.resyncRentRedi}
                  >
                    Resync Portfolio
                  </Button>
                </Box>
              )}
              {partnerSubscribed && partnerName === 'Lease Protector' /* && process.env.NODE_ENV === 'production' */ && (
                <Box marginBottom={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={this.resyncLeaseProtector}
                  >
                    Resync Portfolio
                  </Button>
                </Box>
              )}
            </Box>
          </CardBase>
        </Grid>
      </>
    );
  }
}

PartnerQuicklinksCard.contextType = PersonContext;

PartnerQuicklinksCard.defaultProps = {};

PartnerQuicklinksCard.propTypes = {
  markStale: PropTypes.func.isRequired,
  accountId: PropTypes.node.isRequired,
};

export default PartnerQuicklinksCard;
