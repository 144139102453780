import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import LinkIcon from '@material-ui/icons/Link';
import Grid from '@material-ui/core/Grid';

import {
  getManualBasicJournal,
} from './SearchSelect/TransactionTypeOptions';

import {
  get, create, find, patchMultiple,
} from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleCheckboxChange,
  asyncHandleChange,
} from '../functions/InputHandlers';

import ViewImportedTransactionDialog from './ViewImportedTransactionDialog';

const styles = (theme) => ({
  inlineButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
  summaryGrid: {
    width: '100%',
  },
  transactionsTable: {
    width: '100%',
    maxHeight: '550px',
    display: 'block',
    overflow: 'auto',
  },
  tableBody: {
    display: 'table',
    width: '100%',
  },
});

class ReconcileAccountDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  async componentDidMount() {
    const { accountId } = this.props;

    const account = await get(this, 'accounts', accountId);

    const initialState = {
      submitting: false,
      key: 'aboutReconciliation',
      account,
      statement: {
        startDate: null,
        endDate: null,
        startingBalance: null,
        endingBalance: null,
      },
      accountBalance: null,
      accountReconciledBalance: null,
      startingAdjustment: {
        addAdjustment: false,
        amount: null,
        date: null,
      },
      selectedTransactionsSummary: {
        credits: null,
        creditsCount: null,
        debits: null,
        debitsCount: null,
        reconciledBalance: null,
      },
      transactions: null,
      error: null,
      loading: false,
    };

    this.setState(initialState);
  }

  closeDialog = () => {
    const { closeDialog, onReconcile } = this.props;
    onReconcile();
    closeDialog();
  };

  processStatementDates = async (event) => {
    event.preventDefault();
    const { organizationId, basis } = this.context;
    const { accountId } = this.props;
    const { account, statement } = this.state;

    const accountTransactionsQuery = {
      organizationId,
      basis,
      reportName: 'accountJournalTotals',
      date: { $lte: moment(statement.startDate).subtract(1, 'days').format('YYYY-MM-DD') },
      accountId,
    };

    const accountReconciledTransactionsQuery = {
      organizationId,
      basis,
      reportName: 'accountJournalTotals',
      date: { $lte: moment(statement.startDate).subtract(1, 'days').format('YYYY-MM-DD') },
      accountId,
      reconciled: true,
    };

    const accountTransactionsResponse = await create(this, 'reports', accountTransactionsQuery);
    const accountReconciledTransactionsResponse = await create(this, 'reports', accountReconciledTransactionsQuery);

    let accountBalance;
    let accountReconciledBalance;
    if (account.type === 'Asset') {
      accountBalance = -accountTransactionsResponse[0].netCredits;
      accountReconciledBalance = -accountReconciledTransactionsResponse[0].netCredits;
    } else {
      accountBalance = accountTransactionsResponse[0].netCredits;
      accountReconciledBalance = accountReconciledTransactionsResponse[0].netCredits;
    }

    const key = 'statementStartingBalance';
    this.setState({ key, accountBalance, accountReconciledBalance });
  };

  processStartingBalance = async (event) => {
    event.preventDefault();
    const { accountBalance, accountReconciledBalance, statement } = this.state;

    let key;
    if (accountBalance !== statement.startingBalance
      && accountReconciledBalance !== statement.startingBalance) {
      key = 'startingBalanceMismatch';
      const startingAdjustment = {
        date: moment(statement.startDate).subtract(1, 'days').format('YYYY-MM-DD'),
        amount: statement.startingBalance - accountBalance,
        addAdjustment: false,
      };
      this.setState({ key, startingAdjustment });
    } else {
      key = 'statementEndingBalance';
      this.setState({ key });
    }
  };

  processStartingBalanceMismatch = async (event) => {
    event.preventDefault();
    const { startingAdjustment, submitting } = this.state;

    if (!startingAdjustment.addAdjustment) {
      this.setState({ key: 'statementEndingBalance' });
      return;
    }

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    const { accountId } = this.props;
    const { organizationId } = this.context;
    const { account } = this.state;

    const journal = getManualBasicJournal();
    journal.type = 'Manual Journal';

    const autobalanceAccountData = await find(this, 'accounts', { query: { organizationId, default: 'autoBalance' } });

    if (account.type === 'Asset') {
      journal.creditLines[0].accountId = autobalanceAccountData.data[0].id;
      journal.creditLines[0].credit = startingAdjustment.amount;

      journal.debitLines[0].accountId = accountId;
      journal.debitLines[0].debit = startingAdjustment.amount;
    } else {
      journal.debitLines[0].accountId = autobalanceAccountData.data[0].id;
      journal.debitLines[0].debit = startingAdjustment.amount;

      journal.creditLines[0].accountId = accountId;
      journal.creditLines[0].credit = startingAdjustment.amount;
    }

    journal.date = startingAdjustment.date;
    journal.description = 'Reconciliation adjustment';

    journal.organizationId = organizationId;
    journal.amount = Math.abs(startingAdjustment.amount);
    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    create(this, 'journals', journal, true)
      .then(() => {
        this.setState({
          key: 'statementEndingBalance',
          submitting: false,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  processStatementEndingBalance = async (event) => {
    event.preventDefault();

    const { statement } = this.state;
    const { accountId } = this.props;
    const { organizationId, basis } = this.context;

    let currentTransactions = [];
    let priorTransactions = [];
    let offset = 0;
    let queryResult;
    do {
      // eslint-disable-next-line no-await-in-loop
      queryResult = await find(this, 'journal-lines', {
        query: {
          organizationId,
          basis,
          accountId,
          startDate: statement.startDate,
          endDate: statement.endDate,
          $limit: 200,
          $skip: offset,
        },
      });
      currentTransactions = currentTransactions.concat(queryResult.data);
      offset += 200;
    } while (currentTransactions.length < queryResult.total);

    offset = 0;
    queryResult = null;
    do {
      // eslint-disable-next-line no-await-in-loop
      queryResult = await find(this, 'journal-lines', {
        query: {
          organizationId,
          basis,
          accountId,
          endDate: moment(statement.startDate).subtract(1, 'days').format('YYYY-MM-DD'),
          reconciled: false,
          $limit: 200,
          $skip: offset,
        },
      });
      priorTransactions = priorTransactions.concat(queryResult.data);
      offset += 200;
    } while (priorTransactions.length < queryResult.total);

    const transactions = currentTransactions.concat(priorTransactions);

    for (let i = 0; i < transactions.length; i += 1) {
      transactions[i].reconcile = true;
    }

    await asyncHandleChange('transactions', transactions, this);
    this.updateSummary();
    this.setState({ key: 'selectTransactionsToReconcile' });
  };

  updateSummary = async () => {
    const { transactions, accountReconciledBalance, account } = this.state;

    const selectedTransactionsSummary = {
      credits: 0,
      creditsCount: 0,
      debits: 0,
      debitsCount: 0,
      reconciledBalance: 0,
    };

    for (let i = 0; i < transactions.length; i += 1) {
      if (transactions[i].reconcile) {
        if (transactions[i].type === 'credit') {
          selectedTransactionsSummary.creditsCount += 1;
          selectedTransactionsSummary.credits += transactions[i].credit;
        } else {
          selectedTransactionsSummary.debitsCount += 1;
          selectedTransactionsSummary.debits += transactions[i].debit;
        }
      }
    }

    if (account.type === 'Asset') {
      selectedTransactionsSummary.reconciledBalance = accountReconciledBalance
        + selectedTransactionsSummary.debits
        - selectedTransactionsSummary.credits;
    } else {
      selectedTransactionsSummary.reconciledBalance = accountReconciledBalance
        - selectedTransactionsSummary.debits
        + selectedTransactionsSummary.credits;
    }

    await asyncHandleChange('selectedTransactionsSummary', selectedTransactionsSummary, this);
  };

  handleTransactionSelect = async (event) => {
    const { target } = event;
    const value = target.checked;
    const { name } = target;
    await asyncHandleChange(name, value, this);
    this.updateSummary();
  };

  processSelectTransactions = async (event) => {
    event.preventDefault();

    const { transactions } = this.state;
    const { accountId } = this.props;
    const { organizationId } = this.context;

    await patchMultiple(
      this,
      'journal-lines',
      {
        query: {
          organizationId,
          accountId,
          id: transactions.filter((line) => line.reconcile).map((line) => line.id),
        },
      },
      {
        reconciled: true,
      },
    );

    this.setState({ key: 'reconciliationSuccess' });
  };

  getDialogContent = (key) => {
    const {
      statement, accountBalance, accountReconciledBalance, startingAdjustment,
      transactions, selectedTransactionsSummary, error,
    } = this.state;
    const {
      classes,
    } = this.props;

    switch (key) {
      case 'aboutReconciliation':
        return (
          <>
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Reconcile Account
                </Typography>
              </Box>
              <Typography variant="body1">
                {`Reconciliation is a process that compares your booked transactions and account balance in REI Hub to the information from a statement issued by your financial institution. This can help ensure that your records are consistent, accurate, and complete. 
                  If you do not have a statement to reconcile, please visit your bank's website and download one for your desired reconciliation period.`}
              </Typography>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => this.setState({ key: 'statementDates' })}
                >
                  Next
                </Button>
                <Button
                  color="primary"
                  onClick={this.closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </>
        );
      case 'statementDates':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processStatementDates} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="h6" gutterBottom>
                    What are the start and end dates from your statement?
                  </Typography>
                </Box>
                <KeyboardDatePicker
                  label="Statement Start Date"
                  format="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                  value={statement.startDate}
                  onChange={handleKeyboardDatePickerChange('nested_statement_startDate', this)}
                  margin="dense"
                  fullWidth
                  clearable
                  required
                />
                <KeyboardDatePicker
                  label="Statement End Date"
                  format="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                  value={statement.endDate}
                  onChange={handleKeyboardDatePickerChange('nested_statement_endDate', this)}
                  margin="dense"
                  fullWidth
                  clearable
                  required
                />
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Next
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'statementStartingBalance':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processStartingBalance} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="h6" gutterBottom>
                    What is the account&apos;s starting balance from your statement?
                  </Typography>
                </Box>
                <FormControl margin="dense" fullWidth key="startingBalance">
                  <InputLabel required>
                    Statement Starting Balance
                  </InputLabel>
                  <NumberFormat
                    value={statement.startingBalance}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    required
                    onValueChange={handleNumberFormatChange('nested_statement_startingBalance', this)}
                    customInput={Input}
                  />
                </FormControl>
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Next
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'startingBalanceMismatch':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processStartingBalanceMismatch} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="h6" gutterBottom>
                    The statement starting balance does not equal your account balance in REI Hub.
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {'Statement starting balance: '}
                  <NumberFormat
                    displayType="text"
                    value={statement.startingBalance}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
                <Typography variant="body1">
                  {'Book balance in REI Hub: '}
                  <NumberFormat
                    displayType="text"
                    value={accountBalance}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
                <Typography variant="body1">
                  {'Reconciled balance in REI Hub: '}
                  <NumberFormat
                    displayType="text"
                    value={accountReconciledBalance}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
                <FormControl margin="none" fullWidth>
                  <FormControlLabel
                    control={<Checkbox checked={startingAdjustment.addAdjustment} onChange={handleCheckboxChange(this)} name="nested_startingAdjustment_addAdjustment" />}
                    label="Add adjusting journal"
                  />
                </FormControl>
                {startingAdjustment.addAdjustment && (
                  <>
                    <FormControl margin="dense" fullWidth>
                      <InputLabel required>
                        Adjustment Amount
                      </InputLabel>
                      <NumberFormat
                        value={startingAdjustment.amount}
                        thousandSeparator
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale
                        required
                        onValueChange={handleNumberFormatChange('nested_startingAdjustment_amount', this)}
                        customInput={Input}
                      />
                    </FormControl>
                    <KeyboardDatePicker
                      label="Adjusting Journal Date"
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      value={startingAdjustment.date}
                      onChange={handleKeyboardDatePickerChange('nested_startingAdjustment_date', this)}
                      margin="dense"
                      fullWidth
                      clearable
                      required
                    />
                  </>
                )}
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body2">
                    {`You may want to reconcile an earlier period or add an adjusting entry to the account 
                      to address the discrepancy before continuing your reconciliation.`}
                  </Typography>
                </Box>
                <Typography color="error">{error && error.message}</Typography>
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    {startingAdjustment.addAdjustment ? 'Save Adjustment' : 'Continue Anyway'}
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'statementEndingBalance':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processStatementEndingBalance} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="h6" gutterBottom>
                    What is the account&apos;s ending balance from your statement?
                  </Typography>
                </Box>
                <FormControl margin="dense" fullWidth key="endingBalance">
                  <InputLabel required>
                    Statement Ending Balance
                  </InputLabel>
                  <NumberFormat
                    value={statement.endingBalance}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    required
                    onValueChange={handleNumberFormatChange('nested_statement_endingBalance', this)}
                    customInput={Input}
                  />
                </FormControl>
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Next
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'selectTransactionsToReconcile':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.processSelectTransactions} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="h6" gutterBottom>
                    Please select the transactions to mark as reconciled.
                  </Typography>
                </Box>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography variant="subtitle2">Balance Summary</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid spacing={3} container className={classes.summaryGrid}>
                      <Grid item xs={6}>
                        <Typography variant="h6" align="center">
                          <NumberFormat
                            displayType="text"
                            value={statement.endingBalance}
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </Typography>
                        <Typography variant="subtitle2" align="center">
                          Expected (Bank)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h6" align="center">
                          <NumberFormat
                            displayType="text"
                            value={selectedTransactionsSummary.reconciledBalance}
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </Typography>
                        <Typography variant="subtitle2" align="center">
                          Reconciled (REI Hub)
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography variant="subtitle2">Transactions</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" width="100%">
                      <Table size="small" className={classes.transactionsTable}>
                        <TableBody className={classes.tableBody}>
                          <TableRow>
                            <TableCell />
                            <TableCell><Typography variant="subtitle2">Date</Typography></TableCell>
                            <TableCell><Typography variant="subtitle2">Type</Typography></TableCell>
                            <TableCell />
                            <TableCell align="right"><Typography variant="subtitle2">Debit</Typography></TableCell>
                            <TableCell align="right"><Typography variant="subtitle2">Credit</Typography></TableCell>
                          </TableRow>
                          {transactions.map((transaction, index) => (
                            <TableRow key={transaction.id}>
                              <TableCell>
                                <Checkbox
                                  checked={transaction.reconcile}
                                  onChange={this.handleTransactionSelect}
                                  name={`nested_transactions_${index}_reconcile`}
                                />
                              </TableCell>
                              <TableCell>
                                {moment(transaction.journal.date).format('M/D/YYYY')}
                              </TableCell>
                              <TableCell>{transaction.journal.type}</TableCell>
                              <TableCell>
                                {transaction.yodleeTransactionId && (
                                  <Button
                                    className={classes.inlineButton}
                                    aria-label="imported"
                                    onClick={() => this.setState({
                                      importedTransactionId: transaction.yodleeTransactionId,
                                    })}
                                  >
                                    <LinkIcon fontSize="small" />
                                  </Button>
                                )}
                              </TableCell>
                              <TableCell align="right">
                                <NumberFormat
                                  displayType="text"
                                  value={transaction.debit}
                                  thousandSeparator
                                  prefix="$"
                                  decimalScale={2}
                                  fixedDecimalScale
                                />
                              </TableCell>
                              <TableCell align="right">
                                <NumberFormat
                                  displayType="text"
                                  value={transaction.credit}
                                  thousandSeparator
                                  prefix="$"
                                  decimalScale={2}
                                  fixedDecimalScale
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Save and Finish
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'reconciliationSuccess':
        return (
          <>
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Success
                </Typography>
              </Box>
              <Typography variant="body1">
                You have successfully reconciled your account.
              </Typography>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <Button
                  onClick={this.closeDialog}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  Close
                </Button>
              </Box>
            </DialogContent>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    const { loading, key, importedTransactionId } = this.state;

    return (
      <>
        {importedTransactionId && (
          <ViewImportedTransactionDialog
            yodleeTransactionId={importedTransactionId}
            isOpen
            closeDialog={() => this.setState({ importedTransactionId: null })}
            // onUnmatchTransaction={this.getJournals}
          />
        )}
        <Dialog
          open
          scroll="body"
          maxWidth={key === 'selectTransactionsToReconcile' ? 'md' : 'sm'}
          fullWidth
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {!loading && this.getDialogContent(key)}
        </Dialog>
      </>
    );
  }
}

ReconcileAccountDialog.contextType = PersonContext;

ReconcileAccountDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onReconcile: PropTypes.func.isRequired,
  accountId: PropTypes.node.isRequired,
};

export default withStyles(styles)(ReconcileAccountDialog);
