import React from 'react';
import { Box, Button as MuiButton, DialogContent, Typography } from '@material-ui/core';
import {
  HomeWorkOutlined as HomeWorkOutlinedIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
} from '@material-ui/icons';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Button from '~/components/Button';

export default function PartnerImported({
  partnerName,
  propertyCount,
  unitCount,
  changeToPropertyValues,
  openVideoDialog,
}) {
  return (
    <DialogContent>
      <Box mx="auto" textAlign="center" mb={2}>
        <Typography color="secondary">
          <HomeWorkOutlinedIcon />
        </Typography>
        <Typography variant="caption" gutterBottom>
          STEP 2: REVIEW YOUR PROPERTIES
        </Typography>
        <Typography variant="h6" gutterBottom>
          {`We've imported ${pluralize('property', propertyCount, true)} ${unitCount > propertyCount ? `containing ${pluralize('unit', unitCount, true)} ` : ''}from ${partnerName} into your portfolio.`}
        </Typography>
      </Box>
      <Box mb={1} textAlign="center">
        <Typography variant="body1">
          {`You'll use REI Hub to categorize transactions by property as they are added to
                your books. You can select a property to view its dashboard including financial
                summaries and organizational items from this screen. Add additional properties
                anytime through the ${partnerName} dashboard; they will automatically sync to REI
                Hub.`}
        </Typography>
      </Box>
      <Box marginBottom={1} maxWidth="400px" marginX="auto" textAlign="center">
        <MuiButton
          color="secondary"
          variant="text"
          size="large"
          fullWidth
          startIcon={<PlayCircleOutlineIcon />}
          onClick={openVideoDialog}
        >
          Watch Properties Overview
        </MuiButton>
      </Box>
      <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
        <Button hasFullWidth variant="contained" onClick={changeToPropertyValues}>
          Add Property Values
        </Button>
      </Box>
    </DialogContent>
  );
}

PartnerImported.propTypes = {
  partnerName: PropTypes.string.isRequired,
  propertyCount: PropTypes.number.isRequired,
  unitCount: PropTypes.number.isRequired,
  changeToPropertyValues: PropTypes.func.isRequired,
  openVideoDialog: PropTypes.func.isRequired,
};
