import React from 'react';

import { PersonContext } from '../../contexts/PersonContext';

import Logins from './cards/Logins';

import PageGrid from '../../components/PageGrid';
import AdminSearch from './cards/AdminSearch';

class AdminHome extends React.PureComponent {
  render() {
    return (
      <PageGrid>
        <AdminSearch />
        <Logins />
      </PageGrid>
    );
  }
}

AdminHome.contextType = PersonContext;

export default AdminHome;
